import React, { useState, useEffect } from "react"
import { Input, Label, FormFeedback } from "reactstrap"
import { State, City } from "country-state-city"

const AppSelect = ({
  name = "",
  id = "",
  className = "",
  onChange,
  onBlur,
  value,
  options,
  invalid = "",
  error = "",
  label = "",
  slug = "",
  isExternal = false,
}) => {
  const [dropDownOptions, setDropDownOptions] = useState([])

  useEffect(() => {
    if (!options && !options?.length && isExternal && slug) {
      if (slug === "state") {
        const stateOptions = State.getStatesOfCountry("IN").map(s => ({
          value: s.name,
          label: s.name,
        }))
        setDropDownOptions(stateOptions)
      } else if (slug === "city") {
        const stateOptions = City.getCitiesOfState("IN","MP").map(s => ({
          value: s.name,
          label: s.name,
        }))
        setDropDownOptions(stateOptions)
      }
    }
  }, [])

  return (
    <div className="mb-3">
      <Label className="form-label">{label}</Label>
      <Input
        name={name}
        id={id}
        type="select"
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      >
        {options &&
          options?.length &&
          !isExternal &&
          options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        {dropDownOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
      {invalid ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
    </div>
  )
}

export default AppSelect
