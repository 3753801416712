import React, { useState, useEffect } from "react"
import { Modal, Spinner } from "reactstrap"

const ConfirmationModal = ({
  modalBackdrop,
  setmodalBackdrop,
  modalTitle,
  modalAction,
}) => {
  const [loading, setLoading] = useState(false)
  const toggle = () => setmodalBackdrop(false)

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [loading])

  return (
    <>
      <Modal
        isOpen={modalBackdrop}
        toggle={toggle}
        // backdrop={"static"}
        size="sm"
        centered={true}
        id="staticBackdrop"
      >
        <div className="modal-header d-flex justify-content-center">
          <h5 className="modal-title" id="staticBackdropLabel">
            Confirmation
          </h5>
        </div>
        <div className="modal-body text-center">
        <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-danger text-danger bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-trash-can-outline"></i>
            </div>
          </div>
          <b>{modalTitle}</b>
        </div>
        <div className="d-flex justify-content-between modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setmodalBackdrop(false)
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              modalAction()
              setLoading(true)
            }}
            disabled={loading}
          >
            {loading ? (
              <Spinner className="ms-3 me-3" color="light" size="sm" />
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmationModal
