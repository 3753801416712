import React, { useState, useRef } from 'react';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from "jodit-react"
import {Label, FormFeedback } from "reactstrap";

const defaultTable = {
  borderCollapse: "collapse",
  border: "1px solid black",
  width: "100%"
}
const default_table_td = {
  border: "1px solid black",
  padding: "2px"
}

const DefaultTableStyles = {
  table: defaultTable,
  td: default_table_td,
};

const index = ({
  onChange,
  value,
  isForm = false,
  name,
  placeholder,
  onBlur,
  invalid,
  error,
  label,
}) => {
  const editor = useRef(null)

  const contentFieldChanaged = (newContent) => {
    const editorContent = document.createElement("div");
    editorContent.innerHTML = newContent;
    const cells = editorContent.querySelectorAll("td");
    const elementArray = Array.from(cells);
    if (elementArray.length > 0) {
      elementArray.forEach((element) => {
        element.style.borderWidth = element.style.borderWidth ? element.style.borderWidth : "1px";
        element.style.padding = element.style.padding ? element.style.padding : "2px";
        element.style.borderStyle = element.style.borderStyle ? element.style.borderStyle : "solid"
      });
    }
    onChange(name,editorContent.innerHTML);
  };
  return (
    <div>
      {isForm ?
        <div className="mb-3">
          <Label className="form-label">{label}</Label>
          <JoditEditor
            ref={editor}
            value={value}
            onChange={(newContent) => {
              contentFieldChanaged(newContent)
            }}
            name={name}
            placeholder={placeholder}
            onBlur={onBlur}
            invalid={invalid}
          />
          {invalid ? <p className='text-danger font-size-15 mt-1'>{error}</p> : null}
        </div>
        :
        <JoditEditor
          ref={editor}
          value={value}
          onChange={(newContent) => {
            contentFieldChanaged(newContent)
          }}
        />
      }
    </div>
  );
};

export default index;
