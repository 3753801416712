/* HELP CENTER */
export const GET_HELP_CENTER = "GET_HELP_CENTER"
export const GET_HELP_CENTER_SUCCESS = "GET_HELP_CENTER_SUCCESS"
export const GET_HELP_CENTER_FAIL = "GET_HELP_CENTER_FAIL"
export const RESET_HELP_CENTER = "RESET_HELP_CENTER"

/* HELP CENTER DETAIL*/
export const GET_HELP_CENTER_DETAIL = "GET_HELP_CENTER_DETAIL"
export const GET_HELP_CENTER_DETAIL_SUCCESS = "GET_HELP_CENTER_DETAIL_SUCCESS"
export const GET_HELP_CENTER_DETAIL_FAIL = "GET_HELP_CENTER_DETAIL_FAIL"
export const RESET_HELP_CENTER_DETAIL = "RESET_HELP_CENTER_DETAIL"

/**
 * Add help center
 */
export const ADD_NEW_HELP_CENTER = "ADD_NEW_HELP_CENTER"
export const ADD_HELP_CENTER_SUCCESS = "ADD_HELP_CENTER_SUCCESS"
export const ADD_HELP_CENTER_FAIL = "ADD_HELP_CENTER_FAIL"

export const UPDATE_HELP_CENTER_LOADING_STATE = "UPDATE_HELP_CENTER_LOADING_STATE"
