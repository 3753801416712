import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Col, Card, CardBody, Container, Row } from "reactstrap"
import {
  getStudentCourseResults as onGetStudentCourseResults,
  getCourseDetail as onGetCourseDetail,
  resetCourse as onResetCourse,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import { courseResultColumns } from "./constants"

const StudentCourseReport = () => {
  document.title = "Course Result List | QAPRENEUR"
  const dispatch = useDispatch()
  const params = useParams()

  const { courseResults, loading, courseDetail } = useSelector(state => ({
    courseResults: state?.courses?.courseResults,
    loading: state.courses.loading,
    courseDetail: state.courses?.courseDetail,
  }))

  useEffect(() => {
    const queryparams = {
      studentId: params?.studentId,
      courseId: params?.courseId,
    }
    dispatch(onGetStudentCourseResults(queryparams))
    if (params?.courseId) {
      dispatch(onGetCourseDetail(params.courseId))
    }
    return () => {
      dispatch(onResetCourse())
    }
  }, [dispatch])

  const userOptions =
    courseResults &&
    courseResults?.CertificateResult &&
    Array.isArray(courseResults?.CertificateResult) &&
    courseResults?.CertificateResult.length > 0 &&
    courseResults?.CertificateResult[0].optionDetail
      ? courseResults?.CertificateResult[0].optionDetail?.map(item => {
          const question = JSON.parse(item?.question?.question)
          const userOption = item?.userOption
          return {
            question: question?.question,
            status:
              item?.question?.isAnswer === userOption?.answer
                ? "right"
                : "wrong",
            correctAnswer: `${item?.question?.isAnswer} : ${
              question[item?.question?.isAnswer]
            }`,
            userAnswer: `${userOption?.answer} : ${
              question[userOption?.answer]
            }`,
          }
        })
      : []

  // const quizTest =
  //   courseResults &&
  //   courseResults?.quizeTests &&
  //   Array.isArray(courseResults.quizeTests) &&
  //   courseResults.quizeTests.length > 0
  //     ? courseResults.quizeTests.map((quizTestModule, key) => {
  //         return {
  //           name: `Chapter (${key + 1})`,
  //           questions: quizTestModule?.questions,
  //         }
  //       })
  //     : []

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Student Profile"
            breadcrumbItem={courseDetail?.courseName}
            navigate={-1}
          />
          <Row>
            {/* <Col lg={12}>
              <Card>
                <CardBody style={{ backgroundColor: "#f2f2f2" }}>
                  <div className="pt-3 px-2">
                    <h4 className="sun_heading">Quiz Test</h4>
                  </div>
                  {quizTest &&
                    Array.isArray(quizTest) &&
                    quizTest.length > 0 &&
                    quizTest.map((quiz, key) => {
                      return (
                        <div className="pt-3 px-2" key={key}>
                          <h4 className="sun_heading">{quiz.name}</h4>
                          <TableContainer
                            loading={loading}
                            data={quiz?.questions.map(item => {
                              const question = JSON.parse(item?.question)
                              return {
                                question: question?.question,
                                status:
                                  item?.isAnswer === question?.answer
                                    ? "right"
                                    : "wrong",
                                correctAnswer: `${item?.isAnswer} : ${
                                  question[item?.isAnswer]
                                }`,
                                userAnswer: `${question.answer} : ${
                                  question[question?.answer]
                                }`,
                              }
                            })}
                            isAddOptions={false}
                            isJobListGlobalFilter={false}
                            customPageSize={10}
                            showPageDropdown={false}
                            isGlobalFilter={false}
                            className="project-list-table table-nowrap align-middle table-borderless"
                            columns={courseResultColumns}
                          />
                        </div>
                      )
                    })}
                </CardBody>
              </Card>
            </Col> */}
            <Col lg={12}>
              <Card>
                <CardBody style={{ backgroundColor: "#f2f2f2" }}>
                  <div className="pt-3 px-2">
                    <h4 className="sun_heading">Certificate Test</h4>
                  </div>
                  <TableContainer
                    loading={loading}
                    data={userOptions}
                    isAddOptions={false}
                    isJobListGlobalFilter={false}
                    customPageSize={10}
                    showPageDropdown={false}
                    isGlobalFilter={false}
                    className="project-list-table table-nowrap align-middle table-borderless"
                    columns={courseResultColumns}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StudentCourseReport
