import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import { GET_HR_USERS, ADD_NEW_HR_USER, UPDATE_HR_USER } from "./actionTypes"
import {
  getHrUsersFail,
  getHrUsersSuccess,
  addhrUserFail,
  addhrUserSuccess,
  updateHrUserSuccess,
  updateHrUserFail,
  updatehrLoadingState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  registerHrUser,
  getAllHrUser,
  updateHrUser,
} from "helpers/api/companyApi"

function* fetchHrusers({ params }) {
  try {
    const { companyId, page, limit,search } = params
    console.log(companyId)
    const queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }`
    yield put(updatehrLoadingState(true))
    const response = yield call(getAllHrUser, companyId, queryParams)
    yield put(getHrUsersSuccess(response.users))
    yield put(updatehrLoadingState(false))
  } catch (error) {
    yield put(getHrUsersFail(error))
    yield put(updatehrLoadingState(false))
  }
}

function* onAddNewHrUser({ payload: { hrUser } }) {
  try {
    // yield put(updateStudentLoadingState(true))
    const response = yield call(registerHrUser, hrUser)
    toastr.success("New hr user Added Successfully..!!")
    yield put(addhrUserSuccess(response.data.user))
    // yield put(updateStudentLoadingState(false))
  } catch (error) {
    toastr.error(error.response.data.message)
    // yield put(updateStudentLoadingState(false))
    yield put(addhrUserFail(error))
  }
}

function* onUpdateHr({ payload: { hr, hrId, history } }) {
  try {
    const response = yield call(updateHrUser, hr, hrId)
    yield put(updateHrUserSuccess(response.data))
    toastr.success("hr updated successfully..!!")
    // setTimeout(() => {
    //   history("/students-list")
    // }, 2000)
  } catch (error) {
    yield put(updateHrUserFail(error))
    toastr.error(error.response.data.message)
  }
}

function* hrUsersSaga() {
  yield takeEvery(GET_HR_USERS, fetchHrusers)
  yield takeEvery(ADD_NEW_HR_USER, onAddNewHrUser)
  yield takeEvery(UPDATE_HR_USER, onUpdateHr)
}

export default hrUsersSaga
