export const UserColumns = [
  {
    header: "#",
    key: "",
  },
  // {
  //   header: "Image",
  //   name: "image",
  //   key: "IMAGE",
  //   link: "/student-details"
  // },
  {
    header: "Image",
    name: "image",
    key: "PROFILE_IMAGE",
    link: "/student-details",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Mobile Number",
    name: "phone",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Address",
    name: "address",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Total Chapter",
    name: "chapterCount",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Compleated Chapter",
    name: "courseCompletedCount",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Compleated %",
    name: "completedChapterPer",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
]

export const courseColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
    link: "/course-details",
  },
  {
    header: "Name",
    name: "courseName",
    key: "NAME_DESCRIPTION",
    link: "/course-details",
  },
  // {
  //   header: "Duration",
  //   name: "courseDuration",
  //   key: "DEFAULT",
  //   link: "/course-details",
  // },
  // {
  //   header: "Created Date",
  //   name: "createdAt",
  //   key: "DATE",
  //   link: "/course-details",
  // },
  {
    header: "Course Type",
    name: "type",
    key: "DEFAULT",
    link: "/course-details",
  },
  {
    header: "Price",
    name: "price",
    key: "AMOUNT",
    link: "/course-details",
  },
  {
    header: "Discount (%)",
    name: "discount",
    key: "DEFAULT",
    link: "/course-details",
  },
  {
    header: "Discounted Price",
    name: "discountPrice",
    key: "AMOUNT",
    link: "/course-details",
  },
  // {
  //   header: "Status",
  //   name: "status",
  //   key: "STATUS",
  // },
  {
    header: "Active/Deactive",
    key: "SWITCH",
  },
  // {
  //   header: "Action",
  //   key: "ACTIONS",
  //   link: "/course-details",
  // },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Edit", type: "EDIT" },
      { label: "View", type: "VIEW" },
      { label: "Delete", type: "DELETE" },
      { label: "View Subscription Users", type: "VIEW_STUDENT_LIST" },
    ],
  },
]

export const flashCardFields = [
  {
    name: "frontView",
    label: "Front Content",
    type: "text",
    placeholder: "Enter Front Content",
    required: true,
  },
  {
    name: "backView",
    label: "Back Content",
    type: "textarea",
    placeholder: "Enter Back Content",
    required: true,
  },
]

export const flashcardColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Front Content",
    name: "frontView",
    key: "DEFAULT",
  },
  {
    header: "Back Content",
    name: "backView",
    key: "DEFAULT",
  },
  {
    header: "Action",
    key: "ACTIONS",
  },
]

export const courseResultColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Question",
    name: "question",
    key: "DEFAULT",
  },
  {
    header: "Correct Answer",
    name: "correctAnswer",
    key: "DEFAULT",
  },
  {
    header: "User's Answer",
    name: "userAnswer",
    key: "DEFAULT",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
]

const currentOptions = [{ label: `0%`, value: 0 }]
const additionalOptions = Array.from({ length: 20 }, (_, index) => ({
  label: `${(index + 1) * 5}%`,
  value: (index + 1) * 5,
}))

export const discountOptions = [...currentOptions, ...additionalOptions]
