import React from 'react'
const staticURL = process.env.REACT_APP_STATIC_URL

function MediaLayoutGrid({ media, key, handleDeleteImage, handleCopyImageUrl, showDelete }) {
    const imgUrl = staticURL + media?.media
    return (
        <div key={key} className="mb-3 gallery-item position-relative">
            <div className="position-relative">
                <img
                    className="img-fluid"
                    src={imgUrl}
                    alt={`Media ${key}`} />
                <div className="image-details">
                    {showDelete &&
                        <button className="delete-button" onClick={() => handleDeleteImage(media?._id)} >Delete</button>
                    }
                    <button className="btn btn-primary" onClick={() => handleCopyImageUrl(imgUrl)}>Copy</button>
                    <p className="image-name">{media.mediaName}</p>
                </div>
            </div>
        </div>
    )
}

export default MediaLayoutGrid