import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Card, Row, Col, CardBody, Input } from "reactstrap"
import RDropdown from "react-dropdown"
import "react-dropdown/style.css"

import moment from "moment"
// Import image assets here
import experienceIcon from "../../assets/images/icons/experience.svg"
import salaryIcon from "../../assets/images/icons/salary.svg"
import onsiteIcon from "../../assets/images/icons/onSite.svg"
import locationIcon from "../../assets/images/icons/location.svg"
import applicantIcon from "../../assets/images/icons/applicant.svg"
import arrowRightWhite from "../../assets/images/icons/arrowRight.png"
import placeholder from "../../assets/images/users/img_placeholder.png"
import vacancyIcon from "../../assets/images/icons/vacancyIcon.svg"
// import DropdownMenu from "../../components/Common/DropdownMenu"

const ListItem = ({ job, handleActions, role }) => {
  const staticURL = process.env.REACT_APP_STATIC_URL
  const [showAllSkills, setShowAllSkills] = useState(false)
  const [drpData, setdrpData] = useState("")

  const authUserName = JSON.parse(localStorage.getItem("authUser"))?.name

  const handleDropdownChange = selectedOption => {
    handleActions(job, selectedOption.value)
    setdrpData("")
  }

  return (
    <div>
      {job && Object.keys(job).length && (
        <Card className="border-dark border-1">
          <CardBody>
            <div className="job_full_info">
              <Row className="job_info_inner">
                <Col lg={6} className="d-flex align-items-center">
                  <Link to={`/jobs-details/${job?._id}`}>
                    <img
                      src={job?.image ? staticURL + job?.image : placeholder}
                      width={60}
                      height={60}
                      alt="Job"
                    />
                  </Link>
                  <div>
                    <Link to={`/jobs-details/${job?._id}`}>
                      <h3 className="job_title ms-4">{job?.jobTitle}</h3>
                    </Link>
                    <p className="ms-4 mb-0 text-secondary">
                      <i className="mdi mdi-clock-outline me-2" />
                      {moment(job?.createdAt).fromNow()}
                    </p>
                    {["company", "hr_user"].includes(role) && (
                      <div className="additional_info ms-4">
                        <p className="mb-0 additional_info_name fw-normal">
                          Post By :{" "}
                          {job?.postAutherDetail?.name === authUserName
                            ? "You"
                            : job?.postAutherDetail?.name}
                        </p>
                      </div>
                    )}
                    {["admin"].includes(role) && (
                      <div className="additional_info ms-4">
                        <p className="mb-0 additional_info_name fw-normal">
                          Post By : {job?.postAutherDetail?.name}
                        </p>
                      </div>
                    )}
                  </div>
                </Col>
                {["company", "hr_user", "admin"].includes(role) && (
                  <Col
                    lg={6}
                    className="d-flex align-items-center justify-content-end"
                  >
                    {"admin" === role && (
                      <Link to={`/jobs-details/${job?._id}`}>
                        <h3 className="job_title ms-2 me-2">
                          {job?.companyDetail?.name}
                        </h3>
                      </Link>
                    )}
                    {job?.deletedAt == null && (
                      <>
                        <div className="form-check form-switch form-switch-md text-center d-flex align-items-center justify-content-end">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: "16px", width: "28px" }}
                            defaultChecked={job?.status == "active"}
                            onChange={() => {
                              handleActions(job, "STATUS")
                            }}
                          />
                        </div>
                        <Link
                          className="btn_apply_now font-size-12 me-0"
                          to={`/applicant-list/${job?.companyId}/${job?._id}`}
                        >
                          View Applicant ({job?.applicantsCount || "0"})
                        </Link>
                        {"admin" !== role && (
                          <RDropdown
                            options={[
                              { label: "Edit", value: "EDIT", type: "EDIT" },
                              { label: "View", value: "VIEW", type: "VIEW" },
                              {
                                label: "Delete",
                                value: "DELETE",
                                type: "DELETE",
                              },
                            ]}
                            onChange={handleDropdownChange}
                            value={drpData}
                            controlClassName="custom-dropdown-control"
                            menuClassName="custom-dropdown-menu"
                            placeholder={
                              <i className="mdi mdi-dots-vertical font-size-18" />
                            }
                            arrowOpen={
                              <i className="mdi mdi-dots-vertical font-size-18" />
                            }
                            arrowClosed={
                              <i className="mdi mdi-dots-vertical font-size-18" />
                            }
                          />
                        )}
                      </>
                    )}
                  </Col>
                )}
              </Row>
              <div
                className="job_description"
                dangerouslySetInnerHTML={{
                  __html: job?.description.trim().substring(0, 5000),
                }}
              ></div>

              <div className="d-flex d-flex">
                <h3 className="me-2" style={{ fontSize: "18px" }}>
                  Tags :
                </h3>
                {job && job?.skillIds && job?.skillIds.length > 0 && (
                  <>
                    {showAllSkills ? (
                      <>
                        {job?.skillIds
                          .filter(s => s !== null)
                          .map((skill, index) => (
                            <span key={index} className="skill_tag">
                              {skill?.name}
                            </span>
                          ))}
                        {job?.skillIds.length > 6 && (
                          <span
                            className="text-primary cursor-pointer"
                            onClick={() => setShowAllSkills(false)}
                            style={{ cursor: "pointer" }}
                          >
                            See Less
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {job?.skillIds
                          .filter(s => s !== null)
                          .slice(0, 6)
                          .map((skill, index) => (
                            <span key={index} className="skill_tag">
                              {skill?.name}
                            </span>
                          ))}
                        {job?.skillIds.length > 6 && (
                          <span
                            className="text-primary cursor-pointer"
                            onClick={() => setShowAllSkills(true)}
                            style={{ cursor: "pointer" }}
                          >
                            See More
                          </span>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="jobs_additional_info">
                <div className="additional_info">
                  <img src={onsiteIcon} width={35} height={35} alt="On Site" />
                  <p className="mb-0 additional_info_name">{job?.jobType}</p>
                </div>
                <div className="additional_info">
                  <img src={onsiteIcon} width={35} height={35} alt="On Site" />
                  <p className="mb-0 additional_info_name">{job?.type}</p>
                </div>
                <div className="additional_info">
                  <img src={salaryIcon} width={35} height={35} alt="Salary" />
                  <p className="mb-0 additional_info_name">
                    {job?.salaryRange}
                  </p>
                </div>
                <div className="additional_info">
                  <img
                    src={experienceIcon}
                    width={35}
                    height={35}
                    alt="Experience"
                  />
                  <p className="mb-0 additional_info_name">
                    {job?.experience}-yr
                  </p>
                </div>

                <div className="additional_info">
                  <img
                    src={locationIcon}
                    width={35}
                    height={35}
                    alt="Location"
                  />
                  <p className="mb-0 additional_info_name">{job?.location}</p>
                </div>
                <div className="additional_info">
                  <img
                    src={vacancyIcon}
                    width={35}
                    height={35}
                    alt="No Of Vacancy"
                  />
                  <p className="mb-0 additional_info_name">
                    {job?.noOfVacancy}
                  </p>
                </div>
              </div>
              <div className="row bg-light mt-3 p-2">
                <div className="col">
                  <b className="mb-0">Applicants Status : </b>
                </div>
                <div className="col">
                  <p className="mb-0 text-warning">
                    Under Review : {job?.reviewed}{" "}
                  </p>
                </div>
                <div className="col">
                  <p className="mb-0 text-warning">
                    Awaiting for Review : {job?.awaitingReview}
                  </p>
                </div>
                <div className="col">
                  <p className="mb-0 text-danger">
                    Hold : {job?.hold ? job?.hold : 0}{" "}
                  </p>
                </div>
                <div className="col">
                  <p className="mb-0 text-danger">
                    Rejected : {job?.rejected}{" "}
                  </p>
                </div>
                <div className="col">
                  <p className="mb-0 text-success">
                    Shortlist : {job?.shortlist}
                  </p>
                </div>
                <div className="col">
                  <p className="mb-0 text-success">Hired : {job?.hired}</p>
                </div>
              </div>
              <div className="d-flex justify-content-end pt-2">
                {["company", "hr_user"].includes(role) && (
                  <div className="additional_info">
                    <p className="mb-0 additional_info_name fw-normal">
                      Post By :{" "}
                      {job?.postAutherDetail?.name === authUserName
                        ? "You"
                        : job?.postAutherDetail?.name}
                    </p>
                  </div>
                )}
                {["admin"].includes(role) && (
                  <div className="additional_info">
                    <p className="mb-0 additional_info_name fw-normal">
                      Post By : {job?.postAutherDetail?.name}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  )
}

export default ListItem
