import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import {
  Badge,
  UncontrolledTooltip,
  Tooltip,
  Input,
  Label,
  Dropdown as DropdownBox,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"

import RDropdown from "react-dropdown"
import "react-dropdown/style.css"

import Avatar from "../../../assets/images/users/img_placeholder.png"
import DownloadIcon from "../../../assets/images/icons/downloadIcon.png"
import ImageProfile from "../ImageProfile"
import AppRating from "../AppRating"

const staticURL = process.env.REACT_APP_STATIC_URL

const handleValidDate = date => {
  const date1 = moment(new Date(date)).format("DD MMM Y")
  return date1
}

const handleValidDateAndTime = date => {
  const date1 = moment(new Date(date)).format("DD MMM Y, h:mm A")
  return date1
}

const Count = props => {
  const calculateStartingCount = (pageNo, rowsPerPage) => {
    return (pageNo - 1) * rowsPerPage + 1
  }

  return <p>{calculateStartingCount(props.pageNo, props.rowsPerPage)}</p>
}

const Image = cellProps => {
  const element = (
    <div className="d-flex align-items-center">
      {!cellProps.cell.row.original.image ? (
        <div>
          <img
            src={Avatar}
            alt={"qapreneur"}
            height="47"
            width="59"
            className="tr_img"
          />
        </div>
      ) : (
        <div>
          <img
            className="avatar-sm tr_img"
            src={staticURL + cellProps.cell.row.original.image}
            alt={"qapreneur"}
          />
        </div>
      )}
    </div>
  )
  const _id = cellProps?.defaultId
    ? cellProps.row.original[cellProps?.defaultId]
    : cellProps.row.original._id
  return cellProps.rowLink ? (
    <Link to={`${cellProps.rowLink}/${_id}`}>{element}</Link>
  ) : (
    element
  )
}

const ProfileImage = cellProps => {
  const element = (
    <div className="d-flex align-items-center">
      {!cellProps.cell.row.original.image ? (
        <ImageProfile showFrame={""} imageUrl={Avatar} />
      ) : (
        <ImageProfile
          showFrame={
            cellProps.cell.row.original?.userProfile
              ? cellProps.cell.row.original?.userProfile
              : ""
          }
          imageUrl={staticURL + cellProps.cell.row.original.image}
        />
      )}
    </div>
  )
  const _id = cellProps?.defaultId
    ? cellProps.row.original[cellProps?.defaultId]
    : cellProps.row.original._id
  return (
    <>
      {cellProps.rowLink ? (
        <Link to={`${cellProps.rowLink}/${_id}`}>{element}</Link>
      ) : (
        element
      )}
    </>
  )
}

const ImageWithRating = cellProps => {
  const isUnlock = cellProps.cell.row.original?.isUnlock
    ? cellProps.cell.row.original?.isUnlock
    : false
  const element = (
    <div className="d-flex align-items-center gap-1">
      {!cellProps.cell.row.original.image ? (
        <ImageProfile showFrame={""} imageUrl={Avatar} size="sm" />
      ) : (
        <ImageProfile
          showFrame={
            cellProps.cell.row.original?.image
              ? cellProps.cell.row.original?.image
              : ""
          }
          imageUrl={staticURL + cellProps.cell.row.original.image}
          size="sm"
          width={50}
          height={50}
        />
      )}
      <div className="ms-2 d-flex flex-column align-items-center justify-content-center">
        <span className="text-bold text-capitalize fw-bolder ms-0 ps-0 fc-prime">
          {cellProps.cell.row.original.name}
        </span>
        <AppRating
          userMockTestMarks={cellProps.cell.row.original.userMockTestMarks}
        />
      </div>
    </div>
  )
  const _id = cellProps?.defaultId
    ? cellProps.row.original[cellProps?.defaultId]
    : cellProps.row.original._id
  return (
    <>
      {cellProps.rowLink && isUnlock ? (
        <Link to={`${cellProps.rowLink}/${_id}`}>{element}</Link>
      ) : (
        element
      )}
    </>
  )
}

const Name = cellProps => {
  const element = (
    <div className="d-flex flex-column">
      <div className="list_title_name text-dark">{cellProps?.cell.value}</div>
      {cellProps?.cell?.row?.original?.description && (
        <div
          className="text-muted"
          dangerouslySetInnerHTML={{
            __html: cellProps?.cell?.row?.original?.description.substring(
              0,
              50
            ),
          }}
        ></div>
      )}
    </div>
  )
  const _id = cellProps?.defaultId
    ? cellProps.row.original[cellProps?.defaultId]
    : cellProps.row.original._id

  return (
    <>
      {cellProps.rowLink ? (
        <Link to={`${cellProps.rowLink}/${_id}`}>{element}</Link>
      ) : (
        element
      )}
    </>
  )
}

const Description = cellProps => {
  const element = cellProps?.cell?.row?.original?.description && (
    <div
      className="text-muted"
      dangerouslySetInnerHTML={{
        __html: cellProps?.cell?.row?.original?.description,
      }}
    ></div>
  )
  const _id = cellProps?.defaultId
    ? cellProps.row.original[cellProps?.defaultId]
    : cellProps.row.original._id

  return (
    <>
      {cellProps.rowLink ? (
        <Link to={`${cellProps.rowLink}/${_id}`}>{element}</Link>
      ) : (
        element
      )}
    </>
  )
}

const DefaultColumn = cellProps => {
  const value =
    cellProps?.cell.value || cellProps?.cell.value === 0
      ? cellProps?.cell.value
      : "-"
  const element = <span className="fc-prime fw-bold ">{value}</span>
  const _id = cellProps?.defaultId
    ? cellProps.row.original[cellProps?.defaultId]
    : cellProps.row.original._id
  return cellProps.rowLink && cellProps?.cell.value ? (
    <div className="fw-bold d-flex align-items-center fc-prime">
      <Link to={`${cellProps.rowLink}/${_id}`}>{element}</Link>
    </div>
  ) : (
    element
  )
}

const NameAndTitle = cellProps => {
  const value =
    cellProps?.cell.value || cellProps?.cell.value === 0
      ? cellProps?.cell.value
      : ""
  const title =
    cellProps?.row?.original?.profileTitle ||
    cellProps?.row?.original?.profileTitle === 0
      ? cellProps?.row?.original?.profileTitle
      : "-"

  const element = (
    <div className="d-flex flex-column">
      <span className="fc-prime fw-bold ">{value}</span>
      <span className="fc-prime fw-normal ">{title}</span>
    </div>
  )
  const _id = cellProps?.defaultId
    ? cellProps.row.original[cellProps?.defaultId]
    : cellProps.row.original._id
  return cellProps.rowLink && cellProps?.cell.value ? (
    <div className="fw-bold d-flex align-items-center fc-prime">
      <Link to={`${cellProps.rowLink}/${_id}`}>{element}</Link>
    </div>
  ) : (
    element
  )
}

const AmountColumn = cellProps => {
  const value =
    cellProps?.cell.value || cellProps?.cell.value === 0
      ? cellProps?.cell.value
      : "-"
  return (
    <div
      className="fw-bold d-flex align-items-center fc-prime"
      style={cellProps?.customStyle ? { ...cellProps?.customStyle } : {}} // Correctly spreading the custom styles
    >
      <span className="mdi mdi-currency-inr"></span>
      <span>{value}</span>/-
    </div>
  )
}

const LeadsColumn = cellProps => {
  const value =
    cellProps?.cell.value || cellProps?.cell.value === 0
      ? cellProps?.cell.value
      : "-"
  return (
    <div className="fw-bold d-flex align-items-center fc-prime">
      <span
        className="mdi mdi-check-circle me-1"
        style={{ color: "#1AB69D", fontSize: "15px" }}
      ></span>
      <span>{value}</span>
    </div>
  )
}

const UnlockedInfo = ({ cell, handleUnlock }) => {
  return (
    <div className="d-flex flex-column  justify-content-center">
      {cell.row.original?.isUnlock ? (
        <>
          <span className="text-muted fw-normal d-flex  align-items-center">
            <i className="mdi mdi-email-outline font-size-15 me-2" />
            {cell.row.original?.email || "-"}
          </span>
          <span className="text-muted fw-normal d-flex  align-items-center">
            <i className="mdi mdi-phone-outline font-size-15 me-2" />
            {cell.row.original?.phone || "-"}
          </span>
        </>
      ) : (
        <button
          className="fw-bold d-flex justify-content-center align-items-center p-2 rounded-pill"
          style={{
            backgroundColor: "#f8e7dd",
            fontSize: "11px",
            width: "130px",
            border: "none",
            borderColor: "gray",
            borderSize: "3px",
            borderStyle: "solid",
          }}
          onClick={() => handleUnlock(cell.row.original)}
        >
          Unlock Candidate
        </button>
      )}
    </div>
  )
}

const SkillsColumn = cellProps => {
  const [showAllSkills, setShowAllSkills] = useState(false)
  const applicant = cellProps.cell.row.original
  const _id = cellProps?.defaultId
    ? cellProps.row.original[cellProps?.defaultId]
    : cellProps.row.original._id
  const isUnlock = cellProps.cell.row.original?.isUnlock
    ? cellProps.cell.row.original?.isUnlock
    : false
  const element = applicant?.skills?.length > 3 && (
    <span
      className="candidate_skill cursor-pointer"
      onClick={() => setShowAllSkills(true)}
      style={{ cursor: "pointer" }}
    >
      +3 More
    </span>
  )
  return (
    <div>
      {applicant && applicant?.skills && applicant?.skills.length > 0 && (
        <>
          <div className="d-flex">
            {applicant?.skills
              .filter(s => s !== null)
              .slice(0, 3)
              .map((skill, index) => (
                <span key={index} className="candidate_skill">
                  {skill?.name}
                </span>
              ))}
            {isUnlock ? (
              <Link to={`${cellProps.rowLink}/${_id}`}>{element}</Link>
            ) : (
              element
            )}
          </div>
        </>
      )}
      {/* {applicant && applicant?.skills && applicant?.skills.length > 0 && (
        <>
          {showAllSkills ? (
            <div className="d-flex">
              {applicant?.skills
                .filter(s => s !== null)
                .map((skill, index) => (
                  <span key={index} className="candidate_skill">
                    {skill?.name}
                  </span>
                ))}
              {applicant.skills.length > 3 && (
                <span
                  className="candidate_skill cursor-pointer"
                  onClick={() => setShowAllSkills(false)}
                  style={{ cursor: "pointer" }}
                >
                  See Less
                </span>
              )}
            </div>
          ) : (
            <div className="d-flex">
              {applicant?.skills
                .filter(s => s !== null)
                .slice(0, 3)
                .map((skill, index) => (
                  <span key={index} className="candidate_skill">
                    {skill?.name}
                  </span>
                ))}
              {applicant.skills.length > 2 && (
                <span
                  className="candidate_skill cursor-pointer"
                  onClick={() => setShowAllSkills(true)}
                  style={{ cursor: "pointer" }}
                >
                  +3 More
                </span>
              )}
            </div>
          )}
        </>
      )} */}
    </div>
  )
}

const CategoryColumn = cellProps => {
  const [showAllCategory, setShowAllCategory] = useState(false)
  return (
    <div className="skills-grid">
      {cellProps?.cell?.value &&
        Array.isArray(cellProps?.cell.value) &&
        cellProps?.cell.value.length > 0 && (
          <>
            {showAllCategory ? (
              <>
                {cellProps?.cell.value
                  .filter(s => s !== null)
                  .map((item, index) => (
                    <span key={index} className="candidate_skill">
                      {item?.type}
                    </span>
                  ))}
                {cellProps?.cell?.value?.length > 2 && (
                  <span
                    className="candidate_skill cursor-pointer"
                    onClick={() => setShowAllCategory(false)}
                    style={{ cursor: "pointer" }}
                  >
                    See Less
                  </span>
                )}
              </>
            ) : (
              <>
                {cellProps?.cell?.value
                  .filter(s => s !== null)
                  .slice(0, 2)
                  .map((item, index) => (
                    <span key={index} className="candidate_skill">
                      {item?.type}
                    </span>
                  ))}
                {cellProps?.cell?.value?.length > 2 && (
                  <span
                    className="candidate_skill cursor-pointer"
                    onClick={() => setShowAllCategory(true)}
                    style={{ cursor: "pointer" }}
                  >
                    +2 More
                  </span>
                )}
              </>
            )}
          </>
        )}
    </div>
  )
}

const JobInfo = cellProps => {
  const jobCount = cellProps.cell.row.original.jobCount
  const _id = cellProps?.defaultId
    ? cellProps.row.original[cellProps?.defaultId]
    : cellProps.row.original._id
  const element = (
    <div className="d-flex align-items-center">
      {jobCount > 0 ? (
        <Link to={`${cellProps.rowLink}/${_id}`} className="text-muted">
          <span className="mdi mdi-briefcase-outline me-2 fs-5"></span>
          <span>{jobCount} Job Applied</span>
        </Link>
      ) : (
        <>
          <span className="mdi mdi-close-circle-outline me-2 fs-5"></span>
          <span>No Job Applied</span>
        </>
      )}
    </div>
  )

  return element
}

const DateFormate = cell => {
  return cell.value ? handleValidDate(cell.value) : ""
}
const DateAndTimeFormate = cell => {
  return (
    <span className="fw-bold fc-prime">
      {cell.value ? handleValidDateAndTime(cell.value) : ""}
    </span>
  )
}

const Status = cell => {
  switch (cell.value) {
    case "active":
      return <Badge className="badge_active">Active</Badge>
    case "deactive":
      return <Badge className="badge_deactive">Deactive</Badge>
    case "unverified":
      return <Badge className="badge_unverified">Unverified</Badge>
    case "requested":
      return <Badge className="bg-warning  badge_requested">Requested</Badge>
    case "rejected":
      return <Badge className="bg-danger">Rejected</Badge>
    case "shortlist":
      return <Badge className="bg-success">Shortlist</Badge>
    case "Fail":
      return <Badge className="bg-danger">Fail</Badge>
    case "Pass":
      return <Badge className="bg-success">Pass</Badge>
    case "success":
      return <Badge className="bg-success">Success</Badge>
    case "completed":
      return <Badge className="bg-success">Completed</Badge>
    case "accepted":
      return <Badge className="bg-success">Accepted</Badge>
    case "progress":
      return <Badge className="bg-warning">Progress</Badge>
    case "inprogress":
      return <Badge className="bg-warning">In Progress</Badge>
    case "pending":
      return <Badge className="bg-warning">Pending</Badge>
    case "awaitingReview":
      return <Badge className="bg-warning">Under Review</Badge>
    case "notReviewed":
    case "awaitingReview":
      return <Badge className="bg-warning">Under Review</Badge>
    case "reviewed":
      return <Badge className="bg-success">Reviewed</Badge>
    case "hired":
      return <Badge className="bg-warning">Hired</Badge>
    case "hold":
      return <Badge className="bg-light">Hold</Badge>
    case "faild":
      return <span className="bg-second badge">Failed</span>
    case "right":
      return <Badge className="bg-success">Right</Badge>
    case "wrong":
      return <Badge className="bg-danger">Wrong</Badge>
    case "notAttempted":
      return <Badge className="bg-warning">Skipped</Badge>
    default:
      return null // Handle other cases or return null if needed
  }
}

const StatusChangeToggle = props => {
  const { cellProps, handleChangeStatus, CASE = "" } = props
  const data = cellProps.row.original
  return (
    <div className="form-check form-switch form-switch-md text-center d-flex justify-content-center">
      <Label className="form-check-label" for={data._id}></Label>
      <Input
        type="checkbox"
        className="form-check-input"
        id={data._id}
        defaultChecked={data.status === "active"}
        onChange={() => {
          handleChangeStatus(data, CASE)
        }}
        // disabled={data.status === "unverified"}
      />
    </div>
  )
}

const VerifyUserToggle = props => {
  const { cellProps, handleChangeStatus, CASE = "", name } = props
  const data = cellProps.row.original
  return (
    <div className="form-check form-switch form-switch-md text-center d-flex justify-content-center">
      <Label className="form-check-label" for={data._id}></Label>
      <Input
        type="checkbox"
        className="form-check-input2"
        id={data._id}
        defaultChecked={data[name] === "active"}
        onChange={() => {
          handleChangeStatus(data, CASE)
        }}
      />
    </div>
  )
}

const Action = ({
  cellProps,
  handleDelete,
  handleView,
  handleEdit,
  rowIndex,
}) => {
  const data = cellProps.row.original
  return (
    <div className="d-flex gap-3 action_container">
      {handleEdit ? (
        <span
          className="text-primary"
          onClick={() => {
            handleEdit(data, rowIndex)
          }}
        >
          <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
          <UncontrolledTooltip placement="top" target="edittooltip">
            Edit
          </UncontrolledTooltip>
        </span>
      ) : (
        ""
      )}
      {handleView ? (
        <span
          className="text-gray"
          onClick={() => {
            handleView(data, rowIndex)
          }}
        >
          <i className="mdi mdi-eye-outline font-size-18" id="viewTooltip" />
          <UncontrolledTooltip placement="top" target="viewTooltip">
            View
          </UncontrolledTooltip>
        </span>
      ) : (
        ""
      )}
      {handleDelete ? (
        <span
          className="text-danger"
          onClick={() => {
            handleDelete(data, rowIndex)
          }}
        >
          <i
            className="mdi mdi-trash-can-outline font-size-18"
            id="deleteTooltip"
          />
          <UncontrolledTooltip placement="top" target="deleteTooltip">
            Delete
          </UncontrolledTooltip>
        </span>
      ) : (
        ""
      )}
    </div>
  )
}

const ViewAction = ({ cellProps, handleView }) => {
  const data = cellProps.row.original
  return (
    <div className="d-flex gap-3">
      <span
        className="text-success"
        onClick={() => {
          handleView(data)
        }}
      >
        <i className="mdi mdi-eye font-size-18" id="edittooltip" />
        <UncontrolledTooltip placement="top" target="edittooltip">
          View
        </UncontrolledTooltip>
      </span>
    </div>
  )
}

const MoreActions = ({ cellProps, dropDownOptions, handleActions }) => {
  const data = cellProps.row.original
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)
  const [drpData, setdrpData] = useState("")

  const { _id } = data

  const handleDropdownChange = selectedOption => {
    handleActions(data, selectedOption.value)
    setdrpData("")
  }

  const dropdownOptions = dropDownOptions.map(o => ({
    value: o.type,
    label: o.label,
  }))

  return (
    <div>
      {/* <DropdownBox isOpen={dropdownOpen} toggle={toggle} direction="down">
        <DropdownToggle
          style={{
            background: "none",
            color: "gray",
            padding: "0",
            border: "none",
            outline: "none",
          }}
          caret
        >
          <Button className="me-1" color="secondary" id={"Tooltip-" + _id}>
            <i className="mdi mdi-dots-vertical font-size-18" />
          </Button>
        </DropdownToggle>
        <DropdownMenu>
          {dropDownOptions.map((o, k) => {
            return (
              <div key={k}>
                <DropdownItem href="" onClick={() => {
                  handleActions(data, o.type)
                }}>
                  {o.label}
                </DropdownItem>
              </div>
            )
          })}
        </DropdownMenu>
      </DropdownBox> */}
      {/* 
      <div className="d-flex gap-3 relative">
        <Select
          options={dropDownOptions.map(option => ({
            value: option.type,
            label: option.label,
          }))}
          onChange={selectedOption =>
            handleActions(data, selectedOption[0].value)
          }
          placeholder={<i className="mdi mdi-dots-vertical font-size-18" />}
        />
      </div> */}
      <RDropdown
        options={dropdownOptions}
        onChange={handleDropdownChange}
        value={drpData}
        controlClassName="custom-dropdown-control"
        menuClassName="custom-dropdown-menu"
        placeholder={<i className="mdi mdi-dots-vertical font-size-18" />}
        arrowOpen={<i className="mdi mdi-dots-vertical font-size-18" />}
        arrowClosed={<i className="mdi mdi-dots-vertical font-size-18" />}
      />
    </div>
  )
}

const Dropdown = cell => {
  const { options, name, handleDropDown, condition } = cell
  const data = cell.row.original
  const [border, setBorder] = useState("")
  useEffect(() => {
    borderColor()
  }, [])
  const borderColor = () => {
    if (data[name] === "rejected") {
      setBorder("border-danger")
    } else if (data[name] === "notReviewed") {
      setBorder("border-danger")
    } else if (
      data[name] === "completed" ||
      data[name] === "shortlist" ||
      data[name] === "reviewed"
    ) {
      setBorder("border-success")
    } else if (
      data[name] === "inprogress" ||
      data[name] === "pending" ||
      data[name] === "awaitingReview" ||
      data[name] === "hired"
    ) {
      setBorder("border-warning")
    } else if (data[name] === "requested" || data[name] === "contacting") {
      setBorder("border-primary")
    }
  }
  return (
    <div>
      <Input
        id="exampleSelect"
        name={name}
        type="select"
        className={`border ${border}`}
        value={data[name]}
        onChange={e => {
          handleDropDown(e, data)
        }}
        disabled={condition.disabled.includes(data[name])}
      >
        {options.map((o, k) => {
          return (
            <option key={k} value={o.value}>
              {o.label}
            </option>
          )
        })}
      </Input>
    </div>
  )
}

const InvoiceColumn = ({ cell, handleInvoice, type }) => {
  const data = cell.row.original
  return (
    <div className="d-flex justify-content-center align-items-center">
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleInvoice(data, type)
        }}
      >
        <img src={DownloadIcon} width={18} height={18} />
      </span>
    </div>
  )
}

const BuyButton = ({ cell, handleBuy }) => {
  const data = cell?.row?.original
  return (
    <div className="d-flex gap-3">
      <Button
        onClick={() => {
          handleBuy(data)
        }}
        style={{backgroundColor:'#1AB69D',border:'none',outline:'none'}}
      >
        <span className="mdi mdi-plus-circle-outline me-2"></span>
        Buy
      </Button>
    </div>
  )
}

export {
  Image,
  ProfileImage,
  ImageWithRating,
  Name,
  Description,
  DefaultColumn,
  NameAndTitle,
  AmountColumn,
  LeadsColumn,
  DateFormate,
  DateAndTimeFormate,
  Status,
  Action,
  StatusChangeToggle,
  VerifyUserToggle,
  ViewAction,
  Dropdown,
  MoreActions,
  Count,
  UnlockedInfo,
  SkillsColumn,
  CategoryColumn,
  JobInfo,
  InvoiceColumn,
  BuyButton,
}
