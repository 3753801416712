import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = ({ user, type, data, authRole = "" }) => {
  const staticURL = process.env.REACT_APP_STATIC_URL
  return (
    <React.Fragment>
      <Card className="overflow-hidden" style={{ backgroundColor: "#202f5c" }}>
        <Row>
          <Col>
            <Row className="pt-4 pb-4 ps-3 pe-3">
              <Col
                xs="2"
                className="d-flex align-items-start justify-content-center"
              >
                <div className="avatar-md" style={{ marginTop: -3 }}>
                  <img
                    src={user ? staticURL + user?.image : avatar1}
                    alt={user ? staticURL + user?.image : avatar1}
                    className="img-thumbnail-border-none rounded-circle"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div>
              </Col>
              <Col
                xs="6"
                className="d-flex flex-column align-items- start justify-content-center ps-0"
              >
                <h5 className="text-light text-uppercase fs-6 m-0">
                  {user ? user?.name : "Henry Price"}
                </h5>
                <p className="fs-6 m-0" style={{ color: "#f89752" }}>
                  {user ? user?.email : "Henry Price"}
                </p>
                <Link
                  to={user ? user?.extra?.website : ""}
                  style={{ color: "#f89752" }}
                  target="_blank"
                >
                  {user ? user?.website : "example.com"}
                  {/* <span className="mdi mdi-open-in-new m-0 ms-2"></span> */}
                </Link>
                <div className="mt-4">
                  {type === "company" ? (
                    <Link
                      to={`/profile/view/${authRole}/${user?._id}`}
                      className="btn-sm text-light p-2"
                      style={{ backgroundColor: "#e39a64" }}
                    >
                      View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link>
                  ) : (
                    <Link to="" className="btn btn-primary  btn-sm">
                      View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link>
                  )}
                </div>
              </Col>
              <Col xs={4} className="position-relative">
                <img
                  src={profileImg}
                  alt={"qapreneur"}
                  className="img-fluid position-absolute end-0"
                  style={{ bottom: "-20px" }}
                />{" "}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#253465" }}>
          <Col>
            {data && data?.projectTitle === "company" && (
              <Row className="pt-4 pb-4">
                <Col className="d-flex align-items-start justify-content-center mt-5">
                  <div
                    className="avatar-sm  rounded-circle d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: "#e0f1f0" }}
                  >
                    <span
                      className="mdi mdi-book-open-variant fs-4"
                      style={{ color: "#1AB69D" }}
                    ></span>
                  </div>
                  <div className="ms-2 text-light">
                    <h5 className="fs-6">
                      {data?.remainingJobCount ? data?.remainingJobCount : 0}
                    </h5>
                    <p className="mb-0 text-light">{data?.remainingJobTitle}</p>
                  </div>
                </Col>
                <Col className="d-flex align-items-start justify-content-center mt-5">
                  <div
                    className="avatar-sm  rounded-circle d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: "#e0f1f0" }}
                  >
                    <span
                      className="mdi mdi-book-open-variant fs-4"
                      style={{ color: "#1AB69D" }}
                    ></span>
                  </div>
                  <div className="ms-2 text-light">
                    <h5 className="fs-6 text-light">
                      {data?.remainingLeadCount ? data?.remainingLeadCount : 0}
                    </h5>
                    <p className="mb-0 text-light">
                      {data?.remainingLeadTitle}
                    </p>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
