import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import TableContainer from "../../components/Common/Table/index"
import { transactColumns } from "./constants"
import { useDispatch } from "react-redux"
import { getUserTabData as onGetUserTabData } from "../../store/students/actions"

function UserTransactions({ transactions=[], loading, userId, handleActions }) {
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [data, setData] = useState([])
  const tabId = "TRANSACTIONS"
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      transactions &&
      Array.isArray(transactions) &&
      transactions.length > 0
    ) {
      setData(transactions)
    }
  }, [transactions])

  const onChangePagination = (limit, page) => {
    setRowPerPage(rowPerPage)
    setPageNo(page)
    if (userId) {
      dispatch(onGetUserTabData(tabId, userId, `&page=${page}&limit=${limit}`))
    }
  }

  return (
    <>
        <Card style={{ borderRadius: 15 }}>
          <CardBody>
            <div className="pt-3">
              <div className="d-flex justify-content-between">
                <h4 className="sun_heading">Transaction History</h4>
              </div>
              {transactions && Array.isArray(transactions) ? (
                <TableContainer
                  loading={loading}
                  data={data}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  showPageDropdown={false}
                  customPageSize={10}
                  handleActions={handleActions}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={transactColumns}
                  tableName="viewStudent"
                  showPagination={true}
                  onChangePagination={onChangePagination}
                />
              ) : (
                "No Transaction Available"
              )}
            </div>
          </CardBody>
        </Card>
    </>
  )
}

export default UserTransactions
