

export const columns = [
  {
    header: "#",
    key: ""
  },
  {
    header: "College Name",
    name: "name",
    key: "DEFAULT"
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT"
  },
  {
    header: "Contact Number",
    name: "contactNumber",
    key: "DEFAULT"
  },
  {
    header: "Contact Name",
    name: "contactPersonName",
    key: "DEFAULT"
  },
  {
    header: "State",
    name: "state",
    key: "DEFAULT"
  },
  {
    header: "City",
    name: "city",
    key: "DEFAULT"
  },
  {
    header: "Added By",
    name: "userName",
    key: "DEFAULT",
  }
]
