import { del, get, post, patch } from "../api_helper"
import { NOTIFICATION_ROUTES } from "../url_helper"

// dynamic search

export const createNotification = notification =>
  post(`${NOTIFICATION_ROUTES}`, notification, {
    headers: { "Content-Type": "application/json" },
  })

export const updateNotification = (notification, notificationId) =>
  patch(`${NOTIFICATION_ROUTES}/${notificationId}`, notification, {
    headers: { "Content-Type": "application/json" },
  })

export const getNotificationDetails = notificationId =>
  get(`${NOTIFICATION_ROUTES}/${notificationId}`, {
    headers: { "Content-Type": "application/json" },
  })

export const deleteNotification = notificationId =>
  del(`${NOTIFICATION_ROUTES}/${notificationId}`, {
    headers: { "Content-Type": "application/json" },
  })

export const getNotifications = queryParams =>
  get(`${NOTIFICATION_ROUTES}${queryParams}`)

export const getNotificationReceivers = (notificationId, queryParams) =>
  get(
    `${NOTIFICATION_ROUTES}/report/${notificationId}/${queryParams}`
  )
