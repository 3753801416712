import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import TableContainer from "../../components/Common/Table/index"
import { mockTestRequestsColumns } from "./constants"
import { useDispatch } from "react-redux"
import { getUserTabData as onGetUserTabData } from "../../store/students/actions"

function MockInterViewResults({
  userMockTestRequests=[],
  loading,
  userId,
  role = "",
}) {
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [data, setData] = useState([])
  const tabId = "MOCKINTERVIEWDETAILS"
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      userMockTestRequests &&
      Array.isArray(userMockTestRequests) &&
      userMockTestRequests.length > 0
    ) {
      const userMockTests = userMockTestRequests.map(item => ({
        ...item,
        courseName: item?.courseDetail?.courseName,
      }))
      setData(
        role === "admin"
          ? userMockTests
          : userMockTests.filter(item => item?.visibility === 1)
      )
    }
  }, [userMockTestRequests])

  const onChangePagination = (limit, page) => {
    setRowPerPage(rowPerPage)
    setPageNo(page)
    if (userId) {
      dispatch(onGetUserTabData(tabId, userId, `&page=${page}&limit=${limit}`))
    }
  }

  return (
    <>
      <Card style={{ borderRadius: 15 }}>
        <CardBody>
          <div className="pt-3">
            <h4 className="sun_heading">Mock Interview Detail</h4>
            {userMockTestRequests && Array.isArray(userMockTestRequests) ? (
              <TableContainer
                loading={loading}
                data={data}
                isGlobalFilter={false}
                isAddOptions={false}
                isJobListGlobalFilter={false}
                showPageDropdown={false}
                customPageSize={10}
                className="project-list-table table-nowrap align-middle table-borderless"
                columns={mockTestRequestsColumns}
                tableName="viewStudent"
                showPagination={true}
                onChangePagination={onChangePagination}
              />
            ) : (
              "No Mock Interview Detail Available"
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default MockInterViewResults
