import React from "react"
import { Card, CardBody, Button } from "reactstrap"
const staticURL = process.env.REACT_APP_STATIC_URL

const Certificate = ({ data, count = 1 }) => {
  function formatDate(dateString) {
    const date = new Date(dateString)
    return date.toLocaleDateString()
  }

  const handleDownload = () => {
    if (data?.certificateUrl) {
      // Create a hidden anchor element
      const link = document.createElement("a")
      link.href = staticURL + data.certificateUrl
      link.target = "_blank" // Open the link in a new tab
      link.download = "certificateUrl.pdf" // Specify the download file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up the element
    }
  }

  let key = count

  return (
    <div className="pt-3 px-2">
      <div className="row justify-content-center">
        <div className="col-xl-12">
          <h4 className="sun_heading mb-0">Certificate {key}</h4>
          <div>
            <div className="me-4 mt-2 d-flex">
              {data?.certificateUrl ? (
                <iframe
                  src={staticURL + data?.certificateUrl}
                  height={150}
                  width={200}
                  title="Certificate"
                  className="me-5"
                />
              ) : (
                ""
              )}
              <div>
                <h5 className="certificate_title">{data.title}</h5>
                <p className="issue_expiry_date">
                  {data.issueDate
                    ? `Issue Date: ${formatDate(data.issueDate)} | `
                    : ""}
                  {data.expiryDate
                    ? `Expiry Date: ${formatDate(data.expiryDate)}`
                    : ""}
                </p>
                {data.certificateUrl && (
                  <>
                    <Button
                      onClick={handleDownload}
                      type="button"
                      color="primary"
                      className="btn btn-outline-primary btn_outline_bgwhite"
                    >
                      <i className="bx bx-download font-size-16 align-middle me-2"></i>
                      Download
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="full_devider my-2"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Certificate
