import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import toastr from "toastr"
import {
  Col,
  Container,
  Row,
} from "reactstrap"
import { adminPermission } from "../../helpers/roles_permissions"
import {
  getRoles as onGetRoles,
  getColleges as onGetColleges,
  updateCollege as onUpdateCollege,
  addNewCollege as onAddNewCollege,
  deleteCollege as onDeleteCollege,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import FormModal from "../../components/Common/Modal/FormModal"
import { columns,renderTOFormFields } from "./constants";

const CollegesList = () => {
  document.title = "College List | QAPRENEUR"
  const role = localStorage.getItem("authRole")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [college, setCollege] = useState({
    name: "",
    email: "",
    contactPersonName: "",
    address: ""
  })
  const [formModal, setFormModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [collegeList, setCollegeList] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { colleges, loading, roles } = useSelector(state => ({
    colleges: state?.colleges?.colleges,
    loading: state?.colleges?.loading,
    roles: state.roles?.roles,
  }))

  const collegeRoleId = roles.filter(
    role => role.status === "active" && role.role === "college"
  )

  useEffect(() => {
    const params = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      params.search = search;
    }
    dispatch(onGetColleges(params))
    dispatch(onGetRoles())
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(colleges)) {
      const data = colleges.map(item => ({ ...item, ...item.extra })).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      setCollegeList(data)
    }
  }, [colleges, pageNo])

  const toggleForm = () => setFormModal(!formModal)

  const handleAddCollegeClick = () => {
    setIsEdit(false)
    setCollege({
      name: "",
      email: "",
      contactPersonName: "",
      address: ""
    })
    toggleForm()
  }

  const handleModalAction = () => {
    if (college && college._id) {
      const params = {
        page: pageNo,
        limit: rowPerPage,
      }
      dispatch(onDeleteCollege(college._id, params))
    }
    setDeleteModal(false)
  }

  const handleChangeStatus = college => {
    const status = college.status === "active" ? "deactive" : "active"
    dispatch(onUpdateCollege("college", { status }, college._id, navigate))

  }

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  const handleSearch = (query) => {
    setPageNo(1)
    setSearch(query)
  }

  const handleActions = (c, type) => {
    if (type === "VIEW") {
      navigate(`/college-details/${c._id}`)
    } else if (type === "EDIT") {
      setCollege({
        name: c?.name,
        email: c?.email,
        contactPersonName: c?.contactPersonName,
        address: c?.address,
        _id: c?._id
      })
      setIsEdit(true)
      toggleForm()
    } else if (type === "DELETE") {
      setCollege(c)
      setDeleteModal(true)
    }
  }

  const handleSave = values => {
    if (collegeRoleId && collegeRoleId.length > 0 && collegeRoleId[0]?._id) {
      values.roleId = collegeRoleId[0]?._id
      values.extra = {
        contactNumber: values.contactNumber,
        contactPersonName: values.contactPersonName,
      }
      if (isEdit) {
        delete values.email;
        dispatch(onUpdateCollege("college", values, college._id, navigate))
      } else {
        dispatch(onAddNewCollege(values, navigate))
      }
    } else {
      toastr.error("College role not found")
    }
    toggleForm()
  }

  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the college.?"}
          modalAction={handleModalAction}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Colleges" breadcrumbItem="Colleges" />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={collegeList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  handleStatus={handleChangeStatus}
                  handleActions={handleActions}
                  handleSearch={handleSearch}
                  onChangePagination={onChangePagination}
                  showPagination={true}
                  addButton={adminPermission.includes(role) ? { onclick: handleAddCollegeClick, title: "Add New College" } : null}
                  columns={columns}
                />
              </div>
            </Col>
          </Row>
          {formModal && (
            <FormModal
              modalBackdrop={formModal}
              setmodalBackdrop={setFormModal}
              modalAction={handleSave}
              title={!!isEdit ? "Edit College" : "Add College"}
              formFields={renderTOFormFields(isEdit)}
              data={college}
              height={450}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CollegesList

