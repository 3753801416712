import React from "react"
import { Card, CardBody } from "reactstrap"
import AppLoader from "../Loader/index"

function CardSkills({ title, list = [], loading = false }) {
  return (
    <Card style={{ borderRadius: 15 }}>
      <CardBody>
        {loading ? (
          <AppLoader />
        ) : (
          <div className="pt-3 px-2">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div>
                  <h4 className="sun_heading">{title}</h4>
                  <div className="mt-4">
                    {list.length > 0
                      ? list.map((skill, key) => {
                          return (
                            <span key={key} className="skill_tag">
                              {skill?.name}
                            </span>
                          )
                        })
                      : "No Skills Added"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default CardSkills
