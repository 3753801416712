import moment from "moment"

export const jobPostscolumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Job Title",
    name: "jobTitle",
    key: "DEFAULT",
    // defaultId: "testId",
    link: "/jobs-details",
  },
  {
    header: "Salary Range",
    name: "salaryRange",
    key: "DEFAULT",
  },
  {
    header: "location",
    name: "location",
    key: "DEFAULT",
  },
  {
    header: "Experience",
    name: "experience",
    key: "DEFAULT",
  },
  {
    header: "Job Type",
    name: "type",
    key: "DEFAULT",
  },
  {
    header: "Candidate applied",
    name: "totalCandidatesApplied",
    key: "DEFAULT",
  },
  {
    header: "Job Status",
    name: "totalCandidatesHired",
    key: "DEFAULT",
  },
]

export const ApplicantsColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    // link:"/student-details"
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "Contact",
    name: "phone",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "job Title",
    name: "jobTitle",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "Location",
    name: "location",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "No Of Vacancy",
    name: "noOfVacancy",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "salary Range",
    name: "salaryRange",
    key: "DEFAULT",
    // link: "/student-details",
  },
]
