import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import { GET_JOBS_REFERRALS } from "./actionTypes"
import {
  getJobsReferralsSuccess,
  getJobReferralsFail,
  updateJobReferralsLoadingState,
} from "./actions"

// Include Both Helper File with needed methods
import { getReferrals } from "helpers/api/jobReferralApi"

function* jobsReferrals({ params }) {
  try {
    yield put(updateJobReferralsLoadingState(true))
    const { page, limit, search = "" } = params
    const queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }`
    const response = yield call(getReferrals, queryParams)
    yield put(getJobsReferralsSuccess(response.data))
    yield put(updateJobReferralsLoadingState(false))
  } catch (error) {
    yield put(updateJobReferralsLoadingState(false))
    yield put(getJobReferralsFail(error))
  }
}

function* jobsReferralsSaga() {
  yield takeEvery(GET_JOBS_REFERRALS, jobsReferrals)
}

export default jobsReferralsSaga
