import React from "react"
import CardInfo from "../Common/Card/CardInfo"
import moment from "moment" // Import moment library for date formatting

export const getRenderFields = (data, role) => {
  if (data) {
    switch (role) {
      case "company":
        return [
          { label: "Company Name", value: data.name },
          { label: "Email", value: data.email },
          { label: "Phone", value: data.phone },
          { label: "Contact Person Number*", value: data.contactNumber },
          { label: "Contact Person Name*", value: data.contactPersonName },
          { label: "City*", value: data.city },
          { label: "State*", value: data.state },
          { label: "Address", value: data.address },
          { label: "Head Quarter", value: data.headQuarter },
          {
            label: "Establishment Date*",
            value: data.establishment
              ? moment(data.establishment).format("YYYY-MM-DD")
              : "",
          },
          { label: "Company Size", value: data.companySize },
          { label: "Website", value: data.website },
        ]
        break
      case "hr":
        return [
          { label: "Company Name*", value: data?.companyDetails?.name },
          { label: "Email", value: data.email },
          { label: "Website", value: data?.companyDetails?.website },
          { label: "HR Name*", value: data.name },
          { label: "HR Contact Number*", value: data.phone },
          { label: "Company Size", value: data?.companyDetails?.companySize },
          { label: "State*", value: data?.companyDetails?.state },
          { label: "City*", value: data?.companyDetails?.city },
          { label: "Address", value: data?.companyDetails?.address },
          { label: "Head Quarter", value: data?.companyDetails?.headQuarter },
          {
            label: "Establishment Date*",
            value: data?.companyDetails?.establishment
              ? moment(data?.companyDetails?.establishment).format("YYYY-MM-DD")
              : "",
          },
          { label: "Company Phone Number*", value: data?.companyDetails?.phone },
        ]
        break
      case "student":
        return [
          {
            label: "First Name",
            value: data.firstName ? data.firstName : "-",
          },
          { label: "Last Name", value: data.lastName ? data.lastName : "-" },
          { label: "Email", value: data.email ? data.email : "-" },
          { label: "Mobile Number", value: data.phone ? data.phone : "-" },
          {
            label: "DOB",
            value: data.dob ? moment(data.dob).format("YYYY-MM-DD") : "-",
          },
          {
            label: "Gender",
            value: data.gender ? data.gender.toUpperCase() : "-",
          },
          { label: "Address", value: data.address ? data.address : "-" },
          { label: "City", value: data.city ? data.city : "-" },
          { label: "State", value: data.state ? data.state : "-" },
          { label: "Country", value: data.country ? data.country : "-" },
        ]
        break
      case "company_billing":
        return [
          {
            label: "Billing Address",
            value: data?.billingAddress,
          },
          { label: "City", value: data?.city },
          { label: "State", value: data?.state },
          { label: "Postal Code", value: data?.postalCode },
          { label: "GST Number", value: data?.gstNumber },
        ]
        break
      default:
        return []
    }
  }
}

function dataDetails({
  title,
  data,
  role,
  BtnTitle = "",
  isButton = false,
  onClick,
  loading = false,
}) {
  return (
    <CardInfo
      title={title}
      fields={getRenderFields(data, role)}
      loading={loading}
    />
  )
}

export default dataDetails
