import flashCards from "store/flashCards/reducer"
import {
  GET_CHAPTER_CONTENT,
  GET_CHAPTER_CONTENT_FAIL,
  GET_CHAPTER_CONTENT_SUCCESS,
  ADD_CHAPTER_CONTENT,
  ADD_CHAPTER_CONTENT_SUCCESS,
  ADD_CHAPTER_CONTENT_FAIL,
  UPDATE_CHAPTER_CONTENT,
  UPDATE_CHAPTER_CONTENT_SUCCESS,
  UPDATE_CHAPTER_CONTENT_FAIL,
  DELETE_CHAPTER_CONTENT,
  DELETE_CHAPTER_CONTENT_SUCCESS,
  DELETE_CHAPTER_CONTENT_FAIL,
  UPDATE_CHAPTER_CONTENT_LOADING_STATE,
  RESET_CHAPTER_CONTENT,
} from "./actionTypes"

export const getChapterContent = (chapterId) => ({
  type: GET_CHAPTER_CONTENT,
  chapterId
})

export const getChapterContentSuccess = chapterContent => ({
  type: GET_CHAPTER_CONTENT_SUCCESS,
  payload: chapterContent,
})

export const getChapterContentFail = error => ({
  type: GET_CHAPTER_CONTENT_FAIL,
  payload: error,
})

export const updateChapterContent = (chapterContent, contentId, history) => ({
  type: UPDATE_CHAPTER_CONTENT,
  payload: { chapterContent, contentId, history },
})

export const updateChapterContentSuccess = chapterContent => ({
  type: UPDATE_CHAPTER_CONTENT_SUCCESS,
  payload: chapterContent,
})

export const updateChapterContentFail = error => ({
  type: UPDATE_CHAPTER_CONTENT_FAIL,
  payload: error,
})

export const deleteChapterContent = (contentId, history) => ({
  type: DELETE_CHAPTER_CONTENT,
  payload: { contentId, history },
})

export const deleteChapterContentSuccess = contentId => ({
  type: DELETE_CHAPTER_CONTENT_SUCCESS,
  payload: { contentId },
})

export const deleteChapterContentFail = error => ({
  type: DELETE_CHAPTER_CONTENT_FAIL,
  payload: error,
})

export const addChapterContent = (chapterContent, history) => ({
  type: ADD_CHAPTER_CONTENT,
  payload: { chapterContent, history },
})

export const addChapterContentSuccess = chapterContent => ({
  type: ADD_CHAPTER_CONTENT_SUCCESS,
  payload: chapterContent,
})

export const addChapterContentFail = error => ({
  type: ADD_CHAPTER_CONTENT_FAIL,
  payload: error,
})

export const updateChapterContentLoadingState = status => ({
  type:  UPDATE_CHAPTER_CONTENT_LOADING_STATE ,
  payload: status,
})

export const resetChapterContent = () => ({
  type: RESET_CHAPTER_CONTENT
})
