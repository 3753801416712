import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_ADMIN_DASHBOARD_DATA,
  GET_COMPANY_DASHBOARD_DATA,
  GET_JOB_CHART_DATA_SUCCESS,
  GET_TRANSACTION_CHART_DATA_SUCCESS,
  GET_PROFILE_STATUS_DATA_SUCCESS,
  GET_SIDEBAR,
  UPDATE_DASHBOARD_LOADING_STATE,
} from "./actionTypes"

const INIT_STATE = {
  chartsData: [],
  dashboardData: [],
  companyDashboard: {},
  jobBarChart: {},
  transactionBarChart: {},
  sideBarLayout: [],
  profileStatusChart: [],
  loading: false,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsData: action.payload.data,
          }
        case GET_ADMIN_DASHBOARD_DATA:
          return {
            ...state,
            dashboardData: action.payload.data,
          }
        case GET_COMPANY_DASHBOARD_DATA:
          return {
            ...state,
            companyDashboard: action.payload.data,
          }
        case GET_JOB_CHART_DATA_SUCCESS:
          return {
            ...state,
            jobBarChart: action.payload.data,
          }
        case GET_TRANSACTION_CHART_DATA_SUCCESS:
          return {
            ...state,
            transactionBarChart: action.payload.data,
          }
        case GET_PROFILE_STATUS_DATA_SUCCESS:
          return {
            ...state,
            profileStatusChart: action.payload.data,
          }
        case GET_SIDEBAR:
          return {
            ...state,
            sideBarLayout: action.payload.data,
          }
        default:
          return state
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsDataError: action.payload.error,
          }
        default:
          return state
      }
    case UPDATE_DASHBOARD_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default Dashboard
