import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import TableContainer from "../../components/Common/Table/index"
import Breadcrumbs from "components/Common/Breadcrumb"

import { userJobAppliedcolumns } from "./constants"
import { getUserAppliedJobs as onGetUserAppliedJobs } from "../../store/jobs/actions"

function userJobAppliedList() {
  document.title = "Student job applied | QA"
  const dispatch = useDispatch()
  const params = useParams()
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const [appliedJobs, setAppliedJobs] = useState([])

  const { loading, userAppliedJobs } = useSelector(state => ({
    ...state.jobs,
  }))

  useEffect(() => {
    if (params && params.id) {
      const authUser = localStorage.getItem("authUser")
      const user = authUser ? JSON.parse(authUser) : null
      const authRole = localStorage.getItem("authRole")
      const companyId =
        user && authRole === "hr_user" ? user.companyId : user._id
      const queryParams = {
        candidateId: params.id,
        companyId: companyId,
        page: pageNo,
        limit: rowPerPage,
      }
      if (search && search.length > 0) {
        queryParams.search = search
      }
      dispatch(onGetUserAppliedJobs(queryParams))
    }
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if (userAppliedJobs && Array.isArray(userAppliedJobs)) {
      setAppliedJobs(
        userAppliedJobs.map(userAppliedJob => {
          return {
            ...userAppliedJob,
            ...userAppliedJob?.jobDetail,
            image: userAppliedJob?.companyDetail?.image,
            status: userAppliedJob?.status,
            companyName: userAppliedJob?.companyDetail?.name,
          }
        })
      )
    }
  }, [userAppliedJobs])

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }
  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Candidates"
            breadcrumbItem="Job Applied List"
            navigate={-1}
          />
          <Row>
            <Col lg="12">
              <div>
                {userAppliedJobs && Array.isArray(userAppliedJobs) && (
                  <TableContainer
                    loading={loading}
                    data={appliedJobs}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    isJobListGlobalFilter={false}
                    showPageDropdown={false}
                    customPageSize={10}
                    className="project-list-table table-nowrap align-middle table-borderless"
                    columns={userJobAppliedcolumns}
                    tableName="viewStudent"
                    onChangePagination={onChangePagination}
                    showPagination={true}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default userJobAppliedList
