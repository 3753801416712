import { del, get, post, patch } from "../api_helper"
import { COURSES_ROUTES, TEST_ROUTES } from "../url_helper"
/** Courses */
// add Course
export const addNewCourse = test =>
  post(COURSES_ROUTES, test, {
    headers: { "Content-Type": "multipart/form-data" },
  })

// update Course
export const updateCourse = (test, testId) =>
  patch(`${COURSES_ROUTES}/${testId}`, test, {
    headers: { "Content-Type": "multipart/form-data" },
  })

// delete Course
export const deleteCourse = testId =>
  del(`${COURSES_ROUTES}/${testId}`, { headers: { testId } })

// get Course
export const getCourses = queryParams => get(`${COURSES_ROUTES}${queryParams}`)

// get test details
export const getCoursesDetails = id =>
  get(`${COURSES_ROUTES}/${id}`, { params: { id } })

/** get subscription users by course id */
export const getSubscriptionUsersByCourseId = (courseId, queryParams) => {
  if (queryParams) {
    return get(`/courses/${courseId}/users${queryParams}`)
  } else {
    return get(`/courses/${courseId}/users`)
  }
}

// get test details
export const getStudentCourseResultApi = queryParams =>
  get(`${TEST_ROUTES}/quizeAndCertificateTest/${queryParams}`)
