import React from "react"
import { useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, Button, Badge } from "reactstrap"
import AppTabs from "../Tabs/index"
import star from "../../../assets/images/home/star.svg"
import ImageProfile from "../ImageProfile"

function ProfileHeader({
  imageUrl,
  profileName,
  profileEmail,
  ButtonType,
  onclick,
  LayoutType,
  style,
  tabs,
  data,
  infoBadge = [],
  showBackButton = true,
  showStatus = false,
  showPlan = false,
  planData = "",
  showStart = false,
  startdata,
  showFrame = "",
  handleTabClick = null,
  handleUploadImage = null,
  handleSubmit = null,
  profileTitle = "",
  showAddress = false,
  showUpgradeButton = false,
  handleUpgradePlan = null,
}) {
  return (
    <>
      <Card style={{ borderRadius: 15 }}>
        <CardBody style={style}>
          {renderLayout(
            imageUrl,
            profileName,
            profileEmail,
            ButtonType,
            onclick,
            LayoutType,
            tabs,
            data,
            infoBadge,
            showBackButton,
            showStatus,
            showPlan,
            planData,
            showStart,
            startdata,
            showFrame,
            handleTabClick,
            handleUploadImage,
            handleSubmit,
            profileTitle,
            showAddress,
            showUpgradeButton,
            handleUpgradePlan
          )}
        </CardBody>
      </Card>
    </>
  )
}

const renderLayout = (
  imageUrl,
  profileName,
  profileEmail,
  ButtonType,
  onclick,
  LayoutType,
  tabs,
  data,
  infoBadge,
  showBackButton,
  showStatus,
  showPlan,
  planData,
  showStart,
  startdata,
  showFrame,
  handleTabClick,
  handleUploadImage,
  handleSubmit,
  profileTitle,
  showAddress,
  showUpgradeButton,
  handleUpgradePlan
) => {
  const navigate = useNavigate()

  const percentage = startdata?.total / 100
  const filledStars = Math.floor(percentage * 5)

  const remainder = percentage * 5 - filledStars
  const hasHalfStar = remainder >= 0.5
  switch (LayoutType) {
    case "VERTICAL":
      return (
        <>
          <div className="cover_pic">
            {showBackButton && (
              <Button
                color="primary"
                className="global_btn btn btn-primary px-4 my-2 me-4"
                onClick={() => navigate(-1)}
                style={{
                  position: "absolute",
                  right: "0",
                }}
              >
                Back
              </Button>
            )}
          </div>
          <div className="position-relative">
            <div className="profile_pic">
              <img src={imageUrl} height={150} width={150} alt={"qapreneur"} />
            </div>
            <div className="row">
              <div className="col-xl-12 mt-3">
                <div className="profile_info">
                  <div>
                    <div>
                      {profileName && (
                        <h4 className="profile_name">{profileName}</h4>
                      )}
                      {profileEmail && <b className="mb-1">{profileEmail}</b>}
                      <div className="d-flex">
                        {infoBadge &&
                          Array.isArray(infoBadge) &&
                          infoBadge.map((badge, key) => {
                            return (
                              <div className="info_badge me-2" key={key}>
                                <span>{badge}</span>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      {ButtonType === "EDIT" && (
                        <>
                          <Button
                            color="primary"
                            className="global_btn btn btn-primary px-4 my-2"
                            onClick={onclick}
                          >
                            Edit
                            <i className="bx bx-edit font-size-16 align-middle ms-2"></i>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
      break
    case "HORIZONTAL":
      return (
        <>
          <Row className="d-flex py-4">
            <Col lg={2} className="ms-3 align-items-center d-flex">
              {/* Column 1: Image */}
              <img
                src={imageUrl}
                alt={"qapreneur"}
                className="avatar-md rounded-circle img-thumbnail"
              />
            </Col>
            <Col
              lg={2}
              style={{ borderLeft: "2px solid gray" }}
              className="flex-grow-1 align-self-center"
            >
              {/* Column 2: Profile Name */}
              <div className="text-muted">
                <h2>{profileName}</h2>
                {data?.contactPersonName ? (
                  <p style={{ fontWeight: "600" }}>{data?.contactPersonName}</p>
                ) : (
                  ""
                )}
                {data?.status && showStatus ? (
                  <>
                    {data?.status == "active" && (
                      <Badge className="bg-success badge_active">Active</Badge>
                    )}
                    {data?.status == "deactive" && (
                      <Badge className="bg-danger badge_deactive">
                        Deactive
                      </Badge>
                    )}
                  </>
                ) : (
                  ""
                )}
                {ButtonType === "EDIT" && (
                  <>
                    <span style={{ cursor: "pointer" }} onClick={onclick}>
                      <i className="bx bx-edit font-size-16 align-middle me-2"></i>
                      Edit
                    </span>
                  </>
                )}
              </div>
            </Col>
            <Col
              lg={2}
              style={{ borderLeft: "2px solid gray" }}
              className="flex-grow-1 align-self-center"
            >
              {/* Column 3: Address */}
              {data?.adress && (
                <div className="text-muted">
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-map-marker font-size-18" />
                    <b className="ms-2">{data?.address}</b>
                  </div>
                  <div className="d-flex align-items-center">
                    {/* Display City, State, and Country */}
                    <b className="ms-2">
                      {data?.city} {data?.state} {data?.country}
                    </b>
                  </div>
                </div>
              )}
            </Col>
            <Col
              lg={2}
              style={{ borderLeft: "2px solid gray" }}
              className="flex-grow-1 align-self-center"
            >
              {/* Column 4: Email and Phone */}
              <div className="text-muted">
                {data?.email ? (
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-email-outline font-size-18" />
                    <b
                      className="ms-2"
                      style={{ fontSize: "12px", wordWrap: "break-word" }}
                    >
                      {data?.email}
                    </b>
                  </div>
                ) : (
                  ""
                )}
                {data?.phone ? (
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-phone-outline font-size-18" />
                    <b className="ms-2" style={{ fontSize: "12px" }}>
                      {data?.phone}
                    </b>
                  </div>
                ) : (
                  ""
                )}
                {data?.website ? (
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-earth font-size-18" />
                    <b className="ms-2" style={{ fontSize: "12px" }}>
                      {data?.website}
                    </b>
                  </div>
                ) : (
                  ""
                )}
                {data?.type && data?.price ? (
                  <>
                    <div className="d-flex align-items-center">
                      <i className="mdi mdi-money font-size-18" />
                      <b className="ms-2" style={{ fontSize: "12px" }}>
                        {data?.type} {" : "}{" "}
                        {data?.type == "paid" ? data?.price : ""}Rs
                      </b>
                    </div>
                    <div className="d-flex align-items-center">
                      <b className="ms-2" style={{ fontSize: "12px" }}>
                        Discount {" : "}{" "}
                        {data?.type == "paid" ? data?.discount : ""}%
                      </b>
                    </div>
                    <div className="d-flex align-items-center">
                      <b className="ms-2" style={{ fontSize: "12px" }}>
                        Discount Price{" : "}{" "}
                        {data?.isDiscount ? data?.discountPrice : ""}Rs
                      </b>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {data?.type == "free" && (
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-money font-size-18" />
                    <b className="ms-2" style={{ fontSize: "12px" }}>
                      {data?.type == "free" ? "free" : ""}
                    </b>
                  </div>
                )}
                {data?.type == "premium" && (
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-money font-size-18" />
                    <b className="ms-2" style={{ fontSize: "12px" }}>
                      {data?.type == "premium" ? "premium" : ""}
                    </b>
                  </div>
                )}
                {/* {data?.courseDuration ? (
                  <div className="d-flex align-items-center mt-2">
                    <i
                      style={{ marginLeft: "5px" }}
                      className="mdi mdi-clock font-size-15"
                    />
                    <b className="ms-2" style={{ fontSize: "12px" }}>
                      {data?.courseDuration}
                    </b>
                  </div>
                ) : (
                  ""
                )} */}
              </div>
            </Col>
          </Row>
        </>
      )
      break
    case "HORIZONTAL_TABS":
      return tabs ? (
        <>
          <Row className="d-flex p-4">
            <Col className="align-items-center d-flex">
              <ImageProfile
                showFrame={showFrame}
                imageUrl={imageUrl}
                size="md"
                handleUploadImage={handleUploadImage}
                handleSubmit={handleSubmit}
              />
              <div className="d-flex justify-content-center flex-column ms-2">
                <span className="text-muted text-uppercase fs-2 fw-medium">
                  {profileName}
                </span>
                {profileTitle && (
                  <span
                    className="fc-prime fw-normal"
                    style={{ marginTop: "-9px" }}
                  >
                    {profileTitle}
                  </span>
                )}
                <div className="d-flex mt-1">
                  {data?.status && showStatus && (
                    <div className="me-2">
                      {data?.status == "active" && (
                        <Badge className="bg-success badge_active">
                          Active
                        </Badge>
                      )}
                      {data?.status == "deactive" && (
                        <Badge className="bg-danger badge_deactive">
                          Deactive
                        </Badge>
                      )}
                    </div>
                  )}
                  {showPlan && planData && (
                    <>
                      <div
                        className="d-flex align-items-center justify-content-center me-2"
                        style={{ width: "110px" }}
                      >
                        <span
                          className="bg-success badge_active "
                          style={{
                            maxHeight: "26px",
                            borderRadius: 3,
                            padding: "3px 4px",
                          }}
                        >
                          {planData?.name}
                        </span>
                      </div>
                    </>
                  )}
                  {showStart && startdata && (
                    <div className="user_ratings d-flex align-items-center gap-1">
                      {[...Array(5)].map((_, index) => {
                        if (index < filledStars) {
                          return (
                            <i
                              key={index}
                              style={{ color: "#f89752", fontSize: "15px" }}
                              className="bx bxs-star"
                            ></i>
                          )
                        } else if (index === filledStars && hasHalfStar) {
                          return (
                            <i
                              key={index}
                              style={{ color: "#f89752", fontSize: "15px" }}
                              className="bx bxs-star-half"
                            ></i>
                          )
                        } else {
                          return (
                            <i
                              key={index}
                              style={{ color: "#f89752", fontSize: "15px" }}
                              className="bx bx-star"
                            ></i>
                          )
                        }
                      })}
                      {ButtonType === "EDIT" && (
                        <>
                          <span style={{ cursor: "pointer" }} onClick={onclick}>
                            <i className="bx bx-edit font-size-16 align-middle me-2"></i>
                            Edit
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div></div>
              </div>
            </Col>
            {data?.address && showAddress && (
              <Col
                style={{ borderLeft: "2px solid gray" }}
                className="flex-grow-1 align-self-center"
              >
                <>
                  <div className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="mdi mdi-map-marker font-size-18" />
                      <b className="ms-2">{data?.address}</b>
                    </div>
                    <div className="d-flex align-items-center">
                      <b className="ms-2">
                        {data?.city} {data?.state} {data?.country}
                      </b>
                    </div>
                  </div>
                </>
              </Col>
            )}
            <Col className="align-items-center justify-content-end d-flex flex-grow-1 align-self-center">
              <div className="text-muted">
                <div className="d-flex align-items-center">
                  <i className="mdi mdi-email-outline font-size-18" />
                  <b
                    className="ms-2"
                    style={{ fontSize: "12px", wordWrap: "break-word" }}
                  >
                    {data?.email}
                  </b>
                </div>
                <div className="d-flex align-items-center">
                  <i className="mdi mdi-phone-outline font-size-18" />
                  <b className="ms-2" style={{ fontSize: "12px" }}>
                    {data?.phone}
                  </b>
                </div>
              </div>
            </Col>
          </Row>
          <AppTabs
            tabsArray={tabs}
            contentBgColor="#f2f2f2"
            handleTabClick={handleTabClick}
            showButton={showUpgradeButton}
            handleButton={handleUpgradePlan}
          />
        </>
      ) : (
        ""
      )
      break
    default:
      ""
      break
  }
}

export default ProfileHeader

{
  /* <Col lg={1}>
              <ImageProfile
                showFrame={showFrame}
                imageUrl={imageUrl}
                size="md"
                handleUploadImage={handleUploadImage}
                handleSubmit={handleSubmit}
              />
            </Col> */
}
{
  /* <Col
              lg={2}
              style={{ borderLeft: "2px solid gray" }}
              className="flex-grow-1 d-flex flex-column align-self-center"
            >
              <div className="text-muted">
                <h2 className="mb-0">{profileName}</h2>
                {profileTitle && (
                  <span className="fc-prime fw-normal " style={{marginTop:'-10px'}}>{profileTitle}</span>
                )}
              </div>
              <div className="d-flex align-items-center">
                {data?.status && showStatus ? (
                  <div className="me-2">
                    {data?.status == "active" && (
                      <Badge className="bg-success badge_active">Active</Badge>
                    )}
                    {data?.status == "deactive" && (
                      <Badge className="bg-danger badge_deactive">
                        Deactive
                      </Badge>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {showPlan && planData && (
                  <>
                    <div
                      className="d-flex align-items-center justify-content-center me-2"
                      style={{ width: "110px" }}
                    >
                      <span
                        className="bg-success badge_active "
                        style={{
                          maxHeight: "26px",
                          borderRadius: 3,
                          padding: "3px 4px",
                        }}
                      >
                        {planData?.name}
                      </span>
                    </div>
                  </>
                )}
                {ButtonType === "EDIT" && (
                  <>
                    <span style={{ cursor: "pointer" }} onClick={onclick}>
                      <i className="bx bx-edit font-size-16 align-middle me-2"></i>
                      Edit
                    </span>
                  </>
                )}
                {showStart && startdata && (
                  <div className="user_ratings d-flex align-items-center gap-1 ">
                    {[...Array(5)].map((_, index) => {
                      if (index < filledStars) {
                        return (
                          <i
                            key={index}
                            style={{ color: "gold", fontSize: "15px" }}
                            className="bx bxs-star"
                          ></i>
                        )
                      } else if (index === filledStars && hasHalfStar) {
                        return (
                          <i
                            key={index}
                            style={{ color: "gold", fontSize: "15px" }}
                            className="bx bxs-star-half"
                          ></i>
                        )
                      } else {
                        return (
                          <i
                            key={index}
                            style={{ color: "gold", fontSize: "15px" }}
                            className="bx bx-star"
                          ></i>
                        )
                      }
                    })}
                  </div>
                )}
              </div>
            </Col> */
}
{
  /* <Col
              lg={2}
              style={{ borderLeft: "2px solid gray" }}
              className="flex-grow-1 align-self-center"
            >
              {data?.address && (
                <>
                  <div className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="mdi mdi-map-marker font-size-18" />
                      <b className="ms-2">{data?.address}</b>
                    </div>
                    <div className="d-flex align-items-center">
                      <b className="ms-2">
                        {data?.city} {data?.state} {data?.country}
                      </b>
                    </div>
                  </div>
                </>
              )}
            </Col> */
}
{
  /* <Col
              lg={6}
              // style={{ borderLeft: "2px solid gray" }}
              className="align-items-center justify-content-end d-flex flex-grow-1 align-self-center"
            >
              <div className="text-muted">
                <div className="d-flex align-items-center">
                  <i className="mdi mdi-email-outline font-size-18" />
                  <b
                    className="ms-2"
                    style={{ fontSize: "12px", wordWrap: "break-word" }}
                  >
                    {data?.email}
                  </b>
                </div>
                <div className="d-flex align-items-center">
                  <i className="mdi mdi-phone-outline font-size-18" />
                  <b className="ms-2" style={{ fontSize: "12px" }}>
                    {data?.phone}
                  </b>
                </div>
                {data?.website ? (
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-earth font-size-18" />
                    <b className="ms-2" style={{ fontSize: "12px" }}>
                      {data?.website}
                    </b>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col> */
}
