import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import { GET_TRANSACTIONS, ADD_NEW_TRANSACTION } from "./actionTypes"
import {
  getTransactionsSuccess,
  getTransactionsFail,
  addTransactionsSuccess,
  addTransactionsFail,
  updateTransactionsLoadingState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTransactions,
  getCompanyTransactions,
  addTransactions,
} from "helpers/api/transactionsApi"
import { getTestsDetails } from "helpers/api/testApi"
import { getSubscriptions } from "helpers/api/subscriptionsApi"
import { getCoursesDetails } from "helpers/api/courseApi"

function* fetchTransactions({ params }) {
  try {
    const { page, limit, search = "", role, userId } = params
    yield put(updateTransactionsLoadingState(true))
    const queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }${userId ? "&userId=" + userId : ""}`
    if (role == "admin") {
      const response = yield call(getTransactions, queryParams)
      yield put(getTransactionsSuccess(response.data.transactions))
    }
    if (role == "company") {
      const response = yield call(getCompanyTransactions,queryParams)
      yield put(getTransactionsSuccess(response.data.transactions))
    }
    yield put(updateTransactionsLoadingState(false))
  } catch (error) {
    yield put(getTransactionsFail(error))
  }
}

function* assignTransactionToUser({ payload: { transaction } }) {
  try {
    const testId = transaction?.test
    const courseId = transaction?.course
    const SubscriptionsId = transaction?.subscription
    let testResponse
    let courseResponse
    let SubscriptionResponse
    let amount = 0
    let transactionId =
      Date.now() + Math.floor(Math.random() * 100000) + generateRandomId(10)
    let purpose = ""

    if (transaction.type == "test") {
      purpose = "Test Purchase"
      testResponse = yield call(getTestsDetails, testId)
      if (testResponse?.data?.data) {
        amount = testResponse?.data?.data?.isDiscount
          ? testResponse?.data?.data?.discountPrice
          : testResponse?.data?.data?.price
      }
    } else if (transaction.type == "course") {
      purpose = "Course Purchase"
      courseResponse = yield call(getCoursesDetails, courseId)
      if (courseResponse?.data?.courseDetail) {
        amount = courseResponse?.data?.courseDetail?.isDiscount
          ? courseResponse?.data?.courseDetail?.discountPrice
          : courseResponse?.data?.courseDetail?.price
      }
    } else if (transaction.type === "subscription") {
      purpose = "Subscription Purchase"
      SubscriptionResponse = yield call(getSubscriptions, SubscriptionsId)
      if (SubscriptionResponse?.data?.subscription) {
        amount = SubscriptionResponse?.data?.subscription?.price
      }
    }

    const data = {
      amount: amount,
      transactionType: "Admin",
      purpose: purpose,
      userId: transaction.user,
      transactionId: transactionId,
    }

    if (transaction.type === "test") {
      data.testId = transaction.test
    }

    if (transaction.type === "course") {
      data.courseId = transaction.course
    }
    if (transaction.type === "subscription") {
      data.subscriptionId = transaction.subscription
    }

    if (purpose && transactionId && transaction.user && transaction.type) {
      const response = yield call(addTransactions, data)
      yield put(addTransactionsSuccess(response?.data?.data))
      toastr.success("Transaction added successfully..!!")
    }
  } catch (error) {
    yield put(addTransactionsFail(error))
    // toastr.error(error.message)
  }
}

const generateRandomId = length => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let result = ""
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

function* transactionsSaga() {
  yield takeEvery(GET_TRANSACTIONS, fetchTransactions)
  yield takeEvery(ADD_NEW_TRANSACTION, assignTransactionToUser)
}

export default transactionsSaga
