export const columns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
  },
  {
    header: "Number",
    name: "number",
    key: "DEFAULT",
  },
  {
    header: "Issue Type",
    name: "issueType",
    key: "DEFAULT",
  },
  {
    header: "Description",
    name: "description",
    key: "DESCRIPTION",
  },
]
