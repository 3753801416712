import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Container } from "reactstrap"
import {
  getMockTestPricDetail as onGetMockTestPricDetail,
  updateMockTestPriceDetail as onUpdateMockTestPriceDetails,
  resetPlans as onResetPlan,
} from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AppForm from "../../components/Common/Form"
import { mockTestPriceFields } from "./constants"

import AppLoader from "../../components/Common/Loader"

const EditMockTestPrice = () => {
  document.title = "Edit Mock Test Price | QAPRENEUR"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const { mockTestPriceDetail, loading } = useSelector(state => ({
    mockTestPriceDetail: state.plans?.mockTestPriceDetail,
    loading: state.plans?.loading,
  }))

  useEffect(() => {
    dispatch(onGetMockTestPricDetail())
    return () => dispatch(onResetPlan())
  }, [])

  const handleFormSubmit = (formikValues, formik) => {
    handleSubmit(formikValues, formik)
  }

  const handleSubmit = (formikValues, formik) => {
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        const values = {
          ...formikValues,
        }
        dispatch(
          onUpdateMockTestPriceDetails(
            { ...values },
            mockTestPriceDetail._id,
            navigate
          )
        )
      } else {
        const touchedFields = {}
        Object.keys(errors).forEach(field => {
          touchedFields[field] = true
        })
        formik.setTouched(touchedFields)
      }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Plan List"
            navigate="/plan-list/student"
            breadcrumbItem={`Edit Mock Test Price`}
          />
          {loading && <AppLoader />}
          {!loading && (
            <Card>
              <CardBody>
                <AppForm
                  name=""
                  layoutType={"UPDATE"}
                  fields={mockTestPriceFields}
                  initialValues={{
                    mockTestPrice: mockTestPriceDetail
                      ? mockTestPriceDetail.mockTestPrice
                      : "0",
                  }}
                  handleFormSubmit={(formikValues, formik) => {
                    handleFormSubmit(formikValues, formik)
                  }}
                  cancelRoute={`/plan-list/student`}
                />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditMockTestPrice
