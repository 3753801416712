import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { auth } from "../../firebase.config"
import MetaTags from "../../MetaTags"

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"

import { Link, useParams } from "react-router-dom"
import { Col, Container, Form, Row, Spinner } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import { registerUser, apiError, registerUserFailed } from "../../store/actions"
import logolight from "../../assets/images/qapreneur/logo-light.png"
import CarouselPage from "./CarouselPage"
import { getRoles as onGetRoles } from "../../store/roles/actions"
import { useSelector, useDispatch } from "react-redux"
import "./authentication.css"
import AppInput from "../../components/Common/AppInput"
import { renderFormFields } from "./Utiils"

const Register = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const [role, setRole] = useState("")
  const [isMobile, setIsMobile] = useState(false)
  const [showOtpField, setShowOtpField] = useState(false)

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    dispatch(onGetRoles())
  }, [dispatch])

  const { roles } = useSelector(state => ({
    roles: state.roles.roles,
  }))

  useEffect(() => {
    if (roles && roles.length > 0) {
      const foundRole = roles.find(r => r.role === params.category)
      setRole(foundRole)
    }
  }, [roles])

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  const activeRoles = roles.filter(
    role => role.status === "active" && role.role !== "admin"
  )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      roleId: role?._id,
      password: "",
      passwordConfirm: "",
      contactNumber: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Email"),
      name: Yup.string().required("Please Enter Name"),
      phone: Yup.string().required("Please Enter Phone"),
      roleId: Yup.string().required("Please Select Role"),
      password: Yup.string().min(8).required("Please Enter Password"),
      passwordConfirm: Yup.string()
        .label("confirm password")
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: values => {
      setShowOtpField(true)
      sendOtp(values)
    },
    isSubmitting: false,
  })

  const otpValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("Please Enter OTP")
        .matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
    }),
    onSubmit: values => {
      verifyOtp(values.otp.toString())
    },
    isSubmitting: false,
  })

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container"
      )
    }
  }

  function sendOtp(values) {
    onCaptchVerify()
    const appVerifier = window.recaptchaVerifier
    const formatPh = "+91" + values.phone
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult
      })
      .catch(error => {
        // setLoading(false);
      })
  }

  const verifyOtp = otp => {
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult
      confirmationResult
        .confirm(otp)
        .then(result => {
          setShowOtpField(false)
          signUpUser(validation.values)
          // let user = result.user
        })
        .catch(error => {
          let errorMessage = "An error occurred. Please try again."
          console.log(error, "error??")
          if (error.code === "auth/invalid-verification-code") {
            errorMessage =
              "Invalid verification code. Please check the code and try again."
          } else if (error.code === "auth/code-expired") {
            errorMessage =
              "The verification code has expired. Please request a new code."
          }
          toastr.error(errorMessage)
        })
    }
  }

  const signUpUser = values => {
    const findRole = roles.find(r => r.role === params.category)
    if (findRole) {
      if (findRole.role === "company" || findRole.role === "college") {
        const companyData = {
          ...values,
          country: "",
          state: "",
          city: "",
          address: "",
          status: ["active", "deactive"],
          contactNumber: values.phone,
          pointOfContact: values?.pointOfContact || "",
          extra: {
            about: "",
            industryServe: "",
            contactNumber: "",
            website: "",
            establishment: "",
            contactPersonName: values?.pointOfContact || "",
          },
        }
        dispatch(registerUser(companyData))
      } else {
        dispatch(
          registerUser({
            ...values,
            extra: {
              contactNumber: "",
            },
          })
        )
      }
    }
  }

  if (registrationError) {
    toastr.error(registrationError)
    dispatch(registerUserFailed(null))
  }

  const formFields = renderFormFields(role)

  return (
    <React.Fragment>
      {params.category === "student" && <MetaTags page={"studentSignUp"} />}
      {params.category === "company" && <MetaTags page={"companySignUp"} />}
      {params.category === "college" && <MetaTags page={"collegeSignUp"} />}
      {params.category === "coaching" && <MetaTags page={"coachingSignUp"} />}
      <div>
        <Container fluid className="p-0 overflow-hidden">
          <Row className="g-0">
            <CarouselPage role={params?.category} />
            <Col xl={5} lg={5} md={12} sm={12}>
              <div className="auth-full-page-content p-md-5 p-4 vh-lg-100">
                <div className="w-100 auth_content_container">
                  <div className="d-flex flex-column h-100">
                    {isMobile ? (
                      <div className="mb-4 mb-md-5">
                        <Link to="dashboard" className="d-block auth-logo">
                          <img
                            src={logolight}
                            alt={"qapreneur"}
                            height="64.75px"
                            width="275.5px"
                            className="auth-logo-dark"
                          />
                        </Link>
                      </div>
                    ) : null}
                    <div className="my-auto">
                      <div className="mb-3">
                        <h5 className="text-primary page_title">
                          Registration
                        </h5>
                        <div id="recaptcha-container"></div>
                        <p className="text-muted page_description">
                          Register to continue QAPRENEUR.
                        </p>
                      </div>

                      <div className="mt-3">
                        {!showOtpField && (
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            {formFields.length > 0 &&
                              formFields.map((field, key) => {
                                return (
                                  <div className="mb-3" key={key}>
                                    <AppInput
                                      // label={field.label}
                                      name={field.name}
                                      className="input_field"
                                      type={field.type}
                                      placeholder={field.placeholder}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values[field.name]}
                                      invalid={
                                        validation.touched[field.name] &&
                                        validation.errors[field.name]
                                      }
                                      error={validation.errors[field.name]}
                                    />
                                  </div>
                                )
                              })}

                            <div className="py-1">
                              <p className="mb-0 txt_terms_condition">
                                By registering you agree to the qapreneur{" "}
                                <a href="#" className="text-primary">
                                  Terms of Use
                                </a>
                              </p>
                            </div>

                            <div className="mt-3 d-grid text-center">
                              <button
                                className="btn btn-primary waves-effect waves-light custom_btn "
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <Spinner
                                    className="ms-2"
                                    color="light"
                                    size="sm"
                                  />
                                ) : (
                                  "Register"
                                )}
                              </button>
                            </div>
                          </Form>
                        )}
                        {showOtpField && (
                          <>
                            <Form
                              className="form-horizontal"
                              onSubmit={e => {
                                e.preventDefault()
                                otpValidation.handleSubmit()
                                return false
                              }}
                            >
                              <p className="text-muted page_description">
                                We Sent OTP On This Mobile Number{" "}
                                {validation.values.phone &&
                                  `******${validation.values.phone.slice(-2)}`}
                              </p>
                              <div className="mb-3">
                                <AppInput
                                  label={"Verify Your OTP Code"}
                                  name="otp"
                                  className="input_field otp_container"
                                  type="text"
                                  placeholder="Please Enter 6 Digit Code"
                                  onChange={otpValidation.handleChange}
                                  onBlur={otpValidation.handleBlur}
                                  value={otpValidation.values?.otp}
                                  invalid={
                                    otpValidation.touched?.otp &&
                                    otpValidation.errors?.otp
                                  }
                                  error={otpValidation.errors?.otp}
                                />
                              </div>
                              <div className="mt-3 d-grid text-center">
                                <button
                                  className="btn btn-primary waves-effect waves-light custom_btn"
                                  type="submit"
                                  disabled={loading}
                                >
                                  Submit
                                </button>
                              </div>
                            </Form>
                          </>
                        )}
                        <div className="mt-4 text-center">
                          <p className="txt_noAccount">
                            Already have an account ?
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register

Register.propTypes = {
  history: PropTypes.object,
}
