import { del, get, post, patch } from "../api_helper"
import { USER_ROUTES, COMPANY_ROUTES } from "../url_helper"

/** Companies */
// add Company
export const addNewCompany = company =>
  post(USER_ROUTES, company, {
    headers: {
      "Content-Type":
        !company.image || typeof company?.image?.name == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })

// update Company
export const updateCompany = (slug, company, companyId) =>
  post(`/${slug}/${companyId}`, company, {
    headers: {
      "Content-Type":
        !company.image || typeof company?.company == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })

// delete Company
export const deleteCompany = companyId =>
  del(`${USER_ROUTES}/${companyId}`, { headers: { companyId } })

// get company
export const getCompanies = queryParams =>
  get(`${USER_ROUTES}?roleName=company${queryParams}`)

// get company details
export const getCompaniesDetails = (id, slug) => get(`${slug}/${id}`)
// get company billing details
export const getCompanyBillingDetails = id =>
  get(`${COMPANY_ROUTES}/billing/${id}`)
// add company billing details
export const addCompanyBillingDetails = (id, data) =>
  post(`${COMPANY_ROUTES}/billing/${id}`, data)
// update company billing details
export const updateCompanyBillingDetails = (id, data) =>
  patch(`${COMPANY_ROUTES}/billing/${id}`, data)

// get company  profile details
export const getProfileDetails = () => get(`/users/me`)

// update company profile details
export const updateProfileDetails = (data, id) => {
  return patch(`/users/updateMe`, data, {
    headers: {
      "Content-Type":
        !data.image || typeof data?.image == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })
}

// add hr user
export const registerHrUser = data => {
  return post(`${COMPANY_ROUTES}/hrUser/register`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}

// get hr user
export const getAllHrUser = (companyId, queryParams) =>
  get(`${COMPANY_ROUTES}/hrUser/${companyId}${queryParams}`)

export const updateHrUser = (hr, hrId) => {
  return patch(`${COMPANY_ROUTES}/hr/${hrId}`, hr, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}

// get candidates
export const getCandidatesByUserId = (userId, queryParams) => {
  return get(
    `${COMPANY_ROUTES}/${userId}/candidates${queryParams ? queryParams : ""}`
  )
}

// get my candidates
export const getMyCandidatesByUserId = (userId, queryParams) => {
  if (queryParams) {
    return get(
      `${COMPANY_ROUTES}/${userId}/my-candidates${
        queryParams ? queryParams : ""
      }`
    )
  } else {
    return get(`${COMPANY_ROUTES}/${userId}/my-candidates?companyId=${userId}`)
  }
}

// get unlock candidates
export const getUnlockCandidates = queryParams =>
  get(`${COMPANY_ROUTES}/unlock-candidate/${queryParams}`)

// update applicants
export const unlockCandidateApi = candidateId =>
  post(`${COMPANY_ROUTES}/unlock-candidate/${candidateId}`)

// get user - company view history
export const getUserCompanyViewHistory = candidateId => {
  return get(`${COMPANY_ROUTES}/view-candidate/${candidateId}`)
}


// get user - company view history
export const getCompanyCreditDetails = companyId => {
  return get(`${COMPANY_ROUTES}/credit/${companyId}`)
}