import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
//redux
import { useSelector, useDispatch } from "react-redux"
import classNames from "classnames"
import { Link, useNavigate } from "react-router-dom"
// Pages Components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import WelcomeComp from "./WelcomeComp"
import UserRolesPieChart from "./UserRolesPieChart"
import Reports from "./Reports"
import AppChart from "../../components/Common/AppChart/index"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import AppLoader from "../../components/Common/Loader"
import TableContainer from "../../components/Common/Table/index"

//import action
import {
  getChartsData as onGetChartsData,
  getAdminDashboardData as onGetAdminDashboardData,
  getJobChartsData as onGetJobChartsData,
  getTransactionChartsData as onGetTransactionChartsData,
  getProfileStatusChartsData as onGetProfileStatusChartsData,
} from "./../../store/actions"
import {
  dashboardTiles,
  transactionBarChartData,
  jobBarChartData,
  barChartOptions,
  profileStatusBarChartData,
  getPieChartData,
} from "./Utills"
import { profileStstuaTableColumns } from "./constants"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | QAPRENEUR"
  const [jobStatus, setJobStatus] = useState("year")
  const [transactionStatus, setTransactionStatus] = useState("year")
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState()
  const [profileStatusModal, setProfileStatusModal] = useState(false)
  const [profileStatusData, setProfileStatusData] = useState(null)
  const dispatch = useDispatch()

  const {
    dashboardData,
    jobBarChart,
    transactionBarChart,
    profileStatusChart,
  } = useSelector(state => ({
    ...state.Dashboard,
  }))

  const reports =
    dashboardData && dashboardData?.dashboard
      ? dashboardTiles(dashboardData)
      : []

  useEffect(() => {
    dispatch(onGetAdminDashboardData())
    dispatch(onGetChartsData("yearly"))
    dispatch(onGetProfileStatusChartsData())
    const isUser = localStorage.getItem("authUser")
    if (isUser) {
      setUser(JSON.parse(isUser))
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(onGetTransactionChartsData(transactionStatus))
  }, [transactionStatus])

  useEffect(() => {
    dispatch(onGetJobChartsData(jobStatus))
  }, [jobStatus])

  useEffect(() => {
    if (
      Object.keys(transactionBarChart).length > 0 &&
      Object.keys(jobBarChart).length > 0 &&
      Object.keys(profileStatusChart).length > 0 &&
      dashboardData?.dashboard
    ) {
      setIsLoading(false)
    }
  }, [
    transactionBarChart,
    jobBarChart,
    profileStatusChart,
    dashboardData?.dashboard,
  ])

  const onChangeChartPeriod = (type, pType) => {
    if (type === "TXN") {
      setTransactionStatus(pType)
    }
    if (type === "JOB") {
      setJobStatus(pType)
    }
  }
  // Job bar Chart
  const jobBarChartDatasets = jobBarChart && jobBarChartData(jobBarChart)
  // transaction bar Chart
  const transactionBarChartDatasets =
    transactionBarChart && transactionBarChartData(transactionBarChart)
  // profile bar Chart
  const profileStatusBarChartDatasets =
    profileStatusBarChartData(profileStatusChart)

  const toggleProfileStatusdmodal = () =>
    setProfileStatusModal(!profileStatusModal)

  const handleBarClick = (event, chart) => {
    // /api/v5/dashboards/admin/user-profile-status/barChart?chartLabel=Mar&datasetsValue=100&page=1&limit=2
    // need to implemnt pagination
    if (!chart) return

    const activeElements = chart.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      false
    )

    if (activeElements.length > 0) {
      const firstElement = activeElements[0]
      const dataIndex = firstElement.index
      const datasetIndex = firstElement.datasetIndex
      const dataset = chart.data.datasets[datasetIndex]
      const label = chart.data.labels[dataIndex]
      const datasets = chart.data.datasets[datasetIndex]
      const clickedData = dataset.data[dataIndex]
      setProfileStatusData({
        datasets,
        label,
        clickedData,
        monthlyData: profileStatusChart[label]
          .filter(chartData => chartData.profileCompletion === datasets.value)
          .map(chartData => ({ ...chartData, _id: chartData?.userId })),
      })
      toggleProfileStatusdmodal()
    }
  }
  // users Pie Chart
  const pieChartData =
    dashboardData && dashboardData?.dashboard && dashboardData?.dashboard?.users
      ? getPieChartData(dashboardData?.dashboard?.users)
      : null

  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading ? (
          <Container fluid>
            <Breadcrumbs
              title={props.t("Dashboards")}
              breadcrumbItem={props.t("Dashboard")}
            />
            <Row>
              <Col xl="4">
                <WelcomeComp user={user ? user : null} type={"admin"} />
                <UserRolesPieChart pieChartData={pieChartData} />
              </Col>
              <Col xl="8">
                <Row>
                  <Reports reports={reports} />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">Job Status</h4>
                      <div className="ms-auto">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: jobStatus === "year" },
                                "nav-link"
                              )}
                              onClick={() => {
                                onChangeChartPeriod("JOB", "year")
                              }}
                              id="one_month"
                              style={{ textTransform: "Uppercase" }}
                            >
                              Year
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <AppChart
                      data={jobBarChartDatasets}
                      options={barChartOptions}
                      chartType="bar"
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xs={6}>
                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">Transaction</h4>
                      <div className="ms-auto">
                        <ul className="nav nav-pills">
                          {["week", "month", "year"].map((item, key) => {
                            return (
                              <li className="nav-item" key={key}>
                                <Link
                                  to="#"
                                  className={classNames(
                                    { active: transactionStatus === item },
                                    "nav-link"
                                  )}
                                  onClick={() => {
                                    onChangeChartPeriod("TXN", item)
                                  }}
                                  id={`one-${item}`}
                                  style={{ textTransform: "Uppercase" }}
                                >
                                  {item}
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                    <AppChart
                      data={transactionBarChartDatasets}
                      options={barChartOptions}
                      chartType="bar"
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xs={6}>
                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">
                        Profile Completion Students
                      </h4>
                    </div>
                    <AppChart
                      data={profileStatusBarChartDatasets}
                      options={barChartOptions}
                      chartType="bar"
                      onBarClick={(event, chart) =>
                        handleBarClick(event, chart)
                      }
                    />
                    {profileStatusModal && (
                      <InfomationModal
                        modalBackdrop={profileStatusModal}
                        setmodalBackdrop={setProfileStatusModal}
                        fullscreen={true}
                        title={`Students ${
                          profileStatusData
                            ? profileStatusData?.datasets?.label
                            : "This"
                        } Profile Completion Of ${
                          profileStatusData ? profileStatusData?.label : "This"
                        } Month`}
                        size="md"
                      >
                        {profileStatusData?.monthlyData && (
                          <TableContainer
                            loading={false}
                            data={profileStatusData?.monthlyData}
                            isGlobalFilter={false}
                            isAddOptions={false}
                            isJobListGlobalFilter={false}
                            customPageSize={200}
                            className="project-list-table table-nowrap align-middle table-borderless"
                            columns={profileStstuaTableColumns}
                            showPageDropdown={false}
                          />
                        )}
                      </InfomationModal>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <AppLoader />
        )}
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
