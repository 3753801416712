import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"
import {
  getCourses as onGetCourses,
  updateCourse as onUpdateCourse,
  deleteCourse as onDeleteCourse,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import TableContainer from "../../components/Common/Table/index"
import { courseColumns } from "./constants"

const CoursesList = props => {
  document.title = "Course List | QAPRENEUR"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [course, setCourse] = useState()
  const [courseList, setCourseList] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { courses, loading } = useSelector(state => ({
    courses: state.courses.courses,
    loading: state.courses.loading,
  }))

  useEffect(() => {
    const params = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      params.search = search
    }
    dispatch(onGetCourses(params))
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(courses)) {
      setCourseList(
        courses.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setCourseList([])
    }
  }, [courses, pageNo])

  const modalAction = () => {
    if (course && course._id) {
      const params = {
        page: pageNo,
        limit: rowPerPage,
      }
      dispatch(onDeleteCourse(course._id, params))
    }
    setDeleteModal(false)
  }

  const handleChangeStatus = course => {
    const status = course.status === "active" ? "deactive" : "active"
    dispatch(onUpdateCourse({ status }, course._id, navigate))
  }

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  const handleActions = (course, type) => {
    if (type === "VIEW") {
      navigate(`/course-details/${course._id}`)
    } else if (type === "EDIT") {
      navigate(`/courses-edit/${course._id}`)
    } else if (type === "DELETE") {
      setCourse(course)
      setDeleteModal(true)
    } else if (type === "VIEW_STUDENT_LIST") {
      navigate(`/purchased-course-users/${course._id}`)
    }
  }

  const handleSearch = query => {
    setSearch(query)
  }

  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the course?."}
          modalAction={modalAction}
          loading={props.loading}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Courses"
            navigate="/courses-list"
            breadcrumbItem="Courses"
          />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={courseList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  handleStatus={handleChangeStatus}
                  handleSearch={handleSearch}
                  onChangePagination={onChangePagination}
                  showPagination={true}
                  handleActions={handleActions}
                  addButton={{
                    link: "/courses-create",
                    title: "Add New Course",
                  }}
                  columns={courseColumns}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CoursesList
