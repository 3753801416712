export const renderFormFields = role => {
  const fields = [
    {
      label: `Company Name`,
      name: "name",
      className: "input_field",
      type: "text",
      placeholder: "Enter company name",
    },
    {
      label: "Email",
      name: "email",
      className: "input_field",
      type: "text",
      placeholder: "Enter email",
    },
    {
      label: "Point of Contact",
      name: "pointOfContact",
      className: "input_field",
      type: "text",
      placeholder: "Point of Contact",
    },
    {
      label: "Contact Number",
      name: "phone",
      className: "input_field",
      type: "text",
      placeholder: "Contact Number",
    },
    {
      label: "Password",
      name: "password",
      className: "input_field",
      type: "password",
      placeholder: "Password",
    },
    {
      label: "Confirm Password",
      name: "passwordConfirm",
      className: "input_field",
      type: "password",
      placeholder: "Confirm Password",
    },
  ]

//   if (role.role === "company") {
//     fields.unshift({
//       label: `Company Name`,
//       name: "name",
//       className: "input_field",
//       type: "text",
//       placeholder: "Enter company name",
//     })
//   }
  //    else if (role.role === "college") {
  //     fields.unshift({
  //       label: `College Name`,
  //       name: "name",
  //       className: "input_field",
  //       type: "text",
  //       placeholder: "Enter College name",
  //     })
  //   } else {
  //     fields.splice(1, 1)
  //     fields.unshift({
  //       label: `Company Name`,
  //       name: "name",
  //       className: "input_field",
  //       type: "text",
  //       placeholder: "Enter company name",
  //     })
  //   }
  return fields
}
