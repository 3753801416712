import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
import loginTopImg from "../../assets/images/banners/imgTopLeft.svg"
import topRightImg from "../../assets/images/banners/topRight.svg"
import bottomLeftImg from "../../assets/images/banners/bottomLeft.svg"
import bottomRightImg from "../../assets/images/banners/bottomRight.svg"
import rightIcon from "../../assets/images/rightIcon.svg"
import logolight from "../../assets/images/qapreneur/logo-light.png"
import { Link } from "react-router-dom"

const CarouselPage = ({ role = "admin" }) => {
  return (
    <React.Fragment>
      <Col xl={7} lg={7} md={12} sm={12}>
        <div className="auth-full-bg pt-lg-5 p-4 login_banner vh-sm-100">
          <div className="carousel_logo">
            <Link className="d-block auth-logo">
              <img
                src={logolight}
                alt={"qapreneur"}
                height="64.75px"
                width="210.5px"
                className="logo-dark-element"
              />
            </Link>
          </div>
          <div className="w-100 h-100 banner_content_outer">
            <div className="d-flex h-100 flex-column">
              <div className="p-0 p-md-4 m-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="banner_content">
                      <h1>
                        Over 10 Years in{" "}
                        <span className="txt_hltd">Distant learning</span> for
                        Skill Development
                      </h1>
                      {role === "company" && (
                        <p className="pb-2">
                          Bridging gap between academia and industry by
                          enriching the educational experience for students
                          pursuing careers in IT domain
                        </p>
                      )}
                      {(role === "college" || role === "coaching") && (
                        <p className="pb-2">
                          Bridging gap between academia and industry by
                          enriching the educational experience for students
                          pursuing careers in IT domain.
                        </p>
                      )}
                      {role === "student" && (
                        <p className="pb-2">
                          Your next career move awaits! Sign up to explore
                          exciting job opportunities and let's build your
                          professional journey together.
                        </p>
                      )}
                      {role === "user_login" && (
                        <p className="pb-2">
                          Unlock a world of opportunities — Welcome Back! Log in
                          and dive into a realm of software testing
                        </p>
                      )}
                      <ul className="px-0">
                        {role === "company" && (
                          <>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">
                                  {" "}
                                  Hire the best Talent
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">Certified Resources</p>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">
                                  Faster Time-to-Market
                                </p>
                              </div>
                            </li>
                          </>
                        )}
                        {(role === "college" || role === "coaching") && (
                          <>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">
                                  Professional Courses
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">
                                  Industry Standard Process
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">
                                  Affordable Membership Plans for Students
                                </p>
                              </div>
                            </li>
                          </>
                        )}
                        {role === "student" && (
                          <>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">
                                  Job-Oriented Courses
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">Assessment Tests</p>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">Top Hiring Partners</p>
                              </div>
                            </li>
                          </>
                        )}
                        {role === "user_login" && (
                          <>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">
                                  QA-Oriented Courses and Tests
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">Top Hiring Partners</p>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  alt={"qapreneur"}
                                  src={rightIcon}
                                  height={22}
                                  width={22}
                                />
                                <p className="mx-3 my-0">
                                  {" "}
                                  Diverse Talent Pool
                                </p>
                              </div>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="topLeft_img">
            <img alt={"qapreneur"} src={loginTopImg} height={400} width={500} />
          </div>

          <div className="topRight_img">
            <img alt={"qapreneur"} src={topRightImg} height={200} width={200} />
          </div>

          <div className="bottomLeft_img">
            <img
              alt={"qapreneur"}
              src={bottomLeftImg}
              height={200}
              width={200}
            />
          </div>

          <div className="bottomRight_img">
            <img
              alt={"qapreneur"}
              src={bottomRightImg}
              height={150}
              width={150}
            />
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
