import {
  GET_COLLEGE_REQUESTS_FAIL,
  GET_COLLEGE_REQUESTS_SUCCESS,
  UPDATE_COLLEGE_REQUESTS_FAIL,
  UPDATE_COLLEGE_REQUESTS_LOADING_STATE,
  UPDATE_COLLEGE_REQUESTS_SUCCESS,
  GET_COLLEGE_REQUESTS,
  UPDATE_COLLEGE_REQUESTS
} from "./actionTypes"

export const getcollegeRequests = (params) => ({
  type: GET_COLLEGE_REQUESTS,
  params
})

export const getCollegeRequestsSuccess = collegeRequests => ({
  type: GET_COLLEGE_REQUESTS_SUCCESS,
  payload: collegeRequests,
})

export const getCollegeRequestsFail = error => ({
  type: GET_COLLEGE_REQUESTS_FAIL,
  payload: error,
})

export const updateCollegeRequests = (collegeRequest, collegeRequestId) => ({
  type: UPDATE_COLLEGE_REQUESTS,
  payload: { collegeRequest, collegeRequestId },
})

export const updateCollegeRequestsuccess = hr => ({
  type: UPDATE_COLLEGE_REQUESTS_SUCCESS,
  payload: hr,
})

export const updateCollegeRequestsFail = error => ({
  type: UPDATE_COLLEGE_REQUESTS_FAIL,
  payload: error,
})


export const updateCollegeRequesLoadingState = status => ({
  type: UPDATE_COLLEGE_REQUESTS_LOADING_STATE,
  payload: status,
})