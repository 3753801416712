import multiMedia from "store/multiMedia/reducer"
import {
  GET_MULTI_MEDIA_CONTENT,
  GET_MULTI_MEDIA_CONTENT_FAIL,
  GET_MULTI_MEDIA_CONTENT_SUCCESS,
  ADD_MULTI_MEDIA_CONTENT,
  ADD_MULTI_MEDIA_CONTENT_SUCCESS,
  ADD_MULTI_MEDIA_CONTENT_FAIL,
  UPDATE_MULTI_MEDIA_CONTENT,
  UPDATE_MULTI_MEDIA_CONTENT_SUCCESS,
  UPDATE_MULTI_MEDIA_CONTENT_FAIL,
  DELETE_MULTI_MEDIA_CONTENT,
  DELETE_MULTI_MEDIA_CONTENT_SUCCESS,
  DELETE_MULTI_MEDIA_CONTENT_FAIL,
  UPDATE_MULTI_MEDIA_CONTENT_LOADING_STATE,
  RESET_MULTI_MEDIA_CONTENT,
} from "./actionTypes"

export const getMultiMediaContent = (params) => ({
  type: GET_MULTI_MEDIA_CONTENT,
  params
})

export const getMultiMediaContentSuccess = multiMedia => ({
  type: GET_MULTI_MEDIA_CONTENT_SUCCESS,
  payload: multiMedia,
})

export const getMultiMediaContentFail = error => ({
  type: GET_MULTI_MEDIA_CONTENT_FAIL,
  payload: error,
})

export const updateMultiMediaContent = (multiMediaContent, multiMediaId, history) => ({
  type: UPDATE_MULTI_MEDIA_CONTENT,
  payload: { multiMediaContent, multiMediaId, history },
})

export const updateMultiMediaContentSuccess = multiMediaContent => ({
  type: UPDATE_MULTI_MEDIA_CONTENT_SUCCESS,
  payload: multiMediaContent,
})

export const updateMultiMediaContentFail = error => ({
  type: UPDATE_MULTI_MEDIA_CONTENT_FAIL,
  payload: error,
})

export const deleteMultiMediaContent = (multiMediaId, history) => ({
  type: DELETE_MULTI_MEDIA_CONTENT,
  payload: { multiMediaId, history },
})

export const deleteMultiMediaContentSuccess = multiMediaId => ({
  type: DELETE_MULTI_MEDIA_CONTENT_SUCCESS,
  payload: { multiMediaId },
})

export const deleteMultiMediaContentFail = error => ({
  type: DELETE_MULTI_MEDIA_CONTENT_FAIL,
  payload: error,
})

export const addMultiMediaContent = (multiMediaContent, history) => ({
  type: ADD_MULTI_MEDIA_CONTENT,
  payload: { multiMediaContent, history },
})

export const addMultiMediaContentSuccess = multiMediaContent => ({
  type: ADD_MULTI_MEDIA_CONTENT_SUCCESS,
  payload: multiMediaContent,
})

export const addMultiMediaContentFail = error => ({
  type: ADD_MULTI_MEDIA_CONTENT_FAIL,
  payload: error,
})

export const updateMultiMediaContentLoadingState = status => ({
  type:  UPDATE_MULTI_MEDIA_CONTENT_LOADING_STATE ,
  payload: status,
})

export const resetMultiMediaContent = () => ({
  type: RESET_MULTI_MEDIA_CONTENT
})
