/* JOBS */
export const GET_JOBS = "GET_JOBS"
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS"
export const GET_JOBS_FAIL = "GET_JOBS_FAIL"
export const RESET_JOBS = "RESET_JOBS"

/* JOBS DETAIL*/
export const GET_JOB_DETAIL = "GET_JOB_DETAIL"
export const GET_JOB_DETAIL_SUCCESS = "GET_JOB_DETAIL_SUCCESS"
export const GET_JOB_DETAIL_FAIL = "GET_JOB_DETAIL_FAIL"
export const RESET_JOB_DETAIL = "RESET_JOB_DETAIL"


/**
 * add test
 */
export const ADD_NEW_JOB = "ADD_NEW_JOB"
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS"
export const ADD_JOB_FAIL = "ADD_JOB_FAIL"

/**
 * Edit test
 */
export const UPDATE_JOB = "UPDATE_JOB"
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS"
export const UPDATE_JOB_FAIL = "UPDATE_JOB_FAIL"

/**
 * Delete test
 */
export const DELETE_JOB = "DELETE_JOB"
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS"
export const DELETE_JOB_FAIL = "DELETE_JOB_FAIL"

export const UPDATE_JOB_LOADING_STATE = "UPDATE_JOB_LOADING_STATE"


/* HR JOBS */
export const GET_JOBS_By_HR = "GET_JOBS_By_HR"
export const GET_JOBS_By_HR_SUCCESS = "GET_JOBS_By_HR_SUCCESS"
export const GET_JOBS_By_HR_FAIL = "GET_JOBS_By_HR_FAIL"

/* HR JOBS */
export const GET_USER_APPLIED_JOB = "GET_USER_APPLIED_JOB"
export const GET_USER_APPLIED_JOB_SUCCESS = "GET_USER_APPLIED_JOB_SUCCESS"
export const GET_USER_APPLIED_JOB_FAIL = "GET_USER_APPLIED_JOB_FAIL"