/**
 * Add Multi Media Content
 */
 export const ADD_MULTI_MEDIA_CONTENT = "ADD_MULTI_MEDIA_CONTENT"
 export const ADD_MULTI_MEDIA_CONTENT_SUCCESS = "ADD_MULTI_MEDIA_CONTENT_SUCCESS"
 export const ADD_MULTI_MEDIA_CONTENT_FAIL = "ADD_MULTI_MEDIA_CONTENT_FAIL"
 
 /**
  * Get Multi Media Content
  */
 export const GET_MULTI_MEDIA_CONTENT = "GET_MULTI_MEDIA_CONTENT"
 export const GET_MULTI_MEDIA_CONTENT_SUCCESS = "GET_MULTI_MEDIA_CONTENT_SUCCESS"
 export const GET_MULTI_MEDIA_CONTENT_FAIL = "GET_MULTI_MEDIA_CONTENT_FAIL"
 
 /**
  * Edit Multi Media Content
  */
 export const UPDATE_MULTI_MEDIA_CONTENT = "UPDATE_MULTI_MEDIA_CONTENT"
 export const UPDATE_MULTI_MEDIA_CONTENT_SUCCESS = "UPDATE_MULTI_MEDIA_CONTENT_SUCCESS"
 export const UPDATE_MULTI_MEDIA_CONTENT_FAIL = "UPDATE_MULTI_MEDIA_CONTENT_FAIL"
 
 /**
  * Delete Multi Media Content
  */
 export const DELETE_MULTI_MEDIA_CONTENT = "DELETE_MULTI_MEDIA_CONTENT"
 export const DELETE_MULTI_MEDIA_CONTENT_SUCCESS = "DELETE_MULTI_MEDIA_CONTENT_SUCCESS"
 export const DELETE_MULTI_MEDIA_CONTENT_FAIL = "DELETE_MULTI_MEDIA_CONTENT_FAIL"
 
 export const UPDATE_MULTI_MEDIA_CONTENT_LOADING_STATE = "UPDATE_MULTI_MEDIA_CONTENT_LOADING_STATE"
 export const RESET_MULTI_MEDIA_CONTENT = "RESET_MULTI_MEDIA_CONTENT"
 