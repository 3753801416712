import React from "react"
import { Col, Row } from "reactstrap"
function Loader({ message = "Load more" }) {
  return (
    <Row>
      <Col xs="12">
        <div className="text-center my-3">
          <div className="text-success">
            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
            {message}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Loader
