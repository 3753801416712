import React, { useState } from "react"
import OpenToJob from "../../../assets/images/users/open_to_job_bg.png"
import OpenToQa from "../../../assets/images/users/open_to_qa_bg.png"
import { Button, Row, Col, Label } from "reactstrap"
import InfomationModal from "../Modal/InfomationModal"
import Dropzone from "react-dropzone"
import userAvtar from "../../../assets/images/avtar/userAvtar.png";
function UserProfile({
  showFrame,
  imageUrl,
  width = 70,
  height = 70,
  size = "md",
  handleUploadImage = null,
  handleSubmit = null,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [uploadedImage, setUploadedImage] = useState(null)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleImageUpload = acceptedFiles => {
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.onload = () => {
      setUploadedImage(reader.result)
      if (handleUploadImage) {
        handleUploadImage(acceptedFiles)
      }
    }
    reader.readAsDataURL(file)
  }

  return (
    <>
      {showFrame ? (
        <div
          className="d-flex gap-3 align-items-center justify-content-center position-relative"
          style={{ width, height }}
        >
          <img
            src={imageUrl}
            alt="qapreneur"
            className={`avatar-${size} rounded-circle img-thumbnail position-absolute`}
          />
          <img
            src={showFrame === "JOB" ? OpenToJob : OpenToQa}
            className={`avatar-${size} rounded-circle frame-thumbnail position-absolute`}
            alt="qapreneur"
            style={{ backgroundColor: "transparent" }}
          />
        </div>
      ) : (
        <div className="d-flex gap-3 align-items-center justify-content-center position-relative">
          <img
            src={imageUrl}
            alt="qapreneur"
            className={`avatar-${size} rounded-circle frame-thumbnail`}
          />
          {handleUploadImage && (
            <div
              className="position-absolute rounded-circle d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "#F89752",
                width: 20,
                height: 20,
                padding: 5,
                right: 10,
                bottom: 15,
                transform: "translate(50%, 50%)",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={toggleModal}
            >
              <span className="mdi mdi-pencil-outline font-size-10"></span>
            </div>
          )}
        </div>
      )}
      {isModalOpen && (
        <InfomationModal
          modalBackdrop={isModalOpen}
          setmodalBackdrop={setIsModalOpen}
          handleAction={handleUploadImage}
          title={"Upload Photo"}
          size="lg"
          height={450}
          showHeader={false}
        >
          <Row className="p-4">
            <Col
              className="d-flex justify-content-between align-items-center"
              xs={4}
            >
              {uploadedImage ? (
                <img
                  src={uploadedImage}
                  alt="Uploaded"
                  className="img-fluid rounded-circle ms-4"
                  style={{ width: 130, height: 130 }}
                />
              ) : (
                <img
                  src={userAvtar}
                  alt="Uploaded"
                  className="img-fluid rounded-circle ms-4"
                  style={{ width: 130, height: 130 }}
                />
              )}
            </Col>
            <Col className="d-flex flex-column justify-content-center" xs={8}>
              <Label className="form-label">Upload Photo</Label>

              <Dropzone maxFiles={1} onDrop={handleImageUpload}>
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input name="image" {...getInputProps()} />
                      <div className="mb-3">
                        <span
                          className="display-4 mdi mdi-message-image-outline"
                          style={{ color: "#5555" }}
                        ></span>
                      </div>
                      <h4>Upload or drop your file here.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Col>
          </Row>
          <Row className="position-absolute bottom-0 px-4 py-2 w-100 justify-content-end d-flex">
            <div className="justify-content-end d-flex">
              <Button
                type="button"
                onClick={toggleModal}
                className="btn me-3 primary global_btn"
              >
                <span className="mdi mdi-close-circle-outline me-2"></span>
                Cancel
              </Button>
              <Button
                type="submit"
                className="primary global_btn"
                onClick={() => {
                  handleSubmit()
                  toggleModal()
                }}
              >
                <span className="mdi mdi-plus-circle-outline me-2"></span>
                Save
              </Button>
            </div>
          </Row>
        </InfomationModal>
      )}
    </>
  )
}

export default UserProfile
