import {
  GET_MOCK_TESTS_FAIL,
  GET_MOCK_TESTS_SUCCESS,
  GET_MOCK_TEST_DETAIL_FAIL,
  GET_MOCK_TEST_DETAIL_SUCCESS,
  ADD_MOCK_TEST_SUCCESS,
  ADD_MOCK_TEST_FAIL,
  UPDATE_MOCK_TEST_SUCCESS,
  UPDATE_MOCK_TEST_FAIL,
  DELETE_MOCK_TEST_SUCCESS,
  DELETE_MOCK_TEST_FAIL,
  UPDATE_MOCK_TEST_LOADING_STATE,
} from "./actionTypes"

const INIT_STATE = {
  mockTests: [],
  mockTestDetail: {},
  error: {},
  loading: false,
  pagination:{page:1,limit:10}
}

const mockTests = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MOCK_TESTS_SUCCESS:
      return {
        ...state,
        mockTests:action.payload
      }

    case UPDATE_MOCK_TEST_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case GET_MOCK_TESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_MOCK_TEST_SUCCESS:
      return {
        ...state,
        mockTests: [...state.mockTests, action.payload],
      }

    case ADD_MOCK_TEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MOCK_TEST_DETAIL_SUCCESS:
      return {
        ...state,
        mockTestDetail: action.payload,
      }

    case UPDATE_MOCK_TEST_SUCCESS:
      return {
        ...state,
        mockTests: state.mockTests.map(test =>
          test._id.toString() === action.payload._id.toString()
            ? { test, ...action.payload }
            : test
        ),
      }

    case UPDATE_MOCK_TEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_MOCK_TEST_SUCCESS:
      return {
        ...state,
        mockTests: state.mockTests.filter(
          test => test._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_MOCK_TEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MOCK_TEST_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default mockTests
