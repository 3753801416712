import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import withRouter from "components/Common/withRouter"
import { formFields } from "./constants"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import ProfileHeader from "../../components/Common/Card/ProfileHeader"
import AppForm from "../../components/Common/Form/index"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import {
  getCoachingDetail as onGetCoachingDetail,
  updateCoaching as onUpdateCoaching,
} from "../../store/coachings/actions"
const staticURL = process.env.REACT_APP_STATIC_URL

const EditCoachingProfile = props => {
  //meta title
  document.title = "Profile | QAPRENEUR"
  const params = props.router.params
  const role = localStorage.getItem("authRole")


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [user, setUser] = useState({})

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const { loading, coachingDetail } = useSelector(state => ({
    loading: state.coachings.loading,
    coachingDetail: state.coachings?.coachingDetail,
  }))

  useEffect(() => {
    if (params.coachingId) {
      dispatch(onGetCoachingDetail(params.coachingId, "users/me"))
    }
  }, [dispatch, onGetCoachingDetail])

  useEffect(() => {
    if (coachingDetail) {
      setUser({ ...coachingDetail, ...coachingDetail?.extra })
    }
  }, [coachingDetail])

  const handleSubmit = formikValues => {
    const newObj = { ...user, ...user?.extra, ...formikValues }
    const data = {
      name: newObj?.name,
      email: newObj?.email,
      phone: newObj?.phone,
      address: newObj?.address,
      city: newObj?.city,
      state: newObj?.state,
      extra: {
        contactPersonName: newObj.contactPersonName,
        contactNumber: newObj.contactNumber,
        about: newObj.about,
        website: newObj.website,
        establishmentDate: newObj.establishmentDate,
      },
    }
    if(role==="admin") {
      dispatch(onUpdateCoaching("coaching", data, coachingDetail._id, navigate))
    }else{
      dispatch(onUpdateCoaching('updateMe',data,coachingDetail._id,navigate))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Dashboard"
            breadcrumbItem="Coaching Profile"
            navigate={-1}
          />
          {error && error ? <Alert color="danger">{error}</Alert> : null}
          {success ? <Alert color="success">{success}</Alert> : null}
          <Row>
            <Col lg="12">
              <ProfileHeader
                imageUrl={user && user.image ? staticURL + user.image : avatar}
                profileName={user ? user.name : ""}
                profileEmail={user ? user.email : ""}
                LayoutType={"HORIZONTAL"}
                style={{}}
              />
            </Col>
          </Row>
          <Card>
            <CardBody>
              <AppForm
                name="Coaching Information"
                layoutType="UPDATE"
                fields={formFields}
                // validation={validation}
                initialValues={user}
                handleFormSubmit={handleSubmit}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditCoachingProfile)
