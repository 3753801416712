import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_FLASH_CARD,
  ADD_FLASH_CARD,
  UPDATE_FLASH_CARD,
  DELETE_FLASH_CARD
} from "./actionTypes"
import {
  getflashCardSuccess,
  getflashCardFail,
  updateflashCardSuccess,
  updateflashCardFail,
  deleteflashCardSuccess,
  deleteflashCardFail,
  updateFlashcardLoadingState,
  addFlashCardFail,
  addFlashCardSuccess
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFlashCardByChapterId,
  addNewFlashCard,
  UpdateFlashCard,
  deleteFlashCard
} from "helpers/backend_helper"

function* fetchFlashCards({ chapterId, params }) {
  try {
    const { page, limit } = params
    let queryParams = `?page=${page}&limit=${limit}`
    yield put(updateFlashcardLoadingState(true))
    const response = yield call(getFlashCardByChapterId, chapterId, queryParams)
    yield put(getflashCardSuccess(response.data.flashCards))
    yield put(updateFlashcardLoadingState(false))
  } catch (error) {
    yield put(getflashCardFail(error))
  }
}



function* onUpdateflashCard({ payload: { flashCard, flashCardId, history } }) {
  try {
    const response = yield call(UpdateFlashCard, flashCard, flashCardId)
    yield put(updateflashCardSuccess(response.data.flashCard))
    toastr.success("Flash Card updated successfully..!!")
  } catch (error) {
    yield put(updateflashCardFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteFlashCard({ payload: { flashCardId, history } }) {
  try {
    const response = yield call(deleteFlashCard, flashCardId)
    toastr.success("flash Card deleted successfully..!!")
    yield put(deleteflashCardSuccess(flashCardId))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteflashCardFail(error))
  }
}


function* onAddFlashCard({ payload }) {
  const { flashCard, history } = payload
  try {
    yield put(updateFlashcardLoadingState(true))
    const response = yield call(addNewFlashCard, flashCard)
    toastr.success("Card Added Successfully..!!")
    const res = yield call(getFlashCardByChapterId, flashCard?.chapterId)
    yield put(updateFlashcardLoadingState(false))
    yield put(addFlashCardSuccess(response.data.flashCard))
    yield put(getflashCardSuccess(res.data.flashCards))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateFlashcardLoadingState(false))
    yield put(addFlashCardFail(error))
  }
}

function* flashCardsSaga() {
  yield takeEvery(GET_FLASH_CARD, fetchFlashCards)
  yield takeEvery(ADD_FLASH_CARD, onAddFlashCard)
  yield takeEvery(UPDATE_FLASH_CARD, onUpdateflashCard)
  yield takeEvery(DELETE_FLASH_CARD, onDeleteFlashCard)
}

export default flashCardsSaga
