import React from "react"
import { Input, Label, FormFeedback } from "reactstrap"

const AppTextArea = ({
  name,
  type,
  placeholder,
  className,
  onChange,
  onBlur,
  value,
  invalid,
  error,
  label,
  id,
  disabled,
  height
}) => {
  return (
    <div className="mb-3">
      <Label className="form-label">{label}</Label>
      <Input
        name={name}
        type={type}
        id={id}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        invalid={invalid}
        disabled={disabled}
        style={{ height: height }}
      />
      {invalid ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
    </div>
  )
}

export default AppTextArea
