import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Container } from "reactstrap"
import {
  getSkills as onGetSkills,
  getJobDetail as onGetJobDetail,
  getJobDetailSuccess as onGetJobDetailSuccess,
} from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AppForm from "../../components/Common/Form"
import AppLoader from "../../components/Common/Loader"
import { formFields, handleSubmit, handleSaveAsDraft } from "./Utills"

const Jobs = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [isEdit, setIsEdit] = useState(false)
  const [draft, setDraft] = useState(null)
  const [user, setUser] = useState("")
  document.title = `${isEdit ? "Edit Job" : "Create New Job"} | QAPRENEUR`
  const { skills, jobDetail, loading } = useSelector(state => ({
    skills: state.skills.skills,
    jobDetail: state.jobs?.jobDetail,
    loading: state.jobs?.loading,
  }))

  useEffect(() => {
    const currentLocation = location.pathname
    const status = currentLocation.includes("jobs-edit")
    if (!status) {
      const draftData = localStorage.getItem("skills")
      if (draftData) {
        setDraft(draftData)
      }
    }
    setIsEdit(status)
    if (status && params?.id) dispatch(onGetJobDetail(params.id))
    else dispatch(onGetJobDetailSuccess())
  }, [])

  useEffect(() => {
    const authUser = localStorage.getItem("authUser")
    if (authUser) {
      const data = JSON.parse(authUser)
      setUser(data)
      dispatch(onGetSkills(data?._id))
    }
  }, [dispatch])

  const skillOptions =
    Array.isArray(skills) && skills.length
      ? skills.map(skill => ({ label: skill.name, value: skill._id }))
      : []

  const initData = draft
    ? JSON.parse(draft)
    : {
        ...jobDetail,
        skillIds: jobDetail?.skillIds
          ? jobDetail.skillIds.map(skill => ({
              value: skill._id,
              label: skill.name,
            }))
          : [],
      }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Jobs"
            navigate="/jobs-list"
            breadcrumbItem={`${isEdit ? "Edit Job" : "Create Job"}`}
          />
          <Card>
            <CardBody>
              {loading && isEdit && <AppLoader />}
              {!loading && (
                <AppForm
                  name=""
                  layoutType={isEdit ? "UPDATE" : "CREATE"}
                  fields={formFields(skillOptions)}
                  initialValues={initData}
                  handleFormSubmit={(formikValues, formik) =>
                    handleSubmit(
                      formikValues,
                      formik,
                      jobDetail,
                      user,
                      isEdit,
                      dispatch,
                      navigate
                    )
                  }
                  showDraftButton={!isEdit}
                  handleSaveAsDraft={handleSaveAsDraft}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Jobs
