import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { notificationColumns } from "./constants" // Assuming you have notificationColumns defined
import {
  getNotifications as onGetNotifications,
  updateNotification as onUpdateNotification,
  deleteNotification as onDeleteNotification,
} from "../../store/notification/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"

const NotificationList = () => {
  document.title = "Notification List | QAPRENEUR"
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [notificationsData, setNotificationsData] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { notifications, loading } = useSelector(state => ({
    notifications: state?.notifications?.notifications,
    loading: state?.notifications?.loading,
  }))

  const getData = () => {
    const queryParams = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      queryParams.search = search
    }

    dispatch(onGetNotifications(queryParams))
  }

  useEffect(() => {
    getData()
  }, [pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(notifications)) {
      setNotificationsData(
        notifications
          .map(item => ({ ...item }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setNotificationsData([])
    }
  }, [notifications, pageNo])

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const onChangePagination = (limit, page) => {
    setRowPerPage(limit)
    setPageNo(page)
  }

  const handleActions = (data, type) => {
    if (type === "VIEW") {
    } else if (type === "EDIT") {
      navigate(`/edit-notification/${data._id}`)
    } else if (type === "DELETE") {
      dispatch(onDeleteNotification(data._id))
    } else if (type === "VIEW_RECEIVERS") {
      navigate(`/view-receivers/${data._id}`)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        work in progress...
        {/* <Container fluid>
          <Breadcrumbs
            title="Dashboard"
            navigate={"/dashboard"}
            breadcrumbItem="Notifications"
          />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={notificationsData}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={notificationColumns}
                  showPagination={true}
                  addButton={{
                    link: "/create-notification",
                    title: "Add Notification",
                  }}
                  onChangePagination={onChangePagination}
                  handleStatus={data => handleActions(data, "STATUS_CHANGE")}
                  handleSearch={handleSearch}
                  handleActions={handleActions}
                />
              </div>
            </Col>
          </Row>
        </Container> */}
      </div>
    </React.Fragment>
  )
}

export default NotificationList
