import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Package Redux States
import {
  GET_PACKAGE,
  GET_PACKAGE_DETAIL,
  ADD_NEW_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
} from "./actionTypes"
import {
  getPackagesSuccess,
  getPackageDetailSuccess,
  getPackageDetailFail,
  addPackageSuccess,
  updatePackageSuccess,
  updatePackageFail,
  updatePackageLoadingState,
  deletePackageFail,
  deletePackageSuccess,
} from "./actions"

// Include both helper files with needed methods
import {
  createPackage,
  getPackages,
  updatePackage,
  getPackageDetails,
  deletePackage,
} from "helpers/api/packagesApi"

function* fetchPackages({ payload }) {
  try {
    const { page, limit, search, role } = payload
    let queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }${role ? "&role=" + role : ""}`
    if (search && search?.length) {
      queryParams = queryParams + `&search=${search}`
    }
    yield put(updatePackageLoadingState(true))
    const response = yield call(getPackages, queryParams)
    yield put(getPackagesSuccess(response.data))
    yield put(updatePackageLoadingState(false))
  } catch (error) {
    yield put(updatePackageLoadingState(false))
    // Dispatch an action to handle the failure
  }
}

function* fetchPackageDetail({ packageId }) {
  try {
    yield put(updatePackageLoadingState(true))
    const response = yield call(getPackageDetails, packageId)
    yield put(getPackageDetailSuccess(response.data))
    yield put(updatePackageLoadingState(false))
  } catch (error) {
    yield put(getPackageDetailFail(error))
  }
}

function* onUpdatePackage({ payload: { packageData, params, history } }) {
  try {
    const { packageId, role } = params
    const response = yield call(updatePackage, packageData, packageId)
    toastr.success("Package updated successfully..!!")
    yield put(updatePackageSuccess(response.data))
    history(`/packages/${role}`)
  } catch (error) {
    yield put(updatePackageFail(error))
    console.log(error)
    toastr.error(error.response.data.message)
  }
}

function* onAddNewPackage({ payload: { packageData, history } }) {
  try {
    yield put(updatePackageLoadingState(true))
    const response = yield call(createPackage, packageData)
    toastr.success("New Package Added Successfully..!!")
    yield put(addPackageSuccess(response.data.package))
    yield put(updatePackageLoadingState(false))
    history(`/packages/${packageData?.role}`)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updatePackageLoadingState(false))
  }
}

function* onDeletePackage({packageId}) {
  try {
    yield put(updatePackageLoadingState(true))
    const response = yield call(deletePackage, packageId)
    yield put(deletePackageSuccess(response.data))
    yield put(updatePackageLoadingState(false))
  } catch (error) {
    yield put(updatePackageLoadingState(false))
    yield put(deletePackageFail(error))
    toastr.error(error.response.data.message)
  }
}

function* packagesSaga() {
  yield takeEvery(GET_PACKAGE, fetchPackages)
  yield takeEvery(ADD_NEW_PACKAGE, onAddNewPackage)
  yield takeEvery(GET_PACKAGE_DETAIL, fetchPackageDetail)
  yield takeEvery(UPDATE_PACKAGE, onUpdatePackage)
  yield takeEvery(DELETE_PACKAGE, onDeletePackage)
}

export default packagesSaga
