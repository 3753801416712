import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import MetaTags from "../../MetaTags"


//i18n
import { withTranslation } from "react-i18next"
import phoneIcon from "../../assets/images/home/phone-icon.svg"
import headerLeft from "../../assets/images/home/header_left.png"
import header_right_two from "../../assets/images/home/header_right_two.png"
import about_banner from "../../assets/images/home/about_banner.png"
import about_icon from "../../assets/images/home/about-icon.svg"
import contact_us_banner from "../../assets/images/home/contact_us_banner.png"
import customizable_sol from "../../assets/images/home/customizable_sol.svg"
import right_arrow from "../../assets/images/home/right_arrow.svg"
import arrow_right from "../../assets/images/home/arrow_right.svg"
import black_arrow from "../../assets/images/home/black_arrow.svg"
import rightIcon from "../../assets/images/home/rightIcon.svg"
import scalable_sol from "../../assets/images/home/scalable_sol.svg"
import support from "../../assets/images/home/support.svg"
import vacancyIcon from "../../assets/images/icons/vacancyIcon.svg"
import title_bottom_shape from "../../assets/images/home/title_bottom_shape.svg"
import why_us_banner from "../../assets/images/home/why_us_banner.png"
import logo from "../../assets/images/home/logo.png"
import facebook from "../../assets/images/home/facebook.svg"
import insta from "../../assets/images/home/insta.svg"
import linkedin from "../../assets/images/home/linkedin.svg"
import twitter from "../../assets/images/home/twitter.svg"
import youtube from "../../assets/images/home/youtube.svg"
import footer_logo from "../../assets/images/home/footer_logo.png"
import star from "../../assets/images/home/star.svg"
import testimonial_shap from "../../assets/images/home/testimonial_shap.png"
import quotes_circle from "../../assets/images/home/quotes_circle.svg"
import playstore from "../../assets/images/home/playstore.png"

import "../../assets/css/home.css"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/autoplay"
import { Pagination, Navigation, Autoplay } from "swiper"
import { Link as ScrollLink } from "react-scroll"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import QaReview from "./QaReview"

import {
  Categories,
  scrollLinks,
  ourFeatures,
  brandLogoes,
  footerLinks,
} from "./constant"

const Home = () => {
  const navigate = useNavigate()

  const [isHovered, setIsHovered] = useState(true)
  const [isHoveredLogin, setIsHoveredLogin] = useState(false)

  const handleHoverLogin = () => {
    setIsHoveredLogin(true)
  }

  const handleLeaveLogin = () => {
    setIsHoveredLogin(false)
  }

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleLeave = () => {
    setIsHovered(false)
  }

  let settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "25%",
    autoplay: true,
    autoplaySpeed: 3000,
  }

  if (window.innerWidth < 768) {
    settings.centerPadding = "0"
  }

  const handleSignUpMenu = e => {
    navigate(`/${e.target.value}/register`)
  }

  return (
    <React.Fragment>
       <MetaTags page={'home'} />
      <div>
        <div id="top_bar" className="top_bar">
          <div className="top_bar_container">
            <div className="top_bar_inner">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                  <div className="navbar-brand">
                    <img alt={"qapreneur"} src={logo} width={235} height={55} />
                  </div>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarNavAltMarkup"
                  >
                    <div className="navbar-nav">
                      <ul className="list-unstyled mb-0">
                        {scrollLinks.map((scrollLinks, key) => {
                          return (
                            <li className="px-2 navbar_menu" key={key}>
                              <ScrollLink
                                to={scrollLinks.path}
                                activeClass="active_menu"
                                spy={true}
                                smooth={true}
                                duration={500}
                              >
                                {scrollLinks.label}
                              </ScrollLink>
                            </li>
                          )
                        })}
                      </ul>
                      <div className="login_and_signUp">
                        {/* <Link
                          className={`home_global_btn me-1 btn_login ${
                            isHoveredLogin ? "hovered" : ""
                          }`}
                          to="/login"
                          onMouseEnter={handleHoverLogin}
                          onMouseLeave={handleLeaveLogin}
                          onFocus={handleHoverLogin}
                          onBlur={handleLeaveLogin}
                        >
                          Login
                        </Link>

                        <Link
                          className={`home_global_btn me-1 signup_btn ${
                            isHovered ? "hovered" : ""
                          }`}
                          to="/student/register"
                          onMouseEnter={handleHover}
                          onMouseLeave={handleLeave}
                          onFocus={handleHover}
                          onBlur={handleLeave}
                        >
                          Signup
                        </Link> */}  
                        {/*temp comment*/}
                        <Link
                        // /company/register
                          to="/login"
                          className={`text-muted ms-2`}
                          style={{ fontWeight: "600", fontSize: "16px" }}
                        >
                          Hire with Us
                        </Link>
                        {/* <Link
                          to="/college/register"
                          className={`text-muted ms-2`}
                          style={{ fontWeight: "600", fontSize: "16px" }}
                        >
                          Trained with Us
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div id="section_home" className="header">
          <div className="header_content">
            <h1 style={{ fontFamily: "Sitka Banner Bold" }}>
              All-in-one platform for students <br />
              <span
                style={{
                  color: "#F89752",
                  fontSize: "60%",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                }}
              >
                | IT Companies | Colleges & QA Training Institutes
              </span>
            </h1>
            <p
              className="mb-2"
              style={{ fontWeight: 800, fontFamily: "Quicksand" }}
            >
              Happy to share that we have a record of <br />
              <span
                style={{
                  fontWeight: 800,
                  color: "rgb(79 79 79)",
                  fontFamily: "Quicksand",
                }}
              >
                100% Placement Assistance.
              </span>
            </p>

            <div className="online_support d-flex justify-content-center align-items-center">
              <div className="me-3">
                <img alt={"qapreneur"} src={phoneIcon} height="50" width="50" />
              </div>
              <div>
                <p className="txt_online_support mb-0">online support</p>
                <p className="txt_mobile mb-0">+91 9685353484</p>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <a href="#">
                Find courses &nbsp;
                <img
                  alt={"qapreneur"}
                  src={arrow_right}
                  width="19"
                  height="15"
                />
              </a>
            </div>
          </div>

          <div className="img_header_left">
            <img alt={"qapreneur"} src={headerLeft} height="336" width="327" />
          </div>

          <div className="img_header_right">
            <img
              alt={"qapreneur"}
              src={header_right_two}
              height="486"
              width="408"
            />
          </div>
        </div>

        <div id="section_about_us" className="about_us">
          <div className="row">
            <div className="col-md-12 col-lg-8 col-sm-12 py-5 order-2 order-lg-1">
              <div className="d-flex justify-content-center align-items-center ">
                <div className="about_banner_container position-relative py-5">
                  <img
                    alt={"qapreneur"}
                    src={about_banner}
                    width="520"
                    height="370"
                  />

                  <div className="enroll_learners d-flex justify-content-center align-items-center">
                    <div className="me-3">
                      <img
                        alt={"qapreneur"}
                        src={about_icon}
                        height="50"
                        width="50"
                      />
                    </div>
                    <div>
                      <p className="lbl_learners_count mb-0">20K+</p>
                      <p className="lbl_learners mb-0">Enrolled Learners</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 col-sm-12 order-1 order-lg-2">
              <div className=" about_us_content mt-lg-3">
                <div className="about_us_header">
                  <p className="lbl_about_us mx-4 px-4">About Us</p>
                  <div className=" mx-4 px-4 mb-2">
                    <h2 className="aboutus_heading section_heading mb-0">
                      <span style={{ color: "#F89752" }}>Get Certified,</span>{" "}
                      Get Hired
                    </h2>
                    <img
                      alt={"qapreneur"}
                      src={title_bottom_shape}
                      height="15"
                      width="103"
                    />
                  </div>

                  <p className="section_desc mx-4 px-4">
                    A cutting-edge platform is designed to help students, IT
                    Companies, Colleges & QA Training Institutes to find & get
                    their right source of talent.
                  </p>
                </div>

                <ul className="list-unstyled  mx-4 px-4">
                  <li>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        alt={"qapreneur"}
                        src={rightIcon}
                        height="22"
                        width="22"
                      />
                      <p className="mx-3 my-0 aboutus_list">
                        Certifications and Courses
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        alt={"qapreneur"}
                        src={rightIcon}
                        height="22"
                        width="22"
                      />
                      <p className="mx-3 my-0 aboutus_list">
                        Talented pool of professionals
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        alt={"qapreneur"}
                        src={rightIcon}
                        height="22"
                        width="22"
                      />
                      <p className="mx-3 my-0 aboutus_list">Find Jobs easily</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id="section_online_test" className="home_container">
          <div className="online_test">
            <div className="online_test_header text-center">
              <h2 className="section_heading">
                <span style={{ color: "#F89752" }}>Categories</span>
              </h2>
              <img
                alt={"qapreneur"}
                src={title_bottom_shape}
                height="15"
                width="103"
              />
            </div>
            <p className="section_desc text-center mt-3">
              We brought the best for you.
            </p>

            <div className="test_list_container">
              <div className="row my-5 d-flex justify-content-center">
                {Categories.map((Category, key) => {
                  return (
                    <div
                      key={key}
                      className="col-lg-3 col-md-6 online_test_container"
                    >
                      <div className={Category.className}>
                        <img
                          alt={"qapreneur"}
                          src={Category.imgSrc}
                          height="80"
                          width="80"
                        />
                        <h3 className="cat_title mt-4">{Category.title}</h3>
                        <p className="cat_desc">{Category.description}</p>
                        <span className="num_of_courses">
                          {Category.buttonTitle}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        <div id="section_why_us" className="why_us my-5">
          <div className="home_container">
            <div className="why_us_inner">
              <div className="why_us_banner order-2 order-lg-1">
                <img
                  alt={"qapreneur"}
                  src={why_us_banner}
                  width="770"
                  height="470"
                />
              </div>
              <div className="why_us_content order-1 order-lg-2">
                <div className="text-center text-lg-start mb-5">
                  <h2 className="section_heading">Why Choose Us</h2>
                  <img
                    alt={"qapreneur"}
                    src={title_bottom_shape}
                    height="15"
                    width="103"
                  />
                </div>

                <div className="d-flex align-items-center mb-4">
                  <div>
                    <img
                      alt={"qapreneur"}
                      src={customizable_sol}
                      height="80"
                      width="80"
                    />
                  </div>
                  <div className="ms-4">
                    <h3 className="choose_title">Top QA Courses</h3>
                    <p className="choose_desc">
                      From basic to advance, we cover a variety of testing
                      courses along with ISTQB preparation and certification
                      exam.
                    </p>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <div>
                    <img
                      alt={"qapreneur"}
                      src={scalable_sol}
                      height="80"
                      width="80"
                    />
                  </div>
                  <div className="ms-4">
                    <h3 className="choose_title">Top Hiring Partners</h3>
                    <p className="choose_desc">
                      QAPreneur serves as an effective partner that pairs
                      talented professionals with companies looking to hire.
                    </p>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <div>
                    <img
                      alt={"qapreneur"}
                      src={support}
                      height="80"
                      width="80"
                    />
                  </div>
                  <div className="ms-4">
                    <h3 className="choose_title">Lifetime Placement Support</h3>
                    <p className="choose_desc">
                      Our commitment to providing career placement assistance
                      never expires. Leverage our network to get regular
                      applications.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      alt={"qapreneur"}
                      src={vacancyIcon}
                      height="80"
                      width="80"
                    />
                  </div>
                  <div className="ms-4">
                    <h3 className="choose_title">Talented Pool of Resources</h3>
                    <p className="choose_desc">
                      Don't miss out on the opportunity to hire the best of the
                      best! Connect with us today.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="section_our_features" className="our_features my-5">
          <div className="features_inner">
            <div className="text-center">
              <h2 className="section_heading">Our features</h2>
              <img
                alt={title_bottom_shape}
                src={title_bottom_shape}
                height="15"
                width="103"
              />
            </div>

            <div className="features_container mt-5 pt-5">
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },

                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },

                  1100: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {ourFeatures.map((feature, index) => (
                  <SwiperSlide key={index}>
                    <div className="features_item position-relative">
                      <div className="feature_img">
                        <img
                          alt={feature.imgSrc}
                          src={feature.imgSrc}
                          height="370"
                          width="370"
                        />
                      </div>
                      <div className="feature_content">
                        <h2 className="feature_title">{feature.title}</h2>
                        <p className="feature_desc">{feature.description}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>

        <div id="section_membership_plan" className="home_container">
          <div className="membership_plan py-5">
            <div className="text-center">
              <h5 className="small_heading_title">pricing table</h5>
              <h2 className="section_heading">Great Membership Plan</h2>
              <img
                alt={"qapreneur"}
                src={title_bottom_shape}
                height="15"
                width="103"
              />
            </div>

            <div className="plan_list">
              <div className="row mt-5 justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="plan_container text-center">
                    <h5 className="plan_name">Basic Plan</h5>
                    <h3 className="plan_amount mb-0 px-lg-5">FREE</h3>
                    <p className="plan_desc mx-2">
                      Unlock essential features with our free basic plan to
                      start your testing career
                    </p>

                    <div className="plan_benefits">
                      <ul className="list-unstyled text-center">
                        <li>
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <img
                              alt={"qapreneur"}
                              src={right_arrow}
                              height="15"
                              width="15"
                            />
                            <p className="mx-2 my-0 list_item">
                              Course Included - 1
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <img
                              alt={"qapreneur"}
                              src={right_arrow}
                              height="15"
                              width="15"
                            />
                            <p className="mx-2 my-0 list_item">
                              Assessment Test
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <img
                              alt={"qapreneur"}
                              src={right_arrow}
                              height="15"
                              width="15"
                            />
                            <p className="mx-2 my-0 list_item">Quizzes</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="plan_container text-center">
                    <h5 className="plan_name">Premium Plan</h5>
                    <h3 className="plan_amount mb-0 px-lg-5">700</h3>
                    <p className="lbl_per_month">per month</p>
                    <p className="plan_desc mx-2">
                      Upgrade plan to access essential features tailored for
                      your needs to get placed
                    </p>

                    <div className="plan_benefits">
                      <ul className="list-unstyled text-center">
                        <li>
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <img
                              alt={"qapreneur"}
                              src={right_arrow}
                              height="15"
                              width="15"
                            />
                            <p className="mx-2 my-0 list_item">
                              Premium Courses - 3
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <img
                              alt={"qapreneur"}
                              src={right_arrow}
                              height="15"
                              width="15"
                            />
                            <p className="mx-2 my-0 list_item">
                              More Assessment Tests
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <img
                              alt={"qapreneur"}
                              src={right_arrow}
                              height="15"
                              width="15"
                            />
                            <p className="mx-2 my-0 list_item">
                              Flash Cards & Quizzes
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <img
                              alt={"qapreneur"}
                              src={right_arrow}
                              height="15"
                              width="15"
                            />
                            <p className="mx-2 my-0 list_item">
                              Mock Interview with expert feedback
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <img
                              alt={"qapreneur"}
                              src={right_arrow}
                              height="15"
                              width="15"
                            />
                            <p className="mx-2 my-0 list_item">
                              Easy Job Apply
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <QaReview />

        <div id="section_our_partners" className="our_partners py-5">
          <div className="home_container">
            <div className="our_partners_header text-center">
              <h5 className="small_heading_title">our partners</h5>
              <h2 className="section_heading">Top Hiring Partners</h2>
              <img
                alt={"qapreneur"}
                src={title_bottom_shape}
                height="15"
                width="103"
              />
              <p className="plan_desc mt-3">
                Below are the IT Companies that see growth working with us.
              </p>
            </div>
            <Swiper
              slidesPerView={4}
              spaceBetween={100}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 100,
                },
              }}
            >
              {brandLogoes.map((logo, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={logo.src}
                    alt={logo.src}
                    style={logo?.style ? logo?.style : {}}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div id="section_contact_us" className="contact_us py-5">
          <div className="home_container">
            <div className="contact_us_inner">
              <div className="row">
                <div className="col-lg-8 col-md-6 order-2 order-md-1">
                  <div className=" contact_us_banner">
                    <img
                      src={contact_us_banner}
                      //   height="519"
                      //   width="781"
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 order-1 order-md-2">
                  <div className="contact_us_header">
                    <h5 className="small_heading_title">contact us</h5>
                    <h2 className="section_heading">Get In Touch</h2>
                    <img
                      alt={"qapreneur"}
                      src={title_bottom_shape}
                      height="15"
                      width="103"
                    />
                    <p className="plan_desc mt-3">
                      Fill out the form to reach us. Enquire Now!
                    </p>
                  </div>

                  <form className="contact_us_form my-4">
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Your name"
                        className="form-control home_input_field"
                        id="name"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="email"
                        placeholder="Enter email"
                        className="form-control home_input_field"
                        id="email"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Phone number"
                        className="form-control home_input_field"
                        id="Phone number"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="form-floating">
                      <textarea
                        className="home_input_field p-2 w-100"
                        // aria-placeholder="Your message"
                        placeholder=" Your message"
                        id="floatingTextarea2"
                        style={{ height: "100px" }}
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary button mt-4"
                    >
                      Submit Message &nbsp;
                      <img
                        alt={"qapreneur"}
                        src={arrow_right}
                        width="19"
                        height="15"
                      />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="section_main_footer" className="main_footer py-5">
          <div className="home_container">
            <div className="footer_inner">
              <div className="row">
                <div className="col-lg-3 mb-4">
                  <div className="footer_logo">
                    <img
                      alt={"qapreneur"}
                      src={footer_logo}
                      width="293"
                      height="100"
                    />
                  </div>
                  <p className="footer_text">
                    A Learning platform for Students & IT Solution Partner for
                    Companies, Colleges, & QA Training Institutes looking to
                    find/hire the best talents among the large pool of QA
                    Professionals.
                  </p>
                  <Link
                    to={`https://play.google.com/store/apps/details?id=com.qapreneur.app&pli=1`}
                    target="_blank"
                  >
                    <img alt={"qapreneur"} src={playstore} width="250" />
                  </Link>
                </div>

                <div className="col-lg-3 mb-4">
                  <div className="ms-lg-auto footer_quick_links_menu">
                    <h3 className="foot_section_headting mb-4">Quick link</h3>
                    <ul className="list-unstyled">
                      {footerLinks.map((footerLink, key) => {
                        return (
                          <li className="footer_text py-1" key={key}>
                            {footerLink.scroll ? (
                              <ScrollLink
                                to={footerLink.path}
                                smooth={true}
                                duration={500}
                              >
                                {footerLink.label}
                              </ScrollLink>
                            ) : (
                              <Link className="py-1" to={footerLink.path}>
                                {footerLink.label}
                              </Link>
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 mb-4">
                  <div className="ps-lg-5 footer_quick_links">
                    <h3 className="foot_section_headting mb-4">Quick link</h3>
                    <ul className="list-unstyled">
                      <li className="footer_text py-1">
                        <a href="#">Contact Us</a>
                      </li>
                      <li className="footer_text py-1">
                        <a href="#">FAQ</a>
                      </li>
                      <li className="footer_text py-1">
                        <a href="#">Privacy Policy</a>
                      </li>
                      <li className="footer_text py-1">
                        <a href="#">Terms Of Service</a>
                      </li>
                      <li className="footer_text py-1">
                        <a href="#">Return Policy</a>
                      </li>
                      <li className="footer_text py-1">
                        <a href="#">Shipping Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 mb-4">
                  <h3 className="foot_section_headting mb-4">Contacts</h3>
                  <p className="footer_text mb-2">
                    Add: Plot No. 106, 2nd Floor, PU-4, Scheme No. 54, Vijay
                    Nagar Square, Behind C-21/Malhar Mall, Indore, 452011
                  </p>
                  {/* <p className="footer_text mb-2">Call: +01 123 5641 231</p> */}
                  <p className="footer_text mb-2">Email:info@qapreneur.com</p>
                  <ul className="list-unstyled me-5 social_icons_container">
                    <li className="footer_text py-1">
                      <Link
                        to="https://www.facebook.com/profile.php?id=100093512683210"
                        target="_blank"
                      >
                        <img
                          alt={"qapreneur"}
                          src={facebook}
                          width="25"
                          height="25"
                        />
                      </Link>
                    </li>
                    <li className="footer_text py-1">
                      <Link
                        to="https://www.linkedin.com/company/qapreneur/?viewAsMember=true"
                        target="_blank"
                      >
                        <img
                          alt={"qapreneur"}
                          src={linkedin}
                          width="25"
                          height="25"
                        />
                      </Link>
                    </li>
                    <li className="footer_text py-1">
                      <Link
                        to="https://www.instagram.com/qapreneur/"
                        target="_blank"
                      >
                        <img
                          alt={"qapreneur"}
                          src={insta}
                          width="25"
                          height="25"
                        />
                      </Link>
                    </li>
                    <li className="footer_text py-1">
                      <Link to="https://twitter.com/QaPreneur " target="_blank">
                        <img
                          alt={"qapreneur"}
                          src={twitter}
                          width="25"
                          height="25"
                        />
                      </Link>
                    </li>
                    <li className="footer_text py-1">
                      <Link
                        to="https://www.youtube.com/@QAPreneur"
                        target="_blank"
                      >
                        <img
                          alt={"qapreneur"}
                          src={youtube}
                          width="25"
                          height="25"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_bar py-4 d-flex justify-content-center align-items-center">
          <p className="mb-0">
            Copyright 2023 <a href="#">QApreneur Pvt Ltd.</a> All Right Reserved
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Home)
