import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import { Col, Row, Button } from "reactstrap"
import AppLoader from "../../components/Common/Loader"
import NotFound from "../../components/Common/NotFound"
import TablePagination from "../../components/Common/Table/TablePagination"

//import action
import {
  getJobs as onGetJobs,
  updateJob as onUpdateJob,
  deleteJob as onDeleteJob,
  resetJobs as onResetJobs,
  getCompanySubscriptions as onGetCompanySubscriptions,
  getCompanyDashboardData as onGetCompanyDashboardData,
  getCompanyCredit as onGetCompanyCredit,
} from "../../store/actions"
import toastr from "toastr"
import { checkPlan } from "./Utills"

import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
// import JobCard from "./JobCard"
import Breadcrumbs from "components/Common/Breadcrumb"
import ListItem from "./ListItem"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import DateRange from "./Table/DateRange"
import TableSearch from "./Table/TableSearch"

const JobsList = () => {
  document.title = "Job List | QAPRENEUR"
  const role = localStorage.getItem("authRole")
  let location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [job, setJob] = useState(null)
  const [pageNo, setPageNo] = useState(0)
  const [rowPerPage, setRowPerPage] = useState(5)
  const [search, setSearch] = useState("")
  const [companyDetail, setCompanyDetail] = useState(null)
  const [jobList, setJobList] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [dateValue, setDatevalue] = useState("")
  // const [feedBack, setFeedBack] = useState(false)
  // const [userPlan, setUserPlan] = useState(null)
  const { jobs, loading, companyDashboard } = useSelector(state => ({
    jobs: state?.jobs?.jobs,
    loading: state?.jobs?.loading,
    companyDashboard: state.Dashboard.companyDashboard,
  }))

  useEffect(() => {
    // getUserSubscriptionsPlan()
    dispatch(onGetCompanyDashboardData())
    if (location.pathname.includes("deleted-job-list")) {
      setDatevalue("deleted")
      getJobList("deleted")
    } else {
      getJobList()
    }
    return () => {
      setJobList([])
      dispatch(onResetJobs())
    }
  }, [dispatch, pageNo, rowPerPage, search])

  const getJobList = (dateRange = "") => {
    const user = localStorage.getItem("authUser")
    const authRole = localStorage.getItem("authRole")
    const isHrUser = authRole === "hr_user"
    if (user && typeof user === "string") {
      const companyId = isHrUser
        ? JSON.parse(user).companyId
        : JSON.parse(user)._id
      const params = {
        page: pageNo + 1,
        limit: rowPerPage,
      }
      if (search && search.length > 0) {
        params.search = search
      }
      if (dateRange === "deleted") {
        params.deletedAt = true
      } else {
        params.dateRange = dateRange
        navigate("/jobs-list")
      }
      if (!companyDetail) {
        setCompanyDetail(JSON.parse(user))
      }
      if (role === "company" || isHrUser) {
        dispatch(onGetJobs(companyId, params))
      }
      if (role === "admin") {
        dispatch(onGetJobs("", params))
      }
    }
  }

  useEffect(() => {
    if (!isEmpty(jobs)) {
      setJobList(jobs)
    } else {
      setJobList([])
    }
  }, [jobs, pageNo])

  const handleDelete = data => {
    setJob(data)
    setDeleteModal(true)
  }

  const handleModalAction = () => {
    if (job && job._id) {
      dispatch(onDeleteJob(job._id))
      setDeleteModal(false)
    }
  }

  const handleActions = (data, type) => {
    if (type === "EDIT") {
      navigate(`/jobs-edit/${data?._id}`)
    } else if (type === "VIEW") {
      navigate(`/jobs-details/${data?._id}`)
    } else if (type === "DELETE") {
      handleDelete(data)
    } else if (type === "STATUS") {
      handleChangeStatus(data)
    }
    //  else if (type === "APPLICANTS_STATUS") {
    //   setFeedBack(true)
    //   setJob(data)
    // }
  }

  const handleChangeStatus = job => {
    const status = job.status === "active" ? "deactive" : "active"
    dispatch(onUpdateJob({ status }, job._id, navigate))
  }

  const handlePagination = type => {
    if (type === "NEXT") {
      setPageNo(pageNo + 1)
      setJobList([])
    } else if (type === "PREV") {
      setPageNo(pageNo - 1)
    }
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const onChangeDateRange = e => {
    setDatevalue(e.target.value)
    getJobList(e.target.value)
  }

  const checkJobPlan = async () => {
    checkPlan(navigate, "/jobs-create")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          breadcrumbItem="Job"
          navigate="/dashboard"
        />
        <Row>
          <Col className="col-12">
            <Row>
              <Col className="d-flex align-items-center justify-content-start">
                <DateRange
                  onChangeDateRange={onChangeDateRange}
                  dateValue={dateValue}
                />
                <TableSearch
                  data={jobList}
                  value={search}
                  onChange={handleSearch}
                  width={300}
                />
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                {!loading && (
                  <div className="page-title-right">
                    {["company", "hr_user"].includes(role) ? (
                      <Button
                        className="btn btn-primary global_btn me-1"
                        onClick={() => checkJobPlan()}
                      >
                        <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
                        ADD JOB
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Col lg={12} className="pt-5">
          {loading && <AppLoader />}
          {!loading &&
            jobList &&
            jobList.map((job, index) => (
              <ListItem
                key={index}
                job={job}
                companyDetail={companyDetail}
                handleChangeStatus={handleChangeStatus}
                handleActions={handleActions}
                role={role}
              />
            ))}
          {!loading && !jobList.length && (
            <>
              <div className="text-center my-3">
                <div className="text-success">No Data Available</div>
              </div>
            </>
          )}
          {!jobList.length && !loading && <NotFound />}
        </Col>
        <TablePagination
          data={jobList}
          pageSize={rowPerPage}
          setPageNo={setPageNo}
          pageNo={pageNo}
        />
      </div>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the job."}
          modalAction={handleModalAction}
        />
      )}
    </React.Fragment>
  )
}

export default JobsList
