/* NOTIFICATIONS */
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

/* NOTIFICATION DETAIL */
export const GET_NOTIFICATION_DETAIL = "GET_NOTIFICATION_DETAIL";
export const GET_NOTIFICATION_DETAIL_SUCCESS = "GET_NOTIFICATION_DETAIL_SUCCESS";
export const GET_NOTIFICATION_DETAIL_FAIL = "GET_NOTIFICATION_DETAIL_FAIL";

/**
 * Add notification
 */
export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION";
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const ADD_NOTIFICATION_FAIL = "ADD_NOTIFICATION_FAIL";

/**
 * Edit notification
 */
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL";

/**
 * Delete notification
 */
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";

export const UPDATE_NOTIFICATION_LOADING_STATE = "UPDATE_NOTIFICATION_LOADING_STATE";

/* NOTIFICATIONS REPORT */
export const GET_NOTIFICATIONS_RECEIVERS = "GET_NOTIFICATIONS_RECEIVERS";
export const GET_NOTIFICATIONS_RECEIVERS_SUCCESS = "GET_NOTIFICATIONS_RECEIVERS_SUCCESS";
export const GET_NOTIFICATIONS_RECEIVERS_FAIL = "GET_NOTIFICATIONS_REPORT_FAIL";

/* RESET NOTIFICATIONS */
export const RESET_NOTIFICATIONS_REPORT = "RESET_NOTIFICATIONS_REPORT";
export const RESET_NOTIFICATIONS_REPORT_SUCCESS = "RESET_NOTIFICATIONS_REPORT_SUCCESS";
export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";
