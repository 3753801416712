import students from "store/students/reducer"
import {
  GET_STUDENTS,
  GET_STUDENTS_FAIL,
  GET_STUDENTS_SUCCESS,
  GET_STUDENT_DETAIL,
  ADD_NEW_STUDENT,
  ADD_STUDENT_SUCCESS,
  ADD_STUDENT_FAIL,
  UPDATE_STUDENT,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAIL,
  DELETE_STUDENT,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAIL,
  GET_STUDENT_DETAIL_FAIL,
  GET_STUDENT_DETAIL_SUCCESS,
  UPDATE_STUDENT_LOADING_STATE,
  RESET_STUDENT,
  GET_TEST_RESULT,
  GET_TEST_RESULT_SUCCESS,
  GET_TEST_RESULT_FAIL,
  RESET_TEST_RESULT,
  GET_STUDENT_COURSES,
  GET_STUDENT_COURSES_SUCCESS,
  GET_STUDENT_COURSES_FAIL,
  RESET_STUDENT_COURSES,
  GENERATE_CERTIFICATE,
  GENERATE_CERTIFICATE_SUCCESS,
  GENERATE_CERTIFICATE_FAIL,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAIL,
  SEARCH_USER,
  GET_USER_TAB_DATA_SUCCESS,
  GET_USER_TAB_DATA,
} from "./actionTypes"

export const getStudents = params => ({
  type: GET_STUDENTS,
  params,
})
export const resetStudents = () => ({
  type: RESET_STUDENT,
})
export const getStudentsSuccess = students => ({
  type: GET_STUDENTS_SUCCESS,
  payload: students,
})

export const addNewStudent = (student, history) => ({
  type: ADD_NEW_STUDENT,
  payload: { student, history },
})

export const addStudentSuccess = student => ({
  type: ADD_STUDENT_SUCCESS,
  payload: student,
})

export const addStudentFail = error => ({
  type: ADD_STUDENT_FAIL,
  payload: error,
})

export const updateStudent = (student, studentId, history) => ({
  type: UPDATE_STUDENT,
  payload: { student, studentId, history },
})

export const updateStudentSuccess = student => ({
  type: UPDATE_STUDENT_SUCCESS,
  payload: student,
})

export const updateStudentFail = error => ({
  type: UPDATE_STUDENT_FAIL,
  payload: error,
})

export const deleteStudent = (studentId, params) => ({
  type: DELETE_STUDENT,
  payload: { studentId, params },
})

export const deleteStudentSuccess = student => ({
  type: DELETE_STUDENT_SUCCESS,
  payload: student,
})

export const deleteStudentFail = error => ({
  type: DELETE_STUDENT_FAIL,
  payload: error,
})

export const getStudentsFail = error => ({
  type: GET_STUDENTS_FAIL,
  payload: error,
})

export const getStudentDetail = studentId => ({
  type: GET_STUDENT_DETAIL,
  studentId,
})

export const getStudentDetailSuccess = studentDetails => ({
  type: GET_STUDENT_DETAIL_SUCCESS,
  payload: studentDetails,
})

export const getStudentDetailFail = error => ({
  type: GET_STUDENT_DETAIL_FAIL,
  payload: error,
})

export const updateStudentLoadingState = status => ({
  type: UPDATE_STUDENT_LOADING_STATE,
  payload: status,
})

export const getTestResult = studentId => ({
  type: GET_TEST_RESULT,
  studentId,
})

export const getTestResultSuccess = testResult => ({
  type: GET_TEST_RESULT_SUCCESS,
  payload: testResult,
})

export const getTestResultFail = error => ({
  type: GET_TEST_RESULT_FAIL,
  payload: error,
})

export const resetTestResult = () => ({
  type: RESET_TEST_RESULT,
})

export const getStudentCourses = studentId => ({
  type: GET_STUDENT_COURSES,
  studentId,
})

export const getStudentCoursesSuccess = courses => ({
  type: GET_STUDENT_COURSES_SUCCESS,
  payload: courses,
})

export const getStudentCoursesFail = error => ({
  type: GET_STUDENT_COURSES_FAIL,
  payload: error,
})

export const resetStudentCourses = () => ({
  type: RESET_STUDENT_COURSES,
})

export const generateCertificate = (studentId, courseId) => ({
  type: GENERATE_CERTIFICATE,
  studentId,
  courseId,
})

export const generateCertificateSuccess = certificates => ({
  type: GENERATE_CERTIFICATE_SUCCESS,
  payload: certificates,
})

export const generateCertificateFail = error => ({
  type: GENERATE_CERTIFICATE_FAIL,
  payload: error,
})

export const searchUsers = params => ({
  type: SEARCH_USER,
  params,
})
export const searchUsersSuccess = users => ({
  type: SEARCH_USER_SUCCESS,
  payload: users,
})
export const searchUsersFail = error => ({
  type: SEARCH_USER_FAIL,
  payload: error,
})

export const getUserTabDataSuccess = data => ({
  type: GET_USER_TAB_DATA_SUCCESS,
  payload: data,
})

export const getUserTabData = (slug, userId,queryParams="") => ({
  type: GET_USER_TAB_DATA,
  slug,
  userId,
  queryParams
})
