import {
  GET_HR_USERS_DETAIL_FAIL,
  GET_HR_USERS_DETAIL_SUCCESS,
  GET_HR_USERS_FAIL,
  GET_HR_USERS_SUCCESS,
  ADD_HR_USER_FAIL,
  ADD_HR_USER_SUCCESS,
  UPDATE_HR_USER_FAIL,
  UPDATE_HR_USER_SUCCESS,
  UPDATE_HR_USER_LOADING_STATE
} from "./actionTypes"

const INIT_STATE = {
  hrUsers: [],
  hrUserDetails: {},
  error: {},
  loading: false,
}

const hrUsers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HR_USERS_SUCCESS:
      return {
        ...state,
        hrUsers: action.payload,
      }

    case GET_HR_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_HR_USER_SUCCESS:
      return {
        ...state,
        hrUsers: [...state.hrUsers, action.payload],
      }

    case ADD_HR_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_HR_USER_SUCCESS:
      return {
        ...state,
        hrUsers: state.hrUsers.map(hrUser =>
          hrUser._id.toString() === action.payload._id.toString()
            ? { hrUser, ...action.payload }
            : hrUser
        ),
      }

    case UPDATE_HR_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_HR_USERS_DETAIL_SUCCESS:
      return {
        ...state,
        hrUserDetails: action.payload,
      }

    case GET_HR_USERS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_HR_USER_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    default:
      return state
  }
}

export default hrUsers
