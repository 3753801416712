/* PACKAGE */
export const GET_PACKAGE = "GET_PACKAGE"
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS"
export const GET_PACKAGE_FAIL = "GET_PACKAGE_FAIL"

/* PACKAGE DETAIL */
export const GET_PACKAGE_DETAIL = "GET_PACKAGE_DETAIL"
export const GET_PACKAGE_DETAIL_SUCCESS = "GET_PACKAGE_DETAIL_SUCCESS"
export const GET_PACKAGE_DETAIL_FAIL = "GET_PACKAGE_DETAIL_FAIL"

/**
 * Add package
 */
export const ADD_NEW_PACKAGE = "ADD_NEW_PACKAGE"
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS"
export const ADD_PACKAGE_FAIL = "ADD_PACKAGE_FAIL"

/**
 * Edit package
 */
export const UPDATE_PACKAGE = "UPDATE_PACKAGE"
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS"
export const UPDATE_PACKAGE_FAIL = "UPDATE_PACKAGE_FAIL"

/**
 * Delete package
 */
export const DELETE_PACKAGE = "DELETE_PACKAGE"
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS"
export const DELETE_PACKAGE_FAIL = "DELETE_PACKAGE_FAIL"

export const UPDATE_PACKAGE_LOADING_STATE = "UPDATE_PACKAGE_LOADING_STATE"

/* RESET PACKAGE */
export const RESET_PACKAGE_REPORT = "RESET_PACKAGE_REPORT"
export const RESET_PACKAGE_REPORT_SUCCESS = "RESET_PACKAGE_REPORT_SUCCESS"
export const RESET_PACKAGE = "RESET_PACKAGE"
