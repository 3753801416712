import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import {
  GET_CHAPTER_CONTENT,
  ADD_CHAPTER_CONTENT,
  UPDATE_CHAPTER_CONTENT,
  DELETE_CHAPTER_CONTENT
} from "./actionTypes"
import {
  getChapterContentSuccess,
  getChapterContentFail,
  updateChapterContentSuccess,
  updateChapterContentFail,
  deleteChapterContentSuccess,
  deleteChapterContentFail,
  updateChapterContentLoadingState,
  addChapterContentFail,
  addChapterContentSuccess
} from "./actions"

import {
  getChapterContentByChapterId,
  addNewChapterContent,
  updateChapterContent as UpdateChapterContentApi,
  deleteChapterContent as DeleteChapterContentApi
} from "helpers/backend_helper"

import { getCourseDetail } from "store/actions"

function* fetchChapterContent({ chapterId }) {
  try {
    yield put(updateChapterContentLoadingState(false))
    const response = yield call(getChapterContentByChapterId, chapterId)
    yield put(updateChapterContentLoadingState(true))
    yield put(getChapterContentSuccess(response.data.contents))
  } catch (error) {
    yield put(getChapterContentFail(error))
  }
}

function* onUpdateChapterContent({ payload: { chapterContent, contentId, history } }) {
  try {
    yield put(updateChapterContentLoadingState(false))
    const response = yield call(UpdateChapterContentApi, chapterContent, contentId)
    toastr.success("Chapter Content updated successfully..!!")
    yield put(getCourseDetail(response.data.content?.courseId))
    yield put(updateChapterContentLoadingState(true))
    yield put(updateChapterContentSuccess(response.data.content))
  } catch (error) {
    yield put(updateChapterContentFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteChapterContent({ payload: { contentId, history } }) {
  try {
    yield put(updateChapterContentLoadingState(true))
    const response = yield call(DeleteChapterContentApi, contentId)
    toastr.success("Chapter Content deleted successfully..!!")
    yield put(updateChapterContentLoadingState(true))
    yield put(deleteChapterContentSuccess(contentId))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteChapterContentFail(error))
  }
}

function* onAddChapterContent({ payload }) {
  const { chapterContent, history } = payload
  try {
    yield put(updateChapterContentLoadingState(true))
    const response = yield call(addNewChapterContent, chapterContent)
    toastr.success("Content Added Successfully..!!")
    yield put(getCourseDetail(chapterContent?.courseId))
    yield put(updateChapterContentLoadingState(false))
    yield put(addChapterContentSuccess(response.data.chapterContent))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateChapterContentLoadingState(false))
    yield put(addChapterContentFail(error))
  }
}

function* chapterContentSaga() {
  yield takeEvery(GET_CHAPTER_CONTENT, fetchChapterContent)
  yield takeEvery(ADD_CHAPTER_CONTENT, onAddChapterContent)
  yield takeEvery(UPDATE_CHAPTER_CONTENT, onUpdateChapterContent)
  yield takeEvery(DELETE_CHAPTER_CONTENT, onDeleteChapterContent)
}

export default chapterContentSaga
