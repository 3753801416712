import {
  GET_PLAN_SUCCESS,
  GET_PLAN_FAIL,
  UPDATE_PLAN_LOADING_STATE,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAIL,
  GET_PLAN_DETAIL_SUCCESS,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAIL,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAIL,
  GET_PLAN_DETAIL_FAIL,
  RESET_PLAN,
  GET_COMPANY_PLAN_DETAIL_FAIL,
  GET_COMPANY_PLAN_DETAIL_SUCCESS,
  GET_MOCK_TEST_PRICE_DETAIL_SUCCESS,
  GET_MOCK_TEST_PRICE_DETAIL_FAIL,
  UPDATE_MOCK_TEST_PRICE_DETAIL_FAIL,
  UPDATE_MOCK_TEST_PRICE_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  plans: [],
  receivers: [],
  planDetail: {},
  error: {},
  loading: false,
  companyPlanDetail: {},
  mockTestPriceDetail: {},
}

const plans = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        plans: action.payload,
      }

    case UPDATE_PLAN_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case GET_PLAN_FAIL:
    case ADD_PLAN_FAIL:
    case UPDATE_PLAN_FAIL:
    case DELETE_PLAN_FAIL:
    case GET_PLAN_DETAIL_FAIL:
    case GET_COMPANY_PLAN_DETAIL_FAIL:
    case GET_MOCK_TEST_PRICE_DETAIL_FAIL:
    case UPDATE_MOCK_TEST_PRICE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PLAN_SUCCESS:
      return {
        ...state,
        plans: [...state.plans, action.payload],
      }

    case GET_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        planDetail: action.payload,
      }

    case UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        plans: state.plans.map(plan =>
          plan._id.toString() === action.payload._id.toString()
            ? { ...plan, ...action.payload }
            : plan
        ),
      }

    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        plans: state.plans.filter(
          plan => plan._id.toString() !== action.payload.toString()
        ),
      }
    case GET_COMPANY_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        companyPlanDetail: action.payload,
      }

    case GET_MOCK_TEST_PRICE_DETAIL_SUCCESS:
      return {
        ...state,
        mockTestPriceDetail: action.payload,
      }
    case UPDATE_MOCK_TEST_PRICE_DETAIL_SUCCESS:
      return {
        ...state,
        mockTestPriceDetail: action.payload,
      }
    case RESET_PLAN:
      return {
        ...state,
        planDetail: {},
      }

    default:
      return state
  }
}

export default plans
