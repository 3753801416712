import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_MOCK_TESTS,
  GET_MOCK_TEST_DETAIL,
  ADD_NEW_MOCK_TEST,
  DELETE_MOCK_TEST,
  UPDATE_MOCK_TEST,
} from "./actionTypes"
import {
  getMockTestsSuccess,
  getMockTestsFail,
  getMockTestDetailSuccess,
  getMockTestDetailFail,
  addMockTestFail,
  addMockTestSuccess,
  updateMockTestSuccess,
  updateMockTestFail,
  deleteMockTestSuccess,
  deleteMockTestFail,
  updateMockTestLoadingState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMockTests,
  updateMockTest,
} from "helpers/backend_helper"
import {
  addNewTest,
  deleteTest,
  getTestsDetails,
} from "helpers/api/testApi"

function* fetchMockTests({params}) {
  try {
    const { page, limit } = params
    let queryParams = `?page=${page}&limit=${limit}`
    if (params?.search && params?.search?.length) {
      queryParams =  `?page=${1}&search=${params?.search}`
    }
    yield put(updateMockTestLoadingState(true))
    const response = yield call(getMockTests,queryParams)
    yield put(getMockTestsSuccess(response.data.mockTest))
    yield put(updateMockTestLoadingState(false))
  } catch (error) {
    yield put(updateMockTestLoadingState(false))
    yield put(getMockTestsFail(error))
  }
}

function* fetchMockTestDetail({ testId }) {
  try {
    const response = yield call(getTestsDetails, testId)
    yield put(getMockTestDetailSuccess(response.data.data))
  } catch (error) {
    yield put(getMockTestDetailFail(error))
  }
}

function* onUpdateMockTest({ payload }) {
  const { mockTest, history ,params} = payload
  try {
    const response = yield call(updateMockTest, {...mockTest})
    yield put(updateMockTestSuccess(response.data.mockTest))
    if(params){
      toastr.success("Mock Test updated successfully..!!")
      const { page, limit } = params
      let queryParams = `?page=${page}&limit=${limit}`
      yield put(updateMockTestLoadingState(true))
      const resp = yield call(getMockTests,queryParams)
      yield put(getMockTestsSuccess(resp.data.mockTest))
      yield put(updateMockTestLoadingState(false))
    }
  } catch (error) {
    yield put(updateMockTestFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteMockTest({ payload: testId }) {
  try {
    const response = yield call(deleteTest, testId)
    toastr.success("Test deleted successfully..!!")
    yield put(deleteMockTestSuccess(response.data))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteMockTestFail(error))
  }
}

function* onAddNewMockTest({ payload: { test, history } }) {
  try {
    yield put(updateMockTestLoadingState(true))
    const response = yield call(addNewTest, test)
    // toastr.success("New Test Added Successfully..!!")
    yield put(addMockTestSuccess(response.data.data))
    yield put(updateMockTestLoadingState(false))
    setTimeout(() => {
      history(`/questions-create/test/${response.data.data._id}`)
    }, 1500)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateMockTestLoadingState(false))
    yield put(addMockTestFail(error))
  }
}

function* mockTestsSaga() {
  yield takeEvery(GET_MOCK_TESTS, fetchMockTests)
  yield takeEvery(GET_MOCK_TEST_DETAIL, fetchMockTestDetail)
  yield takeEvery(ADD_NEW_MOCK_TEST, onAddNewMockTest)
  yield takeEvery(UPDATE_MOCK_TEST, onUpdateMockTest)
  yield takeEvery(DELETE_MOCK_TEST, onDeleteMockTest)
}

export default mockTestsSaga
