import React from 'react'
import { Table } from "reactstrap"

const UserFeedBacks = ({ marks, mockTest }) => {
    const data = [
        { name: "Educational Background", value: marks?.educational || 0 },
        { name: "Technical Qualifications", value: marks?.technical || 0},
        { name: "Prior Work Experience", value: marks?.workExperience || 0 },
        { name: "Communication Skills", value: marks?.communicationSkills || 0 },
        { name: "Confidence", value: marks?.confidence || 0},
        { name: "Candidate Enthusiasm", value: marks?.enthusiasm || 0},
        { name: "Leadership Ability", value: marks?.leadership || 0},
        { name: "Overall Impression and Recommendation", value: marks?.overallImpression || 0 },
        // { name: "Comments", value: marks?.comments },
    ];
    return (
        <div>
            <Table size="sm" striped>
                <thead>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            FeedBack
                        </th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.name}</td>
                            <td>{item.value !== null ? `${item.value} of 5` : 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default UserFeedBacks