import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import {
    Col,
    Container,
    Row,
    Spinner
} from "reactstrap"
import {
    getTestsByCategoryId as onGetTestsByCategoryId,
    getTestCategoryDetail as onGetTestCategoryDetail,
    resetTestsOfCategory as onResetTestsOfCategory
} from "../../store/actions"

import { useSelector, useDispatch } from "react-redux"
// import Spinner from "reactstrap"
const TestCategoriesList = props => {
    document.title = "Category List | QAPRENEUR"
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)


    const { categoryId } = params

    const { tests, loading,testCategoryDetail } = useSelector(state => ({
        loading: state?.testCategories?.loading,
        tests: state?.testCategories?.tests,
        testCategoryDetail:state?.testCategories?.testCategoryDetail
    }))


    useEffect(() => {
        if (categoryId) {
            dispatch(onGetTestsByCategoryId(categoryId))
            dispatch(onGetTestCategoryDetail(categoryId))
            setIsLoading(false)
        }
        return () => {
            setIsLoading(false)
            onResetTestsOfCategory()
        }
    }, [categoryId, dispatch])

    useEffect(() => {
        if (Array.isArray(tests) && tests?.length && Object.keys(testCategoryDetail).length) {
            setIsLoading(false)
        }
    }, [tests,testCategoryDetail])

    const columns = [
        {
            header: "#",
            key: ""
        },
        {
            header: "Image",
            name: "image",
            key: "IMAGE",
            link: "/test-details"
        },
        {
            header: "Name",
            name: "name",
            key: "NAME_DESCRIPTION",
            link: "/test-details"
        },
        {
            header: "Category",
            name: "testCategoryType",
            key: "DEFAULT",
            link: "/test-details",
        },
        {
            header: "Type",
            name: "type",
            key: "DEFAULT",
            link: "/test-details",
        },
        {
            header: "Duration(In minutes)",
            name: "duration",
            key: "DEFAULT",
            link: "/test-details"
        },
        {
            header: "Created Date",
            name: "createdAt",
            key: "DATE",
            link: "/test-details"
        },
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="CATEGORY"
                        breadcrumbItem={testCategoryDetail?.type || ""}
                        navigate={-1}
                    />
                    <Row>
                        <Col lg="12">
                            {isLoading ? (
                                <div className="d-flex justify-content-center"><Spinner className="ms-2" color="dark" size="sm" /></div>
                            ) :
                                (<TableContainer
                                    loading={loading}
                                    data={tests.map((test) => ({ ...test, testCategoryType: test?.testCategory?.type }))}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    customPageSize={10}
                                    className="project-list-table table-nowrap align-middle table-borderless"
                                    addButton={{ back: true, onclick: () => navigate(-1), title: "Back" }}
                                    columns={columns}
                                />)
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(TestCategoriesList)

TestCategoriesList.propTypes = {
    history: PropTypes.object,
}
