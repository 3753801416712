import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import {
  GET_MULTI_MEDIA_CONTENT,
  ADD_MULTI_MEDIA_CONTENT,
  UPDATE_MULTI_MEDIA_CONTENT,
  DELETE_MULTI_MEDIA_CONTENT
} from "./actionTypes"
import {
  getMultiMediaContentSuccess,
  getMultiMediaContentFail,
  updateMultiMediaContentSuccess,
  updateMultiMediaContentFail,
  deleteMultiMediaContentSuccess,
  deleteMultiMediaContentFail,
  updateMultiMediaContentLoadingState,
  addMultiMediaContentFail,
  addMultiMediaContentSuccess
} from "./actions"

import {
  getMultiMediaContent,
  addNewMultiMediaContent,
  updateMultiMediaContent as UpdateMultiMediaContentApi,
  deleteMultiMediaContent as deleteMultiMediaContentApi
} from "helpers/backend_helper"

import { getCourseDetail } from "store/actions"

function* fetchMultiMediaContent({ params }) {
  try {
    yield put(updateMultiMediaContentLoadingState(true))
    let response = ''
    if (Object.keys(params).includes('page')) {
      const queryParams = `?page=${params.page}&limit=${params.limit}`
      response = yield call(getMultiMediaContent, queryParams)
    } else {
      response = yield call(getMultiMediaContent)
    }
    yield put(updateMultiMediaContentLoadingState(false))
    yield put(getMultiMediaContentSuccess(response.data.mediaList))
  } catch (error) {
    yield put(updateMultiMediaContentLoadingState(false))
    yield put(getMultiMediaContentFail(error))
  }
}

function* onUpdateMultiMediaContent({ payload: { multiMediaContent, multiMediaId, history } }) {
  try {
    yield put(updateMultiMediaContentLoadingState(true))
    const response = yield call(UpdateMultiMediaContentApi, multiMediaContent, multiMediaId)
    toastr.success("Multi Media updated successfully..!!")
    yield put(updateMultiMediaContentLoadingState(false))
    yield put(updateMultiMediaContentSuccess(response.data.multiMediaDetail))
  } catch (error) {
    yield put(updateMultiMediaContentLoadingState(false))
    yield put(updateMultiMediaContentFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteMultiMediaContent({ payload: { multiMediaId, history } }) {
  try {
    yield put(updateMultiMediaContentLoadingState(true))
    const response = yield call(deleteMultiMediaContentApi, multiMediaId)
    toastr.success("Multi Media deleted successfully..!!")
    yield put(updateMultiMediaContentLoadingState(false))
    yield put(deleteMultiMediaContentSuccess(multiMediaId))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateMultiMediaContentLoadingState(false))
    yield put(deleteMultiMediaContentFail(error))
  }
}

function* onAddMultiMediaContent({ payload }) {
  const { multiMediaContent, history } = payload
  try {
    yield put(updateMultiMediaContentLoadingState(true))
    const response = yield call(addNewMultiMediaContent, multiMediaContent)
    toastr.success("Multi Media Added Successfully..!!")
    const res = yield call(getMultiMediaContent)
    yield put(updateMultiMediaContentLoadingState(false))
    yield put(addMultiMediaContentSuccess(response.data.multiMediaDetail))
    yield put(getMultiMediaContentSuccess(res.data.mediaList))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateMultiMediaContentLoadingState(false))
    yield put(addMultiMediaContentFail(error))
  }
}

function* multiMediaContentSaga() {
  yield takeEvery(GET_MULTI_MEDIA_CONTENT, fetchMultiMediaContent)
  yield takeEvery(ADD_MULTI_MEDIA_CONTENT, onAddMultiMediaContent)
  yield takeEvery(UPDATE_MULTI_MEDIA_CONTENT, onUpdateMultiMediaContent)
  yield takeEvery(DELETE_MULTI_MEDIA_CONTENT, onDeleteMultiMediaContent)
}

export default multiMediaContentSaga
