import React from 'react'
import { Image, Row, Col } from "reactstrap"

import notFoundImg from "../../../assets/images/notFound/QAP-Mascot_A_V2.png"
import notFoundImg2 from "../../../assets/images/notFound/QAP-Mascot_A_V3.png"


function NotFound() {
    return (
        <Row>
            <Col xs="12">
                <div className="text-center my-3">
                    <img src={notFoundImg2}alt={"qapreneur"} height="200" width="200" />
                    <p style={{ fontSize: "18px" ,fontWeight:"500"}} className="text-gray">Hey there! Nothing to show</p>
                </div>
            </Col>
        </Row>
    )
}

export default NotFound