
export const columns = [
    {
        header: "#",
        key: ""
    },
    {
        header: "Image",
        name: "image",
        key: "IMAGE",
        link: "/coaching-details"
    },
    {
        header: "Name",
        name: "name",
        key: "NAME_DESCRIPTION",
        link: "/coaching-details"
    },
    {
        header: "Contact Person Name",
        name: "contactPersonName",
        key: "DEFAULT",
        link: "/coaching-details",
    },
    {
        header: "Mobile",
        name: "phone",
        key: "DEFAULT",
        link: "/coaching-details",
    },
    {
        header: "Email",
        name: "email",
        key: "DEFAULT",
        link: "/coaching-details"
    },
    {
        header: "Address",
        name: "address",
        key: "DEFAULT",
        link: "/coaching-details"
    },
    {
        header: "Status",
        name: "status",
        key: "STATUS",
    },
    {
        header: "Active/Deactive",
        key: "SWITCH",
    },
    {
        header: "Actions",
        key: "MORE_ACRIONS",
        dropDownOptions: [
            { label: "Edit", type: "EDIT" },
            { label: "View", type: "VIEW" },
            { label: "Delete", type: "DELETE" }
        ],
    },
]

export 	const formFields = [
    {
        name: "name",
        label: "Coaching Name",
        type: "text",
        placeholder: "Enter Name",
        required: true,
    },
    {
        name: "email",
        label: "Email",
        type: "text",
        placeholder: "Enter Email",
        required: true,
    },
    {
        name: "phone",
        label: "Mobile",
        type: "text",
        placeholder: "Enter Mobile",
        required: true,
    },
    {
        name: "address",
        label: "Address Line 1",
        type: "text",
        placeholder: "Address Line 1",
        required: true,
    },
    {
        name: "city",
        label: "Select City",
        type: "select",
        options: [
            { value: "Bhopal", label: "Bhopal" },
            { value: "Indore", label: "Indore" },
            { value: "Vidisha", label: "Vidisha" },
        ],
        required: true,
    },
    {
        name: "state",
        label: "Select State",
        type: "select",
        options: [
            { value: "MadhyaPradesh", label: "Madhya Pradesh" },
            { value: "Maharashtra", label: "Maharashtra" },
            { value: "Punjab", label: "Punjab" },
        ],
        required: true,
    },
    {
        name: "contactNumber",
        label: "Contact Person Number",
        type: "number",
        placeholder: "Enter Contact Person Number",
        required: true,
    },
    {
        name: "contactPersonName",
        label: "Contact Person Name",
        type: "text",
        placeholder: "Enter Contact Person Name",
        required: true,
    },
    {
        name: "establishmentDate",
        label: "Establishment Date",
        type: "date",
        required: true,
    },
    {
        name: "website",
        label: "Website",
        type: "text",
        placeholder: "Website",
        required: false,
    },
];

export const renderTOFormFields = (isEdit) => {
    return [
        {
            name: "name",
            label: "Coaching Name",
            type: "text",
            placeholder: "Enter Coaching Name",
            required: true,
        },
        {
            name: "contactPersonName",
            label: "Contact Person Name",
            type: "text",
            placeholder: "Enter contact person name",
            required: true,
        },
        {
            name: "email",
            label: "Email",
            type: "text",
            placeholder: "Enter Email",
            required: true,
            disabled:isEdit
        },
        {
            name: "address",
            label: "Address",
            type: "textarea",
            placeholder: "Enter Address",
            required: true,
        }
    ]
}