//REGISTER
export const POST_REGISTER = "/users/signup"

export const GET_ADMIN_DASHBOARD_DATA = "/dashboards/admin"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_JWT_LOGIN = "/users/login"
export const POST_JWT_ADMIN_LOGIN = "/users/admin/login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_JWT_PASSWORD_FORGET = "/users/forgotPassword"
export const RESET_PASSWORD = "/users/resetPasswordWithPhone"

export const SOCIAL_LOGIN = "/social-login"

export const USER_ROUTES = "/users"
export const DASHBOARD_ROUTES = "/dashboards"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//Roles
export const GET_ROLES = "/roles"

//Review
export const REVIEW_ROUTES = "/review"

export const HELP_CENTER_ROUTES = "/helpCenter"

//TESTS
export const TEST_ROUTES = "/tests"

//TESTS-Category
export const TEST_CATEGORIES_ROUTES = "/test-categories"

//COURSES
export const COURSES_ROUTES = "/courses"

//CHAPTERS
export const CHAPTER_ROUTES = "/chapters"

//STUDENTS
export const GET_STUDENTS = "/users"

//COLLEGES
export const GET_COLLEGES = "/users"

//COACHINGS
export const GET_COACHINGS = "/users"

//COMPANIES
export const GET_COMPANIES = "/users"

export const COMPANY_ROUTES = "/company"
export const MOCK_TEST_PRICE_ROUTES = "/mock-test-price"

export const JOBS_ROUTES = "/jobs"
export const JOBS_REFERRALS_ROUTES = "/job-referrals"
export const NOTIFICATION_ROUTES = "/notifications"
export const ANNOUNCEMENT_ROUTES = "/announcement"

//SKILLS
export const GET_SKILLS = "/skills"

//QUESTIONS
export const GET_QUESTIONS = "/questions"

//CHAPTERS
export const GET_CHAPTERS = "/chapters"

//TRANSACTIONS
export const TRANSACTIONS_ROUTES = "/transaction"

//SUBSCRIPTIONS
export const SUBSCRIPTIONS_ROUTES = "/subscription"
export const PACKAGES_ROUTES = "/user-packages"
