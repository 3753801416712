/* STUDENTS */
export const GET_STUDENTS = "GET_STUDENTS"
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS"
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL"

/* STUDENTS DETAIL*/
export const GET_STUDENT_DETAIL = "GET_STUDENT_DETAIL"
export const GET_STUDENT_DETAIL_SUCCESS = "GET_STUDENT_DETAIL_SUCCESS"
export const GET_STUDENT_DETAIL_FAIL = "GET_STUDENT_DETAIL_FAIL"

/* GET USER TAB DATA SUCCESS*/
export const GET_USER_TAB_DATA_SUCCESS = "GET_USER_TAB_DATA_SUCCESS"
export const GET_USER_TAB_DATA = "GET_USER_TAB_DATA"

/**
 * add course
 */
export const ADD_NEW_STUDENT = "ADD_NEW_STUDENT"
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS"
export const ADD_STUDENT_FAIL = "ADD_STUDENT_FAIL"

/**
 * Edit course
 */
export const UPDATE_STUDENT = "UPDATE_STUDENT"
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS"
export const UPDATE_STUDENT_FAIL = "UPDATE_STUDENT_FAIL"

/**
 * Delete course
 */
export const DELETE_STUDENT = "DELETE_STUDENT"
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS"
export const DELETE_STUDENT_FAIL = "DELETE_STUDENT_FAIL"

export const RESET_STUDENT = "RESET_STUDENT"

export const UPDATE_STUDENT_LOADING_STATE = "UPDATE_STUDENT_LOADING_STATE"


export const GET_TEST_RESULT = "GET_TEST_RESULT"
export const GET_TEST_RESULT_SUCCESS = "GET_TEST_RESULT_SUCCESS"
export const GET_TEST_RESULT_FAIL = "GET_TEST_RESULT_FAIL"
export const RESET_TEST_RESULT = "RESET_TEST_RESULT"

export const GET_STUDENT_COURSES = "GET_STUDENT_COURSES"
export const GET_STUDENT_COURSES_SUCCESS = "GET_STUDENT_COURSES_SUCCESS"
export const GET_STUDENT_COURSES_FAIL = "GET_STUDENT_COURSES_FAIL"
export const RESET_STUDENT_COURSES = "RESET_STUDENT_COURSES"

export const GENERATE_CERTIFICATE = "GENERATE_CERTIFICATE"
export const GENERATE_CERTIFICATE_SUCCESS = "GENERATE_CERTIFICATE_SUCCESS"
export const GENERATE_CERTIFICATE_FAIL = "GENERATE_CERTIFICATE_FAIL"


/* SEARCH_USER */
export const SEARCH_USER = "SEARCH_USER"
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS"
export const SEARCH_USER_FAIL = "SEARCH_USER_FAIL"

