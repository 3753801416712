import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Transaction Redux States
import { GET_HELP_CENTER, ADD_NEW_HELP_CENTER } from "./actionTypes"
import {
  getHelpCenterSuccess,
  getHelpCenterFail,
  addHelpCenterSuccess,
  addHelpCenterFail,
  updateHelpCenterLoadingState,
} from "./actions"

// Include Helper Files with needed methods
import { getHelpCenterApi, addHelpCenterApi } from "helpers/api/helpCenterApi"

function* fetchHelpCenter({ params }) {
  try {
    const { page, limit } = params
    let queryParams = `?page=${page}&limit=${limit}`
    if (params?.search && params?.search?.length) {
      queryParams = queryParams + `&search=${params?.search}`
    }
    yield put(updateHelpCenterLoadingState(true))

    const response = yield call(getHelpCenterApi,queryParams)
    yield put(getHelpCenterSuccess(response.data))
    yield put(updateHelpCenterLoadingState(false))
  } catch (error) {
    yield put(getHelpCenterFail(error))
    yield put(updateHelpCenterLoadingState(false))

  }
}

function* addNewHelpCenter({ payload: { helpCenter } }) {
  try {
    yield put(updateHelpCenterLoadingState(true))

    // const response = yield call(addHelpCenterApi, helpCenter)
    // yield put(addHelpCenterSuccess(response.data.newHelpCenter))
    toastr.success("Help center added successfully.")
  } catch (error) {
    yield put(addHelpCenterFail(error))
    yield put(updateHelpCenterLoadingState(false))
    toastr.error("Failed to add help center.")
  }
}

function* helpCenterSaga() {
  yield takeEvery(GET_HELP_CENTER, fetchHelpCenter)
  yield takeEvery(ADD_NEW_HELP_CENTER, addNewHelpCenter)
}

export default helpCenterSaga
