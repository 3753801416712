import React, { useState } from "react"
import { FormGroup, Input, Label, Row, Col } from "reactstrap"

const AppCheckboxGroup = ({
  name,
  label,
  options,
  invalid,
  error,
  value,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(value)
  return (
    <div className="mb-3">
      <FormGroup tag="fieldset">
        <Label className="form-label">{label}</Label>
        <br />
        <Row>
          {options.map((option, index) => (
            <Col key={index}>
              <FormGroup
                check
                inline
                className={`custom-checkbox border ${
                  invalid ? "border-danger" : "border-primary"
                } p-2 rounded-lg ${option.value === selectedValue ? "active" : ""}`}
                style={{width:'100%'}}
              >
                <div className="d-flex align-items-center">
                  <Input
                    type="checkbox"
                    name={name}
                    checked={value === option.value}
                    onClick={() => onChange(option.value)}
                    style={{ width: "20px", height: "20px" }}
                    className="mx-1"
                  />
                  <Label className="ms-2" check>
                    {option.label}
                  </Label>
                </div>
              </FormGroup>
            </Col>
          ))}
        </Row>
        {invalid ? (
          <p className="text-danger font-size-15 mt-1">{error}</p>
        ) : null}
      </FormGroup>
    </div>
  )
}

export default AppCheckboxGroup
