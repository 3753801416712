import React, { useEffect, useState, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import { Button, Col, Container, Row } from "reactstrap"
import {
  getPlans as onGetPlans,
  deletePlan as onDeletePlan,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import TableContainer from "../../components/Common/Table/index"
import AppTabs from "../../components/Common/Tabs"

import { tabIds } from "./constants"
import { getPlanColumn } from "./Utills"
import { AppContext } from "context/AppContext"

const OurPlan = () => {
  document.title = "Plan List | QAPRENEUR"
  const { authRole } = useContext(AppContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const [planList, setPlanList] = useState([])
  const [plan, setPlan] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const [activeTab, setActivetab] = useState(params.profileTab)

  const { plans, loading } = useSelector(state => ({
    plans: state.plans?.plans,
    loading: state?.plans?.loading,
  }))

  useEffect(() => {
    if (authRole === "admin") {
      const queryParams = {
        page: pageNo,
        limit: rowPerPage,
      }
      if (search && search.length > 0) {
        queryParams.search = search
      }
      if (activeTab && activeTab.length > 0) {
        queryParams.role = activeTab
      }
      dispatch(onGetPlans(queryParams))
    }
  }, [dispatch, activeTab])

  useEffect(() => {
    if (!isEmpty(plans)) {
      setPlanList(
        plans
          .map(item => ({
            ...item,
            ...item?.companySettings,
            ...item?.studentSettings,
            planName: item.name,
            _id: item._id,
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setPlanList([])
    }
  }, [plans])

  const handleModalAction = () => {
    dispatch(onDeletePlan(plan._id))
    setDeleteModal(false)
  }

  const handleActions = (pln, type) => {
    if (type === "EDIT") {
      navigate(`/edit-plan/${pln._id}/${activeTab}`)
    } else if (type === "DELETE") {
      setPlan(pln)
      setDeleteModal(true)
    }
  }

  const handleTabClick = tabId => {
    setActivetab(tabId)
  }

  const tabsArray = tabIds.map((tab, key) => {
    const planColumns = getPlanColumn(tab.id)
    return {
      id: tab.id,
      label: tab.label,
      content: (
        <Col sm="12">
          <div className="pt-3 px-2">
            {tab.id === "student" && (
              <Button
                className="btn btn-primary global_btn me-1"
                onClick={() => navigate("/edit-mock-test-price")}
              >
                Mock Interview Price
                <span className="mdi mdi-currency-inr"></span>
              </Button>
            )}
            <TableContainer
              loading={loading}
              data={planList}
              isGlobalFilter={false}
              isAddOptions={false}
              isJobListGlobalFilter={false}
              // addButton={
              //   role == "admin"
              //     ? { link: `/add-plan/${tab.id}`, title: "Add Plan" }
              //     : null
              // }
              handleActions={handleActions}
              showPageDropdown={false}
              customPageSize={10}
              className="project-list-table table-nowrap align-middle table-borderless"
              columns={planColumns}
              // tableTitle={tab.label}
            />
          </div>
        </Col>
      ),
    }
  })

  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the plan."}
          modalAction={handleModalAction}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Plan" />
          <Row>
            <Col lg="12">
              <AppTabs
                tabsArray={tabsArray}
                contentBgColor=""
                handleTabClick={handleTabClick}
              />
            </Col>
            <Col lg="12"></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OurPlan
