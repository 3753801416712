import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import TableContainer from "../../components/Common/Table/index"
import { userJobAppliedcolumns } from "./constants"
import { useDispatch } from "react-redux"
import { getUserTabData as onGetUserTabData } from "../../store/students/actions"

function UserAppliedJobs({
  userAppliedJobList=[],
  loading,
  userId,
  handleActions,
}) {
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [data, setData] = useState([])
  const tabId = "JOBAPPLIED"
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      userAppliedJobList &&
      Array.isArray(userAppliedJobList) &&
      userAppliedJobList.length > 0
    ) {
      setData(
        userAppliedJobList.map(userAppliedJob => {
          return {
            ...userAppliedJob,
            ...userAppliedJob?.jobDetail,
            image: userAppliedJob?.companyDetail?.image,
            status: userAppliedJob?.status,
            companyName: userAppliedJob?.companyDetail?.name,
          }
        })
      )
    }
  }, [userAppliedJobList])

  const onChangePagination = (limit, page) => {
    setRowPerPage(rowPerPage)
    setPageNo(page)
    if (userId) {
      dispatch(onGetUserTabData(tabId, userId, `&page=${page}&limit=${limit}`))
    }
  }

  return (
    <>
      <Card style={{ borderRadius: 15 }}>
        <CardBody>
          <div className="pt-3">
            <div className="d-flex justify-content-between">
              <h4 className="sun_heading">Job Applied History</h4>
            </div>
            {userAppliedJobList && Array.isArray(userAppliedJobList) ? (
              <TableContainer
                loading={loading}
                data={data}
                isGlobalFilter={false}
                isAddOptions={false}
                isJobListGlobalFilter={false}
                showPageDropdown={false}
                customPageSize={10}
                handleActions={handleActions}
                className="project-list-table table-nowrap align-middle table-borderless"
                columns={userJobAppliedcolumns}
                tableName="viewStudent"
                showPagination={true}
                onChangePagination={onChangePagination}
              />
            ) : (
              "No Job Applied yet!"
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default UserAppliedJobs
