import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"
import {
  getReviews as onGetReviews,
  deleteReview as onDeleteReview,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import TableContainer from "../../components/Common/Table/index"
import { columns } from "./constants"

const OurReview = () => {
  document.title = "Review List | QAPRENEUR"
  const role = localStorage.getItem("authRole")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [reviewList, setReviewList] = useState([])
  const [review, setReview] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)

  const { reviews, loading } = useSelector(state => ({
    reviews: state.reviews?.reviews,
    loading: state?.reviews?.loading,
  }))

  useEffect(() => {
    if (role === "admin") {
      dispatch(onGetReviews())
    }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(reviews)) {
      setReviewList(
        reviews
          .map(item => ({
            ...item,
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setReviewList([])
    }
  }, [reviews])

  
  const handleModalAction = () => {
    dispatch(onDeleteReview(review._id))
    setDeleteModal(false)
  }

  const handleActions = (rvw, type) => {
    if (type === "EDIT") {
      navigate(`/edit-review/${rvw._id}`)
    } else if (type === "DELETE") {
      setReview(rvw)
      setDeleteModal(true)
    }
  }

  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the test."}
          modalAction={handleModalAction}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Review" />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={reviewList}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  addButton={
                    role == "admin"
                      ? { link: "/add-review", title: "Add Review" }
                      : null
                  }
                  handleActions={handleActions}
                  showPageDropdown={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={columns}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OurReview
