import {
  GET_REVIEWS,
  GET_REVIEWS_FAIL,
  GET_REVIEWS_SUCCESS,
  GET_REVIEW_DETAIL,
  ADD_NEW_REVIEW,
  ADD_REVIEW_SUCCESS,
  ADD_REVIEW_FAIL,
  UPDATE_REVIEW,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_FAIL,
  DELETE_REVIEW,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  GET_REVIEW_DETAIL_FAIL,
  GET_REVIEW_DETAIL_SUCCESS,
  UPDATE_REVIEW_LOADING_STATE,
  RESET_REVIEWS,
} from "./actionTypes"

export const getReviews = () => ({
  type: GET_REVIEWS,
})
export const getReviewsFail = error => ({
  type: GET_REVIEWS_FAIL,
  payload: error,
})


export const getReviewsSuccess = reviews => ({
  type: GET_REVIEWS_SUCCESS,
  payload: reviews,
})

export const resetReviews = () => ({
  type: RESET_REVIEWS,
})

export const addNewReview = (review, history) => ({
  type: ADD_NEW_REVIEW,
  payload: { review, history },
})

export const addReviewSuccess = review => ({
  type: ADD_REVIEW_SUCCESS,
  payload: review,
})

export const addReviewFail = error => ({
  type: ADD_REVIEW_FAIL,
  payload: error,
})

export const updateReview = (review, reviewId, history) => ({
  type: UPDATE_REVIEW,
  payload: { review, reviewId, history },
})

export const updateReviewSuccess = review => ({
  type: UPDATE_REVIEW_SUCCESS,
  payload: review,
})

export const updateReviewFail = error => ({
  type: UPDATE_REVIEW_FAIL,
  payload: error,
})

export const deleteReview = (reviewId) => ({
  type: DELETE_REVIEW,
  payload: { reviewId },
})

export const deleteReviewSuccess = review => ({
  type: DELETE_REVIEW_SUCCESS,
  payload: review,
})

export const deleteReviewFail = error => ({
  type: DELETE_REVIEW_FAIL,
  payload: error,
})


export const getReviewDetail = reviewId => ({
  type: GET_REVIEW_DETAIL,
  reviewId,
})

export const getReviewDetailSuccess = reviewDetails => ({
  type: GET_REVIEW_DETAIL_SUCCESS,
  payload: reviewDetails,
})

export const getReviewDetailFail = error => ({
  type: GET_REVIEW_DETAIL_FAIL,
  payload: error,
})

export const updateReviewLoadingState = status => ({
  type: UPDATE_REVIEW_LOADING_STATE,
  payload: status,
})