import {
  GET_COLLEGE_REQUESTS_FAIL,
  GET_COLLEGE_REQUESTS_SUCCESS,
  UPDATE_COLLEGE_REQUESTS_FAIL,
  UPDATE_COLLEGE_REQUESTS_LOADING_STATE,
  UPDATE_COLLEGE_REQUESTS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  collegeRequests: [],
  error: {},
  loading: false,
}

const collegeRequestsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COLLEGE_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COLLEGE_REQUESTS_SUCCESS:
      return {
        ...state,
        collegeRequests: action.payload,
      }

    case UPDATE_COLLEGE_REQUESTS_SUCCESS:
      return {
        ...state,
        collegeRequests: state.collegeRequests.map((collegeRequest) =>
          collegeRequest._id.toString() === action.payload._id.toString()
            ? { ...collegeRequest, ...action.payload }
            : collegeRequest
        ),
      }

    case UPDATE_COLLEGE_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_COLLEGE_REQUESTS_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    default:
      return state
  }
}

export default collegeRequestsReducer
