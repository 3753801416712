import {
  GET_APPLICANTS,
  GET_APPLICANTS_SUCCESS,
  GET_APPLICANTS_FAIL,
  RESET_APPLICANTS,
  UPDATE_APPLICANT_LOADING_STATE,
  UPDATE_APPLICANTS,
  UPDATE_APPLICANTS_SUCCESS,
  UPDATE_APPLICANTS_FAIL,
  GET_CANDIDATES,
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATES_FAIL,
  GET_MY_CANDIDATES,
  GET_MY_CANDIDATES_SUCCESS,
  GET_MY_CANDIDATES_FAIL,
  UNLOCK_CANDIDATES,
  UNLOCK_CANDIDATES_SUCCESS,
  UNLOCK_CANDIDATES_FAIL,
  GET_UNLOCK_CANDIDATES,
  GET_UNLOCK_CANDIDATES_FAIL,
  GET_UNLOCK_CANDIDATES_SUCCESS
} from "./actionTypes"

export const getApplicants = (jobId, params) => ({
  type: GET_APPLICANTS,
  jobId,
  params,
})

export const getApplicantsSuccess = applicants => ({
  type: GET_APPLICANTS_SUCCESS,
  payload: applicants,
})

export const getApplicantsFail = error => ({
  type: GET_APPLICANTS_FAIL,
  payload: error,
})

export const updateApplicants = (data, params) => ({
  type: UPDATE_APPLICANTS,
  data,
  params,
})

export const updateApplicantsSuccess = applicants => ({
  type: UPDATE_APPLICANTS_SUCCESS,
  payload: applicants,
})

export const updateApplicantsFail = error => ({
  type: UPDATE_APPLICANTS_FAIL,
  payload: error,
})

export const updateApplicantsLoadingState = status => ({
  type: UPDATE_APPLICANT_LOADING_STATE,
  payload: status,
})

export const resetApplicants = () => ({
  type: RESET_APPLICANTS,
})

export const getCandidates = (userId, params) => ({
  type: GET_CANDIDATES,
  userId,
  params,
})

export const getCandidatesSuccess = candidates => ({
  type: GET_CANDIDATES_SUCCESS,
  payload: candidates,
})

export const getCandidatesFail = error => ({
  type: GET_CANDIDATES_FAIL,
  payload: error,
})

export const getMyCandidates = (userId, params) => ({
  type: GET_MY_CANDIDATES,
  userId,
  params,
})

export const getMyCandidatesSuccess = myCandidates => ({
  type: GET_MY_CANDIDATES_SUCCESS,
  payload: myCandidates,
})

export const getMyCandidatesFail = error => ({
  type: GET_MY_CANDIDATES_FAIL,
  payload: error,
})

export const unlockApplicantsStatus = (candidateId, applicants) => ({
  type: UNLOCK_CANDIDATES,
  candidateId,
  applicants,
})
export const unlockApplicantsStatusSuccess = applicant => ({
  type: UNLOCK_CANDIDATES_SUCCESS,
  payload: applicant,
})

export const unlockApplicantsStatusFail = error => ({
  type: UNLOCK_CANDIDATES_FAIL,
  payload: error,
})


export const getUnlockCandidates = (companyId, params) => ({
  type: GET_UNLOCK_CANDIDATES,
  companyId,
  params,
})

export const getUnlockCandidatesSuccess = unlockCandidates => ({
  type: GET_UNLOCK_CANDIDATES_SUCCESS,
  payload: unlockCandidates,
})

export const getUnlockCandidatesFail = error => ({
  type: GET_UNLOCK_CANDIDATES_FAIL,
  payload: error,
})
