import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_REVIEWS,
  GET_REVIEW_DETAIL,
  ADD_NEW_REVIEW,
  DELETE_REVIEW,
  UPDATE_REVIEW,
} from "./actionTypes"
import {
  getReviewsSuccess,
  getReviewsFail,
  getReviewDetailSuccess,
  getReviewDetailFail,
  addReviewFail,
  addReviewSuccess,
  updateReviewSuccess,
  updateReviewFail,
  deleteReviewSuccess,
  deleteReviewFail,
  updateReviewLoadingState,
} from "./actions"

// Include Both Helper File with needed methods
import {
  getReviews,
  getReviewsDetails,
  addNewReview,
  updateReview,
  deleteReview,
} from "helpers/api/ourReviewApi"

function* fetchReviews() {
  try {
    yield put(updateReviewLoadingState(true))
    const response = yield call(getReviews)
    yield put(getReviewsSuccess(response.data.reviews))
    yield put(updateReviewLoadingState(false))
  } catch (error) {
    yield put(updateReviewLoadingState(false))
    yield put(getReviewsFail(error))
  }
}

function* fetchReviewDetail({ reviewId }) {
  try {
    yield put(updateReviewLoadingState(true))
    const response = yield call(getReviewsDetails, reviewId)
    yield put(getReviewDetailSuccess(response.data.review))
    yield put(updateReviewLoadingState(false))
  } catch (error) {
    yield put(getReviewDetailFail(error))
    yield put(updateReviewLoadingState(false))
  }
}

function* onUpdateReview({ payload: { review, reviewId, history } }) {
  try {
    yield put(updateReviewLoadingState(true))
    const response = yield call(updateReview, review, reviewId)
    yield put(updateReviewSuccess({ ...review, ...response.data.review }))
    toastr.success("Review updated successfully..!!")
    yield put(updateReviewLoadingState(false))
    history(-1)
  } catch (error) {
    yield put(updateReviewFail(error))
    toastr.error(error.response.data.message)
    yield put(updateReviewLoadingState(false))
  }
}

function* onDeleteReview({ payload }) {
  const { reviewId } = payload
  try {
    const response = yield call(deleteReview, reviewId)
    toastr.success("Review deleted successfully..!!")
    let res
    res = yield call(getReviews)
    // yield put(deleteReviewSuccess(response.data))
    yield put(getReviewsSuccess(res.data.reviews))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteReviewFail(error))
  }
}

function* onAddNewReview({ payload }) {
  const { review, history } = payload
  try {
    yield put(updateReviewLoadingState(true))
    const response = yield call(addNewReview, review)
    yield put(addReviewSuccess(response.data.review))
    yield put(updateReviewLoadingState(false))
    history(-1)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateReviewLoadingState(false))
    yield put(addReviewFail(error))
  }
}

function* reviewsSaga() {
  yield takeEvery(GET_REVIEWS, fetchReviews)
  yield takeEvery(GET_REVIEW_DETAIL, fetchReviewDetail)
  yield takeEvery(ADD_NEW_REVIEW, onAddNewReview)
  yield takeEvery(UPDATE_REVIEW, onUpdateReview)
  yield takeEvery(DELETE_REVIEW, onDeleteReview)
}

export default reviewsSaga
