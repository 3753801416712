import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import AppChart from "../../components/Common/AppChart/index"
import AppLoader from "../../components/Common/Loader"

const UserRolesPieChart = ({ dataseats, pieChartData }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Monthly Earning</CardTitle>
          {pieChartData ? (
            <AppChart chartType="pie" data={pieChartData} />
          ) : (
            <AppLoader />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default UserRolesPieChart
