import {
  GET_PLAN,
  GET_PLAN_FAIL,
  GET_PLAN_SUCCESS,
  GET_PLAN_DETAIL,
  ADD_NEW_PLAN,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAIL,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAIL,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAIL,
  GET_PLAN_DETAIL_FAIL,
  GET_PLAN_DETAIL_SUCCESS,
  UPDATE_PLAN_LOADING_STATE,
  RESET_PLAN,
  GET_COMPANY_PLAN_DETAIL,
  GET_COMPANY_PLAN_DETAIL_FAIL,
  GET_COMPANY_PLAN_DETAIL_SUCCESS,
  GET_MOCK_TEST_PRICE_DETAIL,
  GET_MOCK_TEST_PRICE_DETAIL_SUCCESS,
  GET_MOCK_TEST_PRICE_DETAIL_FAIL,
  UPDATE_MOCK_TEST_PRICE_DETAIL,
  UPDATE_MOCK_TEST_PRICE_DETAIL_SUCCESS,
  UPDATE_MOCK_TEST_PRICE_DETAIL_FAIL,
} from "./actionTypes"

export const getPlans = params => ({
  type: GET_PLAN,
  payload: params,
})

export const getPlansSuccess = plans => ({
  type: GET_PLAN_SUCCESS,
  payload: plans,
})

export const getPlansFail = error => ({
  type: GET_PLAN_FAIL,
  payload: error,
})

export const addNewPlan = (plan, history) => ({
  type: ADD_NEW_PLAN,
  payload: { plan, history },
})

export const addPlanSuccess = plan => ({
  type: ADD_PLAN_SUCCESS,
  payload: plan,
})

export const addPlanFail = error => ({
  type: ADD_PLAN_FAIL,
  payload: error,
})

export const updatePlan = (plan, params, history) => ({
  type: UPDATE_PLAN,
  payload: { plan, params, history },
})

export const updatePlanSuccess = plan => ({
  type: UPDATE_PLAN_SUCCESS,
  payload: plan,
})

export const updatePlanFail = error => ({
  type: UPDATE_PLAN_FAIL,
  payload: error,
})

export const deletePlan = planId => ({
  type: DELETE_PLAN,
  payload: planId,
})

export const deletePlanSuccess = planId => ({
  type: DELETE_PLAN_SUCCESS,
  payload: planId,
})

export const deletePlanFail = error => ({
  type: DELETE_PLAN_FAIL,
  payload: error,
})

export const getPlanDetail = planId => ({
  type: GET_PLAN_DETAIL,
  planId,
})

export const getPlanDetailSuccess = planDetails => ({
  type: GET_PLAN_DETAIL_SUCCESS,
  payload: planDetails,
})

export const getPlanDetailFail = error => ({
  type: GET_PLAN_DETAIL_FAIL,
  payload: error,
})

export const updatePlanLoadingState = status => ({
  type: UPDATE_PLAN_LOADING_STATE,
  payload: status,
})

export const resetPlans = () => ({
  type: RESET_PLAN,
})

export const getCompanyPlanDetail = companyId => ({
  type: GET_COMPANY_PLAN_DETAIL,
  companyId,
})

export const getCompanyPlanDetailSuccess = planDetails => ({
  type: GET_COMPANY_PLAN_DETAIL_SUCCESS,
  payload: planDetails,
})

export const getCompanyPlanDetailFail = error => ({
  type: GET_COMPANY_PLAN_DETAIL_FAIL,
  payload: error,
})

export const getMockTestPricDetail = () => ({
  type: GET_MOCK_TEST_PRICE_DETAIL,
})

export const getMockTestPricDetailSuccess = data => ({
  type: GET_MOCK_TEST_PRICE_DETAIL_SUCCESS,
  payload: data,
})

export const getMockTestPricDetailFail = error => ({
  type: GET_MOCK_TEST_PRICE_DETAIL_FAIL,
  payload: error,
})

export const updateMockTestPriceDetail = (
  mockTestPriceDetail,
  mockTestPriceId,
  history
) => ({
  type: UPDATE_MOCK_TEST_PRICE_DETAIL,
  payload: { mockTestPriceDetail, mockTestPriceId, history },
})

export const updateMockTestPriceDetailSuccess = mockTestPriceDetail => ({
  type: UPDATE_MOCK_TEST_PRICE_DETAIL_SUCCESS,
  payload: mockTestPriceDetail,
})

export const updateMockTestPriceDetailFail = error => ({
  type: UPDATE_MOCK_TEST_PRICE_DETAIL_FAIL,
  payload: error,
})
