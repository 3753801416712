import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_ADMIN_DASHBOARD_DATA,
  GET_COMPANY_DASHBOARD_DATA,
  GET_JOB_CHART_DATA,
  GET_TRANSACTION_CHART_DATA,
  GET_PROFILE_STATUS_DATA,
  GET_SIDEBAR,
  UPDATE_DASHBOARD_LOADING_STATE
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
})

export const getAdminDashboardData = () => ({
  type: GET_ADMIN_DASHBOARD_DATA,
})

export const getCompanyDashboardData = () => ({
  type: GET_COMPANY_DASHBOARD_DATA,
})

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
})

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
})

export const getJobChartsData = daterange => ({
  type: GET_JOB_CHART_DATA,
  daterange,
})

export const getTransactionChartsData = daterange => ({
  type: GET_TRANSACTION_CHART_DATA,
  daterange,
})

export const getProfileStatusChartsData = () => ({
  type: GET_PROFILE_STATUS_DATA,
})

export const getSideBarData = () => ({
  type: GET_SIDEBAR,
})

export const updateDashboardLoadingState = status => ({
  type: UPDATE_DASHBOARD_LOADING_STATE,
  payload: status,
})