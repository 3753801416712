import React, { useEffect } from "react"
import { Row, Col, CardBody, Button } from "reactstrap"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik, Formik, Form } from "formik"
import { renderField } from "./formField"
import { createvalidationSchemas } from "./validation"

function AppForm({
  fields,
  initialValues = {},
  handleFormSubmit,
  name,
  layoutType,
  showCancelButton = true,
  showDraftButton = false,
  handleSaveAsDraft,
  handleImage,
  getValues,
  addButton,
  cancelRoute = -1,
  saveTitle = "Update",
  flex = "center",
  navigateToCancelButton = true,
  handleCancel,
}) {
  const navigate = useNavigate()

  const validationSchema = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...initialValues },
    validationSchema: createvalidationSchemas(fields),
  })

  useEffect(() => {
    if (getValues) {
      getValues(validationSchema)
    }
  }, [getValues])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema.validationSchema}
    >
      {formik => (
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            console.log(validationSchema, "validationSchema")
            handleFormSubmit(validationSchema.values, validationSchema)
          }}
        >
          <CardBody>
            <Row className="d-flex justify-content-between">
              <Col>
                <h4 className="sun_heading mb-3">{name}</h4>
              </Col>
              <Col className="d-flex justify-content-end">
                {addButton && (
                  <Button
                    type="button"
                    color="primary global_btn"
                    onClick={addButton.onclick}
                    className="btn  me-3 px-4"
                  >
                    {addButton.title}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              {fields.map((field, key) => (
                <Col lg={field?.lg ? field.lg : 6} key={field.name + key}>
                  <div className="mb-4">
                    {renderField(field, formik, validationSchema, handleImage)}
                  </div>
                </Col>
              ))}
            </Row>
            {handleFormSubmit && (
              <div className="btn_outer_upsert pt-4 mt-3">
                <div
                  className={`justify-content-${flex} d-flex col-8 col-sm-12 col-md-12`}
                >
                  {showCancelButton && (
                    <Button
                      type="button"
                      color="primary global_btn"
                      onClick={() => {
                        if (navigateToCancelButton) {
                          navigate(cancelRoute)
                        } else {
                          handleCancel()
                        }
                      }}
                      className="btn  me-3 px-4"
                    >
                      <span className="mdi mdi-close-circle-outline me-2"></span>
                      Cancel
                    </Button>
                  )}
                  {showDraftButton && (
                    <Button
                      type="button"
                      color="primary global_btn"
                      onClick={() => {
                        handleSaveAsDraft(validationSchema)
                        navigate(-1)
                      }}
                      className="btn  me-3 px-4"
                    >
                      <i className="bx bx-save font-size-16 align-middle me-2"></i>
                      Save As Draft
                    </Button>
                  )}
                  <Button
                    type="submit"
                    color="primary"
                    className="global_btn btn btn-primary px-4"
                  >
                    <span className="mdi mdi-plus-circle-outline me-2"></span>
                    {layoutType === "CREATE" ? "Create" : saveTitle}
                  </Button>
                </div>
              </div>
            )}
          </CardBody>
        </Form>
      )}
    </Formik>
  )
}

export default AppForm
