import React from "react"
import AppInput from "../AppInput"
import AppSelect from "../AppSelect"
import AppCheckboxGroup from "../AppCheckBoxGroup"
import MultiSelect from "../AppSelect/MultiSelect"
import AppJoditEditor from "../AppJoditEditor"
import AppImageUpload from "../AppImageUpload"
import AppInputUrl from "../AppInputUrl"
import AppDate from "../AppDate"
import AppTIme from "../AppTIme"
import AppCityStateSelect from "../AppSelect/CityStateSelect"
import AppSearch from "../AppSearch/index"
import AppTextArea from "../AppTextArea/index"

export const renderField = (field, formik, validationSchema, handleImage) => {

  if (field.conditional) {
    const showField = validationSchema.values[field.conditional.field] === field.conditional.value;
    if (!showField) return null;
  }
  
  switch (field.type) {
    case "text":
      return (
        <>
          <AppInput
            name={field.name}
            label={field.label}
            type={field.type}
            className="form-control custom_form_control"
            id={`formrow-${field.name}-Input`}
            placeholder={field.placeholder}
            onChange={validationSchema.handleChange}
            onBlur={validationSchema.handleBlur}
            value={validationSchema.values[field.name] || ""}
            disabled={field?.disabled}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
            error={validationSchema.errors[field.name]}
          />
        </>
      )
    case "number":
      return (
        <>
          <AppInput
            name={field.name}
            label={field.label}
            type={field.type}
            className="form-control custom_form_control"
            id={`formrow-${field.name}-Input`}
            placeholder={field.placeholder}
            onChange={validationSchema.handleChange}
            onBlur={validationSchema.handleBlur}
            value={validationSchema.values[field.name] || ""}
            disabled={field?.disabled}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
            error={validationSchema.errors[field.name]}
          />
        </>
      )
    case "textarea":
      return (
        <AppTextArea
          name={field.name}
          label={field.label}
          type={field.type}
          className="form-control custom_form_control"
          id={`formrow-${field.name}-Input`}
          placeholder={field.placeholder}
          onChange={validationSchema.handleChange}
          onBlur={validationSchema.handleBlur}
          value={validationSchema.values[field.name] || ""}
          disabled={field?.disabled}
          invalid={
            validationSchema.touched[field.name] &&
            validationSchema.errors[field.name]
          }
          error={validationSchema.errors[field.name]}
          height={field?.height ? field?.height : 200}
        />
      )
    case "select":
      return (
        <>
          <AppSelect
            name={field.name}
            label={field.label}
            id={`formrow-${field.name}-Input`}
            onChange={validationSchema.handleChange}
            onBlur={validationSchema.handleBlur}
            value={validationSchema.values[field.name] || ""}
            className={`form-select custom_form_control ${
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
                ? "is-invalid"
                : ""
            }`}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
            options={field.options}
            isExternal={field.isExternal ? field.isExternal : false}
            slug={field.slug}
            error={validationSchema.errors[field.name]}
            field={field}
          />
        </>
      )
      break
    case "cityState":
      return (
        <>
          <AppCityStateSelect
            formik={formik}
            field={field}
            validationSchema={validationSchema}
          />
        </>
      )
      break
    case "textEditor":
      return (
        <>
          <AppJoditEditor
            name={field.name}
            label={field.label}
            value={validationSchema.values[field.name] || ""}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
            error={validationSchema.errors[field.name]}
            onChange={validationSchema.setFieldValue}
            onBlur={validationSchema.handleBlur}
            isForm={true}
          />
        </>
      )
    case "checkBoxes":
      return (
        <>
          <AppCheckboxGroup
            name={field.name}
            label={field.label}
            options={field.checkBoxes}
            value={validationSchema.values[field.name] || ""}
            onChange={selected => {
              validationSchema.setFieldValue(field.name, selected)
              validationSchema.setFieldError(field.name, "")
            }}
            error={validationSchema.errors[field.name]}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
          />
        </>
      )
    case "multiSelect":
      return (
        <>
          <MultiSelect
            name={field.name}
            label={field.label}
            options={field.options}
            selectedOptions={validationSchema.values[field.name] || []}
            onChange={selected => {
              validationSchema.setFieldValue(field.name, selected)
              validationSchema.setFieldError({ [field.name]: "" })
            }}
            error={validationSchema.errors[field.name]}
            onBlur={validationSchema.handleBlur}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
          />
        </>
      )
    case "file":
      return (
        <>
          {handleImage && (
            <AppImageUpload
              name={field.name}
              label={field.label}
              onChange={file => {
                validationSchema.setFieldValue(field.name, file)
                validationSchema.setFieldError({ [field.name]: "" })
                handleImage(file[0])
              }}
              value={validationSchema.values[field.name] || ""}
              error={validationSchema.errors[field.name]}
              onBlur={validationSchema.handleBlur}
              invalid={
                validationSchema.touched[field.name] &&
                validationSchema.errors[field.name]
              }
            />
          )}
        </>
      )
    case "url":
      return (
        <>
          <AppInputUrl
            name={field.name}
            label={field.label}
            type={field.type}
            className="form-control custom_form_control"
            id={`formrow-${field.name}-Input`}
            placeholder={field.placeholder}
            onChange={validationSchema.handleChange}
            onBlur={validationSchema.handleBlur}
            value={validationSchema.values[field.name] || ""}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
            error={validationSchema.errors[field.name]}
          />
        </>
      )
    case "date":
      return (
        <>
          <AppDate
            name={field.name}
            label={field.label}
            type={field.type}
            className="form-control custom_form_control"
            id={`formrow-${field.name}-Input`}
            placeholder={field.placeholder}
            onChange={validationSchema.handleChange}
            onBlur={validationSchema.handleBlur}
            value={validationSchema.values[field.name] || ""}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
            error={validationSchema.errors[field.name]}
          />
        </>
      )
    case "time":
      return (
        <>
          <AppTIme
            name={field.name}
            label={field.label}
            type={field.type}
            className="form-control custom_form_control"
            id={`formrow-${field.name}-Input`}
            placeholder={field.placeholder}
            onChange={validationSchema.handleChange}
            onBlur={validationSchema.handleBlur}
            value={validationSchema.values[field.name] || ""}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
            error={validationSchema.errors[field.name]}
          />
        </>
      )
    case "search":
      return (
        <>
          <AppSearch
            name={field.name}
            id={`formrow-${field.name}-Input`}
            label={field.label}
            options={field.options}
            onChange={validationSchema.handleChange}
            formik={validationSchema}
            onBlur={validationSchema.handleBlur}
            error={validationSchema.errors[field.name]}
          />
        </>
      )
    case "multiFields":
      return formLayout(field, formik, validationSchema, handleImage)
    case "switchSelect":
      return (
        <>
          <AppSelect
            key={field.name}
            label={field.label}
            name={field.name}
            id="status1"
            className="form-select custom_form_control"
            onChange={validationSchema.handleChange}
            onBlur={validationSchema.handleBlur}
            value={validationSchema.values[field.name]}
            options={field.options}
            invalid={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
            error={
              validationSchema.touched[field.name] &&
              validationSchema.errors[field.name]
            }
          />
          {renderSwitchedFields(field, formik, validationSchema, handleImage)}
        </>
      )
    default:
      return <p className="text-danger">invalid field type</p>
      break
  }
}

const formLayout = (field, formik, validationSchema, handleImage) => {
  return field.fields.map((mField, key) => {
    return (
      <div key={key}>
        {renderField(mField, formik, validationSchema, handleImage)}
      </div>
    )
  })
}

const renderSwitchedFields = (field, formik, validationSchema, handleImage) => {
  const selectedType = formik.values[field.name]

  if (selectedType === "course") {
    return field.fields
      .filter(mField => mField.name === "course")
      .map((mField, key) => (
        <div key={key}>
          {renderField(mField, formik, validationSchema, handleImage)}
        </div>
      ))
  } else if (selectedType === "test") {
    return field.fields
      .filter(mField => mField.name === "test")
      .map((mField, key) => (
        <div key={key}>
          {renderField(mField, formik, validationSchema, handleImage)}
        </div>
      ))
  } else if (selectedType === "subscription") {
    return field.fields
      .filter(mField => mField.name === "subscription")
      .map((mField, key) => (
        <div key={key}>
          {renderField(mField, formik, validationSchema, handleImage)}
        </div>
      ))
  }

  return null
}
