export const columns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "User Profile",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Name",
    name: "userName",
    key: "DEFAULT",
    link: "/student-details/tab8",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Product Category",
    name: "purpose",
    key: "DEFAULT",
  },
  {
    header: "Product Name",
    name: "productname",
    key: "DEFAULT",
  },
  {
    header: "Transaction Id",
    name: "transactionId",
    key: "DEFAULT",
  },
  {
    header: "Transaction Type",
    name: "transactionType",
    key: "DEFAULT",
  },
  {
    header: "Invoice Number",
    name: "invoiceNumber",
    key: "DEFAULT",
  },
  {
    header: "DATE",
    name: "createdAt",
    key: "DATE",
  },
  {
    header: "Amount",
    name: "amount",
    key: "AMOUNT",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
]

export const formFields = (students, tests, courses, subscriptions) => {
  return [
    {
      label: "Search User Name",
      name: "user",
      type: "search",
      required: true,
    },
    // {
    //   label: "User Name",
    //   name: "user",
    //   type: "select",
    //   options:
    //     students && students.length
    //       ? [{ value: "", label: "Choose Students" },...students.map(student => ({
    //           value: student._id,
    //           label: `Name : ${student.name} | Email: ${student.email}`,
    //         }))]
    //       : [],
    //   required: true,
    // },
    {
      name: "type",
      label: "Type",
      type: "switchSelect",
      options: [
        { value: "", label: "Choose Type" },
        { value: "course", label: "Course" },
        { value: "test", label: "Test" },
        { value: "subscription", label: "Subscription" },
      ],
      fields: [
        {
          label: "courses List",
          name: "course",
          type: "select",
          options:
            courses && courses.length
              ? [
                  { value: "", label: "Choose Course" },
                  ...courses.map(course => ({
                    value: course._id,
                    label: course.courseName,
                  })),
                ]
              : [],
          required: true,
        },
        {
          label: "Test List",
          name: "test",
          type: "select",
          options:
            tests && tests.length
              ? [
                  { value: "", label: "Choose Test" },
                  ...tests.map(test => ({ value: test._id, label: test.name })),
                ]
              : [],
          required: true,
        },
        {
          label: "Subscription List",
          name: "subscription",
          type: "select",
          options:
            subscriptions && subscriptions.length
              ? [
                  { value: "", label: "Choose Subscription" },
                  ...subscriptions.map(subscription => ({
                    value: subscription._id,
                    label: `${subscription.name} (${subscription.role})`,
                  })),
                ]
              : [],
          required: true,
        },
      ],
      required: true,
    },
  ]
}
