import {
  GET_HELP_CENTER,
  GET_HELP_CENTER_SUCCESS,
  GET_HELP_CENTER_FAIL,
  RESET_HELP_CENTER,
  ADD_NEW_HELP_CENTER,
  ADD_HELP_CENTER_SUCCESS,
  ADD_HELP_CENTER_FAIL,
  UPDATE_HELP_CENTER_LOADING_STATE
} from "./actionTypes"

export const getHelpCenter = params => ({
  type: GET_HELP_CENTER,
  params,
})

export const getHelpCenterSuccess = helpCenter => ({
  type: GET_HELP_CENTER_SUCCESS,
  payload: helpCenter,
})

export const getHelpCenterFail = error => ({
  type: GET_HELP_CENTER_FAIL,
  payload: error,
})

export const addNewHelpCenter = (helpCenter) => ({
  type: ADD_NEW_HELP_CENTER,
  payload: { helpCenter },
})

export const addHelpCenterSuccess = helpCenter => ({
  type: ADD_HELP_CENTER_SUCCESS,
  payload: helpCenter,
})

export const addHelpCenterFail = error => ({
  type: ADD_HELP_CENTER_FAIL,
  payload: error,
})

export const resetHelpCenter = () => ({
  type: RESET_HELP_CENTER,
})

export const updateHelpCenterLoadingState = status => ({
  type: UPDATE_HELP_CENTER_LOADING_STATE,
  payload: status,
})
