/* SKILLS */
export const GET_SKILLS = "GET_SKILLS"
export const GET_SKILLS_SUCCESS = "GET_SKILLS_SUCCESS"
export const GET_SKILLS_FAIL = "GET_SKILLS_FAIL"

/* SKILLS */
export const GET_USER_SKILLS = "GET_USER_SKILLS"
export const GET_USER_SKILLS_SUCCESS = "GET_USER_SKILLS_SUCCESS"
export const GET_USER_SKILLS_FAIL = "GET_USER_SKILLS_FAIL"

/* SKILLS DETAIL*/
export const GET_SKILL_DETAIL = "GET_SKILL_DETAIL"
export const GET_SKILL_DETAIL_SUCCESS = "GET_SKILL_DETAIL_SUCCESS"
export const GET_SKILL_DETAIL_FAIL = "GET_SKILL_DETAIL_FAIL"

/**
 * add test
 */
export const ADD_NEW_SKILL = "ADD_NEW_SKILL"
export const ADD_SKILL_SUCCESS = "ADD_SKILL_SUCCESS"
export const ADD_SKILL_FAIL = "ADD_SKILL_FAIL"

/**
 * Edit test
 */
export const UPDATE_SKILL = "UPDATE_SKILL"
export const UPDATE_SKILL_SUCCESS = "UPDATE_SKILL_SUCCESS"
export const UPDATE_SKILL_FAIL = "UPDATE_SKILL_FAIL"

/**
 * Delete test
 */
export const DELETE_SKILL = "DELETE_SKILL"
export const DELETE_SKILL_SUCCESS = "DELETE_SKILL_SUCCESS"
export const DELETE_SKILL_FAIL = "DELETE_SKILL_FAIL"

export const UPDATE_SKILL_LOADING_STATE = "UPDATE_SKILL_LOADING_STATE"
