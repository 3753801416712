import {
  commonFormFields,
  studentSettings,
  companySettings,
  commonFields,
  studentFields,
  companyFields,
  actions,
} from "./constants"

import {
  addNewPackage as onAddNewPackage,
  updatePackage as onUpdatePackage,
} from "../../store/actions"

export const getPackageForm = role => {
  switch (role) {
    case "student":
      return [...commonFormFields, ...studentSettings]

    case "company":
      return [...commonFormFields, ...companySettings]

    default:
      return "Invalid Role"
  }
}

export const getPackageColumn = role => {
  switch (role) {
    case "student":
      return [...commonFields, ...studentFields, ...actions]

    case "company":
      return [...commonFields, ...companyFields, ...actions]

    default:
      return "Invalid Role"
  }
}

export const handleSubmit = (
  formikValues,
  formik,
  isEdit,
  params,
  dispatch,
  navigate
) => {
  formik.validateForm().then(errors => {
    if (Object.keys(errors).length === 0) {
      let values;
      if (formikValues.leads) {
        // If leads field is selected, update leads count
        const updatedLeads = ['mockTestCredit', 'testCredit', 'courseCredit', 'profileCredit', 'jobCredit', 'addUserCredit']
          .reduce((acc, lead) => {
            acc[lead] = lead === formikValues.leads ? formikValues.limit : 0;
            return acc;
          }, {});

        values = {
          ...formikValues,
          packageType: "single",
          ...updatedLeads
        };
      } else {
        // If leads field is not selected, proceed without updating leads count
        values = {
          ...formikValues,
          packageType: "single",
        };
      }

      if (isEdit) {
        dispatch(onUpdatePackage({ ...values }, params, navigate));
      } else {
        dispatch(onAddNewPackage({ ...values, role: params.role }, navigate));
      }
    } else {
      const touchedFields = {};
      Object.keys(errors).forEach(field => {
        touchedFields[field] = true;
      });
      formik.setTouched(touchedFields);
    }
  });
};
