import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate, useParams, useLocation } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Card,
  CardBody,
  Button,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AppLoader from "../../components/Common/Loader/index"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import "react-datepicker/dist/react-datepicker.css"
import {
  getCourseDetail as onGetCourseDetail,
  addNewCourse as onAddNewCourse,
  addNewChapter as onAddNewChapter,
  updateCourse as onUpdateCourse,
  deleteChapter as onDeleteChapter,
  getTestCategories as onGetTestCategories,
  addNewTest as onAddNewTest,
  resetCourse as onResetCourse,
  resetTests as onResetTests,
  addChapterContent as onAddChapterContent,
  updateChapterContent as onUpdateChapterContent,
} from "../../store/actions"
import { getTestsDetails } from "../../helpers/api/testApi"
import withRouter from "components/Common/withRouter"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import AppJoditEditor from "../../components/Common/AppJoditEditor"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import AppTabs from "../../components/Common/Tabs/index"
import AppForm from "../../components/Common/Form/index"
import CertificateTest from "./CertificateTest"
import MultiMedia from "../MediaManagement/Media"
import { discountOptions } from "./constants"

const Courses = props => {
  const staticURL = process.env.REACT_APP_STATIC_URL
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const [isEdit, setIsEdit] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [deleteChapterId, setDeleteChapterId] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const fileInputRef = useRef(null)
  const chapterHtmlFormik = useRef(null)
  const chapterVideoFormik = useRef(null)
  const [chapterModal, setChapterModal] = useState(false)
  const [mediaModal, setMediaModal] = useState(false)
  const [chapterContent, setChapterContent] = useState({
    htmlContent: "",
    videoUrl: "",
    pdfContent: "",
  })

  const modalAction = () => {
    dispatch(onDeleteChapter(deleteChapterId, courseDetail?._id, navigate))
    setTimeout(() => setmodal_backdrop(false), 2000)
  }
  document.title = `${isEdit ? "Edit Course" : "Create New Course"} | QAPRENEUR`

  const { error, testCategories, loading, courseDetail } = useSelector(
    state => ({
      error: state?.courseCategories?.error?.response?.data?.message,
      loading: state.courses.loading,
      courseDetail: state.courses?.courseDetail,
      testCategories: state.testCategories.testCategories,
    })
  )
  useEffect(() => {
    const currentLocation = location.pathname
    const status = currentLocation.includes("courses-edit")
    setIsEdit(status)
    dispatch(onGetTestCategories())
    if (status && params?.id) {
      dispatch(onGetCourseDetail(params.id))
    } else {
      setIsLoading(false)
    }
    return () => {
      dispatch(onResetCourse())
      dispatch(onResetTests())
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(courseDetail).length) {
      setIsLoading(false)
    }
  }, [courseDetail])

  const chapterModalToggle = () => setChapterModal(!chapterModal)

  function handleAcceptedFiles(files) {
    validation.setFieldValue("image", files[0])
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      courseName: courseDetail?.courseName || "",
      courseDuration: courseDetail?.courseDuration || "0",
      description: courseDetail?.description || "",
      image: courseDetail?.image || "",
      status:
        isEdit && courseDetail?.status ? courseDetail?.status : "deactive",
      type: courseDetail?.type || "",
      price: courseDetail?.type === "paid" ? courseDetail?.price || "" : 0,
      isDiscount: [true, "true"].includes(courseDetail?.isDiscount)
        ? "true"
        : "false",
      discount:
        courseDetail?.type === "paid" &&
        [true, "true"].includes(courseDetail?.isDiscount)
          ? courseDetail?.discount
          : 0,
    },
    validationSchema: Yup.object({
      courseName: Yup.string().required("Please Enter Course Name"),
      status: Yup.mixed()
        .oneOf(["active", "deactive"])
        .required("Please Select Course Status"),
      // courseDuration: Yup.number().required("Please Select Course Duration"),
      type: Yup.mixed()
        .oneOf(["free", "paid", "premium"])
        .required("Please Select Course Type"),
      price: Yup.string()
        .required("Please Enter Course Price")
        .test("positive", "Price must be a non-negative value", value => {
          const parsedValue = parseFloat(value)
          return !isNaN(parsedValue) && parsedValue >= 0
        }),
    }),
    onSubmit: values => {
      if (isEdit) {
        // values.price = values.type === "free" ? 0 : values.price
        if (["free", "premium"].includes(values.type)) {
          values.isDiscount = false
          values.price = 0
          values.discount = 0
          values.discountPrice = 0
        } else if (
          values.type === "paid" &&
          [false, "false"].includes(values.isDiscount)
        ) {
          values.discountPrice = 0
          values.price = values.price
          values.discount = 0
          values.isDiscount = false
        } else {
          values.isDiscount = true
          values.price = values.price
          values.discount = values.discount
          values.discountPrice = Math.round(
            values.price - values.price * (Number(values.discount) / 100)
          )
        }
        dispatch(onUpdateCourse(values, courseDetail._id, navigate))
      } else {
        const modifyVal = {
          ...values,
          isDiscount: Boolean(values?.isDiscount),
          discount: Number(values?.discount),
          discountPrice: Math.round(
            values.price - values.price * (Number(values.discount) / 100)
          ),
        }
        dispatch(onAddNewCourse(modifyVal, navigate))
      }
    },
  })

  const chapterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      document: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Module Name"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (!courseDetail?._id) {
        validation.handleSubmit()
        return
      }
      dispatch(onAddNewChapter(values, courseDetail?._id, navigate))
      resetForm()
    },
  })

  // const { setFieldValue } = chapterValidation

  const handleButtonClick = (e, type, { handleSubmit, setFieldValue }) => {
    e.preventDefault()
    if (type === "draft") setFieldValue("status", "deactive")
    handleSubmit()
    return false
  }

  const updateTest = async chapter => {
    if (chapter.tests && chapter.tests.length === 0) {
      const values = {
        name: "chapter test" + Math.floor(Math.random() * 100000),
        chapterId: chapter?._id,
        courseId: courseDetail?._id,
        role: "chapter",
        duration: 30,
        description: "this is course chapter test",
        image: "",
        passingMark: 50,
        status: "active",
        type: "free",
        price: 0,
        testCategoryId: testCategories.length ? testCategories[0] : {},
      }
      dispatch(onAddNewTest(values, navigate, "chapter"))
    } else {
      const response = await getTestsDetails(chapter?.tests[0]._id)
      if (response?.data?.data?.questions?.length > 0) {
        const qNo = response?.data?.data?.questions?.length + 1
        navigate(`/questions-create/chapter/${chapter.tests[0]._id}/${qNo}`)
      } else {
        navigate(`/questions-create/chapter/${chapter.tests[0]._id}/1`)
      }
    }
  }

  const viewTest = chapter => {
    if (chapter.tests && chapter.tests.length > 0) {
      navigate(`/questions-view/chapters/${chapter.tests[0]._id}`)
    }
  }

  const handleDeleteModule = chapterId => {
    setDeleteChapterId(chapterId)
    setmodal_backdrop(true)
  }

  const handleEditModule = chapter => {
    navigate(`/courses/chapter-edit/${chapter?._id}`)
  }

  // const converFileName = (filePathJson) => {
  //   const filePathArray = filePathJson && JSON.parse(filePathJson);
  //   const fileName = Array.isArray(filePathArray) && filePathArray.length && filePathArray.map((filePath) =>
  //     filePath.substring(filePath.lastIndexOf("/") + 1)
  //   );
  //   return fileName
  // }

  const handleContentFormSave = () => {
    const contentData = {
      ...chapterContent,
      ...chapterHtmlFormik?.current?.values,
      ...chapterVideoFormik?.current?.values,
    }
    dispatch(
      onAddChapterContent(
        {
          ...contentData,
          pdfContent: contentData?.pdfContent ? contentData?.pdfContent : "",
        },
        navigate
      )
    )
    chapterModalToggle()
  }

  const getSubscribValueFromToFormik = formik => {
    if (Object.keys(formik.values).includes("htmlContent")) {
      chapterHtmlFormik.current = formik
    }
    if (Object.keys(formik.values).includes("videoUrl")) {
      chapterVideoFormik.current = formik
    }
  }

  const mediaModalToggle = () => setMediaModal(!mediaModal)

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title="Courses"
          navigate="/courses-list"
          breadcrumbItem={`${isEdit ? "Edit Course" : "Create Course"}`}
        />
        <Container fluid>
          {isLoading ? (
            <AppLoader />
          ) : (
            <>
              <Form>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label htmlFor="formrow-name-Input">
                                Course name
                                <span className="required_star">*</span>
                              </Label>
                              <Input
                                name="courseName"
                                type="text"
                                className="form-control custom_form_control"
                                id="formrow-name-Input"
                                placeholder="Enter Course Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.courseName || ""}
                                invalid={
                                  validation.touched.courseName &&
                                  validation.errors.courseName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.courseName &&
                              validation.errors.courseName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.courseName}
                                </FormFeedback>
                              ) : null}
                            </div>
                            {/* <div>
                              <Label>
                                Duration( minutes)
                                <span className="required_star">*</span>
                              </Label>
                              <Input
                                name="courseDuration"
                                id="status1"
                                type="select"
                                className="form-select custom_form_control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.courseDuration || ""}
                                invalid={
                                  validation.touched.courseDuration &&
                                    validation.errors.courseDuration
                                    ? true
                                    : false
                                }
                              >
                                <option value="">Choose </option>
                                <option value="30">30</option>
                                <option value="45">45</option>
                                <option value="60">60</option>
                                <option value="90">90</option>
                                <option value="120">120</option>
                              </Input>
                              {validation.touched.courseDuration &&
                                validation.errors.courseDuration ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.courseDuration}
                                </FormFeedback>
                              ) : null}
                            </div> */}
                            <div className="mb-3 mt-3">
                              <Label
                                htmlFor="status-input"
                                className="form-label"
                              >
                                Status
                              </Label>
                              <Input
                                name="status"
                                id="status-input"
                                type="select"
                                className="form-select custom_form_control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status || ""}
                                invalid={
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? true
                                    : false
                                }
                              >
                                <option>Choose Status</option>
                                <option value="active">Active</option>
                                <option value="deactive">Deactive</option>
                              </Input>
                              {validation.touched.status &&
                              validation.errors.status ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.status}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <label className="control-label">
                                Upload Image
                              </label>
                              <Dropzone
                                maxFiles={1}
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(acceptedFiles)
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick mt-2"
                                      {...getRootProps()}
                                    >
                                      <input
                                        name="image"
                                        {...getInputProps()}
                                      />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {selectedFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )
                                })}

                                {courseDetail?.image &&
                                  selectedFiles.length == 0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              src={
                                                staticURL + courseDetail.image
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <label className="control-label">Description</label>
                            {/* <CKEditor
                              editor={ClassicEditor}
                              data={validation.values.description}
                              onReady={editor => { }}
                              onChange={(event, editor) => {
                                const data = editor.getData()
                                validation.setFieldValue("description", data)
                              }}
                            /> */}
                            <AppJoditEditor
                              name="description"
                              value={validation.values.description}
                              onChange={(name, data) => {
                                validation.setFieldValue("description", data)
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3 mt-3">
                              <Label
                                htmlFor="status-input"
                                className="form-label"
                              >
                                Select Course Type
                              </Label>
                              <Input
                                name="type"
                                id="status-input"
                                type="select"
                                className="form-select custom_form_control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.type || ""}
                                invalid={
                                  validation.touched.type &&
                                  validation.errors.type
                                    ? true
                                    : false
                                }
                              >
                                <option>Select Course Type</option>
                                <option value="free">Free</option>
                                <option value="paid">Paid</option>
                                <option value="premium">Premium</option>
                              </Input>
                              {validation.touched.type &&
                              validation.errors.type ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.type}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {validation.values.type === "paid" ? (
                            <Col lg="6">
                              <div className="mb-3 mt-3">
                                <Label htmlFor="formrow-passingMark-Input">
                                  Course price
                                  <span className="required_star">*</span>
                                </Label>
                                <Input
                                  name="price"
                                  type="Number"
                                  className="form-control custom_form_control"
                                  id="formrow-passingMark-Input"
                                  placeholder="Enter course Price"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.price || ""}
                                  invalid={
                                    validation.touched.price &&
                                    validation.errors.price
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.price &&
                                validation.errors.price ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.price}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                        <Row>
                          {validation.values.type === "paid" ? (
                            <Col lg="6">
                              <div className="mb-3 mt-3">
                                <Label
                                  htmlFor="status-input"
                                  className="form-label"
                                >
                                  Give Discount
                                </Label>
                                <Input
                                  name="isDiscount"
                                  id="status-input"
                                  type="select"
                                  className="form-select custom_form_control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.isDiscount || ""}
                                  invalid={
                                    validation.touched.isDiscount &&
                                    validation.errors.isDiscount
                                      ? true
                                      : false
                                  }
                                >
                                  <option>Choose Test Discount</option>
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </Input>
                                {validation.touched.isDiscount &&
                                validation.errors.isDiscount ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.isDiscount}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {[true, "true"].includes(
                            validation.values.isDiscount
                          ) && validation.values.type === "paid" ? (
                            <Col lg="6">
                              <div className="mb-3 mt-3">
                                <Label
                                  htmlFor="status-input"
                                  className="form-label"
                                >
                                  Discount (%)
                                </Label>
                                <Input
                                  name="discount"
                                  id="status-input"
                                  type="select"
                                  className="form-select custom_form_control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.discount || ""}
                                  invalid={
                                    validation.touched.discount &&
                                    validation.errors.discount
                                      ? true
                                      : false
                                  }
                                >
                                  <option>Choose Discount</option>
                                  {discountOptions &&
                                    discountOptions.map((DiOp, k) => {
                                      return (
                                        <option value={DiOp.value} key={k}>
                                          {DiOp.label}
                                        </option>
                                      )
                                    })}
                                </Input>
                                <h6 className="text-dark font-weight-bold mt-2">
                                  Discount Price :{" "}
                                  {validation.values.price -
                                    validation.values.price *
                                      (Number(validation.values.discount) /
                                        100)}
                                </h6>
                                {validation.touched.isDiscount &&
                                validation.errors.isDiscount ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.isDiscount}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
              {isEdit ? (
                <Card className="add_module">
                  <CardBody className="add_module">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h3 className="mb-0">Add Module</h3>
                    </div>
                    {courseDetail?.chapters?.map((chapter, index) => {
                      return (
                        <div
                          key={index}
                          className="accordion"
                          id="accordionExample"
                        >
                          <div className="accordion-item mb-3">
                            <div className="accordion-header">
                              <div
                                className="accordion-button position-relative"
                                type="button"
                                data-bs-target={`#collapseExample${index}`}
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                              >
                                <span>Module {index + 1}</span>
                                <Button
                                  onClick={() =>
                                    handleDeleteModule(chapter._id)
                                  }
                                  type="button"
                                  color="btn btn-outline-danger acc_header_btn"
                                  className="me-3 d-flex position-absolute py-1"
                                >
                                  <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                                  Delete
                                </Button>
                              </div>
                            </div>
                            <div
                              id={`collapseExample${index}`}
                              className="accordion-collapse collapse show"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label htmlFor="name">
                                        Module Name : {chapter.name}
                                        {/* <span className="required_star">*</span> */}
                                      </Label>
                                      {/* <Input
                                          name="name"
                                          type="text"
                                          className="form-control custom_form_control"
                                          id="name"
                                          placeholder="Enter Module Name"
                                          value={chapter.name}
                                          // value={chapterDetails && chapterDetails._id ? chapterDetails.name:chapter.name}
                                          disabled={chapter._id !== deleteChapterId}
                                        /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      {/* <Label htmlFor="document" className="invisible">
                                          ""
                                        </Label>
                                        <Input
                                          type="file"
                                          className="form-control custom_input custom_form_control"
                                          id="document"
                                          name="document"
                                          placeholder="Enter Course Name"
                                          multiple
                                          disabled={chapter._id !== deleteChapterId}
                                        /> */}
                                      <div>
                                        {/* {converFileName(chapter.document).length ? converFileName(chapter.document).map((fileName, index) => (
                                            <div key={index} className="d-flex align-items-center mt-2">
                                              <div >{fileName}</div>
                                              <Link
                                                className="bg-danger ms-2 p-1"
                                                style={{ fontSize: "12px", color: "#fff", borderRadius: "5px" }}
                                                to={chapter.document.length && Array.isArray(JSON.parse(chapter.document)) ? staticURL + JSON.parse(chapter.document)[0] : ""}
                                                target="_blank" rel="noopener noreferrer">
                                                <i className="bx bx-file font-size-16 align-middle me-1"></i>
                                                View PDF
                                              </Link>
                                            </div>
                                          )) : <div className="d-flex align-items-center mt-2">No File Selected</div>} */}
                                      </div>
                                    </div>
                                  </div>
                                  {/* removed pdf */}
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="d-flex">
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="me-3 global_btn"
                                        onClick={() => updateTest(chapter)}
                                      >
                                        <i className="bx bx-plus-circle font-size-16 align-middle me-1"></i>
                                        Add Test
                                      </Button>
                                      {!chapter?.chapterContents.length ? (
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="me-3 global_btn"
                                          onClick={() => {
                                            chapterModalToggle()
                                            setChapterContent({
                                              ...chapterContent,
                                              chapterId: chapter?._id,
                                              courseId: params?.id,
                                            })
                                          }}
                                        >
                                          <i className="bx bx-plus-circle font-size-16 align-middle me-1"></i>
                                          Add Content
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="me-3 global_btn"
                                          onClick={() =>
                                            navigate(
                                              `/courses/${params.id}/chapter/${chapter?._id}/content`
                                            )
                                          }
                                        >
                                          <i className="bx bx-show-alt font-size-16 align-middle me-1"></i>
                                          View Content
                                        </Button>
                                      )}

                                      <Button
                                        type="button"
                                        color="primary"
                                        className="me-3 global_btn"
                                        onClick={() =>
                                          navigate(
                                            `/courses/${params.id}/chapter/${chapter?._id}/flash-card`
                                          )
                                        }
                                      >
                                        <i className="bx bx-plus-circle font-size-16 align-middle me-1"></i>
                                        Add Flash Card
                                      </Button>
                                      {chapter.tests.length > 0 ? (
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="global_btn"
                                          onClick={() => viewTest(chapter)}
                                        >
                                          <i className="bx bx-show-alt font-size-16 align-middle me-1"></i>
                                          View Test
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                      <Button
                                        onClick={() =>
                                          handleEditModule(chapter)
                                        }
                                        type="button"
                                        color="primary"
                                        className="global_btn ms-3"
                                      >
                                        <i className="bx bx-pencil font-size-16 align-middle me-1"></i>
                                        Edit
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}

                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        chapterValidation.handleSubmit()
                        if (fileInputRef.current) {
                          fileInputRef.current.value = ""
                        }
                        return false
                      }}
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item mb-3">
                          <div className="accordion-header">
                            <div
                              className="accordion-button position-relative"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseExample"
                              aria-expanded="true"
                            >
                              <span>
                                Module {courseDetail?.chapters?.length + 1}
                              </span>
                            </div>
                          </div>
                          <div
                            id="collapseExample"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <Label htmlFor="name">
                                      Module name
                                      <span className="required_star">*</span>
                                    </Label>
                                    <Input
                                      name="name"
                                      type="text"
                                      className="form-control custom_form_control"
                                      id="name"
                                      placeholder="Enter Module Name"
                                      onChange={chapterValidation.handleChange}
                                      onBlur={chapterValidation.handleBlur}
                                      value={
                                        chapterValidation.values.name || ""
                                      }
                                      invalid={
                                        chapterValidation.touched.name &&
                                        chapterValidation.errors.name
                                          ? true
                                          : false
                                      }
                                    />
                                    {chapterValidation.touched.name &&
                                    chapterValidation.errors.name ? (
                                      <FormFeedback type="invalid">
                                        {chapterValidation.errors.name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                                {/* removed pdf */}

                                {/* <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label htmlFor="document" className="invisible">
                                        ""
                                      </Label>
                                      <input
                                        name="document"
                                        type="file"
                                        className="form-control custom_input custom_form_control"
                                        id="document"
                                        placeholder="Enter Course Name"
                                        multiple
                                        ref={fileInputRef}
                                        onChange={event => {
                                          const files = Array.from(event.target.files)
                                          setFieldValue("document", files)
                                        }}
                                      />
                                    </div>
                                  </div> */}
                                {/* removed pdf */}
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="d-flex">
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="me-3 global_btn"
                                    >
                                      <i className="bx bx-plus-circle font-size-16 align-middle me-1"></i>
                                      Save Chapter
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              ) : (
                ""
              )}
              {chapterModal && (
                <InfomationModal
                  modalBackdrop={chapterModal}
                  setmodalBackdrop={setChapterModal}
                  title={"Add Chapter Content"}
                  size="lg"
                  height={400}
                  isFooter={true}
                  handleAction={handleContentFormSave}
                >
                  <Row>
                    <b>Content Type</b>
                    <AppTabs
                      tabsArray={[
                        {
                          id: "tab1",
                          label: "HTML",
                          content: (
                            <Col sm="12">
                              <AppForm
                                name=""
                                layoutType={"CREATE"}
                                fields={[
                                  {
                                    label: "Chapter Content",
                                    name: "htmlContent",
                                    type: "textEditor",
                                    required: true,
                                    lg: 12,
                                  },
                                ]}
                                initialValues={{ htmlContent: "" }}
                                getValues={getSubscribValueFromToFormik}
                                // handleFormSubmit={handleContentFormSave}
                                showCancelButton={false}
                              />
                            </Col>
                          ),
                        },
                        {
                          id: "tab2",
                          label: "Video",
                          content: (
                            <Col sm="12">
                              <AppForm
                                name=""
                                layoutType={"CREATE"}
                                fields={[
                                  {
                                    label: "Chapter Video",
                                    name: "videoUrl",
                                    type: "url",
                                    required: true,
                                    lg: 12,
                                  },
                                ]}
                                initialValues={{ videoUrl: "" }}
                                getValues={getSubscribValueFromToFormik}
                                // handleFormSubmit={handleContentFormSave}
                                showCancelButton={false}
                              />
                            </Col>
                          ),
                        },
                        {
                          id: "tab3",
                          label: "PDF",
                          content: (
                            <Col sm="12">
                              <div className="mb-3">
                                <Label htmlFor="name">
                                  Upload PDF
                                  <span className="required_star">*</span>
                                </Label>
                                <input
                                  name="pdfContent"
                                  type="file"
                                  className="form-control custom_input custom_form_control"
                                  id="document"
                                  multiple
                                  onChange={event => {
                                    const files = Array.from(event.target.files)
                                    setChapterContent(prev => ({
                                      ...prev,
                                      pdfContent: files,
                                    }))
                                  }}
                                />
                              </div>
                            </Col>
                          ),
                        },
                        {
                          id: "tab4",
                          label: "Media",
                          content: (
                            <Col sm="12">
                              <MultiMedia
                                mediaModalToggle={mediaModalToggle}
                                showModal={mediaModal}
                                setShowModal={setMediaModal}
                                showDelete={false}
                              />
                            </Col>
                          ),
                        },
                      ]}
                      contentBgColor="#fff"
                    />
                  </Row>
                </InfomationModal>
              )}
              {isEdit && (
                <CertificateTest
                  courseDetail={courseDetail}
                  testCategories={testCategories}
                />
              )}
              <Card>
                <CardBody>
                  <Row className="justify-content-center">
                    <Col
                      className="justify-content-center d-flex"
                      xs="8"
                      sm={12}
                      md={12}
                    >
                      <Button
                        onClick={e =>
                          handleButtonClick(e, "submit", validation)
                        }
                        type="button"
                        color="primary"
                        className="global_btn"
                        disabled={loading}
                      >
                        <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
                        {loading ? (
                          <Spinner className="ms-2" color="light" size="sm" />
                        ) : isEdit ? (
                          "Save Course"
                        ) : (
                          "Create Course"
                        )}
                      </Button>
                      {!isEdit && (
                        <Button
                          onClick={e =>
                            handleButtonClick(e, "draft", validation)
                          }
                          type="button"
                          color=""
                          disabled={loading}
                          className="ms-4 btn btn-outline-secondary"
                        >
                          <i className="bx bx-save font-size-16 align-middle me-2"></i>
                          {loading ? (
                            <Spinner className="ms-2" color="light" size="sm" />
                          ) : (
                            "Save As Draft"
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {modal_backdrop && (
                    <ConfirmationModal
                      modalBackdrop={modal_backdrop}
                      setmodalBackdrop={setmodal_backdrop}
                      modalTitle={"Are you sure want to delete this module?"}
                      modalAction={modalAction}
                      loading={props.loading}
                    />
                  )}
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Courses)

Courses.propTypes = {
  history: PropTypes.object,
}
