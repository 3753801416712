import React from "react"
import { Card, CardBody } from "reactstrap"
import AppLoader from "../Loader/index"
import PlaceholderImg from "../../../assets/images/users/img_placeholder.png"
import moment from "moment"

function CardEmploymentHistory({ employment, loading = false }) {
  return (
    <Card style={{ borderRadius: 15 }}>
      <CardBody>
        {loading ? (
          <AppLoader />
        ) : (
          <div className="pt-3 px-2">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div>
                  <h4 className="sun_heading">Employment History</h4>
                </div>
                {employment && employment.length > 0
                  ? employment.map((job, index) => (
                      <div key={index}>
                        <div className="mt-4 d-flex">
                          <div className="me-4">
                            <img
                              src={PlaceholderImg} // You should provide the correct image source
                              alt={"qapreneur"}
                              height="47"
                              width="59"
                              className="tr_img"
                            />
                          </div>
                          <div>
                            <h4 className="post_name">{job.designation}</h4>
                            <p className="employer_name mb-1">
                              {job.companyName}
                              <span>
                                &nbsp; &#x2022; &nbsp;{job.employmentType}
                              </span>
                            </p>
                            <p className="work_time my-2">
                              {moment(job.joiningDate).format("DD MMM YYYY")}
                              {job?.releaseDate ? (
                                <>
                                  {" "}
                                  -{" "}
                                  {moment(job.releaseDate).format(
                                    "DD MMM YYYY"
                                  )}
                                </>
                              ) : (
                                <>&nbsp;&#x2022;&nbsp; current</>
                              )}
                              <span>
                                {job?.releaseDate ? (
                                  <>
                                    &nbsp; &#x2022; &nbsp;
                                    {moment(job.releaseDate).diff(
                                      moment(job.joiningDate),
                                      "years"
                                    )}
                                    &nbsp; years
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                              </span>
                            </p>
                            <p className="employee_extra_info mb-1">
                              {job.description}
                            </p>
                          </div>
                        </div>

                        <div className="full_devider my-4"></div>
                      </div>
                    ))
                  : "No Employment Added"}
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default CardEmploymentHistory
