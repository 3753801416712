import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Form,
  Spinner
} from "reactstrap"
import Breadcrumbs from "../../components/Common/BackButtonBreadcrumb"
import "react-datepicker/dist/react-datepicker.css"
import {
  getQuestions as onGetQuestions,
  deleteQuestion as onDeleteQuestion,
  getTestDetail as onGetTestDetail,
  resetTests as onResetTests,
  addNewQuestion as onAddNewQuestion,
} from "../../store/actions"

import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"
import QuestionsCard from "../../components/Common/Card/QuestionsCard";
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import CsvToJsonConverter from "../../components/Common/CsvToJsonConverter";

const QuestionList = props => {
  const dispatch = useDispatch()
  const params = useParams()
  const [isEdit, setIsEdit] = useState(false)
  const [moduleName, setModuleName] = useState("")
  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState([])
  const [csvModal, setCsvModal] = useState(false)
  const navigate = useNavigate()


  document.title = `${isEdit ? `Edit Question Module - ${moduleName}` : "Create New Question"
    } | QAPRENEUR`


  const { testDetail } = useSelector(state => ({
    testDetail: state.tests?.testDetail,
  }))
  useEffect(() => {
    const currentLocation = props.router.location.pathname
    const status = currentLocation.includes("questions-view")
    setIsEdit(status)
    if (status && params?.id) {
      dispatch(onGetTestDetail(params.id))
    }
    return () => {
      dispatch(onResetTests())
      setLoading(false)
    }
  }, [props.router.location.pathname])

  useEffect(() => {
    if (Object.keys(testDetail).length) {
      setLoading(false)
    }
    if (isEdit && testDetail) {
      if (testDetail?.questions) {
        const ques = testDetail.questions.map(question => {
          const que = JSON.parse(question.question)
          que.questionId = question._id
          return que
        })
        setQuestions(ques)
      }
    }
  }, [testDetail])

  const handleDeleteButton = id => {
    setLoading(true)
    dispatch(onDeleteQuestion(id))
    dispatch(onGetTestDetail(params.id))
    dispatch(onGetQuestions("chapters", params.id))
    setLoading(false)
  }
  const csvToggle = () => setCsvModal(!csvModal)

  const handleCsvToJsonConverter = (res) => {
    if (res?.data) {
      const csvResponse = res?.data.length > 0 && res?.data.map((r, k) => {
        return {
          question: r?.title || "",
          A: r?.A || "",
          B: r?.B || "",
          C: r?.C || "",
          D: r?.D || "",
          answer: r?.answer || "",
          description: r?.description || "",
        }
      }).filter(q => q?.question)
      const totalQuestion = { questions: JSON.stringify(csvResponse) }
      dispatch(
        onAddNewQuestion(
          totalQuestion,
          navigate,
          params?.id,
          "questions-view",
          testDetail?.courseId,
          testDetail?.chapterId
        )
      )
      dispatch(onGetTestDetail(params.id))
      csvToggle()
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Form>
            <Breadcrumbs
              title="Questions"
              navigate={-1}
              breadcrumbItem={`${isEdit
                ? `Edit Question Module - ${moduleName}`
                : "Create Question"
                }`}
              name="Back to Create Course"
              breadcrumbTitle={moduleName}
            />
            {loading ? <div className="d-flex justify-content-center"><Spinner className="ms-2" color="dark" size="sm" /></div> : (
              <Row>
                <Col lg={12}>
                  <QuestionsCard
                    title="Question List"
                    questions={questions}
                    handleDeleteButton={handleDeleteButton}
                    showEdit={true}
                    loading={loading}
                    isCsvToJsonConverter={csvToggle}
                  />
                </Col>
              </Row>
            )}
          </Form>
        </Container>
        {csvModal && (
          <InfomationModal
            modalBackdrop={csvModal}
            setmodalBackdrop={setCsvModal}
            title={"Import questions"}
          >
            <Row>
              <CsvToJsonConverter handleCsvToJsonConverter={handleCsvToJsonConverter} />
            </Row>
          </InfomationModal>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(QuestionList)

QuestionList.propTypes = {
  history: PropTypes.object,
}
