import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Form,
  FormFeedback,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import FormModal from "../../../components/Common/Modal/FormModal"
import InfomationModal from "../../../components/Common/Modal/InfomationModal"
import AppInput from "../../../components/Common/AppInput"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  updatePassword,
  updateMyDetails,
  sendPhoneOtp,
  verifyingEmailOtp,
} from "helpers/api/usersApi"
import toastr from "toastr"

const resetpasswordfields = [
  {
    label: "Existing Password",
    name: "oldPassword",
    className: "input_field",
    type: "text",
    placeholder: "Old Password",
  },
  {
    label: "New Password",
    name: "newPassword",
    className: "input_field",
    type: "text",
    placeholder: "New Password",
  },
  {
    label: "Confirm Password",
    name: "confirmPassword",
    className: "input_field",
    type: "text",
    placeholder: "Confirm Password",
  },
]

const ProfileMenu = props => {
  const staticURL = process.env.REACT_APP_STATIC_URL
  const navigate = useNavigate()
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("")
  const [userDetail, setrUserDetail] = useState(null)
  const [role, serRole] = useState("")
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const [showOtpMessage, setOtpMessage] = useState(true)
  const [showOtpField, setShowOtpField] = useState(false)
  const [adminContactModal, setAdminContactModal] = useState(false)
  const [showChangePhoneField, setShowChangePhoneField] = useState(false)

  useEffect(() => {
    const authUser = props.user
    const authRole = localStorage.getItem("authRole")
    if (authUser) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(authUser)
        obj && setrUserDetail(obj)
        setusername(obj.name)
        serRole(authRole ? authRole : "")
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = props.user
        setusername(obj.name)
        obj && setrUserDetail(obj)
        serRole(authRole ? authRole : "")
      }
    }
  }, [props.success, props.user])

  const phoneValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: userDetail ? userDetail?.phone : "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^[0-9]+$/, {
          message: "Phone Number Must Contain Only Numeric Digits",
        })
        .matches(/^\d{10}$/, {
          message: "Phone Number Must Be Exactly 10 Digits",
        })
        .required(`Please Enter phone Number`),
    }),
    onSubmit: async values => {
      try {
        const resp = await updateMyDetails(values)
        if (resp.data.user) {
          toastr.success("Changed Phone Number!")
          setAdminContactModal(false)
          setShowChangePhoneField(false)
          setOtpMessage(true)
        } else {
          toastr.error("Invailid mobile Number!")
        }
      } catch (error) {
        toastr.error(error.response.data.message)
      }
    },
    isSubmitting: false,
  })

  const togglePasswordmodal = () => setResetPasswordModal(!resetPasswordModal)
  const toggleConatctmodal = () => setAdminContactModal(!adminContactModal)

  const handleResetPassword = async values => {
    try {
      const data = {
        ...values,
        resourceType: "Web",
      }
      await updatePassword(data)
      togglePasswordmodal()
    } catch (error) {
      toastr.error(error.response.data.message)
      togglePasswordmodal()
    }
  }

  const sendOtpOnEmail = async () => {
    try {
      const response = await sendPhoneOtp({ email: userDetail.email })
      if (response.status === "success") {
        toastr.success(response.message)
        setOtpMessage(false)
        setShowOtpField(true)
      } else {
        toastr.error(response.message)
      }
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const otpValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("Please Enter OTP")
        .matches(/^\d{4}$/, "OTP must be exactly 4 digits"),
    }),
    onSubmit: values => {
      verifyMailOtp(values.otp)
    },
    isSubmitting: false,
  })

  const verifyMailOtp = async otp => {
    try {
      if (otp.length === 4) {
        const response = await verifyingEmailOtp({
          email: userDetail.email,
          otp,
        })
        if (response?.data?.otpVerified) {
          setShowOtpField(false)
          setShowChangePhoneField(true)
        }
      }
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {props?.user?.image && props.user?.image ? (
            <img
              className="rounded-circle header-profile-user"
              src={staticURL + props.user?.image}
              alt="Header Avatar"
            />
          ) : (
            <img
              className="rounded-circle header-profile-user"
              src={staticURL + "/default/default-user.png"}
              alt="Header Avatar"
            />
          )}
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to={`/profile/view/${role}/${userDetail?._id}`}
            className="dropdown-item"
          >
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>{props.t("Profile")}</span>
          </Link>
          {["company", "hr_users"].includes(role) && (
            <>
              <button
                className="dropdown-item"
                // onClick={toggleForgotPasswordmodal}
                onClick={() => navigate("/changePassword")}
              >
                <i className="bx bx-lock-open font-size-16 align-middle me-1" />
                <span>Change Password</span>
              </button>
              {/* <button
                className="dropdown-item"
                onClick={toggleForgotPasswordmodal}
              >
                <span className="mdi mdi-lock-reset me-1"></span>
                <span>Forgot Password</span>
              </button> */}
            </>
          )}
          {["admin"].includes(role) && (
            <>
              <button
                className="dropdown-item"
                // onClick={toggleForgotPasswordmodal}
                onClick={() => navigate("/changePassword")}
              >
                <i className="bx bx-lock-open font-size-16 align-middle me-1" />
                <span>Change Password</span>
              </button>
              <button className="dropdown-item" onClick={toggleConatctmodal}>
                <i className="bx bx-phone font-size-16 align-middle me-1" />
                <span>Change Contact</span>
              </button>
            </>
          )}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      {resetPasswordModal && (
        <FormModal
          modalBackdrop={resetPasswordModal}
          setmodalBackdrop={setResetPasswordModal}
          modalAction={handleResetPassword}
          title={""}
          showTitle={false}
          formFields={resetpasswordfields}
          data={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          height={300}
          cancelBtn={true}
        />
      )}
      {adminContactModal && (
        <>
          <InfomationModal
            modalBackdrop={adminContactModal}
            setmodalBackdrop={setAdminContactModal}
            title={"Change Number"}
            size="md"
          >
            {showOtpMessage && (
              <>
                <p className="fw-bold fs-6" style={{ color: "#243466" }}>
                  To Change The Mobile Number
                  {userDetail?.phone ? ` ${userDetail?.phone}` : ""}, OTP Will
                  Send On This Email Id &nbsp;
                  {userDetail?.email && `******${userDetail?.email.slice(-17)}`}
                </p>
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    className="btn btn-primary btn-block custom_btn"
                    type="submit"
                    onClick={sendOtpOnEmail}
                  >
                    Send
                  </button>
                </div>
              </>
            )}
            {showOtpField && (
              <>
                <div className="my-lg-auto">
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      otpValidation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="mb-4">
                      <p className="fw-bold fs-6" style={{ color: "#243466" }}>
                        OTP Has Been Sent To This Email Id &nbsp;
                        {userDetail?.email &&
                          `******${userDetail?.email.slice(-17)}`}
                      </p>
                      <AppInput
                        label={"Verify your OTP code"}
                        name="otp"
                        className="input_field otp_container"
                        type="text"
                        placeholder="Please Enter 4 Digit Code"
                        onChange={otpValidation.handleChange}
                        onBlur={otpValidation.handleBlur}
                        value={otpValidation.values?.otp}
                        invalid={
                          otpValidation.touched?.otp &&
                          otpValidation.errors?.otp
                        }
                        error={otpValidation.errors?.otp}
                      />
                      {otpValidation.touched.phone &&
                      otpValidation.errors.phone ? (
                        <FormFeedback type="invalid">
                          {otpValidation.errors.phone}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mt-3 d-flex justify-content-end">
                      <button
                        className="btn btn-primary btn-block custom_btn"
                        onClick={sendOtpOnEmail}
                      >
                        Resend
                      </button>
                      <button
                        className="btn btn-primary btn-block custom_btn"
                        type="submit"
                      >
                        Verify
                      </button>
                    </div>
                  </Form>
                </div>
              </>
            )}
            {showChangePhoneField && (
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  phoneValidation.handleSubmit()
                  return false
                }}
              >
                <div className="mb-4">
                  <AppInput
                    label={"Mobile Number"}
                    name="phone"
                    className="input_field otp_container"
                    type="text"
                    placeholder="Please Enter Mobile Number"
                    onChange={phoneValidation.handleChange}
                    onBlur={phoneValidation.handleBlur}
                    value={phoneValidation.values?.phone}
                    invalid={
                      phoneValidation.touched?.phone &&
                      phoneValidation.errors?.phone
                    }
                    error={phoneValidation.errors?.phone}
                    // disabled={true}
                  />
                  {phoneValidation.touched.phone &&
                  phoneValidation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {phoneValidation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mt-3 d-flex justify-content-end">
                  <button className="btn btn-primary custom_btn" type="submit">
                    Change Number
                  </button>
                </div>
              </Form>
            )}
          </InfomationModal>
        </>
      )}
      {/* {forgotPasswordModal && (
        <InfomationModal
          modalBackdrop={forgotPasswordModal}
          setmodalBackdrop={setForgotPasswordModal}
          title={"Change password"}
          size="md"
        >
          <div className="my-lg-auto">
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                phoneValidation.handleSubmit()
                return false
              }}
            >
              <div className="mb-4">
                <AppInput
                  label={"Mobile Number"}
                  name="phone"
                  className="input_field otp_container"
                  type="text"
                  placeholder="Please Enter Mobile Number"
                  onChange={phoneValidation.handleChange}
                  onBlur={phoneValidation.handleBlur}
                  value={phoneValidation.values?.phone}
                  invalid={
                    phoneValidation.touched?.phone &&
                    phoneValidation.errors?.phone
                  }
                  error={phoneValidation.errors?.phone}
                  // disabled={true}
                />
                {phoneValidation.touched.phone &&
                phoneValidation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {phoneValidation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mt-3 d-flex justify-content-end">
                <button className="btn btn-primary custom_btn" type="submit">
                  Send OTP
                </button>
              </div>
            </Form>
          </div>
          {showOtpField && (
            <>
              <div className="my-lg-auto">
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    otpValidation.handleSubmit()
                    return false
                  }}
                >
                  <div className="mb-4">
                    <AppInput
                      label={"verify your otp code"}
                      name="otp"
                      className="input_field otp_container"
                      type="text"
                      placeholder="please enter 6 digit code"
                      onChange={otpValidation.handleChange}
                      onBlur={otpValidation.handleBlur}
                      value={otpValidation.values?.otp}
                      invalid={
                        otpValidation.touched?.otp && otpValidation.errors?.otp
                      }
                      error={otpValidation.errors?.otp}
                    />
                    {otpValidation.touched.phone &&
                    otpValidation.errors.phone ? (
                      <FormFeedback type="invalid">
                        {otpValidation.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mt-3 d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-block custom_btn"
                      type="submit"
                    >
                      Verify
                    </button>
                  </div>
                </Form>
              </div>
            </>
          )}
          {showCapcha && <div id="recaptcha-container"></div>}
          {showForgotPasswordField && (
            <>
              <div className="my-lg-auto">
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    forgotPasswordValidation.handleSubmit()
                    return false
                  }}
                >
                  <div className="mb-4">
                    <AppInput
                      label="New Password"
                      name="password"
                      className="input_field otp_container"
                      type="text"
                      placeholder="Enter new password"
                      onChange={forgotPasswordValidation.handleChange}
                      onBlur={forgotPasswordValidation.handleBlur}
                      value={forgotPasswordValidation.values?.password}
                      invalid={
                        forgotPasswordValidation.touched?.password &&
                        forgotPasswordValidation.errors?.password
                      }
                      error={forgotPasswordValidation.errors?.password}
                    />
                  </div>
                  <div className="mb-4">
                    <AppInput
                      label="Confirm New Password"
                      name="passwordConfirm"
                      className="input_field otp_container"
                      type="text"
                      placeholder="Confirm new password"
                      onChange={forgotPasswordValidation.handleChange}
                      onBlur={forgotPasswordValidation.handleBlur}
                      value={forgotPasswordValidation.values?.passwordConfirm}
                      invalid={
                        forgotPasswordValidation.touched?.passwordConfirm &&
                        forgotPasswordValidation.errors?.passwordConfirm
                      }
                      error={forgotPasswordValidation.errors?.passwordConfirm}
                    />
                  </div>

                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-primary btn-block custom_btn"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner className="ms-2" color="light" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </Form>
              </div>
            </>
          )}
        </InfomationModal>
      )} */}
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
