/* COURSES */
export const GET_COURSES = "GET_COURSES"
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS"
export const GET_COURSES_FAIL = "GET_COURSES_FAIL"

/* COURSES DETAIL*/
export const GET_COURSE_DETAIL = "GET_COURSE_DETAIL"
export const GET_COURSE_DETAIL_SUCCESS = "GET_COURSE_DETAIL_SUCCESS"
export const GET_COURSE_DETAIL_FAIL = "GET_COURSE_DETAIL_FAIL"

/**
 * add course
 */
export const ADD_NEW_COURSE = "ADD_NEW_COURSE"
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS"
export const ADD_COURSE_FAIL = "ADD_COURSE_FAIL"

/**
 * Edit course
 */
export const UPDATE_COURSE = "UPDATE_COURSE"
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS"
export const UPDATE_COURSE_FAIL = "UPDATE_COURSE_FAIL"

/**
 * Delete course
 */
export const DELETE_COURSE = "DELETE_COURSE"
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS"
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL"

export const RESET_COURSE = "RESET_COURSE"

export const UPDATE_COURSE_LOADING_STATE = "UPDATE_COURSE_LOADING_STATE"

export const GET_SUBSCRIPTIONS_USERS = "GET_SUBSCRIPTIONS_USERS"
export const GET_SUBSCRIPTIONS_USERS_SUCCESS = "GET_SUBSCRIPTIONS_USERS_SUCCESS"
export const RESET_SUBSCRIPTIONS_USERS = 'RESET_SUBSCRIPTIONS_USERS'


export const SEARCH_COURSE = "SEARCH_COURSE"
export const SEARCH_SUCCESS = "SEARCH_SUCCESS"
export const SEARCH_FAIL = "SEARCH_FAIL"

/*COURSE TESTS RESULT */
export const GET_STUDENT_COURSE_RESULT = "GET_STUDENT_COURSE_RESULT"
export const GET_STUDENT_COURSE_RESULT_SUCCESS = "GET_STUDENT_COURSE_RESULT_SUCCESS"
export const GET_STUDENT_COURSE_RESULT_FAIL = "GET_STUDENT_COURSE_RESULT_FAIL"