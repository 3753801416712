import { del, get, post, patch } from "../api_helper"

export const updatePassword = data => patch(`/users/updateMyPassword`, data)

export const updateMyDetails = (data, id) => {
  return patch(`/users/updateMe`, data, {
    headers: {
      "Content-Type":
        !data.image || typeof data?.image == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })
}

export const getCollegeRequestsApi = queryParams =>
  get(`/users/collegeRequests${queryParams}`)

// get company  profile details
export const getProfileDetails = () => get(`/users/me`)

// Forget Password Method
export const postJwtForgetPwd = data => post(url.POST_JWT_PASSWORD_FORGET, data)
export const resetPwd = data => patch("/users/resetPasswordWithPhone", data)
export const resetPwdHr = data => patch(`/users/updateHrPassword/${data._id}`, data)
export const checkUserbyPhone = data => post("/users/check-user-by-phone", data)
// Reset Phone Method
export const sendPhoneOtp = data =>
  post("/users/change-phone-by-mail-verification", data)
export const verifyingEmailOtp = data => post("/users/verifying-otp", data)

export const getUserRole = () => get("/roles/user-role")

export const postJwtLogin = (data, type) =>
  post(type === "admin" ? '/users/admin/login' : '/users/login', data)
