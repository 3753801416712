import { del, get, post, patch } from "../api_helper"
import { PACKAGES_ROUTES } from "../url_helper"

export const createPackage = packageData =>
  post(`${PACKAGES_ROUTES}`, packageData, {
    headers: { "Content-Type": "application/json" },
  })

export const updatePackage = (packageData, packageId) =>
  patch(`${PACKAGES_ROUTES}/${packageId}`, packageData, {
    headers: { "Content-Type": "application/json" },
  })

export const getPackageDetails = packageId =>
  get(`${PACKAGES_ROUTES}/${packageId}`, {
    headers: { "Content-Type": "application/json" },
  })

export const getPackages = queryParams =>
  get(`${PACKAGES_ROUTES}${queryParams}`)

export const deletePackage = packageId => del(`${PACKAGES_ROUTES}/${packageId}`)
