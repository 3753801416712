import tests from "store/tests/reducer"
import {
  GET_TESTS,
  GET_TESTS_FAIL,
  GET_TESTS_SUCCESS,
  GET_TEST_DETAIL,
  ADD_NEW_TEST,
  ADD_TEST_SUCCESS,
  ADD_TEST_FAIL,
  UPDATE_TEST,
  UPDATE_TEST_SUCCESS,
  UPDATE_TEST_FAIL,
  DELETE_TEST,
  DELETE_TEST_SUCCESS,
  DELETE_TEST_FAIL,
  GET_TEST_DETAIL_FAIL,
  GET_TEST_DETAIL_SUCCESS,
  UPDATE_TEST_LOADING_STATE,
  RESET_TESTS,
  GET_USERS_PURCHASED_TESTS,
  RESET_USERS_PURCHASED_TESTS,
  GET_USERS_PURCHASED_TESTS_SUCCESS,
  SEARCH_TESTS,
  SEARCH_TESTS_SUCCESS,
  SEARCH_TESTS_FAIL,
  GET_STUDENT_TEST_RESULT,
  GET_STUDENT_TEST_RESULT_FAIL,
  GET_STUDENT_TEST_RESULT_SUCCESS,
  RESET_STUDENT_TEST_RESULT
} from "./actionTypes"

export const getTests = params => ({
  type: GET_TESTS,
  params,
})

export const resetTests = () => ({
  type: RESET_TESTS,
})

export const getTestsSuccess = tests => ({
  type: GET_TESTS_SUCCESS,
  payload: tests,
})

export const addNewTest = (test, history, route) => ({
  type: ADD_NEW_TEST,
  payload: { test, history, route },
})

export const addTestSuccess = test => ({
  type: ADD_TEST_SUCCESS,
  payload: test,
})

export const addTestFail = error => ({
  type: ADD_TEST_FAIL,
  payload: error,
})

export const updateTest = (test, testId, history, route) => ({
  type: UPDATE_TEST,
  payload: { test, testId, history, route },
})

export const updateTestSuccess = test => ({
  type: UPDATE_TEST_SUCCESS,
  payload: test,
})

export const updateTestFail = error => ({
  type: UPDATE_TEST_FAIL,
  payload: error,
})

export const deleteTest = (testId, params) => ({
  type: DELETE_TEST,
  payload: { testId, params },
})

export const deleteTestSuccess = test => ({
  type: DELETE_TEST_SUCCESS,
  payload: test,
})

export const deleteTestFail = error => ({
  type: DELETE_TEST_FAIL,
  payload: error,
})

export const getTestsFail = error => ({
  type: GET_TESTS_FAIL,
  payload: error,
})

export const getTestDetail = testId => ({
  type: GET_TEST_DETAIL,
  testId,
})

export const getTestDetailSuccess = testDetails => ({
  type: GET_TEST_DETAIL_SUCCESS,
  payload: testDetails,
})

export const getTestDetailFail = error => ({
  type: GET_TEST_DETAIL_FAIL,
  payload: error,
})

export const updateTestLoadingState = status => ({
  type: UPDATE_TEST_LOADING_STATE,
  payload: status,
})

export const getUsersPurchasedTests = (testId, params) => ({
  type: GET_USERS_PURCHASED_TESTS,
  payload: { testId, params },
})

export const resetUsersPurchasedTests = () => ({
  type: RESET_USERS_PURCHASED_TESTS,
})

export const getUsersPurchasedTestsSucess = user => {
  return {
    type: GET_USERS_PURCHASED_TESTS_SUCCESS,
    payload: user,
  }
}

export const searchTest = params => ({
  type: SEARCH_TESTS,
  params,
})
export const searchTestsSuccess = users => ({
  type: SEARCH_TESTS_SUCCESS,
  payload: users,
})
export const searchTestsFail = error => ({
  type: SEARCH_TESTS_FAIL,
  payload: error,
})


export const getStudentTestResults = params => ({
  type: GET_STUDENT_TEST_RESULT,
  params,
})

export const getStudentTestSuccess = testResult => ({
  type: GET_STUDENT_TEST_RESULT_SUCCESS,
  payload: testResult,
})

export const getStudentTestFail = error => ({
  type: GET_STUDENT_TEST_RESULT_FAIL,
  payload: error,
})

export const resettudentTestTests = () => ({
  type: RESET_STUDENT_TEST_RESULT,
})