import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { State, City } from "country-state-city"
import AppSelect from "../../../components/Common/AppSelect/index"
import MultiSelect from "../../../components/Common/AppSelect/MultiSelect"

import { getSkills as onGetSkills } from "../../../store/actions"

export const field = {
  name: "filter",
  label: "",
  type: "filter",
  lg: 12,
  state: {
    name: "state",
    label: "Select State",
    type: "select",
    isExternal: true,
    slug: "state",
    required: true,
  },
  city: {
    name: "city",
    label: "Select City",
    type: "select",
    isExternal: true,
    slug: "city",
    required: true,
  },
  experience: {
    label: "Experience",
    name: "experience",
    type: "select",
    options: [
      { value: "", label: "Select Experience" },
      { value: "0.5", label: "Less than 1yr" },
      { value: "1", label: "1yr" },
      { value: "1.5", label: "1.5yr" },
      { value: "2", label: "2yr" },
      { value: "2.5", label: "2.5yr" },
      { value: "3", label: "3yr" },
      { value: "3.5", label: "3.5yr" },
      { value: "4", label: "4yr" },
      { value: "4.5", label: "4.5yr" },
      { value: "5", label: "5yr" },
      { value: "5.5", label: "5.5yr" },
      { value: "6", label: "6yr" },
      { value: "6.5", label: "6.5yr" },
      { value: "7", label: "7yr" },
      { value: "7.5", label: "7.5yr" },
      { value: "8", label: "8yr" },
    ],
    required: true,
  },
  skillIds: {
    label: "Skills",
    name: "skillIds",
    type: "multiSelect",
    options: [],
    required: true,
  },
  degree: {
    label: "Degree",
    name: "degree",
    type: "select",
    options: [
      { value: "", label: "Select Degree" },
      { value: "bachelors", label: "Bachelor's Degree" },
      { value: "masters", label: "Master's Degree" },
      { value: "bba", label: "Bachelor of Business Administration (BBA)" },
      { value: "mba", label: "Master of Business Administration (MBA)" },
      { value: "bca", label: "Bachelor of Computer Applications (BCA)" },
      { value: "mca", label: "Master of Computer Applications (MCA)" },
      { value: "bsc", label: "Bachelor of Science (BSc)" },
      { value: "msc", label: "Master of Science (MSc)" },
      { value: "bcom", label: "Bachelor of Commerce (BCom)" },
      { value: "mcom", label: "Master of Commerce (MCom)" },
      { value: "bsw", label: "Bachelor of Social Work (BSW)" },
      { value: "msw", label: "Master of Social Work (MSW)" },
      { value: "beng", label: "Bachelor of Engineering (BEng)" },
      { value: "meng", label: "Master of Engineering (MEng)" },
      { value: "mse", label: "Master of Science in Engineering (MSE)" },
      { value: "barch", label: "Bachelor of Architecture (BArch)" },
      { value: "march", label: "Master of Architecture (MArch)" },
      { value: "bed", label: "Bachelor of Education (BEd)" },
      { value: "med", label: "Master of Education (MEd)" },
      { value: "bpharm", label: "Bachelor of Pharmacy (BPharm)" },
      { value: "mpharm", label: "Master of Pharmacy (MPharm)" },
      { value: "bnursing", label: "Bachelor of Nursing (BNursing)" },
      { value: "msn", label: "Master of Science in Nursing (MSN)" },
      { value: "btech", label: "Bachelor of Technology (B Tech)" },
      { value: "mtech", label: "Master of Technology (M Tech)" },
      {
        value: "mbbs",
        label: "Bachelor of Medicine and Bachelor of Surgery (MBBS)",
      },
      { value: "md", label: "Doctor of Medicine (MD)" },
      { value: "ms", label: "Master of Surgery (MS)" },
      { value: "ba", label: "Bachelor of Arts (BA)" },
      { value: "ma", label: "Master of Arts (MA)" },
      { value: "bdes", label: "Bachelor of Design (BDes)" },
      { value: "mdes", label: "Master of Design (MDes)" },
      {
        value: "bjmc",
        label: "Bachelor of Journalism and Mass Communication (BJMC)",
      },
      {
        value: "mjmc",
        label: "Master of Journalism and Mass Communication (MJMC)",
      },
      {
        value: "bjmct",
        label: "Bachelor of Hotel Management and Catering Technology (BHMCT)",
      },
      { value: "mhm", label: "Master of Hotel Management (MHM)" },
    ],
    required: true,
  },
  search: {
    label: "Search",
    name: "search",
    type: "text",
    required: true,
  },
}

function FilterDropdown({ filterType, onChange, onReset, handleFilter }) {
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [selectState, setSelectState] = useState("")
  const [selectCity, setSelectCity] = useState("")
  const [experienceFilter, setExperienceFilter] = useState("")
  const [selectSkills, setSelectSkills] = useState([])
  const [selectDegree, setSelectDegree] = useState([])
  const [searchDegree, setSearchDegree] = useState("")


  const dispatch = useDispatch()

  const { skills } = useSelector(state => ({
    skills: state.skills.skills,
  }))

  useEffect(() => {
    const states = State.getStatesOfCountry("IN").map(s => ({
      value: s.name,
      label: s.name,
      isoCode: s.isoCode,
    }))
    setStateOptions([
      { value: "", label: "select state", isoCode: "" },
      ...states,
    ])

    const cities = City.getCitiesOfState("IN", "MP").map(s => ({
      value: s.name,
      label: s.name,
    }))
    setCityOptions([{ value: "", label: "select city" }, , ...cities])
  }, [])

  useEffect(() => {
    const authUser = localStorage.getItem("authUser")
    if (authUser) {
      const data = JSON.parse(authUser)
      dispatch(onGetSkills(data?._id))
    }
  }, [dispatch])

  const handleStateChange = selectedState => {
    const isoCode = stateOptions.find(
      stateOption => stateOption.value === selectedState
    ).isoCode

    const cities = City.getCitiesOfState("IN", isoCode).map(s => ({
      value: s.name,
      label: s.name,
    }))
    setCityOptions(cities)
    setSelectState(selectedState)
    onChange("state", selectedState)
  }

  const handleCityChange = selectedCity => {
    setSelectCity(selectedCity)
    onChange("city", selectedCity)
  }

  const handleExperienceChange = e => {
    setExperienceFilter(e.target.value)
    onChange("experience", e.target.value)
  }

  const handleReset = type => {
    if (type === "location") {
      setSelectState("MP")
      setCityOptions([{ value: "", label: "select city" }])
    } else if (type === "experience") {
      setExperienceFilter("")
    } else if (type === "skills") {
      setSelectSkills([])
    } else if (type === "degree") {
      setSelectDegree([])
    } else if (type === "ALL") {
      document.getElementById("location").click()
      document.getElementById("experience").click()
      document.getElementById("skills").click()
      document.getElementById("degree").click()
    }
    onReset(type)
  }
  const skillOptions =
    Array.isArray(skills) && skills.length
      ? skills.map(skill => ({ label: skill.name, value: skill._id }))
      : []

  const renderOptions = () => {
    switch (filterType) {
      case "location":
        return (
          <div className="d-flex align-items-center flex-direction-row">
            <AppSelect
              label={field.state.label}
              name={field.state.name}
              id={`formdiv-${field.state.name}-Input`}
              value={selectState}
              onChange={e => handleStateChange(e.target.value)}
              options={stateOptions}
              isExternal={false}
              slug="state"
            />
            <div className="d-flex align-items-center me-2 ms-2">
              <AppSelect
                label={field.city.label}
                name={field.city.name}
                id={`formrow-${field.city.name}-Input`}
                value={selectCity}
                onChange={e => handleCityChange(e.target.value)}
                options={cityOptions}
                isExternal={false}
                slug="city"
              />
            </div>
            <div className="d-flex align-items-center">
              <i
                id="location"
                className="bx bx-reset mt-2"
                onClick={() => handleReset("location")}
                style={{ fontSize: "20px", cursor: "pointer" }}
              ></i>
            </div>
          </div>
        )
      case "experience":
        return (
          <>
            <div className="d-flex align-items-center flex-direction-row">
              <AppSelect
                label={field.experience.label}
                name={field.experience.name}
                id={`formrow-${field.experience.name}-Input`}
                value={experienceFilter}
                onChange={e => {
                  handleExperienceChange(e)
                  onChange("experience", e.target.value)
                }}
                options={field.experience.options}
                isExternal={false}
                slug="experience"
              />
              <div className="d-flex align-items-center ms-2">
                <i
                  id="experience"
                  className="bx bx-reset mt-2"
                  onClick={() => handleReset("experience")}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                ></i>
              </div>
            </div>
          </>
        )
      case "degree":
        return (
          <div className="d-flex align-items-center flex-direction-row">
            <MultiSelect
              label={field.degree.label}
              name={field.degree.name}
              options={field.degree.options}
              selectedOptions={selectDegree}
              onChange={selected => {
                setSelectDegree(selected)
                onChange("degree", selected)
              }}
              isMulti={false}
            />
            <div className="d-flex align-items-center ms-2">
              <i
                id="degree"
                className="bx bx-reset mt-2"
                onClick={() => handleReset("degree")}
                style={{ fontSize: "20px", cursor: "pointer" }}
              ></i>
            </div>
          </div>
        )
      case "search":
        return (
          <>
            <div className="d-flex align-items-center flex-direction-row">
              <FormGroup>
                <Label>{field.search.label}</Label>
                <Input
                  onChange={e => {
                    setSearchDegree(e.target.value)
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control custom_searchbox"
                  placeholder="search by Degree"
                  // value={search}
                  name={field.search.name}
                />
              </FormGroup>
              <div className="d-flex align-items-center ms-2">
                <i
                  id="search"
                  className="bx bx-reset mt-2"
                  onClick={() => handleReset("search")}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                ></i>
              </div>
            </div>
          </>
        )
      case "skills":
        return (
          <div className="d-flex align-items-center flex-direction-row">
            <MultiSelect
              name={field.skillIds.name}
              label={field.skillIds.label}
              options={skillOptions}
              selectedOptions={selectSkills}
              onChange={selected => {
                setSelectSkills(selected)
                onChange("skills", selected)
              }}
              // isMulti={false}
            />
            <div className="d-flex align-items-center ms-2">
              <i
                id="skills"
                className="bx bx-reset mt-2"
                onClick={() => handleReset("skills")}
                style={{ fontSize: "20px", cursor: "pointer" }}
              ></i>
            </div>
          </div>
        )
      case "filterBtn":
        return (
          <Button
            type="button"
            color="primary global_btn"
            onClick={handleFilter}
            className="btn  me-3 px-4"
          >
            Filter
          </Button>
        )
      case "filterReset":
        return (
          <Button
            type="button"
            color="secondary global_btn"
            onClick={() => handleReset("ALL")}
            className="btn me-3 px-4"
          >
            Reset
          </Button>
        )
      default:
        return null
    }
  }

  return renderOptions()
}

const RenderFilterDropdown = ({
  handlefilterchange,
  handleFilter,
  onReset,
}) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col lg={6}>
            <FilterDropdown
              filterType="location"
              onChange={handlefilterchange}
              onReset={onReset}
            />
          </Col>
          <Col>
            <FilterDropdown
              filterType="experience"
              onChange={handlefilterchange}
              onReset={onReset}
            />
          </Col>
          <Col>
            <FilterDropdown
              filterType="skills"
              onChange={handlefilterchange}
              onReset={onReset}
            />
          </Col>
          <Col>
            <FilterDropdown
              filterType="degree"
              onChange={handlefilterchange}
              onReset={onReset}
            />
          </Col>
          {/* <Col >
            <FilterDropdown
              filterType="search"
              onChange={handlefilterchange}
              onReset={onReset}
            />
          </Col> */}
          <Col lg={6}>
            <FilterDropdown
              filterType="filterBtn"
              handleFilter={handleFilter}
            />
            <FilterDropdown filterType="filterReset" onReset={onReset} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default RenderFilterDropdown
