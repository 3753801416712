import React from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import AppLoader from "../Loader/index"

function CardInfo({
  title,
  fields = [],
  BtnTitle = "",
  isButton = false,
  onClick,
  loading = false,
  sm = 6,
  lg = 4,
  md = 4,
}) {
  return (
    <Card style={{ borderRadius: 15 }}>
      {loading && <AppLoader />}
      {!loading && (
        <CardBody>
          <div className="pt-3 px-2">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div className="d-flex justify-content-between align-items-center">
                  <h4
                    className="mb-sm-0 font-size-18 fw-bold"
                    style={{ color: "#243466" }}
                  >
                    {title}
                  </h4>
                  {isButton && onClick && (
                    <Button
                      color="primary"
                      className="global_btn btn btn-primary px-4"
                      onClick={onClick}
                    >
                      <i className="bx bx-edit font-size-16 align-middle me-2"></i>

                      {BtnTitle}
                    </Button>
                  )}
                </div>
                <div className="mt-4">
                  <Row>
                    {fields.length > 0 &&
                      fields.map((field, key) => {
                        return (
                          <Col sm={sm} lg={lg} key={key}>
                            <div className="mb-4">
                              <p className="text-muted mb-2 sm_title">
                                {field.label}
                              </p>
                              <h5
                                className="font-size-15 sm_content"
                                style={{ color: "#243466" }}
                              >
                                {field.value}
                              </h5>
                            </div>
                          </Col>
                        )
                      })}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      )}
    </Card>
  )
}

export default CardInfo
