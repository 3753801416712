import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_TESTS,
  GET_TEST_DETAIL,
  ADD_NEW_TEST,
  DELETE_TEST,
  UPDATE_TEST,
  GET_USERS_PURCHASED_TESTS,
  SEARCH_TESTS,
  GET_STUDENT_TEST_RESULT,
} from "./actionTypes"
import {
  getTestsSuccess,
  getTestsFail,
  getTestDetailSuccess,
  getTestDetailFail,
  addTestFail,
  addTestSuccess,
  updateTestSuccess,
  updateTestFail,
  deleteTestSuccess,
  deleteTestFail,
  updateTestLoadingState,
  getUsersPurchasedTestsSucess,
  searchTestsSuccess,
  searchTestsFail,
  Apis,
  getStudentTestSuccess,
  getStudentTestFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTests,
  getTestsDetails,
  addNewTest,
  updateTest,
  deleteTest,
  getSubscriptionUsersByTestId,
  getStudentTestResultApi,
} from "helpers/api/testApi"

import { dynamicSearch } from "helpers/api/dynamicApi"

function* fetchTests({ params }) {
  try {
    const { page, limit } = params
    let queryParams = `?page=${page}&limit=${limit}`
    if (params?.search && params?.search?.length) {
      queryParams = queryParams + `&search=${params?.search}`
    }
    yield put(updateTestLoadingState(true))
    const response = yield call(getTests, queryParams)
    yield put(getTestsSuccess(response.data.testList))
    yield put(updateTestLoadingState(false))
  } catch (error) {
    yield put(updateTestLoadingState(false))
    yield put(getTestsFail(error))
  }
}

function* fetchTestDetail({ testId }) {
  try {
    yield put(updateTestLoadingState(true))
    const response = yield call(getTestsDetails, testId)
    yield put(getTestDetailSuccess(response.data.data))
    yield put(updateTestLoadingState(false))
  } catch (error) {
    yield put(getTestDetailFail(error))
  }
}

function* onUpdateTest({ payload: { test, testId, history, route } }) {
  try {
    const response = yield call(updateTest, test, testId)
    yield put(updateTestSuccess({ ...test, ...response.data.data }))
    toastr.success("Test updated successfully..!!")
    if (response?.data?.data?.role === "certificate") {
      setTimeout(() => {
        history(`/courses-edit/${response?.data?.data?.courseId}`)
      }, 1500)
    }
    if (route === "tests-edit") {
      setTimeout(() => {
        history(`/tests-list`)
      }, 1500)
    }
  } catch (error) {
    yield put(updateTestFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteTest({ payload }) {
  const { testId, params } = payload
  try {
    const { page, limit } = params
    const queryParams = `?page=${page}&limit=${limit}`
    const response = yield call(deleteTest, testId)
    toastr.success("Test deleted successfully..!!")
    let res
    if (params) {
      res = yield call(getTests, queryParams)
    } else {
      res = yield call(getTests)
    }
    yield put(deleteTestSuccess(response.data))
    yield put(getTestsSuccess(res.data.testList))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteTestFail(error))
  }
}

function* onAddNewTest({ payload }) {
  const { test, history, route = "test" } = payload
  try {
    yield put(updateTestLoadingState(true))
    const response = yield call(addNewTest, test)
    yield put(addTestSuccess(response.data.data))
    yield put(updateTestLoadingState(false))
    setTimeout(() => {
      if (test.chapterId || (test.courseId && route === "chapter")) {
        history(`/questions-create/chapter/${response.data.data._id}/1`)
      } else if (route === "test-create") {
        history(`/questions-create/${route}/${response.data.data._id}/1`)
      } else if (route === "certificate") {
        history(`/questions-create/${route}/${response.data.data._id}/1`)
      } else if (route === "tests-edit") {
        history(`/tests-edit/${response.data.data._id}`)
      } else if (route === "certificate-test-edit") {
        history(`/certificate-test-edit/${response.data.data._id}`)
      } else {
        history(`/questions-create/test/${response.data.data._id}/1`)
      }
    }, 1500)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateTestLoadingState(false))
    yield put(addTestFail(error))
  }
}

function* fetchSubscriptonsUsers({ payload: { testId, params } }) {
  try {
    // yield put(updateTestLoadingState(true))
    const { page, limit } = params
    let queryParams = `?page=${page}&limit=${limit}`
    const response = yield call(
      getSubscriptionUsersByTestId,
      testId,
      queryParams
    )
    yield put(getUsersPurchasedTestsSucess(response.data.tests))
    // yield put(updateTestLoadingState(false))
  } catch (error) {
    toastr.error(error.response.data.message)
  }
}

function* searchTests({ params }) {
  try {
    const { type, slug } = params
    let queryParams = `?type=${type}&slug=${slug}`
    const response = yield call(dynamicSearch, queryParams)
    yield put(searchTestsSuccess(response.data))
  } catch (error) {
    yield put(searchTestsFail(error))
  }
}

function* fetchStudentTestsResult({ params }) {
  try {
    const { testId, studentId, resultId } = params
    let queryParams = `?testId=${testId}&studentId=${studentId}&resultId=${resultId}&getData=all`
    if (params?.search && params?.search?.length) {
      queryParams = queryParams + `&search=${params?.search}`
    }
    yield put(updateTestLoadingState(true))
    const response = yield call(getStudentTestResultApi, queryParams)
    yield put(getStudentTestSuccess(response?.data?.optionDetail))
    yield put(updateTestLoadingState(false))
  } catch (error) {
    yield put(updateTestLoadingState(false))
    yield put(getStudentTestFail(error))
  }
}

function* testsSaga() {
  yield takeEvery(GET_TESTS, fetchTests)
  yield takeEvery(GET_TEST_DETAIL, fetchTestDetail)
  yield takeEvery(ADD_NEW_TEST, onAddNewTest)
  yield takeEvery(UPDATE_TEST, onUpdateTest)
  yield takeEvery(DELETE_TEST, onDeleteTest)
  yield takeEvery(GET_USERS_PURCHASED_TESTS, fetchSubscriptonsUsers)
  yield takeEvery(SEARCH_TESTS, searchTests),
    yield takeEvery(GET_STUDENT_TEST_RESULT, fetchStudentTestsResult)
}

export default testsSaga
