import { del, get, post, patch } from "../api_helper"
import { HELP_CENTER_ROUTES } from "../url_helper"

// Get Help Center Items
export const getHelpCenterApi = (queryParams) =>
  get(`${HELP_CENTER_ROUTES}${queryParams}`)

export const addNewHelpCenter = data =>
  post(`${HELP_CENTER_ROUTES}`, data)

