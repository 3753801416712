import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Container } from "reactstrap"
import {
  getPackageDetail as onGetPackageDetail,
  resetPackages as onResetPackage,
} from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AppForm from "../../components/Common/Form"
import { getPackageForm, handleSubmit } from "./Utills"
import AppLoader from "../../components/Common/Loader"

const ConfigurePackage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [isEdit, setIsEdit] = useState(false)

  document.title = `${isEdit ? "Edit Plan" : "Create Plan"} | QAPRENEUR`

  const { packageDetail, loading } = useSelector(state => ({
    packageDetail: state.packages?.packageDetail,
    loading: state.packages?.loading,
  }))

  useEffect(() => {
    const currentLocation = location.pathname
    const status = currentLocation.includes("edit-package")
    setIsEdit(status)
    if (status && params?.packageId)
      dispatch(onGetPackageDetail(params.packageId))
    return () => dispatch(onResetPackage())
  }, [])

  const handleFormSubmit = (formikValues, formik) => {
    handleSubmit(formikValues, formik, isEdit, params, dispatch, navigate)
  }

  const formFields = getPackageForm(params.role)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Package"
            navigate="/packages/student"
            breadcrumbItem={`${isEdit ? "Edit" : "Create"} ${
              params.role
            } packages`}
          />
          {loading && <AppLoader />}
          {!loading && (
            <Card>
              <CardBody>
                <AppForm
                  name=""
                  layoutType={isEdit ? "UPDATE" : "CREATE"}
                  fields={formFields}
                  initialValues={{
                    name: packageDetail?.name ? packageDetail?.name : "",
                    price: packageDetail?.price
                      ? packageDetail?.price + ""
                      : "",
                    discountPrice: packageDetail?.discountPrice
                      ? packageDetail?.discountPrice + ""
                      : "0",
                    leads: packageDetail?.leads
                      ? packageDetail?.leads + ""
                      : "",
                    limit: packageDetail?.leads
                      ? packageDetail[packageDetail?.leads] + ""
                      : 0,
                  }}
                  handleFormSubmit={(formikValues, formik) => {
                    handleFormSubmit(formikValues, formik)
                  }}
                  cancelRoute={`/packages/${params?.role}`}
                />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfigurePackage
