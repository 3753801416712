import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Container } from "reactstrap"
import {
  getNotificationDetail as onGetNotificationDetail,
  resetNotifications as onResetNotifications,
} from "../../store/notification/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AppForm from "../../components/Common/Form"
import { handleSubmit } from "./Utills"
import { formFields } from "./constants" // Assuming you have notificationColumns defined
import AppLoader from "../../components/Common/Loader"

const ConfigureNotification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [isEdit, setIsEdit] = useState(false)

  document.title = `${
    isEdit ? "Edit Notification" : "Create Notification"
  } | QAPRENEUR`

  const { notificationDetail, loading } = useSelector(state => ({
    notificationDetail: state?.notifications?.notificationDetail,
    loading: state?.notifications?.loading,
  }))

  useEffect(() => {
    const currentLocation = location.pathname
    const status = currentLocation.includes("edit-notification")
    setIsEdit(status)
    if (status && params?.notificationId)
      dispatch(onGetNotificationDetail(params.notificationId))
    return () => dispatch(onResetNotifications())
  }, [])

 
  const initData = notificationDetail
    ? {
        ...notificationDetail,
      }
    : { message: "", title: "" }

  return (
    <React.Fragment>
      <div className="page-content">
        work in progress...
        {/* <Container fluid>
          <Breadcrumbs
            title="notifications"
            navigate="/notification"
            breadcrumbItem={`${
              isEdit ? "Edit Notification" : "Create Notification"
            }`}
          />
          {loading && <AppLoader />}
          {!loading && (
            <Card>
              <CardBody>
                <AppForm
                  name=""
                  layoutType={isEdit ? "UPDATE" : "CREATE"}
                  fields={formFields}
                  initialValues={{ ...initData }}
                  handleFormSubmit={(formikValues, formik) =>
                    handleSubmit(
                      formikValues,
                      formik,
                      initData,
                      isEdit,
                      dispatch,
                      navigate
                    )
                  }
                />
              </CardBody>
            </Card>
          )}
        </Container> */}
      </div>
    </React.Fragment>
  )
}

export default ConfigureNotification
