import { del, get, post, patch } from "../api_helper"
import { ANNOUNCEMENT_ROUTES } from "../url_helper"

// dynamic search

export const createAnnouncement = announcement =>
  post(`${ANNOUNCEMENT_ROUTES}`, announcement, {
    headers: { "Content-Type": "application/json" },
  })

export const updateAnnouncement = (announcement, announcementId) =>
  patch(`${ANNOUNCEMENT_ROUTES}/${announcementId}`, announcement, {
    headers: { "Content-Type": "application/json" },
  })

export const getAnnouncementDetails = announcementId =>
  get(`${ANNOUNCEMENT_ROUTES}/${announcementId}`, {
    headers: { "Content-Type": "application/json" },
  })

export const deleteAnnouncement = announcementId =>
  del(`${ANNOUNCEMENT_ROUTES}/${announcementId}`, {
    headers: { "Content-Type": "application/json" },
  })

export const getAnnouncements = queryParams =>
  get(`${ANNOUNCEMENT_ROUTES}${queryParams}`)

export const getAnnouncementReports = announcementId =>
  get(`${ANNOUNCEMENT_ROUTES}/report?announcementId=${announcementId}`)
