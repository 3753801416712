import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import moment from "moment"
import curve01 from "../../../assets/images/plan/curve01.svg"
import curve02 from "../../../assets/images/plan/curve02.svg"

function Index({
  SubscriptionPlan,
  isActive = false,
  handlePlan,
  showDesc = true,
  showUpgradeButton = false,
  showExpiry = false,
}) {
  const navigate = useNavigate()
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleUpgradePlan = () => {
    setButtonDisabled(true) // Disable the button when clicked

    // Your upgrade plan logic here
    if (handlePlan) {
      handlePlan(SubscriptionPlan)
    }
  }

  const amount = SubscriptionPlan?.price || 0

  const expirationDate = moment(SubscriptionPlan?.activePlan?.expiresAt)
  const today = moment()
  const daysLeft = expirationDate.diff(today, "days")

  const plan_type = SubscriptionPlan?.type || "Basic"

  return (
    <div className={`plan_information`}>
      <div
        className={`plan_info position-relative ${
          isActive ? "active_plan" : "upgrade_plan"
        }`}
      >
        {isActive ? (
          <img src={curve02} className="plan_curve" />
        ) : (
          <img src={curve01} className="plan_curve" />
        )}
        <div className="d-flex justify-content-between align-items-center">
          <p
            className={`${
              plan_type === "Basic" ? "lbl_activeplan" : "lbl_defaultplan"
            }`}
          >
            {isActive ? "Active Plan" : SubscriptionPlan?.name}
          </p>
          <div
            className="plan_price"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "0px 15px",
            }}
          >
            <label className="d-flex flex-column align-items-end">
              {SubscriptionPlan?.type === "Basic"
                ? "Free"
                : `₹${amount}${
                    SubscriptionPlan?.type === "Monthly" ? "/Month" : "/Year"
                  }`}
              <span className="plan_slug">Plan</span>
            </label>
          </div>
        </div>
        <h4 className="plan_type fw-bold fs-2 px-3">
          {isActive ? SubscriptionPlan?.name : ""}
        </h4>
        <div className="px-3 pt-3 pb-2">
          <div className="d-flex align-items-end justify-content-between">
            {showDesc && (
              <p className="plan_desc">
                {SubscriptionPlan?.description
                  ?.split("\n")
                  .map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            )}
            {isActive && showExpiry && plan_type !== "Basic" && (
              <p className="plan_expiry">{daysLeft} Days Left</p>
            )}
            {showUpgradeButton && handlePlan  && (
              <button
                className="btn-sm text-light p-2 position-absolute"
                style={{
                  backgroundColor: "#e39a64",
                  border: "none",
                  outline: "none",
                  right:20,
                  zIndex:2
                }}
                onClick={handleUpgradePlan}
                disabled={buttonDisabled}
              >
                Upgrade Plan
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
