/* PLAN */
export const GET_PLAN = "GET_PLAN"
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS"
export const GET_PLAN_FAIL = "GET_PLAN_FAIL"

/* PLAN DETAIL */
export const GET_PLAN_DETAIL = "GET_PLAN_DETAIL"
export const GET_PLAN_DETAIL_SUCCESS = "GET_PLAN_DETAIL_SUCCESS"
export const GET_PLAN_DETAIL_FAIL = "GET_PLAN_DETAIL_FAIL"

/**
 * Add plan
 */
export const ADD_NEW_PLAN = "ADD_NEW_PLAN"
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS"
export const ADD_PLAN_FAIL = "ADD_PLAN_FAIL"

/**
 * Edit plan
 */
export const UPDATE_PLAN = "UPDATE_PLAN"
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS"
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL"

/**
 * Delete plan
 */
export const DELETE_PLAN = "DELETE_PLAN"
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS"
export const DELETE_PLAN_FAIL = "DELETE_PLAN_FAIL"

export const UPDATE_PLAN_LOADING_STATE = "UPDATE_PLAN_LOADING_STATE"

/* RESET PLAN */
export const RESET_PLAN_REPORT = "RESET_PLAN_REPORT"
export const RESET_PLAN_REPORT_SUCCESS = "RESET_PLAN_REPORT_SUCCESS"
export const RESET_PLAN = "RESET_PLAN"

/* PLAN COMPANY DETAIL */
export const GET_COMPANY_PLAN_DETAIL = "GET_COMPANY_PLAN_DETAIL"
export const GET_COMPANY_PLAN_DETAIL_SUCCESS = "GET_COMPANY_PLAN_DETAIL_SUCCESS"
export const GET_COMPANY_PLAN_DETAIL_FAIL = "GET_COMPANY_PLAN_DETAIL_FAIL"

/* GET MOCK TEST PRICE  DETAIL */
export const GET_MOCK_TEST_PRICE_DETAIL = "GET_MOCK_TEST_PRICE_DETAIL"
export const GET_MOCK_TEST_PRICE_DETAIL_SUCCESS =
  "GET_MOCK_TEST_PRICE_DETAIL_SUCCESS"
export const GET_MOCK_TEST_PRICE_DETAIL_FAIL = "GET_MOCK_TEST_PRICE_DETAIL_FAIL"

/* UPDATE MOCK TEST PRICE DETAIL */
export const UPDATE_MOCK_TEST_PRICE_DETAIL = "UPDATE_MOCK_TEST_PRICE_DETAIL"
export const UPDATE_MOCK_TEST_PRICE_DETAIL_SUCCESS =
  "UPDATE_MOCK_TEST_PRICE_DETAIL_SUCCESS"
export const UPDATE_MOCK_TEST_PRICE_DETAIL_FAIL =
  "UPDATE_MOCK_TEST_PRICE_DETAIL_FAIL"
