import React, { useState, useEffect } from "react"
import { Card } from "reactstrap"
import moment from "moment"
//redux
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import AppForm from "../../components/Common/Form/index"
// actions
import {
  updateCompany as onUpdateCompany,
  getCompanyDetail as onGetCompanyDetail,
} from "../../store/companies/actions"
import { handleFormSubmit } from "./companyUtills"
import CardAbout from "../../components/Common/Card/CardAbout"

const CompanyAboutTab = ({ user }) => {
  //meta title
  const params = useParams()
  const dispatch = useDispatch()
  const [showEdit, setShowEdit] = useState(true)
  const { companyDetail, error, loading } = useSelector(state => ({
    error: state.companies.error,
    loading: state.companies.loading,
    companyDetail: state.companies?.companyDetail,
  }))

  useEffect(() => {
    if (params.companyId) {
      dispatch(onGetCompanyDetail(params.companyId, "company"))
    } else {
      dispatch(onGetCompanyDetail(params.companyId, "users/me"))
    }
  }, [dispatch, onGetCompanyDetail])

  const handleSubmit = (formikValues, formik) => {
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        const data = {
          extra: {
            about: formikValues.about,
          },
        }
        dispatch(onUpdateCompany("users/me", data, companyDetail._id, ""))
        setShowEdit(true)
      } else {
        const touchedFields = {}
        Object.keys(errors).forEach(field => {
          touchedFields[field] = true
        })
        formik.setTouched(touchedFields)
        console.log("Validation errors:", errors)
      }
    })
  }

  const establishmentDate = companyDetail?.extra?.establishment
  const formattedDate = establishmentDate
    ? moment(establishmentDate).format("YYYY-MM-DD")
    : ""

  const handleCancel = () => {
    setShowEdit(true)
  }
  return (
    <div>
      {showEdit && (
        <>
          <CardAbout
            title="About Company"
            descripion={companyDetail?.about || companyDetail?.extra?.about}
            isHtml={true}
            onClick={() => setShowEdit(false)}
            isButton="Edit"
            BtnTitle="Edit"
          />
        </>
      )}
      {!showEdit && (
        <>
          <Card style={{ borderRadius: 15 }}>
            <div className="pt-3 px-2">
              <AppForm
                name="About Company"
                layoutType="UPDATE"
                fields={[
                  {
                    name: "about",
                    label: "",
                    type: "textEditor",
                    placeholder: "Enter Email",
                    required: true,
                    lg: 12,
                  },
                ]}
                initialValues={
                  companyDetail
                    ? {
                        about: companyDetail?.about || companyDetail?.extra?.about,
                      }
                    : {}
                }
                handleFormSubmit={handleSubmit}
                saveTitle="Save"
                flex="end"
                navigateToCancelButton={false}
                handleCancel={handleCancel}
              />
            </div>
          </Card>
        </>
      )}
    </div>
  )
}

export default CompanyAboutTab
