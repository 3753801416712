import React, { useRef, useContext } from "react"
import { AppContext } from "../../context/AppContext"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import AppLoader from "../../components/Common/Loader"

const SidebarContent = props => {
  const ref = useRef()
  const authRole = localStorage.getItem("authRole")
  const path = useLocation()
  const { loading, sideBarLayout } = useContext(AppContext)

  const sidebarLayoutRender = Array.isArray(sideBarLayout) ? sideBarLayout : [] //dynamic
  // const sidebarLayoutRender = authRole && userId ? getSidebarLayoutRender(authRole, userId) : [] //static
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className={path.pathname === "/dashboard" ? "mm-active" : ""}>
              <Link
                to="/dashboard"
                className={path.pathname === "/dashboard" ? "active" : ""}
              >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>
            {loading && <AppLoader />}
            {["admin", "company", "hr_user", "college", "coaching"].includes(
              authRole
            ) &&
              sidebarLayoutRender.length > 0 &&
              sidebarLayoutRender.map((item, key) => {
                return (
                  <li
                    key={key}
                    className={path.pathname === item.link ? "mm-active" : ""}
                  >
                    <Link
                      to={item.link}
                      // onClick={() => handleMenuItemClick(item.link)}
                      className={path.pathname === item.link ? "active" : ""}
                    >
                      {item.class.includes("bx") ? (
                        <i className={`bx ${item.class}`}></i>
                      ) : (
                        <i
                          className={`${item.class}`}
                          style={{ fontSize: 16 }}
                        ></i>
                      )}
                      <span>{props.t(`${item.lable}`)}</span>
                    </Link>
                  </li>
                )
              })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
