import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SUBSCRIPTIONS } from "./actionTypes"
import { getSubscriptionsSuccess, getSubscriptionsFail } from "./actions"

// Include Both Helper File with needed methods
import { getSubscriptions } from "helpers/api/subscriptionsApi"
import { dynamicSearch } from "helpers/api/dynamicApi"



function* fetchSubscriptions({ params }) {
  try {
    const { type, slug } = params
    let queryParams = `?type=${type}&slug=${slug}`
    const response = yield call(dynamicSearch,queryParams)
    yield put(getSubscriptionsSuccess(response.data))
  } catch (error) {
    yield put(getSubscriptionsFail(error))
  }
}

function* subscriptionsSaga() {
  yield takeEvery(GET_SUBSCRIPTIONS, fetchSubscriptions)
}

export default subscriptionsSaga
