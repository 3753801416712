import {
  GET_JOBS_REFERRALS_FAIL,
  GET_JOBS_REFERRALS_SUCCESS,
  UPDATE_JOB_REFERRALS_LOADING_STATE,
} from "./actionTypes"

const INIT_STATE = {
  JobReferrals: [],
  error: {},
  loading: false,
}

const jobsReferrals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOBS_REFERRALS_SUCCESS:
      return {
        ...state,
        JobReferrals: action.payload,
      }
    case GET_JOBS_REFERRALS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_JOB_REFERRALS_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default jobsReferrals
