import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Col, Container, Row, Spinner } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"

import {
  getUsersPurchasedTests as onGetUsersPurchasedTests,
  getTestDetail as onGetTestDetail,
  resetUsersPurchasedTests as onResetUsersPurchasedTests,
  resetTests as onResetTests,
} from "../../store/actions"
import { purchasedTestUsersColumns } from "./constants"

const PurchasedTestUsers = () => {
  document.title = "Course List | QAPRENEUR"
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)

  const { loading, usersPurchasedTests, testDetail } = useSelector(state => ({
    loading: state.tests.loading,
    usersPurchasedTests: state.tests.usersPurchasedTests,
    testDetail: state.tests.testDetail
  }))

  useEffect(() => {
    if (params.id) {
      const queryParams = {
        page: pageNo,
        limit: rowPerPage,
      }
      dispatch(onGetUsersPurchasedTests(params.id, queryParams))
      dispatch(onGetTestDetail(params.id))
    }
    return () => {
      dispatch(onResetUsersPurchasedTests())
      dispatch(onResetTests())
      setIsLoading(true)
    }
  }, [dispatch, pageNo, rowPerPage])

  useEffect(() => {
    if (Array.isArray(usersPurchasedTests)) {
      setIsLoading(false)
    }
  }, [usersPurchasedTests])

  const flattenedData =
    Array.isArray(usersPurchasedTests) && usersPurchasedTests.length
      ? usersPurchasedTests.map(item => {
        delete item.result['_id']
        return {
          name: item.user.name,
          email: item.user.email,
          _id : item.user._id,
          ...item.result,
          questionAttemptedCount:
            Number(item?.result?.wrongAnswer) +
            Number(item?.result?.correctAnswer),
          score: `${Math.floor(Number(item?.result?.score))}%`
        }
      })
      : []

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Breadcrumbs
                title="Tests"
                navigate="/tests-list"
                breadcrumbItem={testDetail?.name}
              />
              <div>
                {isLoading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner className="ms-2" color="dark" size="sm" />
                  </div>
                ) : (
                  <>
                    <TableContainer
                      loading={loading}
                      data={flattenedData}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isJobListGlobalFilter={false}
                      customPageSize={10}
                      className="project-list-table table-nowrap align-middle table-borderless"
                      addButton={{
                        onclick: () => navigate(-1),
                        title: "Back",
                        back: true,
                      }}
                      onChangePagination={onChangePagination}
                      showPagination={true}
                      columns={purchasedTestUsersColumns}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PurchasedTestUsers
