import {
  GET_HELP_CENTER_SUCCESS,
  GET_HELP_CENTER_FAIL,
  ADD_HELP_CENTER_SUCCESS,
  ADD_HELP_CENTER_FAIL,
  UPDATE_HELP_CENTER_SUCCESS,
  UPDATE_HELP_CENTER_FAIL,
  DELETE_HELP_CENTER_SUCCESS,
  DELETE_HELP_CENTER_FAIL,
  RESET_HELP_CENTER,
  UPDATE_HELP_CENTER_LOADING_STATE,
} from "./actionTypes"

const INIT_STATE = {
  helpCenter: [],
  helpCenterDetail: {},
  error: {},
  loading: false,
}

const helpCenter = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HELP_CENTER_SUCCESS:
      return {
        ...state,
        helpCenter: action.payload,
      }

    case GET_HELP_CENTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_HELP_CENTER_SUCCESS:
      return {
        ...state,
        helpCenter: [...state.helpCenter, action.payload],
      }

    case ADD_HELP_CENTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_HELP_CENTER:
      return {
        helpCenter: [],
        helpCenterDetail: {},
        error: {},
        loading: false,
      }

    case UPDATE_HELP_CENTER_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    default:
      return state
  }
}

export default helpCenter
