import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import TableContainer from "../../components/Common/Table/index"
import { testColumns } from "./Utills"
import { useDispatch } from "react-redux"
import { getUserTabData as onGetUserTabData } from "../../store/students/actions"

function StudentTestResults({
  testResults=[],
  loading,
  userId,
  handleActions,
  role = "",
}) {
  const testColumn = testColumns(role)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [data, setData] = useState([])
  const tabId = "TESTRESULTS"
  const dispatch = useDispatch()

  useEffect(() => {
    if (testResults && Array.isArray(testResults) && testResults.length > 0) {
      const tests = testResults
        .map(item => {
          return {
            ...item,
            ...item?.testDetail,
            score: `${Math.floor(item?.score)}%`,
            testName: item?.testDetail?.name,
            testAttemptId: item?._id,
          }
        })
        .filter(t => t.role !== "certificate")

      const filteredData =
        tests &&
        Object.values(
          tests.reduce((acc, obj) => {
            const key = obj.testId + "|" + obj.studentId
            if (!acc[key] || obj.userTestAttempt > acc[key].userTestAttempt) {
              acc[key] = obj
            }
            return acc
          }, {})
        ).filter(t => t.visibility === 1)

      setData(role === "admin" ? tests : filteredData)
    }
  }, [testResults])

  const onChangePagination = (limit, page) => {
    setRowPerPage(rowPerPage)
    setPageNo(page)
    if (userId) {
      dispatch(onGetUserTabData(tabId, userId, `&page=${page}&limit=${limit}`))
    }
  }

  return (
    <>
      <Card style={{ borderRadius: 15 }}>
        <CardBody>
          <div className="pt-3 px-2">
            <h4 className="sun_heading">Tests Result</h4>
            {testResults && Array.isArray(testResults) ? (
              <TableContainer
                loading={loading}
                data={data}
                isGlobalFilter={false}
                isAddOptions={false}
                isJobListGlobalFilter={false}
                showPageDropdown={false}
                handleView={data => handleActions(data, "TEST_REPORT")}
                customPageSize={rowPerPage}
                className="project-list-table table-nowrap align-middle table-borderless"
                columns={testColumn}
                tableName="viewStudent"
                showPagination={true}
                onChangePagination={onChangePagination}
              />
            ) : (
              "No Tests Attempt yet!"
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default StudentTestResults
