import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Card,
  CardBody,
  Button,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import CsvToJsonConverter from "../../components/Common/CsvToJsonConverter"
import { useFormik } from "formik"
import * as Yup from "yup"
import "react-datepicker/dist/react-datepicker.css"
import {
  getTestCategories as onGetTestCategories,
  getTestDetail as onGetTestDetail,
  addNewTest as onAddNewTest,
  updateTest as onUpdateTest,
  deleteQuestion as onDeleteQuestion,
  resetTests as onResetTests,
  addNewQuestion as onAddNewQuestion,
} from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { map } from "lodash"
import withRouter from "components/Common/withRouter"
import QuestionsCard from "../../components/Common/Card/QuestionsCard"
import AppJoditEditor from "../../components/Common/AppJoditEditor"
import MultiSelect from "../../components/Common/AppSelect/MultiSelect"
import { discountOptions } from "./constants"
const Tests = props => {
  const staticURL = process.env.REACT_APP_STATIC_URL
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false)
  const [questions, setQuestions] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [csvModal, setCsvModal] = useState(false)
  const [manualSubmit, setManualSubmit] = useState(false)

  document.title = `${isEdit ? "Edit Test" : "Create New Test"} | QAPRENEUR`

  const { testCategories, loading, testDetail } = useSelector(state => ({
    testCategories: state.testCategories.testCategories,
    loading: state.tests.loading,
    testDetail: state.tests?.testDetail,
  }))

  useEffect(() => {
    dispatch(onGetTestCategories())
  }, [dispatch])

  useEffect(() => {
    const currentLocation = props.router.location.pathname
    const status =
      currentLocation.includes("tests-edit") ||
      currentLocation.includes("certificate-test-edit")
    setIsEdit(status)
    if (status && params?.id) {
      dispatch(onGetTestDetail(params.id))
    } else {
      setIsLoading(false)
      dispatch(onResetTests())
    }
    return () => {
      dispatch(onResetTests())
      setIsLoading(false)
    }
  }, [props.router.location.pathname])

  useEffect(() => {
    if (Object.keys(testDetail).length) {
      setIsLoading(false)
    }
    if (isEdit && testDetail) {
      if (testDetail.questions) {
        const ques = testDetail.questions.map(question => {
          const que = JSON.parse(question.question)
          que.questionId = question._id
          return que
        })
        setQuestions(ques)
      }
    }
  }, [testDetail])

  function handleAcceptedFiles(files) {
    validation.setFieldValue("image", files[0])
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: testDetail?.name || "",
      // testCategoryId:
      //   testDetail?.testCategoryId?._id || testDetail?.testCategoryId || "",
      testCategoryIds:
        testDetail?.testCategories && testDetail?.testCategories.length
          ? testDetail?.testCategories.map(testCat => ({
              value: testCat._id,
              label: testCat.type,
            }))
          : [],
      duration: testDetail?.duration || "",
      description: testDetail?.description || "",
      image: testDetail?.image || "",
      passingMark: testDetail?.passingMark || "",
      status: isEdit && testDetail?.status ? testDetail?.status : "deactive",
      type: testDetail?.type || "",
      price: testDetail?.type === "paid" ? testDetail?.price : 0,
      isDiscount: [true, "true"].includes(testDetail?.isDiscount)
        ? "true"
        : "false",
      discount:
        testDetail?.type === "paid" &&
        [true, "true"].includes(testDetail?.isDiscount)
          ? testDetail?.discount
          : 0,
    },
    validationSchema: Yup.object({
      name: !params?.testType
        ? Yup.string().required("Please Enter Test Name")
        : "",
        // testCategoryIds: !params?.testType
        // ? Yup.string().required("Please Select Categories")
        // : "",
      status: !params?.testType
        ? Yup.mixed()
            .oneOf(["active", "deactive"])
            .required("Please Select Test Status")
        : "",
      duration: Yup.string().required("Please Select Test Duration"),
      passingMark: !params?.testType
        ? Yup.string()
            .required("Please Enter Passing Marks")
            .test(
              "positive",
              "Passing Marks must be a positive value",
              value => {
                const parsedValue = parseFloat(value)
                return !isNaN(parsedValue) && parsedValue >= 0
              }
            )
        : "",
      type: !params?.testType
        ? Yup.mixed()
            .oneOf(["free", "paid", "premium"])
            .required("Please Select Test Type")
        : "",
      price: !params?.testType
        ? Yup.string()
            .required("Please Enter Price")
            .test("positive", "Price must be a non-negative value", value => {
              const parsedValue = parseFloat(value)
              return !isNaN(parsedValue) && parsedValue >= 0
            })
        : "",
    }),
    onSubmit: values => {
      values.questions = questions
      const testCategoryIds =
        values?.testCategoryIds && values?.testCategoryIds.length > 0
          ? values?.testCategoryIds.map(cat => cat.value)
          : []
      if (values?.testCategoryIds) {
        delete values["testCategoryIds"]
      }

      if (isEdit) {
        if (params?.testType === "certificate-test-edit") {
          const updatestres = {
            duration: values?.duration,
            description: values?.description,
          }
          dispatch(
            onUpdateTest(
              updatestres,
              testDetail._id,
              navigate,
              "certificate-test-edit"
            )
          )
        } else {
          values.price = values.type === "free" ? 0 : values.price
          if (["free", "premium"].includes(values.type)) {
            values.isDiscount = false
            values.price = 0
            values.discount = 0
            values.discountPrice = 0
          } else if (
            values.type === "paid" &&
            [false, "false"].includes(values.isDiscount)
          ) {
            values.discount = 0
            values.discountPrice = 0
            values.isDiscount = false
          } else {
            values.isDiscount = true
            values.price = values.price
            values.discount = values.discount
            values.discountPrice = Math.round(
              values.price - values.price * (Number(values.discount) / 100)
            )
          }
          dispatch(
            onUpdateTest(
              { ...values, testCategories: testCategoryIds },
              testDetail._id,
              navigate,
              "tests-edit"
            )
          )
        }
      } else {
        if (params?.testType == "certificate") {
          const certificateValues = {
            courseId: params?.courseId,
            role: "certificate",
            type: "free",
            name: `certificate-${Date.now()}`,
          }
          if (manualSubmit) {
            dispatch(
              onAddNewTest(
                { ...values, ...certificateValues },
                navigate,
                "certificate-test-edit"
              )
            )
          } else {
            dispatch(
              onAddNewTest(
                { ...values, ...certificateValues },
                navigate,
                "certificate"
              )
            )
          }
          setManualSubmit(false)
        } else {
          if (manualSubmit) {
            dispatch(
              onAddNewTest(
                { ...values, testCategories: testCategoryIds },
                navigate,
                "tests-edit"
              )
            )
            setManualSubmit(false)
          } else {
            const modifyVal = {
              ...values,
              isDiscount: Boolean(values?.isDiscount),
              discount: Number(values?.discount),
              discountPrice: Math.round(
                values.price - values.price * (Number(values.discount) / 100)
              ),
              testCategories: testCategoryIds,
            }
            dispatch(onAddNewTest(modifyVal, navigate, "test-create"))
          }
        }
      }
    },
  })

  const handleButtonClick = (e, type, { handleSubmit, setFieldValue }) => {
    e.preventDefault()
    if (type === "draft") {
      setFieldValue("status", "deactive")
    } else {
      handleSubmit()
    }
    return false
  }

  const handleDeleteButton = id => {
    dispatch(onDeleteQuestion(id))
    dispatch(onGetTestDetail(testDetail._id))
  }

  const handleAddQuestions = e => {
    if (!isEdit) {
      handleButtonClick(e, "submit", validation)
    } else {
      const slug =
        testDetail.role === "certificate" ? "certificate" : "test-edit"
      props.router.navigate(
        `/questions-create/${slug}/${testDetail?._id}/${questions.length + 1}`
      )
    }
  }

  const filterTestCategory =
    testCategories &&
    testCategories.length > 0 &&
    testCategories.filter(testcat => {
      return testcat.status === "active"
    })

  const csvToggle = () => setCsvModal(!csvModal)

  const handleCsvToJsonConverter = res => {
    if (res?.data) {
      const csvResponse =
        res?.data.length > 0 &&
        res?.data
          .map((r, k) => {
            return {
              question: r?.title || "",
              A: r?.A || "",
              B: r?.B || "",
              C: r?.C || "",
              D: r?.D || "",
              answer: r?.answer || "",
              description: r?.description || "",
            }
          })
          .filter(q => q?.question)
      const totalQuestion = { questions: JSON.stringify(csvResponse) }
      dispatch(
        onAddNewQuestion(totalQuestion, navigate, params?.id, "no-route")
      )
      csvToggle()
      dispatch(onGetTestDetail(params.id))
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {params.testType == "certificate" ||
          params.testType == "certificate-test-edit" ? (
            <Breadcrumbs
              title="course"
              navigate={`/courses-edit/${testDetail?.courseId}`}
              breadcrumbItem={`${isEdit ? "Edit" : "Create"} Certificate Test`}
            />
          ) : (
            <Breadcrumbs
              title={"Tests"}
              navigate={"/tests-list"}
              breadcrumbItem={`${isEdit ? "Edit Test" : "Create Test"}`}
            />
          )}
          {isLoading && isEdit && (
            <div className="d-flex justify-content-center">
              <Spinner className="ms-2" color="dark" size="sm" />
            </div>
          )}
          {!isLoading && (
            <>
              <Form>
                <Row>
                  {!params?.testType && (
                    <>
                      <Col lg="12">
                        <Card>
                          <CardBody>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label htmlFor="formrow-name-Input">
                                    Test Name
                                    <span className="required_star">*</span>
                                  </Label>
                                  <Input
                                    name="name"
                                    type="text"
                                    className="form-control custom_form_control"
                                    id="formrow-name-Input"
                                    placeholder="Enter Test Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                    invalid={
                                      validation.touched.name &&
                                      validation.errors.name
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.name &&
                                  validation.errors.name ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.name}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                {/* <div className="mb-3">
                                  <Label className="form-label">
                                    Select Category
                                  </Label>
                                  <span className="required_star">*</span>

                                  <Input
                                    name="testCategoryId"
                                    type="select"
                                    className="form-select custom_form_control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.testCategoryId || ""
                                    }
                                    invalid={
                                      validation.touched.testCategoryId &&
                                      validation.errors.testCategoryId
                                        ? true
                                        : false
                                    }
                                  >
                                    <option>Select Category</option>

                                    {filterTestCategory &&
                                      map(
                                        filterTestCategory,
                                        (testCategory, index) => (
                                          <option
                                            key={index}
                                            value={testCategory._id}
                                          >
                                            {testCategory.type}
                                          </option>
                                        )
                                      )}
                                  </Input>
                                  {validation.touched.testCategoryId &&
                                  validation.errors.testCategoryId ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.testCategoryId}
                                    </FormFeedback>
                                  ) : null}
                                </div> */}
                                <div className="mb-3">
                                  <MultiSelect
                                    name="testCategoryIds"
                                    label={"Select Categories"}
                                    options={
                                      filterTestCategory
                                        ? filterTestCategory.map(cat => ({
                                            value: cat._id,
                                            label: cat?.type,
                                          }))
                                        : []
                                    }
                                    selectedOptions={
                                      validation.values.testCategoryIds || []
                                    }
                                    onChange={selected => {
                                      validation.setFieldValue(
                                        "testCategoryIds",
                                        selected
                                      )
                                      validation.setFieldError({
                                        testCategoryIds: "",
                                      })
                                    }}
                                    error={validation.errors?.testCategoryIds}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched?.testCategoryIds &&
                                      validation.errors?.testCategoryIds
                                    }
                                  />
                                </div>
                                <div>
                                  <Label>
                                    Duration( minutes)
                                    <span className="required_star">*</span>
                                  </Label>
                                  <Input
                                    name="duration"
                                    id="status1"
                                    type="select"
                                    className="form-select custom_form_control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.duration || ""}
                                    invalid={
                                      validation.touched.duration &&
                                      validation.errors.duration
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">Choose </option>
                                    <option value="30">30</option>
                                    <option value="45">45</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                  </Input>
                                  {validation.touched.duration &&
                                  validation.errors.duration ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.duration}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                  <label className="control-label">
                                    Upload Image
                                  </label>
                                  <Dropzone
                                    maxFiles={1}
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(acceptedFiles)
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick mt-2"
                                          {...getRootProps()}
                                        >
                                          <input
                                            name="image"
                                            {...getInputProps()}
                                          />
                                          <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                          </div>
                                          <h4>
                                            Drop files here or click to upload.
                                          </h4>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews mt-3"
                                    id="file-previews"
                                  >
                                    {selectedFiles.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </Col>
                                              <Col>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>
                                                    {f.formattedSize}
                                                  </strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      )
                                    })}

                                    {testDetail?.image &&
                                      selectedFiles.length == 0 && (
                                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  src={
                                                    staticURL + testDetail.image
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <label className="control-label">
                                  Description
                                </label>
                                <AppJoditEditor
                                  name="description"
                                  value={validation.values.description}
                                  onChange={(name, data) => {
                                    validation.setFieldValue(
                                      "description",
                                      data
                                    )
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3 mt-3">
                                  <Label htmlFor="formrow-passingMark-Input">
                                    Passing Marks
                                    <span className="required_star">*</span>
                                  </Label>
                                  <Input
                                    name="passingMark"
                                    type="Number"
                                    className="form-control custom_form_control"
                                    id="formrow-passingMark-Input"
                                    placeholder="Enter Passing Marks"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.passingMark || ""}
                                    invalid={
                                      validation.touched.passingMark &&
                                      validation.errors.passingMark
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.passingMark &&
                                  validation.errors.passingMark ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.passingMark}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3 mt-3">
                                  <Label
                                    htmlFor="status-input"
                                    className="form-label"
                                  >
                                    Select Status
                                  </Label>
                                  <Input
                                    name="status"
                                    id="status-input"
                                    type="select"
                                    className="form-select custom_form_control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.status || ""}
                                    invalid={
                                      validation.touched.status &&
                                      validation.errors.status
                                        ? true
                                        : false
                                    }
                                  >
                                    <option>Choose Status</option>
                                    <option value="active">Active</option>
                                    <option value="deactive">Deactive</option>
                                  </Input>
                                  {validation.touched.status &&
                                  validation.errors.status ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.status}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3 mt-3">
                                  <Label
                                    htmlFor="status-input"
                                    className="form-label"
                                  >
                                    Select Test Type
                                  </Label>
                                  <Input
                                    name="type"
                                    id="status-input"
                                    type="select"
                                    className="form-select custom_form_control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.type || ""}
                                    invalid={
                                      validation.touched.type &&
                                      validation.errors.type
                                        ? true
                                        : false
                                    }
                                  >
                                    <option>Choose Test Type</option>
                                    <option value="free">Free</option>
                                    <option value="paid">Paid</option>
                                    <option value="premium">Premium</option>
                                  </Input>
                                  {validation.touched.type &&
                                  validation.errors.type ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.type}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              {validation.values.type === "paid" ? (
                                <Col lg="6">
                                  <div className="mb-3 mt-3">
                                    <Label htmlFor="formrow-passingMark-Input">
                                      Test Price
                                      <span className="required_star">*</span>
                                    </Label>
                                    <Input
                                      name="price"
                                      type="Number"
                                      className="form-control custom_form_control"
                                      id="formrow-passingMark-Input"
                                      placeholder="Enter Test Price"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.price || ""}
                                      invalid={
                                        validation.touched.price &&
                                        validation.errors.price
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.price &&
                                    validation.errors.price ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.price}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                            <Row>
                              {validation.values.type === "paid" ? (
                                <Col lg="6">
                                  <div className="mb-3 mt-3">
                                    <Label
                                      htmlFor="status-input"
                                      className="form-label"
                                    >
                                      Give Discount
                                    </Label>
                                    <Input
                                      name="isDiscount"
                                      id="status-input"
                                      type="select"
                                      className="form-select custom_form_control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.isDiscount || ""}
                                      invalid={
                                        validation.touched.isDiscount &&
                                        validation.errors.isDiscount
                                          ? true
                                          : false
                                      }
                                    >
                                      <option>Choose Test Discount</option>
                                      <option value={true}>Yes</option>
                                      <option value={false}>No</option>
                                    </Input>
                                    {validation.touched.isDiscount &&
                                    validation.errors.isDiscount ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.isDiscount}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {[true, "true"].includes(
                                validation.values.isDiscount
                              ) && validation.values.type === "paid" ? (
                                <Col lg="6">
                                  <div className="mb-3 mt-3">
                                    <Label
                                      htmlFor="status-input"
                                      className="form-label"
                                    >
                                      Discount (%)
                                    </Label>
                                    <Input
                                      name="discount"
                                      id="status-input"
                                      type="select"
                                      className="form-select custom_form_control"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.discount || ""}
                                      invalid={
                                        validation.touched.discount &&
                                        validation.errors.discount
                                          ? true
                                          : false
                                      }
                                    >
                                      <option>Choose Discount</option>
                                      {discountOptions &&
                                        discountOptions.map((DiOp, k) => {
                                          return (
                                            <option value={DiOp.value} key={k}>
                                              {DiOp.label}
                                            </option>
                                          )
                                        })}
                                    </Input>
                                    <h6 className="text-dark font-weight-bold mt-2">
                                      Discount Price :{" "}
                                      {validation.values.price -
                                        validation.values.price *
                                          (Number(validation.values.discount) /
                                            100)}
                                    </h6>
                                    {validation.touched.isDiscount &&
                                    validation.errors.isDiscount ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.isDiscount}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  )}

                  {/* certificate test form */}
                  {params?.testType == "certificate" ||
                  params?.testType == "certificate-test-edit" ? (
                    <>
                      <Col lg="12">
                        <Card>
                          <CardBody>
                            <Row>
                              {/* <Col lg="6">
                              <div className="mb-3">
                                <Label htmlFor="formrow-name-Input">
                                  Test Name<span className="required_star">*</span>
                                </Label>
                                <Input
                                  name="name"
                                  type="text"
                                  className="form-control custom_form_control"
                                  id="formrow-name-Input"
                                  placeholder="Enter Test Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name && validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.name && validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col> */}
                              <Col lg="6">
                                <div>
                                  <Label>
                                    Duration( minutes)
                                    <span className="required_star">*</span>
                                  </Label>
                                  <Input
                                    name="duration"
                                    id="status1"
                                    type="select"
                                    className="form-select custom_form_control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.duration || ""}
                                    invalid={
                                      validation.touched.duration &&
                                      validation.errors.duration
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">Choose </option>
                                    <option value="30">30</option>
                                    <option value="45">45</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                  </Input>
                                  {validation.touched.duration &&
                                  validation.errors.duration ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.duration}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <label className="control-label">
                                  Description
                                </label>
                                <AppJoditEditor
                                  name="description"
                                  value={validation.values.description}
                                  onChange={(name, data) => {
                                    validation.setFieldValue(
                                      "description",
                                      data
                                    )
                                  }}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  {/* certificate test form */}
                  <Col lg={12}>
                    <QuestionsCard
                      title="Question List"
                      questions={questions}
                      handleDeleteButton={handleDeleteButton}
                      showEdit={true}
                      handleAddQuestions={handleAddQuestions}
                      // isCsvToJsonConverter={(e) => {
                      //   if (!isEdit) {
                      //     setManualSubmit(true)
                      //     handleButtonClick(e, "submit", validation);
                      //   } else {
                      //     csvToggle();
                      //   }
                      // }}
                      {...(isEdit
                        ? {
                            isCsvToJsonConverter: e => {
                              if (!isEdit) {
                                setManualSubmit(true)
                                handleButtonClick(e, "submit", validation)
                              } else {
                                csvToggle()
                              }
                            },
                          }
                        : {})}
                      loading={loading}
                    />
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col
                        className="justify-content-center d-flex"
                        xs="8"
                        sm={6}
                        md={4}
                      >
                        <Button
                          onClick={e =>
                            handleButtonClick(e, "submit", validation)
                          }
                          type="button"
                          color="primary"
                          disabled={loading}
                          className="global_btn"
                        >
                          <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
                          {loading ? (
                            <Spinner className="ms-2" color="light" size="sm" />
                          ) : isEdit ? (
                            "Save Test"
                          ) : (
                            "Create Test"
                          )}
                        </Button>
                        {!isEdit && (
                          <Button
                            onClick={e =>
                              handleButtonClick(e, "draft", validation)
                            }
                            type="button"
                            color=""
                            disabled={loading}
                            className="ms-4 btn btn-outline-secondary"
                          >
                            <i className="bx bx-save font-size-16 align-middle me-2"></i>
                            {loading ? (
                              <Spinner
                                className="ms-2"
                                color="light"
                                size="sm"
                              />
                            ) : (
                              "Save As Draft"
                            )}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            </>
          )}
          {csvModal && (
            <InfomationModal
              modalBackdrop={csvModal}
              setmodalBackdrop={setCsvModal}
              title={"Import questions"}
            >
              <Row>
                <CsvToJsonConverter
                  handleCsvToJsonConverter={handleCsvToJsonConverter}
                />
              </Row>
            </InfomationModal>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Tests)

Tests.propTypes = {
  history: PropTypes.object,
}
