import React from "react"
import { Link } from "react-router-dom"
import { Col, Card, CardBody } from "reactstrap"

function Widgets({ widgetReports }) {
  const staticURL = process.env.REACT_APP_STATIC_URL
  return (
    <>
      {widgetReports &&
        widgetReports.map((widget, key) => (
          <Col md={widget?.md ? widget?.md : 3} key={"_col_" + key}>
            <Card className="border border-3">
              <CardBody className="d-flex flex-column align-items-center justify-content-between">
                <img
                  src={staticURL + widget.icon}
                  alt={widget.title}
                  className="widget-sm mb-1"
                />
                <div className="text-center">
                  <Link
                    to={widget?.link}
                    className="font-size-13 text-secondary"
                  >
                    <h5
                      className="text-muted fw-medium mb-0 lh-sm"
                      style={{ fontSize: 14, margin: "2px 0" }}
                    >
                      {widget?.count}
                    </h5>
                  </Link>
                  <p className="mb-0 font-size-13">{widget?.title}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
    </>
  )
}

export default Widgets
