import mockTests from "store/mockTests/reducer"
import {
  GET_MOCK_TESTS,
  GET_MOCK_TESTS_FAIL,
  GET_MOCK_TESTS_SUCCESS,
  GET_MOCK_TEST_DETAIL,
  ADD_NEW_MOCK_TEST,
  ADD_MOCK_TEST_SUCCESS,
  ADD_MOCK_TEST_FAIL,
  UPDATE_MOCK_TEST,
  UPDATE_MOCK_TEST_SUCCESS,
  UPDATE_MOCK_TEST_FAIL,
  DELETE_MOCK_TEST,
  DELETE_MOCK_TEST_SUCCESS,
  DELETE_MOCK_TEST_FAIL,
  GET_MOCK_TEST_DETAIL_FAIL,
  GET_MOCK_TEST_DETAIL_SUCCESS,
  UPDATE_MOCK_TEST_LOADING_STATE,
} from "./actionTypes"

export const getMockTests = (params) => ({
  type: GET_MOCK_TESTS,
  params
})

export const getMockTestsSuccess = mockTests => ({
  type: GET_MOCK_TESTS_SUCCESS,
  payload: mockTests,
})

export const addNewMockTest = (mockTests, history) => ({
  type: ADD_NEW_MOCK_TEST,
  payload: { mockTests, history },
})

export const addMockTestSuccess = mockTests => ({
  type: ADD_MOCK_TEST_SUCCESS,
  payload: mockTests,
})

export const addMockTestFail = error => ({
  type: ADD_MOCK_TEST_FAIL,
  payload: error,
})

export const updateMockTest = (mockTest, history,params) => ({
  type: UPDATE_MOCK_TEST,
  payload: { mockTest, history,params },
})

export const updateMockTestSuccess = mockTest => ({
  type: UPDATE_MOCK_TEST_SUCCESS,
  payload: mockTest,
})

export const updateMockTestFail = error => ({
  type: UPDATE_MOCK_TEST_FAIL,
  payload: error,
})

export const deleteMockTest = mockTestId => ({
  type: DELETE_MOCK_TEST,
  payload: mockTestId,
})

export const deleteMockTestSuccess = mockTests => ({
  type: DELETE_MOCK_TEST_SUCCESS,
  payload: mockTests,
})

export const deleteMockTestFail = error => ({
  type: DELETE_MOCK_TEST_FAIL,
  payload: error,
})

export const getMockTestsFail = error => ({
  type: GET_MOCK_TESTS_FAIL,
  payload: error,
})

export const getMockTestDetail = mockTestId => ({
  type: GET_MOCK_TEST_DETAIL,
  mockTestId,
})

export const getMockTestDetailSuccess = mockTestDetails => ({
  type: GET_MOCK_TEST_DETAIL_SUCCESS,
  payload: mockTestDetails,
})

export const getMockTestDetailFail = error => ({
  type: GET_MOCK_TEST_DETAIL_FAIL,
  payload: error,
})

export const updateMockTestLoadingState = status => ({
  type: UPDATE_MOCK_TEST_LOADING_STATE,
  payload: status,
})
