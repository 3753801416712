import React from "react"

function index({ userMockTestMarks }) {
  const total = userMockTestMarks
  const percentage = total / 100
  const filledStars = Math.floor(percentage * 5)
  const remainder = percentage * 5 - filledStars
  const hasHalfStar = remainder >= 0.5
  return (
    <>
      <div className="user_ratings d-flex align-items-center gap-1">
        {[...Array(5)].map((_, index) => {
          if (index < filledStars) {
            return (
              <span
                key={index}
                className="mdi mdi-star"
                style={{ color: "#f89752", fontSize: "15px" }}
              ></span>
            )
          } else if (index === filledStars && hasHalfStar) {
            return (
              <span
                key={index}
                className="mdi mdi-star-half-full"
                style={{ color: "#f89752", fontSize: "15px" }}
              ></span>
            )
          } else {
            return (
              <span
                key={index}
                className="mdi mdi-star-outline"
                style={{ color: "#f89752", fontSize: "15px" }}
              ></span>
            )
          }
        })}
        <span className="ms-1 fw-bold fc-prime">{(filledStars + percentage).toFixed(1)}</span>
      </div>
    </>
  )
}

export default index
