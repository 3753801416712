import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_JOBS,
  GET_JOB_DETAIL,
  ADD_NEW_JOB,
  DELETE_JOB,
  UPDATE_JOB,
  GET_JOBS_By_HR,
  GET_USER_APPLIED_JOB,
} from "./actionTypes"
import {
  getJobsSuccess,
  getJobsFail,
  getJobDetailSuccess,
  getJobDetailFail,
  addJobFail,
  addJobSuccess,
  updateJobSuccess,
  updateJobFail,
  deleteJobSuccess,
  deleteJobFail,
  updateJobLoadingState,
  getJobsByHrFail,
  getJobsByHrSuccess,
  getUserAppliedJobsFail,
  getUserAppliedJobsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getJobs,
  getHrPostedJobs,
  getJobsDetails,
  addNewJob,
  updateJob,
  deleteJob,
  getUserAppliedJobList,
} from "helpers/api/JobApi"

function* fetchJobs({ companyId, params }) {
  try {
    yield put(updateJobLoadingState(true))
    let response
    const { page, limit, dateRange = "", search = "", deletedAt } = params
    const queryParams = `&page=${page}&limit=${limit}&dateRange=${dateRange}${
      search ? "&search=" + search : ""
    }${deletedAt ? "&deletedAt=" + deletedAt : ""}`
    if (companyId) {
      response = yield call(getJobs, companyId, queryParams)
    } else {
      response = yield call(getJobs, "", queryParams)
    }
    yield put(getJobsSuccess(response.data.jobList))
    yield put(updateJobLoadingState(false))
  } catch (error) {
    yield put(updateJobLoadingState(false))
    yield put(getJobsFail(error))
  }
}

function* fetchJobDetail({ jobId }) {
  try {
    yield put(updateJobLoadingState(true))
    const response = yield call(getJobsDetails, jobId)
    yield put(getJobDetailSuccess(response.data))
    yield put(updateJobLoadingState(false))
  } catch (error) {
    yield put(getJobDetailFail(error))
    yield put(updateJobLoadingState(false))
  }
}

function* onUpdateJob({ payload: { job, jobId, history } }) {
  try {
    const response = yield call(updateJob, job, jobId)
    yield put(updateJobSuccess(response.data.data))
    toastr.success("Job updated successfully..!!")
    setTimeout(() => {
      history("/jobs-list")
    }, 2000)
  } catch (error) {
    yield put(updateJobFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteJob({ payload: jobId }) {
  try {
    const response = yield call(deleteJob, jobId)
    toastr.success("Job deleted successfully..!!")
    yield put(deleteJobSuccess(response.data.job))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteJobFail(error))
  }
}

function* onAddNewJob({ payload: { job, history } }) {
  try {
    yield put(updateJobLoadingState(true))
    const response = yield call(addNewJob, job)
    toastr.success("New Job Added Successfully..!!")
    yield put(addJobSuccess(response.data.data))
    yield put(updateJobLoadingState(false))
    setTimeout(() => {
      history(-1)
    }, 1500)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateJobLoadingState(false))
    yield put(addJobFail(error))
  }
}

function* fetchJobsPostedByHr({ postedBy }) {
  try {
    yield put(updateJobLoadingState(true))
    if (postedBy) {
      const response = yield call(getHrPostedJobs, postedBy)
      yield put(getJobsByHrSuccess(response.data.jobList))
      yield put(updateJobLoadingState(false))
    }
  } catch (error) {
    yield put(updateJobLoadingState(false))
    yield put(getJobsByHrFail(error))
  }
}

function* fetchUserJobs({ params }) {
  try {
    const { candidateId, companyId } = params
    const queryParams = `?candidateId=${candidateId}${
      companyId ? "&companyId=" + companyId : ""
    }`
    yield put(updateJobLoadingState(true))
    const response = yield call(getUserAppliedJobList, queryParams)
    yield put(getUserAppliedJobsSuccess(response.data.jobAppliedList))
    yield put(updateJobLoadingState(false))
  } catch (error) {
    yield put(updateJobLoadingState(false))
    yield put(getUserAppliedJobsFail(error))
  }
}

function* jobsSaga() {
  yield takeEvery(GET_JOBS, fetchJobs)
  yield takeEvery(GET_JOB_DETAIL, fetchJobDetail)
  yield takeEvery(ADD_NEW_JOB, onAddNewJob)
  yield takeEvery(UPDATE_JOB, onUpdateJob)
  yield takeEvery(DELETE_JOB, onDeleteJob)
  yield takeEvery(GET_JOBS_By_HR, fetchJobsPostedByHr)
  yield takeEvery(GET_USER_APPLIED_JOB, fetchUserJobs)
}

export default jobsSaga
