import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import toastr from "toastr"
import {
  Col,
  Container,
  Row,
} from "reactstrap"
import {
  getRoles as onGetRoles,
  getCompanies as onGetCompanies,
  updateCompany as onUpdateCompany,
  addNewCompany as onAddNewCompany,
  deleteCompany as onDeleteCompany,
} from "../../store/actions"
import { adminPermission } from "../../helpers/roles_permissions"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import FormModal from "../../components/Common/Modal/FormModal"
import { columns,renderTOFormFields } from "./constants";

const CompaniesList = () => {
  document.title = "Company List | QAPRENEUR"
  const role = localStorage.getItem("authRole")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [company, setCompany] = useState({
    name: "",
    email: "",
    contactPersonName: "",
    address: ""
  })
  const [formModal, setFormModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { companies, loading, roles } = useSelector(state => ({
    companies: state?.companies?.companies,
    loading: state?.companies?.loading,
    roles: state.roles?.roles,
  }))

  const companyRoleId = roles.filter(
    role => role.status === "active" && role.role === "company"
  )

  useEffect(() => {
    const params = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      params.search = search;
    }
    dispatch(onGetCompanies(params))
    dispatch(onGetRoles())
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(companies)) {
      const data = companies.map(item => ({ ...item, ...item.extra })).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      setCompanyList(data)
    }
  }, [companies, pageNo])

  const toggleForm = () => setFormModal(!formModal)

  const handleAddCompanyClick = () => {
    setIsEdit(false)
    setCompany({
      name: "",
      email: "",
      contactPersonName: "",
      address: ""
    })
    toggleForm()
  }

  const handleModalAction = () => {
    if (company && company._id) {
      const params = {
        page: pageNo,
        limit: rowPerPage,
      }
      dispatch(onDeleteCompany(company._id, params))
    }
    setDeleteModal(false)
  }

  const handleChangeStatus = company => {
    const status = company.status === "active" ? "deactive" : "active"
    dispatch(onUpdateCompany("company", { status }, company._id, navigate))
  }

  const handleActions = (c, type) => {
    if (type === "VIEW") {
      navigate(`/company-details/${c._id}`)
    } else if (type === "EDIT") {
      setCompany({
        name: c?.name,
        email: c?.email,
        contactPersonName: c?.contactPersonName,
        address: c?.address,
        _id: c?._id
      })
      setIsEdit(true)
      toggleForm()
    } else if (type === "DELETE") {
      setCompany(c)
      setDeleteModal(true)
    }
  }

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  const handleSearch = (query) => {
    setPageNo(1)
    setSearch(query)
  }

  const handleSave = values => {
    if (companyRoleId && companyRoleId.length > 0 && companyRoleId[0]?._id) {
      values.roleId = companyRoleId[0]?._id
      values.extra = {
        contactNumber: values.contactNumber,
        contactPersonName: values.contactPersonName,
      }
      if (isEdit) {
        delete values.email;
        dispatch(
          onUpdateCompany("company", values, company._id, navigate)
        )
      } else {
        dispatch(onAddNewCompany(values, navigate))
      }
    } else {
      toastr.error("Company role not found")
    }
    toggleForm()
  }

  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the company?"}
          modalAction={handleModalAction}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Companies" breadcrumbItem="Companies" />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={companyList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  handleStatus={handleChangeStatus}
                  handleActions={handleActions}
                  handleSearch={handleSearch}
                  onChangePagination={onChangePagination}
                  showPagination={true}
                  addButton={adminPermission.includes(role) ? { onclick: handleAddCompanyClick, title: "Add New Company" } : null}
                  columns={columns}
                />
              </div>
            </Col>
          </Row>
          {formModal && (
            <FormModal
              modalBackdrop={formModal}
              setmodalBackdrop={setFormModal}
              modalAction={handleSave}
              title={!!isEdit ? "Edit Company" : "Add Company"}
              formFields={renderTOFormFields(isEdit)}
              data={company}
              height={450}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompaniesList