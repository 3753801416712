export const studentColumns = [
  {
    header: "#",
    key: "",
  },
  // {
  //   header: "Image",
  //   name: "image",
  //   key: "IMAGE",
  //   link: "/student-details",
  // },
  {
    header: "Image",
    name: "image",
    key: "PROFILE_IMAGE",
    link: "/student-details",
  },
  {
    header: "Name",
    name: "name",
    key: "NAME_AND_TITLE",
    link: "/student-details",
  },
  {
    header: "Mobile",
    name: "phone",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Address",
    name: "address",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
  {
    header: "verify User",
    name: "emailVerifyStatus",
    key: "SWITCH2",
    CASE: "CASE1",
  },
  {
    header: "Active/Deactive",
    key: "SWITCH",
    CASE: "CASE2",
  },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Edit", type: "EDIT" },
      { label: "View", type: "VIEW" },
      { label: "Delete", type: "DELETE" },
      { label: "Send Reset Password Mail", type: "MAIL" },
    ],
  },
]

export const transactColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Product Category",
    name: "purpose",
    key: "DEFAULT",
  },
  {
    header: "Product Name",
    name: "productname",
    key: "DEFAULT",
  },
  {
    header: "Transaction Id",
    name: "transactionId",
    key: "DEFAULT",
  },
  {
    header: "Transaction Type",
    name: "transactionType",
    key: "DEFAULT",
  },
  {
    header: "DATE",
    name: "createdAt",
    key: "DATE",
  },
  {
    header: "Amount",
    name: "amount",
    key: "DEFAULT",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [{ label: "download invoice", type: "INVOICE" }],
  },
]

export const mockTestRequestsColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Course Name",
    name: "courseName",
    key: "DEFAULT",
  },
  {
    header: "Purpose",
    name: "description",
    key: "DEFAULT",
  },
  {
    header: "Work Experience",
    name: "workExperience",
    key: "DEFAULT",
  },
  {
    header: "Technical",
    name: "technical",
    key: "DEFAULT",
  },
  {
    header: "Overall Impression",
    name: "overallImpression",
    key: "DEFAULT",
  },
  {
    header: "Leadership",
    name: "leadership",
    key: "DEFAULT",
  },
  {
    header: "Enthusiasm",
    name: "enthusiasm",
    key: "DEFAULT",
  },
  {
    header: "Educational",
    name: "educational",
    key: "DEFAULT",
  },
  {
    header: "Confidence",
    name: "confidence",
    key: "DEFAULT",
  },
  {
    header: "Communication Skills",
    name: "communicationSkills",
    key: "DEFAULT",
  },
  {
    header: "DATE",
    name: "createdAt",
    key: "DATE",
  },
  {
    header: "Interview Status",
    name: "interviewStatus",
    key: "STATUS",
  },
]

export const jobPostscolumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Job Title",
    name: "jobTitle",
    key: "DEFAULT",
    // defaultId: "testId",
    link: "/jobs-details",
  },
  {
    header: "Salary Range",
    name: "salaryRange",
    key: "DEFAULT",
  },
  {
    header: "location",
    name: "location",
    key: "DEFAULT",
  },
  {
    header: "Experience",
    name: "experience",
    key: "DEFAULT",
  },
  {
    header: "Job Type",
    name: "type",
    key: "DEFAULT",
  },
  {
    header: "Candidate applied",
    name: "totalCandidatesApplied",
    key: "DEFAULT",
  },
  {
    header: "Job Status",
    name: "totalCandidatesHired",
    key: "DEFAULT",
  },
]

export const userJobAppliedcolumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Company Name",
    name: "companyName",
    key: "DEFAULT",
  },
  {
    header: "Job Title",
    name: "jobTitle",
    key: "DEFAULT",
    // defaultId: "testId",
    link: "/jobs-details",
  },
  {
    header: "Salary Range",
    name: "salaryRange",
    key: "DEFAULT",
  },
  {
    header: "location",
    name: "location",
    key: "DEFAULT",
  },
  {
    header: "Experience",
    name: "experience",
    key: "DEFAULT",
  },
  {
    header: "Job Type",
    name: "type",
    key: "DEFAULT",
  },
  {
    header: "Job Status",
    name: "status",
    key: "STATUS",
  },
]

export const userCompanyViewHistorycolumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Company Name",
    name: "name",
    key: "DEFAULT",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
  },
  {
    header: "Phone",
    name: "phone",
    key: "DEFAULT",
  },
  {
    header: "Location",
    name: "headQuarter",
    key: "DEFAULT",
  },
]

export const userJobReferralscolumn = [
  {
    header: "#",
    key: "",
  },
  {
    header: "User Name",
    name: "userName",
    key: "DEFAULT",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
  },
  // {
  //   header: "Phone",
  //   name: "phone",
  //   key: "DEFAULT",
  // },
  {
    header: "Job Name",
    name: "jobTitle",
    key: "DEFAULT",
  },
  {
    header: "Job Location",
    name: "location",
    key: "DEFAULT",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
]

export const formFields = [
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "Enter Name",
    required: true,
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Enter Name",
    required: true,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    placeholder: "Enter Email",
    required: true,
  },
  {
    name: "phone",
    label: "Mobile",
    type: "text",
    placeholder: "Enter Mobile",
    required: true,
  },
  {
    name: "address",
    label: "Address Line 1",
    type: "text",
    placeholder: "Address Line 1",
    required: true,
  },
  {
    name: "dob",
    label: "DOB",
    type: "date",
    placeholder: "",
    required: true,
  },
  {
    name: "country",
    label: "Select Country",
    type: "select",
    options: [
      { value: "Select Country", label: "" },
      { value: "India", label: "India" },
    ],
    required: true,
  },
  {
    name: "cityState",
    label: "",
    type: "cityState",
    lg: 12,
    state: {
      name: "state",
      label: "Select State",
      type: "select",
      isExternal: true,
      slug: "state",
      required: true,
    },
    city: {
      name: "city",
      label: "Select City",
      type: "select",
      isExternal: true,
      slug: "city",
      required: true,
    },
  },
]
