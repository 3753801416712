import React from "react"
import { Link } from "react-router-dom"
import { Col, Card, CardBody } from "reactstrap"

function Reports({ reports }) {
  return (
    <>
      {reports &&
        reports.map((report, key) => (
          <Col md={report?.md ? report?.md : 3} key={"_col_" + key}>
            <Card className="border border-3">
              <CardBody className="d-flex flex-column align-items-center justify-content-between">
                <i
                  style={{ color: "#f89752", fontSize: 20 }}
                  className={" " + report?.iconClass}
                ></i>
                <div className="text-center">
                  <Link
                    to={report?.link}
                    className="font-size-13 text-secondary"
                  >
                    <h5
                      className="text-muted fw-medium mb-0 lh-sm"
                      style={{ fontSize: 14, margin: "2px 0" }}
                    >
                      {report?.description}
                    </h5>
                  </Link>
                  <p className="mb-0 font-size-13">{report?.title}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
    </>
  )
}

export default Reports
