import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import AdminProfile from "../pages/Authentication/Profile/AdminProfile"

// // Pages Calendar
// import Calendar from "../pages/Calendar/index"

// //TestCategories
import TestCategoriesList from "../pages/TestCategoryManagement/TestCategoryList"
import TestCountList from "../pages/TestCategoryManagement/TestCountList"

// //Tests
import TestsList from "../pages/TestManagement/TestList"
import Tests from "../pages/TestManagement/tests"
import ViewTest from "../pages/TestManagement/ViewTest"
import PurchasedTestUsers from "../pages/TestManagement/PurchasedTestUsers"

// Questions
import Questions from "../pages/QuestionManagement/questions"
import QuestionList from "../pages/QuestionManagement/QuestionList"

//* Job Management Routes *//
import JobList from "../pages/JobManagement/JobList"
import Jobs from "../pages/JobManagement/Job"
import ViewJob from "../pages/JobManagement/ViewJob"
import JobReferral from "../pages/JobReferralManagement/JobReferral"

//* Applicants Management Routes *//
import ApplicantsList from "../pages/ApplicantsManagement/ApplicantsList"
import CandidateList from "../pages/ApplicantsManagement/Candidate"
import ViewCandidate from "../pages/ApplicantsManagement/ViewCandidate"
import MyCandidateList from "../pages/ApplicantsManagement/MyCandidate"
import UnlockApplicantsList from "../pages/ApplicantsManagement/UnlockApplicants"
import UserJobAppliedList from "../pages/ApplicantsManagement/userJobAppliedList"

//* Skills Management Routes *//
import Skill from "../pages/SkillsManagement/skill"

//* Course Management Routes *//
import CoursesList from "../pages/CourseManagement/CourseList"
import MyCoursesList from "../pages/CourseManagement/MyCourseList"
import Courses from "../pages/CourseManagement/courses"
import ViewCourse from "../pages/CourseManagement/ViewCourse"
import PurchasedCourseUsers from "../pages/CourseManagement/PurchasedCourseUsers"

//* Chapter Management Routes *//
import EditChapter from "../pages/CourseManagement/EditChapter"

//* Chapter Flash Card Management Routes *//
import FlashCardList from "../pages/CourseManagement/FlashCardList"
//* Chapter Content  Management Routes *//
import ChapterContent from "../pages/CourseManagement/ChapterContent"

//* Students Management Routes *//
import StudentsList from "../pages/StudentManagement/StudentList"
import StudentDetail from "../pages/StudentManagement/ViewStudent"
import StudentTestReport from "../pages/TestManagement/StudentTestReport"
import StudentCourseReport from "../pages/CourseManagement/StudentCourseReport"
import Students from "pages/StudentManagement/students"
import EditStudentProfile from "../pages/StudentManagement/EditStudentProfile"

//* Coachings Management Routes *//
import CoachingsList from "../pages/CoachingManagement/CoachingList"
import Coachings from "pages/CoachingManagement/coachings"
import ViewCoachingProfile from "../pages/CoachingManagement/ViewCoachingProfile"
import EditCoachingProfile from "../pages/CoachingManagement/EditCoachingProfile"

//* Companies Management Routes *//
import CompaniesList from "../pages/CompanyManagement/CompanyList"
import ViewCompanyProfile from "../pages/CompanyManagement/ViewCompanyProfile"
import EditCompanyProfile from "../pages/CompanyManagement/EditCompanyProfile"
import AddHrUsers from "../pages/CompanyManagement/AddHrUsers"

//* Hr Management Routes *//
import HrList from "../pages/HrManagement/HrList"
import ViewHrDetail from "../pages/HrManagement/ViewHr"

//* Colleges Management Routes *//
import CollegesList from "../pages/CollegeManagement/CollegeList"
import CreateCollege from "pages/CollegeManagement/CreateCollege"
import ViewCollegeProfile from "../pages/CollegeManagement/ViewCollegeProfile"
import EditCollegeProfile from "../pages/CollegeManagement/EditCollegeProfile"

//* Mock Test Management Routes *//
import MockTestList from "../pages/MockTestManagement/MockTestList"

//* College Request Management Routes *//
import CollegeRequestList from "../pages/CollegeRequestManagement/CollegeRequestList"

//* ContactUs Routes *//
import ContactUs from "../pages/ContactUs"
//* Inquiries Routes *//
import Inquiries from "../pages/Inquiries"
//* Announcement Routes *//
import AnnouncementList from "../pages/Announcement/AnnouncementList"
import Announcement from "../pages/Announcement/Announcement"
import AnnouncementReport from "../pages/Announcement/AnnouncementReport"

//* OurReviews Routes *//
import OurReviews from "../pages/OurReviews/ReviewList"
import Review from "../pages/OurReviews/Review"
//* Plan Routes *//
import PlanList from "../pages/Plan/PlanList"
import ConfigurePlan from "../pages/Plan/configurePlan"
import CompanyPlan from "../pages/Plan/CompanyPlan"
import UpgradePlan from "../pages/Plan/UpgradePlan"
import MockTestPrice from "../pages/Plan/MockTestPrice"

//* Add On Routes *//
import AddOnPackagesList from "../pages/AddOnPackages/AddOnPackageList"
import ConfigurePackage from "../pages/AddOnPackages/configurePackage"
//* Notification Routes *//
import Notification from "../pages/Notification/notification"
import NotificationList from "../pages/Notification/notificationList"
import NotificationReceiversList from "../pages/Notification/notificationReceiversList"

//* Transaction Routes *//
import Transaction from "../pages/Transaction/TransactionList"
import TransactionStatus from "../pages/Transaction/TransactionStatus"

// Authentication related pages
import Home from "../pages/Home"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePassword from "../pages/Authentication/ChangePassword"

// Dashboard
import Dashboard from "../pages/Dashboard/DashboardSetting"
import StudentDashboard from "pages/Dashboard/StudentDashboard"

// Media
import MediaList from "../pages/MediaManagement/MediaList"

const adminRoutes = [
  //TestCategories
  { path: "/test-categories-list/", component: <TestCategoriesList /> },
  { path: "/test-categories-list/:slug", component: <TestCategoriesList /> },
  { path: "/tests-overview/:categoryId", component: <TestCountList /> },

  //Tests
  { path: "/tests-create", component: <Tests /> },
  { path: "/questions-create/:type/:id/:questionNo", component: <Questions /> },
  { path: "/questions-edit/:type/:id/:questionNo", component: <Questions /> },
  {
    path: "/questions-create/:type/:courseId/:chapterId",
    component: <Questions />,
  },

  { path: "/purchased-test-users/:id", component: <PurchasedTestUsers /> },

  {
    path: "/questions-view/chapters/:id",
    component: <QuestionList />,
  },

  //Course
  { path: "/courses-list", component: <CoursesList /> },
  { path: "/courses-list/:slug", component: <CoursesList /> },
  { path: "/courses-edit/:id", component: <Courses /> },
  { path: "/courses-create", component: <Courses /> },
  { path: "/course-details/:id", component: <ViewCourse /> },
  { path: "/purchased-course-users/:id", component: <PurchasedCourseUsers /> },
  { path: "/:testType/:todo/:courseId", component: <Tests /> },
  { path: "/:testType/:id", component: <Tests /> },

  //* Course Chapter *//
  { path: "/courses/chapter-edit/:chapterId", component: <EditChapter /> },

  //* Flash Card *//
  {
    path: "/courses/:courseId/chapter/:chapterId/flash-card",
    component: <FlashCardList />,
  },

  //* Chapter Content *//
  {
    path: "/courses/:courseId/chapter/:chapterId/content",
    component: <ChapterContent />,
  },

  //* ContactUs Routes *//
  { path: "/contact-us", component: <ContactUs /> },
  //* Inquiries Routes *//
  { path: "/inquiries", component: <Inquiries /> },
  { path: "/inquiries/:slug", component: <Inquiries /> },

  //* Plan Routes *//
  { path: "/plan-list/:profileTab", component: <PlanList /> },
  { path: "/add-plan/:role", component: <ConfigurePlan /> },
  { path: "/edit-plan/:planId/:role", component: <ConfigurePlan /> },
  //* Add On Routes *//
  { path: "/packages", component: <AddOnPackagesList /> },
  { path: "/add-package/:role", component: <ConfigurePackage /> },
  { path: "/edit-package/:packageId/:role", component: <ConfigurePackage /> },
  //* Notification Routes *//
  { path: "/notifications", component: <NotificationList /> },
  { path: "/notifications/:slug", component: <NotificationList /> },

  { path: "/create-notification", component: <Notification /> },
  { path: "/edit-notification/:notificationId", component: <Notification /> },
  {
    path: "/view-receivers/:notificationId",
    component: <NotificationReceiversList />,
  },
  //* Transaction Routes *//
  { path: "/transaction", component: <Transaction /> },
  { path: "/transaction/:slug", component: <Transaction /> },

  //* Media *//
  { path: "/media", component: <MediaList /> },
]

const studentsRoutes = [
  { path: "/my-courses-list", component: <MyCoursesList /> },
]

const authProtectedRoutes = [
  //   { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },

  // //calendar
  // { path: "/calendar", component: <Calendar /> },

  // //profile

  { path: "/admin/profile", component: <AdminProfile /> },

  // { path: "/profile", component: <CompanyProfile /> },

  //Tests
  { path: "/tests-list", component: <TestsList /> },
  { path: "/tests-list/:slug", component: <TestsList /> },
  { path: "/tests-edit/:id", component: <Tests /> },
  { path: "/tests-create", component: <Tests /> },
  { path: "/test-details/:id", component: <ViewTest /> },

  // * Jobs Management Routes * //
  { path: "/jobs-list", component: <JobList /> },
  { path: "/jobs-list/:slug", component: <JobList /> },
  { path: "/deleted-job-list", component: <JobList /> },
  { path: "/jobs-edit/:id", component: <Jobs /> },
  { path: "/jobs-create", component: <Jobs /> },
  { path: "/jobs-details/:jobId", component: <ViewJob /> },
  { path: "/referral-report", component: <JobReferral /> },

  //* Applicants Management Routes *//
  { path: "/applicant-list/:companyId/:id", component: <ApplicantsList /> },
  { path: "/my-candidate/:id", component: <MyCandidateList /> },
  { path: "/unlock-candidate/:id", component: <UnlockApplicantsList /> },
  { path: "/user-applied-joblist/:id", component: <UserJobAppliedList /> },
  { path: "/candidate/:id", component: <CandidateList /> },
  {
    path: "/profile/view/candidate/:candidateId",
    component: <ViewCandidate />,
  },
  {
    path: "/profile/view/applicant-details/:candidateId",
    component: <ViewCandidate />,
  },

  //* Skills Management Routes * //
  { path: "/skills-list", component: <Skill /> },
  { path: "/skills-list/:slug", component: <Skill /> },

  //Student
  { path: "/students-list", component: <StudentsList /> },
  { path: "/students-list/:slug", component: <StudentsList /> },
  { path: "/student-details/:id", component: <StudentDetail /> },
  { path: "/student-details/:profileTab/:id", component: <StudentDetail /> },
  {
    path: "/profile/edit/student/:studentId",
    component: <EditStudentProfile />,
  },
  {
    path: "/student-details/test-report/:studentId/:testId/:resultId",
    component: <StudentTestReport />,
  },
  {
    path: "/student-details/course-report/:studentId/:courseId/",
    component: <StudentCourseReport />,
  },
  { path: "/students-create", component: <Students /> },
  { path: "/students-edit/:id", component: <Students /> },

  //* Coachings Management Routes *//
  { path: "/coachings-list", component: <CoachingsList /> },
  { path: "/coachings-list/:slug", component: <CoachingsList /> },

  { path: "/coaching-details/:coachingId", component: <ViewCoachingProfile /> },
  { path: "/coachings-create", component: <Coachings /> },
  { path: "/coachings-edit/:id", component: <Coachings /> },
  {
    path: "/profile/view/coaching/:coachingId",
    component: <ViewCoachingProfile />,
  },
  {
    path: "/profile/edit/coaching/:coachingId",
    component: <EditCoachingProfile />,
  },

  //* Companies Management Routes *//
  { path: "/companies-list", component: <CompaniesList /> },
  { path: "/companies-list/:slug", component: <CompaniesList /> },

  { path: "/company-details/:companyId", component: <ViewCompanyProfile /> },
  {
    path: "/profile/view/company/:companyId",
    component: <ViewCompanyProfile />,
  },
  {
    path: "/profile/edit/company/:companyId",
    component: <EditCompanyProfile />,
  },
  {
    path: "/company/addUser/:companyId",
    component: <AddHrUsers />,
  },
  {
    path: "/company/user-list/:companyId",
    component: <AddHrUsers />,
  },
  {
    path: "/profile/view/hr_user/:hrId",
    component: <ViewHrDetail />,
  },
  {
    path: "/profile/view/hr-details/:hrId",
    component: <ViewHrDetail />,
  },
  { path: "/plan-list", component: <CompanyPlan /> },
  { path: "/upgrade-plan", component: <UpgradePlan /> },
  { path: "/edit-mock-test-price", component: <MockTestPrice /> },
  //* Colleges Management Routes *//
  { path: "/colleges-list", component: <CollegesList /> },
  { path: "/colleges-list/:slug", component: <CollegesList /> },

  { path: "/college-details/:collegeId", component: <ViewCollegeProfile /> },
  { path: "/colleges-create", component: <CreateCollege /> },
  { path: "/colleges-edit/:id", component: <CreateCollege /> },
  {
    path: "/profile/view/college/:collegeId",
    component: <ViewCollegeProfile />,
  },
  {
    path: "/profile/edit/college/:collegeId",
    component: <EditCollegeProfile />,
  },

  //* Mock Test Management Routes *//
  { path: "/mock-test-list", component: <MockTestList /> },
  { path: "/mock-test-list/:slug", component: <MockTestList /> },

  //* College Request Management Routes *//
  { path: "/college-request", component: <CollegeRequestList /> },

  { path: "/transaction", component: <Transaction /> },

  //* Plan Routes *//
  { path: "/plan-list/:profileTab", component: <PlanList /> },
  { path: "/plan-list/:profileTab/:slug", component: <PlanList /> },
  { path: "/packages/:profileTab", component: <AddOnPackagesList /> },

  //* Inquiries Routes *//
  { path: "/inquiries", component: <Inquiries /> },

  //* OurReviews Routes *//
  { path: "/reviews", component: <OurReviews /> },
  { path: "/reviews/:slug", component: <OurReviews /> },
  { path: "/add-review", component: <Review /> },
  { path: "/edit-review/:reviewId", component: <Review /> },

  //* ContactUs Routes *//
  { path: "/contact-us", component: <ContactUs /> },
  { path: "/contact-us/:slug", component: <ContactUs /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
  //   exact: true,
  // { path: "/", component: <Home /> },
  //* Media *//
  { path: "/media", component: <MediaList /> },
  { path: "/media/:slug", component: <MediaList /> },

  {
    path: "/transaction/:status/:itemId/:txnId",
    component: <TransactionStatus />,
  },
  //* Announcement Routes *//
  { path: "/announcement", component: <AnnouncementList /> },
  { path: "/announcement/:slug", component: <AnnouncementList /> },
  { path: "/create-announcement", component: <Announcement /> },
  { path: "/edit-announcement/:announcementId", component: <Announcement /> },
  {
    path: "/announcement/report/:announcementId",
    component: <AnnouncementReport />,
  },
  { path: "/changePassword", component: <ChangePassword /> },
]

const publicRoutes = [
  // { path: "/", component: <Home /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/admin/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
  { path: "/:category/register", component: <Register /> },
]

const websiteRoutes = [
  { path: "/", component: <Home /> },
  // { path: "/transaction/:status/:itemId/:txnId", component: <TransactionStatus /> },
]

export {
  authProtectedRoutes,
  publicRoutes,
  adminRoutes,
  studentsRoutes,
  websiteRoutes,
}
