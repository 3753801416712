import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATION_DETAIL,
  ADD_NEW_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  GET_NOTIFICATION_DETAIL_FAIL,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  UPDATE_NOTIFICATION_LOADING_STATE,
  GET_NOTIFICATIONS_RECEIVERS,
  GET_NOTIFICATIONS_RECEIVERS_FAIL,
  GET_NOTIFICATIONS_RECEIVERS_SUCCESS,
  RESET_NOTIFICATIONS
} from "./actionTypes"

export const getNotifications = (params) => ({
  type: GET_NOTIFICATIONS,
  payload: params
})

export const getNotificationsSuccess = notifications => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const getNotificationsFail = error => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
})

export const addNewNotification = notification => ({
  type: ADD_NEW_NOTIFICATION,
  payload: { notification },
})

export const addNotificationSuccess = notification => ({
  type: ADD_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const addNotificationFail = error => ({
  type: ADD_NOTIFICATION_FAIL,
  payload: error,
})

export const updateNotification = (notification, notificationId) => ({
  type: UPDATE_NOTIFICATION,
  payload: { notification, notificationId },
})

export const updateNotificationSuccess = notification => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const updateNotificationFail = error => ({
  type: UPDATE_NOTIFICATION_FAIL,
  payload: error,
})

export const deleteNotification = notificationId => ({
  type: DELETE_NOTIFICATION,
  payload: notificationId,
})

export const deleteNotificationSuccess = notificationId => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: notificationId,
})

export const deleteNotificationFail = error => ({
  type: DELETE_NOTIFICATION_FAIL,
  payload: error,
})

export const getNotificationDetail = notificationId => ({
  type: GET_NOTIFICATION_DETAIL,
  notificationId,
})

export const getNotificationDetailSuccess = notificationDetails => ({
  type: GET_NOTIFICATION_DETAIL_SUCCESS,
  payload: notificationDetails,
})

export const getNotificationDetailFail = error => ({
  type: GET_NOTIFICATION_DETAIL_FAIL,
  payload: error,
})

export const updateNotificationLoadingState = status => ({
  type: UPDATE_NOTIFICATION_LOADING_STATE,
  payload: status,
})

export const getNotificationsReceivers = (params) => ({
  type: GET_NOTIFICATIONS_RECEIVERS,
  payload: params
})

export const getNotificationsReceiversSuccess = notifications => ({
  type: GET_NOTIFICATIONS_RECEIVERS_SUCCESS,
  payload: notifications,
})

export const getNotificationsReceiversFail = error => ({
  type: GET_NOTIFICATIONS_RECEIVERS_FAIL,
  payload: error,
})

export const resetNotifications = () => ({
  type: RESET_NOTIFICATIONS,
})
