import jobs from "store/jobs/reducer"
import {
  GET_JOBS,
  GET_JOBS_FAIL,
  GET_JOBS_SUCCESS,
  GET_JOB_DETAIL,
  ADD_NEW_JOB,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAIL,
  UPDATE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAIL,
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAIL,
  GET_JOB_DETAIL_FAIL,
  GET_JOB_DETAIL_SUCCESS,
  UPDATE_JOB_LOADING_STATE,
  RESET_JOB_DETAIL,
  RESET_JOBS,
  GET_JOBS_By_HR,
  GET_JOBS_By_HR_SUCCESS,
  GET_JOBS_By_HR_FAIL,
  GET_USER_APPLIED_JOB_SUCCESS,
  GET_USER_APPLIED_JOB_FAIL,
  GET_USER_APPLIED_JOB
} from "./actionTypes"

export const getJobs = (companyId, params) => ({
  type: GET_JOBS,
  companyId,
  params,
})

export const getJobsSuccess = jobs => ({
  type: GET_JOBS_SUCCESS,
  payload: jobs,
})

export const addNewJob = (job, history) => ({
  type: ADD_NEW_JOB,
  payload: { job, history },
})

export const addJobSuccess = job => ({
  type: ADD_JOB_SUCCESS,
  payload: job,
})

export const addJobFail = error => ({
  type: ADD_JOB_FAIL,
  payload: error,
})

export const updateJob = (job, jobId, history) => ({
  type: UPDATE_JOB,
  payload: { job, jobId, history },
})

export const updateJobSuccess = job => ({
  type: UPDATE_JOB_SUCCESS,
  payload: job,
})

export const updateJobFail = error => ({
  type: UPDATE_JOB_FAIL,
  payload: error,
})

export const deleteJob = jobId => ({
  type: DELETE_JOB,
  payload: jobId,
})

export const deleteJobSuccess = job => ({
  type: DELETE_JOB_SUCCESS,
  payload: job,
})

export const deleteJobFail = error => ({
  type: DELETE_JOB_FAIL,
  payload: error,
})

export const getJobsFail = error => ({
  type: GET_JOBS_FAIL,
  payload: error,
})

export const getJobDetail = jobId => ({
  type: GET_JOB_DETAIL,
  jobId,
})

export const resetJobDetail = () => ({
  type: RESET_JOB_DETAIL,
})

export const getJobDetailSuccess = jobDetails => ({
  type: GET_JOB_DETAIL_SUCCESS,
  payload: jobDetails,
})

export const getJobDetailFail = error => ({
  type: GET_JOB_DETAIL_FAIL,
  payload: error,
})

export const updateJobLoadingState = status => ({
  type: UPDATE_JOB_LOADING_STATE,
  payload: status,
})

export const resetJobs = () => ({
  type: RESET_JOBS,
})

export const getJobsByHr = postedBy => ({
  type: GET_JOBS_By_HR,
  postedBy,
})

export const getJobsByHrSuccess = jobs => ({
  type: GET_JOBS_By_HR_SUCCESS,
  payload: jobs,
})

export const getJobsByHrFail = error => ({
  type: GET_JOBS_By_HR_FAIL,
  payload: error,
})

export const getUserAppliedJobs = params => ({
  type: GET_USER_APPLIED_JOB,
  params,
})

export const getUserAppliedJobsSuccess = jobs => ({
  type: GET_USER_APPLIED_JOB_SUCCESS,
  payload: jobs,
})

export const getUserAppliedJobsFail = error => ({
  type: GET_USER_APPLIED_JOB_FAIL,
  payload: error,
})
