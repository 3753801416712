import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import {
  getCompanyDetail as onGetCompanyDetail,
  resetCompany as onResetCompany,
  getJobDetailsPosteByCopmany as onGetJobDetailsPosteByCopmany,
  getCompanySubscriptions as onGetCompanySubscriptions,
  getCompanyAllplan as onGetCompanyAllplan,
} from "../../store/companies/actions"
import { getUserTabData as onGetUserTabData } from "../../store/students/actions"

import { userProfileTab } from "../../helpers/api/dynamicApi"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { jobPostscolumns, ApplicantsColumns } from "./constants"
import TableContainer from "../../components/Common/Table/index"
import AppPlan from "../../components/Common/Plan"
import AppLoader from "../../components/Common/Loader"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/avatar-1.jpg"
import CardProfile from "../../components/Common/Card/ProfileHeader"
import UserDetailsTab from "../../components/Tabs/UserDetailsTab"
import CardAbout from "../../components/Common/Card/CardAbout"

const staticURL = process.env.REACT_APP_STATIC_URL

function ViewHrProfile() {
  const role = localStorage.getItem("authRole")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  params.profileTab = "PERSONALDETAILS"
  const [isLoading, setIsLoading] = useState(true)
  const [txnStatus, setTxnStatus] = useState(false)
  const [title, seTitle] = useState("")
  const [companyId, setCompanyId] = useState("")

  const {
    // companyDetail,
    jobs,
    Subscriptions,
    Plans,
    hrDetail,
    // hrJobs,
    // myCandidates,
  } = useSelector(state => ({
    jobs: state.companies?.jobs,
    Subscriptions: state.companies?.Subscriptions,
    Plans: state.companies?.Plans,
    hrDetail: state.students.studentDetail,
  }))

  const {
    personalDetail,
    companyDetail,
    billingInfo,
    jobList,
    myCandidates,
    loading,
  } = useSelector(state => ({
    ...state.students,
  }))

  useEffect(() => {
    dispatch(onGetCompanySubscriptions(companyId))
    dispatch(onGetCompanyAllplan({ role: "company" }))
    if (params.hrId) {
      dispatch(onGetUserTabData("PERSONALDETAILS", params.hrId))
      getTabdata("PERSONALDETAILS", params.hrId)
      const currentLocation = location.pathname
      const isHr = currentLocation.includes("hr-details")
      if (isHr) {
        seTitle("hr-details")
      }
    }
    return () => {
      dispatch(onResetCompany())
      setIsLoading(false)
    }
  }, [dispatch, onGetCompanyDetail])

  useEffect(() => {
    if (personalDetail && Object.keys(personalDetail).length) {
      setIsLoading(false)
    }
    if (companyDetail && Object.keys(companyDetail).length) {
      setIsLoading(false)
    }
  }, [personalDetail, companyDetail])

  const getTabdata = async (tabId, userId) => {
    const response = await userProfileTab(tabId, userId)
    setCompanyId(response?.data?.personalDetail.companyId)
  }

  const handleTabClick = tabId => {
    if (!["tab5", "tab3"].includes(tabId)) {
      if (tabId === "COMPANYDETAILS" && title !== "hr-details" && companyId) {
        dispatch(onGetUserTabData(tabId, companyId))
        // dispatch(onGetUserTabData(tabId, params.hrId))
      } else if (
        tabId === "BILLING_DETAILS" &&
        title !== "hr-details" &&
        companyId
      ) {
        dispatch(onGetUserTabData(tabId, companyId))
      } else if (
        tabId !== "COMPANYDETAILS" &&
        params?.hrId &&
        title !== "hr-details"
      ) {
        // dispatch(onGetUserTabData(tabId, companyId))
        dispatch(onGetUserTabData(tabId, params.hrId))
      } else if (
        tabId !== "COMPANYDETAILS" &&
        params.hrId &&
        title === "hr-details"
      ) {
        dispatch(onGetUserTabData(tabId, params.hrId))
      }
    }
  }

  const tabsArray = [
    {
      id: "PERSONALDETAILS",
      label: "Personal Details",
      content: (
        <Col sm="12">
          <UserDetailsTab
            title="Personal Details"
            data={personalDetail}
            role={"hr"}
            loading={loading}
          />
        </Col>
      ),
    },
    ...(title !== "hr-details"
      ? [
          // {
          //   id: "COMPANYDETAILS",
          //   label: "Company Details",
          //   content: (
          //     <Col sm="12">
          //       {role === "hr_user" && (
          //         <Col>
          //           <UserDetailsTab
          //             title="Personal Details"
          //             data={companyDetail}
          //             role={"company"}
          //             loading={loading}
          //           />
          //         </Col>
          //       )}
          //     </Col>
          //   ),
          // },
          {
            id: "tab3",
            label: "About Company",
            content: (
              <Col sm="12">
                <CardAbout
                  title="About Company"
                  descripion={personalDetail?.companyDetails?.about}
                  isHtml={true}
                />
              </Col>
            ),
          },
          {
            id: "BILLING_DETAILS",
            label: "Billing Details",
            content: (
              <Col lg={12}>
                <UserDetailsTab
                  title="Billing Details"
                  data={billingInfo ? billingInfo?.billingInfo : {}}
                  role={"company_billing"}
                  loading={loading}
                />
              </Col>
            ),
          },
          // {
          //   id: "tab5",
          //   label: "Plan",
          //   content: (
          //     <Col lg={12}>
          //       <Card>
          //         <CardBody>
          //           <div className="pt-3 px-2">
          //             <h4 className="sun_heading">Plan</h4>
          //             {role === "admin" && (
          //               <AppPlan SubscriptionPlan={Subscriptions} />
          //             )}
          //             {!txnStatus && (
          //               <Row className="mt-5">
          //                 {Plans &&
          //                   Plans.length > 0 &&
          //                   Plans.map((Plans, key) => {
          //                     return (
          //                       <Col key={key}>
          //                         <CardBody
          //                           style={{
          //                             padding: 10,
          //                             border:
          //                               Subscriptions?.type === Plans?.type
          //                                 ? "2px solid #F89752"
          //                                 : "",
          //                           }}
          //                         >
          //                           <AppPlan
          //                             SubscriptionPlan={Plans}
          //                             isActive={
          //                               Subscriptions?.type === Plans?.type
          //                             }
          //                             // handlePlan={handlePlan}
          //                           />
          //                         </CardBody>
          //                       </Col>
          //                     )
          //                   })}
          //               </Row>
          //             )}
          //             {txnStatus && <p>wait For Transaction</p>}
          //           </div>
          //         </CardBody>
          //       </Card>
          //     </Col>
          //   ),
          // },
        ]
      : []),
    ...(title === "hr-details"
      ? [
          {
            id: "HR_JOB_POSTED",
            label: "Hr Job Posts",
            content: (
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="pt-3 px-2">
                      <h4 className="sun_heading">Job Posts</h4>
                    </div>
                    <TableContainer
                      loading={loading}
                      data={jobList.map(job => ({
                        ...job.jobDetail,
                        totalCandidatesApplied: job.totalCandidatesApplied,
                        totalCandidatesHired: job.totalCandidatesHired,
                      }))}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      isJobListGlobalFilter={false}
                      showPageDropdown={false}
                      customPageSize={10}
                      className="project-list-table table-nowrap align-middle table-borderless"
                      columns={jobPostscolumns}
                      notDataFound={true}
                    />
                  </CardBody>
                </Card>
              </Col>
            ),
          },
          {
            id: "HR_APPLICATS",
            label: "Hr Candidate list",
            content: (
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="pt-3 px-2">
                      <h4 className="sun_heading">Job Posts</h4>
                    </div>
                    <TableContainer
                      loading={loading}
                      data={myCandidates
                        .filter(
                          Candidate =>
                            Candidate.jobDetail.postedBy === params.hrId
                        )
                        .map(candidate => {
                          return {
                            ...candidate,
                            postedBy: candidate.jobDetail.postedBy,
                            jobTitle: candidate.jobDetail.jobTitle,
                            location: candidate.jobDetail.location,
                            noOfVacancy: candidate.jobDetail.noOfVacancy,
                            salaryRange: candidate.jobDetail.salaryRange,
                          }
                        })}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      isJobListGlobalFilter={false}
                      showPageDropdown={false}
                      customPageSize={10}
                      className="project-list-table table-nowrap align-middle table-borderless"
                      columns={ApplicantsColumns}
                      notDataFound={true}
                    />
                  </CardBody>
                </Card>
              </Col>
            ),
          },
        ]
      : []),
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={role === "admin" ? "Company List" : "Dashboard"}
            breadcrumbItem={"Hr " + (role === "admin" ? "Detail" : "Profile")}
            navigate={-1}
          />
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <AppLoader />
            </div>
          ) : (
            <Row>
              <Col lg={12}>
                <CardProfile
                  imageUrl={
                    personalDetail && personalDetail.image
                      ? staticURL + personalDetail.image
                      : avatar
                  }
                  ButtonType={
                    ["hr_personalDetail"].includes(role) ? "EDIT" : ""
                  }
                  data={personalDetail}
                  profileName={personalDetail?.name ? personalDetail?.name : ""}
                  profileEmail={personalDetail ? personalDetail.email : ""}
                  // onclick={role === "hr_user" ? () => navigate(`/profile/edit/hr/${user._id}`) : undefined}
                  {...(role === "hr_user"
                    ? {
                        onclick: () =>
                          navigate(`/profile/edit/hr/${personalDetail._id}`),
                      }
                    : {})}
                  LayoutType={"HORIZONTAL_TABS"}
                  tabs={tabsArray}
                  style={{ padding: 0 }}
                  showStatus={true}
                  showPlan={true}
                  planData={Subscriptions}
                  handleTabClick={handleTabClick}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewHrProfile
