import axios from "axios"
import { del, get, post, patch } from "./api_helper"
import * as url from "./url_helper"

const API_URL = process.env.REACT_APP_API_URL

// get roles
export const getRoles = () => get(url.GET_ROLES)

// get roles
export const getAdminDashboard = () => get(url.GET_ADMIN_DASHBOARD_DATA)

// Register Method
export const postJwtRegister = data => {
  return axios
    .post(API_URL + url.POST_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message = err.response.data.message
            break
          case 401:
            message = "Invalid credentials"
          case 400:
            message = err.response.data.message
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
export const postJwtLogin = (data, type) =>
  post(type === "admin" ? url.POST_JWT_ADMIN_LOGIN : url.POST_JWT_LOGIN, data)

// Forget Password Method
export const postJwtForgetPwd = data => post(url.POST_JWT_PASSWORD_FORGET, data)
export const resetPwd = data => patch(url.RESET_PASSWORD, data)
export const resetPwdHr = data => patch(`/users/updateHrPassword/${data._id}`, data)
export const checkUserbyPhone = data => post('/users/check-user-by-phone', data)



/** Mock Tests */

// get mock test
export const getMockTests = queryParams => get(`/users/mockTest${queryParams}`)
// update mock test
export const updateMockTest = mockTest => patch(`/users/mockTest`, mockTest)

/** get subscription users by test id */
export const getSubscriptionUsersByTestId = (testId, queryParams) => {
  if (queryParams) {
    return get(`/tests/${testId}/users${queryParams}`)
  } else {
    return get(`/tests/${testId}/users`)
  }
}

/** Students */
// add Student
export const addNewStudent = student =>
  post(url.GET_STUDENTS, student, {
    headers: {
      "Content-Type": "application/json ",
    },
  })

// update Student
export const updateStudent = (student, studentId) => {
  return patch(`${url.GET_STUDENTS}/${studentId}`, student, {
    headers: {
      "Content-Type":
        !student.image || typeof student?.image?.name == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })
}

// delete Student
export const deleteStudent = studentId =>
  del(`${url.GET_STUDENTS}/${studentId}`, { headers: { studentId } })

// get student
export const getStudents = queryParams =>
  get(`${url.GET_STUDENTS}?roleName=student${queryParams}`)

// get student details
export const getStudentsDetails = id =>
  get(`${url.GET_STUDENTS}/${id}`, { params: { id } })

export const getStudentProfileDetails = id => get(`/users/${id}/profile`)

// /testResult/:testId/:studentId
//  get(`/users/${studentId}/tests`)
export const getStudentTestResult = studentId =>
  get(`/users/${studentId}/tests`)

export const getCoursesResult = studentId => get(`/courses/user/${studentId}`)

export const getStudentCertificateInfo = (studentId, courseId) =>
  get(`/courses/${studentId}/certificate?courseId=${courseId}`)


/** Colleges */
// add College
export const addNewCollege = college =>
  post(url.GET_COLLEGES, college, {
    headers: {
      "Content-Type":
        !college.image || typeof college?.image?.name == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })

// update College
export const updateCollege = (slug, college, collegeId) =>
  post(`/${slug}/${collegeId}`, college, {
    headers: {
      "Content-Type": "application/json",
    },
  })

// delete College
export const deleteCollege = collegeId =>
  del(`${url.GET_COLLEGES}/${collegeId}`, { headers: { collegeId } })

// get college
export const getColleges = queryParams => {
  if (queryParams) {
    return get(`${url.GET_COLLEGES}?roleName=college${queryParams}`)
  } else {
    return get(`${url.GET_COLLEGES}?roleName=college`)
  }
}

// get college details
export const getCollegesDetails = (id, slug) => get(`${slug}/${id}`)

/** Coachings */
// add Coaching
export const addNewCoaching = coaching =>
  post(url.GET_COACHINGS, coaching, {
    headers: {
      "Content-Type":
        !coaching.image || typeof coaching?.image?.name == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })

// update Coaching
export const updateCoaching = (slug, coaching, coachingId) =>
  post(`/${slug}/${coachingId}`, coaching, {
    headers: {
      "Content-Type": "application/json",
    },
  })

// delete Coaching
export const deleteCoaching = coachingId =>
  del(`${url.GET_COACHINGS}/${coachingId}`, { headers: { coachingId } })

// get coaching
export const getCoachings = queryParams => {
  if (queryParams) {
    return get(`${url.GET_COACHINGS}?roleName=coaching${queryParams}`)
  } else {
    return get(`${url.GET_COACHINGS}?roleName=coaching`)
  }
}

// get coaching details
export const getCoachingsDetails = (id, slug) => get(`${slug}/${id}`)

/** Companies */
// add Company
export const addNewCompany = company =>
  post(url.GET_COMPANIES, company, {
    headers: {
      "Content-Type":
        !company.image || typeof company?.image?.name == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })

// update Company
export const updateCompany = (slug, company, companyId) =>
  post(`/${slug}/${companyId}`, company, {
    headers: {
      "Content-Type": "application/json",
    },
  })

// delete Company
export const deleteCompany = companyId =>
  del(`${url.GET_COMPANIES}/${companyId}`, { headers: { companyId } })

// get company
export const getCompanies = queryParams =>
  get(`${url.GET_COMPANIES}?roleName=company${queryParams}`)

// get company details
export const getCompaniesDetails = (id, slug) => get(`${slug}/${id}`)
// get company billing details
export const getCompanyBillingDetails = (id) => get(`/company/billing/${id}`)
// add company billing details
export const addCompanyBillingDetails = (id,data) => post(`/company/billing/${id}`,data)
// update company billing details
export const updateCompanyBillingDetails = (id,data) => patch(`/company/billing/${id}`,data)

// get company  profile details
export const getProfileDetails = () => get(`/users/me`)

// update company profile details
export const updateProfileDetails = (data, id) => {
  return patch(`/users/updateMe`, data, {
    headers: {
      "Content-Type":
        !data.image || typeof data?.image == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })
}


/** Skills */
// add Skill
export const addNewSkill = skill => post(url.GET_SKILLS, skill)

// update Skill
export const updateSkill = (skill, skillId) =>
  patch(`${url.GET_SKILLS}/${skillId}`, skill)

// delete Skill
export const deleteSkill = skillId =>
  del(`${url.GET_SKILLS}/${skillId}`, { headers: { skillId } })

// get skill
export const getSkills = userId => {
  if (userId) {
    return get(`${url.GET_SKILLS}`)
  } else {
    return get(`${url.GET_SKILLS}`)
  }
}
// get User skills
export const getUserSkills = (userId, queryParams) => {
  if (queryParams) {
    return get(`${url.GET_SKILLS}/admin/${userId}${queryParams}`)
  } else {
    return get(`${url.GET_SKILLS}/admin/${userId}`)
  }
}

// get skill details
export const getSkillsDetails = id =>
  get(`${url.GET_SKILLS}/${id}`, { params: { id } })


/** Questions */
// add Question
export const addNewQuestion = (question, testId, type, courseId, chapterId) =>
  post(
    type === "test"
      ? `${url.GET_QUESTIONS}/${testId}`
      : `${url.GET_QUESTIONS}/${courseId}/${chapterId}`,
    question
  )

// update Question
export const updateQuestion = (question, questionId) =>
  patch(`${url.GET_QUESTIONS}/${questionId}`, question)

// delete Question
export const deleteQuestion = questionId =>
  del(`${url.GET_QUESTIONS}/${questionId}`, { headers: { questionId } })

// get question
export const getQuestions = (type, chapterId) =>
  get(`${type}/${chapterId}${url.GET_QUESTIONS}?chapterId=${chapterId}`)

// get question details
export const getQuestionsDetails = id =>
  get(`${url.GET_QUESTIONS}/${id}`, { params: { id } })

// get flashCard by chapter id
export const getFlashCardByChapterId = (chapterId, queryParams) => {
  if (queryParams) {
    return get(`/flashCard/chapter/${chapterId}${queryParams}`)
  } else {
    return get(`/flashCard/chapter/${chapterId}`)
  }
}

// add flashCard to in chapter
export const addNewFlashCard = flashCard =>
  post("/flashCard", flashCard, {
    headers: {
      "Content-Type": "application/json",
    },
  })

// update flashCard to in chapter
export const UpdateFlashCard = (flashCard, flashCardId) =>
  patch(`/flashCard/${flashCardId}`, flashCard, {
    headers: {
      "Content-Type": "application/json",
    },
  })

// delete flashCard to in chapter
export const deleteFlashCard = flashCardId =>
  del(`/flashCard/${flashCardId}`, { headers: { flashCardId } })

// get Chapter Content by chapter id
export const getChapterContentByChapterId = chapterId =>
  get(`/chapter-content/chapter/${chapterId}`)

// add Chapter Content to in chapter
export const addNewChapterContent = ChapterContent =>
  post("/chapter-content", ChapterContent, {
    headers: {
      "Content-Type":
        ChapterContent.pdfContent == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })

// update Chapter Content to in chapter
export const updateChapterContent = (ChapterContent, contentId) =>
  patch(`/chapter-content/${contentId}`, ChapterContent, {
    headers: {
      "Content-Type":
        ChapterContent.pdfContent == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })

// delete Chapter Content to in chapter
export const deleteChapterContent = contentId =>
  del(`/chapter-content/${contentId}`, { headers: { contentId } })

// get Multi-Media Content
export const getMultiMediaContent = params => {
  if (params) {
    return get(`/multi-media${params}`)
  } else {
    return get(`/multi-media`)
  }
}

// add Multi-Media Content
export const addNewMultiMediaContent = multiMediaContent =>
  post("/multi-media", multiMediaContent, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

// update Multi-Media Content
export const updateMultiMediaContent = (multiMediaContent, multiMediaId) =>
  patch(`/multi-media/${multiMediaId}`, multiMediaContent, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

// delete Multi-Media Content from chapter
export const deleteMultiMediaContent = multiMediaId =>
  del(`/multi-media/${multiMediaId}`, { headers: { multiMediaId } })
