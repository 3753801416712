import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Card, Row, Col, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { userProfileFrame } from "../StudentManagement/Utills"
// Import image assets here
import placeholder from "../../assets/images/users/img_placeholder.png"
import AppSelect from "../../components/Common/AppSelect"
import ImageProfile from "../../components/Common/ImageProfile"
import { unlockApplicantsStatus as onUnlockApplicantsStatus } from "../../store/actions"
import toastr from "toastr"

const ListItem = ({
  applicant,
  handleDropDown,
  link,
  showJobdetail = false,
  showJobList = false,
  showskills = true,
  showEmailPhone = true,
  // showLockProfile = true,
}) => {
  const staticURL = process.env.REACT_APP_STATIC_URL
  const dispatch = useDispatch()
  const [showAllSkills, setShowAllSkills] = useState(false)
  const [isUnlock, setIsUnlock] = useState(false)

  const { credit } = useSelector(state => ({
    credit: state.companies.credit,
  }))

  const total = applicant?.userMockTestMarks

  const percentage = total / 100
  const filledStars = Math.floor(percentage * 5)

  const remainder = percentage * 5 - filledStars
  const hasHalfStar = remainder >= 0.5

  const getStatusColor = status => {
    switch (status) {
      case "awaitingReview":
        return "orange"
      case "reviewed":
        return "green"
      case "shortlist":
        return "green"
      case "rejected":
        return "red"
      case "hired":
        return "green"
      case "hold":
        return "#0abde3"
      default:
        return ""
    }
  }

  const handleCandidateLock = () => {
    if (credit?.profileCredit > 0) {
      applicant.isUnlock = !isUnlock
      setIsUnlock(!isUnlock)
      dispatch(onUnlockApplicantsStatus(applicant._id, applicant))
    } else {
      toastr.error("your Profile Credit Limit Ended.")
    }
  }

  const userProfile = applicant ? userProfileFrame(applicant) : ""

  return (
    <div>
      {applicant && Object.keys(applicant).length && (
        <Card>
          <CardBody>
            <Row>
              <Col className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <>
                    <Link to={`${link}/${applicant.studentId}`}>
                      <ImageProfile
                        imageUrl={
                          applicant?.image
                            ? staticURL + applicant?.image
                            : placeholder
                        }
                        showFrame={userProfile}
                      />
                    </Link>
                    <Link to={`${link}/${applicant.studentId}`}>
                      <h5 className="text-dark mt-2 ms-2">{applicant?.name}</h5>
                    </Link>
                  </>

                  <div className="user_ratings d-flex align-items-center gap-1 ms-2">
                    {[...Array(5)].map((_, index) => {
                      if (index < filledStars) {
                        return (
                          <i
                            key={index}
                            style={{ color: "gold", fontSize: "15px" }}
                            className="bx bxs-star"
                          ></i>
                        )
                      } else if (index === filledStars && hasHalfStar) {
                        return (
                          <i
                            key={index}
                            style={{ color: "gold", fontSize: "15px" }}
                            className="bx bxs-star-half"
                          ></i>
                        )
                      } else {
                        return (
                          <i
                            key={index}
                            style={{ color: "gold", fontSize: "15px" }}
                            className="bx bx-star"
                          ></i>
                        )
                      }
                    })}
                  </div>
                  {/* {showLockProfile && (
                    <>
                      {applicant?.isUnlock ? (
                        <span>
                          <i
                            className="bx bxs-lock-open ms-2"
                            style={{ fontSize: "18px", cursor: "pointer" }}
                          ></i>
                        </span>
                      ) : (
                        <span onClick={handleCandidateLock}>
                          <i
                            className="bx bxs-lock ms-2"
                            style={{ fontSize: "18px", cursor: "pointer" }}
                          ></i>
                        </span>
                      )}
                    </>
                  )} */}
                </div>
                <div className="mt-2">
                  {showEmailPhone && (
                    <>
                      <h5 className="text-dark">
                        <i className="mdi mdi-email-outline font-size-18" /> :{" "}
                        {applicant?.email}
                      </h5>
                      <h5 className="text-dark">
                        {" "}
                        <i className="mdi mdi-phone-outline font-size-18" /> :{" "}
                        {applicant?.phone}
                      </h5>
                    </>
                  )}
                </div>
              </Col>
              {showskills && (
                <Col>
                  {handleDropDown && (
                    <>
                      <b>Applicant status : {applicant?.applicantStatus}</b>
                      <AppSelect
                        options={[
                          {
                            label: "Awaiting for Review",
                            value: "awaitingReview",
                          },
                          { label: "Under Review ", value: "reviewed" },
                          { label: "Shortlist", value: "shortlist" },
                          { label: "Rejected", value: "rejected" },
                          { label: "Hired", value: "hired" },
                          { label: "Hold", value: "hold" },
                        ]}
                        value={applicant?.applicantStatus}
                        onChange={e => handleDropDown(e, applicant)}
                      />
                    </>
                  )}
                  <h5>Skills</h5>
                  {applicant &&
                    applicant?.skills &&
                    applicant?.skills.length > 0 && (
                      <>
                        {showAllSkills ? (
                          <>
                            {applicant?.skills
                              .filter(s => s !== null)
                              .map((skill, index) => (
                                <span key={index} className="skill_tag">
                                  {skill?.name}
                                </span>
                              ))}
                            {applicant.skills.length > 6 && (
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => setShowAllSkills(false)}
                                style={{ cursor: "pointer" }}
                              >
                                See Less
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {applicant?.skills
                              .filter(s => s !== null)
                              .slice(0, 6)
                              .map((skill, index) => (
                                <span key={index} className="skill_tag">
                                  {skill?.name}
                                </span>
                              ))}
                            {applicant.skills.length > 6 && (
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => setShowAllSkills(true)}
                                style={{ cursor: "pointer" }}
                              >
                                See More
                              </span>
                            )}
                          </>
                        )}
                      </>
                    )}
                </Col>
              )}
              {showJobdetail && (
                <Col className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <Link to={`/jobs-details/${applicant?.jobDetail?._id}`}>
                      <img
                        src={
                          applicant?.jobDetail?.image
                            ? staticURL + applicant?.jobDetail?.image
                            : placeholder
                        }
                        width={70}
                        height={70}
                        alt="applicant"
                      />
                    </Link>
                    <Link to={`/jobs-details/${applicant?.jobDetail?._id}`}>
                      <h5 className="text-dark mt-2 ms-2">
                        {applicant?.jobDetail?.jobTitle}
                      </h5>
                    </Link>
                  </div>
                  <div
                    className="job_description"
                    dangerouslySetInnerHTML={{
                      __html: applicant?.jobDetail?.description.substring(
                        0,
                        500
                      ),
                    }}
                  ></div>
                </Col>
              )}

              {showJobList && (
                <Col className="d-flex flex-column">
                  <h5>Job Applied List</h5>
                  {applicant?.appliedJobsByUser &&
                  applicant?.appliedJobsByUser.length > 0
                    ? applicant?.appliedJobsByUser
                        .filter(appliedJob => appliedJob?.jobDetails != null)
                        .map((appliedJob, key) => {
                          return (
                            <div key={key}>
                              <div
                                className="d-flex align-items-center"
                                key={key}
                              >
                                <Link
                                  to={
                                    appliedJob?.jobDetails
                                      ? `/jobs-details/${appliedJob?.jobDetails?._id}`
                                      : ""
                                  }
                                >
                                  <img
                                    src={
                                      appliedJob?.jobDetails?.image
                                        ? staticURL +
                                          appliedJob?.jobDetails?.image
                                        : placeholder
                                    }
                                    width={70}
                                    height={70}
                                    alt="appliedJob"
                                  />
                                </Link>
                                <Link
                                  to={
                                    appliedJob?.jobDetails
                                      ? `/jobs-details/${appliedJob?.jobDetails?._id}`
                                      : ""
                                  }
                                >
                                  <h5 className="text-dark mt-2 ms-2">
                                    {appliedJob?.jobDetails?.jobTitle}
                                  </h5>
                                </Link>
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{
                                    backgroundColor: getStatusColor(
                                      appliedJob?.status
                                    ),
                                    borderRadius: "5px",
                                    padding: "2px 5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <h6
                                    className={`mt-2 ms-2}`}
                                    style={{
                                      color: "#fff",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {appliedJob?.status}
                                  </h6>
                                </div>
                              </div>
                              <div
                                className="job_description"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    appliedJob?.jobDetails?.description.substring(
                                      0,
                                      500
                                    ),
                                }}
                              ></div>
                            </div>
                          )
                        })
                    : "No Job Applied"}
                </Col>
              )}

              {showJobdetail && (
                <Col className="d-flex flex-column">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      backgroundColor: getStatusColor(applicant?.status),
                      width: "150px",
                      borderRadius: "5px",
                    }}
                  >
                    <h6
                      className={`mt-2 ms-2}`}
                      style={{ color: "#fff", textTransform: "capitalize" }}
                    >
                      {applicant?.status}
                    </h6>
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      )}
    </div>
  )
}

export default ListItem
