import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"


// Crypto Redux States
import {
  GET_TEST_CATEGORIES,
  GET_TEST_CATEGORY_DETAIL,
  ADD_NEW_TEST_CATEGORY,
  DELETE_TEST_CATEGORY,
  UPDATE_TEST_CATEGORY,
  GET_TESTS_BY_CATEGORY_ID,
} from "./actionTypes"
import {
  getTestCategoriesSuccess,
  getTestCategoriesFail,
  getTestCategoryDetailSuccess,
  getTestCategoryDetailFail,
  getTestsByCategoryIdSuccess,
  getTestsCategoryByIdFail,
  addTestCategoryFail,
  addTestCategorySuccess,
  updateTestCategorySuccess,
  updateTestCategoryFail,
  deleteTestCategorySuccess,
  deleteTestCategoryFail,
  updateLoadingState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTestCategories,
  getTestCategoriesDetails,
  addNewTestCategory,
  updateTestCategory,
  deleteTestCategory,
  getCategories,
  getTestsByCategoryId
} from "helpers/api/testCategoryApi"

function* fetchTestCategories({ params="" }) {
  try {
    const { page, limit } = params
    let queryParams = `?page=${page}&limit=${limit}`
    if (params?.search && params?.search?.length) {
      queryParams =  `?page=${1}&search=${params?.search}`
    }
    let response
    if (params) {
      response = yield call(getCategories, queryParams)
    } else {
      response = yield call(getCategories)
    }
    yield put(getTestCategoriesSuccess(response.data.testCategories))
  } catch (error) {
    yield put(getTestCategoriesFail(error))
  }
}

function* fetchTestCategoryDetail({ testCategoryId }) {
  try {
    const response = yield call(getTestCategoriesDetails, testCategoryId)
    yield put(getTestCategoryDetailSuccess(response?.data?.data))
  } catch (error) {
    yield put(getTestCategoryDetailFail(error))
  }
}

function* fetchTestsByCategoryId({ testCategoryId }) {
  const response = yield call(getTestsByCategoryId, { testCategoryId: testCategoryId })
  try {
    yield put(getTestsByCategoryIdSuccess(response.data.tests))
    yield put(updateLoadingState(false))
  } catch (error) {
    yield put(getTestsCategoryByIdFail(error))
    yield put(updateLoadingState(false))
  }
}

function* onUpdateTestCategory({ payload: { testCategory, testCategoryId } }) {
  // yield put(updateLoadingState(true))
  try {
    const response = yield call(
      updateTestCategory,
      testCategory,
      testCategoryId
    )
    toastr.success("Category updated successfully..!!")
    yield put(updateTestCategorySuccess({ ...testCategory, ...response.data.data }))
    // yield put(updateLoadingState(false))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateTestCategoryFail(error))
    // yield put(updateLoadingState(false))
  }
}

function* onDeleteTestCategory({payload}) {
  const {testCategory,params}= payload
  try {
    const { page, limit } = params
    const queryParams = `?page=${page}&limit=${limit}`
    yield put(updateLoadingState(true))
    const response = yield call(deleteTestCategory, testCategory)
    toastr.success("Category deleted successfully..!!")
    let res 
    if (params) {
      res = yield call(getCategories, queryParams)
    } else {
      res = yield call(getCategories)
    }
    yield put(updateLoadingState(false))
    yield put(deleteTestCategorySuccess(response))
    yield put(getTestCategoriesSuccess(res.data.testCategories))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateLoadingState(false))
    yield put(deleteTestCategoryFail(error))
  }
}

function* onAddNewTestCategory({ payload: testCategory }) {
  try {
    yield put(updateLoadingState(true))
    const response = yield call(addNewTestCategory, testCategory)
    toastr.success("Category added successfully..!!")
    yield put(updateLoadingState(false))
    yield put(addTestCategorySuccess(response.data.data))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateLoadingState(false))
    yield put(addTestCategoryFail(error))
  }
}

function* testCategoriesSaga() {
  yield takeEvery(GET_TEST_CATEGORIES, fetchTestCategories)
  yield takeEvery(GET_TEST_CATEGORY_DETAIL, fetchTestCategoryDetail)
  yield takeEvery(ADD_NEW_TEST_CATEGORY, onAddNewTestCategory)
  yield takeEvery(UPDATE_TEST_CATEGORY, onUpdateTestCategory)
  yield takeEvery(DELETE_TEST_CATEGORY, onDeleteTestCategory)
  yield takeEvery(GET_TESTS_BY_CATEGORY_ID, fetchTestsByCategoryId)
}

export default testCategoriesSaga
