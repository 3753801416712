export const ApplicantsColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Contact",
    name: "phone",
    key: "DEFAULT",
    link: "/student-details",
  },
  // {
  //   header: "Applicant Status",
  //   key: "DROPDOWN",
  //   options: [
  //     { label: "Awaiting Review", value: "awaitingReview" },
  //     { label: "Rreviewed ", value: "reviewed" },
  //     { label: "Shortlist", value: "shortlist", },
  //     { label: "Contacting", value: "contacting" },
  //     { label: "Rejected", value: "rejected" },
  //     { label: "Hired", value: "hired" }
  //   ],
  //   condition: { disabled: ["hired", "rejected"] },
  //   name: "applicantStatus"
  // }
]

export const candidatesColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Candidates",
    name: "image",
    key: "IMAGE_WITH_RATING",
    link: "/profile/view/candidate",
  },
  {
    header: "Contact Info",
    name: "phone",
    key: "UNLOCKED_INFO",
  },
  {
    header: "Top Skills",
    name: "skillNames",
    key: "SKILLS_INFO",
    link: "/profile/view/candidate",
  },
  {
    header: "Job Applied",
    name: "jobCount",
    key: "JOB_INFO",
    link: "/user-applied-joblist",
    defaultId: "studentId",
  },
]

export const unlockedCandidatesColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Candidates",
    name: "image",
    key: "IMAGE_WITH_RATING",
    link: "/profile/view/candidate",
  },
  {
    header: "Contact Info",
    name: "phone",
    key: "UNLOCKED_INFO",
  },
  {
    header: "Top Skills",
    name: "skillNames",
    key: "SKILLS_INFO",
    link: "/profile/view/candidate",
  },
  {
    header: "Unlocked By",
    name: "unlockedByName",
    key: "DEFAULT",
  },
]

export const myCandidatesColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Applicants",
    name: "image",
    key: "IMAGE_WITH_RATING",
    link: "/profile/view/candidate",
  },
  {
    header: "Contact Info",
    name: "phone",
    key: "UNLOCKED_INFO",
  },
  {
    header: "Top Skills",
    name: "skillNames",
    key: "SKILLS_INFO",
    link: "/profile/view/candidate",
  },
  {
    header: "Job Applied",
    name: "jobCount",
    key: "JOB_INFO",
    link: "/user-applied-joblist",
    defaultId: "studentId",
  },
]

export const userJobAppliedcolumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Company Name",
    name: "companyName",
    key: "DEFAULT",
  },
  {
    header: "Job Title",
    name: "jobTitle",
    key: "DEFAULT",
    // defaultId: "testId",
    link: "/jobs-details",
  },
  {
    header: "Salary Range",
    name: "salaryRange",
    key: "DEFAULT",
  },
  {
    header: "Location",
    name: "location",
    key: "DEFAULT",
  },
  {
    header: "Experience",
    name: "experience",
    key: "DEFAULT",
  },
  {
    header: "Job Type",
    name: "type",
    key: "DEFAULT",
  },
  {
    header: "Job Status",
    name: "status",
    key: "STATUS",
  },
]
