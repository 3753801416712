import { del, get, post, patch } from "../api_helper"
import {
  SUBSCRIPTIONS_ROUTES,
  MOCK_TEST_PRICE_ROUTES,
  COMPANY_ROUTES,
} from "../url_helper"

export const createPlan = plan =>
  post(`${SUBSCRIPTIONS_ROUTES}`, plan, {
    headers: { "Content-Type": "application/json" },
  })

export const updatePlan = (plan, planId) =>
  patch(`${SUBSCRIPTIONS_ROUTES}/${planId}`, plan, {
    headers: { "Content-Type": "application/json" },
  })

export const getPlanDetails = planId =>
  get(`${SUBSCRIPTIONS_ROUTES}/${planId}`, {
    headers: { "Content-Type": "application/json" },
  })

export const deletePlan = planId =>
  del(`${SUBSCRIPTIONS_ROUTES}/${planId}`, {
    headers: { "Content-Type": "application/json" },
  })

export const getPlans = queryParams =>
  get(`${SUBSCRIPTIONS_ROUTES}${queryParams}`)

export const getCompanyPlan = () => get(`${COMPANY_ROUTES}/plan`)

// Get Subscriptions by id
export const getSubscriptions = SubscriptionsId =>
  get(`${SUBSCRIPTIONS_ROUTES}/${SubscriptionsId}`)

// Get User Subscriptions
export const getUserSubscriptions = () =>
  get(`${COMPANY_ROUTES + SUBSCRIPTIONS_ROUTES}/`)

// Get User Subscriptions
export const getAllCompanyPlan = queryParams =>
  get(`${SUBSCRIPTIONS_ROUTES + queryParams}`)

// add User Subscriptions
export const addUserSubscriptions = data =>
  post(`${COMPANY_ROUTES + SUBSCRIPTIONS_ROUTES}/`, data)

export const CheckUserSubscriptionsPlan = slug =>
  get(`${COMPANY_ROUTES}/check-plan?slug=${slug}`)

export const getMockTestPriceApi = () => get(`${MOCK_TEST_PRICE_ROUTES}`)

export const updateMockTestPriceApi = (id, mockTestPrice) =>
  patch(`${MOCK_TEST_PRICE_ROUTES}/${id}`, mockTestPrice, {
    headers: { "Content-Type": "application/json" },
  })
