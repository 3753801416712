import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  UPDATE_LOADING,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_HR,
  RESET_PASSWORD_HR_SUCCESS,
} from "./actionTypes"

export const loginUser = (user, history, type) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, type },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const setLoading = status => {
  return {
    type: UPDATE_LOADING,
    payload: status,
  }
}

export const resetPassword = (values) => {
  return {
    type: RESET_PASSWORD,
    payload: values,
  }
}

export const resetPasswordForHr = values => {
  return {
    type: RESET_PASSWORD_HR,
    payload: values,
  }
}

export const resetPasswordForHrSuccess = () => {
  return {
    type: RESET_PASSWORD_HR_SUCCESS,
  }
}

export const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
  }
}
