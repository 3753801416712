import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Badge
} from "reactstrap"
import imgClock from "../../assets/images/icons/imgClock.svg"
import arrowRight from "../../assets/images/icons/arrowRight.png"
import Breadcrumb from "../../components/Common/Breadcrumb"
import AppTabs from "../../components/Common/Tabs"
import CardProfile from "../../components/Common/Card/ProfileHeader"
import CardAbout from "../../components/Common/Card/CardAbout"

import withRouter from "components/Common/withRouter"
import {
  getCourseDetail as onGetCourseDetail,
  resetCourse as onResetCourse,
} from "../../store/courses/actions"
//redux
import { useSelector, useDispatch } from "react-redux"

const staticURL = process.env.REACT_APP_STATIC_URL
import Avatar from "../../assets/images/users/img_placeholder.png"


const CourseDetails = props => {
  document.title = "Course Detail | Skote - React Admin & Dashboard Template"
  const dispatch = useDispatch()

  const { courseDetail } = useSelector(state => ({
    courseDetail: state.courses.courseDetail,
  }))
  const [chapterPdfUrl, setChapterPdfUrl] = useState("")
  const [selectChapter, setSelectChapter] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [activeChapterIndex, setActiveChapterIndex] = useState(0)

  const params = props.router.params

  useEffect(() => {
    if (Object.keys(courseDetail).length) {
      setIsLoading(false)
      handleImageChange(
        courseDetail?.chapters && courseDetail?.chapters.length > 0
          ? courseDetail?.chapters[0]
          : null
      )
    }
  }, [courseDetail])

  useEffect(() => {
    if (params && params.id) {
      dispatch(onGetCourseDetail(params.id))
    }
    return () => {
      dispatch(onResetCourse())
      setIsLoading(false)
    }
  }, [dispatch, onGetCourseDetail])

  const handleImageChange = chapter => {
    const pdfUrl =
      typeof chapter?.document === "string" &&
        Array.isArray(JSON.parse(chapter?.document)) &&
        JSON.parse(chapter?.document).length
        ? staticURL + JSON.parse(chapter?.document)[0]
        : ""
    setChapterPdfUrl(pdfUrl.includes(".pdf") ? pdfUrl : "")
    setSelectChapter(chapter)
  }

  const handleViewTest = (chapter) => {
    if (chapter.tests && chapter.tests.length > 0) {
      props.router.navigate(
        `/questions-view/chapters/${chapter.tests[0]._id}`
      )
    }
  }

  const handleChapterClick = (chapter, index) => {
    setActiveChapterIndex(index) // Set the active chapter index
    handleImageChange(chapter)
  }
  const tabs =
    courseDetail?.chapters?.length > 0
      ? courseDetail?.chapters.map((chapter, key) => {
        const pdfUrl =
          typeof chapter?.document === "string" &&
            Array.isArray(JSON.parse(chapter?.document)) &&
            JSON.parse(chapter?.document).length
            ? staticURL + JSON.parse(chapter?.document)[0]
            : ""
        return {
          id: `tab${key + 1}`,
          label: chapter.name,
          content: (
            <Col lg={12}>
              <div className="pt-3 px-2">
                <Row className="align-items-center">
                  <Col xs="auto">
                    <h2 style={{ fontSize: "17px" }}>{chapter ? chapter.name : ""}</h2>
                  </Col>
                  <Col xs="auto">
                    <p
                      style={{ fontSize: "15px" }}
                      className={`${chapter.status === "active"
                        ? "text-success"
                        : "text-danger"
                        }`}
                    >
                    </p>
                  </Col>
                  {chapter?.tests.length > 0 &&
                    <Col xs="auto">
                      <p onClick={() => handleViewTest(chapter)} style={{ fontWeight: "600", cursor: "pointer" }}>
                        View Test &nbsp;
                        <i
                          className="mdi mdi-eye-outline font-size-15"
                          id="viewTooltip"
                        />
                      </p>
                    </Col>
                  }
                </Row>
                {pdfUrl ? (
                  <iframe src={pdfUrl} height={350} width={"100%"} />
                ) : (
                  "No Preview PDF"
                )}
              </div>
            </Col>
          ),
        }
      })
      : []

  const imageUrl = courseDetail?.image ? staticURL + courseDetail?.image : Avatar


  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb
              title="CourseList"
              breadcrumbItem="Course Profile"
              navigate="/courses-list"
            />
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner className="ms-2" color="dark" size="sm" />
              </div>
            ) : (
              <>
                <CardProfile
                  imageUrl={imageUrl}
                  data={courseDetail}
                  profileName={
                    courseDetail?.courseName ? courseDetail?.courseName : ""
                  }
                  profileEmail={courseDetail?.type ? courseDetail.type : ""}
                  onclick={() => navigate(-1)}
                  LayoutType={"HORIZONTAL"}
                  style={{ padding: 0 }}
                  showStatus={true}
                />
                <CardAbout
                  title="Course Description"
                  descripion={courseDetail?.description}
                  isHtml={true}
                />
                <Card>
                  <AppTabs tabsArray={tabs} tabLayout="VERTICAL_TAB" />
                </Card>
              </>
            )}
            {/* {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner className="ms-2" color="dark" size="sm" />
              </div>
            ) : (
              <Row>
                <Col lg={5}>
                  <Card>
                    <CardBody>
                      <div className="pt-3 px-2">
                        <div>
                          <div className="course_heading">
                            <h4 className="course_title">
                              {courseDetail.courseName}
                            </h4>
                          </div>
                          <div className="course_chapters_list">
                            <div className="chapter_list_progress">
                              <ul className="StepProgress">
                                {courseDetail?.chapters &&
                                  courseDetail.chapters.map(
                                    (chapter, index) => {
                                      const isActive =
                                        index === activeChapterIndex
                                      return (
                                        <li
                                          key={index}
                                          className={`StepProgress-item ${
                                            isActive ? "active-chapter" : ""
                                          }`}
                                          onClick={() =>
                                            handleChapterClick(chapter, index)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div className="course_chapter">
                                            <h4 className="chapter_name">
                                              {chapter.name}
                                            </h4>
                                            <p className="chapter_info mb-0">
                                              <span>
                                                {chapter.tests.length}
                                              </span>{" "}
                                              Topics
                                            </p>
                                          </div>
                                        </li>
                                      )
                                    }
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={7}>
                  <div>
                    <Card>
                      <CardBody>
                        <div className="pt-3 px-2 d-flex flex-column justify-content-center">
                          <h2>{selectChapter ? selectChapter.name : ""}</h2>
                          {chapterPdfUrl ? (
                            <iframe
                              src={chapterPdfUrl}
                              height={698}
                              width={"100%"}
                            />
                          ) : (
                            "No Preview PDF"
                          )}
                        </div>
                      </CardBody>
                    </Card>

                    {selectChapter.tests.length > 0 && (
                      <Card>
                        <CardBody style={{ padding: 10 }}>
                          <div className="px-2 d-flex justify-content-center">
                            <Button
                              onClick={handleViewTest}
                              className="btn btn-primary global_btn me-1 px-5"
                            >
                              View Test &nbsp;
                              <img src={arrowRight} height={12} width={12} />
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    )}
                  </div>
                </Col>
              </Row>
            )} */}
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default withRouter(CourseDetails)
