import React, { useEffect, useState, useRef } from "react"
import { Card, CardBody, Button } from "reactstrap"
import AppLoader from "../Loader/index"
const staticURL = process.env.REACT_APP_STATIC_URL
function CardResume({ data, loading = false }) {
  const pdfRef = useRef()
  const [pdfError, setPdfError] = useState(false)

  useEffect(() => {
    if (data?.resume && data?.resume.includes("default-resume.pdf")) {
      setPdfError(true)
    }
  }, [data?.resume])

  const converFileName = filePath => {
    const fileName = filePath.substring(filePath.lastIndexOf("/") + 1)
    return fileName
  }

  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const formattedDate = `${day}/${month}/${year.toString().slice(-2)}`
    return formattedDate
  }

  const handleDownload = () => {
    if (data?.resume) {
      // Create a hidden anchor element
      const link = document.createElement("a")
      link.href = staticURL + data?.resume
      link.target = "_blank" // Open the link in a new tab
      link.download = "resume.pdf" // Specify the download file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up the element
    }
  }

  return (
    <Card style={{ borderRadius: 15 }}>
      <CardBody>
        {loading ? (
          <AppLoader />
        ) : (
          <div className="pt-3 px-2">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="sun_heading mb-0">Resume</h4>
                    {data?.resume && !pdfError && (
                      <>
                        <Button
                          onClick={handleDownload}
                          type="button"
                          color="primary"
                          className="btn btn-outline-primary btn_outline_bgwhite"
                        >
                          <i className="bx bx-download font-size-16 align-middle me-2"></i>
                          Download
                        </Button>
                      </>
                    )}
                  </div>

                  <div className="full_devider my-2"></div>
                  <div className="me-4 mt-2">
                    {data.resume && !pdfError && (
                      <>
                        <h5 className="resume_name">
                          {data?.resume ? converFileName(data?.resume) : ""}{" "}
                          &#x2022;{" "}
                          <span>
                            {" "}
                            Updated on {formatDateToDDMMYY(data?.updatedAt)}
                          </span>
                        </h5>
                        <iframe
                          src={staticURL + data.resume}
                          height={150}
                          width={300}
                          onLoad={() => {
                            // Content loaded successfully, clear the error
                            setPdfError(false)
                          }}
                          id="iframePdf"
                          ref={pdfRef}
                          // ref={(iframe) => {
                          //     if (iframe && iframe.contentDocument && iframe.contentDocument.body) {
                          //         const bodyContent = iframe.contentDocument.body.innerHTML;

                          //         if (bodyContent.includes("Cannot GET")) {
                          //             setPdfError(true);
                          //         }
                          //     }
                          // }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default CardResume
