export const columns = [
  {
    header: "#",
    key: ""
  },
  {
    header: "Course Name",
    name: "courseName",
    key: "DEFAULT"
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT"
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT"
  },
  {
    header: "Number",
    name: "number",
    key: "DEFAULT",
  },
  {
    header: "Description",
    name: "description",
    key: "DESCRIPTION",
  },
  {
    header: "Created Date",
    name: "createdAt",
    key: "DATE"
  },
  {
    header: "Request",
    key: "DROPDOWN",
    options: [
      { label: "Pending", value: "pending" },
      { label: "Inprogress ", value: "inprogress" },
      { label: "Completed", value: "completed", },
      { label: "Rejected", value: "rejected" },
      { label: "Requested", value: "requested" },
      { label: "Not Reviewed", value: "notReviewed" }
    ],
    condition: { disabled: ["completed", "rejected"] },
    name: "status"
  },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "View FeedBack", type: "VIEW" },
    ],
  }
]

export const formFields = [
  {
    name: 'educational',
    label: 'Educational Background',
    type: 'select',
    options: [
      { value: "0", label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    required: true,
  },
  {
    name: 'technical',
    label: 'Technical Qualifications',
    type: 'select',
    options: [
      { value: "0", label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    required: true,
  },
  {
    name: 'workExperience',
    label: 'Prior Work Experience',
    type: 'select',
    options: [
      { value: "0", label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    required: true,
  },
  {
    name: 'communicationSkills',
    label: 'Communication Skills',
    type: 'select',
    options: [
      { value: "0", label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    required: true,
  },
  {
    name: 'confidence',
    label: 'Confidence',
    type: 'select',
    options: [
      { value: "0", label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    required: true,
  },
  {
    name: 'enthusiasm',
    label: 'Candidate Enthusiasm',
    type: 'select',
    options: [
      { value: "0", label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    required: true,
  },
  {
    name: 'leadership',
    label: 'Leadership Ability',
    type: 'select',
    options: [
      { value: "0", label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    required: true,
  },
  {
    name: 'overallImpression',
    label: 'Overall Impression and Recommendation',
    type: 'select',
    options: [
      { value: "0", label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    required: true,
  },
  // {
  //   name: 'comments',
  //   label: 'Other comments',
  //   type: 'text',
  //   placeholder: 'Add any other comments here',
  //   required: false,
  // },
];