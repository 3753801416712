export const columns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Referred By",
    name: "senderId.name",
    key: "DEFAULT",
    link: "/student-details",
    defaultId: "JobReferralId",
  },
  {
    header: "Referred To",
    name: "invitedUser.email",
    key: "DEFAULT",
  },
  {
    header: "Referral Type",
    name: "referraltype",
    key: "DEFAULT",
  },
  {
    header: "Registration Status",
    name: "isRegistered",
    key: "STATUS",
  },
  {
    header: "Job Apply",
    name: "isAppliedTOJob",
    key: "STATUS",
  },
]

export const JobReferralColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Referred To",
    name: "invitedUser.email",
    key: "DEFAULT",
  },
  {
    header: "Job Title",
    name: "jobDetail.jobTitle",
    key: "DEFAULT",
  },
  {
    header: "Company Name",
    name: "companyDetail.name",
    key: "DEFAULT",
  },
  {
    header: "Referral Type",
    name: "referraltype",
    key: "DEFAULT",
  },
  {
    header: "Registration Status",
    name: "isRegistered",
    key: "STATUS",
  },
  {
    header: "Job Apply",
    name: "isAppliedTOJob",
    key: "STATUS",
  },
]
