import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { columns, formFields } from "./constants"
import {
  updateMockTest as onUpdateMockTest,
  getMockTests as onGetMockTests,
} from "../../store/mockTests/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import FormModal from "../../components/Common/Modal/FormModal"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import UserFeedBacks from "./UserFeedBacks"
// import Table2 from "../../components/Common/Table/Table2"
// import { GET_MOCK_TESTS } from "../../components/Common/Table/constants";

const MockTestsList = () => {
  document.title = "Test List | QAPRENEUR"
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [mockTestMarks, setMockTestMarks] = useState({
    educational: 0,
    technical: 0,
    workExperience: 0,
    communicationSkills: 0,
    confidence: 0,
    enthusiasm: 0,
    leadership: 0,
    overallImpression: 0,
    comments: 0,
  })
  const [mockTest, setMockTest] = useState(null)
  const [mockTestList, setMockTestList] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const [formModal, setFormModal] = useState(false)
  const [feedBack, setFeedBack] = useState(false)
  const toggle = () => setFormModal(!formModal)

  const { mockTests, loading } = useSelector(state => ({
    mockTests: state?.mockTests?.mockTests,
    loading: state?.mockTests?.loading,
  }))

  const getData = () => {
    const params = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      params.search = search
    }
    dispatch(onGetMockTests(params))
  }

  useEffect(() => {
    getData()
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(mockTests)) {
      setMockTestList(
        mockTests
          .map(item => ({ ...item, ...item.extra }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setMockTestList([])
    }
  }, [mockTests, pageNo])

  const handleDropDown = (e, data) => {
    setMockTest(data)
    data.status = e.target.value
    const pages = document.querySelector(".pagination_curr_page").innerHTML
    if (data.status === "completed") {
      toggle()
    } else {
      const params = {
        page: pages,
        limit: rowPerPage,
      }
      dispatch(
        onUpdateMockTest(
          { ...data, remark: true, mockTestId: data._id, result: data.status },
          navigate,
          params
        )
      )
      // onChangePagination(rowPerPage, pages)
    }
  }

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const onChangePagination = (limit, page) => {
    setRowPerPage(limit)
    setPageNo(page)
  }

  const handleSave = values => {
    const data = {
      marks: values,
      mockTestId: mockTest._id,
      result: mockTest.status,
      ...mockTest,
    }
    const pages = document.querySelector(".pagination_curr_page").innerHTML
    const params = {
      page: pages,
      limit: rowPerPage,
    }
    dispatch(onUpdateMockTest({ ...data, remark: true }, navigate, params))
    setMockTestList([])
    // onChangePagination(rowPerPage, pages)
    toggle()
  }

  const handleActions = (data, type) => {
    if (type === "VIEW") {
      setMockTestMarks(data?.marks)
      setMockTest(data)
      setFeedBack(true)
    }
  }

  const handleModalCloseAction = () => {
    // const pages = document.querySelector('.pagination_curr_page').innerHTML
    // setMockTestList(prev=>prev)
    // onChangePagination(rowPerPage, pages)
    getData()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Mock Tests"
            breadcrumbItem="Mock Interviews Request"
          />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={mockTestList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  handleDropDown={handleDropDown}
                  columns={columns}
                  showPagination={true}
                  onChangePagination={onChangePagination}
                  handleActions={handleActions}
                  handleSearch={handleSearch}
                />
              </div>
            </Col>
          </Row>
          {formModal && (
            <FormModal
              modalBackdrop={formModal}
              setmodalBackdrop={setFormModal}
              modalAction={handleSave}
              title={"Admin FeedBack"}
              formFields={formFields}
              data={mockTestMarks}
              handleModalCloseAction={handleModalCloseAction}
              height={450}
            />
          )}
        </Container>
        {feedBack && (
          <InfomationModal
            modalBackdrop={feedBack}
            setmodalBackdrop={setFeedBack}
            title={"Admin FeedBack"}
            size="xxl"
          >
            <Row>
              <UserFeedBacks marks={mockTestMarks} mockTest={mockTest} />
            </Row>
          </InfomationModal>
        )}
        {/* <Table2
          data={mockTestList}
          columns={columns}
          loading={loading}
          getDataFromAPi={true}
          apiSlug={GET_MOCK_TESTS}
          sortBy="ASC"
          handleDropDown={handleDropDown}
          handleActions={handleActions}
        /> */}
      </div>
    </React.Fragment>
  )
}

export default MockTestsList
