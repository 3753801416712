/* COMPANIES */
export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

/* COMPANIES DETAIL*/
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL"
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS"
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL"

/**
 * add course
 */
export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

/**
 * Edit course
 */
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

/**
 * Delete course
 */
export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

export const UPDATE_COMPANY_LOADING_STATE = "UPDATE_COMPANY_LOADING_STATE"

export const RESET_COMPANY = "RESET_COMPANY"


export const GET_COMPANY_BILLING_INFO = "GET_COMPANY_BILLING_INFO"
export const GET_COMPANY_BILLING_INFO_SUCCESS = "GET_COMPANY_BILLING_INFO_SUCCESS"
export const GET_COMPANY_BILLING_INFO_FAIL = "GET_COMPANY_BILLING_INFO_FAIL"

export const ADD_COMPANY_BILLING_INFO = "ADD_COMPANY_BILLING_INFO"
export const ADD_COMPANY_BILLING_INFO_SUCCESS = "ADD_COMPANY_BILLING_INFO_SUCCESS"
export const ADD_COMPANY_BILLING_INFO_FAIL = "ADD_COMPANY_BILLING_INFO_FAIL"

export const UPDATE_COMPANY_BILLING_INFO = "UPDATE_COMPANY_BILLING_INFO"
export const UPDATE_COMPANY_BILLING_INFO_SUCCESS = "UPDATE_COMPANY_BILLING_INFO_SUCCESS"
export const UPDATE_COMPANY_BILLING_INFO_FAIL = "UPDATE_COMPANY_BILLING_INFO_FAIL"

export const GET_JOBS_POSTED_BY_COMPANY = "GET_JOBS_POSTED_BY_COMPANY"
export const GET_JOBS_POSTED_BY_COMPANY_SUCCESS = "GET_JOBS_POSTED_BY_COMPANY_SUCCESS"
export const GET_JOBS_POSTED_BY_COMPANY_FAIL = "GET_JOBS_POSTED_BY_COMPANY_FAIL"

export const GET_COMPANY_SUBSCRIPTION = "GET_COMPANY_SUBSCRIPTION"
export const GET_COMPANY_SUBSCRIPTION_SUCCESS = "GET_COMPANY_SUBSCRIPTION_SUCCESS"
export const GET_COMPANY_SUBSCRIPTION_FAIL = "GET_COMPANY_SUBSCRIPTION_FAIL"

export const GET_COMPANY_ALL_PLAN = "GET_COMPANY_ALL_PLAN"
export const GET_COMPANY_ALL_PLAN_SUCCESS = "GET_COMPANY_ALL_PLAN_SUCCESS"
export const GET_COMPANY_ALL_PLAN_FAIL = "GET_COMPANY_ALL_PLAN_FAIL"

export const GET_USERS_VIEW_HISTORY = "GET_USERS_VIEW_HISTORY"
export const GET_USERS_VIEW_HISTORY_SUCCESS = "GET_USERS_VIEW_HISTORY_SUCCESS"
export const GET_USERS_VIEW_HISTORY_FAIL = "GET_USERS_VIEW_HISTORY_FAIL"

export const GET_COMPANY_CREDIT = "GET_COMPANY_CREDIT"
export const GET_COMPANY_CREDIT_SUCCESS = "GET_COMPANY_CREDIT_SUCCESS"
export const GET_COMPANY_CREDIT_FAIL = "GET_COMPANY_CREDIT_FAIL"