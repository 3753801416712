import React from "react"

function TableSearch({ onChange, value, data, width }) {
  return (
    <div
      className="search-box me-xxl-2  my-xxl-0 d-inline-block"
      style={{ width: width }}
    >
      <div className="position-relative mt-2">
        <label
          htmlFor="search-bar-0"
          className="search-label d-flex align-items-center"
        >
          <span id="search-bar-0-label" className="sr-only">
            Search this table
          </span>
          <input
            onChange={e => {
              onChange(e)
            }}
            id="search-bar-0"
            type="text"
            className="form-control custom_searchbox"
            placeholder={`${data.length} records...`}
            value={value}
          />
          <i className="bx bx-search-alt search-icon ms-1"></i>
        </label>
      </div>
    </div>
  )
}

export default TableSearch
