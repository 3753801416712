import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import { GET_COLLEGE_REQUESTS, UPDATE_COLLEGE_REQUESTS } from "./actionTypes"

import {
  getCollegeRequestsFail,
  getCollegeRequestsSuccess,
  updateCollegeRequestsFail,
  updateCollegeRequestsuccess,
  updateCollegeRequesLoadingState,
} from "./actions"
//Include Both Helper File with needed methods
import { getCollegeRequestsApi } from "helpers/api/usersApi"

function* fetchCollegeRequests({ params }) {
  try {
    const { page, limit, search } = params
    const queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }`
    yield put(updateCollegeRequesLoadingState(true))
    const response = yield call(getCollegeRequestsApi, queryParams)
    yield put(getCollegeRequestsSuccess(response.data.data))
    yield put(updateCollegeRequesLoadingState(false))
  } catch (error) {
    yield put(getCollegeRequestsFail(error))
    yield put(updateCollegeRequesLoadingState(false))
  }
}

function* onUpdateCollegeRequest({
  payload: { collegeRequest, collegeRequestId },
}) {
  try {
    const response = yield call(updateHrUser, collegeRequest, collegeRequestId)
    yield put(updateCollegeRequestsuccess(response.data))
    toastr.success("College request updated successfully..!!")
  } catch (error) {
    yield put(updateCollegeRequestsFail(error))
    toastr.error(error.response.data.message)
  }
}

function* collegeRequestsSaga() {
  yield takeEvery(GET_COLLEGE_REQUESTS, fetchCollegeRequests)
  yield takeEvery(UPDATE_COLLEGE_REQUESTS, onUpdateCollegeRequest)
}

export default collegeRequestsSaga
