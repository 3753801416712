import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Container } from "reactstrap"
import {
  getAnnouncementDetail as onGetAnnouncementDetail,
  resetAnnouncements as onResetAnnouncements,
} from "../../store/announcement/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AppForm from "../../components/Common/Form"
import { handleSubmit } from "./Utills"
import { formFields } from "./constants"
import AppLoader from "../../components/Common/Loader"

const ConfigureAnnouncement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [isEdit, setIsEdit] = useState(false)

  document.title = `${
    isEdit ? "Edit Announcement" : "Create Announcement"
  } | QAPRENEUR`

  const { announcementDetail, loading } = useSelector(state => ({
    announcementDetail: state?.announcement?.announcementDetail,
    loading: state?.announcement?.loading,
  }))

  useEffect(() => {
    const currentLocation = location.pathname
    const status = currentLocation.includes("edit-announcement")
    setIsEdit(status)
    if (status && params?.announcementId)
      dispatch(onGetAnnouncementDetail(params.announcementId))
    return () => dispatch(onResetAnnouncements())
  }, [])

  function convertDateFormat(dateString) {
    // Parse the provided date string into a JavaScript Date object
    const originalDate = new Date(dateString)
    // Extract year, month, and day components
    const year = originalDate.getFullYear()
    const month = String(originalDate.getMonth() + 1).padStart(2, "0") // Adding 1 as months are zero-based
    const day = String(originalDate.getDate()).padStart(2, "0")

    // Return the formatted date
    return `${year}-${month}-${day}`
  }

  const initData = announcementDetail
    ? {
        ...announcementDetail,
        startDate: convertDateFormat(announcementDetail?.startDate),
        endDate: convertDateFormat(announcementDetail?.endDate),
      }
    : { description: "", type: "", status: "" }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="announcements"
            navigate="/announcement"
            breadcrumbItem={`${
              isEdit ? "Edit Announcement" : "Create Announcement"
            }`}
          />
          {loading && <AppLoader />}
          {!loading && (
            <Card>
              <CardBody>
                <AppForm
                  name=""
                  layoutType={isEdit ? "UPDATE" : "CREATE"}
                  fields={formFields}
                  initialValues={{ ...initData }}
                  handleFormSubmit={(formikValues, formik) =>
                    handleSubmit(
                      formikValues,
                      formik,
                      initData,
                      isEdit,
                      dispatch,
                      navigate
                    )
                  }
                />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfigureAnnouncement
