/* collegeRequests */
export const GET_COLLEGE_REQUESTS = "GET_HR_USERS"
export const GET_COLLEGE_REQUESTS_SUCCESS = "GET_COLLEGE_REQUESTS_SUCCESS"
export const GET_COLLEGE_REQUESTS_FAIL = "GET_COLLEGE_REQUESTS_FAIL"

/**
 * update collegeRequests
 */
export const UPDATE_COLLEGE_REQUESTS = "UPDATE_COLLEGE_REQUESTS"
export const UPDATE_COLLEGE_REQUESTS_SUCCESS = "UPDATE_COLLEGE_REQUESTS_SUCCESS"
export const UPDATE_COLLEGE_REQUESTS_FAIL = "UPDATE_COLLEGE_REQUESTS_FAIL"

export const UPDATE_COLLEGE_REQUESTS_LOADING_STATE = "UPDATE_COLLEGE_REQUESTS_LOADING_STATE"
