import React, { useEffect, useState } from "react"
import { Spinner } from "reactstrap"
import { useParams, useNavigate } from "react-router-dom"
import {
  purChaseSubsCriptions,
  purChasePackages,
  updateTransactionsStatus,
  getTransactionStatus,
} from "../../helpers/api/transactionsApi"
import AppLoader from "../../components/Common/Loader"

function TransactionStatus() {
  const role = localStorage.getItem("authRole")
  const authUser = localStorage.getItem("authUser")

  const params = useParams()
  const navigate = useNavigate()
  const { status, itemId, txnId } = params
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [transactions, setTransactions] = useState(null)

  const redirectToProfileView = () => {
    navigate(
      `/profile/view/${role}/${authUser ? JSON.parse(authUser)._id : ""}`
    )
  }

  useEffect(() => {
    const completeTransaction = async () => {
      const txn_info = localStorage.getItem("txn_info")
      if (itemId && txnId && txn_info) {
        const txnInfo = JSON.parse(txn_info)
        const result = await getTransactionStatus(txnInfo?.hash)
        if (result.message_type === "success") {
          setTransactions(result)
          try {
            if (txnInfo?.transaction.purpose === "Subscription Purchase") {
              const response = await purChaseSubsCriptions({
                subscriptionId: itemId,
                transactionId: txnId,
              },'company')
              setIsLoading(false)
              if (response?.data?.newSubscription) {
                const response = await updateTransactionsStatus({
                  transactionId: txnId,
                  success: true,
                  status: true,
                })
                // redirectToProfileView();
              }
            } else if (txnInfo?.transaction.purpose === "Package Purchase") {
              const response = await purChasePackages({
                packageId: itemId,
                transactionId: txnId,
              })
              setIsLoading(false)
              if (response?.data?.packageHistory) {
                await updateTransactionsStatus({
                  transactionId: txnId,
                  success: true,
                  status: true,
                })
              }
            }
          } catch (error) {
            setIsLoading(false)
            console.log(error)
            setError("An error occurred while processing the transaction.")
            console.error(error)
          }
          //     "message": "This transaction has been successfully processed. Please initiate a new transaction request.",
        } else {
          if (result.message_type === "fail" || result.message_type === null) {
            const response = await updateTransactionsStatus({
              transactionId: txnId,
              success: false,
              status: false,
            })
            setIsLoading(false)
            if (response?.data?.transaction) {
              // redirectToProfileView()
            }
          }
        }
      }
    }

    completeTransaction()
  }, [])

  return (
    <div className="page-content">
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <AppLoader />
        </div>
      ) : (
        <div
          className={`d-flex align-items-center justify-content-center flex-column mt-5 ${
            transactions.message_type === "success" ? "success" : "error"
          }`}
        >
          <div className="d-flex align-items-center">
            {transactions.message_type === "success" ? (
              <span
                className="mdi mdi-check-circle me-1"
                style={{ color: "#1AB69D", fontSize: "25px" }}
              ></span>
            ) : (
              <span
                className="mdi mdi-alert-circle-outline"
                style={{ color: "#e28743", fontSize: "25px" }}
              ></span>
            )}
            <h2>
              {transactions.message_type === "success"
                ? "Transaction Successful"
                : "Transaction Failed"}
            </h2>
          </div>
          <p>
            {transactions.message_type === "success"
              ? "Your transaction has been successfully completed."
              : error || "There was an error processing your transaction."}
          </p>
          <p>Transaction Number : {txnId}</p>
        </div>
      )}
    </div>
  )
}

export default TransactionStatus
