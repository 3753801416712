import flashCards from "store/flashCards/reducer"
import {
  GET_FLASH_CARD,
  GET_FLASH_CARD_FAIL,
  GET_FLASH_CARD_SUCCESS,
  ADD_FLASH_CARD,
  ADD_FLASH_CARD_SUCCESS,
  ADD_FLASH_CARD_FAIL,
  UPDATE_FLASH_CARD,
  UPDATE_FLASH_CARD_SUCCESS,
  UPDATE_FLASH_CARD_FAIL,
  DELETE_FLASH_CARD,
  DELETE_FLASH_CARD_SUCCESS,
  DELETE_FLASH_CARD_FAIL,
  UPDATE_FLASH_CARD_LOADING_STATE,
  RESET_FLASH_CARD,
} from "./actionTypes"

export const getflashCard = (chapterId,params) => ({
  type: GET_FLASH_CARD,
   chapterId,
   params
})

export const getflashCardSuccess = flashCards => ({
  type: GET_FLASH_CARD_SUCCESS,
  payload: flashCards,
})

export const getflashCardFail = error => ({
  type: GET_FLASH_CARD_FAIL,
  payload: error,
})


export const updateflashCard = (flashCard, flashCardId, history) => ({
  type: UPDATE_FLASH_CARD,
  payload: { flashCard, flashCardId, history },
})

export const updateflashCardSuccess = flashCard => ({
  type: UPDATE_FLASH_CARD_SUCCESS,
  payload: flashCard,
})

export const updateflashCardFail = error => ({
  type: UPDATE_FLASH_CARD_FAIL,
  payload: error,
})

export const deleteflashCard = (flashCardId, history) => ({
  type: DELETE_FLASH_CARD,
  payload: { flashCardId, history },
})

export const deleteflashCardSuccess = flashCardId => ({
  type: DELETE_FLASH_CARD_SUCCESS,
  payload: {flashCardId},
})

export const deleteflashCardFail = error => ({
  type: DELETE_FLASH_CARD_FAIL,
  payload: error,
})

export const addFlashCard = (flashCard, history) => ({
  type: ADD_FLASH_CARD,
  payload: { flashCard, history },
})

export const addFlashCardSuccess = flashCard => ({
  type: ADD_FLASH_CARD_SUCCESS,
  payload: flashCard,
})

export const addFlashCardFail = error => ({
  type: ADD_FLASH_CARD_FAIL,
  payload: error,
})

export const updateFlashcardLoadingState = status => ({
  type:  UPDATE_FLASH_CARD_LOADING_STATE ,
  payload: status,
})


export const resetFlashCard = () => ({
  type: RESET_FLASH_CARD
})