import React, { useState, useEffect } from "react"
import {
  Col,
  Container,
  Form,
  Row,
  CardBody,
  Card,
  FormFeedback,
  Spinner,
} from "reactstrap"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { auth } from "../../firebase.config"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import toastr from "toastr"
import "./authentication.css"
import { useNavigate, Link } from "react-router-dom"
import { resetPassword } from "../../store/actions"
import { getProfileDetails, checkUserbyPhone } from "../../helpers/api/usersApi"
import AppInput from "../../components/Common/AppInput"
import AppLoader from "../../components/Common/Loader"

const ChangePassword = () => {
  document.title = "QAPreneur | change password"
  const [showForgatePassword, setShowForgatePassword] = useState(true)
  const [showOtpField, setShowOtpField] = useState(false)
  const [showResetPasswordField, setShowResetPasswordField] = useState(false)
  const [showCapcha, setShowCapcha] = useState(true)
  const [userDetail, setUserDetail] = useState(null)
  const [isLoading, setIsloading] = useState(true)
  const [resendCountdown, setResendCountdown] = useState(0)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const otpValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("Please Enter OTP")
        .matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
    }),
    onSubmit: values => {
      verifyOtp(values.otp.toString())
    },
    isSubmitting: false,
  })

  const phoneValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: userDetail ? userDetail.phone : "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^[0-9]+$/, {
          message: "phone Number must contain only numeric digits",
        })
        .matches(/^\d{10}$/, {
          message: "phone Number must be exactly 10 digits",
        })
        .required(`Please Enter phone Number`)
        .test(
          "is-linked",
          "This number is not linked with your account",
          value => {
            return value === userDetail.phone
          }
        ),
    }),
    onSubmit: async values => {
      const response = await checkUserbyPhone({
        ...values,
        extra: { contactNumber: values.phone },
      })
      if (response.userExists) {
        sendOtp(values)
        setShowCapcha(true)
        setShowForgatePassword(false)
        setShowOtpField(true)
        onCaptchVerify()
      } else {
        toastr.error("Invalid mobile Number!")
        setShowOtpField(false)
        setShowForgatePassword(true)
        setShowCapcha(true)
        setShowResetPasswordField(false)
      }
    },
    isSubmitting: false,
  })

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container"
      )
    }
  }

  function sendOtp(values) {
    setShowOtpField(true)
    setShowForgatePassword(false)
    onCaptchVerify()
    const appVerifier = window.recaptchaVerifier
    const formatPh = "+91" + values.phone
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult
        setShowCapcha(false)
        startResendCountdown()
      })
      .catch(error => {
        console.log(error)
        errorHandle(error)
        // toastr.error("Error sending OTP. Please try again.")
        setShowOtpField(false)
        setShowForgatePassword(true)
        setShowResetPasswordField(false)
        setShowCapcha(false)
      })
  }

  const errorHandle = error => {
    let errorMessage = "An error occurred. Please try again."

    if (error.code === "auth/invalid-verification-code") {
      errorMessage =
        "Invalid verification code. Please check the code and try again."
    } else if (error.code === "auth/code-expired") {
      errorMessage =
        "The verification code has expired. Please request a new code."
    } else if (error.code === "auth/too-many-requests") {
      errorMessage =
        "You have made too many attempts in a short period of time. Please wait a few minutes before trying again."
    }
    toastr.error(errorMessage)
  }

  const verifyOtp = otp => {
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult
      confirmationResult
        .confirm(otp)
        .then(result => {
          setShowOtpField(false)
          setShowForgatePassword(false)
          setShowCapcha(false)
          setShowResetPasswordField(true)
        })
        .catch(error => {
          errorHandle(error)
        })
    }
  }

  const startResendCountdown = () => {
    setResendCountdown(39) // Set countdown to 39 seconds
    const countdownInterval = setInterval(() => {
      setResendCountdown(prev => {
        if (prev <= 1) {
          clearInterval(countdownInterval)
          setShowCapcha(true)
          return 0
        }
        return prev - 1
      })
    }, 1000)
  }

  const resetPasswordValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter a new password")
        .min(8, "Password must be at least 8 characters"),
      passwordConfirm: Yup.string()
        .required("Please confirm your new password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: values => {
      const data = {
        ...values,
        ...phoneValidation.values,
      }
      dispatch(resetPassword(data))
      setShowOtpField(false)
      setShowForgatePassword(false)
      setShowCapcha(false)
      // setShowResetPasswordField(false)
      if (history) {
        setTimeout(() => {
          navigate(-1)
        }, 1000)
      }
    },
    isSubmitting: false,
  })

  const { loading } = useSelector(state => ({
    loading: state.Login.loading,
  }))

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const response = await getProfileDetails()
    if (response.data.data) {
      setUserDetail(response.data.data)
      setIsloading(false)
    }
  }

  const clearRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear()
      window.recaptchaVerifier = null
    }
  }
  const handleResendOtp = e => {
    e.preventDefault()
    clearRecaptcha()
    setShowCapcha(true)
    onCaptchVerify()
    const values = phoneValidation.values
    sendOtp(values)
    startResendCountdown()
  }

  return (
    <React.Fragment>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100%", marginTop: "150px" }}
      >
        <Container>
          {isLoading ? (
            <AppLoader />
          ) : (
            <Row className="d-flex align-items-center justify-content-center">
              <Col xs={8}>
                <Card
                  className="mb-0"
                  style={{ height: 400, borderRadius: 20 }}
                >
                  <CardBody>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4
                        className="mb-sm-0 font-size-18"
                        style={{ color: "#243466" }}
                      >
                        Change Password
                      </h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0 ">
                          <Link to={-1} style={{ color: "#243466" }}>
                            Back
                          </Link>
                        </ol>
                      </div>
                    </div>
                    {showCapcha && <div id="recaptcha-container"></div>}
                    {showForgatePassword && (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          phoneValidation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="d-flex flex-column justify-content-between">
                          <AppInput
                            label={"Enter Mobile Number"}
                            name="phone"
                            className="input_field otp_container"
                            type="text"
                            placeholder="Please Enter Mobile Number"
                            onChange={phoneValidation.handleChange}
                            onBlur={phoneValidation.handleBlur}
                            value={phoneValidation.values?.phone}
                            invalid={
                              phoneValidation.touched?.phone &&
                              phoneValidation.errors?.phone
                            }
                            error={phoneValidation.errors?.phone}
                          />
                          {phoneValidation.touched.phone &&
                          phoneValidation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {phoneValidation.errors.phone}
                            </FormFeedback>
                          ) : null}
                          <div className="mt-3 d-flex justify-content-end">
                            <button
                              className="btn btn-primary btn-block custom_btn me-2"
                              type="button"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-primary btn-block custom_btn"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <Spinner
                                  className="ms-2"
                                  color="light"
                                  size="sm"
                                />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                    {showOtpField && (
                      <Form
                        className="form-horizontal"
                        // onSubmit={(e) => {
                        //   e.preventDefault();
                        //   otpValidation.handleSubmit();
                        //   return false;
                        // }}
                      >
                        <p className="text-muted page_description">
                          We Sent OTP On This Mobile Number{" "}
                          {phoneValidation.values.phone &&
                            `******${phoneValidation.values.phone.slice(-2)}`}
                        </p>
                        <div className="mb-4">
                          <AppInput
                            label={"Verify Your OTP Code"}
                            name="otp"
                            className="input_field otp_container"
                            type="text"
                            placeholder="Please enter 6 digit code"
                            onChange={otpValidation.handleChange}
                            onBlur={otpValidation.handleBlur}
                            value={otpValidation.values?.otp}
                            invalid={
                              otpValidation.touched?.otp &&
                              otpValidation.errors?.otp
                            }
                            error={otpValidation.errors?.otp}
                          />
                          {otpValidation.touched.phone &&
                          otpValidation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {otpValidation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3 d-flex align-items-center justify-content-end">
                          {!showCapcha && resendCountdown > 0 ? (
                            <button
                              className="btn btn-secondary btn-block custom_btn me-2"
                              type="button"
                              disabled={true}
                            >
                              Resend OTP ({resendCountdown}s)
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-block custom_btn me-2"
                              type="button"
                              disabled={loading}
                              onClick={handleResendOtp}
                            >
                              Resend OTP
                            </button>
                          )}
                          <button
                            className="btn btn-primary btn-block custom_btn"
                            type="submit"
                            disabled={loading}
                            onClick={e => {
                              e.preventDefault()
                              otpValidation.handleSubmit()
                              return false
                            }}
                          >
                            {loading ? (
                              <Spinner
                                className="ms-2"
                                color="light"
                                size="sm"
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </Form>
                    )}
                    {showResetPasswordField && (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          resetPasswordValidation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="mb-4">
                          <AppInput
                            label="New Password"
                            name="password"
                            className="input_field otp_container"
                            type="text"
                            placeholder="Enter new password"
                            onChange={resetPasswordValidation.handleChange}
                            onBlur={resetPasswordValidation.handleBlur}
                            value={resetPasswordValidation.values?.password}
                            invalid={
                              resetPasswordValidation.touched?.password &&
                              resetPasswordValidation.errors?.password
                            }
                            error={resetPasswordValidation.errors?.password}
                          />
                        </div>
                        <div className="mb-4">
                          <AppInput
                            label="Confirm New Password"
                            name="passwordConfirm"
                            className="input_field otp_container"
                            type="text"
                            placeholder="Confirm new password"
                            onChange={resetPasswordValidation.handleChange}
                            onBlur={resetPasswordValidation.handleBlur}
                            value={
                              resetPasswordValidation.values?.passwordConfirm
                            }
                            invalid={
                              resetPasswordValidation.touched
                                ?.passwordConfirm &&
                              resetPasswordValidation.errors?.passwordConfirm
                            }
                            error={
                              resetPasswordValidation.errors?.passwordConfirm
                            }
                          />
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block custom_btn"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <Spinner
                                className="ms-2"
                                color="light"
                                size="sm"
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </Form>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword
