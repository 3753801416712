import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  RESET_TRANSACTIONS,
  ADD_NEW_TRANSACTION,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAIL,
  UPDATE_TRANSACTION_LOADING_STATE
} from "./actionTypes"

export const getTransactions = params => ({
  type: GET_TRANSACTIONS,
  params,
})

export const getTransactionsSuccess = transactions => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload: transactions,
})

export const getTransactionsFail = error => ({
  type: GET_TRANSACTIONS_FAIL,
  payload: error,
})

export const addNewTransactions = (transaction) => ({
  type: ADD_NEW_TRANSACTION,
  payload: { transaction },
})

export const addTransactionsSuccess = transaction => ({
  type: ADD_TRANSACTION_SUCCESS,
  payload: transaction,
})

export const addTransactionsFail = error => ({
  type: ADD_TRANSACTION_FAIL,
  payload: error,
})

export const resetTransactions = () => ({
  type: RESET_TRANSACTIONS,
})

export const updateTransactionsLoadingState = status => ({
  type: UPDATE_TRANSACTION_LOADING_STATE,
  payload: status,
})