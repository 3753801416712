import React, { useState, useEffect } from "react"
import { Card } from "reactstrap"

import AppForm from "../../components/Common/Form/index"
import CardInfo from "../../components/Common/Card/CardInfo"
import { billingFormFields } from "./constants"
//redux
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import {
  getCompanyBillingInfo as onGetCompanyBillingInfo,
  addCompanyBillingInfo as onAddCompanyBillingInfo,
  updateCompanyBillingInfo as onUpdateCompanyBillingInfo,
  getRoles as onGetRoles,
} from "../../store/actions"

function companyBilling(props) {
  const params = useParams()
  const companyId = props.companyId || params.companyId
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [showEdit, setShowEdit] = useState(true)
  const [userRole, setUserRole] = useState("")

  const { companyDetail, companyBillingInfo, roles } = useSelector(state => ({
    companyDetail: state.companies?.companyDetail,
    companyBillingInfo: state.companies?.companyBillingInfo,
    roles: state.roles.roles,
  }))

  useEffect(() => {
    const authRole = localStorage.getItem("authRole")
    const authUser = localStorage.getItem("authUser")
    if (authUser && authRole) {
      setUserRole(authRole)
      const user = JSON.parse(authUser)
      if (companyId) {
        const isHrUser = authRole === "hr_user"
        const _id = isHrUser ? user?.companyId : companyId
        dispatch(onGetCompanyBillingInfo(_id))
        dispatch(onGetRoles())
      }
    }
  }, [dispatch, onGetCompanyBillingInfo])

  useEffect(() => {
    if (
      companyBillingInfo &&
      Object.keys(companyBillingInfo).length > 0
    ) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }, [companyDetail, companyBillingInfo])

  const handleSubmit = (values, formik) => {
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        if (isEdit) {
          const data = {
            billingAddress: values.billingAddress,
            city: values.city,
            state: values.state,
            postalCode: values.postalCode,
            gstNumber: values.gstNumber,
          }
          dispatch(onUpdateCompanyBillingInfo(companyBillingInfo._id, data))
          setShowEdit(true)
        } else {
          dispatch(onAddCompanyBillingInfo(params.companyId, values))
          setShowEdit(true)
        }
      } else {
        const touchedFields = {}
        Object.keys(errors).forEach(field => {
          touchedFields[field] = true
        })
        formik.setTouched(touchedFields)
        console.log("Validation errors:", errors)
      }
    })
  }

  const handleCancel = () => {
    setShowEdit(true)
  }

  return (
    <div>
      {showEdit && (
        <>
          <CardInfo
            title="Billing Details"
            fields={[
              {
                label: "Billing Address",
                value: companyBillingInfo?.billingAddress,
              },
              { label: "City", value: companyBillingInfo?.city },
              { label: "State", value: companyBillingInfo?.state },
              { label: "Postal Code", value: companyBillingInfo?.postalCode },
              { label: "GST Number", value: companyBillingInfo?.gstNumber },
            ]}
            onClick={() => setShowEdit(false)}
            isButton={userRole === "company"}
            BtnTitle={"Edit"}
            lg={6}
          />
        </>
      )}
      {!showEdit && userRole === "company" && (
        <>
          <Card style={{ borderRadius: 15 }}>
            <div className="pt-3 px-2">
              <AppForm
                name="Billing Details"
                layoutType={"EDIT"}
                fields={billingFormFields}
                initialValues={{
                  billingAddress: "",
                  city: "",
                  state: "",
                  postalCode: "",
                  gstNumber: "",
                  ...companyBillingInfo,
                }}
                // addButton={{
                //   onclick: () => setShowEdit(true),
                //   title: "Cancel",
                // }}
                // getValues={getValues}
                // showCancelButton={false}
                handleFormSubmit={handleSubmit}
                saveTitle="Save"
                flex="end"
                navigateToCancelButton={false}
                handleCancel={handleCancel}
              />
            </div>
          </Card>
        </>
      )}
    </div>
  )
}

export default companyBilling
