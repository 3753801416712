import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_COACHINGS,
  GET_COACHING_DETAIL,
  ADD_NEW_COACHING,
  DELETE_COACHING,
  UPDATE_COACHING,
} from "./actionTypes"
import {
  getCoachingsSuccess,
  getCoachingsFail,
  getCoachingDetailSuccess,
  getCoachingDetailFail,
  addCoachingFail,
  addCoachingSuccess,
  updateCoachingSuccess,
  updateCoachingFail,
  deleteCoachingSuccess,
  deleteCoachingFail,
  updateCoachingLoadingState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCoachings,
  getCoachingsDetails,
  addNewCoaching,
  updateCoaching,
  deleteCoaching,
  getProfileDetails,
  updateProfileDetails
} from "helpers/backend_helper"

function* fetchCoachings({params}) {
  try {
    yield put(updateCoachingLoadingState(true))
    let response
    if(params && Object.keys(params).length>0){
      const { page, limit } = params 
      let queryParams = `&page=${page}&limit=${limit}`
      if (params?.search && params?.search?.length) {
        queryParams = queryParams + `&search=${params?.search}`
      }
      response = yield call(getCoachings,queryParams)
    }else{
      response = yield call(getCoachings,params)
    }
    yield put(getCoachingsSuccess(response.data))
    yield put(updateCoachingLoadingState(false))
  } catch (error) {
    yield put(updateCoachingLoadingState(false))
    yield put(getCoachingsFail(error))
  }
}

function* fetchCoachingDetail({ coachingId, slug }) {
  try {
    let response
    if (slug==="coaching") {
      response = yield call(getCoachingsDetails, coachingId, slug)
    } else {
      response = yield call(getProfileDetails)
    }
    const data = response?.data?.data || response?.data?.coachingDetail || response?.user
    yield put(getCoachingDetailSuccess(data))
  } catch (error) {
    yield put(getCoachingDetailFail(error))
  }
}


function* onUpdateCoaching({ payload: { slug, coaching, coachingId, history } }) {
  try {
    let response
    if (slug==="coaching") {
      response = yield call(updateCoaching, slug,coaching, coachingId)
    } else {
      response =  yield call(updateProfileDetails,coaching,coachingId)
    }
    const data = response.data.data || response.data.coachingDetail || response.data.user

    yield put(updateCoachingSuccess(data))
    toastr.success("Coaching updated successfully..!!")
    // setTimeout(()=>{
    //   history('/coachings-list')
    // },2000)
  } catch (error) {
    yield put(updateCoachingFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteCoaching({ payload }) {
  const {coachingId,params}= payload
  try {
    const { page, limit } = params
    const queryParams = `&page=${page}&limit=${limit}`
    const response = yield call(deleteCoaching, coachingId)
    toastr.success("Coaching deleted successfully..!!")
    let res 
    if (params) {
      res = yield call(getCoachings,queryParams)
    } else {
      res = yield call(getCoachings)
    }
    yield put(deleteCoachingSuccess(response.data))
    yield put(getCoachingsSuccess(res.data))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteCoachingFail(error))
  }
}

function* onAddNewCoaching({ payload: { coaching, history } }) {
  try {
    yield put(updateCoachingLoadingState(true))
    const response = yield call(addNewCoaching, coaching)
    toastr.success("New Coaching Added Successfully..!!")
    yield put(addCoachingSuccess(response.data.data))
    yield put(updateCoachingLoadingState(false))
    setTimeout(() => {
      history("/coachings-list")
    }, 1500)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateCoachingLoadingState(false))
    yield put(addCoachingFail(error))
  }
}

function* coachingsSaga() {
  yield takeEvery(GET_COACHINGS, fetchCoachings)
  yield takeEvery(GET_COACHING_DETAIL, fetchCoachingDetail)
  yield takeEvery(ADD_NEW_COACHING, onAddNewCoaching)
  yield takeEvery(UPDATE_COACHING, onUpdateCoaching)
  yield takeEvery(DELETE_COACHING, onDeleteCoaching)
}

export default coachingsSaga
