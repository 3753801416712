import {
  GET_MULTI_MEDIA_CONTENT_FAIL,
  GET_MULTI_MEDIA_CONTENT_SUCCESS,
  ADD_MULTI_MEDIA_CONTENT_SUCCESS,
  ADD_MULTI_MEDIA_CONTENT_FAIL,
  UPDATE_MULTI_MEDIA_CONTENT_SUCCESS,
  UPDATE_MULTI_MEDIA_CONTENT_FAIL,
  DELETE_MULTI_MEDIA_CONTENT_SUCCESS,
  DELETE_MULTI_MEDIA_CONTENT_FAIL,
  UPDATE_MULTI_MEDIA_CONTENT_LOADING_STATE,
  RESET_MULTI_MEDIA_CONTENT,
} from "./actionTypes"

const INIT_STATE = {
  multiMediaContent: [],
  multiMediaContentDetail: {},
  error: {},
  loading: false,
}

const multiMedias = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MULTI_MEDIA_CONTENT_SUCCESS:{
      return {
        ...state,
        multiMediaContent: action.payload,
      }
    }
    case GET_MULTI_MEDIA_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_MULTI_MEDIA_CONTENT_SUCCESS: {
      return {
        ...state,
        multiMediaContentDetail: action.payload,
      }
    }

    case ADD_MULTI_MEDIA_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_MULTI_MEDIA_CONTENT_SUCCESS:
      return {
        ...state,
        multiMediaContent: state.multiMediaContent.map(media =>
          media._id.toString() === action.payload._id.toString()
            ? { ...media, ...action.payload }
            : media
        ),
      }

    case UPDATE_MULTI_MEDIA_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_MULTI_MEDIA_CONTENT_SUCCESS:
      return {
        ...state,
        multiMediaContent: state.multiMediaContent.filter(
          media => media?._id.toString() !== action.payload?.multiMediaId.toString()
        ),
      }

    case DELETE_MULTI_MEDIA_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_MULTI_MEDIA_CONTENT_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }
    case RESET_MULTI_MEDIA_CONTENT:
      return {
        ...INIT_STATE,
      }

    default:
      return state
  }
}

export default multiMedias
