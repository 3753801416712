import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

import Breadcrumb from "../../components/Common/Breadcrumb"
import ProfileHeader from "../../components/Common/Card/ProfileHeader"
import AppForm from "../../components/Common/Form/index"

import { formFields } from "./constants"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import {
  getCollegeDetail as onGetCollegeDetail,
  updateCollege as onUpdateCollege,
} from "../../store/colleges/actions"

import { useNavigate } from "react-router-dom"
const staticURL = process.env.REACT_APP_STATIC_URL

const EditCollegeProfile = props => {
  //meta title
  document.title = "Profile | QAPRENEUR"

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = props.router.params
  const role = localStorage.getItem("authRole")
  const [user, setUser] = useState(null)

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const { loading, collegeDetail } = useSelector(state => ({
    loading: state.colleges.loading,
    collegeDetail: state.colleges?.collegeDetail,
  }))

  useEffect(() => {
    if (params.collegeId) {
      dispatch(onGetCollegeDetail(params.collegeId, "users/me"))
    }
  }, [dispatch, onGetCollegeDetail])

  useEffect(() => {
    if (Object.keys(collegeDetail).length) {
      setUser({ ...collegeDetail, ...collegeDetail.extra })
    }
  }, [collegeDetail])

  const handleSubmit = formikValues => {
    const newObj = { ...user, ...user?.extra, ...formikValues }
    const data = {
      name: newObj?.name,
      email: newObj?.email,
      phone: newObj?.phone,
      address: newObj?.address,
      city: newObj?.city,
      state: newObj?.state,
      extra: {
        contactPersonName: newObj.contactPersonName,
        contactNumber: newObj.contactNumber,
        about: newObj.about,
        website: newObj.website,
        establishmentDate: newObj.establishmentDate,
      },
    }
	if(role==="admin") {
		dispatch(onUpdateCollege("college", data, collegeDetail._id, navigate))
	}else{
		dispatch(onUpdateCollege('updateMe',data,collegeDetail._id,navigate))
	}
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Dashboard"
            breadcrumbItem="College Profile"
            navigate={-1}
          />
          {error && error ? <Alert color="danger">{error}</Alert> : null}
          {success ? <Alert color="success">{success}</Alert> : null}
          <Row>
            <Col lg="12">
              <ProfileHeader
                imageUrl={user && user.image ? staticURL + user.image : avatar}
                data={user}
                profileName={user?.name ? user?.name : user?.fullname}
                profileEmail={user ? user.email : ""}
                // ButtonType={"EDIT"}
                onclick={() => navigate(`/profile/edit/college/${user._id}`)}
                LayoutType={"HORIZONTAL"}
                style={{ padding: 0 }}
              />
            </Col>
          </Row>
          <Card>
            <CardBody>
              <AppForm
                name="College Information"
                layoutType="UPDATE"
                fields={formFields}
                initialValues={user}
                handleFormSubmit={handleSubmit}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditCollegeProfile)
