import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { notificationReceiversColumns } from "./constants"
import { getNotificationsReceivers as onGetNotificationsReceivers } from "../../store/notification/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"

const NotificationReceiversList = () => {
  document.title = "Receivers List | QAPRENEUR"
  const dispatch = useDispatch()
  const params = useParams()
  const [receiversData, setReceiversData] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { receivers, loading } = useSelector(state => ({
    receivers: state?.notifications?.receivers,
    loading: state?.notifications?.loading,
  }))

  const getData = () => {
    const queryParams = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (params?.notificationId) {
      queryParams.notificationId = params.notificationId
    }

    if (search && search.length > 0) {
      queryParams.search = search
    }

    dispatch(onGetNotificationsReceivers(queryParams))
  }

  useEffect(() => {
    getData()
  }, [pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(receivers)) {
      setReceiversData(
        receivers
          .map(item => ({ ...item ,...item.receiver}))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setReceiversData([])
    }
  }, [receivers, pageNo])

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const onChangePagination = (limit, page) => {
    setRowPerPage(limit)
    setPageNo(page)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        work in progress...
        {/* <Container fluid>
          <Breadcrumbs
            title="Notifications"
            navigate={"/notifications"}
            breadcrumbItem="Receivers"
          />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={receiversData}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={notificationReceiversColumns}
                  showPagination={true}
                  onChangePagination={onChangePagination}
                  handleSearch={handleSearch}
                />
              </div>
            </Col>
          </Row>
        </Container> */}
      </div>
    </React.Fragment>
  )
}

export default NotificationReceiversList
