import React, { Fragment, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button } from "reactstrap"
import { DefaultColumnFilter } from "../filters"
import { Link } from "react-router-dom"
import Loader from "../Loader/index"
import TableSearch from "./TableSearch"
import {
  Image,
  ProfileImage,
  ImageWithRating,
  Name,
  Description,
  DefaultColumn,
  NameAndTitle,
  AmountColumn,
  LeadsColumn,
  UnlockedInfo,
  SkillsColumn,
  CategoryColumn,
  DateFormate,
  DateAndTimeFormate,
  Status,
  Action,
  StatusChangeToggle,
  VerifyUserToggle,
  Dropdown,
  MoreActions,
  JobInfo,
  InvoiceColumn,
  BuyButton,
} from "./TableColumns"
import NotFound from "../NotFound"
import TablePagination from "./TablePagination"

const AppTable = ({
  loading,
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  customPageSize,
  className,
  customPageSizeOptions,
  handleStatus,
  handleDelete,
  handleEdit,
  handleView,
  handleUnlock,
  addButton,
  handleActions,
  handleSearch,
  handleDropDown,
  handleInvoice,
  handleBuy,
  showPageDropdown = true,
  onChangePagination,
  showPagination = false,
  notDataFound = false,
  tableName = "table",
  tableTitle = "",
}) => {
  const [pageNo, setPageNo] = useState(0)
  const [tableData, setTableData] = useState(JSON.stringify(data))

  const createColumns = useMemo(
    () => {
      return (
        columns &&
        columns.map(col => {
          switch (col.key) {
            case "IMAGE":
              return {
                Header: col.header,
                accessor: col.name,
                disableFilters: true,
                filterable: true,
                Cell: cellProps => (
                  <Image
                    rowLink={col.link}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "PROFILE_IMAGE":
              return {
                Header: col.header,
                accessor: col.name,
                disableFilters: true,
                filterable: true,
                Cell: cellProps => (
                  <ProfileImage
                    rowLink={col.link}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "IMAGE_WITH_RATING":
              return {
                Header: col.header,
                accessor: col.name,
                disableFilters: true,
                filterable: true,
                Cell: cellProps => (
                  <ImageWithRating
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "NAME":
            case "NAME_DESCRIPTION":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <Name
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "DESCRIPTION":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <Description
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "CAT_ID":
              return {
                Header: col.header,
                accessor: col.categoryId,
                Cell: cellProps => (
                  <DefaultColumn
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    CategoryId={col.categoryId}
                    {...cellProps}
                  />
                ),
              }
            case "DEFAULT":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <DefaultColumn
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "NAME_AND_TITLE":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <NameAndTitle
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "AMOUNT":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <AmountColumn
                    customStyle={col?.customStyle || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "LEADS":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <LeadsColumn defaultId={col.defaultId} {...cellProps} />
                ),
              }
            case "UNLOCKED_INFO":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <UnlockedInfo
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                    handleUnlock={handleUnlock}
                  />
                ),
              }
            case "SKILLS_INFO":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <SkillsColumn
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "CATEGORY_INFO":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <CategoryColumn
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "JOB_INFO":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <JobInfo
                    rowLink={col?.link || ""}
                    defaultId={col.defaultId}
                    {...cellProps}
                  />
                ),
              }
            case "VERIFY_USER":
            case "DATE":
              return {
                Header: col.header,
                accessor: col.name,
                filterable: true,
                Cell: cellProps => {
                  return (
                    <>
                      <DateFormate {...cellProps} />
                    </>
                  )
                },
              }
            case "DATE_AND_TIME":
              return {
                Header: col.header,
                accessor: col.name,
                filterable: true,
                Cell: cellProps => {
                  return (
                    <>
                      <DateAndTimeFormate {...cellProps} time={true} />
                    </>
                  )
                },
              }
            case "STATUS":
              return {
                Header: col.header,
                accessor: col.name,
                disableFilters: true,
                Cell: cellProps => {
                  return <Status {...cellProps} />
                },
              }
            case "SWITCH":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => {
                  return (
                    <StatusChangeToggle
                      cellProps={cellProps}
                      handleChangeStatus={handleStatus}
                      CASE={col.CASE ? col.CASE : ""}
                    />
                  )
                },
              }
            case "SWITCH2":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => {
                  return (
                    <VerifyUserToggle
                      cellProps={cellProps}
                      handleChangeStatus={handleStatus}
                      CASE={col.CASE ? col.CASE : ""}
                      name={col.name}
                    />
                  )
                },
              }
            case "ACTIONS":
              return {
                Header: col.header,
                Cell: cellProps => {
                  return (
                    <>
                      <Action
                        cellProps={cellProps}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleView={handleView}
                      />
                    </>
                  )
                },
              }
            case "MORE_ACRIONS":
              return {
                Header: "Actions",
                Cell: cellProps => {
                  return (
                    <>
                      <MoreActions
                        cellProps={cellProps}
                        dropDownOptions={col.dropDownOptions}
                        handleActions={handleActions}
                      />
                    </>
                  )
                },
              }
            case "INVOICE":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <InvoiceColumn
                    defaultId={col.defaultId}
                    {...cellProps}
                    handleInvoice={handleInvoice}
                    type={col.key}
                  />
                ),
              }
            case "BUY_BUTTON":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <BuyButton
                    defaultId={col.defaultId}
                    {...cellProps}
                    handleBuy={handleBuy}
                    type={col.key}
                  />
                ),
              }
            case "DROPDOWN":
              return {
                Header: col.header,
                accessor: col.name,
                Cell: cellProps => (
                  <Dropdown
                    {...cellProps}
                    name={col.name}
                    options={col.options}
                    condition={col?.condition ? col?.condition : {}}
                    handleDropDown={handleDropDown}
                  />
                ),
              }
            default:
              return {
                Header: "#",
              }
          }
        })
      )
    },
    tableName === "viewStudent" ? [] : [data]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: createColumns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  useEffect(() => {
    if (onChangePagination && showPagination) {
      setTableData(data)
      onChangePagination(pageSize, pageNo + 1)
    }
  }, [pageIndex, pageNo, customPageSize])

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    const rowPerPage = Number(event.target.value)
    if (onChangePagination && showPagination) {
      onChangePagination(rowPerPage, pageNo)
    }
    setPageSize(rowPerPage)
    setPageNo(0)
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    setPageNo(newPage)
    gotoPage(page)
  }

  return (
    <Fragment>
      <Row className="mb-2">
        {tableTitle && (
          <Col className="d-flex justify-content-start align-items-center">
            <h4 className="mb-sm-0 font-size-18" style={{ color: "#243466" }}>
              {tableTitle}
            </h4>
          </Col>
        )}
        {showPageDropdown && (
          <Col md={4}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[5, 10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <TableSearch
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
            handleSearch={handleSearch}
            setPageNo={setPageNo}
          />
        )}
        {addButton && (
          <>
            <Col className="d-flex  justify-content-end">
              <div className="flex-shrink-0">
                {addButton.link ? (
                  <>
                    <Link
                      to={addButton.link}
                      className="btn btn-primary global_btn me-1"
                    >
                      <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
                      {addButton.title}
                    </Link>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={addButton.onclick}
                      className="btn btn-primary global_btn me-1"
                    >
                      {!addButton.back ? (
                        <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
                      ) : (
                        ""
                      )}
                      {addButton.title}
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </>
        )}
      </Row>

      <div
        className="table-responsive react-table"
        style={{ paddingBottom: "100px" }}
      >
        <Table className={className}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    scope="col"
                    style={{ color: "#243466" }}
                    className="fw-bold"
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, ind) => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr className="shadow-sm" style={{ overflow: "hidden" }}>
                    {row.cells.map((cell, index) => {
                      if (
                        JSON.stringify(data) !== JSON.stringify(tableData) &&
                        !loading
                      ) {
                        if (cell.column.Header === "#") {
                          return (
                            <td key={index} {...cell.getCellProps()}>
                              <span className="fw-bold" style={{ color: "#243466" }}>
                                {pageNo * pageSize + ind + 1}
                              </span>
                            </td>
                          )
                        }
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        )
                      }
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        {loading && <Loader />}

        {!loading && !data.length && !notDataFound && (
          <>
            <div className="text-center my-3">
              <div className="text-success">No Data Available</div>
            </div>
          </>
        )}

        {!data.length && notDataFound && <NotFound />}
      </div>

      {showPagination && (
        <TablePagination
          data={data}
          pageSize={pageSize}
          setPageNo={setPageNo}
          pageNo={pageNo}
        />
      )}
    </Fragment>
  )
}

AppTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default AppTable
