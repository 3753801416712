import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_COURSES,
  GET_COURSE_DETAIL,
  ADD_NEW_COURSE,
  DELETE_COURSE,
  UPDATE_COURSE,
  GET_SUBSCRIPTIONS_USERS,
  SEARCH_COURSE,
  GET_STUDENT_COURSE_RESULT,
} from "./actionTypes"
import {
  getCoursesSuccess,
  getCoursesFail,
  getCourseDetailSuccess,
  getCourseDetailFail,
  addCourseFail,
  addCourseSuccess,
  updateCourseSuccess,
  updateCourseFail,
  deleteCourseSuccess,
  deleteCourseFail,
  updateCourseLoadingState,
  getSubscriptonsUsersSuccess,
  searchCourses,
  searchCourseSuccess,
  searchCourseFail,
  getStudentCourseResultSuccess,
  getStudentCourseResultFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCourses,
  getCoursesDetails,
  addNewCourse,
  updateCourse,
  deleteCourse,
  getSubscriptionUsersByCourseId,
  getStudentCourseResultApi,
} from "helpers/api/courseApi"

import { dynamicSearch } from "helpers/api/dynamicApi"

function* fetchCourses({ params }) {
  try {
    const { page, limit } = params
    let queryParams = `?page=${page}&limit=${limit}`
    if (params?.search && params?.search?.length) {
      queryParams = queryParams + `&search=${params?.search}`
    }
    yield put(updateCourseLoadingState(true))
    const response = yield call(getCourses, queryParams)
    yield put(getCoursesSuccess(response.data.data))
    yield put(updateCourseLoadingState(false))
  } catch (error) {
    yield put(getCoursesFail(error))
    yield put(updateCourseLoadingState(false))
  }
}

function* fetchCourseDetail({ testId }) {
  try {
    const response = yield call(getCoursesDetails, testId)
    yield put(getCourseDetailSuccess(response.data.courseDetail))
  } catch (error) {
    yield put(getCourseDetailFail(error))
  }
}

function* onUpdateCourse({ payload: { test, testId, history } }) {
  try {
    const response = yield call(updateCourse, test, testId)
    yield put(updateCourseSuccess(response.data.data))
    toastr.success("Course updated successfully..!!")
  } catch (error) {
    yield put(updateCourseFail(error))
    toastr.error(error.response.data.message)
  }
  setTimeout(history("/courses-list"), 2000)
}

function* onDeleteCourse({ payload }) {
  const { courseId, params } = payload
  try {
    const { page, limit } = params
    const queryParams = `?page=${page}&limit=${limit}`
    const response = yield call(deleteCourse, courseId)
    toastr.success("Course deleted successfully..!!")
    let res
    if (params) {
      res = yield call(getCourses, queryParams)
    } else {
      res = yield call(getCourses)
    }
    yield put(deleteCourseSuccess(response.data))
    yield put(getCoursesSuccess(res.data.data))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteCourseFail(error))
  }
}

function* onAddNewCourse({ payload: { test, history } }) {
  try {
    yield put(updateCourseLoadingState(true))
    const response = yield call(addNewCourse, test)
    toastr.success("New Course Added Successfully..!!")
    yield put(addCourseSuccess(response.data.data))
    yield put(updateCourseLoadingState(false))
    setTimeout(() => {
      setTimeout(history(`/courses-list`), 2000)
    }, 1500)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateCourseLoadingState(false))
    yield put(addCourseFail(error))
  }
}

function* fetchSubscriptonsUsers({ payload: { courseId, params } }) {
  try {
    const { page, limit } = params
    let queryParams = `?page=${page}&limit=${limit}`
    const response = yield call(
      getSubscriptionUsersByCourseId,
      courseId,
      queryParams
    )
    yield put(getSubscriptonsUsersSuccess(response.usersPurchasedCourses))
  } catch (error) {
    toastr.error(error.response.data.message)
  }
}

function* searchCourse({ params }) {
  try {
    const { type, slug } = params
    let queryParams = `?type=${type}&slug=${slug}`
    const response = yield call(dynamicSearch, queryParams)
    yield put(searchCourseSuccess(response.data))
  } catch (error) {
    yield put(searchCourseFail(error))
  }
}

function* fetchStudentCourseResults({ params }) {
  try {
    const { studentId, courseId } = params
    let queryParams = `?studentId=${studentId}&courseId=${courseId}`
    yield put(updateCourseLoadingState(true))
    const response = yield call(getStudentCourseResultApi, queryParams)
    yield put(getStudentCourseResultSuccess(response.data.quizeAndCertificateResult))
    yield put(updateCourseLoadingState(false))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(getStudentCourseResultFail(error))
    yield put(updateCourseLoadingState(false))
  }
}

function* coursesSaga() {
  yield takeEvery(GET_COURSES, fetchCourses)
  yield takeEvery(GET_COURSE_DETAIL, fetchCourseDetail)
  yield takeEvery(ADD_NEW_COURSE, onAddNewCourse)
  yield takeEvery(UPDATE_COURSE, onUpdateCourse)
  yield takeEvery(DELETE_COURSE, onDeleteCourse)
  yield takeEvery(GET_SUBSCRIPTIONS_USERS, fetchSubscriptonsUsers)
  yield takeEvery(SEARCH_COURSE, searchCourse)
  yield takeEvery(GET_STUDENT_COURSE_RESULT, fetchStudentCourseResults)
}

export default coursesSaga
