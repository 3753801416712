export const tabIds = [
  { id: "student", label: "Student Packages" },
  { id: "company", label: "Company Packages" },
]

export const commonFormFields = [
  {
    label: "Package Name",
    name: "name",
    type: "text",
    required: true,
    lg: 12,
  },
  {
    label: "Price",
    name: "price",
    type: "number",
    required: true,
  },
  {
    label: "Discounted Price",
    name: "discountPrice",
    type: "number",
    required: true,
  },
]

export const studentSettings = [
  {
    label: "Select lead",
    name: "leads",
    type: "select",
    options: [
      {
        label: "Select",
        value: "",
      },
      {
        label: "Mock InterView",
        value: "mockTestCredit",
      },
    ],
    required: true,
  },
  {
    label: "Limit",
    name: "limit",
    type: "number",
    required: true,
  },
]

export const companySettings = [
  {
    label: "Select lead",
    name: "leads",
    type: "select",
    options: [
      {
        label: "Select",
        value: "",
      },
      {
        label: "Job Post Limit",
        value: "jobCredit",
      },
      {
        label: "Unlock Candidate Limit",
        value: "profileCredit",
      },
      {
        label: "HR User Limit",
        value: "addUserCredit",
      },
    ],
    required: true,
  },
  {
    label: "Limit",
    name: "limit",
    type: "number",
    required: true,
  },
]

export const commonFields = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Package Name",
    name: "name",
    key: "DEFAULT",
  },
  // {
  //   header: "Package Type",
  //   name: "packageType",
  //   key: "DEFAULT",
  // },
  {
    header: "Price",
    name: "price",
    key: "AMOUNT",
  },
  {
    header: "Discounted Price",
    name: "discountPrice",
    key: "AMOUNT",
  },
]

export const studentFields = [
  {
    header: "Mock InterView",
    name: "mockTestCredit",
    key: "DEFAULT",
  },
]

export const companyFields = [
  {
    header: "Job Post Limit",
    name: "jobCredit",
    key: "DEFAULT",
  },
  {
    header: "Unlock Candidate Limit",
    name: "profileCredit",
    key: "DEFAULT",
  },
  {
    header: "HR User Limit",
    name: "addUserCredit",
    key: "DEFAULT",
  },
]

export const actions = [
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Edit", type: "EDIT" },
      { label: "Delete", type: "DELETE" },
    ],
  },
]
