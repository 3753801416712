import React from "react"
import AppInput from "../AppInput/index"
import AppTextArea from "../AppTextArea/index"
import AppSelect from "../AppSelect/index"
import AppSearch from "../AppSearch/index"

export const renderFormField = (field, formik) => {
  switch (field.type) {
    case "text":
      return (
        <AppInput
          key={field.name}
          label={field.label}
          name={field.name}
          type={field.type}
          className="custom_form_control"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[field.name]}
          invalid={formik.touched[field.name] && formik.errors[field.name]}
          error={formik.touched[field.name] && formik.errors[field.name]}
          disabled={field?.disabled ? field?.disabled : false}
          placeholder={field?.placeholder}
        />
      )
    case "textarea":
      return (
        <AppTextArea
          key={field.name}
          label={field.label}
          name={field.name}
          type={field.type}
          className="custom_form_control"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[field.name]}
          invalid={formik.touched[field.name] && formik.errors[field.name]}
          error={formik.touched[field.name] && formik.errors[field.name]}
          disabled={field?.disabled ? field?.disabled : false}
          placeholder={field?.placeholder}
        />
      )
    case "select":
      return (
        <AppSelect
          key={field.name}
          label={field.label}
          name={field.name}
          id="status1"
          className="form-select custom_form_control"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[field.name]}
          options={field.options}
          invalid={formik.touched[field.name] && formik.errors[field.name]}
          error={formik.touched[field.name] && formik.errors[field.name]}
        />
      )
    case "switchSelect":
      return (
        <>
          <AppSelect
            key={field.name}
            label={field.label}
            name={field.name}
            id="status1"
            className="form-select custom_form_control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[field.name]}
            options={field.options}
            invalid={formik.touched[field.name] && formik.errors[field.name]}
            error={formik.touched[field.name] && formik.errors[field.name]}
          />
          {renderSwitchedFields(field, formik)}
        </>
      )
    case "search":
      return (
        <>
          <AppSearch
            name={field.name}
            label={field.label}
            options={field.options}
            onChange={formik.handleChange}
            formik={formik}
          />
        </>
      )
    default:
      return "invalid field type"
  }
}

const renderSwitchedFields = (field, formik) => {
  const selectedType = formik.values[field.name]

  if (selectedType === "course") {
    return field.fields
      .filter(mField => mField.name === "course")
      .map((mField, key) => (
        <div key={key}>{renderFormField(mField, formik)}</div>
      ))
  } else if (selectedType === "test") {
    return field.fields
      .filter(mField => mField.name === "test")
      .map((mField, key) => (
        <div key={key}>{renderFormField(mField, formik)}</div>
      ))
  } else if (selectedType === "subscription") {
    return field.fields
      .filter(mField => mField.name === "subscription")
      .map((mField, key) => (
        <div key={key}>{renderFormField(mField, formik)}</div>
      ))
  }

  return null
}
