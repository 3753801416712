import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
  Spinner,
} from "reactstrap"
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAuth,
} from "firebase/auth"
import { auth, firebaseApp } from "../../firebase.config"
import * as Yup from "yup"
import { useFormik } from "formik"
import logolight from "../../assets/images/qapreneur/logo-light.png"
import CarouselPage from "./CarouselPage"
import { loginUser, resetPassword } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { checkUserbyPhone } from "helpers/backend_helper"
import toastr from "toastr"
import withRouter from "components/Common/withRouter"
import "./authentication.css"
import AppInput from "../../components/Common/AppInput"

const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [showForgatePassword, setShowForgatePassword] = useState(false)
  const [showOtpField, setShowOtpField] = useState(false)
  const [showResetPasswordField, setShowResetPasswordField] = useState(false)
  const [showCapcha, setShowCapcha] = useState(true)

  const dispatch = useDispatch()

  document.title = "QAPreneur | Software testing free course with certificate"

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter email"),
      password: Yup.string().required("Please Enter Password"),
    }),
    onSubmit: values => {
      dispatch(
        loginUser(values, props.router.navigate, isAdmin ? "admin" : "others")
      )
    },
  })

  const otpValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("Please Enter OTP")
        .matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
    }),
    onSubmit: values => {
      verifyOtp(values.otp.toString())
    },
    isSubmitting: false,
  })

  const phoneValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^[0-9]+$/, {
          message: "phone Number must contain only numeric digits",
        })
        .matches(/^\d{10}$/, {
          message: "phone Number must be exactly 10 digits",
        })
        .required(`Please Enter phone Number`),
    }),
    onSubmit: async values => {
      const response = await checkUserbyPhone({
        ...values,
        extra: { contactNumber: values.phone },
      })
      if (response.userExists) {
        sendOtp(values)
        showCapcha(true)
        setShowForgatePassword(false)
        setShowOtpField(true)
        onCaptchVerify()
      } else {
        toastr.error("Invailid mobile Number!")
        setShowOtpField(false)
        setShowForgatePassword(false)
        setShowCapcha(true)
        setShowResetPasswordField(false)
      }
    },
    isSubmitting: false,
  })

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container"
      )
    }
  }

  function sendOtp(values) {
    setShowOtpField(true)
    setShowForgatePassword(false)
    onCaptchVerify()
    const appVerifier = window.recaptchaVerifier
    const formatPh = "+91" + values.phone
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult
      })
      .catch(error => {
        console.log(error)
        // setLoading(false);
        // if (error.code === 'auth/too-many-requests') {
        //   toastr.error('Too many requests. Please try again later.');
        // } else {
        // }
        toastr.error("Error sending OTP. Please try again.")
        setShowOtpField(false)
        setShowForgatePassword(false)
        setShowResetPasswordField(false)
        setShowCapcha(false)
      })
  }

  const verifyOtp = otp => {
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult
      confirmationResult
        .confirm(otp)
        .then(result => {
          setShowOtpField(false)
          setShowForgatePassword(false)
          setShowCapcha(false)
          setShowResetPasswordField(true)
        })
        .catch(error => {
          console.log(error, "error??")
        })
    }
  }

  const resetPasswordValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter a new password")
        .min(8, "Password must be at least 8 characters"),
      passwordConfirm: Yup.string()
        .required("Please confirm your new password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: values => {
      const data = {
        ...values,
        ...phoneValidation.values,
      }
      dispatch(resetPassword(data))
      setShowOtpField(false)
      setShowForgatePassword(false)
      setShowCapcha(false)
      setShowResetPasswordField(false)
    },
    isSubmitting: false,
  })

  const { loading } = useSelector(state => ({
    loading: state.Login.loading,
  }))

  useEffect(() => {
    const currentLocation = props.router.location.pathname
    const status = currentLocation.includes("admin")
    setIsAdmin(status)
  }, [])

  return (
    <React.Fragment>
      <div>
        <meta name="description" content="Your meta description goes here" />
        {/* Add other meta tags as needed */}
        <meta
          name="keywords"
          content="QA delivers training, Learning Management Systems, ..."
        />
        {/* Add other meta tags as needed */}
      </div>
      <div>
        <Container fluid className="p-0 overflow-hidden">
          <Row className="g-0">
            <CarouselPage role="user_login" />

            <Col xl={5} lg={5} md={12} sm={12}>
              <div className="auth-full-page-content p-md-5 p-4 vh-100 vh-sm-100">
                <div className="w-100 auth_content_container">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logolight}
                         alt={"qapreneur"}
                          height="64.75px"
                          width="275.5px"
                          className="logo-dark-element"
                        />
                      </Link>
                    </div>
                    {!showForgatePassword &&
                      !showOtpField &&
                      !showResetPasswordField && (
                        <>
                          <div className="my-lg-auto mt-3">
                            <div className="mb-5">
                              <h5 className="text-primary page_title">
                                Sign in
                              </h5>
                              <p className="text-muted page_description">
                                Sign in to continue to QAPRENEUR.
                              </p>
                            </div>

                            <div className="mt-4">
                              <Form
                                className="form-horizontal"
                                onSubmit={e => {
                                  e.preventDefault()
                                  validation.handleSubmit()
                                  return false
                                }}
                              >
                                <div className="mb-4">
                                  <Label className="form-label">Email</Label>
                                  <Input
                                    name="email"
                                    className="form-control input_field"
                                    placeholder="Enter email"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                      validation.touched.email &&
                                      validation.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.email &&
                                  validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.email}
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">Password</Label>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      name="password"
                                      className="input_field"
                                      value={validation.values.password || ""}
                                      type={passwordShow ? "text" : "password"}
                                      placeholder="Enter Password"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.password &&
                                        validation.errors.password
                                          ? true
                                          : false
                                      }
                                    />

                                    {validation.touched.password &&
                                    validation.errors.password ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.password}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>

                                {!isAdmin && (
                                  <div className="text-end txt_forgot_psd py-1">
                                    <Link
                                      onClick={() => {
                                        setShowForgatePassword(true)
                                        setShowCapcha(true)
                                      }}
                                      className="text-muted"
                                    >
                                      Forgot password?
                                    </Link>
                                  </div>
                                )}

                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block custom_btn"
                                    type="submit"
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <Spinner
                                        className="ms-2"
                                        color="light"
                                        size="sm"
                                      />
                                    ) : (
                                      "Sign in"
                                    )}
                                  </button>
                                </div>
                              </Form>

                              {!isAdmin && (
                                <div className="mt-4 text-center">
                                  <p className="txt_noAccount">
                                    Don&apos;t have an account ?
                                    <Link
                                      to="/company/register"
                                      // /student/register
                                      className="fw-medium text-primary ms-2"
                                    >
                                      Sign up
                                    </Link>
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    {showForgatePassword && (
                      <>
                        <div className="my-lg-auto">
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              phoneValidation.handleSubmit()
                              return false
                            }}
                          >
                            <div className="mb-4">
                              <AppInput
                                label={"Enter Mobile Number"}
                                name="phone"
                                className="input_field otp_container"
                                type="text"
                                placeholder="Please Enter Mobile Number"
                                onChange={phoneValidation.handleChange}
                                onBlur={phoneValidation.handleBlur}
                                value={phoneValidation.values?.phone}
                                invalid={
                                  phoneValidation.touched?.phone &&
                                  phoneValidation.errors?.phone
                                }
                                error={phoneValidation.errors?.phone}
                              />
                              {phoneValidation.touched.phone &&
                              phoneValidation.errors.phone ? (
                                <FormFeedback type="invalid">
                                  {phoneValidation.errors.phone}
                                </FormFeedback>
                              ) : null}
                            </div>
                            {/* <div id="recaptcha-container"></div> */}

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block custom_btn"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <Spinner
                                    className="ms-2"
                                    color="light"
                                    size="sm"
                                  />
                                ) : (
                                  "Send OTP"
                                )}
                              </button>
                            </div>
                          </Form>
                        </div>
                      </>
                    )}
                    {showOtpField && (
                      <>
                        <div className="my-lg-auto">
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              otpValidation.handleSubmit()
                              return false
                            }}
                          >
                            <p className="text-muted page_description">
                              We Sent OTP On This Mobile Number{" "}
                              {phoneValidation.values.phone &&
                                `******${phoneValidation.values.phone.slice(
                                  -2
                                )}`}
                            </p>
                            <div className="mb-4">
                              <AppInput
                                label={"verify your OTP code"}
                                name="otp"
                                className="input_field otp_container"
                                type="text"
                                placeholder="please enter 6 digit code"
                                onChange={otpValidation.handleChange}
                                onBlur={otpValidation.handleBlur}
                                value={otpValidation.values?.otp}
                                invalid={
                                  otpValidation.touched?.otp &&
                                  otpValidation.errors?.otp
                                }
                                error={otpValidation.errors?.otp}
                              />
                              {otpValidation.touched.phone &&
                              otpValidation.errors.phone ? (
                                <FormFeedback type="invalid">
                                  {otpValidation.errors.phone}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block custom_btn"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <Spinner
                                    className="ms-2"
                                    color="light"
                                    size="sm"
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </div>
                          </Form>
                        </div>
                      </>
                    )}
                    {showCapcha && <div id="recaptcha-container"></div>}
                    {showResetPasswordField && (
                      <>
                        <div className="my-lg-auto">
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              resetPasswordValidation.handleSubmit()
                              return false
                            }}
                          >
                            <div className="mb-4">
                              <AppInput
                                label="New Password"
                                name="password"
                                className="input_field otp_container"
                                type="text"
                                placeholder="Enter new password"
                                onChange={resetPasswordValidation.handleChange}
                                onBlur={resetPasswordValidation.handleBlur}
                                value={resetPasswordValidation.values?.password}
                                invalid={
                                  resetPasswordValidation.touched?.password &&
                                  resetPasswordValidation.errors?.password
                                }
                                error={resetPasswordValidation.errors?.password}
                              />
                            </div>
                            <div className="mb-4">
                              <AppInput
                                label="Confirm New Password"
                                name="passwordConfirm"
                                className="input_field otp_container"
                                type="text"
                                placeholder="Confirm new password"
                                onChange={resetPasswordValidation.handleChange}
                                onBlur={resetPasswordValidation.handleBlur}
                                value={
                                  resetPasswordValidation.values
                                    ?.passwordConfirm
                                }
                                invalid={
                                  resetPasswordValidation.touched
                                    ?.passwordConfirm &&
                                  resetPasswordValidation.errors
                                    ?.passwordConfirm
                                }
                                error={
                                  resetPasswordValidation.errors
                                    ?.passwordConfirm
                                }
                              />
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block custom_btn"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <Spinner
                                    className="ms-2"
                                    color="light"
                                    size="sm"
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </div>
                          </Form>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
