import React from 'react';
import { Row, Col } from 'reactstrap';
const staticURL = process.env.REACT_APP_STATIC_URL;

function MediaLayoutList({ media, key, handleDeleteImage, handleCopyImageUrl, showDelete }) {
    const imgUrl = staticURL + media?.media;
    return (
        <div key={key} className="mb-3 gallery-item position-relative">
            <Row>
                <Col xs="12" sm="6" md="3">
                    <img
                        className="img-fluid"
                        src={imgUrl}
                        alt={`Media ${key}`}
                    />
                </Col>
                <Col xs="12" sm="6" md="3">
                    <p className="image-name mt-2">{media.mediaName}</p>
                </Col>
                <Col xs="12" sm="6" md="3">
                    <p className="image-name mt-2">{Date.now(media.createdAt)}</p>
                </Col>
                <Col xs="12" sm="12" md="3">
                    <div className='d-flex align-items-center mt-2'>
                        {showDelete &&
                            <button className="delete-button me-2 mb-0" onClick={() => handleDeleteImage(media?._id)}>Delete</button>
                        }
                        <button className="btn btn-primary" onClick={() => handleCopyImageUrl(imgUrl)}>Copy</button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default MediaLayoutList;
