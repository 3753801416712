/**
 * Add Chapter Content
 */
 export const ADD_CHAPTER_CONTENT = "ADD_CHAPTER_CONTENT"
 export const ADD_CHAPTER_CONTENT_SUCCESS = "ADD_CHAPTER_CONTENT_SUCCESS"
 export const ADD_CHAPTER_CONTENT_FAIL = "ADD_CHAPTER_CONTENT_FAIL"
 
 /**
  * Get Chapter Content
  */
 export const GET_CHAPTER_CONTENT = "GET_CHAPTER_CONTENT"
 export const GET_CHAPTER_CONTENT_SUCCESS = "GET_CHAPTER_CONTENT_SUCCESS"
 export const GET_CHAPTER_CONTENT_FAIL = "GET_CHAPTER_CONTENT_FAIL"
 
 /**
  * Edit Chapter Content
  */
 export const UPDATE_CHAPTER_CONTENT = "UPDATE_CHAPTER_CONTENT"
 export const UPDATE_CHAPTER_CONTENT_SUCCESS = "UPDATE_CHAPTER_CONTENT_SUCCESS"
 export const UPDATE_CHAPTER_CONTENT_FAIL = "UPDATE_CHAPTER_CONTENT_FAIL"
 
 /**
  * Delete Chapter Content
  */
 export const DELETE_CHAPTER_CONTENT = "DELETE_CHAPTER_CONTENT"
 export const DELETE_CHAPTER_CONTENT_SUCCESS = "DELETE_CHAPTER_CONTENT_SUCCESS"
 export const DELETE_CHAPTER_CONTENT_FAIL = "DELETE_CHAPTER_CONTENT_FAIL"
 
 export const UPDATE_CHAPTER_CONTENT_LOADING_STATE = "UPDATE_CHAPTER_CONTENT_LOADING_STATE"
 export const RESET_CHAPTER_CONTENT = "RESET_CHAPTER_CONTENT"
 