import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { searchUsers as onSearchUsers } from "../../../store/actions"
import {  Label } from "reactstrap"

function AppSearch({ onChange, name, label, formik }) {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(null)
  const [options, setOptions] = useState([])
  const dispatch = useDispatch()
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectOptn, setSelectOptn] = useState("")

  const { students } = useSelector(state => ({
    students: state?.students?.students,
  }))

  useEffect(() => {
    dispatch(onSearchUsers({ type: "User", slug: search }))
  }, [search])

  useEffect(() => {
    setOptions(
      students && students.length
        ? [
            { value: "", label: "Choose Students" },
            ...students.map(student => ({
              value: student._id,
              //   label: `Name : ${student.name} | Email: ${student.email}`,
              label: `${student.name} (${student.email})`,
              label2: `(${student.email})`,
            })),
          ]
        : []
    )
  }, [students])

  const handleSelectOption = option => {
    onChange({ target: { value: option } })
    setShowDropdown(false)
    setSelectOptn(option)
    formik.setFieldValue(name, option.value)
  }
  return (
    <div className="search-box me-xxl-2  my-xxl-0 d-inline-block d-flex justify-content-center flex-column">
      <Label className="form-label">{label}</Label>
      <div className="position-relative mt-2">
        <div className="position-relative d-flex align-items-center">
          <input
            onChange={e => {
              setSearch(e.target.value)
              setShowDropdown(true)
            }}
            id="search-bar-0"
            type="text"
            className="form-control custom_searchbox"
            placeholder={`${label}...`}
            value={search}
            name={name}
          />
          <i className="bx bx-search-alt search-icon p-2"></i>
        </div>
        {loading && <p>Loading...</p>}
        {showDropdown && search && (
          <div className="dropdown mt-3">
            <ul className="dropdown-menu" style={{ display: "block" }}>
              {options.map((option, index) => (
                <li key={index}>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => handleSelectOption(option)}
                  >
                    {option.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {selectOptn && (
        <div className="selected-option d-flex align-items-cetner ms-2">
          <button style={{ border: "none", height: 30, padding: 2 }}>
            {selectOptn?.label2}
            <span
              onClick={() => setSelectOptn("")}
              style={{ fontSize: 18, marginLeft: 2 }}
            >
              &times;
            </span>
          </button>
        </div>
      )}
    </div>
  )
}

export default AppSearch
