import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import FilterDropdown from "../JobManagement/Table/FilterDropdown"
import TableContainer from "../../components/Common/Table/index"
import { myCandidatesColumns } from "./constants"

import {
  getMyCandidates as onGetMyCandidates,
  getCompanyCredit as onGetCompanyCredit,
} from "../../store/actions"

function MyCandidateList() {
  document.title = "My Candidate List | QAPRENEUR"
  const params = useParams()
  const dispatch = useDispatch()
  const [candidateList, setCandidateList] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(5)
  const [search, setSearch] = useState(null)
  const [locationFilter, setLocationFilter] = useState({
    city: "",
    state: "",
    skills: "",
    experience: "",
  })

  const { loading, myCandidates } = useSelector(state => ({
    loading: state.applicants.loading,
    myCandidates: state.applicants.myCandidates,
  }))

  useEffect(() => {
    if (params.id) {
      dispatch(onGetCompanyCredit(params.id))
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [
    onGetMyCandidates,
    pageNo,
    rowPerPage,
    search,
    locationFilter.state,
    locationFilter.city,
    locationFilter.degree,
    locationFilter.skills,
    locationFilter.experience,
  ])

  useEffect(() => {
    if (Array.isArray(myCandidates)) {
      setCandidateList(
        myCandidates
          .map(candidate => ({
            ...candidate,
            jobCount: candidate?.jobDetail ? 1 : 0,
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setCandidateList([])
    }
  }, [myCandidates, pageNo])

  const loadData = () => {
    const authRole = localStorage.getItem("authRole")
    const authUser = JSON.parse(localStorage.getItem("authUser"))
    if (params.id) {
      const queryParams = {
        page: pageNo,
        limit: rowPerPage,
      }
      if (authRole === "hr_user") {
        const authUser = localStorage.getItem("authUser")
        const user = authUser && JSON.parse(authUser)
        queryParams.postedBy = user._id
      }
      if (search && search.length > 0) {
        queryParams.search = search
      }
      if (locationFilter && locationFilter.state) {
        queryParams.state = locationFilter.state
      }
      if (locationFilter && locationFilter.city) {
        queryParams.city = locationFilter.city
      }
      if (locationFilter && locationFilter.degree) {
        queryParams.degree = locationFilter.degree
      }
      if (locationFilter && locationFilter.experience) {
        queryParams.experience = locationFilter.experience
      }
      if (locationFilter && locationFilter.skills) {
        queryParams.skills = locationFilter.skills
      }
      if (authRole === "hr_user") {
        dispatch(onGetMyCandidates(authUser.companyId, queryParams))
      } else {
        dispatch(onGetMyCandidates(params.id, queryParams))
      }
    }
  }

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const handlePagination = type => {
    if (type === "NEXT") {
      setPageNo(pageNo + 1)
      setCandidateList([])
    } else if (type === "PREV") {
      setPageNo(pageNo - 1)
    }
  }

  const handlefilterchange = (type, value) => {
    if (type === "degree" && value) {
      const degree = value.value
      setLocationFilter(locationFilter => ({
        ...locationFilter,
        degree: degree,
      }))
    }
    if (type === "state" || type === "city" || type === "experience") {
      setLocationFilter(locationFilter => ({
        ...locationFilter,
        [type]: value,
      }))
    }
    if (type === "skills") {
      setLocationFilter(prev => ({
        ...prev,
        [type]: value.length && value.map(val => val.label).join(","),
      }))
    }
  }

  const handleFilter = () => {
    loadData()
  }

  const onResetFilter = type => {
    if (type === "ALL") {
      setLocationFilter({ city: "", state: "", experience: "", degree: "" })
      loadData()
    }
    if (type === "location" || type === "experience") {
      setLocationFilter(prev => ({ ...prev, city: "", state: "" }))
    }
    if (type === "degree") {
      setLocationFilter(prev => ({ ...prev, degree: "" }))
    }
  }

  const onChangePagination = (limit, page) => {
    setRowPerPage(limit)
    setPageNo(page)
  }

  const handleActions = (test, type) => {}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Breadcrumbs
                title="Dashboard"
                navigate="/dashboard"
                breadcrumbItem="My Applicant"
              />
              <div>
                <FilterDropdown
                  handlefilterchange={handlefilterchange}
                  handleFilter={handleFilter}
                  onReset={onResetFilter}
                />
                {/* {loading && <AppLoader />}
                {!loading &&
                  candidateList &&
                  candidateList.map((candidate, index) => (
                    <ListItem
                      key={index}
                      applicant={candidate}
                      link="/profile/view/candidate"
                      showJobdetail={true}
                      showskills={false}
                    />
                  ))}
                {!loading && !candidateList.length && (
                  <>
                    <div className="text-center my-3">
                      <div className="text-success">No Data Available</div>
                    </div>
                  </>
                )}
                {!candidateList.length && !loading && <NotFound />}

                <TablePagination
                  data={candidateList}
                  pageSize={rowPerPage}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                /> */}
                <TableContainer
                  loading={loading}
                  data={candidateList}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={5}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={myCandidatesColumns}
                  showPagination={true}
                  onChangePagination={onChangePagination}
                  showPageDropdown={false}
                  notDataFound={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyCandidateList
