import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import FilterDropdown from "../JobManagement/Table/FilterDropdown"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import {
  getCandidates as onGetCandidates,
  getCompanyCredit as onGetCompanyCredit,
  unlockApplicantsStatus as onUnlockApplicantsStatus,
} from "../../store/actions"
import { candidatesColumns } from "./constants"
import toastr from "toastr"

function Candidate() {
  document.title = "Candidate List | QAPRENEUR"
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [candidateList, setCandidateList] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(5)
  const [search, setSearch] = useState(null)
  const [filterCandidate, setFilterCandidate] = useState({
    city: "",
    state: "",
    skills: "",
    experience: "",
  })

  const { loading, otherCandidates, credit } = useSelector(state => ({
    loading: state.applicants.loading,
    otherCandidates: state.applicants.Candidates,
    credit: state.companies.credit,
  }))

  useEffect(() => {
    if (params.id) {
      dispatch(onGetCompanyCredit(params.id))
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [
    onGetCandidates,
    pageNo,
    rowPerPage,
    search,
    filterCandidate.state,
    filterCandidate.city,
    filterCandidate.skills,
    filterCandidate.experience,
    filterCandidate.degree,
  ])

  useEffect(() => {
    if (Array.isArray(otherCandidates)) {
      setCandidateList(
        otherCandidates
          .map(candidate => ({
            ...candidate,
            studentId: candidate._id,
            jobCount: candidate?.appliedJobsByUser.length,
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setCandidateList([])
    }
  }, [otherCandidates, pageNo])

  const loadData = () => {
    if (params.id) {
      const queryParams = {
        page: pageNo,
        limit: rowPerPage,
      }
      if (search && search.length > 0) {
        queryParams.search = search
      }
      if (filterCandidate && filterCandidate.state) {
        queryParams.state = filterCandidate.state
      }
      if (filterCandidate && filterCandidate.city) {
        queryParams.city = filterCandidate.city
      }
      if (filterCandidate && filterCandidate.degree) {
        queryParams.degree = filterCandidate.degree
      }
      if (filterCandidate && filterCandidate.experience) {
        queryParams.experience = filterCandidate.experience
      }
      if (filterCandidate && filterCandidate.skills) {
        queryParams.skills = filterCandidate.skills
      }
      dispatch(onGetCompanyCredit(params.id))
      dispatch(onGetCandidates(params.id, queryParams))
    }
  }

  const handlefilterchange = (type, value) => {
    if (type === "degree" && value) {
      const degree = value.value
      setFilterCandidate(prev => ({
        ...prev,
        degree: degree,
      }))
    }
    if (type === "state" || type === "city" || type === "experience") {
      setFilterCandidate(prev => ({
        ...prev,
        [type]: value,
      }))
    }
    if (type === "skills") {
      setFilterCandidate(prev => ({
        ...prev,
        [type]: value.length && value.map(val => val.label).join(","),
      }))
    }
  }

  const handleFilter = () => {
    loadData()
  }

  const onResetFilter = type => {
    if (type === "ALL") {
      setFilterCandidate({ city: "", state: "", experience: "", degree: "" })
      loadData()
    }
    if (type === "location" || type === "experience") {
      setFilterCandidate(prev => ({ ...prev, city: "", state: "" }))
    }
    if (type === "degree") {
      setFilterCandidate(prev => ({ ...prev, degree: "" }))
    }
  }

  const onChangePagination = (limit, page) => {
    setRowPerPage(limit)
    setPageNo(page)
  }

  const handleActions = (data, type) => {
    if (type === "VIEW") {
      navigate(`/profile/view/candidate/${data?._id}`)
    }
  }

  const handleCandidateLock = data => {
    if (credit?.profileCredit > 0) {
      data.isUnlock = true
      dispatch(onUnlockApplicantsStatus(data._id, data))
    } else {
      toastr.error("your Profile Credit Limit Ended.")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Breadcrumbs
                title=""
                navigate="/jobs-list"
                breadcrumbItem="Candidates"
              />
              <div>
                <FilterDropdown
                  handlefilterchange={handlefilterchange}
                  handleFilter={handleFilter}
                  onReset={onResetFilter}
                />
                <TableContainer
                  loading={loading}
                  data={candidateList}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={5}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={candidatesColumns}
                  showPagination={true}
                  onChangePagination={onChangePagination}
                  showPageDropdown={false}
                  handleActions={handleActions}
                  handleUnlock={handleCandidateLock}
                  notDataFound={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Candidate
