import {
 GET_HR_USERS,
 GET_HR_USERS_DETAIL,
 GET_HR_USERS_DETAIL_FAIL,
 GET_HR_USERS_DETAIL_SUCCESS,
 GET_HR_USERS_FAIL,
 GET_HR_USERS_SUCCESS,
 ADD_HR_USER_FAIL,
 ADD_HR_USER_SUCCESS,
 ADD_NEW_HR_USER,
 UPDATE_HR_USER,
 UPDATE_HR_USER_FAIL,
 UPDATE_HR_USER_SUCCESS,
 UPDATE_HR_USER_LOADING_STATE
} from "./actionTypes"

export const getHrUsers = (params) => ({
  type: GET_HR_USERS,
  params
})

export const getHrUsersSuccess = hrUsers => ({
  type: GET_HR_USERS_SUCCESS,
  payload: hrUsers,
})

export const getHrUsersFail = error => ({
  type: GET_HR_USERS_FAIL,
  payload: error,
})

export const addNewhrUser = (hrUser) => ({
  type: ADD_NEW_HR_USER,
  payload: { hrUser },
})

export const addhrUserSuccess = hrUser => ({
  type: ADD_HR_USER_SUCCESS,
  payload: hrUser,
})

export const addhrUserFail = error => ({
  type: ADD_HR_USER_FAIL,
  payload: error,
})


export const gethrUserDetail = hrUserId => ({
  type: GET_HR_USERS_DETAIL,
  hrUserId,
})

export const gethrUserDetailSuccess = hrUserDetails => ({
  type: GET_HR_USERS_DETAIL_SUCCESS,
  payload: hrUserDetails,
})

export const gethrUserDetailFail = error => ({
  type: GET_HR_USERS_DETAIL_FAIL,
  payload: error,
})

export const updateHrUser = (hr, hrId) => ({
  type: UPDATE_HR_USER,
  payload: { hr, hrId },
})

export const updateHrUserSuccess = hr => ({
  type: UPDATE_HR_USER_SUCCESS,
  payload: hr,
})

export const updateHrUserFail = error => ({
  type: UPDATE_HR_USER_FAIL,
  payload: error,
})


export const updatehrLoadingState = status => ({
  type: UPDATE_HR_USER_LOADING_STATE,
  payload: status,
})