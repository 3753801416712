import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody,Button } from "reactstrap"
import AppLoader from "../Loader/index"
const staticURL = process.env.REACT_APP_STATIC_URL

function CardAbout({
  title,
  descripion,
  isHtml = false,
  videoUrl = "",
  isPdf = false,
  pdfUrl = "",
  loading = false,
  isButton = false,
  onClick,
  BtnTitle=""
}) {
  const converFileName = () => {
    const filePathArray = JSON.parse(pdfUrl)
    const fileName = filePathArray.length && filePathArray[0]
    return staticURL + fileName
  }

  const pdf = isPdf && converFileName()
  return (
    <Card style={{ borderRadius: 15 }}>
      <CardBody>
        {loading ? (
          <AppLoader />
        ) : (
          <div className="pt-3 px-2">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="sun_heading">{title}</h4>
                    {isButton && onClick && (
                      <Button
                        color="primary"
                        className="global_btn btn btn-primary px-4"
                        onClick={onClick}
                      >
                        <i className="bx bx-edit font-size-16 align-middle me-2"></i>

                        {BtnTitle}
                      </Button>
                    )}
                  </div>
                  <div className="mt-3">
                    {isHtml ? (
                      <>
                        <div
                          className="text-muted font-size-14"
                          id="editor"
                          dangerouslySetInnerHTML={{ __html: descripion }}
                        />
                      </>
                    ) : (
                      <div className="text-muted font-size-14">
                        {descripion}
                      </div>
                    )}
                  </div>
                  {videoUrl && (
                    <div className="mt-3">
                      <iframe
                        width="400"
                        height="200"
                        src={videoUrl}
                        title="YouTube video player"
                        className="mt-2"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  )}
                  {isPdf && (
                    <div className="d-flex align-items-center shadow-sm border border-2 border-secondary-5">
                      <iframe src={pdf} height={80} width={100} />
                      <div className="ms-4">
                        <p className="text-muted font-weight-bold">{pdf}</p>
                        <Link
                          className="bg-danger p-1"
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            borderRadius: "5px",
                          }}
                          to={pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bx-file font-size-16 align-middle me-1"></i>
                          View PDF
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default CardAbout
