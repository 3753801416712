import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Container } from "reactstrap"
import {
  getPlanDetail as onGetPlansDetail,
  resetPlans as onResetPlan,
} from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AppForm from "../../components/Common/Form"
import { getPlanForm, handleSubmit } from "./Utills"
import AppLoader from "../../components/Common/Loader"

const ConfigurePlan = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [isEdit, setIsEdit] = useState(false)

  document.title = `${isEdit ? "Edit Plan" : "Create Plan"} | QAPRENEUR`

  const { planDetail, loading } = useSelector(state => ({
    planDetail: state.plans?.planDetail,
    loading: state.plans?.loading,
  }))

  useEffect(() => {
    const currentLocation = location.pathname
    const status = currentLocation.includes("edit-plan")
    setIsEdit(status)
    if (status && params?.planId) dispatch(onGetPlansDetail(params.planId))
    return () => dispatch(onResetPlan())
  }, [])

  const handleFormSubmit = (formikValues, formik) => {
    handleSubmit(formikValues, formik, isEdit, params, dispatch, navigate)
  }

  const formFields = getPlanForm(params.role)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Plan"
            navigate="/plan-list/student"
            breadcrumbItem={`${isEdit ? "Edit" : "Create"} ${params.role} plan`}
          />
          {loading && <AppLoader />}
          {!loading && (
            <Card>
              <CardBody>
                <AppForm
                  name=""
                  layoutType={isEdit ? "UPDATE" : "CREATE"}
                  fields={formFields}
                  initialValues={{
                    price: planDetail?.price ? planDetail?.price + "" : "",
                    actualPrice: planDetail?.actualPrice
                      ? planDetail?.actualPrice + ""
                      : "",
                    description: planDetail?.description,
                    ...(planDetail?.companySettings
                      ? planDetail?.companySettings
                      : {}),
                    ...(planDetail?.studentSettings
                      ? planDetail?.studentSettings
                      : {}),
                    _id: planDetail._id,
                  }}
                  handleFormSubmit={(formikValues, formik) => {
                    handleFormSubmit(formikValues, formik)
                  }}
                  cancelRoute={`/plan-list/${params?.role}`}
                />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfigurePlan
