import {
  GET_PACKAGE,
  GET_PACKAGE_FAIL,
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_DETAIL,
  ADD_NEW_PACKAGE,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAIL,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAIL,
  GET_PACKAGE_DETAIL_FAIL,
  GET_PACKAGE_DETAIL_SUCCESS,
  UPDATE_PACKAGE_LOADING_STATE,
  RESET_PACKAGE,
  DELETE_PACKAGE,
  DELETE_PACKAGE_FAIL,
  DELETE_PACKAGE_SUCCESS,
} from "./actionTypes"

export const getPackages = params => ({
  type: GET_PACKAGE,
  payload: params,
})

export const getPackagesSuccess = packages => ({
  type: GET_PACKAGE_SUCCESS,
  payload: packages,
})

export const getPackagesFail = error => ({
  type: GET_PACKAGE_FAIL,
  payload: error,
})

export const addNewPackage = (packageData, history) => ({
  type: ADD_NEW_PACKAGE,
  payload: { packageData, history },
})

export const addPackageSuccess = packageData => ({
  type: ADD_PACKAGE_SUCCESS,
  payload: packageData,
})

export const addPackageFail = error => ({
  type: ADD_PACKAGE_FAIL,
  payload: error,
})

export const updatePackage = (packageData, params, history) => ({
  type: UPDATE_PACKAGE,
  payload: { packageData, params, history },
})

export const updatePackageSuccess = packageData => ({
  type: UPDATE_PACKAGE_SUCCESS,
  payload: packageData,
})

export const updatePackageFail = error => ({
  type: UPDATE_PACKAGE_FAIL,
  payload: error,
})

export const getPackageDetail = packageId => ({
  type: GET_PACKAGE_DETAIL,
  packageId,
})

export const getPackageDetailSuccess = packageDetails => ({
  type: GET_PACKAGE_DETAIL_SUCCESS,
  payload: packageDetails,
})

export const getPackageDetailFail = error => ({
  type: GET_PACKAGE_DETAIL_FAIL,
  payload: error,
})

export const updatePackageLoadingState = status => ({
  type: UPDATE_PACKAGE_LOADING_STATE,
  payload: status,
})

export const resetPackages = () => ({
  type: RESET_PACKAGE,
})

export const deletePackage = packageId => ({
  type: DELETE_PACKAGE,
  packageId,
})

export const deletePackageSuccess = packageData => ({
  type: DELETE_PACKAGE_SUCCESS,
  payload: packageData,
})

export const deletePackageFail = error => ({
  type: DELETE_PACKAGE_FAIL,
  payload: error,
})
