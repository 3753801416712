export const applicantsColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
    link: "/candidate-details",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    link: "/candidate-details",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/candidate-details",
  },
  {
    header: "Contact",
    name: "phone",
    key: "DEFAULT",
    link: "/candidate-details",
  },
]

export const profileStstuaTableColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Profile Status %",
    name: "profileCompletion",
    key: "DEFAULT",
  },
  {
    header: "Profile Completed At",
    name: "updatedAt",
    key: "DATE",
  },
]
