import React, { useEffect, useState } from "react"
import withRouter from "components/Common/withRouter"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Col, Container, Row, Spinner } from "reactstrap"
import {
  getSubscriptonsUsers as onGetSubscriptonsUsers,
  resetSubscriptonsUsers as onResetSubscriptonsUsers,
} from "../../store/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import { UserColumns } from "./constants"
import { userProfileFrame } from "../StudentManagement/Utills"

const PurchasedCourseUsers = props => {
  document.title = "Course List | QAPRENEUR"
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)

  const { loading, usersPurchasedCourses } = useSelector(state => ({
    loading: state.courses.loading,
    usersPurchasedCourses: state.courses.usersPurchasedCourses,
  }))

  useEffect(() => {
    if (params.id) {
      const queryParams = {
        page: pageNo,
        limit: rowPerPage,
      }
      dispatch(onGetSubscriptonsUsers(params.id, queryParams))
    }
    return () => {
      dispatch(onResetSubscriptonsUsers())
      setIsLoading(true)
    }
  }, [dispatch, pageNo, rowPerPage])

  useEffect(() => {
    if (Array.isArray(usersPurchasedCourses)) {
      setIsLoading(false)
    }
  }, [usersPurchasedCourses, pageNo])

  const flattenedData =
    Array.isArray(usersPurchasedCourses) && usersPurchasedCourses.length
      ? usersPurchasedCourses.map(item => {
          const user = item.user
          let chapterCount = item.chapterCount
          let completedChapterCount = Math.min(
            chapterCount,
            item.completedChapterCount
          )
          let baseCompletionPercentage =
            (completedChapterCount / chapterCount) * 90

          let finalCompletionPercentage = Math.min(baseCompletionPercentage, 90)
          if (item.isCertificateTestCompleted) {
            finalCompletionPercentage += 10
          }
          finalCompletionPercentage = Math.max(finalCompletionPercentage, 0)
          return {
            ...(user ? user : {}),
            userProfile: user ? userProfileFrame(user) : "",
            chapterCount: chapterCount,
            courseCompletedCount: completedChapterCount,
            completedChapterPer: `${
              chapterCount === 0 ? 0 : Math.round(finalCompletionPercentage)
            }%`,
            status:
              Math.round(finalCompletionPercentage) === 100
                ? "completed"
                : "progress",
          }
        })
      : []

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Courses"
            navigate="/courses-list"
            breadcrumbItem="Courses"
          />
          <Row>
            <Col lg="12">
              <div>
                {isLoading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner className="ms-2" color="dark" size="sm" />
                  </div>
                ) : (
                  <>
                    <TableContainer
                      loading={loading}
                      data={flattenedData}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isJobListGlobalFilter={false}
                      customPageSize={10}
                      className="project-list-table table-nowrap align-middle table-borderless"
                      addButton={{
                        onclick: () => navigate(-1),
                        title: "Back",
                        back: true,
                      }}
                      onChangePagination={onChangePagination}
                      showPagination={true}
                      columns={UserColumns}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PurchasedCourseUsers)
