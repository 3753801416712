import {
  DYNAMIC_API_FAIL,
  DYNAMIC_API_SUCCESS,
  GET_DYNAMIC_LIST,
  GET_DYNAMIC_PROFILE_TAB_DATA,
  GET_DYNAMIC_SIDEBAR,
  UPDATE_DYNAMIC_LOADING_STATE,
} from "./actionTypes"

const INIT_STATE = {
  sideBarLayout: [],
  list: {
    TEST_CATEGORY: [],
    TESTS: [],
    COURSES: [],
    PLANS: [],
    STUDENTS: [],
    COLLEGES: [],
    COACHINGS: [],
    COMPANIES: [],
    MOCK_TESTS: [],
    JOBS: [],
    SKILLS: [],
    ANNOUNCEMENTS: [],
    TRANSACTIONS: [],
    NOTIFICATIONS: [],
    INQUIRIES: [],
    REVIEWS: [],
    MEDIA: [],
  },
  profileTab: {},
  loading: false,
  error: {},
}

const DynamicReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DYNAMIC_API_SUCCESS:
      const { actionType, slug, data } = action.payload
      switch (actionType) {
        case GET_DYNAMIC_LIST:
          return {
            ...state,
            list: {
              ...state.list,
              [slug]: data,
            },
          }
        case GET_DYNAMIC_PROFILE_TAB_DATA:
          return {
            ...state,
            profileTab: data,
          }
        case GET_DYNAMIC_SIDEBAR:
          return {
            ...state,
            sideBarLayout: data,
          }
        default:
          return state
      }
    case DYNAMIC_API_FAIL:
      return {
        ...state,
        error: action.payload.error,
      }
    case UPDATE_DYNAMIC_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default DynamicReducer
