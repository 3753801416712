import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import testsCategorySaga from "./test-categories/saga"
import testsSaga from "./tests/saga"
import mockTestsSaga from "./mockTests/saga"
import jobsSaga from "./jobs/saga"
import applicantsSaga from "./applicants/saga"
import coursesSaga from "./courses/saga"
import studentsSaga from "./students/saga"
import companiesSaga from "./companies/saga"
import coachingsSaga from "./coachings/saga"
import collegesSaga from "./colleges/saga"
import rolesSaga from "./roles/saga"
import skillsSaga from "./skills/saga"
import dashboardSaga from "./dashboard/saga"
import dynamicSaga from "./dynamic/saga"
import questionSaga from "./questions/saga"
import chapterSaga from "./chapters/saga"
import flashCardSaga from "./flashCards/saga"
import chapterContentSaga from "./chapterContent/saga"
import multiMediaSaga from "./multiMedia/saga"
import transactionsSaga from "./transactions/saga"
import subscriptionSaga from "./subscription/saga"
import hrUserSaga from "./HrUsers/saga"
import ourReviewSaga from "./ourReview/saga"
import helpcenterSaga from "./helpcenter/saga"
import announcementSaga from "./announcement/saga"
import notificationSaga from "./notification/saga"
import planSaga from "./plan/saga";
import collegeRequestsSaga from "./collegeRequests/saga";
import jobReferralSaga from "./jobReferral/saga";
import packagesSaga from "./package/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(testsCategorySaga),
    fork(testsSaga),
    fork(mockTestsSaga),
    fork(jobsSaga),
    fork(applicantsSaga),
    fork(coursesSaga),
    fork(studentsSaga),
    fork(companiesSaga),
    fork(coachingsSaga),
    fork(collegesSaga),
    fork(chapterSaga),
    fork(flashCardSaga),
    fork(chapterContentSaga),
    fork(rolesSaga),
    fork(skillsSaga),
    fork(dashboardSaga),
    fork(dynamicSaga),
    fork(questionSaga),
    fork(multiMediaSaga),
    fork(transactionsSaga),
    fork(subscriptionSaga),
    fork(hrUserSaga),
    fork(ourReviewSaga),
    fork(helpcenterSaga),
    fork(announcementSaga),
    fork(notificationSaga),
    fork(planSaga),
    fork(collegeRequestsSaga),
    fork(jobReferralSaga),
    fork(packagesSaga)
  ])
}
