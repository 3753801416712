import React, { useState, useEffect } from "react"
import { FormGroup, Row, Col } from "reactstrap"
import { State, City } from "country-state-city"
import AppSelect from "./index"

const AppCityStateSelect = ({ formik, field, validationSchema }) => {
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([{value: '', label: 'Select City'}])

  useEffect(() => {
    const states = State.getStatesOfCountry("IN").map(s => ({
      value: s.name,
      label: s.name,
      isoCode: s.isoCode,
    }))
    setStateOptions(states)

    // If a state value is present in Formik, set the default city
    const selectedState = validationSchema.values[field.state.name]
    if (selectedState) {
      const stateFound = states.find(
        stateOption => stateOption.value === selectedState
      )
      if (stateFound) {
        const isoCode = states.find(
          stateOption => stateOption.value === selectedState
        ).isoCode
        const cities = City.getCitiesOfState("IN", isoCode).map(s => ({
          value: s.name,
          label: s.name,
        }))
        setCityOptions([{ value: '', label: 'Select City' }, ...cities])
      }
    }
  }, [validationSchema.values[field.state.name]])

  const handleStateChange = selectedState => {
    const isoCode = stateOptions.find(
      stateOption => stateOption.value === selectedState
    ).isoCode

    const cities = City.getCitiesOfState("IN", isoCode).map(s => ({
      value: s.name,
      label: s.name,
    }))
    setCityOptions([{ value: '', label: 'Select City' }, ...cities])

    // Set the selected state in validationSchema
    validationSchema.setFieldValue(field.state.name, selectedState)

    // Clear the city value when the state changes
    validationSchema.setFieldValue(field.city.name, "")
  }

  const handleCityChange = selectedCity => {
    // Set the selected city in validationSchema
    validationSchema.setFieldValue(field.city.name, selectedCity)
  }

  return (
    <FormGroup>
      <Row>
        <Col>
          <AppSelect
            label={field.state.label}
            name={field.state.name}
            id={`formrow-${field.state.name}-Input`}
            value={validationSchema.values[field.state.name] || ""}
            onChange={e => handleStateChange(e.target.value)}
            options={stateOptions}
            isExternal={false}
            slug="state"
            className={`form-select custom_form_control ${
              validationSchema.touched[field.state.name] &&
              validationSchema.errors[field.state.name]
                ? "is-invalid"
                : ""
            }`}
            error={validationSchema.errors[field.state.name]}
            invalid={
              validationSchema.touched[field.state.name] &&
              validationSchema.errors[field.state.name]
            }
          />
        </Col>
        <Col>
          <AppSelect
            label={field.city.label}
            name={field.city.name}
            id={`formrow-${field.city.name}-Input`}
            value={validationSchema.values[field.city.name] || ""}
            onChange={e => handleCityChange(e.target.value)}
            options={cityOptions}
            isExternal={false}
            slug="city"
            className={`form-select custom_form_control ${
              validationSchema.touched[field.city.name] &&
              validationSchema.errors[field.city.name]
                ? "is-invalid"
                : ""
            }`}
            error={validationSchema.errors[field.city.name]}
            invalid={
              validationSchema.touched[field.city.name] &&
              validationSchema.errors[field.city.name]
            }
          />
        </Col>
      </Row>
    </FormGroup>
  )
}

export default AppCityStateSelect
