/* TRANSACTIONS */
export const GET_TRANSACTIONS = "GET_TRANSACTIONS"
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS"
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL"
export const RESET_TRANSACTIONS = "RESET_TRANSACTIONS"

/**
 * Add transaction
 */
export const ADD_NEW_TRANSACTION = "ADD_NEW_TRANSACTION"
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS"
export const ADD_TRANSACTION_FAIL = "ADD_TRANSACTION_FAIL"

export const UPDATE_TRANSACTION_LOADING_STATE = "UPDATE_TRANSACTION_LOADING_STATE"

