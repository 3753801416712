import { del, get, post, patch } from "../api_helper"
import { DASHBOARD_ROUTES } from "../url_helper"

export const getAdminDashboard = () => get(`${DASHBOARD_ROUTES}/admin`)
export const getAdminDashboardJobBarChartData = (queryParams) => get(`${DASHBOARD_ROUTES}/admin/job/barChart${queryParams}`)
export const getAdminDashboardTransactionBarChartData = (queryParams) => get(`${DASHBOARD_ROUTES}/admin/transaction/barChart${queryParams}`)
export const getProfileStatusChartdtDataApi = () => get(`${DASHBOARD_ROUTES}/admin/user-profile-status/barChart`)

export const getCompanyDashboard = () => get(`${DASHBOARD_ROUTES}/company`)


export const getSideBarLayout = () => get(`/dynamic/layout`)