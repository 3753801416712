/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS"
export const API_FAIL = "API_FAIL"
export const GET_CHARTS_DATA = "GET_CHARTS_DATA"
export const GET_ADMIN_DASHBOARD_DATA = "GET_ADMIN_DASHBOARD_DATA"
export const GET_COMPANY_DASHBOARD_DATA = "GET_COMPANY_DASHBOARD_DATA"
export const GET_JOB_CHART_DATA = "GET_JOB_CHART_DATA"
export const GET_JOB_CHART_DATA_SUCCESS = "GET_JOB_CHART_DATA_SUCCESS"
export const GET_JOB_CHART_DATA_FAIL = "GET_JOB_CHART_DATA_FAIL"

export const GET_TRANSACTION_CHART_DATA = "GET_TRANSACTION_CHART_DATA"
export const GET_TRANSACTION_CHART_DATA_SUCCESS =
  "GET_TRANSACTION_CHART_DATA_SUCCESS"
export const GET_TRANSACTION_CHART_DATA_FAIL = "GET_TRANSACTION_CHART_DATA_FAIL"

export const GET_PROFILE_STATUS_DATA = "GET_PROFILE_STATUS_DATA"
export const GET_PROFILE_STATUS_DATA_SUCCESS = "GET_PROFILE_STATUS_DATA_SUCCESS"
export const GET_PROFILE_STATUS_DATA_FAIL = "GET_PROFILE_STATUS_DATA_FAIL"

export const GET_SIDEBAR = "GET_SIDEBAR"

export const UPDATE_DASHBOARD_LOADING_STATE = "UPDATE_DASHBOARD_LOADING_STATE"
