import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap"
import moment from "moment"
//redux
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import ProfileHeader from "../../components/Common/Card/ProfileHeader"
import AppForm from "../../components/Common/Form/index"
import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import {
  getCompanyDetail as onGetCompanyDetail,
} from "../../store/companies/actions"

import { formFields } from "./constants"
import { handleFormSubmit } from "./companyUtills"

const staticURL = process.env.REACT_APP_STATIC_URL

const EditCompanyProfile = props => {
  //meta title
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [user, setUser] = useState({})
  const [image, setImage] = useState("")

  const { companyDetail, error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
    companyDetail: state.companies?.companyDetail,
  }))

  useEffect(() => {
    if (params.companyId) {
      dispatch(onGetCompanyDetail(params.companyId, "users/me"))
    }
  }, [dispatch, onGetCompanyDetail])

  useEffect(() => {
    if (companyDetail) {
      const establishmentDate = companyDetail?.extra?.establishment
      const formattedDate = establishmentDate
        ? moment(establishmentDate).format("YYYY-MM-DD")
        : ""
      setUser({
        ...companyDetail,
        ...companyDetail?.extra,
        establishment: formattedDate,
      })
    }
  }, [companyDetail])

  const handleSubmit = formikValues => {
    handleFormSubmit(
      formikValues,
      companyDetail,
      user,
      image,
      dispatch,
      navigate
    )
  }

  const handleImage = file => {
    setImage(file)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Back"
            breadcrumbItem="Company Profile"
            navigate={-1}
          />
          {error && error ? <Alert color="danger">{error}</Alert> : null}
          {success ? <Alert color="success">{success}</Alert> : null}
          <Row>
            <Col lg="12">
              <ProfileHeader
                imageUrl={user && user.image ? staticURL + user.image : avatar}
                profileName={user ? user.name : ""}
                profileEmail={user ? user.email : ""}
                LayoutType={"HORIZONTAL"}
                style={{}}
              />
            </Col>
          </Row>

          <Card>
            <CardBody>
              <AppForm
                name="Company Information"
                layoutType="UPDATE"
                fields={formFields}
                initialValues={user}
                handleFormSubmit={handleSubmit}
                handleImage={handleImage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditCompanyProfile
