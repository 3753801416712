import React from "react"
import { Card, CardBody, Col } from "reactstrap"

const ProgressCard = ({ title, remaining, limit }) => {
  const percentage = (remaining / limit) * 100

  return (
    <Col lg="6">
      <Card className="mini-stats-wid">
        <CardBody style={{ padding: 10 }}>
          <h5>{`${title} (${limit})`}</h5>

          <div className="d-flex align-items-center mb-2">
            <b>Unused : {remaining}</b>
            <div className="progress ms-2" style={{ height: "10px", flex: "1",backgroundColor:'#ccd0dc' }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${percentage}%`,
                  backgroundColor:"#1AB69D"
                }}
                aria-valuenow={remaining}
                aria-valuemin="0"
                aria-valuemax={limit}
              ></div>
            </div>
          </div>

          <div className="d-flex align-items-center mb-2">
            <b>Consume : {limit - remaining}</b>
            <div className="progress ms-2" style={{ height: "10px", flex: "1",backgroundColor:'#ccd0dc' }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${100 - percentage}%`,
                  backgroundColor:"#F89752",
                }}
                aria-valuenow={limit - remaining}
                aria-valuemin="0"
                aria-valuemax={limit}
              ></div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default ProgressCard
