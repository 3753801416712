import {
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_FAIL,
  UPDATE_PACKAGE_LOADING_STATE,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAIL,
  GET_PACKAGE_DETAIL_SUCCESS,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAIL,
  GET_PACKAGE_DETAIL_FAIL,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  RESET_PACKAGE,
} from "./actionTypes"

const INIT_STATE = {
  packages: [],
  packageDetail: {},
  error: {},
  loading: false,
  userPackageDetail: {},
}

const packages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: action.payload,
      }

    case UPDATE_PACKAGE_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case GET_PACKAGE_FAIL:
    case ADD_PACKAGE_FAIL:
    case UPDATE_PACKAGE_FAIL:
    case GET_PACKAGE_DETAIL_FAIL:
    case DELETE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: [...state.packages, action.payload],
      }

    case GET_PACKAGE_DETAIL_SUCCESS:
      return {
        ...state,
        packageDetail: action.payload,
      }

    case UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: state.packages.map(packageItem =>
          packageItem._id.toString() === action.payload._id.toString()
            ? { ...packageItem, ...action.payload }
            : packageItem
        ),
      }

    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: state.packages.filter(
          pack => pack._id.toString() !== action.payload._id.toString()
        ),
      }

    case RESET_PACKAGE:
      return {
        ...state,
        packageDetail: {},
      }

    default:
      return state
  }
}

export default packages
