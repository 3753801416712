import {
  GET_STUDENTS_FAIL,
  GET_STUDENTS_SUCCESS,
  GET_STUDENT_DETAIL_FAIL,
  GET_STUDENT_DETAIL_SUCCESS,
  ADD_STUDENT_SUCCESS,
  ADD_STUDENT_FAIL,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAIL,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAIL,
  UPDATE_STUDENT_LOADING_STATE,
  RESET_STUDENT,
  GET_TEST_RESULT_SUCCESS,
  GET_TEST_RESULT_FAIL,
  RESET_TEST_RESULT,
  GET_STUDENT_COURSES_SUCCESS,
  GET_STUDENT_COURSES_FAIL,
  RESET_STUDENT_COURSES,
  GENERATE_CERTIFICATE_SUCCESS,
  GENERATE_CERTIFICATE_FAIL,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAIL,
  GET_USER_TAB_DATA_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  students: [],
  studentDetail: {},
  personalDetail: {},
  companyDetail: {},
  billingInfo: {},
  resume: "/default/resume/resume.pdf",
  careerObjective: "",
  skills: [],
  educational: [],
  employment: [],
  certificate: [],
  error: {},
  loading: false,
  testResults: [],
  userCourses: [],
  courses: [],
  certificateBuffer: null,
  userMockTestRequests: [],
  transactions: [],
  companyViews: [],
  jobAppliedList: [],
  jobReferrals: [],
  jobList: [],
  myCandidates: [],
  unlockCandidates: [],
}

const students = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload,
      }

    case RESET_STUDENT:
      return {
        ...INIT_STATE,
      }

    case UPDATE_STUDENT_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case GET_STUDENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_STUDENT_SUCCESS:
      return {
        ...state,
        students: [...state.students, action.payload],
      }

    case ADD_STUDENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_STUDENT_DETAIL_SUCCESS:
      return {
        ...state,
        studentDetail: action.payload,
      }

    case GET_USER_TAB_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        students: state.students.map(student =>
          student._id.toString() === action.payload._id.toString()
            ? { student, ...action.payload }
            : student
        ),
      }

    case UPDATE_STUDENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        students: state.students.filter(
          student => student._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_STUDENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_STUDENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TEST_RESULT_SUCCESS:
      return {
        ...state,
        testResult: action.payload,
      }

    case GET_TEST_RESULT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_TEST_RESULT:
      return {
        ...state,
        testResult: [],
      }

    case GET_STUDENT_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
      }

    case GET_STUDENT_COURSES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_STUDENT_COURSES:
      return {
        ...state,
        courses: [],
      }
    case GENERATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        certificateBuffer: action.payload,
      }
    case GENERATE_CERTIFICATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        students: action.payload,
      }

    case SEARCH_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default students
