import { del, get, post, patch } from "../api_helper"
import { TEST_CATEGORIES_ROUTES } from "../url_helper"

/** TestsCategory */
// add Test
export const addNewTestCategory = testCategory =>
  post(TEST_CATEGORIES_ROUTES, testCategory)

// update TestCategory
export const updateTestCategory = (testCategory, testCategoryId) =>
  patch(`${TEST_CATEGORIES_ROUTES}/${testCategoryId}`, testCategory)

// delete TestCategory
export const deleteTestCategory = testCategory =>
  del(`${TEST_CATEGORIES_ROUTES}/${testCategory}`, {
    headers: { testCategory },
  })

// get testCategory
export const getTestCategories = () => get(TEST_CATEGORIES_ROUTES)

export const getCategories = queryParams => {
  const url = queryParams
    ? `/tests/categories${queryParams}`
    : "/tests/categories"
  return get(url)
}

// get testCategory details
export const getTestCategoriesDetails = id =>
  get(`${TEST_CATEGORIES_ROUTES}/${id}`, { params: { id } })

export const getTestsByCategoryId = data =>
  get(
    `/tests/appAllTest?testCategoryId=${data.testCategoryId}&status=all`,
    data
  )