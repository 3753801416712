/* CHAPTERS */
export const GET_CHAPTERS = "GET_CHAPTERS"
export const GET_CHAPTERS_SUCCESS = "GET_CHAPTERS_SUCCESS"
export const GET_CHAPTERS_FAIL = "GET_CHAPTERS_FAIL"

/* CHAPTERS DETAIL*/
export const GET_CHAPTER_DETAIL = "GET_CHAPTER_DETAIL"
export const GET_CHAPTER_DETAIL_SUCCESS = "GET_CHAPTER_DETAIL_SUCCESS"
export const GET_CHAPTER_DETAIL_FAIL = "GET_CHAPTER_DETAIL_FAIL"
export const RESET_CHAPTER = "RESET_CHAPTER"

/**
 * add chapter
 */
export const ADD_NEW_CHAPTER = "ADD_NEW_CHAPTER"
export const ADD_CHAPTER_SUCCESS = "ADD_CHAPTER_SUCCESS"
export const ADD_CHAPTER_FAIL = "ADD_CHAPTER_FAIL"

/**
 * EDit chapter
 */
 export const UPDATE_CHAPTER = "UPDATE_CHAPTER"
 export const UPDATE_CHAPTER_SUCCESS = "UPDATE_CHAPTER_SUCCESS"
 export const UPDATE_CHAPTER_FAIL = "UPDATE_CHAPTER_FAIL"


/**
 * Delete course
 */
export const DELETE_CHAPTER = "DELETE_CHAPTER"
export const DELETE_CHAPTER_SUCCESS = "DELETE_CHAPTER_SUCCESS"
export const DELETE_CHAPTER_FAIL = "DELETE_CHAPTER_FAIL"

export const UPDATE_CHAPTER_LOADING_STATE = "UPDATE_CHAPTER_LOADING_STATE"

