import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_STUDENTS,
  GET_STUDENT_DETAIL,
  ADD_NEW_STUDENT,
  DELETE_STUDENT,
  UPDATE_STUDENT,
  GET_TEST_RESULT,
  GET_STUDENT_COURSES,
  GENERATE_CERTIFICATE,
  SEARCH_USER,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAIL,
  GET_USER_TAB_DATA,
} from "./actionTypes"
import {
  getStudentsSuccess,
  getStudentsFail,
  getStudentDetailSuccess,
  getStudentDetailFail,
  addStudentFail,
  addStudentSuccess,
  updateStudentSuccess,
  updateStudentFail,
  deleteStudentSuccess,
  deleteStudentFail,
  updateStudentLoadingState,
  getTestResultSuccess,
  getTestResultFail,
  getStudentCoursesSuccess,
  getStudentCoursesFail,
  generateCertificateSuccess,
  generateCertificateFail,
  getUserTabDataSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getStudents,
  getStudentsDetails,
  addNewStudent,
  updateStudent,
  deleteStudent,
  getStudentProfileDetails,
  getStudentTestResult,
  getCoursesResult,
  getStudentCertificateInfo,
} from "helpers/backend_helper"
import { dynamicSearch, userProfileTab } from "helpers/api/dynamicApi"

function* fetchStudents({ params }) {
  try {
    const { page, limit } = params
    let queryParams = `&page=${page}&limit=${limit}`
    if (params?.search && params?.search?.length) {
      queryParams = `&page=${1}&search=${params?.search}`
    }
    yield put(updateStudentLoadingState(true))
    const response = yield call(getStudents, queryParams)
    yield put(getStudentsSuccess(response.data))
    yield put(updateStudentLoadingState(false))
  } catch (error) {
    yield put(getStudentsFail(error))
    yield put(updateStudentLoadingState(false))
  }
}

function* fetchStudentDetail({ studentId }) {
  try {
    yield put(updateStudentLoadingState(true))
    const response = yield call(getStudentsDetails, studentId)
    yield put(getStudentDetailSuccess(response.data.data))
    yield put(updateStudentLoadingState(false))
  } catch (error) {
    yield put(getStudentDetailFail(error))
    yield put(updateStudentLoadingState(false))
  }
}

function* onUpdateStudent({ payload: { student, studentId, history } }) {
  try {
    const response = yield call(updateStudent, student, studentId)
    yield put(updateStudentSuccess(response.data.data))
    toastr.success("Student updated successfully..!!")
    setTimeout(() => {
      history("/students-list")
    }, 2000)
  } catch (error) {
    yield put(updateStudentFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteStudent({ payload }) {
  const { studentId, params } = payload
  try {
    const { page, limit } = params
    const queryParams = `&page=${page}&limit=${limit}`
    const response = yield call(deleteStudent, studentId)
    toastr.success("Student deleted successfully..!!")
    let res
    if (params) {
      res = yield call(getStudents, queryParams)
    } else {
      res = yield call(getStudents)
    }
    yield put(deleteStudentSuccess(response.data))
    yield put(getStudentsSuccess(res.data))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteStudentFail(error))
  }
}

function* onAddNewStudent({ payload: { student, history } }) {
  try {
    yield put(updateStudentLoadingState(true))
    const response = yield call(addNewStudent, student)
    toastr.success("New Student Added Successfully..!!")
    yield put(addStudentSuccess(response.data.data))
    yield put(updateStudentLoadingState(false))
    setTimeout(() => {
      history("/students-list")
    }, 1500)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateStudentLoadingState(false))
    yield put(addStudentFail(error))
  }
}

function* fetchTestResult({ studentId }) {
  try {
    const res = yield call(getStudentTestResult, studentId)
    yield put(getTestResultSuccess(response.data))
  } catch (error) {
    yield put(getTestResultFail(error))
  }
}

function* fetchCoursesResult({ studentId }) {
  try {
    const res = yield call(getCoursesResult, studentId)
    yield put(getStudentCoursesSuccess(res.data?.userCourses))
  } catch (error) {
    yield put(getStudentCoursesFail(error))
  }
}

function* getCertificateInfo({ studentId, courseId }) {
  try {
    const response = yield call(getStudentCertificateInfo, studentId, courseId)
    yield put(generateCertificateSuccess(response))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(generateCertificateFail(error))
  }
}

function* searchUsers({ params }) {
  try {
    const { type, slug } = params
    let queryParams = `?type=${type}&slug=${slug}`
    const response = yield call(dynamicSearch, queryParams)
    yield put(getStudentsSuccess(response.data))
  } catch (error) {
    yield put(getStudentsFail(error))
  }
}

function* getTabData({ slug, userId, queryParams }) {
  try {
    yield put(updateStudentLoadingState(true))
    let res = yield call(userProfileTab, slug, userId, queryParams)
    yield put(getUserTabDataSuccess(res.data))
    yield put(updateStudentLoadingState(false))
  } catch (error) {
    yield put(getStudentsFail(error))
    yield put(updateStudentLoadingState(false))
  }
}

function* studentsSaga() {
  yield takeEvery(GET_STUDENTS, fetchStudents)
  yield takeEvery(GET_STUDENT_DETAIL, fetchStudentDetail)
  yield takeEvery(ADD_NEW_STUDENT, onAddNewStudent)
  yield takeEvery(UPDATE_STUDENT, onUpdateStudent)
  yield takeEvery(DELETE_STUDENT, onDeleteStudent)
  yield takeEvery(GET_TEST_RESULT, fetchTestResult)
  yield takeEvery(GET_STUDENT_COURSES, fetchCoursesResult)
  yield takeEvery(GENERATE_CERTIFICATE, getCertificateInfo)
  yield takeEvery(SEARCH_USER, searchUsers)
  yield takeEvery(GET_USER_TAB_DATA, getTabData)
}

export default studentsSaga
