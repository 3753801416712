import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"
import {
  getTests as onGetTests,
  updateTest as onUpdateTest,
  deleteTest as onDeleteTest,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import TableContainer from "../../components/Common/Table/index"
import { columns } from "./constants"

const TestsList = () => {
  document.title = "Test List | QAPRENEUR"
  const role = localStorage.getItem("authRole")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [test, setTest] = useState()
  const [testList, setTestList] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { tests, loading } = useSelector(state => ({
    tests: state?.tests?.tests,
    loading: state?.tests?.loading,
  }))

  useEffect(() => {
    const queryParams = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      queryParams.search = search
    }
    if (role === "admin") {
      dispatch(onGetTests(queryParams))
    }
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(tests)) {
      setTestList(
        tests
          .map(item => ({
            ...item,
            category: item?.testCategory?.type,
            discount: item?.discount ? `${item?.discount}%` : 0,
            discountPrice: item?.discountPrice ? `${item?.discountPrice}` : 0,
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setTestList([])
    }
  }, [tests, pageNo])

  const handleModalAction = () => {
    if (test && test._id) {
      const params = {
        page: pageNo,
        limit: rowPerPage,
      }
      dispatch(onDeleteTest(test._id, params))
    }
    setDeleteModal(false)
  }

  const handleChangeStatus = test => {
    const status = test.status === "active" ? "deactive" : "active"
    const testCategory = test?.testCategory
    const questionCount = test?.questionCount
    dispatch(
      onUpdateTest(
        { status, testCategory, questionCount },
        test._id,
        navigate,
        "no-route"
      )
    )
    dispatch(onGetTests())
  }

  const handleActions = (test, type) => {
    if (type === "VIEW") {
      navigate(`/test-details/${test._id}`)
    } else if (type === "EDIT") {
      navigate(`/tests-edit/${test._id}`)
    } else if (type === "DELETE") {
      setTest(test)
      setDeleteModal(true)
    } else if (type === "VIEW_STUDENT_LIST") {
      navigate(`/purchased-test-users/${test._id}`)
    }
  }

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the test."}
          modalAction={handleModalAction}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tests" breadcrumbItem="Tests" />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={testList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  handleStatus={handleChangeStatus}
                  onChangePagination={onChangePagination}
                  showPagination={true}
                  handleActions={handleActions}
                  handleSearch={handleSearch}
                  addButton={
                    role == "admin"
                      ? { link: "/tests-create", title: "Add New Test" }
                      : null
                  }
                  rowLink="/test-details"
                  columns={columns}
                  notDataFound={role == "student"}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TestsList
