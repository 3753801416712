import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_SUCCESS,
  RESET_SUBSCRIPTIONS,
} from "./actionTypes"

export const getSubscriptions = params => ({
  type: GET_SUBSCRIPTIONS,
  params,
})

export const getSubscriptionsSuccess = subscriptions => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  payload: subscriptions,
})

export const getSubscriptionsFail = error => ({
  type: GET_SUBSCRIPTIONS_FAIL,
  payload: error,
})

export const resetSubscriptions = () => ({
  type: RESET_SUBSCRIPTIONS,
})
