import {
  updateCompany as onUpdateCompany,
  getCompanyDetail as onGetCompanyDetail,
} from "../../store/companies/actions"
import moment from "moment"
import { CheckUserSubscriptionsPlan } from "../../helpers/api/subscriptionsApi"
import toastr from "toastr"

const role = localStorage.getItem("authRole")

export const handleFormSubmit = (
  formikValues,
  formik,
  companyDetail,
  user,
  image,
  dispatch,
  navigate = "",
  setShowEdit
) => {
  formik.validateForm().then(errors => {
    if (Object.keys(errors).length === 0) {
      const newObj = {
        ...companyDetail,
        ...companyDetail?.extra,
        ...formikValues,
      }
      const data = {
        name: newObj?.name,
        email: newObj?.email,
        phone: newObj?.phone,
        address: newObj?.address,
        city: newObj?.city,
        state: newObj?.state,
        image: image ? image : newObj?.image,
        extra: {
          contactPersonName: newObj.contactPersonName,
          contactNumber: newObj.contactNumber,
          about: newObj.about,
          website: newObj.website,
          establishment: newObj.establishment,
          headQuarter: newObj?.headQuarter,
          companySize: newObj?.companySize,
        },
      }
      if (role === "admin") {
        dispatch(onUpdateCompany("company", data, companyDetail._id, navigate))
        setShowEdit && setShowEdit(true)
      } else {
        dispatch(onUpdateCompany("users/me", data, companyDetail._id, navigate))
        setShowEdit && setShowEdit(true)
      }
    } else {
      const touchedFields = {}
      Object.keys(errors).forEach(field => {
        touchedFields[field] = true
      })
      formik.setTouched(touchedFields)
      console.log("Validation errors:", errors)
    }
  })
}

export const getRenderFields = (user, key) => {
  if (user) {
    switch (key) {
      case "personal":
        return [
          { label: "Company Name*", value: user?.name },
          { label: "Email*", value: user?.email },
          { label: "Phone Number*", value: user.phone },
          { label: "Contact Person Number*", value: user?.contactNumber },
          { label: "Contact Person Name*", value: user?.contactPersonName },
          { label: "City*", value: user?.city },
          { label: "State*", value: user?.state },
          { label: "Address", value: user?.address },
          { label: "Head Quarter", value: user?.headQuarter },
          {
            label: "Establishment Date*",
            value: user?.establishment
              ? moment(user?.establishment).format("YYYY-MM-DD")
              : "",
          },
          { label: "Company Size", value: user?.companySize },
          { label: "Website", value: user?.website },
        ]
      case "hr":
        return [
          { label: "Name", value: user?.name },
          { label: "Email", value: user?.email },
          { label: "Phone", value: user.phone },
          { label: "City*", value: user?.city },
          { label: "State*", value: user?.state },
          { label: "Address", value: user?.address },
        ]

      default:
        return []
        break
    }
  }
}

export const HrUserfields = isEdit => {
  if (isEdit) {
    return [
      {
        label: "First Name",
        name: "firstName",
        className: "input_field",
        type: "text",
        placeholder: "Enter First Name",
      },
      {
        label: "Last Name",
        name: "lastName",
        className: "input_field",
        type: "text",
        placeholder: "Enter Last Name",
      },
      {
        label: "Email",
        name: "email",
        className: "input_field",
        type: "text",
        placeholder: "Enter Email",
        disabled: true,
      },
      {
        label: "Contact Number",
        name: "phone",
        className: "input_field",
        type: "text",
        placeholder: "Contact Number",
      },
    ]
  } else {
    return [
      {
        label: "First Name",
        name: "firstName",
        className: "input_field",
        type: "text",
        placeholder: "Enter First Name",
      },
      {
        label: "Last Name",
        name: "lastName",
        className: "input_field",
        type: "text",
        placeholder: "Enter Last Name",
      },
      {
        label: "Email",
        name: "email",
        className: "input_field",
        type: "text",
        placeholder: "Enter Email",
      },
      {
        label: "Contact Number",
        name: "phone",
        className: "input_field",
        type: "text",
        placeholder: "Contact Number",
      },
      {
        label: "Password",
        name: "password",
        className: "input_field",
        type: "text",
        placeholder: "Password",
      },
      {
        label: "Confirm Password",
        name: "passwordConfirm",
        className: "input_field",
        type: "text",
        placeholder: "Confirm Password",
      },
    ]
  }
}

export const checkPlan = async toggleForm => {
  const response = await CheckUserSubscriptionsPlan("USER_ADDED")
  const plan = response?.data?.plan
  if (typeof plan === "object" && plan?.addUserCredit) {
    plan?.addUserCredit > 0 && toggleForm()
  } else if (typeof plan === "string") {
    toastr.error(plan)
  } else {
    console.log("The plan is neither an object nor a string.")
  }
}
