import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import arrowRight from "../../assets/images/icons/arrowRightColored.svg"
import AppLoader from "../../components/Common/Loader"
import Plan from "../../components/Common/Plan"
import { createTransactions } from "../../helpers/api/transactionsApi"
import toastr from "toastr"
import { useNavigate } from "react-router-dom"
import {
  generateKey as generatePaymentKey,
  generateRandomId,
} from "../../helpers/api/transactionsApi"
import { getCompanyDetail as onGetCompanyDetail } from "../../store/companies/actions"
//import action
import {
  getCompanyDashboardData as onGetCompanyDashboardData,
  getChartsData as onGetChartsData,
  getCandidates as onGetCandidates,
  getCompanySubscriptions as onGetCompanySubscriptions,
  unlockApplicantsStatus as onUnlockApplicantsStatus,
  getCompanyCredit as onGetCompanyCredit,
  getCompanyPlanDetail as onGetCompanyPlansDetail
} from "../../store/actions"
// Pages Components
import WelcomeComp from "./WelcomeComp"
//Import Breadcrumb
import TableContainer from "../../components/Common/Table/index"

//i18n
import { withTranslation } from "react-i18next"
//redux
import { useSelector, useDispatch } from "react-redux"
import Reports from "./Reports"
import Widgets from "./Widgets"
import { candidatesColumns } from "pages/ApplicantsManagement/constants"

const CompanyDashboard = props => {
  document.title = "Dashboard | QAPRENEUR"
  const dispatch = useDispatch()
  const [widgetData, setWidegtdata] = useState([])
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [candidateList, setCandidateList] = useState([])
  const navigate = useNavigate()
  const authRole = localStorage.getItem("authRole")

  const {
    companyDashboard,
    companyPlanDetail,
    otherCandidates,
    credit,
    Subscriptions,
  } = useSelector(state => ({
    companyDashboard: state.Dashboard.companyDashboard,
    otherCandidates: state.applicants.Candidates,
    Subscriptions: state.companies?.Subscriptions,
    credit: state.companies.credit,
    companyPlanDetail: state.plans?.companyPlanDetail,
  }))

  useEffect(() => {
    dispatch(onGetCompanyDashboardData())
    dispatch(onGetChartsData("yearly"))
    dispatch(onGetCompanyPlansDetail())
    const isUser = localStorage.getItem("authUser")
    const isHrUser = authRole === "hr_user"
    if (isUser) {
      const LogedInUser = JSON.parse(isUser)
      setUser(LogedInUser)
      const queryParams = {
        page: 1,
        limit: 10,
      }
      dispatch(
        onGetCandidates(
          isHrUser ? LogedInUser?.companyId : LogedInUser?._id,
          queryParams
        )
      )
      dispatch(onGetCompanySubscriptions(LogedInUser?._id))
      dispatch(
        onGetCompanyCredit(isHrUser ? LogedInUser?.companyId : LogedInUser?._id)
      )
    }
  }, [])

  useEffect(() => {
    if (Array.isArray(otherCandidates)) {
      setCandidateList(
        otherCandidates.map(candidate => ({
          ...candidate,
          studentId: candidate._id,
          jobCount: candidate?.appliedJobsByUser.length,
        }))
      )
    } else {
      setCandidateList([])
    }
  }, [otherCandidates])

  useEffect(() => {
    if (Object.keys(companyDashboard)?.length) {
      setWidegtdata(companyDashboard?.widgets)
      setIsLoading(false)
    }
  }, [companyDashboard])

  const handleActions = (data, type) => {
    if (type === "VIEW") {
      navigate(`/profile/view/candidate/${data?._id}`)
    }
  }

  const handleCandidateLock = data => {
    if (credit?.profileCredit > 0) {
      data.isUnlock = true
      dispatch(onUnlockApplicantsStatus(data._id, data))
    } else {
      toastr.error("your Profile Credit Limit Ended.")
    }
  }

  const handlePlan = item => {
    navigate("/upgrade-plan")
    // handlePayment(item)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading ? (
          <Container fluid>
            <Row>
              <Col className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4
                    className="mb-sm-0 font-size-18"
                    style={{ color: "#243466" }}
                  >
                    Welcome To Dashboard
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl="5">
                <WelcomeComp
                  user={companyDashboard?.user ? companyDashboard?.user : null}
                  type="company"
                  authRole={authRole}
                  data={{
                    projectTitle: "company",
                    remainingLeadTitle: "Remaining Unlock Candidate",
                    remainingLeadCount: companyPlanDetail?.companyCredit?.profileCredit,
                    remainingJobTitle: "Remaining Job Posts",
                    remainingJobCount: companyPlanDetail?.companyCredit?.jobCredit,
                  }}
                />
                <Col>
                  <Card className="mini-stats-wid">
                    <CardBody style={{ padding: 10 }}>
                      <Plan
                        SubscriptionPlan={Subscriptions}
                        showDesc={true}
                        isActive={true}
                        showUpgradeButton={
                          authRole === "company" ? true : false
                        }
                        handlePlan={handlePlan}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Col>
              <Col xl="7">
                <Row>
                  <Widgets widgetReports={widgetData} />
                </Row>
              </Col>
            </Row>
            <Row>
              <div>
                <div className="section_header">
                  <h3 className="section_main_heading">Candidates</h3>
                  <Link className="btn_bgwhite" to={`/candidate/${user?._id}`}>
                    View All Candidates &nbsp;
                    <img
                      src={arrowRight}
                      height="10"
                      width="10"
                      alt={"qapreneur"}
                    />
                  </Link>
                </div>
                <TableContainer
                  loading={false}
                  data={candidateList}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={candidatesColumns}
                  showPageDropdown={false}
                  handleUnlock={handleCandidateLock}
                  handleActions={handleActions}
                />
              </div>
            </Row>
          </Container>
        ) : (
          <AppLoader />
        )}
      </div>
    </React.Fragment>
  )
}

CompanyDashboard.propTypes = {
  t: PropTypes.any,
  onGetChartsData: PropTypes.func,
  chartsData: PropTypes.any,
}

export default withTranslation()(CompanyDashboard)
