import {
  GET_REVIEWS_FAIL,
  GET_REVIEWS_SUCCESS,
  GET_REVIEW_DETAIL_FAIL,
  GET_REVIEW_DETAIL_SUCCESS,
  ADD_REVIEW_SUCCESS,
  ADD_REVIEW_FAIL,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_FAIL,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  UPDATE_REVIEW_LOADING_STATE,
  RESET_REVIEWS
} from "./actionTypes"

const INIT_STATE = {
  reviews: [],
  reviewDetail: {},
  error: {},
  loading: false,
  usersPurchasedReviews: [],
}

const reviews = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
      }
      case GET_REVIEWS_FAIL:
        return {
          ...state,
          error: action.payload,
        }
    case RESET_REVIEWS:
      return {
        reviews: [],
        reviewDetail: {},
        error: {},
        loading: false,
        usersPurchasedReviews: [],
      }


    case UPDATE_REVIEW_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }



    case ADD_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: [...state.reviews, action.payload],
      }

    case ADD_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REVIEW_DETAIL_SUCCESS:
      return {
        ...state,
        reviewDetail: action.payload,
      }
      case GET_REVIEW_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
    case UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: state.reviews.map(review =>
          review._id.toString() === action.payload._id.toString()
            ? { review, ...action.payload }
            : review
        ),
      }

    case UPDATE_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: state.reviews.filter(
          review => review._id.toString() !== action.payload._id.toString()
        ),
      }



    case DELETE_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default reviews
