export const tabIds = [
  { id: "student", label: "Student Plan" },
  { id: "company", label: "Company Plan" },
]

const options = [
  { value: "", label: "Select Number Of Job Attempts" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 25, label: "25" },
  { value: 30, label: "30" },
  { value: 35, label: "35" },
  { value: 40, label: "40" },
  { value: 45, label: "45" },
  { value: 50, label: "50" },
  { value: 55, label: "55" },
  { value: 60, label: "60" },
  { value: 65, label: "65" },
  { value: 70, label: "70" },
  { value: 75, label: "75" },
  { value: 80, label: "80" },
  { value: 85, label: "85" },
  { value: 90, label: "90" },
  { value: 95, label: "95" },
  { value: 100, label: "100" },
]

export const commonFormFields = [
  {
    label: "Price",
    name: "actualPrice",
    type: "text",
    required: true,
  },
  {
    label: "Discounted Price",
    name: "price",
    type: "text",
    required: true,
  },
  {
    label: "Description*",
    name: "description",
    type: "textarea",
    required: true,
    lg: 12,
  },
  // {
  //   label: "Discount",
  //   name: "discount",
  //   type: "select",
  //   options: [
  //     { value: "no", label: "No" },
  //     { value: "yes", label: "Yes" },
  //   ],
  //   required: true,
  // },
  // {
  //   label: "Discounted Price",
  //   name: "discountedPrice",
  //   type: "text",
  //   required: true,
  //   conditional: {
  //     field: "discount",
  //     value: "yes",
  //   },
  // },
  // {
  //   label: "Discount Percentage",
  //   name: "discountPercentage",
  //   type: "text",
  //   required: true,
  //   conditional: {
  //     field: "discount",
  //     value: "yes",
  //   },
  // },
]

export const studentSettings = [
  {
    label: "  Job Attempts",
    name: "noOfJobAttempts",
    type: "select",
    options: options,
    required: true,
  },
]

export const companySettings = [
  {
    label: "Job Post Limit",
    name: "jobPostLimit",
    type: "select",
    options: options,
    required: true,
  },
  {
    label: "Unlock Candidate Limit",
    name: "unlockCandidate",
    type: "select",
    options: options,
    required: true,
  },
  {
    label: "HR User Limit",
    name: "hrUserLimit",
    type: "select",
    options: options,
    required: true,
  },
]

export const commonFields = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Plan Name",
    name: "planName",
    key: "DEFAULT",
  },
  {
    header: "Plan Type",
    name: "type",
    key: "DEFAULT",
  },
  {
    header: "Price",
    name: "actualPrice",
    key: "AMOUNT",
  },
  {
    header: "Discounted Price",
    name: "price",
    key: "AMOUNT",
  },
]

export const studentFields = [
  {
    header: "Job Attempts",
    name: "noOfJobAttempts",
    key: "DEFAULT",
  },
]

export const companyFields = [
  {
    header: "Job Post Limit",
    name: "jobPostLimit",
    key: "DEFAULT",
  },
  {
    header: "Unlock Candidate Limit",
    name: "unlockCandidate",
    key: "DEFAULT",
  },
  {
    header: "HR User Limit",
    name: "hrUserLimit",
    key: "DEFAULT",
  },
]

export const actions = [
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Edit", type: "EDIT" },
      // { label: "Delete", type: "DELETE" },
    ],
  },
]

export const mockTestPriceFields = [
  {
    label: "Price",
    name: "mockTestPrice",
    type: "text",
    required: true,
  },
]

export const packageColumn = role => {
  const commonFields = [
    {
      header: "#",
      key: "",
    },
    {
      header: "Package Name",
      name: "name",
      key: "DEFAULT",
    },
    {
      header: "Package Type",
      name: "leadsName",
      key: "DEFAULT",
    },
    {
      header: "Price",
      name: "price",
      key: "AMOUNT",
      customStyle: { color: "#1AB69D" },
    },
    {
      header: "Discounted Price",
      name: "discountPrice",
      key: "AMOUNT",
      customStyle: { color: "#f09555" },
    },
    {
      header: "Lead Limit",
      name: "leadLimit",
      key: "LEADS",
    },
  ];

  const companyColumn = [
    {
      header: "Get",
      name: "get",
      key: "BUY_BUTTON",
    },
  ];

  switch (role) {
    case "company":
      return [...commonFields, ...companyColumn];
    case "hr_user":
      return commonFields;
    default:
      return commonFields;
  }
};
