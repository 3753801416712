import React from "react"
import { Button } from "reactstrap"

function TablePagination({ data, pageSize, setPageNo, pageNo }) {
  return (
    <>
      <div className="d-flex justify-content-md-end justify-content-center align-items-center">
        <Button
          className="btn_pagination rounded-start"
          onClick={() => {
            setPageNo(pageNo - 1)
          }}
          disabled={pageNo < 1}
        >
          Previous
        </Button>

        <div className="d-flex justify-content-md-start justify-content-center align-items-center">
          <div className="pagination_curr_page border-top-0">{pageNo + 1}</div>
        </div>

        <Button
          className="btn_pagination rounded-end"
          onClick={() => {
            setPageNo(pageNo + 1)
          }}
          disabled={data.length < pageSize}
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default TablePagination
