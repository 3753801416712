import React from 'react'

function index() {
  return (
    <div className="page-content">
      work in progress...
    </div>
  )
}

export default index