import React from 'react'
import {
    Card,
    Col,
    Label,
    Row,
} from "reactstrap"
import Dropzone from "react-dropzone"

function AppImageUpload(
    {
        onChange,
        value,
        invalid,
        error,
        label,
    }
) {

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        onChange(files)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    const staticURL = process.env.REACT_APP_STATIC_URL
    const splitName = value && !Array.isArray(value) && typeof value === "string" && value.split('/')
    const nameofImage = splitName.length > 0 && splitName[splitName.length - 1]
    return (
        <div>
            <div className="mb-3">
                <Label className="form-label">{label}</Label>
                <Dropzone
                    maxFiles={1}
                    onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                            <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                            >
                                <input name="image" {...getInputProps()} />
                                <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                            </div>
                        </div>
                    )}
                </Dropzone>
                {invalid ? <p className='text-danger font-size-15 mt-1'>{error}</p> : null}
                <div
                    className="dropzone-previews mt-3"
                    id="file-previews"
                >
                    {Array.isArray(value) && value.length && value.map((f, i) => {
                        return (
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                            />
                                        </Col>
                                        <Col>
                                            <h6
                                                className="text-muted font-weight-bold"
                                            >
                                                {f.name}
                                            </h6>
                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        )
                    })}
                    {!Array.isArray(value) && value && value.length && <>
                        <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        // key={i + "-file"}
                        >
                            <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                        <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={"qapreneur"}
                                            src={staticURL + value}
                                        />
                                    </Col>
                                    <Col>
                                        <h6
                                            className="text-muted font-weight-bold"
                                        >
                                            {nameofImage}
                                        </h6>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </>}
                </div>
            </div>
        </div>
    )
}

export default AppImageUpload