import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import {
    Row,
    Spinner,
    Button
} from 'reactstrap';
import {
    getMultiMediaContent as onGetMultiMedia,
    deleteMultiMediaContent as onDeleteMultiMedia,
    updateMultiMediaContent as onUpdateMulti,
    addMultiMediaContent as onAddMultiMediaContent
} from "../../store/actions";
import AppForm from "../../components/Common/Form";
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import MediaLayoutGrid from "./MediaLayoutGrid";
import MediaLayoutList from './MediaLayoutList';
const staticURL = process.env.REACT_APP_STATIC_URL

function Media({ mediaModalToggle, showModal, setShowModal, showDelete = true, isGrid = true }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [imageFile, setImageFile] = useState("")
    const mediaFormik = useRef(null)
    const [pageNo, setPageNo] = useState(0)
    const [limit, setLimit] = useState(10)


    const { multiMediaContent, loading } = useSelector((state) => ({
        multiMediaContent: state.multiMedia.multiMediaContent,
        loading: state.multiMedia.loading
    }));

    useEffect(() => {
        getData()
    }, [pageNo])

    const getData = () => {
        const params = {
            page: pageNo === 0 ? 1 : pageNo,
            limit: limit
        }
        dispatch(onGetMultiMedia(params))
    }

    const handleImage = (file) => {
        setImageFile(file)
    }


    const handleMediaFormSave = () => {
        dispatch(onAddMultiMediaContent({ media: imageFile }, navigate))
        mediaModalToggle()
    }

    const getSubscribValueFromToFormik = (formik) => {
        if (Object.keys(formik.values).includes('media')) {
            mediaFormik.current = formik
        }
    }

    const handleDeleteImage = (id) => {
        dispatch(onDeleteMultiMedia(id, navigate))
    }

    const handleCopyImageUrl = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                console.log('URL copied to clipboard:', url);
                // Optionally show a message to the user confirming the copy
            })
            .catch((err) => {
                console.error('Failed to copy URL:', err);
            });
    }

    return (
        <React.Fragment>
            {loading && (
                <div className="d-flex justify-content-center">
                    <Spinner className="ms-2" color="dark" size="sm" />
                </div>
            )
            }
            {!loading &&
                <div className={isGrid ? 'gallery' : 'gallery-list'}>
                    {
                        multiMediaContent && multiMediaContent.map((media, key) => {
                            return isGrid ? <>
                                <MediaLayoutGrid
                                    media={media}
                                    key={key}
                                    handleDeleteImage={handleDeleteImage}
                                    handleCopyImageUrl={handleCopyImageUrl}
                                    showDelete={showDelete}
                                />
                            </> : <>
                                <MediaLayoutList
                                    media={media}
                                    key={key}
                                    handleDeleteImage={handleDeleteImage}
                                    handleCopyImageUrl={handleCopyImageUrl}
                                    showDelete={showDelete}
                                />
                            </>
                        })
                    }
                </div>
            }
            <Row>
                <div>
                    {showModal && (
                        <InfomationModal
                            modalBackdrop={showModal}
                            setmodalBackdrop={setShowModal}
                            title={"Add Media"}
                            size="lg"
                            height={400}
                            isFooter={true}
                            handleAction={handleMediaFormSave}
                        >
                            <Row>
                                <AppForm
                                    name=""
                                    layoutType="CREATE"
                                    fields={[
                                        {
                                            label: "Image*",
                                            name: "media",
                                            type: "file",
                                            placeholder: "Enter Location",
                                            required: true,
                                            lg: 12
                                        }
                                    ]}
                                    initialValues={{ media: "" }}
                                    handleImage={handleImage}
                                    getValues={getSubscribValueFromToFormik}
                                    showCancelButton={false}
                                />
                            </Row>
                        </InfomationModal>
                    )}
                </div>
            </Row>
            <div className="d-flex justify-content-md-end justify-content-center align-items-center">
                <Button
                    className="btn_pagination rounded-start"
                    onClick={() => {
                        setPageNo(pageNo - 1)
                    }}
                    disabled={pageNo < 1}
                >
                    Previous
                </Button>

                <div className="d-flex justify-content-md-start justify-content-center align-items-center">
                    <div className="pagination_curr_page border-top-0">{pageNo + 1}</div>
                </div>

                <Button
                    className="btn_pagination rounded-end"
                    onClick={() => {
                        if (pageNo === 0) {
                            setPageNo(pageNo + 2)
                        } else {
                            setPageNo(pageNo + 1)
                        }
                    }}
                    disabled={multiMediaContent.length < limit}
                >
                    Next
                </Button>
            </div>
        </React.Fragment>
    )
}

export default Media