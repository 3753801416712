import React, { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import avatar from "../../assets/images/users/avatar-1.jpg"
import Breadcrumb from "../../components/Common/Breadcrumb"
import CardProfile from "../../components/Common/Card/ProfileHeader"
import UserDetailsTab from "../../components/Tabs/UserDetailsTab"
import CardAbout from "../../components/Common/Card/CardAbout"
import CardSkills from "../../components/Common/Card/CardSkills"
import CardResume from "../../components/Common/Card/CardResume"
import CardCertificate from "../../components/Common/Card/CardCertificate"
import CardEmploymentHistory from "../../components/Common/Card/CardEmploymentHistory"
import CardEducationalDetails from "../../components/Common/Card/CardEducationalDetails"
import Certificate from "../../components/Common/Certificate"
import TableContainer from "../../components/Common/Table/index"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import AppInvoice from "../../components/Common/Invoice/invoice"
// import UserJobReferralStatus from "../../components/Common/Card/UserJobReferralStatus"
import AppLoader from "../../components/Common/Loader"
import toastr from "toastr"
import jsPDF from "jspdf"

import StudentTestResults from "./StudentTestResults"
import StudentCourseResults from "./StudentCourseResults"
import MockInterViewResults from "./MockInterViewResults"
import UserAppliedJobs from "./UserAppliedJobs"
import CompanyReached from "./CompanyReached"
import UserJobReferralReport from "./UserJobReferralReport"
import UserTransactions from "./UserTransactions"

//redux
import {
  resetStudents as onResetStudents,
  resetTestResult as onResetTestResult,
  getUserTabData as onGetUserTabData,
} from "../../store/students/actions"
import { userProfileFrame, getUserFullName } from "./Utills"
const staticURL = process.env.REACT_APP_STATIC_URL

const StudentProfileTab = () => {
  //meta title
  document.title = "Student Detail | QA"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  params.profileTab = "PERSONALDETAILS"
  const [userData, setUserData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [modal, setModal] = useState(false)
  const [invoiceModal, setInvoiceModal] = useState(false)
  const [certificateCourse, setCertificateCourse] = useState(null)
  const [invoiceDetail, setInvoiceDetail] = useState(null)
  const [title, seTitle] = useState("")
  const role = localStorage.getItem("authRole")

  const contentRef = useRef(null)

  const {
    loading,
    companyViews,
    jobAppliedList,
    personalDetail,
    careerObjective,
    resume,
    educational,
    employment,
    skills,
    certificate,
    testResults,
    userCourses,
    transactions,
    userMockTestRequests,
    jobReferrals,
  } = useSelector(state => ({
    ...state.students,
  }))

  useEffect(() => {
    if (params && params.id) {
      dispatch(onGetUserTabData("PERSONALDETAILS", params.id))
    }
    const authRole = localStorage.getItem("authRole")
    if (authRole && authRole === "admin") {
      setIsAdmin(true)
    }
    return () => {
      dispatch(onResetStudents())
      dispatch(onResetTestResult())
      setIsLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    if (personalDetail && Object.keys(personalDetail).length) {
      setUserData({ ...personalDetail })
      setIsLoading(false)
    }
  }, [personalDetail])

  const handleActions = (data, type) => {
    if (type === "CERTIFICATE" && data.completedChapterPer.includes("100")) {
      setCertificateCourse(data)
      setModal(true)
    } else if (type === "CERTIFICATE") {
      toastr.info("This User Not Completed 100% of this course")
    } else if (type === "INVOICE") {
      setInvoiceDetail(data)
      setInvoiceModal(true)
    } else if (type === "TEST_REPORT") {
      navigate(
        `/student-details/test-report/${params.id}/${data.testId}/${data.testAttemptId}`
      )
    } else if (type === "COURSE_REPORT") {
      navigate(`/student-details/course-report/${params.id}/${data._id}`)
    }
  }

  const handleInvoice = () => {
    const input = contentRef.current
    const pdf = new jsPDF("p", "pt", "a4")

    pdf.html(input, {
      html2canvas: {
        scale: 0.56, // Adjust the scale to fit content within PDF
      },
      callback: () => {
        pdf.save(`${new Date().toISOString()}_invoice.pdf`)
      },
    })
  }

  const total = personalDetail?.userMockTestMarks || 0

  const tabsArray = [
    {
      id: "PERSONALDETAILS",
      label: "Personal Details",
      content: (
        <Col sm="12">
          <UserDetailsTab
            title="Personal Details"
            data={personalDetail}
            role="student"
            loading={loading}
          />
        </Col>
      ),
    },
    {
      id: "CAREER_OBJECTIVE",
      label: "Career Objective",
      content: (
        <Col sm="12">
          <CardAbout
            title="Career Objective"
            descripion={careerObjective}
            loading={loading}
          />
        </Col>
      ),
    },
    {
      id: "EDUCATIONDETAILS",
      label: "Educational Details",
      content: (
        <Col lg={12}>
          <CardEducationalDetails
            educationalDetails={educational}
            loading={loading}
          />
        </Col>
      ),
    },
    {
      id: "SKILLS",
      label: "Skills",
      content: (
        <Col lg={12}>
          <CardSkills title="Skills" list={skills} loading={loading} />
        </Col>
      ),
    },
    {
      id: "EMPLOYMENTS",
      label: "Employment History",
      content: (
        <Col lg={12}>
          <CardEmploymentHistory employment={employment} loading={loading} />
        </Col>
      ),
    },
    {
      id: "RESUME",
      label: "Resume",
      content: (
        <Col lg={12}>
          <CardResume data={{ resume: resume }} loading={loading} />
        </Col>
      ),
    },
    {
      id: "CERTIFICATE",
      label: "Certificates",
      content: (
        <Col lg={12}>
          <Card style={{ borderRadius: 15 }}>
            <CardBody>
              <div className="pt-3">
                <h4 className="sun_heading ms-2">Certificates</h4>
                {loading && <AppLoader />}
                {!loading &&
                  certificate &&
                  Array.isArray(certificate) &&
                  certificate.map((item, key) => {
                    return (
                      <CardCertificate key={key} count={key + 1} data={item} />
                    )
                  })}
              </div>
            </CardBody>
          </Card>
        </Col>
      ),
    },
    {
      id: "TESTRESULTS",
      label: "Test Results",
      content: (
        <Col lg={12}>
          <StudentTestResults
            testResults={testResults}
            loading={loading}
            handleActions={handleActions}
            userId={params.id}
            role="admin"
          />
        </Col>
      ),
    },
    {
      id: "COURSES",
      label: "Courses",
      content: (
        <Col lg={12}>
          <StudentCourseResults
            userCourses={userCourses}
            loading={loading}
            handleActions={handleActions}
            userId={params.id}
            role="admin"
          />
        </Col>
      ),
    },
    {
      id: "MOCKINTERVIEWDETAILS",
      label: "Mock Interview Detail",
      content: (
        <Col lg={12}>
          <MockInterViewResults
            userMockTestRequests={userMockTestRequests}
            loading={loading}
            userId={params.id}
            role="admin"
          />
        </Col>
      ),
    },
    {
      id: "JOBAPPLIED",
      label: "Job Apply",
      content: (
        <Col lg={12}>
          <UserAppliedJobs
            userAppliedJobList={jobAppliedList}
            loading={loading}
            userId={params.id}
            handleActions={handleActions}
          />
        </Col>
      ),
    },
    {
      id: "COMPANYVIEW",
      label: "Company Reached",
      content: (
        <Col lg={12}>
          <CompanyReached
            companyViews={companyViews}
            loading={loading}
            userId={params.id}
            handleActions={handleActions}
          />
        </Col>
      ),
    },
    {
      id: "JOBREFRRAL",
      label: "Job Referral",
      content: (
        <Col lg={12}>
          <UserJobReferralReport
            jobReferrals={jobReferrals}
            loading={loading}
            userId={params.id}
          />
        </Col>
      ),
    },
    {
      id: "TRANSACTIONS",
      label: "Transaction History",
      content: (
        <Col lg={12}>
          <UserTransactions
            transactions={transactions}
            loading={loading}
            userId={params.id}
            handleActions={handleActions}
          />
        </Col>
      ),
    },
  ]

  const handleTabClick = tabId => {
    dispatch(onGetUserTabData(tabId, params.id, `&page=${1}&limit=${10}`))
  }

  const fullname = personalDetail && getUserFullName(personalDetail)
  const userProfile = userData ? userProfileFrame(userData) : ""

  return (
    <React.Fragment>
      {modal && (
        <InfomationModal
          modalBackdrop={modal}
          setmodalBackdrop={setModal}
          title={"Certificate"}
          size={"lg"}
          height={600}
        >
          <Certificate user={userData} courses={certificateCourse} />
        </InfomationModal>
      )}
      {invoiceModal && (
        <InfomationModal
          modalBackdrop={invoiceModal}
          setmodalBackdrop={setInvoiceModal}
          title={"Transaction History"}
          size={"xl"}
          height={500}
          isFooter={true}
          buttonTitle={"Download as PDF"}
          handleAction={handleInvoice}
        >
          <AppInvoice
            transactions={transactions}
            invoiceDetail={invoiceDetail}
            user={userData}
            contentRef={contentRef}
          />
        </InfomationModal>
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={!isAdmin ? title : "StudentList"}
            breadcrumbItem={!isAdmin ? `${title} Profile` : "Student Profile"}
            navigate={!isAdmin ? -1 : "/students-list"}
          />
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <AppLoader />
              ) : (
                <CardProfile
                  imageUrl={
                    userData && userData.image
                      ? staticURL + userData.image
                      : avatar
                  }
                  data={{ user: userData, ...personalDetail }}
                  profileName={
                    personalDetail?.name ? personalDetail?.name : fullname
                  }
                  profileEmail={personalDetail ? personalDetail.email : ""}
                  onclick={() =>
                    navigate(`/profile/edit/student/${personalDetail._id}`)
                  }
                  LayoutType={"HORIZONTAL_TABS"}
                  tabs={tabsArray}
                  style={{ padding: 0 }}
                  showStart={true}
                  startdata={{ total: total }}
                  showFrame={userProfile}
                  handleTabClick={handleTabClick}
                  ButtonType={["admin", "student"].includes(role) ? "EDIT" : ""}
                  profileTitle={
                    personalDetail ? personalDetail?.profileTitle : ""
                  }
                  showAddress={true}
                  showStatus={true}
                  // planData={}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StudentProfileTab
