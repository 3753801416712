import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  Button,
} from 'reactstrap';
import { Link } from "react-router-dom"
import ConfirmationModal from "../Modal/ConfirmationModal"
const QuestionsCard = (
  { title,
    questions,
    handleDeleteButton,
    handleAddQuestions,
    isCsvToJsonConverter,
    showEdit = false,
    loading = false,
    ButtonData = null,
    test = null
  }
) => {
  const [showModal, setShowModal] = useState(false)
  const [questionId, setQuestionId] = useState("")
  const modalAction = () => {
    handleDeleteButton(questionId)
    setShowModal(false)
  }
  return (
    <Card style={{ borderColor: "#CED4DA", borderWidth: 1.5 }}>
      <CardHeader style={{ backgroundColor: "#E0E5FA" }}>
        <Row>
          <Col>
            <h4 className="sun_heading">{title}</h4>
          </Col>
          <Col className='d-flex justify-content-end'>
            {ButtonData &&
              <button
                onClick={ButtonData?.onClick}
                style={{
                  backgroundColor: 'transparent',
                  border: "1px solid blue",
                  color: 'blue',
                  '&:hover': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                }}
                className="btn me-2"
              >
                <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
                {ButtonData?.title}
              </button>
            }
            {handleAddQuestions &&
              <Button
                onClick={handleAddQuestions}
                style={{
                  backgroundColor: 'transparent',
                  border: "1px solid blue",
                  color: 'blue',
                  '&:hover': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                }}
                className="btn"
              >
                <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
                {loading ? (
                  <Spinner className="ms-2" color="light" size="sm" />
                ) : (
                  "Add Questions"
                )}
              </Button>
            }
            {
              isCsvToJsonConverter &&
              <Button
                onClick={isCsvToJsonConverter}
                style={{
                  backgroundColor: 'transparent',
                  border: "1px solid blue",
                  color: 'blue',
                  '&:hover': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                }}
                className="btn ms-2"
              >
                Import CSV
              </Button>
            }
          </Col>
        </Row>
      </CardHeader>
      <CardBody style={{ backgroundColor: "#F2F2F2" }}>
        {test && <TestCard test={test} />}
        {questions.length
          ? questions.map((question, index) => {
            return (
              <Card key={index} style={{ marginBottom: 10 }}>
                <CardBody style={{ padding: "12px 20px" }}>
                  <Row>
                    <Col
                      lg="6"
                      className="justify-content-left d-flex question_text"
                    >
                      {`${index + 1}. ${question?.question}`}
                    </Col>
                    <Col
                      lg="6"
                      className="justify-content-end d-flex question_answer px-lg-0"
                    >
                      {`Answer : ${question?.answer}`}
                      {showEdit && <Link
                        to={`/questions-edit/test/${question?.questionId}/${index + 1}`}
                        className="text-muted font-weight-bold px-2 ms-3"
                      >
                        <i
                          className="mdi mdi-pencil edit_blue_icon font-size-15"
                          id="edittooltip"
                        />
                        <UncontrolledTooltip placement="top" target="edittooltip">
                          Edit
                        </UncontrolledTooltip>
                      </Link>
                      }

                      {handleDeleteButton && <Link onClick={() => {
                        setShowModal(true)
                        setQuestionId(question?.questionId)
                      }}>
                        <i
                          className="mdi mdi-delete delete_red_icon font-size-15"
                          id="deletetooltip"
                        />
                        <UncontrolledTooltip placement="top" target="deletetooltip">
                          Delete
                        </UncontrolledTooltip>
                      </Link>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            );
          })
          : "No Questions"}
        {showModal && (
          <ConfirmationModal
            modalBackdrop={showModal}
            setmodalBackdrop={setShowModal}
            modalTitle={"Are you sure want to delete this question ?."}
            modalAction={modalAction}
          // loading={props.loading}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default QuestionsCard;


const TestCard = ({ test }) => {
  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <CardBody style={{ padding: "12px 20px" }}>
          <Row>
          <Col
              lg="10"
              className="justify-content d-flex question_answer px-lg-0"
            >
              <div className="mb-4">
                <p className="text-muted mb-2 sm_title">
                  Description
                </p>
                <div className="font-size-15 sm_content" dangerouslySetInnerHTML={{ __html: test?.description }}></div>
              </div>
            </Col>
            <Col
              lg="2"
              className="justify-content d-flex question_answer px-lg-0"
            >
              {/* <div className="mb-4">
                <p className="text-muted mb-2 sm_title">
                  Test Name
                </p>
                <h5 className="font-size-15 sm_content">
                  {test?.name}
                </h5>
              </div> */}
              <div className="mb-4">
                <p className="text-muted mb-2 sm_title">
                  Duration
                </p>
                <h5 className="font-size-15 sm_content">
                  {test?.duration}
                </h5>
              </div>
              {/* <div className="mb-4 ms-4">
                <p className="text-muted mb-2 sm_title">
                  Test type
                </p>
                <h5 className="font-size-15 sm_content">
                  {test?.type}
                </h5>
              </div>
              <div className="mb-4 ms-4">
                <p className="text-muted mb-2 sm_title">
                  Price
                </p>
                <h5 className="font-size-15 sm_content">
                  {test?.price}
                </h5>
              </div>
              <div className="mb-4 ms-4">
                <p className="text-muted mb-2 sm_title">
                  Status
                </p>
                <h5 className="font-size-15 sm_content">
                  {test?.status}
                </h5>
              </div> */}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

