import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import TableContainer from "../../components/Common/Table/index"
import { unlockedCandidatesColumns } from "../ApplicantsManagement/constants"
import { useDispatch } from "react-redux"
import { getUserTabData as onGetUserTabData } from "../../store/students/actions"

function UnlockCandidatesTab({ unlockCandidates, loading, userId }) {
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [data, setData] = useState([])
  const tabId = "UNLOCK_CANDIDATES"
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      unlockCandidates &&
      Array.isArray(unlockCandidates) &&
      unlockCandidates.length > 0
    ) {
      setData(
        unlockCandidates
          .map(unlockCandidates => ({
            ...unlockCandidates,
            unlockedByName: unlockCandidates?.unlockedBy
              ? unlockCandidates?.unlockedBy?.name
              : "",
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    }
  }, [unlockCandidates])

  const onChangePagination = (limit, page) => {
    setRowPerPage(rowPerPage)
    setPageNo(page)
    if (userId) {
      dispatch(onGetUserTabData(tabId, userId, `&page=${page}&limit=${limit}`))
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          <div className="pt-3 px-2">
            <h4 className="sun_heading">Unlock Candidates</h4>
          </div>
          <TableContainer
            loading={loading}
            data={data}
            isGlobalFilter={false}
            isAddOptions={false}
            isJobListGlobalFilter={false}
            showPageDropdown={false}
            customPageSize={10}
            className="project-list-table table-nowrap align-middle table-borderless"
            columns={unlockedCandidatesColumns}
            showPagination={true}
            onChangePagination={onChangePagination}
          />
        </CardBody>
      </Card>
    </>
  )
}

export default UnlockCandidatesTab
