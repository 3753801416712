export const columns = [
    {
        header: "#",
        key: "",
    },
    {
        header: "Name",
        name: "type",
        key: "DEFAULT",
        link: "/tests-overview",
    },
    {
        header: "TestCount",
        name: "testCount",
        key: "DEFAULT",
        link: "/tests-overview",
    },
    {
        header: "Created Date",
        name: "createdAt",
        key: "DATE",
        link: "/tests-overview",
    },
    {
        header: "Status",
        name: "status",
        key: "STATUS",
    },
    {
        header: "Active/Deactive",
        key: "SWITCH",
        name: "testCategoryStatus",
    },
    {
        header: "Actions",
        key: "MORE_ACRIONS",
        dropDownOptions: [
            { label: "Edit", type: "EDIT" },
            { label: "View", type: "VIEW" },
            { label: "Delete", type: "DELETE" }
        ],
    },
]

export const formFields = [
    {
        name: "type",
        label: "Name",
        type: "text",
        placeholder: "Enter Category Name",
        required: true,
    },
    {
        name: "status",
        label: "Status",
        type: "select",
        options: [
            { value: "", label: "Choose Status" },
            { value: "active", label: "Active" },
            { value: "deactive", label: "Deactive" },
        ],
        required: true,
    },
]