import { del, get, post, patch } from "../api_helper"

// dynamic search
export const dynamicSearch = queryParams => get(`dynamic/search/${queryParams}`)

// get user Profile tab data
export const userProfileTab = (slug, userId, queryParams = "") =>
  get(`users/tab/${userId}?slug=${slug}${queryParams ? queryParams : ""}`)

export const getDynamicSideBarLayout = () => get(`/dynamic/layout`)

export const getDynamicList = slug => get(`/dynamic/list${slug}`)
