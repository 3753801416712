// BarChart.js
import React, { useRef } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  registerables,
} from "chart.js"
import {
  Doughnut,
  Pie,
  Bar,
} from "react-chartjs-2"
import "chartjs-plugin-annotation"

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const Chart = ({ data, options, chartType = "bar", onBarClick }) => {
  const chartRef = useRef()

  const handleClick = event => {
    onBarClick(event, chartRef.current)
  }

  // Function to render the appropriate chart based on chartType
  const renderChart = () => {
    switch (chartType) {
      case "bar":
        return (
          <Bar
            data={data}
            options={options}
            {...(onBarClick ? { onClick: handleClick } : {})}
            ref={chartRef}
          />
        )
      case "pie":
        return <Pie data={data} options={options} />
      case "doughnut":
        return <Doughnut data={data} options={options} />
      default:
        return null
    }
  }

  return <>{renderChart()}</>
}

export default Chart
