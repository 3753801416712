/* APPLICANTS */
export const GET_APPLICANTS = "GET_APPLICANTS"
export const GET_APPLICANTS_SUCCESS = "GET_APPLICANTS_SUCCESS"
export const GET_APPLICANTS_FAIL = "GET_APPLICANTS_FAIL"

export const UPDATE_APPLICANTS = "UPDATE_APPLICANTS"
export const UPDATE_APPLICANTS_SUCCESS = "UPDATE_APPLICANTS_SUCCESS"
export const UPDATE_APPLICANTS_FAIL = "UPDATE_APPLICANTS_FAIL"

export const RESET_APPLICANTS = "RESET_APPLICANTS"

export const UPDATE_APPLICANT_LOADING_STATE = "UPDATE_APPLICANT_LOADING_STATE"

/* CANDIDATES */
export const GET_CANDIDATES = "GET_CANDIDATES"
export const GET_CANDIDATES_SUCCESS = "GET_CANDIDATES_SUCCESS"
export const GET_CANDIDATES_FAIL = "GET_CANDIDATES_FAIL"

/* MY CANDIDATES */
export const GET_MY_CANDIDATES = "GET_MY_CANDIDATES"
export const GET_MY_CANDIDATES_SUCCESS = "GET_MY_CANDIDATES_SUCCESS"
export const GET_MY_CANDIDATES_FAIL = "GET_MY_CANDIDATES_FAIL"

/* UNLOCK CANDIDATES */
export const UNLOCK_CANDIDATES = "UNLOCK_CANDIDATES"
export const UNLOCK_CANDIDATES_SUCCESS = "UNLOCK_CANDIDATES_SUCCESS"
export const UNLOCK_CANDIDATES_FAIL = "UNLOCK_CANDIDATES_FAIL"

export const GET_UNLOCK_CANDIDATES = "GET_UNLOCK_CANDIDATES"
export const GET_UNLOCK_CANDIDATES_SUCCESS = "GET_UNLOCK_CANDIDATES_SUCCESS"
export const GET_UNLOCK_CANDIDATES_FAIL = "GET_UNLOCK_CANDIDATES_FAIL"
