import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import {
  getRoles as onGetRoles,
  getHrUsers as onGetUsers,
  addNewhrUser as onAddNewHrUser,
  updateHrUser as onUpdateHrUser,
  deleteStudent as onDeleteStudent,
  resetPasswordForHr,
} from "../../store/actions"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
// import Breadcrumbs from "components/Common/Breadcrumb"
import Breadcrumb from "../../components/Common/Breadcrumb"

import TableContainer from "../../components/Common/Table/index"
import FormModal from "../../components/Common/Modal/FormModal"
const authRole = localStorage.getItem("authRole")
import { UsersColumns, resetHrpasswordfields } from "./constants"
import { HrUserfields, checkPlan } from "./companyUtills"

const AddHrUsers = () => {
  document.title = "Student List | QAPRENEUR"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [formModal, setFormModal] = useState(false)
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [hrUsersList, setHrUsersList] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const [hrDetail, setHrDetail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirm: "",
  })

  const { HrUsers, roles, loading } = useSelector(state => ({
    roles: state.roles?.roles,
    loading: state?.HrUsers?.loading,
    HrUsers: state?.HrUsers?.hrUsers,
  }))

  const handleModalAction = () => {
    if (student && student._id) {
      const queryParams = {
        page: pageNo,
        limit: rowPerPage,
      }
      dispatch(onDeleteStudent(student._id, queryParams))
    }
    setDeleteModal(false)
  }

  useEffect(() => {
    if (params.companyId) {
      const queryParams = {
        page: pageNo,
        limit: rowPerPage,
        companyId: params.companyId,
      }
      if (search && search.length > 0) {
        queryParams.search = search
      }
      dispatch(onGetRoles())
      dispatch(onGetUsers(queryParams))
    }
  }, [dispatch, pageNo, rowPerPage,search])

  useEffect(() => {
    if (!isEmpty(HrUsers)) {
      setHrUsersList(
        HrUsers.map(hr => ({
          ...hr,
          status: hr.emailVerified ? hr.status : "unverified",
          // status: s.status,
          emailVerifyStatus: hr.emailVerified ? "active" : "deactive",
        })).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    }
  }, [HrUsers, pageNo])

  const toggleForm = () => setFormModal(!formModal)
  const togglePasswordmodal = () => setResetPasswordModal(!resetPasswordModal)

  const handleChangeStatus = (hr, CASE) => {
    if (CASE === "CASE2") {
      const status = hr.status === "active" ? "deactive" : "active"
      dispatch(onUpdateHrUser({ status }, hr._id))
    }
    if (CASE === "CASE1") {
      const emailVerified = hr.emailVerifyStatus === "active" ? false : true
      dispatch(onUpdateHrUser({ emailVerified }, hr._id))
    }
  }
  const handleSearch = (query) => {
    setPageNo(1)
    setSearch(query)
  }

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  const handleActions = (data, type) => {
    if (type === "RESET_PASSWORD") {
      setHrDetail(data)
      togglePasswordmodal()
    } else if (type === "EDIT") {
      setHrDetail(data)
      setIsEdit(true)
      toggleForm()
    }
  }

  const handleSave = values => {
    const role =
      roles && roles.length > 0 && roles.find(r => r.role === "hr_user")
    if (role && params.companyId) {
      const newValues = {
        roleId: role._id,
        companyId: params.companyId,
        ...values,
        name: `${values.firstName} ${values.lastName}`,
        firstName: values.firstName,
        lastName: values.lastName,
      }
      if (isEdit) {
        const updateValues = {
          firstName: newValues?.firstName,
          lastName: newValues?.lastName,
          hrId: newValues?._id,
          name: `${values.firstName} ${values.lastName}`,
          phone: newValues?.phone,
        }
        dispatch(onUpdateHrUser(updateValues, updateValues?.hrId))
        setIsEdit(false)
        setHrDetail({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          password: "",
          passwordConfirm: "",
        })
      } else {
        dispatch(onAddNewHrUser(newValues))
      }
    } else {
      console.log("invailid role")
    }
    toggleForm()
  }

  const handleResetPassword = values => {
    const data = {
      ...values,
      _id: hrDetail?._id,
    }
    dispatch(resetPasswordForHr(data))
    togglePasswordmodal()
  }

  const HrUserFormfields = HrUserfields(isEdit)

  const checkUserPlan = async () => {
    checkPlan(toggleForm)
  }

  const authRole = localStorage.getItem("authRole")
  const isHrUser = authRole === "hr_user"

  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the student.?"}
          modalAction={handleModalAction}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="dashboard"
            breadcrumbItem={isHrUser ? "User" : "Add User"}
            navigate={"/dashboard"}
          />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={hrUsersList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  // showPageDropdown={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  handleStatus={handleChangeStatus}
                  handleActions={handleActions}
                  onChangePagination={onChangePagination}
                  showPagination={true}
                  addButton={
                    isHrUser
                      ? null // If user is hr_user, hide the add button
                      : {
                          onclick: checkUserPlan,
                          title: "Add User",
                        }
                  }
                  handleSearch={handleSearch}
                  rowLink="/student-details"
                  columns={UsersColumns}
                  notDataFound={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {formModal && (
        <FormModal
          modalBackdrop={formModal}
          setmodalBackdrop={setFormModal}
          modalAction={handleSave}
          title={isEdit ? "Edit User" : "Add User"}
          formFields={HrUserFormfields}
          data={{ ...hrDetail }}
          height={450}
        />
      )}
      {resetPasswordModal && (
        <FormModal
          modalBackdrop={resetPasswordModal}
          setmodalBackdrop={setResetPasswordModal}
          modalAction={handleResetPassword}
          title={"Reset User password"}
          formFields={resetHrpasswordfields}
          data={{
            newPassword: "",
            confirmPassword: "",
          }}
          height={250}
        />
      )}
    </React.Fragment>
  )
}

export default AddHrUsers
