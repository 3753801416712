import React, { useEffect, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Container, Card, CardBody, Spinner } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import PlanTab from "./PlanTab"
import AppLoader from "../../components/Common/Loader"
import {
  getCompanySubscriptions as onGetCompanySubscriptions,
  getCompanyAllplan as onGetCompanyAllplan,
} from "../../store/companies/actions"
import { AppContext } from "../../context/AppContext"

const UpgradePlan = () => {
  const dispatch = useDispatch()
  const role = localStorage.getItem("authRole")
  const { loggedInUser } = useContext(AppContext)

  const { Subscriptions, loading, Plans, activePlan } = useSelector(state => ({
    Subscriptions: state.companies?.Subscriptions,
    loading: state.companies?.loading,
    Plans: state.companies?.Plans,
    activePlan: state.companies?.activePlan,
    // companyDetail: state.companies?.companyDetail,
  }))

  useEffect(() => {
    // dispatch(onGetCompanySubscriptions(companyDetail._id))
    dispatch(onGetCompanyAllplan({ role: "company" }))
  }, [dispatch])

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title="Dashboard"
          breadcrumbItem="Upgrade Plan"
          navigate={"/dashboard"}
        />
        {Subscriptions && Plans ? (
          <PlanTab
            role={role}
            user={loggedInUser}
            Plans={Plans}
            Subscriptions={Subscriptions}
            activePlan={activePlan}
            loading={loading}
          />
        ) : (
          <AppLoader />
        )}
      </Container>
    </div>
  )
}

export default UpgradePlan
