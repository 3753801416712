// ourFeatures images
import our_features1 from "../../assets/images/home/our_features1.png"
import our_features2 from "../../assets/images/home/our_features2.png"
import our_features3 from "../../assets/images/home/our_features3.png"

// brandLogoes images and svg
import Syscraft from "../../assets/images/home/Hiring_Partners/Syscraft.png"
import advancedgroup from "../../assets/images/home/Hiring_Partners/advancedgroup.png"
import apconic from "../../assets/images/home/Hiring_Partners/apconic.png"
import infobyd from "../../assets/images/home/Hiring_Partners/infobyd.png"
import zehntech from "../../assets/images/home/Hiring_Partners/zehntech.svg"
import thriveonsolutions from "../../assets/images/home/Hiring_Partners/thriveonsolutions.png"
import questglt from "../../assets/images/home/Hiring_Partners/questglt.png"
import cubexo from "../../assets/images/home/Hiring_Partners/cubexo.webp"
import qltyexperts from "../../assets/images/home/Hiring_Partners/qltyexperts.png"
import basecamp from "../../assets/images/home/Hiring_Partners/basecamp.png"
import gammastack from "../../assets/images/home/Hiring_Partners/gammastack.webp"
import infosparkles from "../../assets/images/home/Hiring_Partners/infosparkles.png"
import lmsin from "../../assets/images/home/Hiring_Partners/lmsin.png"
import nenosystems from "../../assets/images/home/Hiring_Partners/nenosystems.png"
import pataa from "../../assets/images/home/Hiring_Partners/pataa.svg"
import AM_Webtech from "../../assets/images/home/Hiring_Partners/AM_Webtech.png"
import impetus from "../../assets/images/home/Hiring_Partners/impetus.svg"
import degree_program from "../../assets/images/home/degree_program.svg"
import non_degree_program from "../../assets/images/home/non_degree_program.svg"
import off_campus from "../../assets/images/home/off_campus.svg"

export const Categories = [
  {
    imgSrc: degree_program,
    title: "Students",
    description:
      "Easy study material, tests & mock interviews to get placed in top companies.",
    buttonTitle: "10+ Courses",
    className: "online_test_cat",
  },
  {
    imgSrc: non_degree_program,
    title: "Companies",
    description:
      "To employ the most qualified talent, companies can find talented resources.",
    buttonTitle: "50+ Companies",
    className: "online_test_cat non_degree",
  },
  {
    imgSrc: off_campus,
    title: "Colleges",
    description:
      "Colleges can enroll themselves to place their students with reputed companies.",
    buttonTitle: "35+ Colleges",
    className: "online_test_cat off_campus",
  },
]

export const scrollLinks = [
  {
    path: "top_bar",
    label: "Home",
  },
  {
    path: "section_about_us",
    label: "About",
  },
  {
    path: "section_membership_plan",
    label: "Price",
  },
  {
    path: "section_contact_us",
    label: "Contact",
  },
]

export const footerLinks = [
  {
    path: "section_home",
    label: "Home",
    scroll: true,
  },
  {
    path: "section_about_us",
    label: "About",
    scroll: true,
  },
  {
    path: "section_online_test",
    label: "Test",
    scroll: true,
  },
  {
    path: "section_membership_plan",
    label: "Price",
    scroll: true,
  },
  {
    path: "section_contact_us",
    label: "Contact",
    scroll: true,
  },
  {
    path: "/login",
    label: "Sign In",
    scroll: false,
  },
  {
    path: "/student/register",
    label: "Registration",
    scroll: false,
  },
]

export const ourFeatures = [
  {
    imgSrc: our_features1,
    title: "Job-oriented Courses",
    description:
      "All the instructors are industry experts having a thorough knowledge of real-time projects who help you secure your dream job.",
  },
  {
    imgSrc: our_features2,
    title: "Mock Tests",
    description:
      "Join the QAPreneur app to test your knowledge before applying for any company interview to secure your chances of getting hired.",
  },
  {
    imgSrc: our_features3,
    title: "Affordable Pricing",
    description:
      "Access study materials, mock tests, interviews, get shortlisted by companies, and more with our minimum pricing plans.",
  },
  {
    imgSrc: our_features1,
    title: "One App gets you Covered",
    description:
      "The all-in-one app for all IT requirements from giving tests to getting hired by top IT companies. We have got you covered so that you don’t miss any other app.",
  },
  {
    imgSrc: our_features1,
    title: "Explore FlashCards",
    description:
      "Engage in an interactive learning experience by flipping through digital flashcards to reinforce key concepts.",
  },
  {
    imgSrc: our_features3,
    title: "Quizzes",
    description:
      "Embrace the features, ignite your curiosity, and embark on a transformative learning journey with quizzes! ",
  },
  {
    imgSrc: our_features2,
    title: "Video Lectures",
    description:
      "Embark on a transformative learning journey with the dynamic world of video lectures and stay engaged even while on the move.",
  },
]

export const brandLogoes = [
  { src: Syscraft },
  { src: AM_Webtech },
  { src: qltyexperts, style: { filter: "invert(100%)" } },
  { src: infobyd },
  { src: lmsin },
  { src: advancedgroup },
  { src: cubexo },
  { src: gammastack },
  { src: zehntech, style: { filter: "invert(100%)" } },
  { src: pataa },
  { src: thriveonsolutions },
  { src: questglt },
  { src: apconic, style: { filter: "invert(100%)" } },
  { src: infosparkles },
  { src: nenosystems },
  { src: basecamp },
  { src: impetus },
]
