import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import Breadcrumbs from "components/Common/Breadcrumb"
import AppTable from "../../components/Common/Table/index"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import FormModal from "../../components/Common/Modal/FormModal"
import { Col, Container, Row } from "reactstrap"
import {
  addNewTestCategory as onAddNewTestCategory,
  getTestCategories as onGetTestCategories,
  updateTestCategory as onUpdateTestCategory,
  deleteTestCategory as onDeleteTestCategory,
  getDynamicList as onGetDynamicList,
} from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { columns, formFields } from "./constants"

const TestCategoriesList = () => {
  document.title = "Category List | QAPRENEUR"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [testCategory, setTestCategory] = useState({
    type: "",
    status: "",
  })
  const [isEdit, setIsEdit] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const [testCategoryList, setTestCategoryList] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [formModal, setFormModal] = useState(false)
  const role = localStorage.getItem("authRole")

  const { testCategories, loading } = useSelector(state => ({
    testCategories: state?.testCategories?.testCategories,
    loading: state?.testCategories?.loading,
  }))

  useEffect(() => {
    const params = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      params.search = search
    }
    dispatch(onGetTestCategories(params))
    if (params.slug) {
      dispatch(onGetDynamicList(params.slug))
    }
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(testCategories)) {
      setTestCategoryList(
        testCategories
          .map(t_cat => ({ ...t_cat, testCategoryStatus: t_cat.status }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setTestCategoryList([])
    }
  }, [testCategories, pageNo])

  const toggle = () => {
    if (formModal) {
      setFormModal(false)
      setTestCategory(null)
    } else {
      setFormModal(true)
    }
  }

  const handleDeleteOrder = () => {
    if (testCategory && testCategory._id) {
      const params = {
        page: pageNo,
        limit: rowPerPage,
      }
      dispatch(onDeleteTestCategory(testCategory._id, params))
    }
    setDeleteModal(false)
  }
  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }
  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  const handleSave = values => {
    if (isEdit) {
      const updateTestCategory = {
        type: values.type,
        status: values.status,
      }
      dispatch(onUpdateTestCategory(updateTestCategory, testCategory.id))
    } else {
      const newTestCategory = {
        type: values["type"],
        status: values["status"],
      }
      dispatch(onAddNewTestCategory(newTestCategory))
    }
    toggle()
  }

  const handleActions = (testCategory, type) => {
    if (type === "VIEW") {
      navigate(`/tests-overview/${testCategory?._id}`)
      setTestCategory(testCategory)
    } else if (type === "EDIT") {
      setTestCategory({
        id: testCategory._id,
        type: testCategory.type,
        status: testCategory.status,
        testCount: testCategory.testCount,
      })
      setIsEdit(true)
      toggle()
    } else if (type === "DELETE") {
      setTestCategory(testCategory)
      setDeleteModal(true)
    } else if (type === "CREATE") {
      setIsEdit(false)
      setTestCategory({
        type: "",
        status: "",
      })
      toggle()
    } else if (type === "STATUS_CHANGE") {
      const status = testCategory.status === "active" ? "deactive" : "active"
      const testCount = testCategory.testCount
      dispatch(
        onUpdateTestCategory({ status, testCount }, testCategory._id, navigate)
      )
    }
  }

  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the category ?."}
          modalAction={handleDeleteOrder}
        />
      )}
      {formModal && (
        <FormModal
          modalBackdrop={formModal}
          setmodalBackdrop={setFormModal}
          modalAction={handleSave}
          title={!!isEdit ? "Edit Category" : "Add Category"}
          formFields={formFields}
          data={testCategory}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Category" breadcrumbItem="Category" />
          <Row>
            <Col lg="12">
              <div>
                <AppTable
                  loading={loading}
                  data={testCategoryList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  handleStatus={data => handleActions(data, "STATUS_CHANGE")}
                  handleActions={handleActions}
                  onChangePagination={onChangePagination}
                  handleSearch={handleSearch}
                  showPagination={true}
                  rowLink={"/"}
                  addButton={
                    ["admin"].includes(role)
                      ? {
                          onclick: () => handleActions(testCategory, "CREATE"),
                          title: "Add New Category",
                        }
                      : null
                  }
                  columns={columns}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TestCategoriesList
