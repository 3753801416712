import React from "react"
import { Helmet } from "react-helmet"
import logo from "./assets/images/logo.svg";


const metaDatas = {
  home: {
    title: "QAPreneur | QA delivers training | Hire QA professional | ISTQB",
    description:
      "Unlock success with QAPreneur! Quality Assurance training and hiring QA professionals made it easy. Elevate your team with expert QA solutions.",
    keywords:
      "QA delivers training,Learning Management Systems,E-Training System,World-Leading EduTech Training,How to become an ISTQB Certified professional,ISTQB exams syllabus,ISTQB exam Study Material with Mock Test,ISTQB course materials,Hire certified QA professionals,With QAPreneur reduce your hiring burden by 90%,Manual Testing Course,ISTQB Foundation Course online,ISTQB Agile Extension Course,ISTQB Study Guide,Interview Question for Manual Testing,ISTQB Foundation Level Notes,Study Materials for the ISTQB Foundation Exam,ISTQB Study Material,ISTQB exam preparation,ISTQB Certification Study Material,Dumbs for ISTQB API United,Manual testing syllabus,automation testing syllabus,ISTQB certified testers,ISTQB Testing Certification,ISTQB Foundation Level Exam,ISTQB CTFL Certification,ISTQB Agile Tester certification?,Benefits of ISTQB Certified Testers,ISTQB Certified Tester Foundation Level ISTQB Certification Levels,ISTQB Certification Info and Free Exams,ISTQB Courses and Certifications,ISTQB Foundation Certificate in Software Testing,ISTQB Certification Preparation,Becoming an ISTQB Foundation Level Certified QA Tester,What is ISTQB certification & its benefits ?,Certifications for Software Testing,Hire highly experienced QA Engineers,hiring QA testers in India,ISTQB-certified QA experts,Hire QA professional,Hire Qualified Software Testers & QA Experts,How to hire a QA Engineer",
  },
  studentSignUp: {
    title: "QAPreneur | QA learning platforms | Jobs in Software Testing",
    description:
      "Explore QA learning platforms & software testing jobs at QAPreneur. Elevate your skills, and advance your career in quality assurance with our expert guidance.",
    keywords:
      "QA learning platforms,Software Quality Assurance Online Training Courses,QA Online Course with Certification,QA Courses,online certificate for QA testing,Mock interview for testing,Mock interview for QA testing,Mock interview for manual testing,Mock interview for automation testing,How to become a QA Master online,How to Pass the ISTQB Foundation Level Exam,Interview Question for Automation Testing,Interview Question on Agile,Interview Question on Jira,Interview Question on Database Testing,Interview Question on API Testing,Jobs in Software Testing,Jobs in Automation Testing,Dumbs for ISTQB,Dumbs for ISTQB Advance Level,Dumbs for ISTQB Foundation Level,Dumbs for ISTQB Agile Extenssion,Dumbs for ISTQB Selenium United",
  },
  companySignUp: {
    title: "QAPreneur | Hire certified QA professionals | QA testing experts",
    description:
      "Unlock quality assurance excellence with QAPreneur! Hire certified QA professionals and QA testing experts for reliable software quality.",
    keywords:
      "Hire certified QA professionals,With QApreneur reduce your hiring burden by 90%,Hire Dedicated QA Testers,hire testing experts,QA testing experts,Hire the best Software QA Testers,top-rated Software QA Testers,Hire certified QA and testing engineers,Looking to Hire Software QA Tester,Experienced software QA testers",
  },
  collegeSignUp: {
    title: "QAPreneur | Hire certified QA professionals | QA testing experts",
    description:
      "Unlock quality assurance excellence with QAPreneur! Hire certified QA professionals and QA testing experts for reliable software quality.",
    keywords:
      "ISTQB performance testing syllabus,ISTQB selenium certification syllabus,ISTQB free certification course,ISTQB foundation level certification mock test online,ISTQB certification exam study material,ISTQB foundation level practice test online,ISTQB foundation level study material,ISTQB foundation level preparation,ISTQB preparation material,ISTQB learning material,ISTQB syllabus and study material,aptitude questions for manual testing,agile methodology mock interview questions,Jira tool mock interview test,agile testing mock interview questions,api automation testing mock interview questions,agile devops mock interview questions,manual QA tester jobs,manual software testing jobs,QA automation engineer jobs,software testing jobs for freshers,How to become a QA tester,quality test management for Jira,ISTQB certification cost,ISTQB certified tester foundation level,ISTQB foundation level certification,ISTQB foundation level mock test,ISTQB foundation level exam registration,ISTQB foundation level study material,ISTQB agile tester certification,automation testing course syllabus,ISTQB selenium certification,ISTQB agile tester mock test",
  },
  coachingSignUp: {
    title: "QAPreneur | Hire certified QA professionals | QA testing experts",
    description:
      "Unlock quality assurance excellence with QAPreneur! Hire certified QA professionals and QA testing experts for reliable software quality.",
    keywords:
      "ISTQB performance testing syllabus,ISTQB selenium certification syllabus,ISTQB free certification course,ISTQB foundation level certification mock test online,ISTQB certification exam study material,ISTQB foundation level practice test online,ISTQB foundation level study material,ISTQB foundation level preparation,ISTQB preparation material,ISTQB learning material,ISTQB syllabus and study material,aptitude questions for manual testing,agile methodology mock interview questions,Jira tool mock interview test,agile testing mock interview questions,api automation testing mock interview questions,agile devops mock interview questions,manual QA tester jobs,manual software testing jobs,QA automation engineer jobs,software testing jobs for freshers,How to become a QA tester,quality test management for Jira,ISTQB certification cost,ISTQB certified tester foundation level,ISTQB foundation level certification,ISTQB foundation level mock test,ISTQB foundation level exam registration,ISTQB foundation level study material,ISTQB agile tester certification,automation testing course syllabus,ISTQB selenium certification,ISTQB agile tester mock test",
  },
}

const MetaTags = ({ page }) => {
  const { title, description, keywords } = metaDatas[page]
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={logo} />
      {/* Add more Open Graph meta tags as needed */}

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logo} />
      {/* Add more Twitter meta tags as needed */}

      {/* Additional SEO-based meta tags */}
      <meta name="author" content="Your Name" />
      <meta name="robots" content="index, follow" />
      {/* Add more SEO-based meta tags as needed */}
    </Helmet>
  )
}

export default MetaTags
