import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Button,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AppLoader from "../../components/Common/Loader/index"

import { useFormik } from "formik"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import {
  getChapterDetail as onGetChapterDetail,
  updateChapter as onUpdateChapter,
  resetChapter as onResetChapter,
} from "../../store/actions"
const staticURL = process.env.REACT_APP_STATIC_URL

const EditChapter = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [PdfContnent, setPdfContnent] = useState("")

  document.title = `QAPRENEUR`

  const { chapterDetail } = useSelector(state => ({
    chapterDetail: state.chapters.chapterDetail,
  }))

  // Define a state to track whether the user wants to change the PDF
  const [isChangingPDF, setIsChangingPDF] = useState(false)

  useEffect(() => {
    if (params?.chapterId) {
      dispatch(onGetChapterDetail(params?.chapterId))
    }
    return () => {
      setIsLoading(true)
      dispatch(onResetChapter())
    }
  }, [])

  useEffect(() => {
    if (Object.keys(chapterDetail).length) {
      setIsLoading(false)
      const url =
        chapterDetail.document && !isChangingPDF
          ? staticURL + JSON.parse(chapterDetail.document)[0]
          : ""
      setPdfContnent(url)
    }
  }, [chapterDetail])

  const chapterValidation = useFormik({
    enableReinitialize: true,
    initialValues: { name: chapterDetail?.name, document: [] },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Module Name"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (chapterDetail?._id) {
        setIsLoading(true)
        values.document = PdfContnent === "" ? "[]" : values.document
        dispatch(onUpdateChapter(values, chapterDetail?._id, navigate))
        setIsChangingPDF(false)
      }
      resetForm()
    },
  })

  // const { setFieldValue } = chapterValidation;

  // const converFileName = (filePathJson) => {
  //     const filePathArray = JSON.parse(filePathJson);
  //     const fileName = filePathArray.map((filePath) =>
  //         filePath.substring(filePath.lastIndexOf("/") + 1)
  //     );
  //     return fileName
  // }

  // const removePdf = () => {
  //     setIsChangingPDF(true)
  //     setPdfContnent("")
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading ? (
          <AppLoader />
        ) : (
          <>
            <Breadcrumbs
              title="Chapter"
              navigate={-1}
              breadcrumbItem={chapterDetail?.name ? chapterDetail?.name : ""}
            />
            <Container fluid>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      chapterValidation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <Label htmlFor="name">
                            Module name
                            <span className="required_star">*</span>
                          </Label>
                          <Input
                            name="name"
                            type="text"
                            className="form-control custom_form_control"
                            id="name"
                            placeholder="Enter Module Name"
                            onChange={chapterValidation.handleChange}
                            onBlur={chapterValidation.handleBlur}
                            value={chapterValidation.values.name || ""}
                            invalid={
                              chapterValidation.touched.name &&
                              chapterValidation.errors.name
                                ? true
                                : false
                            }
                          />
                          {chapterValidation.touched.name &&
                          chapterValidation.errors.name ? (
                            <FormFeedback type="invalid">
                              {chapterValidation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                                                <div className="mb-3">
                                                    <Label htmlFor="name">
                                                        Module PDF
                                                        <span className="required_star">*</span>
                                                    </Label>
                                                    {chapterDetail.document && JSON.parse(chapterDetail.document).length > 0 && !isChangingPDF && PdfContnent ? (
                                                        <div className="d-flex align-items-center shadow-sm border border-2 border-secondary-5">
                                                            <iframe
                                                                src={PdfContnent?PdfContnent:""}
                                                                height={80}
                                                                width={100}
                                                            />
                                                            <div className="ms-4">
                                                                <p className="text-muted font-weight-bold">
                                                                    {converFileName(chapterDetail.document)[0]}
                                                                </p>
                                                                <Link
                                                                    className="bg-danger p-1"
                                                                    style={{ fontSize: "12px", color: "#fff", borderRadius: "5px" }}
                                                                    to={staticURL + JSON.parse(chapterDetail.document)[0]}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <i className="bx bx-file font-size-16 align-middle me-1"></i>
                                                                    View PDF
                                                                </Link>
                                                                <button
                                                                    className="bg-primary p-1 ms-2"
                                                                    style={{ fontSize: "12px", color: "#fff", borderRadius: "5px", border: "none" }}
                                                                    onClick={removePdf}
                                                                >
                                                                    <i className="bx bx-file font-size-16 align-middle me-1"></i>
                                                                    Change & Remove PDF
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <input
                                                            name="document"
                                                            type="file"
                                                            className="form-control custom_input custom_form_control"
                                                            id="document"
                                                            placeholder="Enter Course Name"
                                                            multiple
                                                            onChange={(event) => {
                                                                const files = Array.from(event.target.files);
                                                                setFieldValue("document", files);
                                                                setPdfContnent(files)
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div> */}
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex">
                          <Button
                            type="submit"
                            color="primary"
                            className="me-3 global_btn"
                          >
                            {isChangingPDF ? "Update PDF" : "Update Chapter"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Container>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default EditChapter
