/* REVIEWS */
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAIL = "GET_REVIEWS_FAIL";
export const RESET_REVIEWS = "RESET_REVIEWS";

/* REVIEW DETAIL*/
export const GET_REVIEW_DETAIL = "GET_REVIEW_DETAIL";
export const GET_REVIEW_DETAIL_SUCCESS = "GET_REVIEW_DETAIL_SUCCESS";
export const GET_REVIEW_DETAIL_FAIL = "GET_REVIEW_DETAIL_FAIL";

/**
 * add review
 */
export const ADD_NEW_REVIEW = "ADD_NEW_REVIEW";
export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS";
export const ADD_REVIEW_FAIL = "ADD_REVIEW_FAIL";

/**
 * Edit review
 */
export const UPDATE_REVIEW = "UPDATE_REVIEW";
export const UPDATE_REVIEW_SUCCESS = "UPDATE_REVIEW_SUCCESS";
export const UPDATE_REVIEW_FAIL = "UPDATE_REVIEW_FAIL";

/**
 * Delete review
 */
export const DELETE_REVIEW = "DELETE_REVIEW";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";

export const UPDATE_REVIEW_LOADING_STATE = "UPDATE_REVIEW_LOADING_STATE";
