/**
 * Add Flash Card
 */
 export const ADD_FLASH_CARD = "ADD_FLASH_CARD"
 export const ADD_FLASH_CARD_SUCCESS = "ADD_FLASH_CARD_SUCCESS"
 export const ADD_FLASH_CARD_FAIL = "ADD_FLASH_CARD_FAIL"

/* Flash Card */
export const GET_FLASH_CARD = "GET_FLASH_CARD"
export const GET_FLASH_CARD_SUCCESS = "GET_FLASH_CARD_SUCCESS"
export const GET_FLASH_CARD_FAIL = "GET_FLASH_CARD_FAIL"

/**
 * EDit Flash Card
 */
 export const UPDATE_FLASH_CARD = "UPDATE_FLASH_CARD"
 export const UPDATE_FLASH_CARD_SUCCESS = "UPDATE_FLASH_CARD_SUCCESS"
 export const UPDATE_FLASH_CARD_FAIL = "UPDATE_FLASH_CARD_FAIL"

/**
 * Delete Flash Card
 */
export const DELETE_FLASH_CARD = "DELETE_FLASH_CARD"
export const DELETE_FLASH_CARD_SUCCESS = "DELETE_FLASH_CARD_SUCCESS"
export const DELETE_FLASH_CARD_FAIL = "DELETE_FLASH_CARD_FAIL"

export const UPDATE_FLASH_CARD_LOADING_STATE = "UPDATE_FLASH_CARD_LOADING_STATE"
export const RESET_FLASH_CARD = "RESET_FLASH_CARD"


