import {
  GET_FLASH_CARD_FAIL,
  GET_FLASH_CARD_SUCCESS,
  ADD_FLASH_CARD_SUCCESS,
  ADD_FLASH_CARD_FAIL,
  UPDATE_FLASH_CARD_SUCCESS,
  UPDATE_FLASH_CARD_FAIL,
  DELETE_FLASH_CARD_SUCCESS,
  DELETE_FLASH_CARD_FAIL,
  UPDATE_FLASH_CARD_LOADING_STATE,
  RESET_FLASH_CARD,
} from "./actionTypes"

const INIT_STATE = {
  flashCards: [],
  flashCardDetail: {},
  error: {},
  loading: false,
}

const flashCards = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FLASH_CARD_SUCCESS:
      return {
        ...state,
        flashCards: action.payload,
      }
    case GET_FLASH_CARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_FLASH_CARD_SUCCESS: {
      return {
        ...state,
        // flashCards: [...state.flashCards,...action.payload],
        flashCardDetail:action.payload
      }
    }

    case ADD_FLASH_CARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_FLASH_CARD_SUCCESS:
      return {
        ...state,
        flashCards: state.flashCards.map(flashCard =>
          flashCard._id.toString() === action.payload._id.toString()
            ? { flashCard, ...action.payload }
            : flashCard
        ),
      }

    case UPDATE_FLASH_CARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_FLASH_CARD_SUCCESS:
      return {
        ...state,
        flashCards: state.flashCards.filter(
          flashCard => flashCard?._id.toString() !== action.payload?.flashCardId.toString()
        ),
      }

    case DELETE_FLASH_CARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_FLASH_CARD_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }
    case RESET_FLASH_CARD:
      return {
        ...state,
        flashCards: [],
        flashCardDetail: {},
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default flashCards
