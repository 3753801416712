import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Col, Container, Row, Spinner } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import AppLoader from "../../components/Common/Loader"
import TablePagination from "../../components/Common/Table/TablePagination"
import ListItem from "./ListItem"
import NotFound from "../../components/Common/NotFound"

import {
  getApplicants as onGetApplicants,
  updateApplicants as onUpdateApplicants,
  resetApplicants as onResetApplicants,
  getCompanyCredit as onGetCompanyCredit,
} from "../../store/actions"

const ApplicantsList = () => {
  document.title = "Applicant List | QAPRENEUR"
  const params = useParams()
  const dispatch = useDispatch()
  const [candidateList, setCandidateList] = useState([])
  const [pageNo, setPageNo] = useState(0)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const role = localStorage.getItem("authRole")

  const { loading, applicants } = useSelector(state => ({
    loading: state.applicants.loading,
    applicants: state.applicants.applicants,
  }))

  useEffect(() => {
    if (params.companyId) {
      dispatch(onGetCompanyCredit(params.companyId))
    }
  }, [])

  useEffect(() => {
    if (params.id) {
      const queryParams = {
        page: pageNo + 1,
        limit: rowPerPage,
        companyId: params.companyId,
      }
      if (search && search.length > 0) {
        queryParams.search = search
      }
      dispatch(onGetApplicants(params.id, queryParams))
    }
    return () => {
      dispatch(onResetApplicants())
    }
  }, [onGetApplicants, pageNo, rowPerPage, search])

  useEffect(() => {
    if (Array.isArray(applicants)) {
      setCandidateList(
        applicants
          .map(item => ({
            ...item?.applicant,
            applicantStatus: item?.status,
            jobId: item?.jobId,
            studentId: item?.studentId,
            userMockTestReviews: item?.userMockTestReviews,
            userMockTestMarks: item?.userMockTestMarks,
            isUnlock: item?.isUnlock,
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setCandidateList([])
    }
  }, [applicants, pageNo])

  const handleDropDown = (e, data) => {
    data.applicantStatus = e.target.value
    // setCandidateList([])
    const obj = {
      jobId: data.jobId,
      studentId: data.studentId,
      status: e.target.value,
    }
    dispatch(
      onUpdateApplicants(obj, {
        page: pageNo,
        limit: rowPerPage,
        companyId: params.companyId,
      })
    )
  }

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const handlePagination = type => {
    if (type === "NEXT") {
      setPageNo(pageNo + 1)
      setCandidateList([])
    } else if (type === "PREV") {
      setPageNo(pageNo - 1)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Breadcrumbs
                title="Jobs"
                navigate="/jobs-list"
                breadcrumbItem="Applicants"
              />
              <div>
                {loading && <AppLoader />}
                {!loading &&
                  candidateList &&
                  candidateList.map((candidate, index) => (
                    <ListItem
                      key={index}
                      applicant={candidate}
                      handleDropDown={handleDropDown}
                      link="/profile/view/applicant-details"
                      showskills={role == "company" || role == "hr_user"}
                      // showLockProfile={role !== "admin"}
                    />
                  ))}
                {!loading && !candidateList.length && (
                  <>
                    <div className="text-center my-3">
                      <div className="text-success">No Data Available</div>
                    </div>
                  </>
                )}
                {!loading && !candidateList.length && <NotFound />}
                <TablePagination
                  data={candidateList}
                  pageSize={rowPerPage}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ApplicantsList
