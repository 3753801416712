import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getUnlockCandidates as onGetUnlockCandidates } from "../../store/actions"
import TableContainer from "../../components/Common/Table/index"
import { unlockedCandidatesColumns } from "./constants"

function UnlockApplicants() {
  document.title = "Candidate List | QAPRENEUR"
  const params = useParams()
  const dispatch = useDispatch()
  const [candidateList, setCandidateList] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const authRole = localStorage.getItem("authRole")
  const authUser = localStorage.getItem("authUser")
  const [locationFilter, setLocationFilter] = useState({
    city: "",
    state: "",
    skills: "",
    experience: "",
  })

  const { loading, unlockCandidates } = useSelector(state => ({
    loading: state.applicants.loading,
    unlockCandidates: state.applicants.unlockCandidates,
  }))

  useEffect(() => {
    loadData()
  }, [
    pageNo,
    rowPerPage,
    search,
    locationFilter.state,
    locationFilter.city,
    locationFilter.degree,
    locationFilter.skills,
    locationFilter.experience,
  ])

  useEffect(() => {
    if (Array.isArray(unlockCandidates)) {
      setCandidateList(
        unlockCandidates
          .map(unlockCandidates => ({
            ...unlockCandidates,
            unlockedByName: unlockCandidates?.unlockedBy
              ? unlockCandidates?.unlockedBy?.name
              : "",
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setCandidateList([])
    }
  }, [unlockCandidates, pageNo])

  const loadData = () => {
    const user = authUser && JSON.parse(authUser)
    if (params.id) {
      const queryParams = {
        page: pageNo,
        limit: rowPerPage,
      }
      if (authRole === "hr_user") {
        queryParams.postedBy = user._id
      }
      if (search && search.length > 0) {
        queryParams.search = search
      }
      if (locationFilter && locationFilter.state) {
        queryParams.state = locationFilter.state
      }
      if (locationFilter && locationFilter.city) {
        queryParams.city = locationFilter.city
      }
      if (locationFilter && locationFilter.degree) {
        queryParams.degree = locationFilter.degree
      }
      if (locationFilter && locationFilter.experience) {
        queryParams.experience = locationFilter.experience
      }
      if (locationFilter && locationFilter.skills) {
        queryParams.skills = locationFilter.skills
      }
      if (authRole === "hr_user") {
        dispatch(onGetUnlockCandidates(user.companyId, queryParams))
      } else {
        dispatch(onGetUnlockCandidates(params.id, queryParams))
      }
    }
  }

  const onChangePagination = (limit, page) => {
    setRowPerPage(limit)
    if (page === 0) {
      setPageNo(1)
    } else {
      setPageNo(page)
    }
    setCandidateList([])
  }

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Breadcrumbs
                title="Dashboard"
                navigate="/dashboard"
                breadcrumbItem="Unlock Candidates"
              />
              <TableContainer
                loading={loading}
                data={candidateList}
                isGlobalFilter={true}
                isAddOptions={false}
                isJobListGlobalFilter={false}
                customPageSize={rowPerPage}
                className="project-list-table table-nowrap align-middle table-borderless"
                columns={unlockedCandidatesColumns}
                showPagination={true}
                onChangePagination={onChangePagination}
                showPageDropdown={true}
                notDataFound={true}
                handleSearch={handleSearch}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UnlockApplicants
