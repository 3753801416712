import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Button, Container, Spinner, Label } from "reactstrap"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  getChapterDetail as onGetChapterDetail,
  resetChapter as onResetChapter,
  getChapterContent as onGetChapterContent,
  resetChapterContent as onResetChapterContent,
  deleteChapterContent as onDeleteChapterContent,
  updateChapterContent as onUpdateChapterContent,
} from "../../store/actions"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import AppTabs from "../../components/Common/Tabs/index"
import AppForm from "../../components/Common/Form/index"
import CardAbout from "../../components/Common/Card/CardAbout"
import MultiMedia from "../MediaManagement/Media"
const staticURL = process.env.REACT_APP_STATIC_URL

function ChapterContent() {
  document.title = `QAPRENEUR`
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [modalBackdrop, setModalBackdrop] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [contentDetail, setContentDetail] = useState({})
  const [contentId, setContentId] = useState("")
  const [chapterModal, setChapterModal] = useState(false)
  const [mediaModal, setMediaModal] = useState(false)
  const chapterHtmlFormik = useRef(null)
  const chapterVideoFormik = useRef(null)
  const [pdfUrl, setPdfUrl] = useState("")

  const { chapterDetail, chapterContent, loading } = useSelector(state => ({
    chapterDetail: state.chapters.chapterDetail,
    chapterContent: state.chapterContents.chapterContent,
    loading: state.chapterContents.loading,
  }))

  useEffect(() => {
    if (params?.chapterId) {
      dispatch(onGetChapterDetail(params?.chapterId))
      dispatch(onGetChapterContent(params?.chapterId))
    }
    return () => {
      setIsLoading(true)
      dispatch(onResetChapter())
      dispatch(onResetChapterContent())
    }
  }, [])

  useEffect(() => {
    if (Object.keys(chapterDetail).length) {
      setIsLoading(false)
      setContentDetail(chapterContent.length > 0 ? chapterContent[0] : {})
      chapterContent.length && converFileName(chapterContent[0]?.pdfContent)
    }
  }, [chapterDetail])

  const modalAction = () => {
    dispatch(onDeleteChapterContent(contentId, navigate))
    setModalBackdrop(false)
    navigate(-1)
  }

  const chapterModalToggle = () => setChapterModal(!chapterModal)

  const handleContentFormSave = () => {
    const contentData = {
      ...contentDetail,
      ...chapterHtmlFormik?.current?.values,
      ...chapterVideoFormik?.current?.values,
    }
    dispatch(
      onUpdateChapterContent(
        {
          ...contentData,
          pdfContent:
            contentData?.pdfContent ? contentData?.pdfContent
              : chapterContent?.pdfContent,
        },
        contentId,
        navigate
      )
    )
    chapterModalToggle()
  }

  const getSubscribValueFromToFormik = formik => {
    if (Object.keys(formik.values).includes("htmlContent")) {
      chapterHtmlFormik.current = formik
    }
    if (Object.keys(formik.values).includes("videoUrl")) {
      chapterVideoFormik.current = formik
    }
  }

  const mediaModalToggle = () => setMediaModal(!mediaModal)

  const converFileName = filePathJson => {
    const filePathArray = JSON.parse(filePathJson)
    const fileName = filePathArray.map(filePath =>
      filePath.substring(filePath.lastIndexOf("/") + 1)
    )
    setPdfUrl(staticURL + filePathArray[0])
    return staticURL + filePathArray[0]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner className="ms-2" color="dark" size="sm" />
          </div>
        ) : (
          <>
            <Container fluid>
              <Row>
                <Col>
                  <h4>{chapterDetail.name}</h4>
                </Col>
                <Col>
                  {chapterContent && chapterContent.length > 0 && (
                    <div className="d-flex justify-content-end mb-3">
                      <Button
                        onClick={() => {
                          chapterModalToggle()
                          setContentId(chapterContent[0]._id)
                          setContentDetail(prev => ({
                            ...prev,
                            htmlContent: chapterContent[0]?.htmlContent,
                            videoUrl: chapterContent[0]?.videoUrl,
                          }))
                        }}
                        className="btn btn-primary global_btn me-1"
                      >
                        <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
                        Edit Content
                      </Button>
                      <Button
                        onClick={() => {
                          setModalBackdrop(true)
                          setContentId(chapterContent[0]._id)
                        }}
                        className="btn btn-danger me-1"
                      >
                        <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                        Delete Content
                      </Button>
                      <Button
                        onClick={() =>
                          navigate(`/courses-edit/${params.courseId}`)
                        }
                        className="btn btn-primary global_btn me-1"
                      >
                        <i
                          className="mdi mdi-arrow-left  me-2"
                          id="edittooltip"
                        />
                        Back
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              {chapterContent &&
                chapterContent.length > 0 &&
                chapterContent.map((item, key) => {
                  return (
                    <div key={key}>
                      <CardAbout
                        title={chapterDetail.name}
                        descripion={item?.htmlContent}
                        videoUrl={item?.videoUrl}
                        isHtml={true}
                        isPdf={item?.pdfContent.length > 0 ? true : false}
                        pdfUrl={item?.pdfContent}
                      />
                    </div>
                  )
                })}
              {modalBackdrop && (
                <ConfirmationModal
                  modalBackdrop={modalBackdrop}
                  setmodalBackdrop={setModalBackdrop}
                  modalTitle={"Are you sure want to delete this Content?"}
                  modalAction={modalAction}
                  loading={isLoading}
                />
              )}
              {chapterModal && (
                <InfomationModal
                  modalBackdrop={chapterModal}
                  setmodalBackdrop={setChapterModal}
                  title={"Edit Chapter Content"}
                  size="lg"
                  height={400}
                  isFooter={true}
                  handleAction={handleContentFormSave}
                >
                  <Row>
                    <b>Content Type</b>
                    <AppTabs
                      tabsArray={[
                        {
                          id: "tab1",
                          label: "HTML",
                          content: (
                            <Col sm="12">
                              <AppForm
                                name=""
                                layoutType={"EDIT"}
                                fields={[
                                  {
                                    label: "Chapter Content",
                                    name: "htmlContent",
                                    type: "textEditor",
                                    required: true,
                                    lg: 12,
                                  },
                                ]}
                                initialValues={{
                                  htmlContent: contentDetail.htmlContent,
                                }}
                                getValues={getSubscribValueFromToFormik}
                                // handleFormSubmit={handleContentFormSave}
                                showCancelButton={false}
                              />
                            </Col>
                          ),
                        },
                        {
                          id: "tab2",
                          label: "Video",
                          content: (
                            <Col sm="12">
                              <AppForm
                                name=""
                                layoutType={"CREATE"}
                                fields={[
                                  {
                                    label: "Chapter Video",
                                    name: "videoUrl",
                                    type: "url",
                                    required: true,
                                    lg: 12,
                                  },
                                ]}
                                initialValues={{
                                  videoUrl: contentDetail.videoUrl,
                                }}
                                getValues={getSubscribValueFromToFormik}
                                showCancelButton={false}
                              />
                            </Col>
                          ),
                        },
                        {
                          id: "tab3",
                          label: "PDF",
                          content: (
                            <Col sm="12">
                              <div className="mb-3">
                                <Label htmlFor="name">
                                  Upload PDF
                                  <span className="required_star">*</span>
                                </Label>
                                <input
                                  name="pdfContent"
                                  type="file"
                                  className="form-control custom_input custom_form_control"
                                  id="document"
                                  multiple
                                  onChange={event => {
                                    const files = Array.from(event.target.files)
                                    setContentDetail(prev => ({
                                      ...prev,
                                      pdfContent: files,
                                    }))
                                  }}
                                />
                              </div>
                            </Col>
                          ),
                        },
                        {
                          id: "tab4",
                          label: "Media",
                          content: (
                            <Col sm="12">
                              <MultiMedia
                                mediaModalToggle={mediaModalToggle}
                                showModal={mediaModal}
                                setShowModal={setMediaModal}
                                showDelete={false}
                              />
                            </Col>
                          ),
                        },
                      ]}
                      contentBgColor="#fff"
                    />
                  </Row>
                </InfomationModal>
              )}
            </Container>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default ChapterContent
