export const columns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
    link: "/test-details",
  },
  {
    header: "Name",
    name: "name",
    key: "NAME_DESCRIPTION",
    link: "/test-details",
  },
  {
    header: "Duration(In minutes)",
    name: "duration",
    key: "DEFAULT",
    link: "/test-details",
  },
  {
    header: "Question Count",
    name: "questionCount",
    key: "DEFAULT",
    link: "/test-details",
  },
  {
    header: "Categories",
    name: "testCategories",
    key: "CATEGORY_INFO",
    // link: "/test-details",
  },
  {
    header: "Test Type",
    name: "type",
    key: "DEFAULT",
    link: "/test-details",
  },
  {
    header: "Price",
    name: "price",
    key: "AMOUNT",
    link: "/test-details",
  },
  {
    header: "Discount (%)",
    name: "discount",
    key: "DEFAULT",
    link: "/test-details",
  },
  {
    header: "Discounted Price",
    name: "discountPrice",
    key: "AMOUNT",
    link: "/test-details",
  },
  // {
  //     header: "Created Date",
  //     name: "createdAt",
  //     key: "DATE",
  //     link: "/test-details"
  // },
  // {
  //     header: "Status",
  //     name: "status",
  //     key: "STATUS",
  // },
  {
    header: "Active/Deactive",
    key: "SWITCH",
  },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Edit", type: "EDIT" },
      { label: "View", type: "VIEW" },
      { label: "Delete", type: "DELETE" },
      { label: "View Report", type: "VIEW_STUDENT_LIST" },
    ],
  },
]

export const testResultColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Question",
    name: "question",
    key: "DEFAULT",
  },
  {
    header: "Correct Answer",
    name: "correctAnswer",
    key: "DEFAULT",
  },
  {
    header: "User's Answer",
    name: "userAnswer",
    key: "DEFAULT",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
]

export const purchasedTestUsersColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Test Attempts Count",
    name: "userTestAttempt",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Total Question",
    name: "totalQuestion",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Correct AnswerCount",
    name: "correctAnswer",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Wrong AnswerCount",
    name: "wrongAnswer",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Attempt Question Count",
    name: "questionAttemptedCount",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Score (%)",
    name: "score",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Status",
    name: "result",
    key: "STATUS",
  },
]

const currentOptions = [{ label: `0%`, value: 0 }]
const additionalOptions = Array.from({ length: 20 }, (_, index) => ({
  label: `${(index + 1) * 5}%`,
  value: (index + 1) * 5,
}))

export const discountOptions = [...currentOptions, ...additionalOptions]
