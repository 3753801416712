export const columns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Objective",
    name: "objective",
    key: "DEFAULT",
  },
  // {
  //   header: "Description",
  //   name: "description",
  //   key: "DESCRIPTION",
  // },
  {
    header: "Category",
    name: "category",
    key: "DEFAULT",
  },
  {
    header: "Type",
    name: "type",
    key: "DEFAULT",
  },
  {
    header: "Start Date",
    name: "startDate",
    key: "DATE",
  },
  {
    header: "End Date",
    name: "endDate",
    key: "DATE",
  },
  //   {
  //   header: "Start Time",
  //   name: "startTime",
  //   type: "DEFAULT",
  // },
  {
    header: "Active/Deactive",
    key: "SWITCH",
    name: "status",
  },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Edit", type: "EDIT" },
      { label: "Delete", type: "DELETE" },
      { label: "View Report", type: "VIEW_REPORT" },
    ],
  },
]

export const reportColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
    link: "/student-details",
  },
  {
    header: "User name",
    name: "name",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/student-details",
  },
  {
    header: "Phone",
    name: "phone",
    key: "DEFAULT",
    link: "/student-details",
  },
]

export const formFields = [
  {
    label: "Description*",
    name: "description",
    type: "textEditor",
    required: true,
    lg: 12,
  },
  {
    label: "Objective*",
    name: "objective",
    type: "textarea",
    required: true,
    lg: 12,
  },
  {
    label: "Start Date*",
    name: "startDate",
    type: "date",
    required: true,
  },
  {
    label: "End Date*",
    name: "endDate",
    type: "date",
    required: true,
  },
  {
    label: "Start Time*",
    name: "startTime",
    type: "time",
    required: true,
  },
  {
    label: "Announcement Type",
    name: "type",
    type: "checkBoxes",
    required: true,
    checkBoxes: [
      { value: "normal", label: "Normal" },
      { value: "report", label: "Report" },
      //   { value: "on-demand", label: "On Demand" },
    ],
  },
  {
    label: "Category*",
    name: "category",
    type: "select",
    options: [
      { value: "", label: "Select category" },
      { value: "Exam", label: "Exam" },
      { value: "Test", label: "Test" },
      { value: "Job", label: "Job" },
      { value: "Course", label: "Course" },
      { value: "Webinar", label: "Webinar" },
      { value: "Offer", label: "Offer" },
    ],
    required: true,
  },
  {
    label: "Status*",
    name: "status",
    type: "select",
    options: [
      { value: "", label: "Select Status" },
      { value: "active", label: "Active" },
      { value: "deactive", label: "Deactive" },
    ],
    required: true,
  },
]
