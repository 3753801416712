import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import title_bottom_shape from "../../assets/images/home/title_bottom_shape.svg"
import arrow_right from "../../assets/images/home/arrow_right.svg"
import testimonial_shap from "../../assets/images/home/testimonial_shap.png"
import quotes_circle from "../../assets/images/home/quotes_circle.svg"
import star from "../../assets/images/home/star.svg"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Avatar from "../../assets/images/users/img_placeholder.png"
import moment from 'moment';

import { getReviews as onGetReviews } from "../../store/actions"

let settings = {
  dots: true,
  infinite: true,
  centerMode: true,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: "25%",
  autoplay: true,
  autoplaySpeed: 3000,
}

if (window.innerWidth < 768) {
  settings.centerPadding = "0"
}
function QaReview() {
  const staticURL = process.env.REACT_APP_STATIC_URL
  const dispatch = useDispatch()

  const { reviews } = useSelector(state => ({
    reviews: state.reviews?.reviews,
  }))

  useEffect(() => {
    dispatch(onGetReviews())
  }, [dispatch])

  return (
    <div className="testimonials py-5">
      <div className="home_container">
        <div className="testimonials_inner">
          <div className="row">
            <div className="col-lg-4">
              <div className="testimonials_header">
                <h5 className="small_heading_title">Testimonials</h5>
                <h2 className="section_heading">What Our Students Say</h2>
                <img
                  alt={"qapreneur"}
                  src={title_bottom_shape}
                  height="15"
                  width="103"
                />
                <p className="testi_desc mt-3">
                  Still not convinced? Read out the reviews of our placed
                  students, and their experience of studying here, in their own
                  words.
                </p>

                <Link
                  to={`https://play.google.com/store/apps/details?id=com.qapreneur.app&pli=1`}
                  className="btn btn-primary button mt-4"
                  target="_blank"
                >
                  View All &nbsp;
                  <img
                    alt={"qapreneur"}
                    src={arrow_right}
                    width="19"
                    height="15"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-8">
              <Slider {...settings}>
                {reviews &&
                  reviews.map((review, key) => {
                    return (
                      <div className="p-3" key={key}>
                        <div className="testi_container position-relative">
                          <div className="testi_shape">
                            <img
                              src={testimonial_shap}
                              width="123"
                              height="121"
                            />
                          </div>
                          <div className="position-relative">
                            <div className="testi_user_img my-3">
                              <img
                                alt={"qapreneur"}
                                src={
                                  review?.image
                                    ? staticURL + review?.image
                                    : Avatar
                                }
                                width="70"
                                height="70"
                              />
                            </div>
                            <div className="quotes_circle">
                              <img
                                alt={"qapreneur"}
                                src={quotes_circle}
                                width="28"
                                height="25"
                              />
                            </div>
                          </div>
                          <div>
                            <div
                              className="testi_desc"
                              dangerouslySetInnerHTML={{
                                __html: review?.description,
                              }}
                            ></div>
                            <div className="user_ratings d-flex align-items-center justify-content-start gap-1 mb-3">
                              {Array(review?.rating ? review?.rating : 5)
                                .fill()
                                .map((el, k) => {
                                  return (
                                    <img
                                      key={k}
                                      alt={"qapreneur"}
                                      src={star}
                                      width="17"
                                      height="14"
                                    />
                                  )
                                })}
                            </div>
                            <h3 className="user_name">{review?.userName}</h3>
                            <h3 className="user_name">Posted At {moment(review?.postedAt).format('MMMM Do YYYY')}</h3>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QaReview
