/* STUDENTS */
export const GET_HR_USERS = "GET_HR_USERS"
export const GET_HR_USERS_SUCCESS = "GET_HR_USERS_SUCCESS"
export const GET_HR_USERS_FAIL = "GET_HR_USERS_FAIL"

/* user DETAIL*/
export const GET_HR_USERS_DETAIL = "GET_HR_USERS_DETAIL"
export const GET_HR_USERS_DETAIL_SUCCESS = "GET_HR_USERS_DETAIL_SUCCESS"
export const GET_HR_USERS_DETAIL_FAIL = "GET_HR_USERS_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_NEW_HR_USER = "ADD_NEW_HR_USER"
export const ADD_HR_USER_SUCCESS = "ADD_HR_USER_SUCCESS"
export const ADD_HR_USER_FAIL = "ADD_STUDENT_FAIL"

/**
 * update user
 */
export const UPDATE_HR_USER = "UPDATE_HR_USER"
export const UPDATE_HR_USER_SUCCESS = "UPDATE_HR_USER_SUCCESS"
export const UPDATE_HR_USER_FAIL = "UPDATE_STUDENT_FAIL"

export const UPDATE_HR_USER_LOADING_STATE = "UPDATE_HR_USER_LOADING_STATE"
