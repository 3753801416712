import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import {
  getSkills as onGetSkills,
  getUserSkills as onGetUserSkills,
  addNewSkill as onAddNewSkill,
  updateSkill as onUpdateSkill,
  deleteSkill as onGetDeleteSkill,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import AppTable from "../../components/Common/Table/index"
import FormModal from "../../components/Common/Modal/FormModal"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import { columns, formFields } from "./constants"
import { isEmpty } from "lodash"

function skillConfigure() {
  document.title = `Skills | QAPRENEUR`
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [formModal, setFormModal] = useState(false)
  const [skillDetail, setSkillDetail] = useState({ name: "" })
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [skillList, setSkillList] = useState([])

  const toggle = () => setFormModal(!formModal)

  const { skills, loading } = useSelector(state => ({
    skills: state.skills.skills,
    loading: state.skills.loading,
  }))

  useEffect(() => {
    getUserSkills()
  }, [dispatch, pageNo, rowPerPage, search])

  const getUserSkills = () => {
    const user = localStorage.getItem("authUser")
    if (user) {
      const params = {
        page: pageNo,
        limit: rowPerPage,
      }
      if (search && search.length > 0) {
        params.search = search;
      }
      const userId = JSON.parse(user)?._id
      dispatch(onGetUserSkills(userId, params))
    }
  }

  useEffect(() => {
    if (!isEmpty(skills)) {
      setSkillList(
        skills.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setSkillList([])
    }
  }, [skills, pageNo])

  const handleSave = values => {
    if (isEdit) {
      dispatch(onUpdateSkill(values, skillDetail._id))
    } else {
      dispatch(onAddNewSkill(values))
    }
    toggle()
  }

  const handleActions = (_skill, type) => {
    setSkillDetail(_skill)
    if (type === "EDIT") {
      setIsEdit(true)
    } else if (type === "CREATE") {
      setIsEdit(false)
    } else if (type === "DELETE") {
      setDeleteModal(true)
    }
    type !== "DELETE" && toggle()
  }

  const handleChangeStatus = skillData => {
    const status = skillData.status === "active" ? "deactive" : "active"
    dispatch(onUpdateSkill({ status }, skillData._id))
  }

  const handleDeleteSkill = () => {
    if (skillDetail && skillDetail._id) {
      dispatch(onGetDeleteSkill(skillDetail._id))
    }
    setDeleteModal(false)
  }

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  const handleSearch = (query) => {
    setPageNo(1)
    setSearch(query)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Skills" />
          <Row>
            <Col lg="12">
              <div>
                <AppTable
                  loading={loading}
                  data={skillList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={rowPerPage}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  handleActions={handleActions}
                  handleStatus={handleChangeStatus}
                  onChangePagination={onChangePagination}
                  handleSearch={handleSearch}
                  showPagination={true}
                  rowLink={"/"}
                  addButton={{
                    onclick: () => handleActions({ name: "" }, "CREATE"),
                    title: "Add Skill",
                  }}
                  columns={columns}
                />
                {formModal && (
                  <FormModal
                    modalBackdrop={formModal}
                    setmodalBackdrop={setFormModal}
                    modalAction={handleSave}
                    title={!!isEdit ? "Edit Skill" : "Add Skill"}
                    formFields={formFields}
                    data={skillDetail}
                  />
                )}
                {deleteModal && (
                  <ConfirmationModal
                    modalBackdrop={deleteModal}
                    setmodalBackdrop={setDeleteModal}
                    modalTitle={
                      "Are you sure want to permanently delete the Skill ?."
                    }
                    modalAction={handleDeleteSkill}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default skillConfigure
