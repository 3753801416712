import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import avatar from "../../assets/images/users/avatar-1.jpg"
import Breadcrumb from "../../components/Common/Breadcrumb"
import CardProfile from "../../components/Common/Card/ProfileHeader"
import CardInfo from "../../components/Common/Card/CardInfo"
import CardAbout from "../../components/Common/Card/CardAbout"
import CardSkills from "../../components/Common/Card/CardSkills"
import CardResume from "../../components/Common/Card/CardResume"
import CardCertificate from "../../components/Common/Card/CardCertificate"
import CardEmploymentHistory from "../../components/Common/Card/CardEmploymentHistory"
import CardEducationalDetails from "../../components/Common/Card/CardEducationalDetails"
import Certificate from "../../components/Common/Certificate"
import TableContainer from "../../components/Common/Table/index"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import toastr from "toastr"
import AppLoader from "../../components/Common/Loader"
import UserDetailsTab from "../../components/Tabs/UserDetailsTab"

import StudentTestResults from "../StudentManagement/StudentTestResults"
import StudentCourseResults from "../StudentManagement/StudentCourseResults"
import MockInterViewResults from "../StudentManagement/MockInterViewResults"

//redux
import {
  resetStudents as onResetStudents,
  resetTestResult as onResetTestResult,
  getUserTabData as onGetUserTabData,
} from "../../store/students/actions"
import { mockTestRequestsColumns } from "../StudentManagement/constants"
import {
  courseColumns,
  testColumns,
  userProfileFrame,
  calculateCompletionPercentage,
  getUserFullName,
} from "../StudentManagement/Utills"
const staticURL = process.env.REACT_APP_STATIC_URL

const ViewCandidate = () => {
  //meta title
  document.title = "Student Detail | Skote - React Admin & Dashboard Template"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  params.profileTab = "PERSONALDETAILS"
  const [userData, setUserData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [modal, setModal] = useState(false)
  const [certificateCourse, setCertificateCourse] = useState(null)

  const {
    personalDetail,
    careerObjective,
    educational,
    employment,
    skills,
    loading,
    resume,
    certificate,
    testResults,
    userCourses,
    userMockTestRequests,
  } = useSelector(state => ({
    ...state.students,
  }))

  useEffect(() => {
    if (params && params.candidateId) {
      dispatch(onGetUserTabData("PERSONALDETAILS", params.candidateId))
    }
    return () => {
      dispatch(onResetStudents())
      dispatch(onResetTestResult())
      setIsLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    if (personalDetail && Object.keys(personalDetail).length) {
      setUserData({ ...personalDetail })
      setIsLoading(false)
    }
  }, [personalDetail])

  const handleActions = (data, type) => {
    if (type === "CERTIFICATE" && data.completedChapterPer.includes("100")) {
      setCertificateCourse(data)
      setModal(true)
    } else if (type === "CERTIFICATE") {
      toastr.info("This User Not Completed 100% of this course")
    }
  }

  const courseColumn = courseColumns("company")

  const total = personalDetail?.userMockTestMarks || 0

  const tabsArray = [
    {
      id: "PERSONALDETAILS",
      label: "Personal Details",
      content: (
        <Col sm="12">
          <UserDetailsTab
            title="Personal Details"
            data={personalDetail}
            role="student"
            loading={loading}
          />
        </Col>
      ),
    },
    {
      id: "CAREER_OBJECTIVE",
      label: "Career Objective",
      content: (
        <Col sm="12">
          <CardAbout
            title="Career Objective"
            descripion={careerObjective}
            loading={loading}
          />
        </Col>
      ),
    },
    {
      id: "EDUCATIONDETAILS",
      label: "Educational Details",
      content: (
        <Col lg={12}>
          <CardEducationalDetails
            educationalDetails={educational}
            loading={loading}
          />
        </Col>
      ),
    },
    {
      id: "SKILLS",
      label: "Skills",
      content: (
        <Col lg={12}>
          <CardSkills title="Skills" list={skills} loading={loading} />
        </Col>
      ),
    },
    {
      id: "EMPLOYMENTS",
      label: "Employment History",
      content: (
        <Col lg={12}>
          <CardEmploymentHistory employment={employment} loading={loading} />
        </Col>
      ),
    },
    {
      id: "RESUME",
      label: "Resume",
      content: (
        <Col lg={12}>
          <CardResume data={{ resume: resume }} loading={loading} />
        </Col>
      ),
    },
    {
      id: "CERTIFICATE",
      label: "Certificates",
      content: (
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="pt-3">
                <h4 className="sun_heading ms-2">Certificates</h4>
                {loading && <AppLoader />}
                {!loading &&
                  certificate &&
                  Array.isArray(certificate) &&
                  certificate.map((item, key) => {
                    return (
                      <CardCertificate key={key} count={key + 1} data={item} />
                    )
                  })}
              </div>
            </CardBody>
          </Card>
        </Col>
      ),
    },
    {
      id: "TESTRESULTS",
      label: "Test Results",
      content: (
        <Col lg={12}>
          <StudentTestResults
            testResults={testResults}
            loading={loading}
            handleActions={handleActions}
            userId={params.id}
            role="company"
          />
        </Col>
      ),
    },
    {
      id: "COURSES",
      label: "Courses",
      content: (
        <Col lg={12}>
          <StudentCourseResults
            userCourses={userCourses}
            loading={loading}
            handleActions={handleActions}
            userId={params.id}
            role="company"
          />
        </Col>
      ),
    },
    {
      id: "MOCKINTERVIEWDETAILS",
      label: "Mock Interview Detail",
      content: (
        <Col lg={12}>
          <MockInterViewResults
            userMockTestRequests={userMockTestRequests}
            loading={loading}
            userId={params.id}
            role="company"
          />
        </Col>
      ),
    },
  ]

  const handleTabClick = tabId => { 
    dispatch(onGetUserTabData(tabId, params.candidateId,`&page=${1}&limit=${10}`))
  }
  const fullname = personalDetail && getUserFullName(personalDetail)
  const userProfile = userData ? userProfileFrame(userData) : ""

  return (
    <React.Fragment>
      {modal && (
        <InfomationModal
          modalBackdrop={modal}
          setmodalBackdrop={setModal}
          title={"Certificate"}
          size={"lg"}
          height={600}
        >
          <Certificate user={userData} courses={certificateCourse} />
        </InfomationModal>
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={"Candidate List"}
            breadcrumbItem={"Candidate Profile"}
            navigate={-1}
          />
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <AppLoader />
                </div>
              ) : (
                <CardProfile
                  imageUrl={
                    userData && userData.image
                      ? staticURL + userData.image
                      : avatar
                  }
                  data={userData}
                  profileName={
                    personalDetail?.name ? personalDetail?.name : fullname
                  }
                  profileEmail={personalDetail ? personalDetail.email : ""}
                  onclick={() => navigate(-1)}
                  LayoutType={"HORIZONTAL_TABS"}
                  tabs={tabsArray}
                  style={{ padding: 0 }}
                  showStart={true}
                  startdata={{ total: total }}
                  showFrame={userProfile}
                  handleTabClick={handleTabClick}
                  profileTitle={
                    personalDetail ? personalDetail?.profileTitle : ""
                  }
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewCandidate
