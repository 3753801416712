import React from "react"
import { Col } from "reactstrap"
import {
    useAsyncDebounce
} from "react-table"
import JobListGlobalFilter from "../GlobalSearchFilter"

const TableSearch = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,
    handleSearch,
    setPageNo
}) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(values => {
        setGlobalFilter(value || undefined)
        if (handleSearch) {
            setPageNo(0)
            handleSearch(values || undefined)
        }
    }, 200)

    return (
        <React.Fragment>
            <Col md={4}>
                <div className="search-box me-xxl-2  my-xxl-0 d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label d-flex align-items-center">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={e => {
                                    setValue(e.target.value)
                                    onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control custom_searchbox"
                                placeholder={`${count} records...`}
                                value={value || ""}
                            />
                        <i className="bx bx-search-alt search-icon ms-1"></i>
                        </label>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    )
}

export default TableSearch