import React, { useState, useEffect } from "react"
import { Col, Row, Card, CardBody } from "reactstrap"
import AppPlan from "../../components/Common/Plan"
import AppLoader from "../../components/Common/Loader"
import {
  generateKey as generatePaymentKey,
  createTransactions,
  getTransactionStatus,
  purChaseSubsCriptions,
  updateTransactionsStatus,
} from "../../helpers/api/transactionsApi"
import toastr from "toastr"
import { getCompanyPlan } from "helpers/api/subscriptionsApi"

const PlanTab = ({ role, loading, user, Plans, Subscriptions, activePlan }) => {
  const [txnStatus, setTxnStatus] = useState(false)
  const [hash, setHash] = useState("")
  const [txnInfo, setTxnInfo] = useState(null)
  const [message, setMessage] = useState("")

  // const [shouldContinue,setShouldContinue] = useState(false)

  useEffect(() => {
    let shouldContinue = true

    const fetchData = async () => {
      try {
        let result = await getTransactionStatus(hash)
        if (result && result?.message_type === "success") {
          try {
            let companySubscriptions = ""
            companySubscriptions = await getCompanyPlan()
            if (
              companySubscriptions &&
              companySubscriptions?.companyPlan &&
              companySubscriptions?.companyPlan?.userSubscription == null
            ) {
              await purChaseSubsCriptions(
                {
                  subscriptionId: txnInfo?.itemId,
                  transactionId: txnInfo?.txnId,
                },
                "company"
              )
              await updateTransactionsStatus({
                transactionId: txnInfo?.txnId,
                success: true,
                status: true,
              })
              setTxnStatus(false)
              shouldContinue = false
              companySubscriptions = null
              setMessage(result?.message)
            }else{
              setTxnStatus(false)
              shouldContinue = false
              companySubscriptions = null
              setMessage(result?.message)
            }
          } catch (error) {
            await updateTransactionsStatus({
              transactionId: txnInfo?.txnId,
              success: false,
              status: false,
            })
            setTxnStatus(false)
            shouldContinue = false
          }
        } else if (result && result.message_type === "error") {
          await updateTransactionsStatus({
            transactionId: txnInfo?.txnId,
            success: false,
            status: false,
          })
          shouldContinue = false
          setMessage(result?.message)
        } else if (result && result.message_type === "info") {
          await updateTransactionsStatus({
            transactionId: txnInfo?.txnId,
            success: false,
            status: false,
          })
          setTxnStatus(true)
          setMessage(result?.message)
        } else if (result && result.message_type) {
          await updateTransactionsStatus({
            transactionId: txnInfo?.txnId,
            success: false,
            status: false,
          })
          shouldContinue = false
        } else {
          await updateTransactionsStatus({
            transactionId: txnInfo?.txnId,
            success: false,
            status: false,
          })
        }
        result = ""
        if (!shouldContinue) {
          clearInterval(intervalId)
          setTxnStatus(false)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    if (hash) {
      fetchData()
    }

    const intervalId = setInterval(() => {
      if (hash && shouldContinue) {
        fetchData()
      }
    }, 2000)

    return () => clearInterval(intervalId)
  }, [hash, txnInfo])

  const handlePlan = async item => {
    const splitUsername = user && user?.name && user?.name.split(" ")
    const firstName = splitUsername && splitUsername[0]
    const amount = `${parseFloat(item?.price).toFixed(2)}`
    const txnId =
      Date.now() +
      Math.floor(Math.random() * 100000) +
      Math.random().toString(36).substring(2, 15)
    const itemId = item._id

    const response = await generatePaymentKey({
      firstname: firstName,
      email: user?.email,
      phone: user?.phone,
      productinfo: item?.name,
      amount: amount,
      surl: `https://qapreneur.com/transaction/success/${item._id}/${txnId}`,
      furl: `https://qapreneur.com/transaction/fail/${item._id}/${txnId}`,
      txnId: txnId,
    })

    if (response?.data && response?.status === 1) {
      const url_link = `https://pay.easebuzz.in/pay/${response.data}`
      const transactionRes = await createTransactions({
        amount: amount,
        purpose: "Subscription Purchase",
        transactionId: txnId,
        transactionType: "Debit",
        txn_info: JSON.stringify({ productinfo: item?.name }),
        success: false,
      })
      setHash(response.data)
      setTxnInfo({
        txnId: txnId,
        itemId: itemId,
        hash: response.data,
        ...transactionRes?.data?.transaction,
      })

      if (transactionRes && transactionRes?.data?.transaction) {
        localStorage.setItem(
          "txn_info",
          JSON.stringify({
            transaction: transactionRes?.data?.transaction,
            hash: response.data,
          })
        )
        setTxnStatus(true)
        const anchor = document.createElement("a")
        anchor.href = url_link
        anchor.target = "_blank"
        anchor.click()
      } else {
        setHash("")
        toastr.error("Transaction creation failed")
      }
    } else {
      setHash("")
      toastr.error(response?.data + " " + response?.error_desc)
    }
  }
  return (
    <Col lg={12}>
      <Card style={{ borderRadius: 15 }}>
        <CardBody>
          <div className="pt-3 px-2">
            {loading ? (
              <AppLoader />
            ) : (
              <>
                {!txnStatus && (
                  <Row className="mt-5">
                    {Plans &&
                      Plans.length > 0 &&
                      Plans.map((plan, key) => (
                        <Col key={key} lg={6}>
                          <CardBody
                            style={{
                              padding: 10,
                              border:
                                Subscriptions?.type === plan?.type
                                  ? "2px solid #F89752"
                                  : "",
                              borderRadius: 10,
                            }}
                          >
                            <AppPlan
                              SubscriptionPlan={{
                                ...plan,
                                activePlan: activePlan,
                              }}
                              isActive={Subscriptions?.type === plan?.type}
                              handlePlan={handlePlan}
                              showExpiry={true}
                            />
                            {Subscriptions?.type !== plan?.type &&
                              plan?.type !== "Basic" && (
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="btn_bgprime btn-sm text-light p-2 mt-2"
                                    onClick={() =>
                                      handlePlan({
                                        ...plan,
                                        activePlan: activePlan,
                                      })
                                    }
                                  >
                                    Pay Now
                                  </button>
                                </div>
                              )}
                          </CardBody>
                        </Col>
                      ))}
                  </Row>
                )}
                {txnStatus && (
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <AppLoader message="Waiting for transaction to complete..." />
                    <h5>Please wait while we process your transaction.</h5>
                    <h6 className="text-danger">
                      Do not close or refresh the page.
                    </h6>
                    {message && <h6 className="text-success">{message}</h6>}
                  </div>
                )}
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default PlanTab
