import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_SKILLS,
  GET_USER_SKILLS,
  GET_SKILL_DETAIL,
  ADD_NEW_SKILL,
  DELETE_SKILL,
  UPDATE_SKILL,
} from "./actionTypes"
import {
  getSkillsSuccess,
  getUserSkillsSuccess,
  getUserSkillsFail,
  getSkillsFail,
  getSkillDetailSuccess,
  getSkillDetailFail,
  addSkillFail,
  addSkillSuccess,
  updateSkillSuccess,
  updateSkillFail,
  deleteSkillSuccess,
  deleteSkillFail,
  updateSkillLoadingState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSkills,
  getUserSkills,
  getSkillsDetails,
  addNewSkill,
  updateSkill,
  deleteSkill,
} from "helpers/backend_helper"

function* fetchSkills({userId}) {
  try {
    yield put(updateSkillLoadingState(true))
    const response = yield call(getSkills,userId)
    yield put(getSkillsSuccess(response.data.data))
    yield put(updateSkillLoadingState(false))
  } catch (error) {
    yield put(updateSkillLoadingState(false))
    yield put(getSkillsFail(error))
  }
}

function* fetchSkillDetail({ skillId }) {
  try {
    const response = yield call(getSkillsDetails, skillId)
    yield put(getSkillDetailSuccess(response.data.data))
  } catch (error) {
    yield put(getSkillDetailFail(error))
  }
}

function* onUpdateSkill({ payload: { skill, skillId } }) {
  try {
    const response = yield call(updateSkill, skill, skillId)
    toastr.success("Skill updated successfully..!!")
    yield put(updateSkillSuccess(response.skill))
  } catch (error) {
    yield put(updateSkillFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteSkill({ payload: skillId }) {
  try {
    yield put(updateSkillLoadingState(true))
    const response = yield call(deleteSkill, skillId)
    toastr.success("Skill deleted successfully..!!")
    yield put(updateSkillLoadingState(false))
    yield put(deleteSkillSuccess(skillId))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateSkillLoadingState(false))
    yield put(deleteSkillFail(error))
  }
}

function* onAddNewSkill({ payload: { skill } }) {
  try {
    yield put(updateSkillLoadingState(true))
    const response = yield call(addNewSkill, skill)
    toastr.success("New Skill Added Successfully..!!")
    yield put(addSkillSuccess(response.skill))
    yield put(updateSkillLoadingState(false))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateSkillLoadingState(false))
    yield put(addSkillFail(error))
  }
}

function* fetchSkillsByUserId({ userId, params }) {
  try {
    const { page, limit,search='' } = params
    const queryParams = `?page=${page}&limit=${limit}${search?'&search='+search:''}`
    yield put(updateSkillLoadingState(true))
    const response = yield call(getUserSkills, userId, queryParams)
    yield put(getUserSkillsSuccess(response.data.data))
    yield put(updateSkillLoadingState(false))
  } catch (error) {
    yield put(updateSkillLoadingState(false))
    yield put(getUserSkillsFail(error))
  }
}

function* skillsSaga() {
  yield takeEvery(GET_SKILLS, fetchSkills)
  yield takeEvery(GET_USER_SKILLS, fetchSkillsByUserId)
  yield takeEvery(GET_SKILL_DETAIL, fetchSkillDetail)
  yield takeEvery(ADD_NEW_SKILL, onAddNewSkill)
  yield takeEvery(UPDATE_SKILL, onUpdateSkill)
  yield takeEvery(DELETE_SKILL, onDeleteSkill)
}

export default skillsSaga
