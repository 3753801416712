import React, { useEffect, useState, useRef, useContext } from "react"
import { AppContext } from "../../context/AppContext"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"
import {
  getTransactions as onGetTransactions,
  searchTest as onSearchTest,
  searchCourses as onSearchCourse,
  getSubscriptions as onGetSubscriptions,
  addNewTransactions as onAddNewTransactions,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import FormModal from "../../components/Common/Modal/FormModal"
import AppInvoice from "../../components/Common/Invoice/invoice"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import { columns, formFields } from "./constants"
import { companytransactColumns } from "../CompanyManagement/constants"
import jsPDF from "jspdf"
import toastr from "toastr"

const CommonTransactions = () => {
  document.title = "Transaction List | QAPRENEUR"
  const role = localStorage.getItem("authRole")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [TransactionList, setTransactionList] = useState([])
  const [transactionDetails, setTransactionDetails] = useState({})
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const [formModal, setFormModal] = useState(false)
  const [invoiceModal, setInvoiceModal] = useState(false)
  const [invoiceDetail, setInvoiceDetail] = useState(null)
  const [userData, setUserData] = useState(null)
  const contentRef = useRef(null)
  const { loggedInUser } = useContext(AppContext)

  const { transactions, students, tests, courses, subscriptions, loading } =
    useSelector(state => ({
      transactions: state?.transactions?.transactions,
      loading: state?.transactions?.loading,
      students: state?.students?.students,
      tests: state?.tests?.tests,
      courses: state?.courses?.courses,
      subscriptions: state?.subscriptions?.subscriptions,
    }))

  useEffect(() => {
    const params = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      params.search = search
    }
    if (role === "admin") {
      params.role = "admin"
      dispatch(onGetTransactions(params))
      dispatch(onSearchTest({ type: "Test", slug: "" }))
      dispatch(onSearchCourse({ type: "Course", slug: "" }))
      dispatch(onGetSubscriptions({ type: "Subscription", slug: "" }))
    }
    if (role === "company") {
      params.role = "company"
      dispatch(onGetTransactions(params))
    }
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(transactions)) {
      setTransactionList(
        transactions
          .map(item => ({
            ...item,
            status: item?.success ? "success" : "faild",
            productname:
              typeof item?.txn_info === "string"
                ? JSON.parse(item?.txn_info)?.productinfo
                : "",
            _id: item?.userId,
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setTransactionList([])
    }
  }, [transactions, pageNo, rowPerPage])

  const handleActions = (data, type) => {
    if (type === "VIEW") {
      navigate(`/test-details/${data._id}`)
    } else if (type === "EDIT") {
      navigate(`/tests-edit/${data._id}`)
    } else if (type === "VIEW_STUDENT_LIST") {
      navigate(`/purchased-test-users/${data._id}`)
    } else if (type === "CREATE") {
      setTransactionDetails({
        type: "",
        status: "",
      })
      toggle()
    } else if (type === "INVOICE") {
      if (data.status === "faild" || !data.success) {
        toastr.error("invoice not generated for failed transaction")
      } else {
        setInvoiceDetail(data)
        setInvoiceModal(true)
      }
    }
  }

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    if (pageNo === 0) {
      setPageNo(1)
    } else {
      setPageNo(pageNo)
    }
    setTransactionList([])
  }

  const toggle = () => setFormModal(!formModal)

  const handleSave = values => {
    if (values.user && values.type) {
      dispatch(onAddNewTransactions(values))
      toggle()
      setPageNo(1)
    }
  }

  const handleInvoice = () => {
    const input = contentRef.current
    const pdf = new jsPDF("p", "pt", "a4")

    pdf.html(input, {
      html2canvas: {
        scale: 0.56, // Adjust the scale to fit content within PDF
      },
      callback: () => {
        pdf.save(`${new Date().toISOString()}_invoice.pdf`)
      },
    })
  }

  const fields = formFields(students, tests, courses, subscriptions)

  return (
    <React.Fragment>
      {formModal && (
        <FormModal
          modalBackdrop={formModal}
          setmodalBackdrop={setFormModal}
          modalAction={handleSave}
          title={"Add Transactions"}
          formFields={fields}
          height={300}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Transactions" breadcrumbItem="Transactions" />
          <Row>
            <Col lg="12">
              <div>
                {role === "admin" && (
                  <>
                    <TableContainer
                      loading={loading}
                      data={TransactionList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isJobListGlobalFilter={false}
                      customPageSize={rowPerPage}
                      className="project-list-table table-nowrap align-middle table-borderless"
                      onChangePagination={onChangePagination}
                      showPagination={true}
                      handleActions={handleActions}
                      handleSearch={handleSearch}
                      {...(role === "admin"
                        ? {
                            addButton: {
                              onclick: () =>
                                handleActions(transactionDetails, "CREATE"),
                              title: "Add New Transaction",
                            },
                          }
                        : {})}
                      columns={columns}
                    />
                  </>
                )}
                {role === "company" && (
                  <>
                    <TableContainer
                      loading={loading}
                      data={TransactionList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isJobListGlobalFilter={false}
                      customPageSize={rowPerPage}
                      className="project-list-table table-nowrap align-middle table-borderless"
                      columns={companytransactColumns}
                      notDataFound={true}
                      handleInvoice={handleActions}
                      onChangePagination={onChangePagination}
                      showPagination={true}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
          {invoiceModal && (
            <InfomationModal
              modalBackdrop={invoiceModal}
              setmodalBackdrop={setInvoiceModal}
              title={"Transaction History"}
              size={"xl"}
              height={500}
              isFooter={true}
              buttonTitle={"Download as PDF"}
              handleAction={handleInvoice}
            >
              <AppInvoice
                transactions={TransactionList}
                invoiceDetail={invoiceDetail}
                user={loggedInUser || invoiceDetail?.userDetails}
                contentRef={contentRef}
              />
            </InfomationModal>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CommonTransactions
