import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody } from "reactstrap"
import TableContainer from "../Common/Table/index"
import { getTransactions as onGetTransactions } from "../../store/actions"
import { limit } from "firebase/firestore"

function TransactionsTab({
  isGlobalFilter = false,
  isAddOptions = false,
  isJobListGlobalFilter = false,
  showPageDropdown = false,
  customPageSize = 10,
  className = "project-list-table table-nowrap align-middle table-borderless",
  columns,
  tableName = "",
  notDataFound = false,
  role,
  userId
}) {
  const dispatch = useDispatch()
  const { transactions, loading } = useSelector(state => ({
    transactions: state?.transactions?.transactions,
    loading: state?.transactions?.loading,
  }))

  useEffect(() => {
    const params = {
      role,
      page:1,
      limit:10,
      userId
    }
    dispatch(onGetTransactions(params))
  }, [])

  const data = transactions && transactions.length
  ? transactions.map(item => ({
      ...item,
      status: item?.success ? "success" : "faild",
      productname:
        typeof item?.txn_info === "string"
          ? JSON.parse(item?.txn_info)?.productinfo
          : "",
    }))
  : []

  return (
    <Card style={{ borderRadius: 15 }}>
      <CardBody>
        <div className="pt-3">
          <div className="d-flex justify-content-between">
            <h4 className="sun_heading">Transaction History</h4>
          </div>
          {transactions && Array.isArray(transactions) ? (
            <TableContainer
              loading={loading}
              data={data}
              isGlobalFilter={isGlobalFilter}
              isAddOptions={isAddOptions}
              isJobListGlobalFilter={isJobListGlobalFilter}
              showPageDropdown={showPageDropdown}
              customPageSize={customPageSize}
              className={className}
              columns={columns}
              tableName={tableName}
              notDataFound={notDataFound}
            />
          ) : (
            "No Transaction Available"
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default TransactionsTab
