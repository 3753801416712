import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATION_LOADING_STATE,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAIL,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  GET_NOTIFICATION_DETAIL_FAIL,
  GET_NOTIFICATIONS_RECEIVERS_FAIL,
  GET_NOTIFICATIONS_RECEIVERS_SUCCESS,
  RESET_NOTIFICATIONS,
} from "./actionTypes"

const INIT_STATE = {
  notifications: [],
  receivers: [],
  notificationDetail: {},
  error: {},
  loading: false,
}

const notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      }

    case GET_NOTIFICATIONS_RECEIVERS_SUCCESS:
      return {
        ...state,
        receivers: action.payload,
      }

    case UPDATE_NOTIFICATION_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case GET_NOTIFICATIONS_FAIL:
    case ADD_NOTIFICATION_FAIL:
    case UPDATE_NOTIFICATION_FAIL:
    case DELETE_NOTIFICATION_FAIL:
    case GET_NOTIFICATION_DETAIL_FAIL:
    case GET_NOTIFICATIONS_RECEIVERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      }

    case GET_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        notificationDetail: action.payload,
      }

    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          notification._id.toString() === action.payload._id.toString()
            ? { ...notification, ...action.payload }
            : notification
        ),
      }

    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification =>
            notification._id.toString() !== action.payload.toString()
        ),
      }

    case RESET_NOTIFICATIONS:
      return {
        ...state,
        notificationDetail: {},
      }

    default:
      return state
  }
}

export default notifications
