import { del, get, post, patch } from "../api_helper"

// get student
export const getStudents = queryParams =>
  get(`/users/?roleName=student${queryParams}`)

  
// get student details
export const getStudentsDetails = id =>
get(`/users/${id}`, { params: { id } })

// add Student
export const addNewStudent = student =>
  post(`/users`, student, {
    headers: {
      "Content-Type": "application/json ",
    },
  })

// update Student
export const updateStudent = (student, studentId) => {
  return patch(`/users/${studentId}`, student, {
    headers: {
      "Content-Type":
        !student.image || typeof student?.image?.name == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })
}

// delete Student
export const deleteStudent = studentId =>
  del(`/users/${studentId}`, { headers: { studentId } })

  export const getStudentProfileDetails = id => get(`/users/${id}/profile`)

  //  get(`/users/${studentId}/tests`)
export const getStudentTestResult = studentId =>
get(`/users/${studentId}/tests`)

export const getCoursesResult = studentId => get(`/courses/user/${studentId}`)

export const getStudentCertificateInfo = (studentId, courseId) =>
  get(`/courses/${studentId}/certificate?courseId=${courseId}`)

  export const sendVerificationMailToUser = (data) =>
  post(`/users/resendMail/${data?.userId}`,data)