import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { columns } from "./constants"
import { getJobsReferrals as onGetJobReferrals } from "../../store/jobReferral/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"

function UserJobReferrals() {
  document.title = "Referral Report | QAPRENEUR"
  const dispatch = useDispatch()
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const [JobReferralList, setJobReferralList] = useState([])


  const { JobReferrals, loading } = useSelector(state => ({
    loading: state?.jobReferrals?.loading,
    JobReferrals: state?.jobReferrals?.JobReferrals,
  }))

  const getData = () => {
    const params = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      params.search = search
    }
    dispatch(onGetJobReferrals(params))
  }

  useEffect(() => {
    getData()
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if(Array.isArray(JobReferrals) && JobReferrals.length){
      setJobReferralList(JobReferrals.map(item => ({
        ...item,
        isRegistered: item?.isRegistered ? "success" : "pending",
        isAppliedTOJob: item?.isAppliedTOJob ? "success" : "pending",
        _id: item?.item?.senderId?._id,
        JobReferralId: item?.senderId?._id,
      })).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)))
    }
  }, [JobReferrals])
  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const onChangePagination = (limit, page) => {
    setRowPerPage(limit)
    setPageNo(page)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Referral Report" />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={JobReferralList}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={columns}
                  showPagination={true}
                  onChangePagination={onChangePagination}
                  handleSearch={handleSearch}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserJobReferrals
