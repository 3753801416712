export const columns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    link: "/tests-overview",
  },
  {
    header: "Created Date",
    name: "createdAt",
    key: "DATE",
    link: "/tests-overview",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
  {
    header: "Active/Deactive",
    key: "SWITCH",
  },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Edit", type: "EDIT" },
      { label: "Delete", type: "DELETE" },
    ],
  }
]

export const formFields = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placeholder: "Enter Category Name",
    required: true,
  },
]
