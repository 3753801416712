import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Plan Redux States
import {
  GET_PLAN,
  GET_PLAN_DETAIL,
  ADD_NEW_PLAN,
  DELETE_PLAN,
  UPDATE_PLAN,
  GET_COMPANY_PLAN_DETAIL,
  GET_MOCK_TEST_PRICE_DETAIL,
  UPDATE_MOCK_TEST_PRICE_DETAIL,
} from "./actionTypes"
import {
  getPlansSuccess,
  getPlanDetailSuccess,
  getCompanyPlanDetailSuccess,
  getCompanyPlanDetailFail,
  getPlanDetailFail,
  addPlanSuccess,
  updatePlanSuccess,
  updatePlanFail,
  deletePlanSuccess,
  deletePlanFail,
  updatePlanLoadingState,
  getMockTestPricDetail,
  getMockTestPricDetailSuccess,
  getMockTestPricDetailFail,
  updateMockTestPriceDetail,
  updateMockTestPriceDetailSuccess,
  updateMockTestPriceDetailFail,
} from "./actions"

// Include both helper files with needed methods
import {
  createPlan,
  getPlans,
  updatePlan,
  deletePlan,
  getPlanDetails,
  getCompanyPlan,
  getMockTestPriceApi,
  updateMockTestPriceApi,
} from "helpers/api/subscriptionsApi"

function* fetchPlans({ payload }) {
  try {
    const { page, limit, search, role } = payload
    let queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }${role ? "&role=" + role : ""}`
    if (search && search?.length) {
      queryParams = queryParams + `&search=${search}`
    }
    yield put(updatePlanLoadingState(true))
    const response = yield call(getPlans, queryParams)
    yield put(getPlansSuccess(response.data.subscription))
    yield put(updatePlanLoadingState(false))
  } catch (error) {
    yield put(updatePlanLoadingState(false))
    // Dispatch an action to handle the failure
  }
}

function* fetchPlanDetail({ planId }) {
  try {
    yield put(updatePlanLoadingState(true))
    const response = yield call(getPlanDetails, planId)
    yield put(getPlanDetailSuccess(response.data.subscription))
    yield put(updatePlanLoadingState(false))
  } catch (error) {
    yield put(getPlanDetailFail(error))
  }
}

function* onUpdatePlan({ payload: { plan, params, history } }) {
  try {
    const { planId, role } = params
    const response = yield call(updatePlan, plan, planId)
    toastr.success("Plan updated successfully..!!")
    yield put(updatePlanSuccess(response.data.subscription))
    history(`/plan-list/${role}`)
  } catch (error) {
    yield put(updatePlanFail(error))
    console.log(error)
    toastr.error(error.response.data.message)
  }
}

function* onDeletePlan({ payload: planId }) {
  try {
    yield put(updatePlanLoadingState(true))
    const response = yield call(deletePlan, planId)
    toastr.success("Plan deleted successfully..!!")
    yield put(updatePlanLoadingState(false))
    yield put(deletePlanSuccess(planId))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updatePlanLoadingState(false))
    yield put(deletePlanFail(error))
  }
}

function* onAddNewPlan({ payload: { plan, history } }) {
  try {
    yield put(updatePlanLoadingState(true))
    const response = yield call(createPlan, plan)
    toastr.success("New Plan Added Successfully..!!")
    yield put(addPlanSuccess(response.data.plan))
    yield put(updatePlanLoadingState(false))
    history(-1)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updatePlanLoadingState(false))
  }
}

function* fetchCompanyPlan() {
  try {
    yield put(updatePlanLoadingState(true))
    const response = yield call(getCompanyPlan)
    yield put(getCompanyPlanDetailSuccess(response.companyPlan))
    yield put(updatePlanLoadingState(false))
  } catch (error) {
    yield put(getCompanyPlanDetailFail(false))
    yield put(updatePlanLoadingState(false))
  }
}

function* onGetMockTestPriceDetail() {
  try {
    yield put(updatePlanLoadingState(true))
    const response = yield call(getMockTestPriceApi)
    yield put(getMockTestPricDetailSuccess(response.data))
    yield put(updatePlanLoadingState(false))
  } catch (error) {
    yield put(getMockTestPricDetailFail(error))
    yield put(updatePlanLoadingState(false))
    toastr.error(error.response.data.message)
  }
}

function* onUpdateMockTestPriceDetail({
  payload: { mockTestPriceDetail, mockTestPriceId, history },
}) {
  try {
    yield put(updatePlanLoadingState(true))
    const response = yield call(
      updateMockTestPriceApi,
      mockTestPriceId,
      mockTestPriceDetail
    )
    yield put(updateMockTestPriceDetailSuccess(response.data))
    toastr.success("Mock Interview Price updated successfully..!!")
    yield put(updatePlanLoadingState(false))
    history(-1)
  } catch (error) {
    yield put(updateMockTestPriceDetailFail(error))
    yield put(updatePlanLoadingState(false))
    toastr.error(error.response.data.message)
  }
}

function* plansSaga() {
  yield takeEvery(GET_PLAN, fetchPlans)
  yield takeEvery(ADD_NEW_PLAN, onAddNewPlan)
  yield takeEvery(DELETE_PLAN, onDeletePlan)
  yield takeEvery(GET_PLAN_DETAIL, fetchPlanDetail)
  yield takeEvery(UPDATE_PLAN, onUpdatePlan)
  yield takeEvery(GET_COMPANY_PLAN_DETAIL, fetchCompanyPlan)
  yield takeEvery(GET_MOCK_TEST_PRICE_DETAIL, onGetMockTestPriceDetail)
  yield takeEvery(UPDATE_MOCK_TEST_PRICE_DETAIL, onUpdateMockTestPriceDetail)
}

export default plansSaga
