import {
  GET_JOBS_REFERRALS,
  GET_JOBS_REFERRALS_FAIL,
  GET_JOBS_REFERRALS_SUCCESS,
  UPDATE_JOB_REFERRALS_LOADING_STATE,
} from "./actionTypes"

export const getJobsReferrals = params => ({
  type: GET_JOBS_REFERRALS,
  params,
})

export const getJobsReferralsSuccess = jobs => ({
  type: GET_JOBS_REFERRALS_SUCCESS,
  payload: jobs,
})

export const getJobReferralsFail = error => ({
  type: GET_JOBS_REFERRALS_FAIL,
  payload: error,
})
export const updateJobReferralsLoadingState = status => ({
  type: UPDATE_JOB_REFERRALS_LOADING_STATE,
  payload: status,
})
