/* ANNOUNCEMENTS */
export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAIL = "GET_ANNOUNCEMENTS_FAIL";

/* ANNOUNCEMENT DETAIL*/
export const GET_ANNOUNCEMENT_DETAIL = "GET_ANNOUNCEMENT_DETAIL";
export const GET_ANNOUNCEMENT_DETAIL_SUCCESS = "GET_ANNOUNCEMENT_DETAIL_SUCCESS";
export const GET_ANNOUNCEMENT_DETAIL_FAIL = "GET_ANNOUNCEMENT_DETAIL_FAIL";

/**
 * Add announcement
 */
export const ADD_NEW_ANNOUNCEMENT = "ADD_NEW_ANNOUNCEMENT";
export const ADD_ANNOUNCEMENT_SUCCESS = "ADD_ANNOUNCEMENT_SUCCESS";
export const ADD_ANNOUNCEMENT_FAIL = "ADD_ANNOUNCEMENT_FAIL";

/**
 * Edit announcement
 */
export const UPDATE_ANNOUNCEMENT = "UPDATE_ANNOUNCEMENT";
export const UPDATE_ANNOUNCEMENT_SUCCESS = "UPDATE_ANNOUNCEMENT_SUCCESS";
export const UPDATE_ANNOUNCEMENT_FAIL = "UPDATE_ANNOUNCEMENT_FAIL";

/**
 * Delete announcement
 */
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_FAIL = "DELETE_ANNOUNCEMENT_FAIL";

export const UPDATE_ANNOUNCEMENT_LOADING_STATE = "UPDATE_ANNOUNCEMENT_LOADING_STATE";

/* ANNOUNCEMENTS */
export const GET_ANNOUNCEMENTS_REPORT = "GET_ANNOUNCEMENTS_REPORT";
export const GET_ANNOUNCEMENTS_REPORT_SUCCESS = "GET_ANNOUNCEMENTS_REPORT_SUCCESS";
export const GET_ANNOUNCEMENTS_REPORT_FAIL = "GET_ANNOUNCEMENTS_REPORT_FAIL";

/* RESET ANNOUNCEMENTS */
export const RESET_ANNOUNCEMENTS_REPORT = "RESET_ANNOUNCEMENTS_REPORT";
export const RESET_ANNOUNCEMENTS_REPORT_SUCCESS = "RESET_ANNOUNCEMENTS_REPORT_SUCCESS";
export const RESET_ANNOUNCEMENTS = "RESET_ANNOUNCEMENTS";