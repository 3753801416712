import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { Row } from 'reactstrap';
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import InfomationModal from "../../components/Common/Modal/InfomationModal"
import CsvToJsonConverter from "../../components/Common/CsvToJsonConverter";
import QuestionsCard from "../../components/Common/Card/QuestionsCard";

import {
    addNewQuestion as onAddNewQuestion,
    deleteQuestion as onDeleteQuestion,
    getTestDetail as onGetTestDetail,
} from "../../store/actions"

function CertificateTest({ courseDetail, testCategories }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const [csvModal, setCsvModal] = useState(false)
    const [questions, setQuestions] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [questionId, setQuestionId] = useState("")

    const { testDetail, loading } = useSelector(state => ({
        testDetail: state.tests?.testDetail,
        loading: state.tests?.loading
    }))

    useEffect(() => {
        if (params.id && courseDetail && Array.isArray(courseDetail?.certificateTest) &&
            courseDetail?.certificateTest.length > 0
            && courseDetail?.certificateTest[0]?._id) {
            dispatch(onGetTestDetail(courseDetail?.certificateTest[0]?._id))
        }
    }, [params.id])

    useEffect(() => {
        if (Object.keys(testDetail).length && testDetail?.questions) {
            const ques = testDetail.questions.map(question => {
                const que = JSON.parse(question.question)
                que.questionId = question._id
                return que
            })
            setQuestions(ques)
        }
    }, [testDetail])

    const csvToggle = () => setCsvModal(!csvModal)

    const handleSave = async () => {
        if (courseDetail?.certificateTest && Array.isArray(courseDetail?.certificateTest) && courseDetail?.certificateTest.length === 0 && testCategories) {
            navigate(`/certificate/tests-create/${courseDetail?._id}`)
        } else {
            navigate(`/certificate-test-edit/${courseDetail?.certificateTest[0]?._id}`)
        }
    }

    const handleCsvToJsonConverter = (res) => {
        if (res?.data) {
            const csvResponse = res?.data.length > 0 && res?.data.map((r, k) => {
                return {
                    question: r?.title || "",
                    A: r?.A || "",
                    B: r?.B || "",
                    C: r?.C || "",
                    D: r?.D || "",
                    answer: r?.answer || "",
                    description: r?.description || "",
                }
            }).filter(q => q?.question)
            const totalQuestion = { questions: JSON.stringify(csvResponse) }
            // const resp = csvResponse.map(question => ({ question: question, isAnswer: question?.answer }))
            // const totalQuestion = {questions:JSON.stringify(resp)}
            // isAnswer: r?.answer
            dispatch(
                onAddNewQuestion(
                    totalQuestion,
                    navigate,
                    courseDetail?.certificateTest[0]?._id,
                    "add-test",
                    params?.id,
                )
            )
            csvToggle()
            dispatch(onGetTestDetail(courseDetail?.certificateTest[0]?._id))
        }
    }

    const modalAction = () => {
        setShowModal(false)
        handleDeleteButton(questionId)
    }

    const handleDeleteButton = id => {
        dispatch(onDeleteQuestion(id))
        dispatch(onGetTestDetail(courseDetail?.certificateTest[0]?._id))
    }

    return (
        <>
            {csvModal && (
                <InfomationModal
                    modalBackdrop={csvModal}
                    setmodalBackdrop={setCsvModal}
                    title={"Import questions"}
                >
                    <Row>
                        <CsvToJsonConverter handleCsvToJsonConverter={handleCsvToJsonConverter} />
                    </Row>
                </InfomationModal>
            )}
            {showModal && (
                <ConfirmationModal
                    modalBackdrop={showModal}
                    setmodalBackdrop={setShowModal}
                    modalTitle={"Are you sure want to delete this question ?."}
                    modalAction={modalAction}
                // loading={props.loading}
                />
            )}
            <QuestionsCard
                title="Certificate Test List"
                questions={questions}
                handleDeleteButton={handleDeleteButton}
                showEdit={true}
                {...courseDetail?.certificateTest?.length > 0 ? {
                    handleAddQuestions: () => navigate(`/questions-create/certificate-questions/${courseDetail?.certificateTest[0]?._id}/${questions?.length + 1}`)
                } : {}}
                // handleAddQuestions={}
                // isCsvToJsonConverter={csvToggle}
                {...courseDetail?.certificateTest?.length > 0 ? {
                    isCsvToJsonConverter: csvToggle
                } : {}}
                loading={loading}
                ButtonData={{
                    onClick: handleSave, title: courseDetail?.certificateTest &&
                        courseDetail?.certificateTest.length === 0 ? "Add Certificate Test"
                        : "Edit Certificate Test"
                }}
                test={courseDetail?.certificateTest
                    && courseDetail?.certificateTest.length > 0
                    ? courseDetail?.certificateTest[0]
                    : null}
            />

        </>
    )
}

export default CertificateTest