import React, { useState } from "react"
import { useParams } from "react-router-dom"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap"

const active = {
  borderBottom: "2.5px solid #252f6a",
  transition: ".2s",
  color: "gray",
  padding: 10,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}

const TabPanes = ({
  tabsArray,
  tabLayout = "HORIZONTAL_TABS",
  contentBgColor = "#1AB69D",
  handleTabClick = null,
  showButton = false,
  handleButton = null,
}) => {
  return renderTabContent(
    tabsArray,
    tabLayout,
    contentBgColor,
    handleTabClick,
    showButton,
    handleButton
  )
}

const renderTabContent = (
  tabsArray,
  tabLayout,
  contentBgColor,
  handleTabClick,
  showButton,
  handleButton
) => {
  const params = useParams()
  const [activeTab, setActiveTab] = useState(
    params.profileTab ? params.profileTab : "tab1"
  )
  const toggleTab = tabId => {
    if (activeTab !== tabId) {
      setActiveTab(tabId)
      handleTabClick && handleTabClick(tabId)
    }
  }
  switch (tabLayout) {
    case "HORIZONTAL_TABS":
      return (
        <div>
          <Nav tabs style={{ borderRadius: 15 }} className="position-relative">
              <div className="d-flex w-100 flex-wrap">
                {tabsArray.map(tab => (
                  <NavItem key={tab.id}>
                    <NavLink
                      style={
                        activeTab === tab.id
                          ? active
                          : {
                              borderBottom: "2px solid #0000",
                              transition: ".5s linear",
                              color: "gray",
                              padding: 10,
                              fontWeight: 500,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }
                      }
                      className="fs-6"
                      onClick={() => toggleTab(tab.id)}
                    >
                      {tab.label}
                    </NavLink>
                  </NavItem>
                ))}
              </div>

              {showButton && handleButton && (
                <button
                  className="btn_upgrade_plan position-absolute"
                  onClick={handleButton}
                  style={{right:20,bottom:10,padding:'8px 0'}}
                >
                  Upgrade Plan
                </button>
              )}
          </Nav>
          <TabContent
            style={{ backgroundColor: contentBgColor }}
            activeTab={activeTab}
          >
            {tabsArray.map(tab => (
              <TabPane key={tab.id} tabId={tab.id} className="py-2">
                <Row>{tab.content}</Row>
              </TabPane>
            ))}
          </TabContent>
        </div>
      )
    case "VERTICAL_TAB":
      return (
        <Row>
          <Col
            lg={4}
            style={{
              borderRight: "1px solid gray",
              paddingRight: 0,
              paddingTop: "20px",
            }}
          >
            <Nav vertical>
              {tabsArray.map((tab, key) => (
                <NavItem key={tab.id}>
                  <NavLink
                    style={
                      activeTab === tab.id
                        ? {
                            backgroundColor: "#1AB69D",
                            border: "2px solid white",
                            color: "white",
                            transition: ".2s",
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                            padding: "5px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }
                        : {
                            borderLeft: "3px solid transparent",
                            color: "gray",
                            padding: "5px",
                            height: "40px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }
                    }
                    onClick={() => toggleTab(tab.id)}
                  >
                    {key + 1 + " " + "." + " " + tab.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col lg={8}>
            <TabContent activeTab={activeTab}>
              {tabsArray.map(tab => (
                <TabPane key={tab.id} tabId={tab.id} className="py-2">
                  {tab.content}
                </TabPane>
              ))}
            </TabContent>
          </Col>
        </Row>
      )
    default:
      return null
  }
}

export default TabPanes
