import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import TableContainer from "../../components/Common/Table/index"
import { JobReferralColumns } from "../JobReferralManagement/constants"
import { useDispatch } from "react-redux"
import { getUserTabData as onGetUserTabData } from "../../store/students/actions"

function UserJobReferralReport({ jobReferrals=[], loading, userId }) {
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [data, setData] = useState([])
  const tabId = "JOBREFRRAL"
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      jobReferrals &&
      Array.isArray(jobReferrals) &&
      jobReferrals.length > 0
    ) {
      setData(
        jobReferrals
          .map(item => ({
            ...item,
            isRegistered: item?.isRegistered ? "success" : "pending",
            isAppliedTOJob: item?.isAppliedTOJob ? "success" : "pending",
            _id: item?.item?.senderId?._id,
            JobReferralId: item?.senderId?._id,
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    }
  }, [jobReferrals])

  const onChangePagination = (limit, page) => {
    setRowPerPage(rowPerPage)
    setPageNo(page)
    if (userId) {
      dispatch(onGetUserTabData(tabId, userId, `&page=${page}&limit=${limit}`))
    }
  }

  return (
    <>
      <Card style={{ borderRadius: 15 }}>
        <CardBody>
          <div className="pt-3">
            <div className="d-flex justify-content-between">
              <h4 className="sun_heading">User Job Referrals</h4>
            </div>
            {jobReferrals && Array.isArray(jobReferrals) ? (
              <TableContainer
                loading={loading}
                data={data}
                isGlobalFilter={false}
                isAddOptions={false}
                isJobListGlobalFilter={false}
                showPageDropdown={false}
                customPageSize={10}
                // handleActions={handleActions}
                className="project-list-table table-nowrap align-middle table-borderless"
                columns={JobReferralColumns}
                tableName="viewStudent"
                showPagination={true}
                onChangePagination={onChangePagination}
              />
            ) : (
              "No Job Referrals"
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default UserJobReferralReport
