import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import TableContainer from "../../components/Common/Table/index"
import { courseColumns } from "./Utills"
import { useDispatch } from "react-redux"
import { getUserTabData as onGetUserTabData } from "../../store/students/actions"
import { calculateCompletionPercentage } from "./Utills"

function StudentCourseResults({
  userCourses=[],
  loading,
  userId,
  handleActions,
  role = "",
}) {
  const courseColumn = courseColumns(role)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [data, setData] = useState([])
  const tabId = "COURSES"
  const dispatch = useDispatch()

  useEffect(() => {
    if (userCourses && Array.isArray(userCourses) && userCourses.length > 0) {
      setData(userCourses)
    }
  }, [userCourses])

  const onChangePagination = (limit, page) => {
    setRowPerPage(rowPerPage)
    setPageNo(page)
    if (userId) {
      dispatch(onGetUserTabData(tabId, userId, `&page=${page}&limit=${limit}`))
    }
  }

  const calculateCourseCompletionPercentage =
    data && calculateCompletionPercentage(data)

  const visibilityData =
    calculateCourseCompletionPercentage &&
    calculateCourseCompletionPercentage.filter(
      userCourse => userCourse?.visibility === 1
    )

  return (
    <>
      <Card style={{ borderRadius: 15 }}>
        <CardBody>
          <div className="pt-3">
            <h4 className="sun_heading">Subscribed Courses</h4>
            {userCourses && Array.isArray(userCourses) ? (
              <TableContainer
                loading={loading}
                data={
                  role === "admin"
                    ? calculateCourseCompletionPercentage
                    : visibilityData
                }
                isGlobalFilter={false}
                isAddOptions={false}
                isJobListGlobalFilter={false}
                showPageDropdown={false}
                customPageSize={rowPerPage}
                handleActions={handleActions}
                handleView={data => handleActions(data, "CERTIFICATE")}
                className="project-list-table table-nowrap align-middle table-borderless"
                columns={courseColumn}
                tableName="viewStudent"
                showPagination={true}
                onChangePagination={onChangePagination}
              />
            ) : (
              "No Courses Subscriptions"
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default StudentCourseResults
