import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_COLLEGES,
  GET_COLLEGE_DETAIL,
  ADD_NEW_COLLEGE,
  DELETE_COLLEGE,
  UPDATE_COLLEGE,
} from "./actionTypes"
import {
  getCollegesSuccess,
  getCollegesFail,
  getCollegeDetailSuccess,
  getCollegeDetailFail,
  addCollegeFail,
  addCollegeSuccess,
  updateCollegeSuccess,
  updateCollegeFail,
  deleteCollegeSuccess,
  deleteCollegeFail,
  updateCollegeLoadingState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getColleges,
  getCollegesDetails,
  addNewCollege,
  updateCollege,
  deleteCollege,
  getProfileDetails,
  updateProfileDetails
} from "helpers/backend_helper"

function* fetchColleges({params}) {
  try {
    yield put(updateCollegeLoadingState(true))
    let response
    if(params && Object.keys(params).length>0){
      const { page, limit } = params 
      let queryParams = `&page=${page}&limit=${limit}`
      if (params?.search && params?.search?.length) {
        queryParams = queryParams + `&search=${params?.search}`
      }
      response = yield call(getColleges,queryParams)
    }else{
      response = yield call(getColleges,params)
    }
    yield put(getCollegesSuccess(response.data))
    yield put(updateCollegeLoadingState(false))
  } catch (error) {
    yield put(updateCollegeLoadingState(false))
    yield put(getCollegesFail(error))
  }
}

function* fetchCollegeDetail({ collegeId, slug }) {
  try {
    let response
    if (slug==="college") {
      response = yield call(getCollegesDetails, collegeId, slug)
    } else {
      response = yield call(getProfileDetails)
    }
    const data = response?.data?.data || response?.data?.collegeDetail || response?.user
    yield put(getCollegeDetailSuccess(data))
  } catch (error) {
    yield put(getCollegeDetailFail(error))
  }
}

function* onUpdateCollege({ payload: {slug, college, collegeId, history } }) {
  try {
    let response
    if (slug==="college") {
      response = yield call(updateCollege,slug, college, collegeId)
    } else {
      // response = yield call(updateCollege,'users', college, collegeId)
      response =  yield call(updateProfileDetails,college,collegeId)
    }
    const data = response.data.data || response.data.collegeDetail || response.data.user
    yield put(updateCollegeSuccess(data))
    toastr.success("College updated successfully..!!")
    // setTimeout(()=>{
    //   history(-1)
    // },2000)
  } catch (error) {
    yield put(updateCollegeFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteCollege({ payload }) {
  const {collegeId,params}= payload
  try {
    const { page, limit } = params
    const queryParams = `&page=${page}&limit=${limit}`
    const response = yield call(deleteCollege, collegeId)
    toastr.success("College deleted successfully..!!")
    let res 
    if (params) {
      res = yield call(getColleges,queryParams)
    } else {
      res = yield call(getColleges)
    }
    yield put(deleteCollegeSuccess(response.data))
    yield put(getCollegesSuccess(res.data))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteCollegeFail(error))
  }
}

function* onAddNewCollege({ payload: { college, history } }) {
  try {
    yield put(updateCollegeLoadingState(true))
    const response = yield call(addNewCollege, college)
    toastr.success("New College Added Successfully..!!")
    yield put(addCollegeSuccess(response.data.data))
    yield put(updateCollegeLoadingState(false))
    setTimeout(() => {
      history("/colleges-list")
    }, 1500)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateCollegeLoadingState(false))
    yield put(addCollegeFail(error))
  }
}

function* collegesSaga() {
  yield takeEvery(GET_COLLEGES, fetchColleges)
  yield takeEvery(GET_COLLEGE_DETAIL, fetchCollegeDetail)
  yield takeEvery(ADD_NEW_COLLEGE, onAddNewCollege)
  yield takeEvery(UPDATE_COLLEGE, onUpdateCollege)
  yield takeEvery(DELETE_COLLEGE, onDeleteCollege)
}

export default collegesSaga
