import { del, get, post, patch } from "../api_helper"
import { JOBS_ROUTES } from "../url_helper"

/** Jobs */
// add Job
export const addNewJob = job =>
  post(JOBS_ROUTES, job, {
    headers: {
      "Content-Type": "application/json",
    },
  })

// update Job
export const updateJob = (job, jobId) =>
  patch(`${JOBS_ROUTES}/${jobId}`, job, {
    headers: {
      "Content-Type":
        !job.image || typeof job?.image == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })

// delete Job
export const deleteJob = jobId =>
  del(`${JOBS_ROUTES}/${jobId}`, { headers: { jobId } })
// get job
export const getJobs = (companyId, queryParams) => {
  if (companyId)
    return get(`${JOBS_ROUTES}?companyId=${companyId + queryParams}`)
  else return get(`${JOBS_ROUTES}?${queryParams}`)
}

export const getHrPostedJobs = postedBy =>
  get(`${JOBS_ROUTES}?postedBy=${postedBy}`)

// get job details
export const getJobsDetails = id =>
  get(`${JOBS_ROUTES}/${id}`, { params: { id } })

// get applicants
export const getApplicantsByJObdId = (companyId, JobId, queryParams) => {
  return get(
    `${JOBS_ROUTES}/${companyId}/${JobId}/applicants${
      queryParams ? queryParams : ""
    }`
  )
}

// update applicants
export const updateApplicantsStatus = data => {
  return patch(`${JOBS_ROUTES}/applicants/status`, data)
}


// get candidates
export const getjobsPostedByCompany = companyId => {
  return get(`${JOBS_ROUTES}/postedBy/${companyId}`)
}


// get user's applied jobs
export const getUserAppliedJobList = queryParams => {
  return get(`${JOBS_ROUTES}/appliedJob/${queryParams}`)
}