import axios from "axios"
import toastr from "toastr"
//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL
let isRefreshing = false

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token
axiosApi.interceptors.request.use(function (response) {
  // const token = localStorage.getItem("authToken")
  const authUser = localStorage.getItem("authUser")
  if (authUser) {
    const parseUser = JSON.parse(authUser)
    const token = parseUser.activeToken
    if (token !== undefined && token !== null) {
      response.headers["Authorization"] = `Bearer ${token}`
      // response.headers["Content-Type"] = "multipart/form-data"
    }
  }
  return response
})

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const isLogin = localStorage.getItem("authToken")
    const role = localStorage.getItem("authRole")
    const authUser = localStorage.getItem("authUser")

    if (isLogin && error.response?.status === 401) {
      const parseUser = authUser && JSON.parse(authUser)
      const token = parseUser.activeToken
      const refreshToken = localStorage.getItem("refreshToken")
      if (refreshToken && isLogin === token) {
        axiosApi
          .post(
            "/users/refreshToken",
            { refreshToken: `Bearer ${refreshToken}` },
            {
              headers: {
                "refresh-token": `Bearer ${refreshToken}`,
              },
            }
          )
          .then(res => {
            localStorage.setItem("authToken", res.data.activeToken)
            localStorage.setItem("refreshToken", res.data.refreshToken)
            localStorage.setItem("authUser", JSON.stringify(res.data.data.user))
            setTimeout(() => {
              window.location.reload()
            }, 1500)
            //  toastr.info("Token refreshed successfully!");
          })
          .catch(err => {
            console.error("Error refreshing token:", err)
            // Handle token refresh failure
            if (role === "admin") {
              window.location.href = `${window.location.protocol}//${window.location.host}/admin/login`
            } else {
              window.location.href = `${window.location.protocol}//${window.location.host}/login`
            }

            localStorage.clear()
          })
      } else {
        if (role === "admin") {
          window.location.href = `${window.location.protocol}//${window.location.host}/admin/login`
        } else {
          window.location.href = `${window.location.protocol}//${window.location.host}/login`
        }
      }
    }

    if (isLogin && error.response?.status === 422) {
      toastr.error("Your account is logged in on another device.")
      setInterval(() => {
        localStorage.clear()
        if (role === "admin") {
          window.location.href = `${window.location.protocol}//${window.location.host}/admin/login`
        } else {
          window.location.href = `${window.location.protocol}//${window.location.host}/login`
        }
      }, 1000)
    }

    return Promise.reject(error)
  }
)

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}
export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
