import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import TooltipPortal from "./TooltipPortal"; // Import the TooltipPortal
import "./NotificationTooltip.css";

// Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

const NotificationDropdown = props => {
  const staticURL = process.env.REACT_APP_STATIC_URL;
  const [menu, setMenu] = useState(false);
  const [clickedNotification, setClickedNotification] = useState(null);
  const [readNotifications, setReadNotifications] = useState(new Set());
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const notificationRefs = useRef([]);

  const handleNotificationClick = index => {
    setClickedNotification(index);
    const rect = notificationRefs.current[index].getBoundingClientRect();
    setTooltipPosition({
      top: rect.top + window.scrollY + rect.height / 20, // Center vertically relative to the notification
      left: rect.left + window.scrollX - 360, // Adjust 360px to move it to the left side (assuming the tooltip width is 350px + some padding)
    });
    setReadNotifications(prev => new Set(prev).add(index));
  };

  const toggleMenu = () => {
    setMenu(prevMenu => {
      if (prevMenu) {
        // Reset clickedNotification when the menu is closed
        setClickedNotification(null);
      }
      return !prevMenu;
    });
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggleMenu}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <span
            className="mdi mdi-bell-ring-outline"
            style={{ fontSize: "22px", color: "#f89752" }}
          ></span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="py-3 px-4">
            <Row
              className="align-items-center"
              style={{ borderBottom: "3px solid #eff2f7" }}
            >
              <Col className="mb-2">
                <span style={{ color: "#243466" }} className="m-0 fs-3 f-bold">
                  {props.t("Notification")}
                </span>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ height: "500px" }} className="px-4">
            {[...Array(8)].map((_, index) => (
              <Link
                to=""
                key={index}
                className=""
                onClick={() => handleNotificationClick(index)}
              >
                <div
                  className="tooltip-container"
                  ref={el => (notificationRefs.current[index] = el)}
                >
                  <Row
                    className="px-3 rounded-3 mb-2"
                    style={{ background: "#f1f3ff", position: "relative" }}
                  >
                    <Col
                      className="d-flex justify-content-center align-items-center p-0 py-2"
                      xs={12}
                    >
                      <Row>
                        <Col
                          className="d-flex justify-content-center align-items-center p-0"
                        >
                          {!readNotifications.has(index) && (
                            <div
                              className="rounded-5 p-0"
                              style={{
                                background: "#fa9759",
                                width: "8px",
                                height: "8px",
                              }}
                            ></div>
                          )}
                          <img
                            src={staticURL + "/default/default-user.png"}
                            alt="qapreneur"
                            className="avatar-sm rounded-circle frame-thumbnail me-2 ms-2"
                          />
                          <h6 className="mt-0 mb-1 font-size-12 text-dark">
                            Lorem ipsum dolor sit amet consectetur. Sed eget
                            tortor amet in ultrices odio dolor viverra sagittis.
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className="d-flex justify-content-end p-0 text-dark"
                      xs={12}
                    >
                      <span className="mb-0">{props.t("3 min ago")}</span>
                    </Col>
                  </Row>
                </div>
              </Link>
            ))}
          </SimpleBar>
        </DropdownMenu>
        {menu && clickedNotification !== null && (
          <TooltipPortal>
            <div
              className={`tooltip-content ${menu && clickedNotification !== null ? 'open' : ''}`}
              style={{
                top: `${tooltipPosition.top}px`,
                left: `${tooltipPosition.left}px`,
              }}
            >
              <Row>
                <Col className="d-flex">
                  <img
                    src={staticURL + "/default/default-user.png"}
                    alt="qapreneur"
                    className="avatar-sm rounded-circle frame-thumbnail me-2"
                  />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Sed eget tortor amet
                    in ultrices odio dolor viverra sagittis.
                  </p>
                </Col>
              </Row>
              <span className="time">{props.t("3 min ago")}</span>
              <div className="tooltip-arrow"></div>
            </div>
          </TooltipPortal>
        )}
      </Dropdown>
    </React.Fragment>
  );
};

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(NotificationDropdown);
