import React, { createContext, useState, useEffect } from "react"
import { getProfileDetails, getUserRole } from "../helpers/api/usersApi"
import { getSideBarLayout } from "../helpers/api/dashboardApi"

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState(null)
  const [authRole, setAuthRole] = useState(localStorage.getItem("authRole"))
  const [sideBarLayout, setSideBarLayout] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchUserData = async () => {
    try {
      const userResponse = await getProfileDetails()
      setLoggedInUser(userResponse?.data?.data)
    } catch (error) {
      console.error("Failed to fetch user", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchSidebarData = async () => {
    setLoading(true)
    try {
      const sideBarLayoutResponse = await getSideBarLayout()
      setSideBarLayout(sideBarLayoutResponse?.data)
    } catch (error) {
      console.error("Failed to fetch sidebar", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchRoleName = async () => {
    try {
      const userRoleName = await getUserRole()
      setAuthRole(userRoleName?.data?.roleName)
    } catch (error) {
      console.error("Failed to fetch Role", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AppContext.Provider
      value={{
        loggedInUser,
        authRole,
        loading,
        sideBarLayout,
        fetchData: fetchUserData,
        fetchSidebarData: fetchSidebarData,
        fetchRoleName: fetchRoleName,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
