import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import {
    generateCertificate as onGenerateCertificate
} from "../../../store/students/actions"
// 842X595
function Certificate({ user, courses }) {
    const dispatch = useDispatch()
    const [pdfBuffer, setPdfBuffer] = useState("")
    const [pdfName, setPdfName] = useState("")
    const [loading, setLoading] = useState(true)

    const { certificateBuffer } = useSelector(
        state => ({
            certificateBuffer: state.students.certificateBuffer,
        })
    )

    useEffect(() => {
        if (user && courses) {
            dispatch(onGenerateCertificate(user?._id, courses?._id))
        }
    }, [dispatch, onGenerateCertificate])

    useEffect(() => {
        if (certificateBuffer && Object.keys(certificateBuffer).length > 0) {
            const staticURL = process.env.REACT_APP_STATIC_URL
            const generatePath = (filePath) => {
                const fileName = filePath?.replace(/\\/g, '/').replace('public/', '');
                setPdfName(fileName);
                return `${staticURL}${fileName}`;
            }
            const url = generatePath(certificateBuffer?.data?.userCourseHistories?.certificate)
            setLoading(false)
            setPdfBuffer(url)
        }
    }, [certificateBuffer])


    const handleDownload = () => {
        if (pdfBuffer && pdfName) {
            const link = document.createElement('a');
            link.href = pdfBuffer;
            link.target = '_blank';
            link.download = pdfName;
            link.style.display = 'none';
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        }
    };



    return (
        <div>
            {loading && <>
                <div style={{ height: 595, width: 842 }} className="d-flex align-items-center justify-content-center">
                    <div className="text-gray">
                        <Spinner color="dark" size="lg" />
                    </div>
                </div>
            </>}
            {!loading && certificateBuffer && pdfBuffer && <>
                <iframe
                    src={pdfBuffer}
                    height={595}
                    width={"100%"} />
            </>
            }
        </div>
    )
}

export default Certificate