import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Crypto Redux States
import {
  GET_COMPANIES,
  GET_COMPANY_DETAIL,
  ADD_NEW_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANY_BILLING_INFO,
  ADD_COMPANY_BILLING_INFO,
  UPDATE_COMPANY_BILLING_INFO,
  GET_JOBS_POSTED_BY_COMPANY,
  GET_COMPANY_SUBSCRIPTION,
  GET_COMPANY_ALL_PLAN,
  GET_USERS_VIEW_HISTORY,
  GET_COMPANY_CREDIT,
} from "./actionTypes"
import {
  getCompaniesSuccess,
  getCompaniesFail,
  getCompanyDetailSuccess,
  getCompanyDetailFail,
  addCompanyFail,
  addCompanySuccess,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  updateCompanyLoadingState,
  getCompanyBillingInfoFail,
  getCompanyBillingInfoSuccess,
  addCompanyBillingInfoSuccess,
  addCompanyBillingInfoFail,
  updateCompanyBillingInfoFail,
  updateCompanyBillingInfoSuccess,
  getJobDetailsPosteByCopmanyFail,
  getJobDetailsPosteByCopmanySuccess,
  getCompanySubscriptionsFail,
  getCompanySubscriptionsSuccess,
  getCompanyAllplanSuccess,
  getCompanyAllplanFail,
  getUsersCompanyViewHistorySuccess,
  getUsersCompanyViewHistoryFail,
  getCompanyCreditFail,
  getCompanyCreditSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCompanies,
  getCompaniesDetails,
  addNewCompany,
  updateCompany,
  deleteCompany,
  getProfileDetails,
  updateProfileDetails,
  getCompanyBillingDetails,
  addCompanyBillingDetails,
  updateCompanyBillingDetails,
  getUserCompanyViewHistory,
  getCompanyCreditDetails,
} from "helpers/api/companyApi"

//Include Both Helper File with needed methods
import { getjobsPostedByCompany } from "helpers/api/JobApi"
import {
  getUserSubscriptions,
  getAllCompanyPlan,
} from "helpers/api/subscriptionsApi"

function* fetchCompanies({ params }) {
  try {
    const { page, limit } = params
    let queryParams = `&page=${page}&limit=${limit}`
    if (params?.search && params?.search?.length) {
      queryParams = queryParams + `&search=${params?.search}`
    }
    const response = yield call(getCompanies, queryParams)
    yield put(getCompaniesSuccess(response.data))
  } catch (error) {
    yield put(getCompaniesFail(error))
  }
}

function* fetchCompanyDetail({ companyId, slug }) {
  try {
    let response
    yield put(updateCompanyLoadingState(true))
    if (slug === "company") {
      response = yield call(getCompaniesDetails, companyId, slug)
      const data = response?.data
      yield put(getCompanyDetailSuccess(data))
    } else {
      response = yield call(getProfileDetails)
      const data = response?.data?.data
      // const res = yield call(getUserSubscriptions)
      yield put(
        getCompanyDetailSuccess({
          companyDetail: data,
        })
      )
      // subscription: res?.user?.subscription,
      // yield put(getCompanySubscriptionsSuccess(res?.user?.subscription))
    }
    yield put(updateCompanyLoadingState(false))
  } catch (error) {
    yield put(getCompanyDetailFail(error))
    yield put(updateCompanyLoadingState(false))
  }
}

function* onUpdateCompany({ payload: { slug, company, companyId, history } }) {
  try {
    let response
    if (slug === "company") {
      response = yield call(updateCompany, slug, company, companyId)
    } else {
      // response =  yield call(updateCompany,"users", company, companyId)
      response = yield call(updateProfileDetails, company, companyId)
    }
    const data =
      response.data.data || response.data.companyDetail || response.data.user
    yield put(updateCompanySuccess(data))
    toastr.success("Company updated successfully..!!")
    setTimeout(() => {
      history && history(`/profile/view/company/${companyId}`)
    }, 2000)
  } catch (error) {
    yield put(updateCompanyFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteCompany({ payload }) {
  const { companyId, params } = payload
  try {
    const { page, limit } = params
    const queryParams = `&page=${page}&limit=${limit}`
    const response = yield call(deleteCompany, companyId)
    toastr.success("Company deleted successfully..!!")
    let res
    if (params) {
      res = yield call(getCompanies, queryParams)
    } else {
      res = yield call(getCompanies)
    }
    yield put(deleteCompanySuccess(response.data))
    yield put(getCompaniesSuccess(res.data))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(deleteCompanyFail(error))
  }
}

function* onAddNewCompany({ payload: { company, history } }) {
  try {
    yield put(updateCompanyLoadingState(true))
    const response = yield call(addNewCompany, company)
    toastr.success("New Company Added Successfully..!!")
    yield put(addCompanySuccess(response.data.data))
    yield put(updateCompanyLoadingState(false))
    setTimeout(() => {
      history("/companies-list")
    }, 1500)
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateCompanyLoadingState(false))
    yield put(addCompanyFail(error))
  }
}

function* fetchCompanyBillingDetail({ companyId }) {
  try {
    const response = yield call(getCompanyBillingDetails, companyId)
    yield put(getCompanyBillingInfoSuccess(response.billingInfo))
  } catch (error) {
    yield put(getCompanyBillingInfoFail(error))
  }
}

function* onAddCompanyBillingDetail({ companyId, billingInfo }) {
  try {
    const response = yield call(
      addCompanyBillingDetails,
      companyId,
      billingInfo
    )
    yield put(addCompanyBillingInfoSuccess(response.data))
    toastr.success("BillingInfo Added successfully..!!")
  } catch (error) {
    yield put(addCompanyBillingInfoFail(error))
  }
}

function* onUpdateCompanyBillingDetail({ billingId, billingInfo }) {
  try {
    const response = yield call(
      updateCompanyBillingDetails,
      billingId,
      billingInfo
    )
    yield put(updateCompanyBillingInfoSuccess(response.data))
    toastr.success("BillingInfo updated successfully..!!")
  } catch (error) {
    yield put(updateCompanyBillingInfoFail(error))
  }
}

function* onGetCompanyPostedJobs({ companyId }) {
  try {
    const response = yield call(getjobsPostedByCompany, companyId)
    yield put(getJobDetailsPosteByCopmanySuccess(response.data.jobCounts))
  } catch (error) {
    yield put(getJobDetailsPosteByCopmanyFail(error))
  }
}

function* onGetCompanySubscriptions({ companyId }) {
  try {
    yield put(updateCompanyLoadingState(true))
    const response = yield call(getUserSubscriptions)
    yield put(getCompanySubscriptionsSuccess(response?.user))
    yield put(updateCompanyLoadingState(false))
  } catch (error) {
    yield put(getCompanySubscriptionsFail(error))
    yield put(updateCompanyLoadingState(false))
  }
}

function* onGetCompanyPlans({ params }) {
  try {
    const { role } = params
    console.log(role)

    yield put(updateCompanyLoadingState(true))
    const response = yield call(getAllCompanyPlan, `?role=${role}`)
    yield put(getCompanyAllplanSuccess(response.data.subscription))
    yield put(updateCompanyLoadingState(false))
  } catch (error) {
    yield put(getCompanyAllplanFail(error))
    yield put(updateCompanyLoadingState(false))
  }
}

function* fetchUsersCompanyViewHistory({ candidateId }) {
  try {
    const response = yield call(getUserCompanyViewHistory, candidateId)
    yield put(getUsersCompanyViewHistorySuccess(response.data.viewCandidates))
  } catch (error) {
    yield put(getUsersCompanyViewHistoryFail(error))
  }
}

function* fetchCompanyCreditDetails({ companyId }) {
  try {
    const response = yield call(getCompanyCreditDetails, companyId)
    yield put(getCompanyCreditSuccess(response.companyCredit))
  } catch (error) {
    yield put(getCompanyCreditFail(error))
  }
}

function* companysSaga() {
  yield takeEvery(GET_COMPANIES, fetchCompanies),
    yield takeEvery(GET_COMPANY_DETAIL, fetchCompanyDetail),
    yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany),
    yield takeEvery(UPDATE_COMPANY, onUpdateCompany),
    yield takeEvery(DELETE_COMPANY, onDeleteCompany),
    yield takeEvery(GET_COMPANY_BILLING_INFO, fetchCompanyBillingDetail),
    yield takeEvery(ADD_COMPANY_BILLING_INFO, onAddCompanyBillingDetail),
    yield takeEvery(UPDATE_COMPANY_BILLING_INFO, onUpdateCompanyBillingDetail),
    yield takeEvery(GET_JOBS_POSTED_BY_COMPANY, onGetCompanyPostedJobs),
    yield takeEvery(GET_COMPANY_SUBSCRIPTION, onGetCompanySubscriptions),
    yield takeEvery(GET_COMPANY_ALL_PLAN, onGetCompanyPlans),
    yield takeEvery(GET_USERS_VIEW_HISTORY, fetchUsersCompanyViewHistory)
  yield takeEvery(GET_COMPANY_CREDIT, fetchCompanyCreditDetails)
}

export default companysSaga
