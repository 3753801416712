import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Button,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import placeholder from "../../assets/images/users/img_placeholder.png"
import experienceIcon from "../../assets/images/icons/experience.svg"
import salaryIcon from "../../assets/images/icons/salary.svg"
import onsiteIcon from "../../assets/images/icons/onSite.svg"
import locationIcon from "../../assets/images/icons/location.svg"
import vacancyIcon from "../../assets/images/icons/vacancyIcon.svg"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import {
  getJobDetail as onGetJobDetail,
  resetJobDetail as onResetJobDetail,
} from "../../store/jobs/actions"
import AppLoader from "../../components/Common/Loader"

function ViewJob() {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const staticURL = process.env.REACT_APP_STATIC_URL
  const [companyUser, setCompanyUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const { jobDetail, companyDetail } = useSelector(state => ({
    jobDetail: state.jobs.jobDetail,
    companyDetail: state.jobs.companyDetail,
  }))

  useEffect(() => {
    if (jobDetail && Object.keys(jobDetail).length) {
      setIsLoading(false)
    }
  }, [jobDetail])

  useEffect(() => {
    const user = localStorage.getItem("authUser")
    if (params && params.jobId) {
      dispatch(onGetJobDetail(params.jobId))
    }
    if (user && typeof user === "string") {
      setCompanyUser(JSON.parse(user))
    }
    return () => {
      setIsLoading(false)
      dispatch(onResetJobDetail())
    }
  }, [dispatch, onGetJobDetail, params.jobId])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Job list"
            navigate="/jobs-list"
            breadcrumbItem="job"
          />
          {isLoading ? (
            <AppLoader />
          ) : (
            <Row>
              <Col lg={7}>
                <Card className="h-100">
                  <CardBody>
                    <Row className="mb-4">
                      <Col lg={2} className="d-flex align-items-center">
                        <div className="img_job_outer">
                          <img
                            src={
                              jobDetail?.image
                                ? staticURL + jobDetail?.image
                                : placeholder
                            }
                            width={72}
                            height={72}
                            alt="Job"
                          />
                        </div>
                      </Col>
                      <Col lg={5}>
                        <h3 className="mb-2">{jobDetail?.jobTitle}</h3>
                        <p className="mb-2">Company: {companyDetail?.name}</p>
                      </Col>
                      <Col
                        lg={5}
                        className="d-flex justify-content-end align-items-center"
                      >
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline me-2" />
                          {moment(jobDetail?.createdAt).fromNow()}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="mb-3">Job Description</h4>
                        <div
                          className="job_description"
                          dangerouslySetInnerHTML={{
                            __html: `<p>${jobDetail?.description}</p>`,
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <h4 className="mb-3">Required Skills</h4>
                        <div>
                          {jobDetail &&
                          jobDetail?.skillIds &&
                          jobDetail?.skillIds.length > 0 ? (
                            jobDetail?.skillIds.map((skill, index) => (
                              <span key={index} className="skill_tag">
                                {skill?.name}
                              </span>
                            ))
                          ) : (
                            <p>No Skills Added</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5}>
                <Card className="h-100">
                  <CardBody>
                    <Row>
                      <Col>
                        <h3 className="mb-3">About Company</h3>
                        <h4 className="mb-2">Company Name</h4>
                        <p className="mb-3">{companyDetail?.name}</p>
                        <h5 className="mb-3">
                          No Of Vacancy ({jobDetail?.noOfVacancy})
                        </h5>
                        <h4 className="mb-2">Location</h4>
                        <p className="mb-3">
                          <i className="mdi mdi-map-marker font-size-17 me-2" />
                          {jobDetail.location}
                        </p>
                        <h3 className="mb-3">Other Information</h3>
                        <div className="additional_info mb-3 d-flex align-items-center">
                          <img
                            src={onsiteIcon}
                            width={35}
                            height={35}
                            alt="On Site"
                          />
                          <p className="mb-0 additional_info_name ms-2">
                            {jobDetail.jobType}
                          </p>
                        </div>
                        <div className="additional_info mb-3 d-flex align-items-center">
                          <img
                            src={onsiteIcon}
                            width={35}
                            height={35}
                            alt="On Site"
                          />
                          <p className="mb-0 additional_info_name ms-2">
                            {jobDetail.type}
                          </p>
                        </div>
                        <div className="additional_info mb-3 d-flex align-items-center">
                          <img
                            src={salaryIcon}
                            width={35}
                            height={35}
                            alt="Salary"
                          />
                          <p className="mb-0 additional_info_name ms-2">
                            {jobDetail.salaryRange}
                          </p>
                        </div>
                        <div className="additional_info mb-3 d-flex align-items-center">
                          <img
                            src={experienceIcon}
                            width={35}
                            height={35}
                            alt="Experience"
                          />
                          <p className="mb-0 additional_info_name ms-2">
                            {jobDetail.experience}
                          </p>
                        </div>
                        <div className="additional_info mb-3 d-flex align-items-center">
                          <img
                            src={locationIcon}
                            width={35}
                            height={35}
                            alt="Location"
                          />
                          <p className="mb-0 additional_info_name ms-2">
                            {jobDetail.location}
                          </p>
                        </div>
                        <div className="additional_info mb-3 d-flex align-items-center">
                          <img
                            src={vacancyIcon}
                            width={35}
                            height={35}
                            alt="No Of Vacancy"
                          />
                          <p className="mb-0 additional_info_name ms-2">
                            {jobDetail.noOfVacancy}
                          </p>
                        </div>
                        <Button
                          color="primary"
                          className="global_btn btn btn-primary px-4 mt-2 w-100"
                          onClick={() =>
                            navigate(
                              `/applicant-list/${jobDetail?.companyId}/${jobDetail?._id}`
                            )
                          }
                        >
                          View Applicant ({jobDetail?.applicantsCount || "0"})
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewJob
