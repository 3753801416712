import React from "react"
import { Card, CardBody } from "reactstrap"
import AppLoader from "../Loader/index"
import moment from "moment"

function CardEducationalDetails({ educationalDetails, loading = false }) {
  return (
    <Card style={{ borderRadius: 15 }}>
      <CardBody>
        {loading ? (
          <AppLoader />
        ) : (
          <div className="pt-3 px-2">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div>
                  <h4 className="sun_heading">Educational Details</h4>
                </div>
                {educationalDetails && educationalDetails.length > 0 ? (
                  educationalDetails.map((edu, index) => (
                    <div key={index}>
                      <div className="mt-4 d-flex">
                        <div>
                          <h4 className="post_name">{edu.collegeUniversity}</h4>
                          <p className="employer_name mb-1">
                            {edu.degreeName}
                            <span>
                              &nbsp; &#x2022; &nbsp;{edu.educationType}
                            </span>
                          </p>
                          <p className="employer_name mb-1">
                            Grade (%)
                            <span>
                              &nbsp; &#x2022; &nbsp;{edu.gradePercentage}
                            </span>
                          </p>
                          <p className="work_time my-2">
                            {moment(edu.yearOfStarting).format("YYYY")} -{" "}
                            {moment(edu.yearOfPassing).format("YYYY")}
                            <span>
                              &nbsp; &#x2022; &nbsp;{" "}
                              {moment(edu.yearOfPassing).diff(
                                moment(edu.yearOfStarting),
                                "years"
                              )}
                              &nbsp; years
                            </span>
                          </p>
                          <p className="employee_extra_info mb-1">
                            {edu.address}, {edu.city}, {edu.state},{" "}
                            {edu.country}
                          </p>
                        </div>
                      </div>

                      <div className="full_devider my-4"></div>
                    </div>
                  ))
                ) : (
                  <p>No Educational Details Added</p>
                )}
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default CardEducationalDetails
