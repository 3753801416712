import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Notification Redux States
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_DETAIL,
  ADD_NEW_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  GET_NOTIFICATIONS_RECEIVERS,
} from "./actionTypes"
import {
  getNotificationsSuccess,
  getNotificationDetailSuccess,
  getNotificationsReceiversSuccess,
  getNotificationDetailFail,
  addNotificationSuccess,
  updateNotificationSuccess,
  updateNotificationFail,
  deleteNotificationSuccess,
  deleteNotificationFail,
  updateNotificationLoadingState,
} from "./actions"

// Include both helper files with needed methods
import {
  createNotification,
  getNotifications,
  updateNotification,
  deleteNotification,
  getNotificationDetails,
  getNotificationReceivers,
} from "helpers/api/notificationApi"

function* fetchNotifications({ payload }) {
  try {
    const { page, limit, search } = payload
    let queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }`
    if (search && search?.length) {
      queryParams = queryParams + `&search=${search}`
    }
    yield put(updateNotificationLoadingState(true))
    const response = yield call(getNotifications, queryParams)
    yield put(getNotificationsSuccess(response.data.notifications))
    yield put(updateNotificationLoadingState(false))
  } catch (error) {
    yield put(updateNotificationLoadingState(false))
    // Dispatch an action to handle the failure
  }
}

function* fetchNotificationDetail({ notificationId }) {
  try {
    yield put(updateNotificationLoadingState(true))
    const response = yield call(getNotificationDetails, notificationId)
    yield put(getNotificationDetailSuccess(response.data.notification))
    yield put(updateNotificationLoadingState(false))
  } catch (error) {
    yield put(getNotificationDetailFail(error))
  }
}

function* onUpdateNotification({ payload: { notification, notificationId } }) {
  try {
    const response = yield call(
      updateNotification,
      notification,
      notificationId
    )
    toastr.success("Notification updated successfully..!!")
    yield put(updateNotificationSuccess(response.data.notification))
  } catch (error) {
    yield put(updateNotificationFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteNotification({ payload: notificationId }) {
  try {
    yield put(updateNotificationLoadingState(true))
    console.log(notificationId)
    const response = yield call(deleteNotification, notificationId)
    toastr.success("Notification deleted successfully..!!")
    yield put(updateNotificationLoadingState(false))
    yield put(deleteNotificationSuccess(notificationId))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateNotificationLoadingState(false))
    yield put(deleteNotificationFail(error))
  }
}

function* onAddNewNotification({ payload: { notification } }) {
  try {
    yield put(updateNotificationLoadingState(true))
    const response = yield call(createNotification, notification)
    toastr.success("New Notification Added Successfully..!!")
    yield put(addNotificationSuccess(response.data.notification))
    yield put(updateNotificationLoadingState(false))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateNotificationLoadingState(false))
  }
}

function* fetchNotificationsReceivers({ payload }) {
  try {
    const { page, limit, search, notificationId } = payload
    let queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }`
    console.log(payload, "params>")
    yield put(updateNotificationLoadingState(true))
    const response = yield call(
      getNotificationReceivers,
      notificationId,
      queryParams
    )
    yield put(getNotificationsReceiversSuccess(response.data))
    yield put(updateNotificationLoadingState(false))
  } catch (error) {
    yield put(updateNotificationLoadingState(false))
    // Dispatch an action to handle the failure
  }
}

function* notificationsSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotifications)
  yield takeEvery(ADD_NEW_NOTIFICATION, onAddNewNotification)
  yield takeEvery(DELETE_NOTIFICATION, onDeleteNotification)
  yield takeEvery(GET_NOTIFICATION_DETAIL, fetchNotificationDetail)
  yield takeEvery(UPDATE_NOTIFICATION, onUpdateNotification)
  yield takeEvery(GET_NOTIFICATIONS_RECEIVERS, fetchNotificationsReceivers)
}

export default notificationsSaga
