/* TEST_CATEGORIES */
export const GET_TEST_CATEGORIES = "GET_TEST_CATEGORIES"
export const GET_TEST_CATEGORIES_SUCCESS = "GET_TEST_CATEGORIES_SUCCESS"
export const GET_TEST_CATEGORIES_FAIL = "GET_TEST_CATEGORIES_FAIL"
export const RESET_TESTS_OF_CATEGORY = "RESET_TESTS_OF_CATEGORY"

/* GET_TESTS_BY_CATEGORY_ID */
export const GET_TESTS_BY_CATEGORY_ID = "GET_TESTS_BY_CATEGORY_ID"
export const GET_TESTS_BY_CATEGORY_ID_SUCCESS = "GET_TESTS_BY_CATEGORY_ID_SUCCESS"
export const GET_TESTS_BY_CATEGORY_ID_FAIL = "GET_TESTS_BY_CATEGORY_ID_FAIL"


/* TEST_CATEGORIES DETAIL*/
export const GET_TEST_CATEGORY_DETAIL = "GET_TEST_CATEGORY_DETAIL"
export const GET_TEST_CATEGORY_DETAIL_SUCCESS = "GET_TEST_CATEGORY_DETAIL_SUCCESS"
export const GET_TEST_CATEGORY_DETAIL_FAIL = "GET_TEST_CATEGORY_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_NEW_TEST_CATEGORY = "ADD_NEW_TEST_CATEGORY"
export const ADD_TEST_CATEGORY_SUCCESS = "ADD_TEST_CATEGORY_SUCCESS"
export const ADD_TEST_CATEGORY_FAIL = "ADD_TEST_CATEGORY_FAIL"

/**
 * Edit user
 */
export const UPDATE_TEST_CATEGORY = "UPDATE_TEST_CATEGORY"
export const UPDATE_TEST_CATEGORY_SUCCESS = "UPDATE_TEST_CATEGORY_SUCCESS"
export const UPDATE_TEST_CATEGORY_FAIL = "UPDATE_TEST_CATEGORY_FAIL"
export const UPDATE_LOADING_STATE = "UPDATE_LOADING_STATE"

/**
 * Delete user
 */
export const DELETE_TEST_CATEGORY = "DELETE_TEST_CATEGORY"
export const DELETE_TEST_CATEGORY_SUCCESS = "DELETE_TEST_CATEGORY_SUCCESS"
export const DELETE_TEST_CATEGORY_FAIL = "DELETE_TEST_CATEGORY_FAIL"
