import React from "react"
import { FormGroup, Label, FormFeedback } from "reactstrap"
import Select from "react-select"

const MultiSelect = ({
  name,
  label,
  options,
  selectedOptions,
  onChange,
  error,
  onBlur,
  invalid,
  isMulti = true,
}) => {
  const borderColor = invalid ? "red" : "gray"
  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Select
        value={selectedOptions}
        isMulti={isMulti}
        onChange={selected => {
          onChange(selected)
        }}
        onBlur={onBlur}
        options={options}
        isReq={true}
        errorMsg="required"
        onValidateFunc={e => {
          console.log(e)
        }}
        styles={{
          // Add a styles object to set the border color
          control: provided => ({
            ...provided,
            borderColor: borderColor,
          }),
        }}
      />
      {invalid ? (
        <p className="text-danger font-size-15 mt-1">{error}</p>
      ) : null}
    </FormGroup>
  )
}

export default MultiSelect
