import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
// Crypto Redux States
import {
  GET_APPLICANTS,
  UPDATE_APPLICANTS,
  GET_CANDIDATES,
  GET_MY_CANDIDATES,
  UNLOCK_CANDIDATES,
  GET_UNLOCK_CANDIDATES,
} from "./actionTypes"
import {
  getApplicantsSuccess,
  getApplicantsFail,
  updateApplicantsSuccess,
  updateApplicantsFail,
  updateApplicantsLoadingState,
  getCandidatesSuccess,
  getCandidatesFail,
  getMyCandidatesSuccess,
  getMyCandidatesFail,
  unlockApplicantsStatusSuccess,
  unlockApplicantsStatusFail,
  getUnlockCandidatesSuccess,
  getUnlockCandidatesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getApplicantsByJObdId,
  updateApplicantsStatus,
} from "helpers/api/JobApi"
import {
  unlockCandidateApi,
  getCandidatesByUserId,
  getMyCandidatesByUserId,
  getUnlockCandidates,
} from "helpers/api/companyApi"

function* fetchApplicants({ jobId, params }) {
  try {
    const { page, limit, search, companyId } = params
    const queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }`
    yield put(updateApplicantsLoadingState(true))
    let response
    response = yield call(getApplicantsByJObdId, companyId, jobId, queryParams)
    yield put(getApplicantsSuccess(response.data.applicants))
    yield put(updateApplicantsLoadingState(false))
  } catch (error) {
    yield put(updateApplicantsLoadingState(false))
    yield put(getApplicantsFail(error))
  }
}

function* updateApplicants({ data, params }) {
  try {
    yield put(updateApplicantsLoadingState(true))
    const response = yield call(updateApplicantsStatus, data)
    // yield put(updateApplicantsSuccess(response.data.updatedCandidate))
    toastr.success("Applicant updated successfully..!!")
    const { page, limit, companyId } = params
    const queryParams = `?page=${page}&limit=${limit}`
    res = yield call(getApplicantsByJObdId, companyId, jobId, queryParams)
    yield put(getApplicantsSuccess(res.data.applicants))
    yield put(updateApplicantsLoadingState(false))
  } catch (error) {
    yield put(updateApplicantsLoadingState(false))
    yield put(updateApplicantsFail(error))
  }
}

function* fetchCandidates({ userId, params }) {
  try {
    const { page, limit, search, state, city, degree, skills, experience } =
      params
    const queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }${state ? "&state=" + state : ""}${city ? "&city=" + city : ""}${
      degree ? "&degree=" + degree : ""
    }${skills ? "&skills=" + skills : ""}${
      experience ? "&experience=" + experience : ""
    }`
    yield put(updateApplicantsLoadingState(true))
    let response
    response = yield call(getCandidatesByUserId, userId, queryParams)
    yield put(getCandidatesSuccess(response.data.candidates))
    yield put(updateApplicantsLoadingState(false))
  } catch (error) {
    yield put(updateApplicantsLoadingState(false))
    yield put(getCandidatesFail(error))
  }
}

function* fetchMyCandidates({ userId, params }) {
  try {
    if (params) {
      const {
        page,
        limit,
        search,
        state,
        city,
        degree,
        skills,
        postedBy,
        experience,
      } = params
      const queryParams = `?page=${page}&limit=${limit}${
        search ? "&search=" + search : ""
      }${state ? "&state=" + state : ""}${city ? "&city=" + city : ""}${
        degree ? "&degree=" + degree : ""
      }${skills ? "&skills=" + skills : ""}${
        experience ? "&experience=" + experience : ""
      }${postedBy ? "&postedBy=" + postedBy : ""}`
      yield put(updateApplicantsLoadingState(true))
      let response
      response = yield call(getMyCandidatesByUserId, userId, queryParams)
      yield put(getMyCandidatesSuccess(response.data.myCandidates))
      yield put(updateApplicantsLoadingState(false))
    } else if (!params && userId) {
      yield put(updateApplicantsLoadingState(true))
      const response = yield call(getMyCandidatesByUserId, userId, null)
      yield put(getMyCandidatesSuccess(response.data.myCandidates))
      yield put(updateApplicantsLoadingState(false))
    }
  } catch (error) {
    yield put(updateApplicantsLoadingState(false))
    yield put(getMyCandidatesFail(error))
  }
}

function* unlockCandidates({ candidateId, applicants }) {
  try {
    yield put(updateApplicantsLoadingState(true))
    const response = yield call(unlockCandidateApi, candidateId)
    yield put(unlockApplicantsStatusSuccess(applicants))
    toastr.success("Applicant Unlocked..!!")
    yield put(updateApplicantsLoadingState(false))
  } catch (error) {
    yield put(updateApplicantsLoadingState(false))
    yield put(unlockApplicantsStatusFail(error))
    toastr.error(error.response.data.message)
  }
}

function* fetchUnlockCandidates({ companyId, params }) {
  try {
    if (params) {
      const {
        page,
        limit,
        search,
        state,
        city,
        degree,
        skills,
        postedBy,
        experience,
      } = params
      yield put(updateApplicantsLoadingState(true))
      const queryParams = `?companyId=${companyId}&page=${page}&limit=${limit}${
        search ? "&search=" + search : ""
      }${state ? "&state=" + state : ""}${city ? "&city=" + city : ""}${
        degree ? "&degree=" + degree : ""
      }${skills ? "&skills=" + skills : ""}${
        experience ? "&experience=" + experience : ""
      }${postedBy ? "&postedBy=" + postedBy : ""}`
      let response
      response = yield call(getUnlockCandidates, queryParams)
      yield put(getUnlockCandidatesSuccess(response.data.unlockCandidates))
      yield put(updateApplicantsLoadingState(false))
    } else if (!params && companyId) {
      const queryParams = `?companyId=${companyId}`
      yield put(updateApplicantsLoadingState(false))
      const response = yield call(getUnlockCandidates, queryParams)
      yield put(getUnlockCandidatesSuccess(response.data.unlockCandidates))
      yield put(updateApplicantsLoadingState(false))
    }
  } catch (error) {
    yield put(getUnlockCandidatesFail(false))
    yield put(getMyCandidatesFail(error))
  }
}

function* applicantsSaga() {
  yield takeEvery(GET_APPLICANTS, fetchApplicants)
  yield takeEvery(UPDATE_APPLICANTS, updateApplicants)
  yield takeEvery(GET_CANDIDATES, fetchCandidates)
  yield takeEvery(GET_MY_CANDIDATES, fetchMyCandidates)
  yield takeEvery(UNLOCK_CANDIDATES, unlockCandidates)
  yield takeEvery(GET_UNLOCK_CANDIDATES, fetchUnlockCandidates)
}

export default applicantsSaga
