import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Modal,
  ModalHeader,
  Spinner,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { renderFormField } from "./formFields"

const FormModal = ({
  modalBackdrop,
  setmodalBackdrop,
  children,
  modalAction,
  handleModalCloseAction,
  title,
  showTitle = true,
  formFields = [],
  data = {},
  size = "md",
  height = 250,
  leftLink = false,
  cancelBtn = false,
  openNewModal,
}) => {
  const [loading, setLoading] = useState(false)
  const toggle = () => setmodalBackdrop(false)

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [loading])

  const createValidationSchema = () => {
    const validationObj = {}
    formFields.forEach(field => {
      if (field.name === "firstName") {
        validationObj[field.name] = Yup.string()
          .matches(
            /^[a-zA-Z ]*$/,
            "First name must only contain letters and spaces"
          )
          .notOneOf(["", null], `Please Enter ${field.label}`)
          .required(`Please Enter ${field.label}`)
      } else if (field.name === "phone" || field.name === "contactNumber") {
        validationObj[field.name] = Yup.string()
          .matches(/^[0-9]+$/, {
            message: "Phone Number must contain only numeric digits",
          })
          .matches(/^\d{10}$/, {
            message: "Phone Number must be exactly 10 digits",
          })
          .required(`Please Enter ${field.label}`)
      } else {
        validationObj[field.name] = Yup.string().required(
          `Please Enter ${field.label}`
        )
      }
    })

    return Yup.object().shape(validationObj)
  }
  const formik = useFormik({
    initialValues: data,
    validationSchema: createValidationSchema(),
    onSubmit: values => {
      modalAction(values)
    },
  })

  return (
    <>
      <Modal
        isOpen={modalBackdrop}
        toggle={() => {
          toggle()
          handleModalCloseAction && handleModalCloseAction()
        }}
        size={size}
      >
        <form onSubmit={formik.handleSubmit}>
          {showTitle && (
            <ModalHeader
              toggle={() => {
                toggle()
                handleModalCloseAction && handleModalCloseAction()
              }}
              tag="h4"
            >
              {title}
            </ModalHeader>
          )}
          <ModalBody className="overflow-y-scroll" style={{ height: height }}>
            <Row>
              <Col xs={12}>
                {formFields.map(field => renderFormField(field, formik))}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row style={{ width: "100%" }}>
              {/* {leftLink && (
                <Col className="d-flex align-items-center">
                  <Button
                    onClick={() => {
                      toggle()
                      openNewModal && openNewModal()
                    }}
                    style={{ color: "#f9974e", backgroundColor: "none" }}
                  >
                    Forgot password?
                  </Button>
                </Col>
              )} */}
              <Col>
                <div className="text-end">
                  {cancelBtn && (
                    <Button
                      type="submit"
                      className="global_btn btn btn-primary px-4"
                      disabled={loading}
                      onClick={toggle}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    type="submit"
                    className="global_btn btn btn-primary px-4 ms-2"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner className="ms-2" color="light" size="sm" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}

export default FormModal
