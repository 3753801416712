export const columns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
    link: "/profile/view/company",
  },
  {
    header: "Name",
    name: "name",
    key: "NAME_DESCRIPTION",
    link: "/profile/view/company",
  },
  {
    header: "Contact Person Name",
    name: "contactPersonName",
    key: "DEFAULT",
    link: "/profile/view/company",
  },
  {
    header: "Mobile",
    name: "phone",
    key: "DEFAULT",
    link: "/profile/view/company",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/profile/view/company",
  },
  {
    header: "Address",
    name: "address",
    key: "DEFAULT",
    link: "/profile/view/company",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
  {
    header: "Active/Deactive",
    key: "SWITCH",
  },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Edit", type: "EDIT" },
      { label: "View", type: "VIEW" },
      { label: "Delete", type: "DELETE" },
    ],
  },
]

export const formFields = [
  {
    label: "Upload your logo here*",
    name: "image",
    type: "file",
    placeholder: "",
    required: true,
  },
]

export const formFields2 = [
  {
    name: "name",
    label: "Company Name",
    type: "text",
    placeholder: "Enter Name",
    required: true,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    placeholder: "Enter Email",
    required: true,
    disabled: true,
  },
  {
    name: "website",
    label: "Website",
    type: "text",
    placeholder: "Website",
    required: true,
  },
  {
    name: "phone",
    label: "Phone",
    type: "text",
    placeholder: "Phone",
    required: true,
  },
  {
    name: "cityState",
    label: "",
    type: "cityState",
    lg: 12,
    state: {
      name: "state",
      label: "Select State",
      type: "select",
      isExternal: true,
      slug: "state",
      required: true,
    },
    city: {
      name: "city",
      label: "Select City",
      type: "select",
      isExternal: true,
      slug: "city",
      required: true,
    },
  },
  {
    name: "contactPersonName",
    label: "Contact Person Name",
    type: "text",
    placeholder: "Enter Contact Person Name",
    required: true,
  },
  {
    name: "contactNumber",
    label: "Contact Person Number",
    type: "text",
    placeholder: "Enter Contact Person Number",
    required: true,
  },
  {
    name: "address",
    label: "Address Line 1",
    type: "text",
    placeholder: "Address Line 1",
    required: true,
  },
  {
    name: "headQuarter",
    label: "Head Quarter",
    type: "text",
    required: true,
    placeholder: "Enter Head Quarter Location",
  },
  {
    name: "companySize",
    label: "Company Size",
    type: "text",
    required: true,
    placeholder: "Enter Company Size",
  },
  {
    name: "establishment",
    label: "Establishment Date",
    type: "date",
    required: true,
  },
]

export const renderTOFormFields = isEdit => {
  return [
    {
      name: "name",
      label: "Company Name",
      type: "text",
      placeholder: "Enter Company Name",
      required: true,
    },
    {
      name: "contactPersonName",
      label: "Contact Person Name",
      type: "text",
      placeholder: "Enter contact person name",
      required: true,
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "Enter Email",
      required: true,
      disabled: isEdit,
    },
    {
      name: "address",
      label: "Address",
      type: "textarea",
      placeholder: "Enter Address",
      required: true,
    },
  ]
}

export const billingFormFields = [
  {
    name: "billingAddress",
    label: "Billing Address",
    type: "text",
    placeholder: "Enter Billing Address",
    required: true,
  },
  {
    name: "gstNumber",
    label: "GST Number",
    type: "text",
    placeholder: "Enter GST Number",
    required: true,
  },
  {
    name: "cityState",
    label: "",
    type: "cityState",
    lg: 12,
    state: {
      name: "state",
      label: "Select State",
      type: "select",
      isExternal: true,
      slug: "state",
      required: true,
    },
    city: {
      name: "city",
      label: "Select City",
      type: "select",
      isExternal: true,
      slug: "city",
      required: true,
    },
  },
  {
    name: "postalCode",
    label: "Postal Code",
    type: "text",
    placeholder: "Enter Postal Code",
    required: true,
  },
]

export const jobPostscolumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Job Title",
    name: "jobTitle",
    key: "DEFAULT",
    // defaultId: "testId",
    link: "/jobs-details",
  },
  {
    header: "Salary Range",
    name: "salaryRange",
    key: "DEFAULT",
  },
  {
    header: "location",
    name: "location",
    key: "DEFAULT",
  },
  {
    header: "Experience",
    name: "experience",
    key: "DEFAULT",
  },
  {
    header: "Job Type",
    name: "type",
    key: "DEFAULT",
  },
  {
    header: "Candidate applied",
    name: "totalCandidatesApplied",
    key: "DEFAULT",
  },
  {
    header: "Job Status",
    name: "totalCandidatesHired",
    key: "DEFAULT",
  },
]

export const UsersColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    link: "/profile/view/hr-details",
  },
  {
    header: "Mobile",
    name: "phone",
    key: "DEFAULT",
    link: "/profile/view/hr-details",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/profile/view/hr-details",
  },
  {
    header: "Address",
    name: "address",
    key: "DEFAULT",
    link: "/profile/view/hr-details",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
  {
    header: "verify User",
    name: "emailVerifyStatus",
    key: "SWITCH2",
    CASE: "CASE1",
  },
  {
    header: "Active/Deactive",
    key: "SWITCH",
    CASE: "CASE2",
  },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Reset Password", type: "RESET_PASSWORD" },
      // { label: "View", type: "VIEW" },
      { label: "Edit", type: "EDIT" },
    ],
  },
]

export const transactColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Product Category",
    name: "purpose",
    key: "DEFAULT",
  },
  {
    header: "Product Name",
    name: "productname",
    key: "DEFAULT",
  },
  {
    header: "Transaction Id",
    name: "transactionId",
    key: "DEFAULT",
  },
  {
    header: "Transaction Type",
    name: "transactionType",
    key: "DEFAULT",
  },
  {
    header: "DATE",
    name: "createdAt",
    key: "DATE",
  },
  {
    header: "Amount",
    name: "amount",
    key: "DEFAULT",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
  // {
  //   header: "Actions",
  //   key: "MORE_ACRIONS",
  //   dropDownOptions: [{ label: "download invoice", type: "INVOICE" }],
  // },
]

export const companytransactColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Product Name",
    name: "productname",
    key: "DEFAULT",
  },
  {
    header: "Transaction Id",
    name: "transactionId",
    key: "DEFAULT",
  },
  {
    header: "Transaction Type",
    name: "transactionType",
    key: "DEFAULT",
  },
  {
    header: "Invoice Number",
    name: "invoiceNumber",
    key: "DEFAULT",
  },
  {
    header: "DATE",
    name: "createdAt",
    key: "DATE_AND_TIME",
  },
  {
    header: "Amount",
    name: "amount",
    key: "AMOUNT",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
  {
    header: "Download Invoice",
    name: "invoice",
    key: "INVOICE",
  },
]

export const resetHrpasswordfields = [
  {
    label: "New Password",
    name: "newPassword",
    className: "input_field",
    type: "text",
    placeholder: "New Password",
  },
  {
    label: "Confirm Password",
    name: "confirmPassword",
    className: "input_field",
    type: "text",
    placeholder: "Confirm Password",
  },
]

export const ApplicantsColumns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    // link:"/student-details"
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "Contact",
    name: "phone",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "job Title",
    name: "jobTitle",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "Location",
    name: "location",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "No Of Vacancy",
    name: "noOfVacancy",
    key: "DEFAULT",
    // link: "/student-details",
  },
  {
    header: "salary Range",
    name: "salaryRange",
    key: "DEFAULT",
    // link: "/student-details",
  },
]

export const UsersColumns2 = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Name",
    name: "name",
    key: "DEFAULT",
    link: "/profile/view/hr-details",
  },
  {
    header: "Mobile",
    name: "phone",
    key: "DEFAULT",
    link: "/profile/view/hr-details",
  },
  {
    header: "Email",
    name: "email",
    key: "DEFAULT",
    link: "/profile/view/hr-details",
  },
  {
    header: "Address",
    name: "address",
    key: "DEFAULT",
    link: "/profile/view/hr-details",
  },
  {
    header: "Status",
    name: "status",
    key: "STATUS",
  },
]
