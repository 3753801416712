/* COLLEGES */
export const GET_COLLEGES = "GET_COLLEGES"
export const GET_COLLEGES_SUCCESS = "GET_COLLEGES_SUCCESS"
export const GET_COLLEGES_FAIL = "GET_COLLEGES_FAIL"

/* COLLEGES DETAIL*/
export const GET_COLLEGE_DETAIL = "GET_COLLEGE_DETAIL"
export const GET_COLLEGE_DETAIL_SUCCESS = "GET_COLLEGE_DETAIL_SUCCESS"
export const GET_COLLEGE_DETAIL_FAIL = "GET_COLLEGE_DETAIL_FAIL"
export const UPDATE_COLLEGE_DETAIL = "UPDATE_COLLEGE_DETAIL"


/**
 * add course
 */
export const ADD_NEW_COLLEGE = "ADD_NEW_COLLEGE"
export const ADD_COLLEGE_SUCCESS = "ADD_COLLEGE_SUCCESS"
export const ADD_COLLEGE_FAIL = "ADD_COLLEGE_FAIL"

/**
 * Edit course
 */
export const UPDATE_COLLEGE = "UPDATE_COLLEGE"
export const UPDATE_COLLEGE_SUCCESS = "UPDATE_COLLEGE_SUCCESS"
export const UPDATE_COLLEGE_FAIL = "UPDATE_COLLEGE_FAIL"

/**
 * Delete course
 */
export const DELETE_COLLEGE = "DELETE_COLLEGE"
export const DELETE_COLLEGE_SUCCESS = "DELETE_COLLEGE_SUCCESS"
export const DELETE_COLLEGE_FAIL = "DELETE_COLLEGE_FAIL"

export const RESET_COLLEGE = "RESET_COLLEGE"

export const UPDATE_COLLEGE_LOADING_STATE = "UPDATE_COLLEGE_LOADING_STATE"
