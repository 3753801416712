import React, { useEffect, useState } from "react"
import { Container, Row, Col, Spinner } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/avatar-1.jpg"
import CardProfile from "../../components/Common/Card/ProfileHeader"
import CardAbout from "../../components/Common/Card/CardAbout"
import CardInfo from "../../components/Common/Card/CardInfo"
import { useSelector, useDispatch } from "react-redux"
import {
  getCollegeDetail as onGetCollegeDetail,
  resetCollege as onResetCollege,
} from "../../store/colleges/actions"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

const staticURL = process.env.REACT_APP_STATIC_URL

function CollegeProfile(props) {
  const role = localStorage.getItem("authRole")

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = props.router.params
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const { loading, collegeDetail } = useSelector(state => ({
    loading: state.colleges.loading,
    collegeDetail: state.colleges?.collegeDetail,
  }))

  useEffect(() => {
    if (params.collegeId) {
      if (role === "admin") {
        dispatch(onGetCollegeDetail(params.collegeId, "college"))
      } else {
        dispatch(onGetCollegeDetail(params.collegeId, "users/me"))
      }
    }
    return () => {
      dispatch(onResetCollege())
      setIsLoading(false)
    }
  }, [dispatch, onGetCollegeDetail])

  useEffect(() => {
    if (collegeDetail && Object.keys(collegeDetail).length) {
      setUser({ ...collegeDetail, ...collegeDetail.extra })
      setIsLoading(false)
    }
  }, [collegeDetail])

  const fields = user
    ? [
        { label: "College Name", value: user.name },
        { label: "Email*", value: user.email },
        { label: "Phone", value: user.phone },
        { label: "Address", value: user.address },
        { label: "City*", value: user.city },
        { label: "State*", value: user.state },
        { label: "Contact Person Number*", value: user.contactNumber },
        { label: "Contact Person Name*", value: user.contactPersonName },
        { label: "Establishment Date*", value: user.establishmentDate },
        { label: "Website", value: user.website },
      ]
    : []

  const tabsArray = [
    {
      id: "tab1",
      label: "Personal Details",
      content: (
        <Col sm="12">
          <CardInfo title="Personal Details" fields={fields} />
        </Col>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={role === "admin" ? "Colleges List" : "Dashboard"}
            breadcrumbItem={
              "College " + (role === "admin" ? "Detail" : "Profile")
            }
            navigate={role === "admin" ? "/colleges-list" : "/dashboard"}
          />
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner className="ms-2" color="dark" size="sm" />
            </div>
          ) : (
            <Row>
              <Col lg={12}>
                <CardProfile
                  imageUrl={
                    user && user.image ? staticURL + user.image : avatar
                  }
                  data={user}
                  ButtonType={role !== "admin" ? "EDIT" : ""}
                  profileName={user?.name ? user?.name : fullname}
                  profileEmail={user ? user.email : ""}
                  onclick={() => navigate(`/profile/edit/college/${user._id}`)}
                  LayoutType={"HORIZONTAL_TABS"}
                  tabs={tabsArray}
                  style={{ padding: 0 }}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CollegeProfile)
