import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap"
import moment from "moment"
//redux
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import ProfileHeader from "../../components/Common/Card/ProfileHeader"
import AppForm from "../../components/Common/Form/index"
import CardInfo from "../../components/Common/Card/CardInfo"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { getCompanyDetail as onGetCompanyDetail } from "../../store/companies/actions"
import { getRenderFields } from "./companyUtills"

import { formFields2 } from "./constants"
import { handleFormSubmit } from "./companyUtills"

const staticURL = process.env.REACT_APP_STATIC_URL

const CompanyProfileTab = props => {
  //meta title
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [user, setUser] = useState({})
  // const [image, setImage] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [showEdit, setShowEdit] = useState(true)
  const [userRole, setUserRole] = useState("")

  const { companyDetail, error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
    companyDetail: state.companies?.companyDetail,
  }))

  useEffect(() => {
    if (params.companyId) {
      dispatch(onGetCompanyDetail(params.companyId, "company"))
    } else {
      dispatch(onGetCompanyDetail(params.companyId, "users/me"))
    }
  }, [dispatch, onGetCompanyDetail])

  useEffect(() => {
    if (companyDetail) {
      const establishmentDate =
        companyDetail?.extra?.establishment || companyDetail?.establishment
      const formattedDate = establishmentDate
        ? moment(establishmentDate).format("YYYY-MM-DD")
        : ""
      setUser({
        ...companyDetail,
        ...companyDetail?.extra,
        establishment: formattedDate,
      })
    }
  }, [companyDetail])

  const handleSubmit = (formikValues,formik) => {
    handleFormSubmit(
      formikValues,
      formik,
      companyDetail,
      companyDetail.image,
      user,
      dispatch,
      navigate,
      setShowEdit
    )
  }

  // const handleImage = file => {
  //   setImage(file)
  // }

  const handleCancel = () => {
    setShowEdit(true)
  }

  const fields = getRenderFields(user, "personal")

  return (
    <div>
      {error && error ? <Alert color="danger">{error}</Alert> : null}
      {success ? <Alert color="success">{success}</Alert> : null}
      {showEdit && (
        <>
          <CardInfo
            title="Personal Details"
            fields={fields}
            onClick={() => setShowEdit(false)}
            isButton={"company"}
            BtnTitle="Edit"
          />
        </>
      )}
      {!showEdit && (
        <>
          <Card style={{ borderRadius: 15 }}>
            <div className="pt-3 px-2">
              <AppForm
                name="Personal Details"
                layoutType="UPDATE"
                fields={formFields2}
                initialValues={{
                  ...user,
                  city: user ? user?.city : "",
                  state: user ? user?.state : "",
                }}
                handleFormSubmit={handleSubmit}
                // handleImage={handleImage}
                saveTitle="Save"
                flex="end"
                navigateToCancelButton={false}
                handleCancel={handleCancel}
              />
            </div>
          </Card>
        </>
      )}
    </div>
  )
}

export default CompanyProfileTab
