import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import toastr from "toastr"
import { Col, Container, Row } from "reactstrap"
import {
  getRoles as onGetRoles,
  getColleges as onGetColleges,
  getCoachings as onGetCoachings,
  getStudents as onGetStudents,
  addNewStudent as onAddNewStudent,
  updateStudent as onUpdateStudent,
  deleteStudent as onDeleteStudent,
} from "../../store/actions"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import FormModal from "../../components/Common/Modal/FormModal"
const authRole = localStorage.getItem("authRole")
import { studentColumns } from "./constants"
import {  renderToFormFields,userProfileFrame } from "./Utills"
import { sendVerificationMailToUser } from "../../helpers/api/studentApi"

const StudentsList = () => {
  document.title = "Student List | QAPRENEUR"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [student, setStudent] = useState({
    name: "",
    email: "",
    collegeId: "",
    coachingId: "",
  })
  const [formModal, setFormModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [studentList, setStudentList] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { students, colleges, coachings, roles, loading } = useSelector(
    state => ({
      students: state?.students?.students,
      colleges: state.colleges?.colleges,
      coachings: state.coachings?.coachings,
      roles: state.roles?.roles,
      loading: state?.students?.loading,
    })
  )

  const studentRoleId = roles.filter(
    role => role.status === "active" && role.role === "student"
  )

  const handleModalAction = () => {
    if (student && student._id) {
      const params = {
        page: pageNo,
        limit: rowPerPage,
      }
      dispatch(onDeleteStudent(student._id, params))
    }
    setDeleteModal(false)
  }

  useEffect(() => {
    const params = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      params.search = search
    }
    dispatch(onGetStudents(params))
    dispatch(onGetColleges(null))
    dispatch(onGetCoachings(null))
    dispatch(onGetRoles())
  }, [dispatch, pageNo, rowPerPage, search])

  useEffect(() => {
    if (!isEmpty(students)) {
      setStudentList(
        students
          .map(s => ({
            ...s,
            status: s.emailVerified ? s.status : "unverified",
             userProfile : s ? userProfileFrame(s) : "",
            emailVerifyStatus: s.emailVerified ? "active" : "deactive",
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    }
  }, [students, pageNo])

  const toggleForm = () => setFormModal(!formModal)

  const handleChangeStatus = (student, CASE) => {
    if (CASE === "CASE2") {
      const status = student.status === "active" ? "deactive" : "active"
      dispatch(onUpdateStudent({ status }, student._id, navigate))
    }
    if (CASE === "CASE1") {
      const emailVerified =
        student.emailVerifyStatus === "active" ? false : true
      dispatch(onUpdateStudent({ emailVerified }, student._id, navigate))
    }
  }

  const handleAddStudentClick = () => {
    setIsEdit(false)
    setStudent({
      name: "",
      email: "",
      collegeId: "",
      coachingId: "",
    })
    toggleForm()
  }

  const onChangePagination = (limit, pageNo) => {
    setRowPerPage(limit)
    setPageNo(pageNo)
  }

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const handleActions = (student, type) => {
    if (type === "VIEW") {
      navigate(`/student-details/${student._id}`)
    } else if (type === "EDIT") {
      setStudent({
        name: student?.name,
        email: student?.email,
        collegeId: student?.collegeId,
        coachingId: student?.coachingId,
        _id: student?._id,
        verifystatus: student?.emailVerified,
      })
      setIsEdit(true)
      toggleForm()
    } else if (type === "DELETE") {
      setStudent(student)
      setDeleteModal(true)
    } else if (type === "MAIL") {
      if (student?.emailVerified == false) {
        sendMail({
          userId: student?._id,
          email: student?.email,
          name: student?.name,
          firstName: student?.firstName,
        })
      } else {
        toastr.error("Student already verified")
      }
    }
  }

  const sendMail = async data => {
    const res = await sendVerificationMailToUser(data)
    res.message && toastr.success(res.message)
  }

  const handleSave = values => {
    if (studentRoleId && studentRoleId.length > 0 && studentRoleId[0]?._id) {
      values.roleId = studentRoleId[0]?._id
      const splitingName = values?.name.split(" ")
      const firstName = splitingName?.length > 0 ? splitingName[0] : values.name
      const lastName =
        splitingName?.length > 1 ? splitingName[splitingName.length - 1] : ""
      const newValues = { firstName: firstName, lastName: lastName }
      if (isEdit) {
        delete values.email
        dispatch(
          onUpdateStudent({ ...values, ...newValues }, student._id, navigate)
        )
      } else {
        dispatch(onAddNewStudent({ ...values, ...newValues }, navigate))
      }
    } else {
      toastr.error("Student role not found")
    }
    toggleForm()
  }

  const coachingDropDownData =
    coachings &&
    coachings.length &&
    coachings.filter((coaching, index) => {
      return coaching.status === "active"
    })

  const coachingOptions =
    Array.isArray(coachingDropDownData) && coachingDropDownData.length
      ? coachingDropDownData.map(c => {
          return { value: c?._id, label: c?.name }
        })
      : []

  const collegeDropDownData =
    colleges &&
    Array.isArray(colleges) &&
    colleges.length &&
    colleges.filter((college, index) => {
      return college.status === "active"
    })

  const collegeOptions =
    Array.isArray(collegeDropDownData) && collegeDropDownData.length
      ? collegeDropDownData.map(c => {
          return { value: c?._id, label: c?.name }
        })
      : []

  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the student.?"}
          modalAction={handleModalAction}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Students" breadcrumbItem="Students" />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={studentList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={rowPerPage}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  handleStatus={
                    authRole === "company" ? null : handleChangeStatus
                  }
                  handleActions={authRole === "company" ? null : handleActions}
                  onChangePagination={onChangePagination}
                  addButton={
                    authRole === "company"
                      ? null
                      : {
                          onclick: handleAddStudentClick,
                          title: "Add New Student",
                        }
                  }
                  rowLink="/student-details"
                  handleSearch={handleSearch}
                  columns={studentColumns}
                  showPagination={true}
                />
              </div>
            </Col>
          </Row>
          {formModal && (
            <FormModal
              modalBackdrop={formModal}
              setmodalBackdrop={setFormModal}
              modalAction={handleSave}
              title={!!isEdit ? "Edit Student" : "Add Student"}
              formFields={renderToFormFields(
                coachingOptions,
                collegeOptions,
                isEdit
              )}
              data={student}
              height={450}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StudentsList
