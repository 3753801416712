import React, { useEffect, useState } from "react"
import { Container, Row, Col, Spinner, Card, CardBody } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/avatar-1.jpg"
import CardProfile from "../../components/Common/Card/ProfileHeader"
import CardInfo from "../../components/Common/Card/CardInfo"
import CardAbout from "../../components/Common/Card/CardAbout"
import { useSelector, useDispatch } from "react-redux"
import AppLoader from "../../components/Common/Loader/index"
import TransactionsTab from "../../components/Tabs/TransactionsTab"
import CompanyProfileTab from "./CompanyProfileTab"
import CompanyAboutTab from "./CompanyAboutTab"
import axios from "axios"
import {
  getCompanyDetail as onGetCompanyDetail,
  resetCompany as onResetCompany,
  getJobDetailsPosteByCopmany as onGetJobDetailsPosteByCopmany,
  getCompanySubscriptions as onGetCompanySubscriptions,
  getCompanyAllplan as onGetCompanyAllplan,
} from "../../store/companies/actions"
import {
  getHrUsers as onGetUsers,
  getMyCandidates as onGetMyCandidates,
  getUnlockCandidates as onGetUnlockCandidates,
} from "../../store/actions"
import { updateCompany as onUpdateCompany } from "../../store/companies/actions"
import {
  createTransactions,
  checkTransaction,
} from "../../helpers/api/transactionsApi"
import { useNavigate, useParams } from "react-router-dom"
import { getRenderFields } from "./companyUtills"
import CompanyBilling from "./companyBilling"
import {
  jobPostscolumns,
  transactColumns,
  ApplicantsColumns,
  UsersColumns2,
} from "./constants"
import { unlockedCandidatesColumns } from "../ApplicantsManagement/constants"
import TableContainer from "../../components/Common/Table/index"
import AppPlan from "../../components/Common/Plan"
import toastr from "toastr"
import { handleFormSubmit } from "./companyUtills"

import { getUserTabData as onGetUserTabData } from "../../store/students/actions"
import UnlockCandidatesTab from "./UnlockCandidatesTab"

import {
  generateKey as generatePaymentKey,
  generateRandomId,
  getTransactionStatus,
  purChaseSubsCriptions,
  updateTransactionsStatus,
} from "../../helpers/api/transactionsApi"

const staticURL = process.env.REACT_APP_STATIC_URL

function CompanyProfile() {
  const role = localStorage.getItem("authRole")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [jobList, setJobList] = useState([])
  const [sec, setSec] = useState(0)
  const [hash, setHash] = useState("")
  const [txnStatus, setTxnStatus] = useState(false)
  const [txnInfo, setTxnInfo] = useState(null)
  const [Time, setTime] = useState(0)
  const [image, setImage] = useState("")

  const {
    loading,
    companyDetail,
    jobs,
    Subscriptions,
    activePlan,
    Plans,
    myCandidates,
    HrUsers,
    unlockCandidates,
    candidateLoading,
  } = useSelector(state => ({
    loading: state.companies.loading,
    companyDetail: state.companies?.companyDetail,
    jobs: state.companies?.jobs,
    Subscriptions: state.companies?.Subscriptions,
    activePlan: state.companies?.activePlan,
    Plans: state.companies?.Plans,
    myCandidates: state.applicants.myCandidates,
    HrUsers: state?.HrUsers?.hrUsers,
    // unlockCandidates: state.applicants.unlockCandidates,
    unlockCandidates: state.students.unlockCandidates,
    candidateLoading: state.students.loading,
  }))

  useEffect(() => {
    if (params.companyId) {
      if (role === "admin") {
        dispatch(onGetCompanyDetail(params.companyId, "company"))
        dispatch(onGetCompanySubscriptions(params.companyId))
        dispatch(onGetJobDetailsPosteByCopmany(params.companyId))
        dispatch(onGetMyCandidates(params.companyId, null))
        // dispatch(onGetUnlockCandidates(params.companyId, null))
        dispatch(onGetUsers(params))
      } else {
        dispatch(onGetCompanyDetail(params.companyId, "users/me"))
        dispatch(onGetCompanySubscriptions(params.companyId))
        dispatch(onGetCompanyAllplan({ role: "company" }))
      }
    }
    return () => {
      dispatch(onResetCompany())
      setIsLoading(false)
    }
  }, [dispatch, onGetCompanyDetail])

  useEffect(() => {
    if (companyDetail && Object.keys(companyDetail).length) {
      setUser({ ...companyDetail, ...companyDetail.extra })
      setIsLoading(false)
    }
  }, [companyDetail])

  useEffect(() => {
    if (jobs && jobs.length > 0) {
      setJobList(
        jobs.map(job => ({
          ...job.jobDetail,
          totalCandidatesApplied: job.totalCandidatesApplied,
          totalCandidatesHired: job.totalCandidatesHired,
        }))
      )
    }
  }, [jobs])

  useEffect(() => {
    let shouldContinue = true

    const fetchData = async () => {
      try {
        const result = await getTransactionStatus(hash)
        // Check the transaction status and handle accordingly
        if (result && result.message_type === "success") {
          // Transaction is successful
          try {
            const response = await purChaseSubsCriptions({
              subscriptionId: txnInfo?.itemId,
              transactionId: txnInfo?.txnId,
            },'company')
            await updateTransactionsStatus({
              transactionId: txnInfo?.txnId,
              success: true,
              status: true,
            })
            setTxnStatus(false)
          } catch (error) {
            console.error(error)
            await updateTransactionsStatus({
              transactionId: txnInfo?.txnId,
              success: false,
              status: false,
            })
            setTxnStatus(false)
          }
        } else if (result && result.message_type === "error") {
          await updateTransactionsStatus({
            transactionId: txnInfo?.txnId,
            success: false,
            status: false,
          })
          // Stop the interval since the transaction status is now determined
          shouldContinue = false
          // setTxnStatus(false)
        } else {
          await updateTransactionsStatus({
            transactionId: txnInfo?.txnId,
            success: false,
            status: false,
          })
          // setTxnStatus(false)
        }

        // If the transaction status is determined, stop the interval
        if (!shouldContinue) {
          clearInterval(intervalId)
          setTxnStatus(false)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        // Handle the error state as needed
      }
    }

    // Call fetchData initially
    if (hash) {
      fetchData()
    }

    // Setup interval to call fetchData every minute
    const intervalId = setInterval(() => {
      if (hash && shouldContinue) {
        fetchData()
      }
    }, 1000) // 1 millisecond

    // Clear the interval when the component unmounts or when the transaction is no longer pending
    return () => clearInterval(intervalId)
  }, [hash, txnInfo])

  const fields = getRenderFields(user, "personal")

  const handlePlan = () => {
    navigate("/upgrade-plan")
    // handlePayment(item)
  }

  const handlePayment = async item => {
    const splitUsername = user && user?.name && user?.name.split(" ")
    const firstName =
      splitUsername && splitUsername.length > 0 && splitUsername[0]
    // const amount = `${parseFloat(1).toFixed(2)}`
    const amount = `${parseFloat(item?.price).toFixed(2)}`
    const txnId =
      Date.now() + Math.floor(Math.random() * 100000) + generateRandomId(10)
    // setTxnInfo(txnId)
    const itemId = item._id

    const response = await generatePaymentKey({
      firstname: firstName,
      email: user?.email,
      phone: user?.phone,
      productinfo: item?.name,
      amount: amount,
      surl: `https://qapreneur.com/transaction/success/${item._id}/${txnId}`,
      furl: `https://qapreneur.com/transaction/fail/${item._id}/${txnId}`,
      txnId: txnId,
    })
    // https://qapreneur.com/
    if (response?.data && response?.status === 1) {
      const url_link = `https://pay.easebuzz.in/pay/${response.data}`
      // Create an anchor element
      const transactionRes = await createTransactions({
        amount: amount,
        purpose: "Subscription Purchase",
        transactionId: txnId,
        transactionType: "Debit",
        txn_info: JSON.stringify({ productinfo: item?.name }),
        success: false,
      })
      setHash(response.data)
      setTime(15)
      setTxnInfo({
        txnId: txnId,
        itemId: itemId,
        hash: response.data,
        ...transactionRes?.data?.transaction,
      })

      if (transactionRes && transactionRes?.data?.transaction) {
        localStorage.setItem(
          "txn_info",
          JSON.stringify({
            transaction: transactionRes?.data?.transaction,
            hash: response.data,
          })
        )
        setTxnStatus(true)
        const anchor = document.createElement("a")
        anchor.href = url_link
        anchor.target = "_blank"
        anchor.click()
      } else {
        setHash("")
        toastr.error("Transaction creation failed")
      }
    } else {
      setHash("")
      toastr.error(response?.data + " " + response?.error_desc)
    }
  }

  const handleTabClick = tabId => {
    if (tabId === "UNLOCK_CANDIDATES") {
      dispatch(
        onGetUserTabData(tabId, params.companyId, `&page=${1}&limit=${10}`)
      )
    }
  }

  const tabsArray = [
    {
      id: "tab1",
      label: "Personal Details",
      content: (
        <Col sm="12">
          {role === "company" ? (
            <CompanyProfileTab />
          ) : (
            <CardInfo title="Personal Details" fields={fields} />
          )}
        </Col>
      ),
    },
    {
      id: "tab3",
      label: "About Company",
      content: (
        <Col sm="12">
          {role === "company" ? (
            <CompanyAboutTab />
          ) : (
            <CardAbout
              title="About Company"
              descripion={
                role === "hr_user"
                  ? user?.companyDetail?.extra?.about
                  : user?.about
              }
              isHtml={true}
            />
          )}
        </Col>
      ),
    },
    {
      id: "tab4",
      label: "Billing Details",
      content: (
        <Col lg={12}>
          <CompanyBilling />
        </Col>
      ),
    },
    // {
    //   id: "tab5",
    //   label: "Plan",
    //   content: (
    //     <Col lg={12}>
    //       <Card style={{ borderRadius: 15 }}>
    //         <CardBody>
    //           <div className="pt-3 px-2">
    //             <h4 className="sun_heading">Plan</h4>
    //             {role === "admin" && (
    //               <AppPlan
    //                 SubscriptionPlan={{
    //                   ...Subscriptions,
    //                   activePlan: activePlan,
    //                 }}
    //                 isActive={true}
    //                 showUpgradeButton={true}
    //                 />
    //             )}
    //             {!txnStatus && (
    //               <Row className="mt-5">
    //                 {Plans &&
    //                   Plans.length > 0 &&
    //                   Plans.map((Plans, key) => {
    //                     return (
    //                       <Col key={key}>
    //                         <CardBody
    //                           style={{
    //                             padding: 10,
    //                             border:
    //                               Subscriptions?.type === Plans?.type
    //                                 ? "2px solid #F89752"
    //                                 : "",
    //                           }}
    //                         >
    //                           <AppPlan
    //                             SubscriptionPlan={{
    //                               ...Plans,
    //                               activePlan: activePlan,
    //                             }}
    //                             isActive={Subscriptions?.type === Plans?.type}
    //                             handlePlan={handlePlan}
    //                           />
    //                         </CardBody>
    //                       </Col>
    //                     )
    //                   })}
    //               </Row>
    //             )}
    //             {txnStatus && (
    //               <>
    //                 <div className="d-flex justify-content-center flex-column align-items-center">
    //                   <Loader message="Waiting for transaction to complete..." />
    //                   <h5>Please wait while we process your transaction.</h5>
    //                   <h6 className="text-danger">
    //                     Do not close or refresh the page.
    //                   </h6>
    //                 </div>
    //               </>
    //             )}
    //           </div>
    //         </CardBody>
    //       </Card>
    //     </Col>
    //   ),
    // },
    ...(["admin"].includes(role)
      ? [
          {
            id: "transactionDetail",
            label: "Transaction Detail",
            content: (
              <Col lg={12}>
                <TransactionsTab
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  showPageDropdown={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={transactColumns}
                  tableName="transactionDetail"
                  notDataFound={true}
                  role={"company"}
                  userId={companyDetail?._id}
                />
              </Col>
            ),
          },
        ]
      : []),
    ...(role === "admin"
      ? [
          {
            id: "tab7",
            label: "Job Posts",
            content: (
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="pt-3 px-2">
                      <h4 className="sun_heading">Job Posts</h4>
                    </div>
                    <TableContainer
                      loading={loading}
                      data={jobList}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      isJobListGlobalFilter={false}
                      showPageDropdown={false}
                      customPageSize={10}
                      className="project-list-table table-nowrap align-middle table-borderless"
                      columns={jobPostscolumns}
                    />
                  </CardBody>
                </Card>
              </Col>
            ),
          },
          {
            id: "myApplicants",
            label: "My Applicants",
            content: (
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="pt-3 px-2">
                      <h4 className="sun_heading">My Applicants</h4>
                    </div>
                    <TableContainer
                      loading={loading}
                      data={
                        myCandidates && Array.isArray(myCandidates)
                          ? myCandidates.map(candidate => {
                              return {
                                ...candidate,
                                postedBy: candidate?.jobDetail?.postedBy,
                                jobTitle: candidate?.jobDetail?.jobTitle,
                                location: candidate?.jobDetail?.location,
                                noOfVacancy: candidate?.jobDetail?.noOfVacancy,
                                salaryRange: candidate?.jobDetail?.salaryRange,
                              }
                            })
                          : []
                      }
                      isGlobalFilter={false}
                      isAddOptions={false}
                      isJobListGlobalFilter={false}
                      showPageDropdown={false}
                      customPageSize={10}
                      className="project-list-table table-nowrap align-middle table-borderless"
                      columns={ApplicantsColumns}
                    />
                  </CardBody>
                </Card>
              </Col>
            ),
          },
          {
            id: "companyHr",
            label: "Users",
            content: (
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="pt-3 px-2">
                      <h4 className="sun_heading">My Users</h4>
                    </div>
                    <TableContainer
                      loading={loading}
                      data={HrUsers}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      isJobListGlobalFilter={false}
                      showPageDropdown={false}
                      customPageSize={10}
                      className="project-list-table table-nowrap align-middle table-borderless"
                      columns={UsersColumns2}
                    />
                  </CardBody>
                </Card>
              </Col>
            ),
          },
          {
            id: "UNLOCK_CANDIDATES",
            label: "Unlock Candidates",
            content: (
              <Col lg={12}>
                <UnlockCandidatesTab
                  unlockCandidates={unlockCandidates}
                  loading={candidateLoading}
                  userId={params.companyId}
                />
              </Col>
            ),
          },
        ]
      : []),
  ]

  const handleUploadImage = files => {
    setImage(files[0])
  }

  const handleSubmit = () => {
    dispatch(
      onUpdateCompany(
        "users/me",
        { image: image ? image : user?.image },
        user._id,
        ""
      )
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={role === "admin" ? "Company List" : "Dashboard"}
            breadcrumbItem={
              "Company " + (role === "admin" ? "Detail" : "Profile")
            }
            navigate={role === "admin" ? "/companies-list" : "/dashboard"}
          />
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <AppLoader />
            </div>
          ) : (
            <CardProfile
              imageUrl={user && user.image ? staticURL + user.image : avatar}
              ButtonType={!["admin", "hr_user"].includes(role) ? "EDIT" : ""}
              data={user}
              profileName={user?.name ? user?.name : ""}
              profileEmail={user ? user.email : ""}
              onclick={() => navigate(`/profile/edit/company/${user._id}`)}
              LayoutType={"HORIZONTAL_TABS"}
              tabs={tabsArray}
              style={{ padding: 0 }}
              showStatus={true}
              showPlan={true}
              planData={Subscriptions}
              {...("company" === role
                ? {
                    handleUploadImage: handleUploadImage,
                    handleSubmit: handleSubmit,
                  }
                : {})}
              showUpgradeButton={role === "company" ? true : false}
              handleUpgradePlan={handlePlan}
              handleTabClick={handleTabClick}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompanyProfile
