import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Papa from 'papaparse';

const CsvToJsonConverter = ({ handleCsvToJsonConverter }) => {
    const [csvData, setCsvData] = useState('');
    const [jsonData, setJsonData] = useState(null);
    const [error, setError] = useState(null);


    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    // Check if the required columns are present
                    if (
                        !result.meta.fields.includes('title') ||
                        !result.meta.fields.includes('A') ||
                        !result.meta.fields.includes('B') ||
                        !result.meta.fields.includes('C') ||
                        !result.meta.fields.includes('D') ||
                        !result.meta.fields.includes('answer') ||
                        !result.meta.fields.includes('description')
                    ) {
                        setError('Invalid CSV format. Make sure to include the required columns: title, A, B, C, D, answer, descriptions');
                        setJsonData(null);
                        return;
                    }
                    setCsvData(result.data);
                    setJsonData(result.data);
                    setError(null);
                    handleCsvToJsonConverter(result)
                },
                header: true, // If your CSV has headers
            });
        }
    };

    const handleDownload = () => {
        // Create a sample CSV content
        const sampleCsv = `title,A,B,C,D,answer,description\n
Example 1,Option A,Option B,Option C,Option D,A,Description for Example 1\n
Example 2,Option A,Option B,Option C,Option D,B,Description for Example 2\n`;

        // Create a Blob with the CSV content
        const blob = new Blob([sampleCsv], { type: 'text/csv' });

        // Create a temporary URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a hidden anchor element to trigger the download
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'questions_sample.csv';

        // Append the anchor element to the document and click it to trigger the download
        document.body.appendChild(a);
        a.click();

        // Remove the anchor element and revoke the URL to free up resources
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <>
            <div
                onClick={() => {
                    document.getElementById("csvImport").click()
                }}
                style={{
                    border: '2px dashed #ccc',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                }}
            >
                <input
                    type="file"
                    id="csvImport"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                <p>Upload a CSV file here, or click to select one.</p>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Button
                type="button"
                className="btn btn-primary mt-3"
                onClick={handleDownload}
            >
                Download sample CSV
            </Button>
        </>
    );
};

export default CsvToJsonConverter;
