import {
  GET_APPLICANTS_SUCCESS,
  GET_APPLICANTS_FAIL,
  RESET_APPLICANTS,
  UPDATE_APPLICANT_LOADING_STATE,
  UPDATE_APPLICANTS_SUCCESS,
  UPDATE_APPLICANTS_FAIL,
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATES_FAIL,
  GET_MY_CANDIDATES_SUCCESS,
  GET_MY_CANDIDATES_FAIL,
  UNLOCK_CANDIDATES_SUCCESS,
  UNLOCK_CANDIDATES_FAIL,
  GET_UNLOCK_CANDIDATES_FAIL,
  GET_UNLOCK_CANDIDATES_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  error: {},
  loading: false,
  applicants: [],
  Candidates: [],
  myCandidates: [],
  unlockCandidates: [],
}

const applicants = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_APPLICANT_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case GET_APPLICANTS_SUCCESS:
      return {
        ...state,
        applicants: action.payload,
      }
    case GET_APPLICANTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_APPLICANTS_SUCCESS:
      return {
        ...state,
        applicants: state.applicants.map(applicant =>
          applicant?._id.toString() === action.payload?.studentId.toString()
            ? { applicant, ...action.payload }
            : applicant
        ),
      }
    case UPDATE_APPLICANTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        Candidates: action.payload,
      }
    case GET_CANDIDATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_MY_CANDIDATES_SUCCESS:
      return {
        ...state,
        myCandidates: action.payload,
      }

    case GET_MY_CANDIDATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UNLOCK_CANDIDATES_SUCCESS:
      return {
        ...state,
        applicants: state.applicants.map(applicant =>
          applicant?._id.toString() === action.payload?.studentId.toString()
            ? { applicant, ...action.payload }
            : applicant
        ),
      }
    case UNLOCK_CANDIDATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_UNLOCK_CANDIDATES_SUCCESS:
      return {
        ...state,
        unlockCandidates: action.payload,
      }

    case GET_UNLOCK_CANDIDATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_APPLICANTS:
      return {
        ...state,
        error: {},
        loading: false,
        applicants: [],
      }
    default:
      return state
  }
}

export default applicants
