import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { columns } from "./constants"
import {
  updateMockTest as onUpdateMockTest,
  getHelpCenter as onGetHelpCenter,
} from "../../store/helpcenter/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"

// import Table2 from "../../components/Common/Table/Table2"
// import { GET_MOCK_TESTS } from "../../components/Common/Table/constants";

const MockTestsList = () => {
  document.title = "Test List | QAPRENEUR"
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [helpCenterList, setHelpCenterList] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { helpCenter, loading } = useSelector(state => ({
    helpCenter: state?.helpcenter?.helpCenter,
    loading: state?.helpcenter?.loading,
  }))

  const getData = () => {
    const params = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      params.search = search
    }

    dispatch(onGetHelpCenter(params))
  }

  useEffect(() => {
    getData()
  }, [pageNo, rowPerPage, search])

  useEffect(() => {
    if (helpCenter && Array.isArray(helpCenter)) {
      setHelpCenterList(
        helpCenter
          .map(item => ({ ...item, ...item.extra }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setHelpCenterList([])
    }
  }, [helpCenter, pageNo])

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const onChangePagination = (limit, page) => {
    setRowPerPage(limit)
    setPageNo(page)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Help Center" breadcrumbItem="Help Center" />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={helpCenterList}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={columns}
                  showPagination={true}
                  onChangePagination={onChangePagination}
                  // handleActions={handleActions}
                  handleSearch={handleSearch}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MockTestsList
