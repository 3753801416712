import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Announcement Redux States
import {
  GET_ANNOUNCEMENTS,
  GET_USER_ANNOUNCEMENTS,
  GET_ANNOUNCEMENT_DETAIL,
  ADD_NEW_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS_REPORT,
} from "./actionTypes"
import {
  getAnnouncementsSuccess,
  getAnnouncementDetailSuccess,
  getAnnouncementsReportSuccess,
  getAnnouncementDetailFail,
  addAnnouncementSuccess,
  updateAnnouncementSuccess,
  updateAnnouncementFail,
  deleteAnnouncementSuccess,
  deleteAnnouncementFail,
  updateAnnouncementLoadingState,
} from "./actions"

// Include both helper files with needed methods
import {
  createAnnouncement,
  getAnnouncements,
  updateAnnouncement,
  deleteAnnouncement,
  getAnnouncementDetails,
  getAnnouncementReports,
} from "helpers/api/announcementApi"

function* fetchAnnouncements({ payload }) {
  try {
    const { page, limit, search } = payload
    let queryParams = `?page=${page}&limit=${limit}${
      search ? "&search=" + search : ""
    }`
    yield put(updateAnnouncementLoadingState(true))
    const response = yield call(getAnnouncements, queryParams)
    yield put(getAnnouncementsSuccess(response.data))
    yield put(updateAnnouncementLoadingState(false))
  } catch (error) {
    yield put(updateAnnouncementLoadingState(false))
    // Dispatch an action to handle the failure
  }
}

function* fetchAnnouncementDetail({ announcementId }) {
  try {
    yield put(updateAnnouncementLoadingState(true))
    const response = yield call(getAnnouncementDetails, announcementId)
    yield put(getAnnouncementDetailSuccess(response.data))
    yield put(updateAnnouncementLoadingState(false))
  } catch (error) {
    yield put(getAnnouncementDetailFail(error))
  }
}

function* onUpdateAnnouncement({ payload: { announcement, announcementId } }) {
  try {
    const response = yield call(
      updateAnnouncement,
      announcement,
      announcementId
    )
    toastr.success("Announcement updated successfully..!!")
    yield put(updateAnnouncementSuccess(response.data))
  } catch (error) {
    yield put(updateAnnouncementFail(error))
    toastr.error(error.response.data.message)
  }
}

function* onDeleteAnnouncement({ payload: announcementId }) {
  try {
    yield put(updateAnnouncementLoadingState(true))
    const response = yield call(deleteAnnouncement, announcementId)
    toastr.success("Announcement deleted successfully..!!")
    yield put(updateAnnouncementLoadingState(false))
    yield put(deleteAnnouncementSuccess(announcementId))
  } catch (error) {
    toastr.error(error.response.data.message)
    yield put(updateAnnouncementLoadingState(false))
    yield put(deleteAnnouncementFail(error))
  }
}

function* onAddNewAnnouncement({ payload: { announcement } }) {
  try {
    yield put(updateAnnouncementLoadingState(true))
    const response = yield call(createAnnouncement, announcement)
    toastr.success("New Announcement Added Successfully..!!")
    yield put(addAnnouncementSuccess(response.data.newAnnouncement))
    yield put(updateAnnouncementLoadingState(false))
  } catch (error) {
    toastr.error(error.response.data.message)
    // yield put(updateAnnouncementLoadingState(false))
    // yield put(addAnnouncementFail(error))
  }
}

function* fetchAnnouncementsReport({ payload: { announcementId } }) {
  try {
    console.log(announcementId, "params")
    yield put(updateAnnouncementLoadingState(true))
    const response = yield call(getAnnouncementReports, announcementId)
    yield put(
      getAnnouncementsReportSuccess(response.data.newAnnouncementReport)
    )
    yield put(updateAnnouncementLoadingState(false))
  } catch (error) {
    yield put(updateAnnouncementLoadingState(false))
    // Dispatch an action to handle the failure
  }
}

function* announcementsSaga() {
  yield takeEvery(GET_ANNOUNCEMENTS, fetchAnnouncements)
  yield takeEvery(GET_ANNOUNCEMENT_DETAIL, fetchAnnouncementDetail)
  yield takeEvery(ADD_NEW_ANNOUNCEMENT, onAddNewAnnouncement)
  yield takeEvery(UPDATE_ANNOUNCEMENT, onUpdateAnnouncement)
  yield takeEvery(DELETE_ANNOUNCEMENT, onDeleteAnnouncement)
  yield takeEvery(GET_ANNOUNCEMENTS_REPORT, fetchAnnouncementsReport)
}

export default announcementsSaga
