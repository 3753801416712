import {
  DYNAMIC_API_FAIL,
  DYNAMIC_API_SUCCESS,
  GET_DYNAMIC_LIST,
  GET_DYNAMIC_PROFILE_TAB_DATA,
  GET_DYNAMIC_SIDEBAR,
  UPDATE_DYNAMIC_LOADING_STATE
} from "./actionTypes"

export const dynamicApiSuccess = (actionType, slug, data) => ({
  type: DYNAMIC_API_SUCCESS,
  payload: { actionType, slug, data },
})

export const dynamicApiFail = (actionType, error) => ({
  type: DYNAMIC_API_FAIL,
  payload: { actionType, error },
})

export const getDynamicSideBarData = () => ({
  type: GET_DYNAMIC_SIDEBAR,
})

export const getDynamicList = slug => ({
  type: GET_DYNAMIC_LIST,
  slug,
})

export const getDynamicProfileTabData = (slug, userId, queryParams) => ({
  type: GET_DYNAMIC_PROFILE_TAB_DATA,
  slug,
  userId,
  queryParams,
})

export const updateDynamicLoadingState = status => ({
  type: UPDATE_DYNAMIC_LOADING_STATE,
  payload: status,
})