import {
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_SUCCESS,
  RESET_SUBSCRIPTIONS
} from "./actionTypes"

const INIT_STATE = {
  subscriptions: [],
  subscriptionDetail: {},
  error: {},
  loading: false,
}

const subscriptions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
      }

    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_SUBSCRIPTIONS:
      return {
        subscriptions: [],
        subscriptionDetail: {},
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default subscriptions
