import { del, get, post, patch } from "../api_helper"
import { CHAPTER_ROUTES } from "../url_helper"

/** Chapters */
// add Chapter
export const addNewChapter = (courseId, chapter) =>
  post(`${CHAPTER_ROUTES}/${courseId}`, chapter, {
    headers: {
      "Content-Type":"application/json"
    },
  })

// update Chapter
export const updateChapter = (chapter, chapterId) =>
  patch(`${CHAPTER_ROUTES}/${chapterId}`, chapter, {
    headers: {
      "Content-Type":
        !chapter.document || typeof chapter?.document?.name == "string"
          ? "application/json"
          : "multipart/form-data",
    },
  })

// delete Chapter
export const deleteChapter = chapterId =>
  del(`${CHAPTER_ROUTES}/${chapterId}`, { headers: { chapterId } })

// get chapter
export const getChapters = () => get(`${CHAPTER_ROUTES}`)

// get chapter details
export const getChaptersDetails = id =>
  get(`${CHAPTER_ROUTES}/${id}`, { params: { id } })
