import {
  addNewAnnouncement as onAddNewAnnouncement,
  updateAnnouncement as onUpdateAnnouncement,
} from "../../store/announcement/actions"
import toastr from "toastr"

export const handleSubmit = (
  formikValues,
  formik,
  announcementDetail,
  isEdit,
  dispatch,
  navigate
) => {
  formik.validateForm().then(errors => {
    if (Object.keys(errors).length === 0) {
      const values = {
        ...announcementDetail,
        ...formikValues,
      }

      const startTime = values?.startTime
      let startDateAndTime = `${values?.startDate}T${startTime}:00`
      values.startDateAndTime = startDateAndTime
      if (isEdit) {
        dispatch(
          onUpdateAnnouncement(
            {
              ...values,
            },
            announcementDetail._id,
            navigate
          )
        )
      } else {
        dispatch(onAddNewAnnouncement({ ...values }, navigate))
      }
      navigate(-1)
    } else {
      const touchedFields = {}
      Object.keys(errors).forEach(field => {
        touchedFields[field] = true
      })
      formik.setTouched(touchedFields)
      console.log("Validation errors:", errors)
    }
  })
}
