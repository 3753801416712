import { del, get, post, patch } from "../api_helper"
import { REVIEW_ROUTES } from "../url_helper" // Assuming you have updated the URL helper as well

/** Reviews */

// Add Review
export const addNewReview = review =>
  post(REVIEW_ROUTES, review, {
    headers: {
      "Content-Type":
        typeof review?.image !== "string"
          ? "multipart/form-data"
          : "application/json",
    },
  })

// Update Review
export const updateReview = (review, reviewId) => {
  return patch(`${REVIEW_ROUTES}/${reviewId}`, review, {
    headers: {
      "Content-Type":
      review?.image && typeof review?.image !== "string"
          ? "multipart/form-data"
          : "application/json",
    },
  })
}

// Delete Review
export const deleteReview = reviewId =>
  del(`${REVIEW_ROUTES}/${reviewId}`, { headers: { reviewId } })

// Get reviews
export const getReviews = () => get(`${REVIEW_ROUTES}`)

// Get review details
export const getReviewsDetails = reviewId =>
  get(`${REVIEW_ROUTES}/${reviewId}`, { params: { reviewId } })

/** get subscription users by review id */
export const getSubscriptionUsersByReviewId = (reviewId, queryParams) => {
  if (queryParams) {
    return get(`${REVIEW_ROUTES}/${reviewId}/users${queryParams}`)
  } else {
    return get(`/reviews/${reviewId}/users`)
  }
}
