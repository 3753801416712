import PropTypes from "prop-types"
import React, { useState, useEffect, useContext } from "react"
import { AppContext } from "../../context/AppContext"
import { connect } from "react-redux"
import { Button, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"

import logoLightSvg from "../../assets/images/logo-light.svg"
import { withTranslation } from "react-i18next"
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
const Header = props => {
  const { loggedInUser } = useContext(AppContext)

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt={"qapreneur"} height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src={logoLightSvg}
                    alt={"qapreneur"}
                    height="17"
                    style={{ height: "42px !important" }}
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
              style={{color:"#243466"}}
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex align-items-center">
            {/* <LanguageDropdown/> */}
            <NotificationDropdown />
            <ProfileMenu user={loggedInUser} />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
