/* QUESTIONS */
export const GET_QUESTIONS = "GET_QUESTIONS"
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS"
export const GET_QUESTIONS_FAIL = "GET_QUESTIONS_FAIL"

/* QUESTIONS DETAIL*/
export const GET_QUESTION_DETAIL = "GET_QUESTION_DETAIL"
export const GET_QUESTION_DETAIL_SUCCESS = "GET_QUESTION_DETAIL_SUCCESS"
export const GET_QUESTION_DETAIL_FAIL = "GET_QUESTION_DETAIL_FAIL"

/**
 * add question
 */
export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION"
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS"
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL"

/**
 * Edit question
 */
export const UPDATE_QUESTION = "UPDATE_QUESTION"
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS"
export const UPDATE_QUESTION_FAIL = "UPDATE_QUESTION_FAIL"

/**
 * Delete question
 */
export const DELETE_QUESTION = "DELETE_QUESTION"
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS"
export const DELETE_QUESTION_FAIL = "DELETE_QUESTION_FAIL"

export const UPDATE_QUESTION_LOADING_STATE = "UPDATE_QUESTION_LOADING_STATE"
