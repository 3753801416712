import { del, get, post, patch } from "../api_helper"
import { TEST_ROUTES } from "../url_helper"

/** Tests */

// Add Test
export const addNewTest = test =>
  post(TEST_ROUTES, test, {
    headers: { "Content-Type": "multipart/form-data" },
  })

// Update Test
export const updateTest = (test, testId) => {
  return patch(`${TEST_ROUTES}/${testId}`, test, {
    headers: {
      "Content-Type":
        typeof test?.image?.name === "string"
          ? "multipart/form-data"
          : "application/json",
    },
  })
}

// Delete Test
export const deleteTest = testId =>
  del(`${TEST_ROUTES}/${testId}`, { headers: { testId } })

// Get test
export const getTests = queryParams => get(`${TEST_ROUTES}/${queryParams}`)

// Get test details
export const getTestsDetails = testId =>
  get(`${TEST_ROUTES}/${testId}`, { params: { testId } })

/** get subscription users by test id */
export const getSubscriptionUsersByTestId = (testId, queryParams) => {
  if (queryParams) {
    return get(`${TEST_ROUTES}/${testId}/users${queryParams}`)
  } else {
    return get(`/tests/${testId}/users`)
  }
}

// Get student test result
export const getStudentTestResultApi = queryParams => get(`${TEST_ROUTES}/question/answer/${queryParams}`)