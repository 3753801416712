import {
  GET_COMPANIES,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_DETAIL,
  ADD_NEW_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  GET_COMPANY_DETAIL_FAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  UPDATE_COMPANY_LOADING_STATE,
  RESET_COMPANY,
  GET_COMPANY_BILLING_INFO,
  GET_COMPANY_BILLING_INFO_FAIL,
  GET_COMPANY_BILLING_INFO_SUCCESS,
  ADD_COMPANY_BILLING_INFO,
  ADD_COMPANY_BILLING_INFO_FAIL,
  ADD_COMPANY_BILLING_INFO_SUCCESS,
  UPDATE_COMPANY_BILLING_INFO,
  UPDATE_COMPANY_BILLING_INFO_FAIL,
  UPDATE_COMPANY_BILLING_INFO_SUCCESS,
  GET_JOBS_POSTED_BY_COMPANY,
  GET_JOBS_POSTED_BY_COMPANY_FAIL,
  GET_JOBS_POSTED_BY_COMPANY_SUCCESS,
  GET_COMPANY_SUBSCRIPTION,
  GET_COMPANY_SUBSCRIPTION_SUCCESS,
  GET_COMPANY_SUBSCRIPTION_FAIL,
  GET_COMPANY_ALL_PLAN,
  GET_COMPANY_ALL_PLAN_SUCCESS,
  GET_COMPANY_ALL_PLAN_FAIL,
  GET_USERS_VIEW_HISTORY,
  GET_USERS_VIEW_HISTORY_SUCCESS,
  GET_USERS_VIEW_HISTORY_FAIL,
  GET_COMPANY_CREDIT_SUCCESS,
  GET_COMPANY_CREDIT_FAIL,
  GET_COMPANY_CREDIT
} from "./actionTypes"

export const getCompanies = params => ({
  type: GET_COMPANIES,
  params,
})

export const resetCompany = () => ({
  type: RESET_COMPANY,
})

export const getCompaniesSuccess = companies => ({
  type: GET_COMPANIES_SUCCESS,
  payload: companies,
})

export const addNewCompany = (company, history) => ({
  type: ADD_NEW_COMPANY,
  payload: { company, history },
})

export const addCompanySuccess = company => ({
  type: ADD_COMPANY_SUCCESS,
  payload: company,
})

export const addCompanyFail = error => ({
  type: ADD_COMPANY_FAIL,
  payload: error,
})

export const updateCompany = (slug, company, companyId, history) => ({
  type: UPDATE_COMPANY,
  payload: { slug, company, companyId, history },
})

export const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: company,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
})

export const deleteCompany = (companyId, params) => ({
  type: DELETE_COMPANY,
  payload: { companyId, params },
})

export const deleteCompanySuccess = company => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: company,
})

export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
})

export const getCompaniesFail = error => ({
  type: GET_COMPANIES_FAIL,
  payload: error,
})

export const getCompanyDetail = (companyId, slug) => ({
  type: GET_COMPANY_DETAIL,
  companyId,
  slug,
})

export const getCompanyDetailSuccess = data => ({
  type: GET_COMPANY_DETAIL_SUCCESS,
  payload: {
    companyDetail: data?.companyDetail,
    subscription: data?.subscription,
  },
})

export const getCompanyDetailFail = error => ({
  type: GET_COMPANY_DETAIL_FAIL,
  payload: error,
})

export const updateCompanyLoadingState = status => ({
  type: UPDATE_COMPANY_LOADING_STATE,
  payload: status,
})

export const getCompanyBillingInfo = companyId => ({
  type: GET_COMPANY_BILLING_INFO,
  companyId,
})

export const getCompanyBillingInfoSuccess = billingInfo => ({
  type: GET_COMPANY_BILLING_INFO_SUCCESS,
  payload: billingInfo,
})

export const getCompanyBillingInfoFail = error => ({
  type: GET_COMPANY_BILLING_INFO_FAIL,
  payload: error,
})

export const addCompanyBillingInfo = (companyId, billingInfo) => ({
  type: ADD_COMPANY_BILLING_INFO,
  companyId,
  billingInfo,
})

export const addCompanyBillingInfoSuccess = billingInfo => ({
  type: ADD_COMPANY_BILLING_INFO_SUCCESS,
  payload: billingInfo,
})

export const addCompanyBillingInfoFail = error => ({
  type: ADD_COMPANY_BILLING_INFO_FAIL,
  payload: error,
})

export const updateCompanyBillingInfo = (billingId, billingInfo) => ({
  type: UPDATE_COMPANY_BILLING_INFO,
  billingId,
  billingInfo,
})

export const updateCompanyBillingInfoSuccess = billingInfo => ({
  type: UPDATE_COMPANY_BILLING_INFO_SUCCESS,
  payload: billingInfo,
})

export const updateCompanyBillingInfoFail = error => ({
  type: UPDATE_COMPANY_BILLING_INFO_FAIL,
  payload: error,
})

export const getJobDetailsPosteByCopmany = companyId => ({
  type: GET_JOBS_POSTED_BY_COMPANY,
  companyId,
})

export const getJobDetailsPosteByCopmanySuccess = jobs => ({
  type: GET_JOBS_POSTED_BY_COMPANY_SUCCESS,
  payload: jobs,
})

export const getJobDetailsPosteByCopmanyFail = error => ({
  type: GET_JOBS_POSTED_BY_COMPANY_FAIL,
  payload: error,
})

export const getCompanySubscriptions = companyId => ({
  type: GET_COMPANY_SUBSCRIPTION,
  companyId,
})

export const getCompanySubscriptionsSuccess = Subscriptions => ({
  type: GET_COMPANY_SUBSCRIPTION_SUCCESS,
  payload: Subscriptions,
})

export const getCompanySubscriptionsFail = error => ({
  type: GET_COMPANY_SUBSCRIPTION_FAIL,
  payload: error,
})

export const getCompanyAllplan = params => ({
  type: GET_COMPANY_ALL_PLAN,
  params,
})

export const getCompanyAllplanSuccess = plans => ({
  type: GET_COMPANY_ALL_PLAN_SUCCESS,
  payload: plans,
})

export const getCompanyAllplanFail = error => ({
  type: GET_COMPANY_ALL_PLAN_FAIL,
  payload: error,
})

export const getUsersCompanyViewHistory = candidateId => ({
  type: GET_USERS_VIEW_HISTORY,
  candidateId,
})

export const getUsersCompanyViewHistorySuccess = userViewHistory => ({
  type: GET_USERS_VIEW_HISTORY_SUCCESS,
  payload: userViewHistory,
})

export const getUsersCompanyViewHistoryFail = error => ({
  type: GET_USERS_VIEW_HISTORY_FAIL,
  payload: error,
})


export const getCompanyCredit = companyId => ({
  type: GET_COMPANY_CREDIT,
  companyId,
})


export const getCompanyCreditSuccess = credit => ({
  type: GET_COMPANY_CREDIT_SUCCESS,
  payload: credit,
})

export const getCompanyCreditFail = error => ({
  type: GET_COMPANY_CREDIT_FAIL,
  payload: error,
})