import React, { useEffect, useState, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"
import {
  getPackages as onGetPackages,
  deletePackage as onDeletePackage,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import AppTabs from "../../components/Common/Tabs"
import { tabIds } from "./constants"
import { getPackageColumn } from "./Utills"
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import { AppContext } from "context/AppContext"

const AddOnPackageList = () => {
  document.title = "package List | QAPRENEUR"
  const { authRole } = useContext(AppContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const [PackageList, setPackageList] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)
  const [activeTab, setActivetab] = useState(params.profileTab)
  const [deleteModal, setDeleteModal] = useState(false)
  const [packageDetail, setPackageDetail] = useState(null)

  const { packages, loading } = useSelector(state => ({
    packages: state.packages?.packages,
    loading: state?.packages?.loading,
  }))

  useEffect(() => {
    if (authRole === "admin") {
      const queryParams = {
        page: pageNo,
        limit: rowPerPage,
      }
      if (search && search.length > 0) {
        queryParams.search = search
      }
      if (activeTab && activeTab.length > 0) {
        queryParams.role = activeTab
      }
      dispatch(onGetPackages(queryParams))
    }
  }, [dispatch, activeTab])

  useEffect(() => {
    if (!isEmpty(packages)) {
      setPackageList(
        packages
          .map(item => ({
            ...item,
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setPackageList([])
    }
  }, [packages])

  const handleActions = (pln, type) => {
    if (type === "EDIT") {
      navigate(`/edit-package/${pln._id}/${activeTab}`)
    } else if (type === "DELETE") {
      setPackageDetail(pln)
      setDeleteModal(true)
    }
  }

  const handleModalAction = () => {
    if (packageDetail && packageDetail._id) {
      dispatch(onDeletePackage(packageDetail._id))
    }
    setDeleteModal(false)
  }

  const handleTabClick = tabId => {
    setActivetab(tabId)
  }

  const tabsArray = tabIds.map((tab, key) => {
    const packageColumns = getPackageColumn(tab.id)
    return {
      id: tab.id,
      label: tab.label,
      content: (
        <Col sm="12">
          <div className="pt-3 px-2">
            <TableContainer
              loading={loading}
              data={PackageList}
              isGlobalFilter={false}
              isAddOptions={false}
              isJobListGlobalFilter={false}
              addButton={
                authRole == "admin"
                  ? { link: `/add-package/${tab.id}`, title: "Add Package" }
                  : null
              }
              handleActions={handleActions}
              showPageDropdown={false}
              customPageSize={10}
              className="project-list-table table-nowrap align-middle table-borderless"
              columns={packageColumns}
            />
          </div>
        </Col>
      ),
    }
  })
  return (
    <React.Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalBackdrop={deleteModal}
          setmodalBackdrop={setDeleteModal}
          modalTitle={"Are you sure want to permanently delete the package."}
          modalAction={handleModalAction}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItem="Packages"
            navigate="/dashboard"
          />
          <Row>
            <Col lg="12">
              <AppTabs
                tabsArray={tabsArray}
                contentBgColor=""
                handleTabClick={handleTabClick}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddOnPackageList
