import React, { useState, useEffect } from "react"
import {
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "reactstrap"

const InfoModal = ({
  modalBackdrop,
  setmodalBackdrop,
  children,
  title,
  size = "sm",
  height = 250,
  isFooter = false,
  handleAction,
  handleModalCloseAction,
  fullscreen = false,
  buttonTitle = "",
  showHeader = true,
}) => {
  const [loading, setLoading] = useState(false)
  const toggle = () => setmodalBackdrop(false)

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [loading])

  return (
    <>
      <Modal
        isOpen={modalBackdrop}
        toggle={() => {
          toggle()
          handleModalCloseAction && handleModalCloseAction()
        }}
        fullscreen={fullscreen}
        size={size}
        centered={true}
        id="staticBackdrop"
      >
        {showHeader && (
          <ModalHeader
            toggle={() => {
              toggle()
              handleModalCloseAction && handleModalCloseAction()
            }}
            tag="h4"
          >
            {title}
          </ModalHeader>
        )}
        <ModalBody className="overflow-y-scroll" style={{ height: height }}>
          {children}
        </ModalBody>
        {isFooter && (
          <ModalFooter>
            <Row>
              <Col>
                <div className="text-end">
                  {handleAction && (
                    <Button
                      type="submit"
                      className="btn btn-success save-user"
                      onClick={handleAction}
                    >
                      {buttonTitle ? buttonTitle : "Save"}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </ModalFooter>
        )}
      </Modal>
    </>
  )
}

export default InfoModal
