import React, { useState, useEffect ,useContext} from "react"
import { useSelector, useDispatch } from "react-redux"
import { Col, Card, CardBody, Container, Row } from "reactstrap"
import { getCompanyPlanDetail as onGetCompanyPlansDetail } from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import AppLoader from "../../components/Common/Loader"
import Plan from "../../components/Common/Plan"
import ProgressCard from "../../components/Common/Card/ProgressCard"
import toastr from "toastr"
import { createTransactions } from "../../helpers/api/transactionsApi"
import TableContainer from "../../components/Common/Table/index"
import { isEmpty } from "lodash"
import { getPackages as onGetPackages } from "../../store/package/actions"
import { packageColumn } from "./constants"
import { convertToTitleCase } from "./Utills"
import {
  generateKey as generatePaymentKey,
  generateRandomId,
} from "../../helpers/api/transactionsApi"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../../context/AppContext"

function CompanyPlan() {
  document.title = "Company Plan List | QAPRENEUR"
  const dispatch = useDispatch()
  const [isloading, setIsloading] = useState(true)
  const [PackageList, setPackageList] = useState([])
  const navigate = useNavigate()
  const authRole = localStorage.getItem("authRole")
  const { loggedInUser } = useContext(AppContext)

  const {
    // companyDetail,
    companyPlanDetail,
    packages,
    loadingPackage,
    loading,
  } = useSelector(state => ({
    companyPlanDetail: state.plans?.companyPlanDetail,
    // companyDetail: state.companies?.companyDetail,
    loading: state?.plans?.loading,
    packages: state.packages?.packages,
    loadingPackage: state?.packages?.loading,
  }))

  useEffect(() => {
    dispatch(onGetCompanyPlansDetail())
    // dispatch(onGetCompanyDetail("", "users/me"))
    dispatch(
      onGetPackages({
        page: 1,
        limit: 20,
        role: "company",
      })
    )
  }, [])

  useEffect(() => {
    if (!isEmpty(packages)) {
      setPackageList(
        packages
          .map(item => ({
            ...item,
            leadLimit: item[item.leads],
            leadsName: convertToTitleCase(item?.leads),
          }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setPackageList([])
    }
    if (loggedInUser && Object.keys(loggedInUser).length) {
      setIsloading(false)
    }
  }, [packages, loggedInUser])

  const handlePayment = async (item, purpose) => {
    const splitUsername =
      loggedInUser && loggedInUser?.name && loggedInUser?.name.split(" ")
    const firstName =
      splitUsername && splitUsername.length > 0 && splitUsername[0]
    const amount = `${parseFloat(item?.price).toFixed(2)}`
    const txnId =
      Date.now() + Math.floor(Math.random() * 100000) + generateRandomId(10)
    const itemId = item._id
    const response = await generatePaymentKey({
      firstname: firstName,
      email: loggedInUser?.email,
      phone: loggedInUser?.phone,
      productinfo: item?.name,
      amount: amount,
      surl: `https://qapreneur.com/transaction/success/${item._id}/${txnId}`,
      furl: `https://qapreneur.com/transaction/fail/${item._id}/${txnId}`,
      txnId: txnId,
    })
    if (response?.data && response?.status === 1) {
      const url_link = `https://pay.easebuzz.in/pay/${response.data}`
      const transactionRes = await createTransactions({
        amount: amount,
        purpose: purpose,
        transactionId: txnId,
        transactionType: "Debit",
        txn_info: JSON.stringify({ productinfo: item?.name }),
        success: false,
      })
      // setHash(response.data)
      // setTxnInfo({
      //   txnId: txnId,
      //   itemId: itemId,
      //   hash: response.data,
      //   ...transactionRes?.data?.transaction,
      // })
      if (transactionRes && transactionRes?.data?.transaction) {
        localStorage.setItem(
          "txn_info",
          JSON.stringify({
            transaction: transactionRes?.data?.transaction,
            hash: response.data,
          })
        )
        const anchor = document.createElement("a")
        anchor.href = url_link
        anchor.target = "_blank"
        anchor.click()
      } else {
        // setHash("")
        toastr.error("Transaction creation failed")
      }
    } else {
      // setHash("")
      toastr.error(response?.data + " " + response?.error_desc)
    }
  }

  const handlePlan = item => {
    // Object.keys(companyDetail).length > 0 && handlePayment(item)
    navigate("/upgrade-plan")
  }

  const handleBuyPackage = item => {
    handlePayment(item, "Package Purchase")
  }

  const columns = packageColumn(authRole)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Plan" />
          {loading && <AppLoader />}
          {!loading && (
            <>
              <Row>
                <Col lg="12">
                  <Card className="mini-stats-wid">
                    <CardBody style={{ padding: 10 }}>
                      <Plan
                        SubscriptionPlan={companyPlanDetail?.subscription}
                        isActive={true}
                        showDesc={true}
                        showUpgradeButton={
                          authRole === "company" ? true : false
                        }
                        handlePlan={handlePlan}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <ProgressCard
                  title="Job Post Limit"
                  remaining={companyPlanDetail?.companyCredit?.jobCredit}
                  limit={companyPlanDetail?.companyCredit?.jobCreditMax}
                />
                <ProgressCard
                  title="Unlocked Candidate Limit"
                  remaining={companyPlanDetail?.companyCredit?.profileCredit}
                  limit={companyPlanDetail?.companyCredit?.profileCreditMax}
                />
                <ProgressCard
                  title="HR User Limit"
                  remaining={companyPlanDetail?.companyCredit?.addUserCredit}
                  limit={companyPlanDetail?.companyCredit?.addUserCreditMax}
                />
              </Row>
              <Row>
                <TableContainer
                  loading={isloading}
                  data={PackageList}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  handleBuy={handleBuyPackage}
                  showPageDropdown={false}
                  customPageSize={20}
                  className="project-list-table table-nowrap  align-middle table-borderless"
                  columns={columns}
                  // tableTitle={tab.label}
                />
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompanyPlan
