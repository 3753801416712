
export const courseColumns = (role = "") => {
  const cols = [
    {
      header: "#",
      key: "",
    },
    {
      header: "Image",
      name: "image",
      key: "IMAGE",
    },
    {
      header: "Name",
      name: "courseName",
      key: "NAME_DESCRIPTION",
    },
    {
      header: "Type",
      name: "type",
      key: "DEFAULT",
    },
    {
      header: "Price",
      name: "price",
      key: "DEFAULT",
    },
    {
      header: "Total Chapter",
      name: "chapterCount",
      key: "DEFAULT",
    },
    {
      header: "Attempt Chapter",
      name: "completedChapterCount",
      key: "DEFAULT",
    },
    {
      header: "Certificate Completed",
      name: "isCertificateTestCompleted",
      key: "DEFAULT",
    },
    {
      header: "Completed %",
      name: "completedChapterPer",
      key: "DEFAULT",
    },
    {
      header: "status",
      name: "status",
      key: "STATUS",
    },
    {
      header: "View Certificate",
      name: "View",
      key: "ACTIONS",
    },
  ]
  if (role == "admin") {
    cols.push({
      header: "View Report",
      key: "MORE_ACRIONS",
      dropDownOptions: [{ label: "View Report", type: "COURSE_REPORT" }],
    })
  }
  return cols
}

export const testColumns = (role = "") => {
  const cols = [
    {
      header: "#",
      key: "",
    },
    {
      header: "Image",
      name: "image",
      key: "IMAGE",
      link: "/test-details",
      defaultId: "testId",
    },
    {
      header: "Test Name",
      name: "testName",
      key: "DEFAULT",
      link: "/test-details",
      defaultId: "testId",
    },
    {
      header: "Test Attempts",
      name: "userTestAttempt",
      key: "DEFAULT",
      link: "/test-details",
      defaultId: "testId",
    },
    {
      header: "Total Question",
      name: "totalQuestion",
      key: "DEFAULT",
      link: "/test-details",
      defaultId: "testId",
    },
    {
      header: "Attempt Question Count",
      name: "questionAttemptedCount",
      key: "DEFAULT",
      link: "/test-details",
      defaultId: "testId",
    },
    {
      header: "Correct Answer",
      name: "correctAnswer",
      key: "DEFAULT",
      link: "/test-details",
      defaultId: "testId",
    },
    {
      header: "Wrong Answer",
      name: "wrongAnswer",
      key: "DEFAULT",
      link: "/test-details",
      defaultId: "testId",
    },
    {
      header: "Score %",
      name: "score",
      key: "DEFAULT",
      link: "/test-details",
      defaultId: "testId",
    },
    {
      header: "Result",
      name: "result",
      key: "STATUS",
    },
  ]
  if (role == "admin") {
    cols.push({
      header: "View Report",
      name: "View",
      key: "ACTIONS",
    })
  }
  return cols
}

export const renderToFormFields = (coachingOptions, collegeOptions, isEdit) => {
  return [
    {
      name: "name",
      label: "Full Name",
      type: "text",
      placeholder: "Enter Student Name",
      required: true,
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "Enter Student Email",
      required: true,
      disabled: isEdit,
    },
    {
      name: "collegeId",
      label: "Select College",
      type: "select",
      options: Array.isArray(collegeOptions)
        ? [{ value: "", label: "Select College" }, ...collegeOptions]
        : [],
      required: true,
    },
    {
      name: "coachingId",
      label: "Select Coaching",
      type: "select",
      options: Array.isArray(coachingOptions)
        ? [{ value: "", label: "Select Coaching" }, ...coachingOptions]
        : [],
      required: true,
    },
  ]
}

export const userProfileFrame = user => {
  if (!user?.lookingForQa && !user?.lookingForJob) {
    return ""
  } else if (user?.lookingForQa && !user?.lookingForJob) {
    return "QA"
  } else if (user?.lookingForQa && user?.lookingForJob) {
    return "JOB"
  } else if (!user?.lookingForQa && user?.lookingForJob) {
    return "JOB"
  }
}

export const calculateCompletionPercentage = userCourses => {
  return (
    Array.isArray(userCourses) &&
    userCourses.map(item => {
      let chapterCount = item.chapterCount
      let completedChapterCount =
        chapterCount < item.completedChapterCount
          ? chapterCount
          : item.completedChapterCount
      let isCertificateTestCompleted = item.isCertificateTestCompleted

      let baseCompletionPercentage = (completedChapterCount / chapterCount) * 90
      let finalCompletionPercentage = Math.min(baseCompletionPercentage, 90)

      if (isCertificateTestCompleted) {
        finalCompletionPercentage += 10
      }

      finalCompletionPercentage = Math.max(finalCompletionPercentage, 0)

      return {
        ...item,
        ...item.extra,
        completedChapterPer: `${
          finalCompletionPercentage ? Math.round(finalCompletionPercentage) : 0
        }%`,
        isCertificateTestCompleted: isCertificateTestCompleted ? "Yes" : "No",
      }
    })
  )
}

export const getUserFullName = user => {
  const fullname =
    user && user?.firstName && user?.lastName
      ? user?.firstName + " " + user?.lastName
      : ""
  return fullname
}
