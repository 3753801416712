import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { reportColumns } from "./constants"
import {
  getAnnouncementsReport as onGetAnnouncementsReport,
} from "../../store/announcement/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"

const AnnouncementReport = () => {
  document.title = "Announcement Report | QAPRENEUR"
  const dispatch = useDispatch()
  const params = useParams()
  const [announcementsData, setAnnouncementsData] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { announcementReports, loading } = useSelector(state => ({
    announcementReports: state?.announcement?.announcementReports,
    loading: state?.announcement?.loading,
  }))

  const getData = () => {
    const queryParams = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      queryParams.search = search
    }

    if (params?.announcementId) {
      queryParams.announcementId = params.announcementId
    }

    dispatch(onGetAnnouncementsReport(queryParams))
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!isEmpty(announcementReports)) {
      setAnnouncementsData(
        announcementReports
          .map(item => ({ ...item.user, _id: item.userId }))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setAnnouncementsData([])
    }
  }, [announcementReports, pageNo])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Announcement"
            navigate={"/announcement"}
            breadcrumbItem="Announcement Report"
          />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={announcementsData}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={reportColumns}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AnnouncementReport
