export const dashboardTiles = dashboardData => {
  return [
    {
      title: "Total User",
      iconClass: "fas fa-user-friends",
      description: `${dashboardData?.dashboard?.users?.noOfTotalUsers}`,
      md: 4,
      link: "/students-list",
    },
    {
      title: "Total Students",
      iconClass: "fas fa-user-graduate",
      description: `${dashboardData?.dashboard?.users?.noOfStudent}`,
      md: 4,
      link: "/students-list",
    },
    {
      title: "Total Coaching",
      iconClass: "bx bx-building-house",
      description: `${dashboardData?.dashboard?.users?.noOfCoaching}`,
      md: 4,
      link: "/coachings-list",
    },
    {
      title: "Total Colleges",
      iconClass: "bx bxs-graduation",
      description: `${dashboardData?.dashboard?.users?.noOfCollege}`,
      md: 4,
      link: "/colleges-list",
    },
    {
      title: "Total Companies",
      iconClass: "mdi mdi-office-building-outline",
      description: `${dashboardData?.dashboard?.users?.noOfCompany}`,
      md: 4,
      link: "/companies-list",
    },
    {
      title: "Total Hr",
      iconClass: "mdi mdi-office-building-outline",
      description: `${dashboardData?.dashboard?.users?.noOfHr}`,
      md: 4,
      link: "/hr-list",
    },
    {
      title: "Total Earning  ",
      iconClass: "mdi mdi-currency-inr",
      description: `${dashboardData?.dashboard?.transactions.totalEarning}`,
      md: 4,
      link: "/transaction",
    },
    {
      title: "Subscribers",
      iconClass: "mdi mdi-bell-ring",
      description: `${
        dashboardData ? dashboardData?.dashboard?.totalSubscribersCount : 0
      }`,
      md: 4,
      link: "/transaction",
    },
    {
      title: "Total Jobs",
      iconClass: "mdi mdi-view-list-outline",
      description: `${
        dashboardData ? dashboardData?.dashboard?.jobs?.totalJobsCount : 0
      }`,
      md: 4,
      link: "/jobs-list",
    },
    {
      title: "Total Open Jobs",
      iconClass: "mdi mdi-view-list-outline",
      description: `${
        dashboardData ? dashboardData?.dashboard?.jobs?.noOfOpenJobs : 0
      }`,
      md: 4,
      link: "/jobs-list",
    },
    {
      title: "Total  Closed Jobs",
      iconClass: "mdi mdi-view-list-outline",
      description: `${
        dashboardData ? dashboardData?.dashboard?.jobs?.noOfClosedJobs : 0
      }`,
      md: 4,
      link: "/jobs-list",
    },
    {
      title: "Total Courses",
      iconClass: "mdi mdi-view-list-outline",
      description: `${
        dashboardData ? dashboardData?.dashboard?.courses?.noOfCourseCount : 0
      }`,
      md: 4,
      link: "/courses-list",
    },
    {
      title: "Total Tests",
      iconClass: "mdi mdi-view-list-outline",
      description: `${
        dashboardData ? dashboardData?.dashboard?.tests?.noOfTestCount : 0
      }`,
      md: 4,
      link: "/tests-list",
    },
  ]
}

export const transactionBarChartData = response => {
  const result = {}
  // Iterate over the amount object
  for (const year in response?.amount) {
    const amountData = response?.amount[year]
    const userCount = response?.users[year] ? response?.users[year].length : 0
    const totalAmount = amountData.reduce(
      (total, item) => total + item.amount,
      0
    )
    result[year] = { userCount, amount: totalAmount }
  }

  // Iterate over the users object to include years not present in the amount object
  for (const year in response?.users) {
    if (!result[year]) {
      const userCount = response?.users[year].length
      const totalAmount = response?.amount[year]
        ? response?.amount[year].reduce((total, item) => total + item.amount, 0)
        : 0
      result[year] = { userCount, amount: totalAmount }
    }
  }

  const labels = Object.keys(result)
  const userData2 = []
  const amountData2 = []
  for (const year in result) {
    userData2.push(result[year].userCount)
    amountData2.push(result[year].amount)
  }
  const transactionDatasets = [
    {
      label: "User Count",
      data: userData2,
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Amount",
      data: amountData2,
      backgroundColor: "rgba(54, 162, 235, 0.5)",
    },
  ]
  const data = barChartData(labels, transactionDatasets)

  return data
}

export const jobBarChartData = response => {
  const result = {}
  for (const month in response.active) {
    const activeJobsData = response?.active[month].length
    const closedJobsData = response?.deActive[month]
      ? response?.deActive[month].length
      : 0
    result[month] = { activeJobsData, closedJobsData }
  }

  const labels = Object.keys(result)
  const activeJobsData = labels.map(month => result[month].activeJobsData)
  const closedJobsData = labels.map(month => result[month].closedJobsData)

  const jobDatasets = [
    {
      label: "Active Jobs",
      data: activeJobsData,
      backgroundColor: "rgba(255, 99, 132, 0.5)", // Customize as needed
    },
    {
      label: "Closed Jobs",
      data: closedJobsData,
      backgroundColor: "rgba(54, 162, 235, 0.5)", // Customize as needed
    },
  ]
  const data = barChartData(labels, jobDatasets)

  return data
}

export const profileStatusBarChartData = result => {
  const newObj = {
    Jan: [],
    Feb: [],
    Mar: [],
    Apr: [],
    May: [],
    Jun: [],
    Jul: [],
    Aug: [],
    Sep: [],
    Oct: [],
    Nov: [],
    Dec: [],
  }

  Object.keys(result).forEach(res => {
    newObj[res] = result[res]
  })

  const monthWiseData = {}
  // Group data by month
  Object.keys(newObj).forEach(month => {
    newObj[month].forEach(data => {
      const { updatedAt, profileCompletion } = data
      const monthName = new Date(updatedAt).toLocaleString("default", {
        month: "short",
      })

      // If the month key doesn't exist in monthWiseData, initialize it as an empty array
      if (!monthWiseData.hasOwnProperty(monthName)) {
        monthWiseData[monthName] = [0, 0, 0, 0, 0] // Initialize counts for 0%, 25%, 50%, 75%, 100%
      }

      // Increment the count for the corresponding completion percentage
      monthWiseData[monthName][profileCompletion / 25]++
    })
  })

  const profileStatusLabels = Object.keys(monthWiseData)
  const profileStatusDatasets = [
    {
      label: "0%",
      value: 0,
      data: Object.values(monthWiseData).map(data => data[0]),
      backgroundColor: "rgba(54, 162, 235, 0.5)",
    },
    {
      label: "25%",
      value: 25,
      data: Object.values(monthWiseData).map(data => data[1]),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "50%",
      value: 50,
      data: Object.values(monthWiseData).map(data => data[2]),
      backgroundColor: "rgba(255, 206, 86, 0.5)",
    },
    {
      label: "75%",
      value: 75,
      data: Object.values(monthWiseData).map(data => data[3]),
      backgroundColor: "rgba(75, 192, 192, 0.5)",
    },
    {
      label: "100%",
      value: 100,
      data: Object.values(monthWiseData).map(data => data[4]),
      backgroundColor: "rgba(153, 102, 255, 0.5)",
    },
  ]
  const data = barChartData(profileStatusLabels, profileStatusDatasets)
  return data
}

export const barChartData = (labels, datasets) => {
  return {
    labels: labels,
    datasets: datasets,
  }
}

export const barChartOptions = () => {
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 10, // Scale step size
          max: 100, // Maximum scale size
        },
      },
    },
  }

  return options
}

export const getPieChartData = pieChartData => {
  const data = {
    labels: ["HR USERS", "STUDENT", "COMPANY", "COLLEGE", "COACHING"],
    datasets: [
      {
        data: [
          pieChartData.noOfHr,
          pieChartData.noOfStudent,
          pieChartData.noOfCompany,
          pieChartData.noOfCollege,
          pieChartData.noOfCoaching,
        ],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(255, 99, 132, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(54, 162, 235, 0.6)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }
  return data
}
