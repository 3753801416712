import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import {
  getStudentTestResults as onGetStudentTestResults,
  getTestDetail as onGetTestDetail,
  resetTests as onResetTests,
} from "../../store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"
import { testResultColumns } from "./constants"

const StudentTestReport = () => {
  document.title = "Test Result List | QAPRENEUR"
  const dispatch = useDispatch()
  const params = useParams()

  const { testResults,testDetail, loading } = useSelector(state => ({
    testResults: state?.tests?.testResults,
    loading: state?.tests?.loading,
    testDetail: state.tests?.testDetail,
  }))

  useEffect(() => {
    const queryparams = {
      studentId: params?.studentId,
      testId: params?.testId,
      resultId: params?.resultId,
    }
    dispatch(onGetStudentTestResults(queryparams))
    if (params?.testId) {
      dispatch(onGetTestDetail(params?.testId))
    }
    return () => {
      dispatch(onResetTests())
    }
  }, [dispatch])

  const userOptions = testResults
    ? testResults?.map(item => {
        const question = JSON.parse(item?.question?.question)
        const userOption = item?.userOption
        return {
          question: question?.question,
          status: userOption?.answer
            ? item?.question?.isAnswer === userOption?.answer
              ? "right"
              : "wrong"
            : "notAttempted",
          correctAnswer: `${item?.question?.isAnswer} : ${
            question[item?.question?.isAnswer]
          }`,
          userAnswer: userOption?.answer
            ? `${userOption?.answer} : ${question[userOption?.answer]}`
            : "",
        }
      })
    : []

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Student Profile"
            breadcrumbItem={testDetail?.name}
            navigate={-1}
          />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={userOptions}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  showPageDropdown={false}
                  isGlobalFilter={false}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={testResultColumns}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StudentTestReport
