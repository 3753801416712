import {
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_FAIL,
  GET_ANNOUNCEMENTS_SUCCESS,
  GET_ANNOUNCEMENT_DETAIL,
  ADD_NEW_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_SUCCESS,
  ADD_ANNOUNCEMENT_FAIL,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_SUCCESS,
  UPDATE_ANNOUNCEMENT_FAIL,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAIL,
  GET_ANNOUNCEMENT_DETAIL_FAIL,
  GET_ANNOUNCEMENT_DETAIL_SUCCESS,
  UPDATE_ANNOUNCEMENT_LOADING_STATE,
  GET_ANNOUNCEMENTS_REPORT,
  GET_ANNOUNCEMENTS_REPORT_FAIL,
  GET_ANNOUNCEMENTS_REPORT_SUCCESS,
  RESET_ANNOUNCEMENTS
} from "./actionTypes"

export const getAnnouncements = (params) => ({
  type: GET_ANNOUNCEMENTS,
  payload:params
})

export const getAnnouncementsSuccess = announcements => ({
  type: GET_ANNOUNCEMENTS_SUCCESS,
  payload: announcements,
})

export const getAnnouncementsFail = error => ({
  type: GET_ANNOUNCEMENTS_FAIL,
  payload: error,
})

export const addNewAnnouncement = announcement => ({
  type: ADD_NEW_ANNOUNCEMENT,
  payload: { announcement },
})

export const addAnnouncementSuccess = announcement => ({
  type: ADD_ANNOUNCEMENT_SUCCESS,
  payload: announcement,
})

export const addAnnouncementFail = error => ({
  type: ADD_ANNOUNCEMENT_FAIL,
  payload: error,
})

export const updateAnnouncement = (announcement, announcementId) => ({
  type: UPDATE_ANNOUNCEMENT,
  payload: { announcement, announcementId },
})

export const updateAnnouncementSuccess = announcement => ({
  type: UPDATE_ANNOUNCEMENT_SUCCESS,
  payload: announcement,
})

export const updateAnnouncementFail = error => ({
  type: UPDATE_ANNOUNCEMENT_FAIL,
  payload: error,
})

export const deleteAnnouncement = announcementId => ({
  type: DELETE_ANNOUNCEMENT,
  payload: announcementId,
})

export const deleteAnnouncementSuccess = announcementId => ({
  type: DELETE_ANNOUNCEMENT_SUCCESS,
  payload: announcementId,
})

export const deleteAnnouncementFail = error => ({
  type: DELETE_ANNOUNCEMENT_FAIL,
  payload: error,
})

export const getAnnouncementDetail = announcementId => ({
  type: GET_ANNOUNCEMENT_DETAIL,
  announcementId,
})

export const getAnnouncementDetailSuccess = announcementDetails => ({
  type: GET_ANNOUNCEMENT_DETAIL_SUCCESS,
  payload: announcementDetails,
})

export const getAnnouncementDetailFail = error => ({
  type: GET_ANNOUNCEMENT_DETAIL_FAIL,
  payload: error,
})

export const updateAnnouncementLoadingState = status => ({
  type: UPDATE_ANNOUNCEMENT_LOADING_STATE,
  payload: status,
})

export const getAnnouncementsReport = (params) => ({
  type: GET_ANNOUNCEMENTS_REPORT,
  payload:params
})

export const getAnnouncementsReportSuccess = announcements => ({
  type: GET_ANNOUNCEMENTS_REPORT_SUCCESS,
  payload: announcements,
})

export const getAnnouncementsReportFail = error => ({
  type: GET_ANNOUNCEMENTS_REPORT_FAIL,
  payload: error,
})

export const resetAnnouncements = () => ({
  type: RESET_ANNOUNCEMENTS,
})
