import React from "react"

function DateRange({ dateValue, onChangeDateRange }) {
  return (
    <select
      className="form-select me-3"
      value={dateValue}
      onChange={onChangeDateRange}
    >
      <option value="">Date Posted</option>
      <optgroup label="Date">
        <option value="today">Today</option>
        <option value="yesterday">Yesterday</option>
        <option value="thisWeek">This Week</option>
        <option value="thisMonth">This Month</option>
        <option value="thisYear">This Year</option>
      </optgroup>
      <optgroup label="Deleted Job">
        <option value="deleted">Deleted</option>
      </optgroup>
    </select>
  )
}

export default DateRange
