import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ADMIN_DASHBOARD_DATA,
  GET_COMPANY_DASHBOARD_DATA,
  GET_CHARTS_DATA,
  GET_JOB_CHART_DATA,
  GET_JOB_CHART_DATA_SUCCESS,
  GET_TRANSACTION_CHART_DATA,
  GET_TRANSACTION_CHART_DATA_SUCCESS,
  GET_PROFILE_STATUS_DATA_SUCCESS,
  GET_PROFILE_STATUS_DATA,
  GET_SIDEBAR,
} from "./actionTypes"
import { apiSuccess, apiFail, updateDashboardLoadingState } from "./actions"
import {
  getAdminDashboard,
  getCompanyDashboard,
  getAdminDashboardJobBarChartData,
  getAdminDashboardTransactionBarChartData,
  getProfileStatusChartdtDataApi,
  getSideBarLayout,
} from "../../helpers/api/dashboardApi"

function* getAdminDashboardData() {
  try {
    var response
    response = yield call(getAdminDashboard)
    yield put(apiSuccess(GET_ADMIN_DASHBOARD_DATA, response))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* getJobChartdDataByDateRange({ daterange }) {
  try {
    const queryParams = `?dateRange=${daterange}`
    const jobBarChartData = yield call(
      getAdminDashboardJobBarChartData,
      queryParams
    )
    yield put(apiSuccess(GET_JOB_CHART_DATA_SUCCESS, jobBarChartData?.barChart))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* getTransactionChartdDataByDateRange({ daterange }) {
  try {
    const queryParams = `?dateRange=${daterange}`
    const transactionBarChartData = yield call(
      getAdminDashboardTransactionBarChartData,
      queryParams
    )
    yield put(
      apiSuccess(
        GET_TRANSACTION_CHART_DATA_SUCCESS,
        transactionBarChartData?.barChart
      )
    )
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* getProfileStatusChartdData() {
  try {
    const profileStatusChartdData = yield call(getProfileStatusChartdtDataApi)
    yield put(
      apiSuccess(
        GET_PROFILE_STATUS_DATA_SUCCESS,
        profileStatusChartdData?.barChart
      )
    )
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* getCompanyDashboardData() {
  try {
    var response
    response = yield call(getCompanyDashboard)
    yield put(apiSuccess(GET_COMPANY_DASHBOARD_DATA, response.data))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* getSideBardLayout() {
  try {
    yield put(updateDashboardLoadingState(true))
    const response = yield call(getSideBarLayout)
    yield put(apiSuccess(GET_SIDEBAR, response.data))
    yield put(updateDashboardLoadingState(false))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
    yield put(updateDashboardLoadingState(false))
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_ADMIN_DASHBOARD_DATA, getAdminDashboardData)
  yield takeEvery(GET_COMPANY_DASHBOARD_DATA, getCompanyDashboardData)
  yield takeEvery(GET_JOB_CHART_DATA, getJobChartdDataByDateRange)
  yield takeEvery(
    GET_TRANSACTION_CHART_DATA,
    getTransactionChartdDataByDateRange
  )
  yield takeEvery(GET_SIDEBAR, getSideBardLayout)
  yield takeEvery(GET_PROFILE_STATUS_DATA, getProfileStatusChartdData)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])
}

export default dashboardSaga
