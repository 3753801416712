import {
  commonFormFields,
  studentSettings,
  companySettings,
  commonFields,
  studentFields,
  companyFields,
  actions,
} from "./constants"

import {
  addNewPlan as onAddNewPlan,
  updatePlan as onUpdatePlan,
} from "../../store/actions"

export const getPlanForm = role => {
  switch (role) {
    case "student":
      return [...commonFormFields, ...studentSettings]

    case "company":
      return [...commonFormFields, ...companySettings]

    default:
      return "Invalid Role"
  }
}

export const getPlanColumn = role => {
  switch (role) {
    case "student":
      return [...commonFields, ...studentFields, ...actions]

    case "company":
      return [...commonFields, ...companyFields, ...actions]

    default:
      return "Invalid Role"
  }
}

export const handleSubmit = (
  formikValues,
  formik,
  isEdit,
  params,
  dispatch,
  navigate
) => {
  formik.validateForm().then(errors => {
    if (Object.keys(errors).length === 0) {
      const roleSettings = params.role + "Settings"
      const settings = {
        ...(params.role === "student"
          ? { noOfJobAttempts: formikValues?.noOfJobAttempts }
          : {}),
        ...(params.role === "company"
          ? {
              jobPostLimit: formikValues?.jobPostLimit,
              // noOfCandidateApply: formikValues?.noOfCandidateApply,
              hrUserLimit: formikValues?.hrUserLimit,
              unlockCandidate: formikValues?.unlockCandidate,
            }
          : {}),
      }
      const values = {
        price: formikValues?.price,
        actualPrice: formikValues?.actualPrice,
        description: formikValues?.description,
        [roleSettings]: settings,
      }
      if (isEdit) {
        dispatch(onUpdatePlan({ ...values }, params, navigate))
      } else {
        dispatch(onAddNewPlan({ ...values, role: params.role }, navigate))
      }
    } else {
      const touchedFields = {}
      Object.keys(errors).forEach(field => {
        touchedFields[field] = true
      })
      formik.setTouched(touchedFields)
    }
  })
}

export const convertToTitleCase = str => {
  return str
    .replace(/([A-Z])/g, " $1") // Insert a space before all capital letters
    .replace(/^./, function (char) {
      return char.toUpperCase()
    }) // Capitalize the first character
    .trim() // Remove leading/trailing whitespace
}
