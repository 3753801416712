import { del, get, post, patch } from "../api_helper"
import { TRANSACTIONS_ROUTES } from "../url_helper"

// Get Transactions
export const getTransactions = queryParams =>
  get(`${TRANSACTIONS_ROUTES}/admin/${queryParams}`)

export const getCompanyTransactions = (queryParams) => get(`${TRANSACTIONS_ROUTES+queryParams}`)

export const addTransactions = data =>
  post(`${TRANSACTIONS_ROUTES}/admin`, data)

export const createTransactions = data => post(`${TRANSACTIONS_ROUTES}`, data)
export const updateTransactionsStatus = data =>
  patch(`${TRANSACTIONS_ROUTES}`, data)

export const generateKey = data => post(`/payment/generate-key`, data)

export const getTransactionStatus = hash =>
  get(`/payment/get-transaction-status/${hash}`)

export const purChaseSubsCriptions = (data,role) => post(`/${role}/subscription`, data)

export const purChasePackages = data => post(`/user-packages/purchase`, data)


export const generateRandomId = length => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let result = ""
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}
