import {
  GET_CHAPTER_CONTENT_FAIL,
  GET_CHAPTER_CONTENT_SUCCESS,
  ADD_CHAPTER_CONTENT_SUCCESS,
  ADD_CHAPTER_CONTENT_FAIL,
  UPDATE_CHAPTER_CONTENT_SUCCESS,
  UPDATE_CHAPTER_CONTENT_FAIL,
  DELETE_CHAPTER_CONTENT_SUCCESS,
  DELETE_CHAPTER_CONTENT_FAIL,
  UPDATE_CHAPTER_CONTENT_LOADING_STATE,
  RESET_CHAPTER_CONTENT,
} from "./actionTypes"

const INIT_STATE = {
  chapterContent: [],
  chapterContentDetail: {},
  error: {},
  loading: false,
}

const chapterContent = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHAPTER_CONTENT_SUCCESS:
      return {
        ...state,
        chapterContent: action.payload,
      }
    case GET_CHAPTER_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CHAPTER_CONTENT_SUCCESS: {
      return {
        ...state,
        chapterContentDetail: action.payload,
      }
    }

    case ADD_CHAPTER_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CHAPTER_CONTENT_SUCCESS:
      return {
        ...state,
        chapterContent: state.chapterContent.map(content =>
          content._id.toString() === action.payload._id.toString()
            ? { ...content, ...action.payload }
            : content
        ),
      }

    case UPDATE_CHAPTER_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CHAPTER_CONTENT_SUCCESS:
      return {
        ...state,
        chapterContent: state.chapterContent.filter(
          content => content?._id.toString() !== action.payload?.contentId.toString()
        ),
      }

    case DELETE_CHAPTER_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CHAPTER_CONTENT_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }
    case RESET_CHAPTER_CONTENT:
      return {
        ...INIT_STATE,
      }

    default:
      return state
  }
}

export default chapterContent
