import {
  GET_ANNOUNCEMENTS_SUCCESS,
  GET_ANNOUNCEMENTS_FAIL,
  UPDATE_ANNOUNCEMENT_LOADING_STATE,
  ADD_ANNOUNCEMENT_SUCCESS,
  ADD_ANNOUNCEMENT_FAIL,
  GET_ANNOUNCEMENT_DETAIL_SUCCESS,
  UPDATE_ANNOUNCEMENT_SUCCESS,
  UPDATE_ANNOUNCEMENT_FAIL,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAIL,
  GET_ANNOUNCEMENT_DETAIL_FAIL,
  GET_ANNOUNCEMENTS_REPORT_FAIL,
  GET_ANNOUNCEMENTS_REPORT_SUCCESS,
  RESET_ANNOUNCEMENTS,
} from "./actionTypes"

const INIT_STATE = {
  announcements: [],
  announcementReports: [],
  announcementDetail: {},
  error: {},
  loading: false,
}

const announcements = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        announcements: action.payload,
      }

    case GET_ANNOUNCEMENTS_REPORT_SUCCESS:
      return {
        ...state,
        announcementReports: action.payload,
      }

    case UPDATE_ANNOUNCEMENT_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case GET_ANNOUNCEMENTS_FAIL:
    case ADD_ANNOUNCEMENT_FAIL:
    case UPDATE_ANNOUNCEMENT_FAIL:
    case DELETE_ANNOUNCEMENT_FAIL:
    case GET_ANNOUNCEMENT_DETAIL_FAIL:
    case GET_ANNOUNCEMENTS_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        announcements: [...state.announcements, action.payload],
      }

    case GET_ANNOUNCEMENT_DETAIL_SUCCESS:
      return {
        ...state,
        announcementDetail: action.payload,
      }

    case UPDATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        announcements: state.announcements.map(announcement =>
          announcement._id.toString() === action.payload._id.toString()
            ? { announcement, ...action.payload }
            : announcement
        ),
      }

    case DELETE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        announcements: state.announcements.filter(
          announcement =>
            announcement._id.toString() !== action.payload.toString()
        ),
      }

    case RESET_ANNOUNCEMENTS:
      return {
        ...state,
        announcementDetail: {},
      }

    default:
      return state
  }
}

export default announcements
