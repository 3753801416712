import * as Yup from "yup"

export const createvalidationSchemas = fields => {
  const obj = {}
  fields.forEach(field => {
    if (field.type === "checkBoxes") {
      obj[field.name] = Yup.string().required(
        `Please Select At Least One ${field.label}`
      )
    } else if (field.type === "multiSelect") {
      obj[field.name] = Yup.array().test(
        "isArray",
        `Please Select At Least One ${field.label}`,
        value => Array.isArray(value) && value.length > 0
      )
    } else if (field.type === "select") {
      obj[field.name] = Yup.string().required(`Please Select ${field.label}`)
    } else if (field.type === "search") {
      obj[field.name] = Yup.string().required(`Field is Required*`)
    } else if (field.type === "url") {
      obj[field.name] = Yup.string().url(`Please Enter a valid ${field.label}`)
    } else if (field.type === "date") {
      obj[field.name] = Yup.string().required(
        `Please Enter a ${field.label} Date`
      )
    } else if (field.type === "cityState") {
      obj[field.state.name] = Yup.string().required(
        `Please Select Any State Name.`
      )
      obj[field.city.name] = Yup.string().required(
        `Please Select Any City Name.`
      )
    } else if (field.type === "time") {
      obj[field.name] = Yup.string().required(
        `Please Enter a ${field.label} Time`
      )
    } else if (field.type === "file") {
      // obj[field.name] = Yup.mixed().required(`Please Select ${field.label}`);
      obj[field.name] = Yup.mixed()
    } else if (field.type === "multiFields") {
      field.fields.forEach(mFiedl => {
        if (mFiedl.type === "checkBoxes") {
          obj[mFiedl.name] = Yup.string().required(
            `Please Select At Least One ${mFiedl.label}`
          )
        } else if (mFiedl.type === "multiSelect") {
          obj[mFiedl.name] = Yup.array().test(
            "isArray",
            `Please Select At Least One ${mFiedl.label}`,
            value => Array.isArray(value) && value.length > 0
          )
        } else if (mFiedl.type === "select") {
          obj[mFiedl.name] = Yup.string().required(
            `Please Select ${mFiedl.label}`
          )
        } else if (field.type === "url") {
          obj[field.name] = Yup.string().url(
            `Please Enter a valid ${field.label}`
          )
        } else if (mFiedl.type === "file") {
          // obj[mFiedl.name] = Yup.mixed().required(`Please Select ${mFiedl.label}`);
          obj[mFiedl.name] = Yup.mixed()
        } else if (mFiedl.type === "text") {
          if (mFiedl.name === "postalCode") {
            obj[mFiedl.name] = Yup.string().matches(/^\d{6}$/, {
              message: `Postal Code must be exactly 6 digits`,
            })
          } else if (mFiedl.name === "gstNumber") {
            obj[mFiedl.name] = Yup.string()
              .test(
                "no-special-characters",
                "Special characters are not allowed",
                value => !/[^\w\d]/.test(value)
              )
              .test(
                "is-alphanumeric",
                "GST Number must be exactly 15 alphanumeric characters",
                value => /^[a-zA-Z0-9]{15}$/.test(value)
              )
          } else if (mFiedl.name === "contactNumber") {
            obj[mFiedl.name] = Yup.string()
              .matches(/^[0-9]+$/, {
                message: "Contact Number must contain only numeric digits",
              })
              .matches(/^\d{10}$/, {
                message: "Contact Number must be exactly 10 digits",
              })
              .required(`Please Enter ${field.label}`)
          } else if (mFiedl.name === "phone") {
            obj[mFiedl.name] = Yup.string()
              .matches(/^[0-9]+$/, {
                message: "phone Number must contain only numeric digits",
              })
              .matches(/^\d{10}$/, {
                message: "phone Number must be exactly 10 digits",
              })
              .required(`Please Enter ${mFiedl.label}`)
          } else {
            obj[field.name] = Yup.string().required(
              `Please Enter ${field.label}`
            )
          }
        } else {
          obj[mFiedl.name] = Yup.string().required(
            `Please Enter ${mFiedl.label}`
          )
        }
      })
    } else if (field.type === "text") {
      if (field.name === "postalCode") {
        obj[field.name] = Yup.string().matches(/^\d{6}$/, {
          message: `Postal Code must be exactly 6 digits`,
        })
      } else if (field.name === "gstNumber") {
        obj[field.name] = Yup.string()
          .test(
            "no-special-characters",
            "Special characters are not allowed",
            value => !/[^\w\d]/.test(value)
          )
          .test(
            "is-alphanumeric",
            "GST Number must be exactly 15 alphanumeric characters",
            value => /^[a-zA-Z0-9]{15}$/.test(value)
          )
      } else if (field.name === "contactNumber") {
        obj[field.name] = Yup.string()
          .matches(/^[0-9]+$/, {
            message: "Contact Number must contain only numeric digits",
          })
          .matches(/^\d{10}$/, {
            message: "Contact Number must be exactly 10 digits",
          })
          .required(`Please Enter ${field.label}`)
      } else if (field.name === "phone") {
        obj[field.name] = Yup.string()
          .matches(/^[0-9]+$/, {
            message: "phone Number must contain only numeric digits",
          })
          .matches(/^\d{10}$/, {
            message: "phone Number must be exactly 10 digits",
          })
          .required(`Please Enter ${field.label}`)
      } else {
        obj[field.name] = Yup.string().required(`Please Enter ${field.label}`)
      }
    } else if (field.type === "cityState") {
      obj[field.state.name] = Yup.string().required(
        `Please Select Any State Name.`
      )
      obj[field.city.name] = Yup.string().required(
        `Please Select Any City Name.`
      )
    } else if (field.type === "number") {
      if (["price", "limit", "discountPrice"].includes(field.name)) {
        obj[field.name] = Yup.number()
          .min(0, `Please Enter a valid ${field.label}`)
          .required(`Please Enter ${field.label}`)
      } else {
        obj[field.name] = Yup.string().required(`Please Enter ${field.label}`)
      }
    } else {
      obj[field.name] = Yup.string().required(`Please Enter ${field.label}`)
    }
  })
  return Yup.object().shape(obj)
}
