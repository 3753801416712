import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import withRouter from "components/Common/withRouter"
import { formFields } from "./constants"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import ProfileHeader from "../../components/Common/Card/ProfileHeader"
import AppForm from "../../components/Common/Form/index"
import AppLoader from "../../components/Common/Loader"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import {
  getStudentDetail as onGetStudentDetail,
  updateStudent as onUpdateStudent,
} from "../../store/students/actions"
const staticURL = process.env.REACT_APP_STATIC_URL

const EditStudentProfile = props => {
  //meta title
  document.title = "Profile | QAPRENEUR"
  const params = props.router.params
  const role = localStorage.getItem("authRole")

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [user, setUser] = useState({})

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const { loading, studentDetail } = useSelector(state => ({
    loading: state.students.loading,
    studentDetail: state.students?.studentDetail,
  }))

  useEffect(() => {
    if (params.studentId) {
      dispatch(onGetStudentDetail(params.studentId, "users/me"))
    }
  }, [dispatch, onGetStudentDetail])

  useEffect(() => {
    if (studentDetail) {
      setUser({
        ...studentDetail,
        ...studentDetail?.extra,
        dob: studentDetail?.dob
          ? new Date(studentDetail.dob).toISOString().split("T")[0]
          : "",
      })
    }
  }, [studentDetail])

  const handleSubmit = (formikValues, formik) => {
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        const newObj = { ...user, ...user?.extra, ...formikValues }
        const data = {
          firstName: newObj?.firstName,
          lastName: newObj?.lastName,
          name: newObj?.firstName + " " + newObj?.lastName,
          email: newObj?.email,
          phone: newObj?.phone,
          address: newObj?.address,
          city: newObj?.city,
          state: newObj?.state,
          country: newObj?.country,
          dob: newObj?.dob,
        }
        if (role === "admin") {
          dispatch(onUpdateStudent(data, studentDetail._id, navigate))
        } else {
          dispatch(
            onUpdateStudent("updateMe", data, studentDetail._id, navigate)
          )
        }
      } else {
        const touchedFields = {}
        Object.keys(errors).forEach(field => {
          touchedFields[field] = true
        })
        formik.setTouched(touchedFields)
        console.log("Validation errors:", errors)
      }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Dashboard"
            breadcrumbItem="Student Profile"
            navigate={-1}
          />
          {error && error ? <Alert color="danger">{error}</Alert> : null}
          {success ? <Alert color="success">{success}</Alert> : null}
          {!loading ? (
            <>
              <Row>
                <Col lg="12">
                  <ProfileHeader
                    imageUrl={
                      user && user.image ? staticURL + user.image : avatar
                    }
                    profileName={user ? user.name : ""}
                    profileEmail={user ? user.email : ""}
                    LayoutType={"HORIZONTAL"}
                    style={{}}
                  />
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <AppForm
                    name="Student Information"
                    layoutType="UPDATE"
                    fields={formFields}
                    // validation={validation}
                    initialValues={user}
                    handleFormSubmit={handleSubmit}
                  />
                </CardBody>
              </Card>
            </>
          ) : (
            <AppLoader />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditStudentProfile)
