import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import {
  Col,
  Container,
  Row,
  Spinner
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import {
  getTestDetail as onGetTestDetail,
  getTestCategories as onGetTestCategories,
  resetTests as onResetTests
} from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import Avatar from "../../assets/images/users/img_placeholder.png"
import CardProfile from "../../components/Common/Card/ProfileHeader";
import CardAbout from "../../components/Common/Card/CardAbout";
import CardInfo from "../../components/Common/Card/CardInfo";
import QuestionsCard from "../../components/Common/Card/QuestionsCard";

const TestDetail = props => {
  const BASE_URL = process.env.REACT_APP_STATIC_URL
  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  document.title = "Test Detail | QAPRENEUR"

  const dispatch = useDispatch()

  const { testDetail, testCategories } = useSelector(state => ({
    testDetail: state.tests.testDetail,
    testCategories: state.testCategories.testCategories,
  }))

  const params = props.router.params

  useEffect(() => {
    if (testDetail) {
      if (Object.keys(testDetail).length) {
        setIsLoading(false)
      }
      if (testDetail.questions) {
        const ques = testDetail.questions.map(question => {
          const que = JSON.parse(question.question)
          que.questionId = question._id
          return que
        })
        setQuestions(ques)
      }
    }
  }, [testDetail])

  useEffect(() => {
    if (params && params.id) {
      dispatch(onGetTestDetail(params.id))
    }
    dispatch(onGetTestCategories())
    return () => {
      dispatch(onResetTests())
      setIsLoading(false)
    }
  }, [dispatch, onGetTestDetail])

  const imageUrl = testDetail?.image ? BASE_URL + testDetail?.image : Avatar

  const cardFields = [
    { label: "Test Name", value: testDetail?.name },
    { label: "Category", value: testDetail?.testCategoryId?.type || "" },
    { label: "Test Duration (minutes)", value: testDetail?.duration },
    { label: "Test Passing Marks", value: testDetail?.passingMark },
    { label: "Test Type", value: testDetail?.type },
    { label: "Status", value: testDetail?.status },
    { label: "Test Price", value: testDetail?.price },
    { label: "Discount (%)", value: testDetail?.discount+ "%" },
    { label: "Discount Price", value: testDetail?.discountPrice },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="TestList"
            breadcrumbItem="Test Profile"
            navigate="/tests-list"
          />
          {isLoading ? (
            <div className="d-flex justify-content-center"><Spinner className="ms-2" color="dark" size="sm" /></div>
          ) : (
            <Row>
              <Col lg={12}>
                <CardProfile
                  imageUrl={imageUrl}
                  profileName={testDetail ? testDetail.name : ""}
                  infoBadge={testDetail?.testCategories && testDetail?.testCategories?.length>0 ? testDetail?.testCategories.map(testCat=>testCat.type)  : [] }
                  LayoutType={"VERTICAL"}
                  style={{ padding: 0 }}
                />
              </Col>
              <Col lg={12}>
                <CardAbout
                  title="About"
                  descripion={testDetail?.description}
                  isHtml={true}
                />
              </Col>
              <Col lg={12}>
                <CardInfo
                  title="More Information"
                  fields={cardFields}
                />
              </Col>
              <Col lg={12}>
                <QuestionsCard title="Questions" questions={questions} />
              </Col>
            </Row>)}

        </Container>
      </div>
    </React.Fragment>
  )
}

TestDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(TestDetail)
