import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

import 'firebase/firestore'; // Add any other Firebase services you are using

// Your Firebase config object
const firebaseConfig = {
  apiKey: "AIzaSyDp3rn4tnLWPULRF7IhFpwYCUE4Nv5j3uQ",
  authDomain: "qa-preneur.firebaseapp.com",
  projectId: "qa-preneur",
  storageBucket: "qa-preneur.appspot.com",
  messagingSenderId: "1068515579835",
  appId: "1:1068515579835:web:fa79a9335de55f1fd8169e",
  measurementId: "G-0W30DB9XN4"
};

const app = initializeApp(firebaseConfig);
// export const auth = app.auth();
// export const firestore = app.firestore();

// export default firebase;
export const auth = getAuth(app);
export const firebaseApp = app
