import { call, put, takeEvery } from "redux-saga/effects"
import { dynamicApiFail, dynamicApiSuccess } from "./actions"
import {
  getDynamicSideBarLayout,
  getDynamicList,
  userProfileTab,
} from "../../helpers/api/dynamicApi"
import {
  GET_DYNAMIC_LIST,
  GET_DYNAMIC_PROFILE_TAB_DATA,
  GET_DYNAMIC_SIDEBAR,
  DYNAMIC_API_FAIL,
  DYNAMIC_API_SUCCESS,
} from "./actionTypes"

function* getDynamicListDataSaga({ slug }) {
  try {
    const data = yield call(getDynamicList, slug)
    yield put(dynamicApiSuccess(GET_DYNAMIC_LIST, slug, data))
  } catch (error) {
    yield put(dynamicApiFail(DYNAMIC_API_FAIL, error))
  }
}

function* getDynamicProfileTabDataSaga({ slug, userId, queryParams }) {
  try {
    const data = yield call(userProfileTab, slug, userId, queryParams)
    yield put(dynamicApiSuccess(GET_DYNAMIC_PROFILE_TAB_DATA, slug, data))
  } catch (error) {
    yield put(dynamicApiFail(DYNAMIC_API_FAIL, error))
  }
}

function* getDynamicSideBarDataSaga() {
  try {
    const data = yield call(getDynamicSideBarLayout)
    yield put(dynamicApiSuccess(GET_DYNAMIC_SIDEBAR, slug, data))
  } catch (error) {
    yield put(dynamicApiFail(DYNAMIC_API_FAIL, error))
  }
}
 function* watchGetDynamicData() {
  yield takeEvery(GET_DYNAMIC_LIST, getDynamicListDataSaga)
  yield takeEvery(GET_DYNAMIC_PROFILE_TAB_DATA, getDynamicProfileTabDataSaga)
  yield takeEvery(GET_DYNAMIC_SIDEBAR, getDynamicSideBarDataSaga)
}

export default watchGetDynamicData