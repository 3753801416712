import {
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_DETAIL_FAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  UPDATE_COMPANY_LOADING_STATE,
  RESET_COMPANY,
  GET_COMPANY_BILLING_INFO_FAIL,
  GET_COMPANY_BILLING_INFO_SUCCESS,
  ADD_COMPANY_BILLING_INFO_SUCCESS,
  ADD_COMPANY_BILLING_INFO_FAIL,
  UPDATE_COMPANY_BILLING_INFO_FAIL,
  UPDATE_COMPANY_BILLING_INFO_SUCCESS,
  GET_JOBS_POSTED_BY_COMPANY_FAIL,
  GET_JOBS_POSTED_BY_COMPANY_SUCCESS,
  GET_COMPANY_SUBSCRIPTION_SUCCESS,
  GET_COMPANY_SUBSCRIPTION_FAIL,
  GET_COMPANY_ALL_PLAN_SUCCESS,
  GET_COMPANY_ALL_PLAN_FAIL,
  GET_USERS_VIEW_HISTORY_SUCCESS,
  GET_USERS_VIEW_HISTORY_FAIL,
  GET_COMPANY_CREDIT_SUCCESS,
  GET_COMPANY_CREDIT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  companies: [],
  jobs: [],
  Plans: [],
  userViewHistory: [],
  companyDetail: {},
  companyBillingInfo: {},
  Subscriptions: {},
  activePlan: {},
  credit: {},
  error: {},
  loading: false,
}

const companies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
      }
    case RESET_COMPANY:
      return {
        companies: [],
        companyDetail: {},
        error: {},
        loading: false,
      }
    case UPDATE_COMPANY_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case GET_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload],
      }

    case ADD_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        companyDetail: action?.payload?.companyDetail,
        // Subscriptions: action?.payload?.subscription,
      }

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companyDetail:action.payload,
        companies: state.companies.map(company =>
          company._id.toString() === action.payload._id.toString()
            ? { company, ...action.payload }
            : company
        ),
      }

    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.filter(
          company => company._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANY_BILLING_INFO_SUCCESS:
      return {
        ...state,
        companyBillingInfo: action.payload,
      }
    case GET_COMPANY_BILLING_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COMPANY_BILLING_INFO_SUCCESS:
      return {
        ...state,
        companyBillingInfo: action.payload,
      }
    case ADD_COMPANY_BILLING_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_COMPANY_BILLING_INFO_SUCCESS:
      return {
        ...state,
        companyBillingInfo: action.payload,
      }
    case UPDATE_COMPANY_BILLING_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_JOBS_POSTED_BY_COMPANY_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
      }
    case GET_JOBS_POSTED_BY_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPANY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        Subscriptions: action.payload?.subscription,
        activePlan: action.payload?.userSubscription,
      }
    case GET_COMPANY_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPANY_ALL_PLAN_SUCCESS:
      return {
        ...state,
        Plans: action.payload,
      }
    case GET_COMPANY_ALL_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_USERS_VIEW_HISTORY_SUCCESS:
      return {
        ...state,
        userViewHistory: action.payload,
      }
    case GET_USERS_VIEW_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANY_CREDIT_SUCCESS:
      return {
        ...state,
        credit: action.payload,
      }
    case GET_COMPANY_CREDIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default companies
