import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { columns } from "./constants"
import {
  getAnnouncements as onGetAnnouncements,
  updateAnnouncement as onUpdateAnnouncement,
  deleteAnnouncement as onDeleteAnnouncement,
} from "../../store/announcement/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "../../components/Common/Table/index"

const AnnouncementList = () => {
  document.title = "Test List | QAPRENEUR"
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [announcementsData, setAnnouncementsData] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [search, setSearch] = useState(null)

  const { announcements, loading } = useSelector(state => ({
    announcements: state?.announcement?.announcements,
    loading: state?.announcement?.loading,
  }))

  const getData = () => {
    const queryParams = {
      page: pageNo,
      limit: rowPerPage,
    }
    if (search && search.length > 0) {
      queryParams.search = search
    }

    dispatch(onGetAnnouncements(queryParams))
  }

  useEffect(() => {
    getData()
  }, [pageNo, rowPerPage, search])
  useEffect(() => {
    if (!isEmpty(announcements)) {
      setAnnouncementsData(
        announcements
          .map(item => ({ ...item ,image:item?.icon}))
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      )
    } else {
      setAnnouncementsData([])
    }
  }, [announcements, pageNo])

  const handleSearch = query => {
    setPageNo(1)
    setSearch(query)
  }

  const onChangePagination = (limit, page) => {
    setRowPerPage(limit)
    setPageNo(page)
  }

  const handleActions = (data, type) => {
    if (type === "VIEW") {
    } else if (type === "EDIT") {
      navigate(`/edit-announcement/${data._id}`)
    } else if (type === "DELETE") {
      dispatch(onDeleteAnnouncement(data._id))
    } else if (type === "VIEW_REPORT") {
      navigate(`/announcement/report/${data._id}`)
    } else if (type === "STATUS_CHANGE") {
      const status = data.status === "active" ? "deactive" : "active"
      dispatch(onUpdateAnnouncement({ status }, data._id))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Dashboard"
            navigate={"/dashboard"}
            breadcrumbItem="Announcement"
          />
          <Row>
            <Col lg="12">
              <div>
                <TableContainer
                  loading={loading}
                  data={announcementsData}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isJobListGlobalFilter={false}
                  customPageSize={10}
                  className="project-list-table table-nowrap align-middle table-borderless"
                  columns={columns}
                  showPagination={true}
                  addButton={{
                    link: "/create-announcement",
                    title: "Add Announcement",
                  }}
                  onChangePagination={onChangePagination}
                  handleStatus={data => handleActions(data, "STATUS_CHANGE")}
                  handleSearch={handleSearch}
                  handleActions={handleActions}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AnnouncementList
