import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Container } from "reactstrap"
import {
  getReviewDetail as onGetReviewDetail,
  addNewReview as onAddNewReview,
  updateReview as onUpdateReview,
  resetReviews as onResetReviews,
} from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AppForm from "../../components/Common/Form"
import { formFields } from "./constants"
import AppLoader from "../../components/Common/Loader"

const AddReview = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [isEdit, setIsEdit] = useState(false)
  const [imageFile, setImageFile] = useState("")

  document.title = `${isEdit ? "Edit Review" : "Create Review"} | QAPRENEUR`

  const { reviewDetail, loading } = useSelector(state => ({
    reviewDetail: state.reviews?.reviewDetail,
    loading: state.reviews?.loading,
  }))

  useEffect(() => {
    const currentLocation = location.pathname
    const status = currentLocation.includes("edit-review")
    setIsEdit(status)
    if (status && params?.reviewId) dispatch(onGetReviewDetail(params.reviewId))
    return () => dispatch(onResetReviews())
  }, [])

  const handleSubmit = (formikValues, formik) => {
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length === 1 && errors.multiFields) {
        formikValues.image = imageFile ? imageFile : formikValues.image
        if (!isEdit) {
          dispatch(onAddNewReview(formikValues, navigate))
        } else {
          dispatch(
            onUpdateReview({ ...formikValues }, params.reviewId, navigate)
          )
        }
      } else {
        const touchedFields = {}
        Object.keys(errors).forEach(field => {
          touchedFields[field] = true
        })
        formik.setTouched(touchedFields)
      }
    })
  }

  const handleImage = file => {
    setImageFile(file)
  }

  const formattedPostedAt = reviewDetail && reviewDetail?.postedAt? new Date(reviewDetail?.postedAt).toISOString().split('T')[0] : '';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Reviews"
            navigate="/reviews"
            breadcrumbItem={`${isEdit ? "Edit Review" : "Create Review"}`}
          />
          {loading && <AppLoader />}
          {!loading && (
            <Card>
              <CardBody>
                <AppForm
                  name=""
                  layoutType={isEdit ? "UPDATE" : "CREATE"}
                  fields={formFields}
                  initialValues={{ ...reviewDetail,postedAt:formattedPostedAt }}
                  handleFormSubmit={(formikValues, formik) =>
                    handleSubmit(formikValues, formik)
                  }
                  handleImage={handleImage}
                />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddReview
