import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  RESET_PASSWORD,
  RESET_PASSWORD_HR,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  resetPasswordSuccess,
  setLoading,
} from "./actions"
import toastr from "toastr"
import {
  postJwtLogin,
  resetPwd,
  resetPwdHr,
} from "../../../helpers/api/usersApi"

function* loginUser({ payload: { user, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(
        postJwtLogin,
        {
          email: user.email,
          password: user.password,
        },
        type
      )

      if (response?.data?.user.roleId.role !== "student") {
        localStorage.setItem("authRole", response.data.user.roleId.role)
        localStorage.setItem("authUser", JSON.stringify(response.data.user))
        localStorage.setItem("authToken", response.activeToken)
        localStorage.setItem("refreshToken", response.refreshToken)
        toastr.success("You have successfully signed in!")
        yield put(loginSuccess(response))
        history("/dashboard")
      } else {
        yield put(setLoading(false))
        toastr.error(
          "for student we are not allowed to login on web please use mobile application"
        )
      }
    }
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      toastr.error(error.message)
    } else {
      toastr.error(error.response.data.message)
    }
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const authRole = localStorage.getItem("authRole")
    if (authRole && authRole === "admin") {
      history("/admin/login")
    } else {
      history("/login")
    }
    localStorage.removeItem("authUser")
    localStorage.removeItem("authRole")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("authToken")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      // const response = yield call(postSocialLogin, data)
      // localStorage.setItem("authUser", JSON.stringify(response))
      // yield put(loginSuccess(response))
    }
    history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* resetPassword({ payload }) {
  try {
    const res = yield call(resetPwd, payload)
    if (res?.data?.user) {
      toastr.success("You have successfully changed your passowrd!")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* resetPasswordHr({ payload }) {
  try {
    const res = yield call(resetPwdHr, payload)
    if (res?.data?.user) {
      toastr.success("User passsword  successfully changed!")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(RESET_PASSWORD, resetPassword)
  yield takeEvery(RESET_PASSWORD_HR, resetPasswordHr)
}

export default authSaga
