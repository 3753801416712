export const columns = [
  {
    header: "#",
    key: "",
  },
  {
    header: "Image",
    name: "image",
    key: "IMAGE",
  },
  {
    header: "Name",
    name: "userName",
    key: "DEFAULT",
  },
  {
    header: "Rating",
    name: "rating",
    key: "DEFAULT",
  },
  {
    header: "Description",
    name: "description",
    key: "DESCRIPTION",
  },
  {
    header: "Posted Date",
    name: "postedAt",
    key: "DATE",
  },
  {
    header: "Actions",
    key: "MORE_ACRIONS",
    dropDownOptions: [
      { label: "Edit", type: "EDIT" },
      { label: "Delete", type: "DELETE" },
    ],
  },
]

export const formFields = [
  {
    type: "multiFields",
    name: "multiFields",
    label: "multiFields",
    fields: [
      {
        label: "User Name",
        name: "userName",
        type: "text",
        placeholder: "Enter User Name",
        required: true,
      },
      {
        label: "5-Star Rating",
        name: "rating",
        type: "select",
        options: [
          { value: "", label: "give Rate" },
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "5", label: "5" },
        ],
        required: true,
      },
      {
        label: "Posted Date*",
        name: "postedAt",
        type: "date",
        required: true,
      },
    ],
  },
  {
    label: "Image",
    name: "image",
    type: "file",
    required: true,
  },
  {
    label: "Description*",
    name: "description",
    type: "textEditor",
    required: true,
    lg: 12,
  },
]
