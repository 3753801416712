import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//testCategories
import testCategories from "./test-categories/reducer"

//tests
import tests from "./tests/reducer"

//mock tests

import mockTests from "./mockTests/reducer"

//questions
import questions from "./questions/reducer"

//jobs
import jobs from "./jobs/reducer"

//applicants
import applicants from "./applicants/reducer"

//courses
import courses from "./courses/reducer"

//chapters
import chapters from "./chapters/reducer"

//flashCards
import flashCards from "./flashCards/reducer"

// Chapter Content
import chapterContents from "./chapterContent/reducer"

//students
import students from "./students/reducer"

//coachings
import coachings from "./coachings/reducer"

//companies
import companies from "./companies/reducer"

//colleges
import colleges from "./colleges/reducer"

//roles
import roles from "./roles/reducer"

//skills
import skills from "./skills/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"
//DynamicReducer
import dynamicReducer from "./dynamic/reducer"

// Multi Media
import multiMedia from "./multiMedia/reducer"

// Transactions
import transactions from "./transactions/reducer"

// subscriptions
import subscriptions from "./subscription/reducer"
import notifications from "./notification/reducer"


import HrUsers from "./HrUsers/reducer"
import reviews from "./ourReview/reducer"
import helpcenter from "./helpcenter/reducer"
import announcement from "./announcement/reducer"
import plans from "./plan/reducer"
import collegeRequests from "./collegeRequests/reducer";
import jobReferrals from "./jobReferral/reducer";
import packages from "./package/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  testCategories,
  tests,
  mockTests,
  jobs,
  applicants,
  roles,
  chapters,
  flashCards,
  chapterContents,
  questions,
  skills,
  courses,
  students,
  colleges,
  Dashboard,
  dynamicReducer,
  coachings,
  companies,
  multiMedia,
  transactions,
  subscriptions,
  HrUsers,
  reviews,
  helpcenter,
  announcement,
  notifications,
  plans,
  collegeRequests,
  jobReferrals,
  packages
})

export default rootReducer
