import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  CardBody,
  UncontrolledTooltip,
  Input,
} from "reactstrap"
import moment from "moment"
// Import image assets here
import experienceIcon from "../../assets/images/icons/experience.svg"
import salaryIcon from "../../assets/images/icons/salary.svg"
import onsiteIcon from "../../assets/images/icons/onSite.svg"
import locationIcon from "../../assets/images/icons/location.svg"
import arrowRightWhite from "../../assets/images/icons/arrowRight.png"
import placeholder from "../../assets/images/users/img_placeholder.png"
import vacancyIcon from "../../assets/images/icons/vacancyIcon.svg"

const JobCard = ({ job, companyDetail, handleChangeStatus }) => {
  const staticURL = process.env.REACT_APP_STATIC_URL
  const [status, setstatus] = useState(job?.status)
  return (
    <div>
      {job && Object.keys(job).length && (
        <Card>
          <CardBody>
            <div className="job_full_info">
              <Row className="job_info_inner">
                <Col lg={7} className="job_short_info">
                  <div className="img_job_outer">
                    <img
                      src={job?.image ? staticURL + job?.image : placeholder}
                      width={72}
                      height={72}
                      alt="Job"
                    />
                  </div>
                  <div>
                    <h3 className="job_title">{job?.jobTitle}</h3>
                    <p className="job_company_name">
                      Company: {companyDetail?.name}
                    </p>
                  </div>
                  <div className="ms-2 d-flex justify-content-center">
                    <p className="mb-0 mt-2">
                      <i className="mdi mdi-clock-outline me-2" />
                      {moment(job?.createdAt).fromNow()}
                    </p>
                  </div>
                </Col>

                <Col
                  lg={5}
                  className="d-flex align-items-center justify-content-between"
                >
                  <div className="form-check form-switch form-switch-md text-center d-flex align-items-center justify-content-center">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      style={{ height: "16px", width: "28px" }}
                      defaultChecked={job?.status == "active"}
                      onChange={() => {
                        handleChangeStatus(job)
                        setstatus(
                          job?.status == "active" ? "deactive" : "active"
                        )
                      }}
                    />
                    <label
                      className="form-check-label font-size-15 text-secondary"
                      htmlFor="customSwitchsizemd"
                      style={{ textTransform: "capitalize" }}
                    >
                      {status}
                    </label>
                  </div>
                  <Link
                    className="me-2 d-flex"
                    id="edittooltip"
                    to={`/jobs-edit/${job?._id}`}
                  >
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                    <p className="font-size-15 m-1 text-secondary">Edit</p>
                    <i
                      style={{ color: "#1AB69D", margin: "0" }}
                      className="mdi mdi-pencil font-size-18 ms-2"
                    />
                  </Link>
                  <Link
                    className="me-2 d-flex"
                    id="viewTooltip"
                    to={`/jobs-details/${job?._id}`}
                  >
                    <UncontrolledTooltip placement="top" target="viewTooltip">
                      View
                    </UncontrolledTooltip>
                    <p className="font-size-15 m-1 text-secondary">View</p>
                    <i
                      style={{ color: "#F8941F", margin: "0" }}
                      className="mdi mdi-eye-outline font-size-20 ms-2"
                    />
                  </Link>
                  <Link className="btn_apply_now font-size-12" to="">
                    View Applicant
                    <img
                      src={arrowRightWhite}
                      height="10"
                      width="10"
                      alt="Apply Now"
                      className="ms-1"
                    />
                  </Link>
                </Col>
              </Row>

              <div
                className="job_description"
                dangerouslySetInnerHTML={{
                  __html: job?.description,
                }}
              ></div>

              <div>
                {job && job?.skillIds && job?.skillIds.length > 0 ? (
                  job?.skillIds.map((skill, index) => (
                    <span key={index} className="skill_tag">
                      {skill?.name}
                    </span>
                  ))
                ) : (
                  <p>No Skills Added</p>
                )}
              </div>

              <div className="jobs_additional_info">
                <div className="additional_info">
                  <img src={onsiteIcon} width={35} height={35} alt="On Site" />
                  <p className="mb-0 additional_info_name">{job?.jobType}</p>
                </div>
                <div className="additional_info">
                  <img src={onsiteIcon} width={35} height={35} alt="On Site" />
                  <p className="mb-0 additional_info_name">{job?.type}</p>
                </div>
                <div className="additional_info">
                  <img src={salaryIcon} width={35} height={35} alt="Salary" />
                  <p className="mb-0 additional_info_name">
                    {job?.salaryRange}
                  </p>
                </div>
                <div className="additional_info">
                  <img
                    src={experienceIcon}
                    width={35}
                    height={35}
                    alt="Experience"
                  />
                  <p className="mb-0 additional_info_name">
                    {job?.experience}-yr
                  </p>
                </div>

                <div className="additional_info">
                  <img
                    src={locationIcon}
                    width={35}
                    height={35}
                    alt="Location"
                  />
                  <p className="mb-0 additional_info_name">{job?.location}</p>
                </div>
                <div className="additional_info">
                  <img
                    src={vacancyIcon}
                    width={35}
                    height={35}
                    alt="No Of Vacancy"
                  />
                  <p className="mb-0 additional_info_name">
                    {job?.noOfVacancy}
                  </p>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  )
}

export default JobCard
