import React, { useState } from 'react'
import {
    Row,
    Col,
    Button,
    Container,
} from 'reactstrap';
import Media from "./Media"

function MediaList() {
    const [mediaModal, setMediaModal] = useState(false)
    const [isGrid, setIsGrid] = useState(true)
    const mediaModalToggle = () => setMediaModal(!mediaModal)
    const toggleGridView = () => setIsGrid(!isGrid);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <h4>Media</h4>
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-end mb-3">
                                <Button
                                    onClick={mediaModalToggle}
                                    className="btn btn-primary global_btn me-1"
                                >
                                    <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
                                    Add Media
                                </Button>
                                <Button
                                    onClick={toggleGridView}
                                    className="btn btn-primary global_btn me-1"
                                >
                                    {isGrid ? (
                                        <i className="bx bx-grid font-size-16 align-middle"></i>
                                    ) : (
                                        <i className="bx bx-list-ul font-size-16 align-middle"></i>
                                    )}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Media
                        mediaModalToggle={mediaModalToggle}
                        showModal={mediaModal}
                        setShowModal={setMediaModal}
                        isGrid={isGrid}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default MediaList