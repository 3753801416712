import {
  addNewJob as onAddNewJob,
  updateJob as onUpdateJob,
} from "../../store/actions"
import toastr from "toastr"
import { CheckUserSubscriptionsPlan } from "../../helpers/api/subscriptionsApi"

export const formFields = skillOptions => {
  return [
    {
      label: "Job Title",
      name: "jobTitle",
      type: "text",
      placeholder: "e.g Looking for QA Engineer",
      required: true,
      lg: 12,
    },
    {
      label: "Description*",
      name: "description",
      type: "textEditor",
      required: true,
      lg: 12,
    },
    {
      label: "Employment Type",
      name: "type",
      type: "checkBoxes",
      required: true,
      checkBoxes: [
        { value: "Full Time", label: "Full-Time" },
        { value: "Part Time", label: "Part-Time" },
        { value: "On-Demand", label: "On Demand" },
      ],
    },
    {
      label: "Job Type",
      name: "jobType",
      type: "checkBoxes",
      required: true,
      checkBoxes: [
        { value: "WFH", label: "WFH" },
        { value: "WFO", label: "WFO" },
        { value: "Hybrid", label: "Hybrid" },
      ],
    },
    {
      label: "Required Skills*",
      name: "skillIds",
      type: "multiSelect",
      options: skillOptions,
      required: true,
    },
    // {
    //   label: "Location*",
    //   name: "location",
    //   type: "text",
    //   placeholder: "Enter Location",
    //   required: true,
    // },
    {
      name: "cityState",
      label: "",
      type: "cityState",
      lg: 12,
      state: {
        name: "state",
        label: "Select State",
        type: "select",
        isExternal: true,
        slug: "state",
        required: true,
      },
      city: {
        name: "city",
        label: "Select City",
        type: "select",
        isExternal: true,
        slug: "city",
        required: true,
      },
    },
    {
      label: "Salary Range",
      name: "salaryRange",
      type: "text",
      placeholder: "Enter Salary Range*",
      required: true,
    },
    {
      label: "Experience*",
      name: "experience",
      type: "select",
      options: [
        { value: "", label: "Select Experience" },
        { value: "0.5", label: "Less than 1yr" },
        { value: "1", label: "1yr" },
        { value: "1.5", label: "1.5yr" },
        { value: "2", label: "2yr" },
        { value: "2.5", label: "2.5yr" },
        { value: "3", label: "3yr" },
        { value: "3.5", label: "3.5yr" },
        { value: "4", label: "4yr" },
        { value: "4.5", label: "4.5yr" },
        { value: "5", label: "5yr" },
        { value: "5.5", label: "5.5yr" },
        { value: "6", label: "6yr" },
        { value: "6.5", label: "6.5yr" },
        { value: "7", label: "7yr" },
        { value: "7.5", label: "7.5yr" },
        { value: "8", label: "8yr" },
      ],
      required: true,
    },
    {
      label: "Status*",
      name: "status",
      type: "select",
      options: [
        { value: "", label: "Select Status" },
        { value: "active", label: "Active" },
        { value: "deactive", label: "Deactive" },
      ],
      required: true,
    },
    {
      label: "No Of Vacancy",
      name: "noOfVacancy",
      type: "select",
      options: [
        { value: "", label: "Select No Of Vacancy" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
        { value: "40", label: "40" },
        { value: "50", label: "50" },
        { value: "60", label: "60" },
        { value: "70", label: "70" },
        { value: "80", label: "80" },
      ],
      required: true,
    },
  ]
}

export const handleSubmit = (
  formikValues,
  formik,
  jobDetail,
  user,
  isEdit,
  dispatch,
  navigate
) => {
  formik.validateForm().then(errors => {
    if (Object.keys(errors).length === 0) {
      const values = {
        ...jobDetail,
        ...formikValues,
        location: `${formikValues.city} ${formikValues.state}`,
      }
      values.skillIds =
        Array.isArray(values?.skillIds) && values.skillIds.length
          ? values.skillIds.map(skill => skill.value)
          : "[]"
      values.type = Array.isArray(values.type)
        ? values.type.join(",")
        : values.type
      values.image = user ? user?.image : ""
      if (isEdit) {
        dispatch(
          onUpdateJob(
            {
              ...values,
              skillIds:
                typeof values?.skillIds === "string"
                  ? JSON.parse(values?.skillIds)
                  : values?.skillIds,
            },
            jobDetail._id,
            navigate
          )
        )
      } else {
        dispatch(onAddNewJob({ ...values }, navigate))
        const isSkillsExist = localStorage.getItem("skills")
        if (isSkillsExist) {
          localStorage.removeItem("skills")
        }
      }
    } else {
      const touchedFields = {}
      Object.keys(errors).forEach(field => {
        touchedFields[field] = true
      })
      formik.setTouched(touchedFields)
      console.log("Validation errors:", errors)
    }
  })
}

export const handleSaveAsDraft = formik => {
  const isSkillsExist = localStorage.getItem("skills")
  const data = JSON.stringify(formik.values)
  if (isSkillsExist) {
    localStorage.removeItem("skills")
  }
  localStorage.setItem("skills", data)
  toastr.success("Saved data to in Draft")
}

export const checkPlan = async (navigate = "", route = "") => {
  const response = await CheckUserSubscriptionsPlan("JOB_POSTED")
  const plan = response?.data?.plan
  if (typeof plan === "object" && plan?.jobCredit && route) {
    plan?.jobCredit > 0 && navigate(route)
  } else if (typeof plan === "string") {
    toastr.error(plan)
  } else {
    console.log("The plan is neither an object nor a string.")
  }
}
