import React, { useState, useEffect } from 'react'
import {
	Card,
	CardHeader,
	CardBody,
	Row,
	Col,
	Button,
	Container,
	Spinner
} from 'reactstrap';
import {  useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import {
	getflashCard as onGetflashCard,
	resetFlashCard as onResetFlashCard,
	addFlashCard as onAddFlashCard,
	updateflashCard as onUpdateflashCard,
	deleteflashCard as onDeleteflashCard
} from "../../store/actions";
import ConfirmationModal from "../../components/Common/Modal/ConfirmationModal"
import FormModal from "../../components/Common/Modal/FormModal"
import Breadcrumbs from "../../components/Common/BackButtonBreadcrumb"
import TableContainer from "../../components/Common/Table/index"
import { flashCardFields, flashcardColumns } from "./constants";


function FlashCardList() {
	const [isEdit, setIsEdit] = useState(false)
	const [isLoading, setIsLoading] = useState(true);
	const [showModal, setShowModal] = useState(false)
	const [flashCardId, setFlashCardId] = useState("")
	const [flashCardModal, setFlashcardModal] = useState(false)
	const [flashCardDetail, setFlashCardDetail] = useState({
		frontView: "",
		backView: ""
	})
	const [chapterFlashCards, setChapterFlashCards] = useState([])
	const [pageNo, setPageNo] = useState(1)
	const [rowPerPage, setRowPerPage] = useState(10)

	const params = useParams()
	const dispatch = useDispatch();
	const navigate = useNavigate()

	const { flashCards, loading } = useSelector((state) => ({
		flashCards: state.flashCards.flashCards,
		loading: state.flashCards.loading,
	}));

	useEffect(() => {
		if (params?.chapterId) {
			const queryParams = {
				page: pageNo,
				limit: rowPerPage,
			}
			dispatch(onGetflashCard(params?.chapterId,queryParams));
		}
		return () => {
			setIsLoading(true);
			dispatch(onResetFlashCard())
		}
	}, [pageNo, rowPerPage]);

	useEffect(() => {
		if (Array.isArray(flashCards)) {
			setIsLoading(false);
			setChapterFlashCards(flashCards)
		}
	}, [flashCards, pageNo]);

	const toggleFlashCardModal = () => setFlashcardModal(!flashCardModal)

	const handleOpenFlashCard = (data) => {
		setIsEdit(true)
		setFlashCardDetail(data)
		toggleFlashCardModal()
	}

	const handleSaveFlashCard = values => {
		if (isEdit) {
			dispatch(onUpdateflashCard({ ...flashCardDetail, ...values }, flashCardDetail?._id, navigate))
		} else {
			values.courseId = params?.courseId
			values.chapterId = params?.chapterId
			dispatch(onAddFlashCard(values, navigate))
		}
		toggleFlashCardModal()
	}

	const modalAction = () => {
		handleDeleteButton(flashCardId)
		setShowModal(false)
	}
	const handleModalCloseAction = () => {
		setFlashCardDetail({
			frontView: "",
			backView: ""
		})
		setIsEdit(false)
	}
	const handleDeleteButton = (id) => {
		dispatch(onDeleteflashCard(id, navigate))
	}

	const onChangePagination = (limit, pageNo) => {
		setRowPerPage(limit)
		setPageNo(pageNo)
	  }

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs
						title="Courses"
						navigate={-1}
						back={-1}
						breadcrumbItem={"Flash Cards"}
					/>
					{isLoading ?
						<div className="d-flex justify-content-center">
							<Spinner className="ms-2" color="dark" size="sm" />
						</div>
						:
						<Card style={{ borderColor: "#CED4DA", borderWidth: 1.5 }}>
							<CardHeader style={{ backgroundColor: "#E0E5FA" }}>
								<Row>
									<Col>
										<h4 className="sun_heading">Flash Card</h4>
									</Col>
									<Col className='d-flex justify-content-end'>
										<Button
											onClick={() => {
												toggleFlashCardModal()
												setIsEdit(false)
											}}
											style={{
												backgroundColor: 'transparent',
												border: "1px solid blue",
												color: 'blue',
												'&:hover': {
													backgroundColor: 'blue',
													color: 'white',
												},
											}}
											className="btn"
										>
											<i className="bx bx-plus-circle font-size-16 align-middle me-2"></i>
											Add Flash Card
										</Button>
									</Col>
								</Row>
							</CardHeader>
							<CardBody style={{ backgroundColor: "#F2F2F2" }}>
								{chapterFlashCards && Array.isArray(chapterFlashCards) ? (
									<TableContainer
										loading={loading}
										data={chapterFlashCards}
										isGlobalFilter={false}
										isAddOptions={false}
										isJobListGlobalFilter={false}
										showPageDropdown={false}
										customPageSize={10}
										handleDelete={(item) => {
											setShowModal(true)
											setFlashCardId(item?._id)
										}}
										handleEdit={(item) => {
											handleOpenFlashCard(item)
										}}
										onChangePagination={onChangePagination}
										showPagination={true}
										className="project-list-table table-nowrap align-middle table-borderless"
										columns={flashcardColumns}
										tableName="viewStudent"
									/>
								) : "No Flash Cards"}
								{showModal && (
									<ConfirmationModal
										modalBackdrop={showModal}
										setmodalBackdrop={setShowModal}
										modalTitle={"Are you sure want to delete this Flash Card?."}
										modalAction={modalAction}
									/>
								)}
								{flashCardModal && (
									<FormModal
										modalBackdrop={flashCardModal}
										setmodalBackdrop={setFlashcardModal}
										modalAction={handleSaveFlashCard}
										handleModalCloseAction={handleModalCloseAction}
										title={`${isEdit ? "Edit" : "Add"} Flash Card`}
										formFields={flashCardFields}
										data={flashCardDetail}
										height={250}
									/>
								)}
							</CardBody>
						</Card>
					}
				</Container>
			</div>
		</React.Fragment>
	)
}

export default FlashCardList