import React from "react"
import moment from "moment"
import infobyd from "../../../assets/images/Invoice/infobyd.png"
import numberToWords from "number-to-words"

function invoice({ contentRef, invoiceDetail, transactions, user }) {
  // const downloadAsPDF = () => {
  //   const input = contentRef.current
  //   const pdf = new jsPDF("p", "pt", "a4")

  //   pdf.html(input, {
  //     html2canvas: {
  //       scale: 0.51, // Adjust the scale to fit content within PDF
  //     },
  //     callback: () => {
  //       pdf.save(`${new Date().toISOString()}_invoice.pdf`)
  //     },
  //   })
  // }

  const total = invoiceDetail?.amount || 0
  const gstAmount = ((invoiceDetail?.amount * 18) / 100).toFixed(2)
  const itemAmountWithoutGst = (total - gstAmount).toFixed(2)
  const amountInWordsString = numberToWords.toWords(total)

  return (
    <div style={{ width: "100%", height: "100%", padding: 10 }}>
      <div style={{ width: "100%", height: "100%" }} ref={contentRef}>
        <div
          style={{
            backgroundColor: "#000",
            display: "flex",
            padding: "20px",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
            gap: "20px",
          }}
        >
          <div>
            <p
              style={{
                paddingTop: "12pt",
                paddingLeft: "7pt",
                textIndent: "0pt",
                textAlign: "left",
                color: "#17a6ca",
                fontFamily: "Arial Black , sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                textDecoration: "none",
                fontSize: "32pt",
              }}
            >
              INVOICE
            </p>
          </div>
          <div style={{ color: "#fff" }}>
            <h6
              style={{
                fontWeight: "600",
                letterSpacing: "1px",
              }}
            >
              Company Details
            </h6>
            <p
              style={{
                letterSpacing: "1px",
              }}
            >
              Infobyd Software Solutions <br />
              GSTIN 23AAECI2749B1Z0
            </p>
            <p
              style={{
                letterSpacing: "1px",
              }}
            >
              PAN: AAECI2749B <br />
              CIN: U74999MP2016PTC041615
            </p>
          </div>
          <div style={{ color: "#fff" }}>
            <h6
              style={{
                fontWeight: "600",
                letterSpacing: "1px",
              }}
            >
              Contact Details
            </h6>
            <p
              style={{
                letterSpacing: "1px",
              }}
            >
              <a
                href="http://www.infobyd.com/"
                style={{
                  letterSpacing: "1px",
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                www.infobyd.com
              </a>
              <br />
              <a
                style={{
                  letterSpacing: "1px",
                  textDecoration: "none",
                  color: "#fff",
                }}
                href="mailto:accounts@infobyd.com"
              >
                accounts@infobyd.com
              </a>
              <br />
              <a> +91-96853 53494</a>
            </p>
          </div>
          <div style={{ color: "#fff" }}>
            <h6
              style={{
                fontWeight: "600",
                letterSpacing: "1px",
              }}
            >
              Address
            </h6>
            <p
              style={{
                letterSpacing: "1px",
              }}
            >
              Plot No. 106, PU-4, Scheme No. 54, Behind C-21/Malhar Mall,
              Indore, Madhya Pradesh, India, 452011
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div>
            <p
              style={{
                color: "black",
                fontFamily: "Arial, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                textDecoration: "none",
                fontSize: "12pt",
                margin: 0,
              }}
            >
              INVOICE TO,
            </p>
            <p
              style={{
                color: "#29779c",
                fontFamily: "Arial, sans-serif",
                fontStyle: "normal",
                fontWeight: "800",
                textDecoration: "none",
                fontSize: "14pt",
                margin: 0,
                marginBottom: "10px",
                textTransform: "Uppercase",
              }}
            >
              {user?.name}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "18px",
              }}
            >
              {user?.address}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "18px",
              }}
            >
              {user?.city}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "18px",
              }}
            >
              {user?.postalCode ? user?.postalCode : ""}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "18px",
              }}
            >
              {user?.state}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "18px",
              }}
            >
              {user?.country}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "18px",
              }}
            >
              {user?.phone}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "18px",
              }}
            >
              {user?.gstNumber ? user?.gstNumber : ""}
            </p>
          </div>
          <div>
            <img
              src={infobyd}
              style={{ width: "200px", marginLeft: "-5px" }}
              alt={"qapreneur"}
            />
            <p
              style={{
                margin: 0,
                marginTop: "5px",
                fontSize: "18px",
                color: "#000",
              }}
            >
              #: {invoiceDetail?.invoiceNumber}
            </p>
            <p
              style={{
                //   margin: 0,
                marginTop: "5px",
                marginBottom: "20px",
                fontSize: "18px",
                color: "#000",
              }}
            >
              Invoice Date: {moment(new Date()).format("DD MMM Y")}
            </p>
            <p
              style={{
                margin: 0,
                marginTop: "5px",
                fontSize: "18px",
                color: "#000",
              }}
            >
              Payment Date:{" "}
              {moment(new Date(invoiceDetail?.createdAt)).format("DD MMM Y")}
            </p>
            <p
              style={{
                margin: 0,
                marginTop: "5px",
                fontSize: "18px",
                color: "#000",
              }}
            >
              Place Of Supply: {user?.state}
            </p>
          </div>
        </div>
        <div>
          <table style={{ width: "100%" }}>
            <tr style={{ backgroundColor: "#000" }}>
              <th
                style={{
                  backgroundColor: "#29779c",
                  padding: "20px",
                  color: "#fff",
                }}
              >
                ITEM DESCRIPTION
              </th>
              <th
                style={{
                  textDecoration: "upperCase",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                HSN/SAC
              </th>
              <th
                style={{
                  textDecoration: "upperCase",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                QTY
              </th>
              <th
                style={{
                  textDecoration: "upperCase",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                RATE
              </th>
              <th
                style={{
                  textDecoration: "upperCase",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                IGST
              </th>
              <th
                style={{
                  textDecoration: "upperCase",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                AMOUNT
              </th>
            </tr>
            {invoiceDetail && (
              <tr>
                <td style={{ color: "#000", padding: "0 10px" }}>
                  {invoiceDetail?.purpose}
                </td>
                <td style={{ color: "#000", padding: "0 10px" }}>996111</td>
                <td style={{ color: "#000", padding: "0 10px" }}>1</td>
                <td style={{ color: "#000", padding: "0 10px" }}>
                  {itemAmountWithoutGst}
                </td>
                <td
                  style={{
                    fontSize: "18px",
                    color: "#000",
                    fontWeight: "600",
                    padding: "0 10px",
                  }}
                >
                  %
                </td>
                <td
                  style={{
                    fontSize: "18px",
                    color: "#000",
                    fontWeight: "600",
                    padding: "0 10px",
                  }}
                >
                  {itemAmountWithoutGst}
                </td>
              </tr>
            )}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td
                style={{
                  fontSize: "18px",
                  color: "#000",
                  fontWeight: "600",
                  padding: "0 10px",
                }}
              >
                18%
              </td>
              <td
                style={{
                  fontSize: "18px",
                  color: "#000",
                  fontWeight: "600",
                  padding: "0 10px",
                }}
              >
                {gstAmount}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "18px",
                  color: "#000",
                  textTransform: "uppercase",
                  padding: "0 10px",
                }}
              >
                {invoiceDetail?.productname}
              </td>
            </tr>
            <tr>
              <td
                style={{ fontSize: "18px", color: "#000", padding: "0 10px" }}
              >
                IN WORDS:
                <p
                  style={{
                    color: "#29779c",
                    fontStyle: "normal",
                    textDecoration: "none",
                    fontSize: "14pt",
                    margin: 0,
                    marginBottom: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  #Rupees {amountInWordsString} Only
                </p>
              </td>
              <td style={{ fontSize: "18px", color: "#000" }}></td>
              <td style={{ fontSize: "18px", color: "#000" }}></td>
              <td style={{ fontSize: "18px", color: "#000" }}></td>
              <td
                style={{ fontSize: "18px", color: "#000", padding: "0 10px" }}
              >
                Total:
              </td>
              <td
                style={{ fontSize: "18px", color: "#000", padding: "0 10px" }}
              >
                {total}
              </td>
            </tr>
          </table>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              width: "30%",
              backgroundColor: "#29779c",
              display: "flex",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              marginTop: "50px",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <p style={{ color: "#fff", margin: 0, padding: 10 }}>
                TOTAL PAID
              </p>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#000",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <p style={{ color: "#fff", margin: 0, padding: 10 }}>{total}</p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div>
            <p
              style={{
                margin: 0,
                marginTop: "5px",
                fontSize: "16px",
                color: "#000",
              }}
            >
              BANK DETAILS:
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "16px",
              }}
            >
              Name: Infobyd Software Solutions Pvt. Ltd.
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "16px",
              }}
            >
              Bank Name: IndusInd Bank
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "16px",
              }}
            >
              Bank A/C No: 254777770000
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "16px",
              }}
            >
              Swift Code: INDBINB
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "16px",
              }}
            >
              IFSC Code: INDB0000011
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "16px",
              }}
            >
              Branch Address: Industry House 15 Agra Mumbai Road, Old Palasia,
              Indore - 452 001
            </p>
          </div>
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "250px",
                  height: "3px",
                  backgroundColor: "#000",
                  margin: "10px 0",
                }}
              ></div>
              <p
                style={{
                  color: "#29779c",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "normal",
                  fontWeight: "500",
                  textDecoration: "none",
                  fontSize: "14pt",
                  margin: 0,
                }}
              >
                Alok Ranjan
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: "16px",
                  color: "#000",
                }}
              >
                DIRECTOR
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6
            style={{
              color: "#000",
              fontSize: "16px",
              textTransform: "uppercase",
              textAlign: "center",
              letterSpacing: "1.5px",
            }}
          >
            This is computer generated invoice <br /> no signature and seal
            required.
          </h6>
        </div>
      </div>
    </div>
  )
}

export default invoice
