/* TESTS */
export const GET_MOCK_TESTS = "GET_MOCK_TESTS"
export const GET_MOCK_TESTS_SUCCESS = "GET_MOCK_TESTS_SUCCESS"
export const GET_MOCK_TESTS_FAIL = "GET_MOCK_TESTS_FAIL"

/* TESTS DETAIL*/
export const GET_MOCK_TEST_DETAIL = "GET_MOCK_TEST_DETAIL"
export const GET_MOCK_TEST_DETAIL_SUCCESS = "GET_MOCK_TEST_DETAIL_SUCCESS"
export const GET_MOCK_TEST_DETAIL_FAIL = "GET_MOCK_TEST_DETAIL_FAIL"

/**
 * add test
 */
export const ADD_NEW_MOCK_TEST = "ADD_NEW_MOCK_TEST"
export const ADD_MOCK_TEST_SUCCESS = "ADD_MOCK_TEST_SUCCESS"
export const ADD_MOCK_TEST_FAIL = "ADD_MOCK_TEST_FAIL"

/**
 * Edit test
 */
export const UPDATE_MOCK_TEST = "UPDATE_MOCK_TEST"
export const UPDATE_MOCK_TEST_SUCCESS = "UPDATE_MOCK_TEST_SUCCESS"
export const UPDATE_MOCK_TEST_FAIL = "UPDATE_MOCK_TEST_FAIL"

/**
 * Delete test
 */
export const DELETE_MOCK_TEST = "DELETE_MOCK_TEST"
export const DELETE_MOCK_TEST_SUCCESS = "DELETE_MOCK_TEST_SUCCESS"
export const DELETE_MOCK_TEST_FAIL = "DELETE_MOCK_TEST_FAIL"

export const UPDATE_MOCK_TEST_LOADING_STATE = "UPDATE_MOCK_TEST_LOADING_STATE"
