import {
  GET_JOBS_FAIL,
  GET_JOBS_SUCCESS,
  GET_JOB_DETAIL_FAIL,
  GET_JOB_DETAIL_SUCCESS,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAIL,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAIL,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAIL,
  UPDATE_JOB_LOADING_STATE,
  RESET_JOB_DETAIL,
  RESET_JOBS,
  GET_JOBS_By_HR_SUCCESS,
  GET_JOBS_By_HR_FAIL,
  GET_USER_APPLIED_JOB_SUCCESS,
  GET_USER_APPLIED_JOB_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  jobs: [],
  hrJobs: [],
  userAppliedJobs: [],
  jobDetail: {},
  companyDetail: {},
  error: {},
  loading: false,
}

const jobs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
      }
    case GET_JOBS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_JOBS_By_HR_SUCCESS:
      return {
        ...state,
        hrJobs: action.payload,
      }
    case GET_JOBS_By_HR_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_USER_APPLIED_JOB_SUCCESS:
      return {
        ...state,
        userAppliedJobs: action.payload,
      }
    case GET_USER_APPLIED_JOB_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_JOB_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case ADD_JOB_SUCCESS:
      return {
        ...state,
        jobs: [...state.jobs, action.payload],
      }

    case ADD_JOB_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_JOB_DETAIL_SUCCESS:
      return {
        ...state,
        jobDetail: action?.payload?.job || action?.payload,
        companyDetail: action?.payload?.companyDetail || {},
      }

    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map(job =>
          job?._id.toString() === action.payload?._id.toString()
            ? { job, ...action.payload }
            : job
        ),
      }

    case UPDATE_JOB_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.filter(
          job => job._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_JOB_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_JOB_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_JOB_DETAIL:
      return {
        ...state,
        jobDetail: {},
      }
    case RESET_JOBS:
      return {
        ...state,
        jobs: [],
        jobDetail: {},
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default jobs
