import {
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAIL,
  UPDATE_TRANSACTION_LOADING_STATE,
  RESET_TRANSACTIONS,
} from "./actionTypes"

const INIT_STATE = {
  transactions: [],
  transactionDetail: {},
  error: {},
  loading: false,
}

const transactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      }

    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: [...state.transactions, action.payload],
      }

    case ADD_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TRANSACTION_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }

    case RESET_TRANSACTIONS:
      return {
        transactions: [],
        transactionDetail: {},
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default transactions
